import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import StickyBox from "react-sticky-box";
import { MDBDataTableV5 } from 'mdbreact';
import DoctorSidebar from "../sidebar";
import host from "../../../../host";
import moment from 'moment';





class MyFacture extends Component {
	constructor(props) {
		super(props);
        this.state = {
          transaction: [],
          // rows: [],
          datatable:{
            columns:[{
              label: "Patient",
              field: "psCentre",
              //   sort: 'asc',
              width: 370,
            },
            // {
            //   label: "Patient",
            //   field: "patient",
            //   //   sort: 'asc',
            //   width: 270,
            // },
            {
              label: "Prestation",
              field: "prestation",
              //   sort: 'asc',
              width: 270,
            },
            {
              label: "Prix",
              field: "prix",
              //   sort: 'asc',
              width: 270,
            },
            {
              label: "Mode de paiement",
              field: "mode",
              //   sort: 'asc',
              width: 270,
            },
            {
                label: "Date de paiement",
                field: "date",
                //   sort: 'asc',
                width: 270,
              },
            {
              label: "idTransaction",
              field: "idTransaction",
              //   sort: 'asc',
              width: 270,
            },
            {
              label: "Statut",
              field: "statut",
              //   sort: 'asc',
              width: 270,
            },
            // {
            //   label: "Numero",
            //   field: "numero",
            //   //   sort: 'asc',
            //   width: 270,
            // },
          ],
              rows:[]
          },
		
		}
	}

    componentDidMount() {
        let dbUser = JSON.parse(localStorage.getItem("user"))
        || JSON.parse(sessionStorage.getItem("user"));
  
        if (!dbUser) {
            window.location.assign('/')
        }
  
      if (dbUser === null || dbUser === undefined) {
        this.props.history.push('/login')
      } 
      console.log("dbUser: ",dbUser)
  
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch(host+`/ps/${dbUser.user.professionnelsante.idprofessionnelsante}`, requestOptions)
      // fetch("https://api.sante.kwaber.com/api/v1/ps/11", requestOptions)
        .then(response => {
          if(response.status === 200){
            return response.json();
          }
        })
        .then(result => {
          // console.log("result-1", result)
          this.setState({
              transaction: result.rdvs 
          })

          console.log("elementPaiemnt: ",result)
              let rowElement = [];
          for (let index = 0; index < result.paiement.length; index++) {
            const element = result.paiement[index];
                     console.log("ELEMENTrrr:", element)
                     let condition = element.idcentre2 ? element.idcentre2.libelle :  element.idpatient2.prenom+" "+element.idpatient2.nom;
                    rowElement.push({
                        psCentre: condition,
                        //  patient: element.,
                        prestation: element.prestation,
                        prix: element.prixPrestation,
                        mode: element.modePaiement,
                        idTransaction: element.transactionId,
                        date: moment(element.datepaiement).format("YYYY-MM-DD hh:mm:ss"),
                         
                        // numero:,
                        statut: <span  className="btn btn-sm bg-info-light">Payé</span>
                    })   
          }
          this.setState({
              datatable: {
                columns: this.state.datatable.columns,
                  rows: rowElement
                }
          })

      })
        .catch(error => console.log('error', error));
            
         
      }
      
    
    
	render() {
		return (
			<div>
				<div className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
								<StickyBox offsetTop={20} offsetBottom={20}>
                                <DoctorSidebar/>
								</StickyBox>
							</div>						
							<div className="col-md-7 col-lg-8 col-xl-9">
                                    {/* <h1> Suis la page Mes</h1> */}
                                    <MDBDataTableV5
                                hover
                                entriesOptions={[5, 20, 25]}
                                entries={5}
                                pagesAmount={4}
                                data={ this.state.datatable}
                                pagingTop
                                searchTop
                                searchLabel="Recherche"
                                searchBottom={false}
                                // infoLabel={["Showing", "to", "of", "entries"]}
                                />
                            </div>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

export default MyFacture;

