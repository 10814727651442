import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../client/assets/images/KSL_White__Paysage.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "react-loader-spinner";
import FadeIn from "react-fade-in";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import host from "../../../host";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      password: "",
    };

    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  // componentDidMount(){
  // 	toast.warning("teste");
  // }

  handleSubmit(e) {
    if (this.state.email != "" && this.state.password != "") {
      this.setState({
        isLoading: true,
      });
      console.log(this.state);
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        username: this.state.email,
        password: this.state.password,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + "/auth/login", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log("result", JSON.parse(result));
          const data = JSON.parse(result);
          if (data.message) {
            this.setState({
              isLoading: false,
            });
            toast.error("Vos indentifiant sont incorrectes");
          } else {
            this.setState({
              isLoading: false,
            });
            toast.success("Connection realiser avec succes");

            sessionStorage.setItem(
              "admin",
              JSON.stringify({
                token: data.token,
                userId: data.userId,
                username: data.username,
                state: true,
              })
            );
            this.props.history.push("/admin");
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          toast.error("Probleme l'hors de la connection");
        });
      e.preventDefault();
    } else {
      toast.error("Tous les champs sont requis");
    }
  }

  handleChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  handleChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }
  render() {
    return (
      <div>
        <ToastContainer />
        <div
          className={
            this.state.isLoading
              ? "page_wrapper_blur main-wrapper login-body"
              : "main-wrapper login-body"
          }
        >
          <div className="login-wrapper">
            <div className="container">
              <div className="loginbox">
                <div className="login-left">
                  <img className="img-fluid" src={Logo} alt="Logo" />
                </div>
                <div className="login-right">
                  <div className="login-right-wrap">
                    <FadeIn>
                      <h1>Connection</h1>
                      <p className="account-subtitle">
                        Accéder au tableau de bord
                      </p>

                      <form action="/" onSubmit={this.handleSubmit}>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleChangeEmail}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="password"
                            placeholder="Mot de passe"
                            value={this.state.password}
                            onChange={this.handleChangePassword}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Connection
                          </button>
                        </div>
                      </form>
                      <Loader
                        style={{
                          position: "absolute",
                          textAlign: "center",
                          marginLeft: "auto",
                          left: "0",
                          right: "0",
                          marginRight: "auto",
                          zIndex: "200px",
                        }}
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={this.state.isLoading}
                      />

                      <div className="text-center forgotpass">
                        <Link to="/admin/forgotPassword">
                          Mot de passe oublier?
                        </Link>
                      </div>
                      {/* <div className="login-or">
										<span className="or-line"></span>
										<span className="span-or">or</span>
									</div> */}

                      {/* <div className="social-login">
										<span>Login with</span>
										<a href="#0" className="facebook"><i class="fab fa-facebook-f"></i></a>
										<a href="#0" className="google"><i class="fab fa-google"></i></a>
									</div> */}
                    </FadeIn>

                    {/* <div className="text-center dont-have">Vous n'avez pas de compte? <Link to="/admin/register">Inscription</Link></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
