import React, { Component } from "react";
import { IMG01, IMG02, IMG03 } from "./img";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Countdown from "react-countdown";
import CountDownTimer from "./Counter";
import moment from "moment";
import swal from 'sweetalert';
import hostMeet from "../../../../hostMeet";
import host from "../../../../host";

//const hoursMinSecs = {hours:0, minutes: 1, seconds: 10}

// Random component
const Completionist = () => <span>Veuillez actualiser votre page svp !</span>;

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <span>{hours}:{minutes}:{seconds}</span>;
  }
};

class VideoCall extends Component {
  constructor(props) {
		super(props);

		this.state = {
			hoursMinSecs: "",
      name: "",

		}


	}

  componentDidMount(){

    const dataJson = sessionStorage.getItem('user') || sessionStorage.getItem('user');;
		const data = dataJson && JSON.parse(dataJson);

        this.setState({
            name: ""
        })
    

    const lien = host

    const urls = window.location.href + '';
    // const bits = urls.toString().split("/");
    const URLbits = urls.toString().split("?");
    const urlBits = URLbits[0].split('/');
     const codeMeet = urlBits[urlBits.length - 1];
    // const tokenMeet = bits[5].split("?");
    // const codeMeet = tokenMeet[0] ;


    console.log("Token", codeMeet)
     var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  fetch(lien+"/consultation/token/"+codeMeet, requestOptions)
    .then(response => response.json())
    .then((result) =>{
      // console.log("REusltPPPP",result)
      const info = result;
      const token = result.token;
    const dateToday = new Date();
    const dateConsut = result.dateconsultation;
    const momentToday = moment(dateToday).format("YYYY-MM-DD");
    //  const dateConsulFormat = moment("2021-09-08").format("YYYY-MM-DD");
     const dateConsulFormat = moment().format("YYYY-MM-DD");


  const timeConsul = new Date(result.dateconsultation+" "+result.heuredebutconsultation).getTime();
  const timeToday = new Date().getTime() - 30*60*1000;

  let timeTodays = new Date().getTime();
  let timeDebut = new Date(result.dateconsultation + " " + result.heuredebutconsultation).getTime() - 30 * 60 * 1000;

  // if (timeTodays < timeDebut) {
  //   window.location.replace("/expiration");
  //   return null;
  // }
   
      // const rr = new Date(timeToday);  

      // if( momentToday === dateConsulFormat){
        // dateConsulFormat ===
          // if(timeTodays < timeDebut){
          if(timeTodays){
            
            window.location.replace(hostMeet+'pa/' + codeMeet)    
            // swal('Veuillez vous connecté 30min avant');
            // === timeToday
          }else{
            window.location.replace(hostMeet+'pa/' + codeMeet)    
          }
      // }
      // else{
      //   swal('Veuillez vous connecté 30min avant');
        
      // }



    //   if (info.message === "Consulation non trouvée !"){
    //     window.location.replace('http://localhost:3001')
    //   }
    //   else{
    //     // window.location.replace('http://localhost:8080/meeting/' + token)
    //     window.location.replace('https://kwaber-teleconsultation.herokuapp.com/meeting/' + codeMeet)
    //   }


    //  // 
          


     } )
    .catch(error =>{ 
      console.log("error", error)
      // window.location.replace('http://localhost:3001')
      }
      );

      this.setState({
        hoursMinSecs: {hours:0, minutes: 1, seconds: 0},
      });

    }
  

  render() {
              
    return (
      <>
        <div className="content">
          <div className="container-fluid">
            <div className="call-wrapper">
              <div className="call-main-row">
                <div className="call-main-wrapper">
                  <div className="call-view">
                    <div className="call-window">
                      <div className="fixed-header">
                        <div className="navbar">
                          <div className="user-details">
                            <div className="float-left user-img">
                              <a
                                className="avatar avatar-sm mr-2"
                                href="/doctor/patient-profile"
                                title="Charlene Reed"
                              >
                                <img
                                  src={IMG02}
                                  alt="User"
                                  className="rounded-circle"
                                />
                                <span className="status online"></span>
                              </a>
                            </div>
                            <div className="user-info float-left">
                              <a href="/doctor/patient-profile">
                                <span> {this.state.name} </span>
                              </a>
                              <span className="last-seen">En ligne</span>
                            </div>
                          </div>
                          {/* <ul className="nav float-right custom-menu">
                            <li className="nav-item dropdown dropdown-action">
                              <a
                                href="#0"
                                className="nav-link dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-cog"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a href="#0" className="dropdown-item">
                                  Settings
                                </a>
                              </div>
                            </li>
                          </ul> */}
                        </div>
                      </div>

                      <div className="call-contents">
                        <div className="call-content-wrap">
                          <div className="user-video">
                            <img src={IMG03} alt="User" />
                          </div>
                          <div className="my-video">
                            <ul>
                             
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="call-footer">
                        <div className="call-icons">
                          <span className="call-duration">
                            <Countdown date={Date.now() + 60000} renderer={renderer} />
                            {/* <CountDownTimer hoursMinSecs={this.state.hoursMinSecs}/> */}
                          </span>
                          <ul className="call-items">
                          
                            <li className="call-item">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip2">Lancer l'appel </Tooltip>
                                }
                              >
                              
                            <a href="#0">
                                  <i className="material-icons">medication</i>
                            </a>                          
                                
                              </OverlayTrigger>
                            </li>
                            {/* <div className="start-call">
                            <a href="#0">
                              <i className="material-icons">call</i>
                            </a>
                          </div> */}
                          </ul> 
                          <div className="end-call">
                            {/* <a href="#0">
                              <i className="material-icons">call_end</i>
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default VideoCall;
