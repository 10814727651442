import React,{ Component } from 'react';

import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { IMG01, IMG02, IMG03, IMG04, IMG09, IMG010, IMG011  } from './img';
class DoctorChat extends Component{
    constructor(props) {
		super(props);
		this.state = {
			activeModal: null

		}
    }
	openModal= (id)=> {
        this.setState({activeModal: id}, () => {
            }); 
	  }
	  
      handleCloseModal = () => {
          this.setState({activeModal: false}, () => {
            }); 
		}

		componentDidMount(){
			document.body.classList.add('chat-page');
		}
		componentWillUnmount(){
			document.body.classList.remove('chat-page');
		}

    render(){
        return(
    <div>
        <div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-12">
							<div className="chat-window">
							
								
								<div className="chat-cont-left">
									<div className="chat-header">
										<span>Messagerie</span>
										<a href="#0" className="chat-compose">
											<i className="material-icons">control_point</i>
										</a>
									</div>
									<form className="chat-search">
										<div className="input-group">
											<div className="input-group-prepend">
												<i className="fas fa-search"></i>
											</div>
											<input type="text" className="form-control" placeholder="Chercher"/>
										</div>
									</form>
									<div className="chat-users-list">
										<div className="chat-scroll">
											<a href="#0" className="media">
												<div className="media-img-wrap">
													<div className="avatar avatar-away">
									<img src={IMG01} alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Richard Wilson</div>
														<div className="user-last-chat">J'aimerais savoir si...</div>
													</div>
													<div>
														<div className="last-chat-time block">2 min</div>
														<div className="badge badge-success badge-pill">2</div>
													</div>
												</div>
											</a>
											<a href="#0" className="media read-chat active">
												<div className="media-img-wrap">
													<div className="avatar avatar-online">
								<img src={IMG02} alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Charles Reed</div>
														<div className="user-last-chat">Bien sur sans problèmes ... </div>
													</div>
													<div>
														<div className="last-chat-time block">8:01 PM</div>
													</div>
												</div>
											</a>
											<a href="#0" className="media">
												<div className="media-img-wrap">
													<div className="avatar avatar-away">
								<img src={IMG03} alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Travis Trimble </div>
														<div className="user-last-chat">Donnez moi une minute... </div>
													</div>
													<div>
														<div className="last-chat-time block">7:30 PM</div>
														<div className="badge badge-success badge-pill">3</div>
													</div>
												</div>
											</a>
											<a href="#0" className="media read-chat">
												<div className="media-img-wrap">
													<div className="avatar avatar-online">
							<img src={IMG04} alt="User" className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Michel NGOLO</div>
														<div className="user-last-chat">Bien sur c'est bien...</div>
													</div>
													<div>
														<div className="last-chat-time block">6:59 PM</div>
													</div>
												</div>
											</a>
											
										</div>
									</div>
								</div>
								
								<div className="chat-cont-right">
									<div className="chat-header">
										<a id="back_user_list" href="#0" className="back-user-list">
											<i className="material-icons">chevron_left</i>
										</a>
										<div className="media">
											<div className="media-img-wrap">
												<div className="avatar avatar-online">
								 <img src={IMG02} alt="User" className="avatar-img rounded-circle" />
												</div>
											</div>
											<div className="media-body">
												<div className="user-name">Charles Reed</div>
												<div className="user-status">online</div>
											</div>
										</div>
										<div className="chat-options">
											<a href="#0" data-toggle="modal" data-target="#voice_call" onClick={()=> this.openModal('voice')}>
												<i className="material-icons">local_phone</i> 
											</a>
											<a href="#0" data-toggle="modal" data-target="#video_call" onClick={()=> this.openModal('video')}>
												<i className="material-icons">videocam</i>
											</a>
											<a href="#0">
												<i className="material-icons">more_vert</i>
											</a>
										</div>
									</div>
									<div className="chat-body">
										<div className="chat-scroll">
											<ul className="list-unstyled">
												<li className="media sent">
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Bonjour, que puis-je faire pour vous ?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:30 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media received">
													<div className="avatar">
							<img src={IMG02} alt="User" className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>J'aimerais savoir s'il est possible de réserver une consultation avec vous ?</p>
																<p>Je vous fais parvenir un l'ordonnance de mon médecin traitant</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:35 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<p>Je vous l'envoie en deux photos distinctes</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:40 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<div className="chat-msg-attachments">
																	<div className="chat-attachment">
																		<img src={IMG010} alt="Attachment" />
																		<div className="chat-attach-caption">placeholder.jpg</div>
																		<a href="#0" className="chat-attach-download">
																			<i className="fas fa-download"></i>
																		</a>
																	</div>
																	<div className="chat-attachment">
																		<img src={IMG09} alt="Attachment" />
																		<div className="chat-attach-caption">placeholder.jpg</div>
																		<a href="#0" className="chat-attach-download">
																			<i className="fas fa-download"></i>
																		</a>
																	</div>
																</div>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:41 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media sent">
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>D'accord pas de soucis je regarde d'abord votre ordonnance</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:42 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<p>J'ai bien tout reçu, je vous fais parvenir mes disponibilités</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:42 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<div className="chat-msg-attachments">
																	<div className="chat-attachment">
																		<img src={IMG011} alt="Attachment" />
																		<div className="chat-attach-caption">placeholder.jpg</div>
																		<a href="#0" className="chat-attach-download">
																			<i className="fas fa-download"></i>
																		</a>
																	</div>
																</div>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:50 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media received">
													<div className="avatar">
				                              <img src={IMG01} alt="User" className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>J'ai bien reçu merci.</p>
																<p>Je regarde et je vous informe dans la semaine</p>
																<p>Merci</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:55 PM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="chat-date">Today</li>
												<li className="media received">
													<div className="avatar">
					                          <img src={IMG02} alt="User" className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Mercredi de cette semaine à 14h me conviendrait, cela vous convient ?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>10:17 AM</span>
																		</div>
																	</li>
																	<li><a href="#0">Edit</a></li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media sent">
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Bien sur sans problème, venez avec votre ordonnance</p>
																<p>N'hésitez à m'envoyer un message si vous avez d'autres questions.</p>
																<div className="chat-msg-actions dropdown">
																	<a href="#0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																		<i className="fe fe-elipsis-v"></i>
																	</a>
																	<div className="dropdown-menu dropdown-menu-right">
																		<a className="dropdown-item" href="#0">Delete</a>
																	</div>
																</div>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>10:19 AM</span>
																		</div>
																	</li>
																	<li><a href="#0">Edit</a></li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media received">
													<div className="avatar">
						          	<img src={IMG01} alt="User" className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<div className="msg-typing">
																	<span></span>
																	<span></span>
																	<span></span>
																</div>
															</div>
														</div>
													</div>
												</li>
											</ul>
										</div>
									</div>
									<div className="chat-footer">
										<div className="input-group">
											<div className="input-group-prepend">
												<div className="btn-file btn">
													<i className="fa fa-paperclip"></i>
													<input type="file" />
												</div>
											</div>
											<input type="text" className="input-msg-send form-control" placeholder="Ecrivez"/>
											<div className="input-group-append">
												<button type="button" className="btn msg-send-btn"><i className="fab fa-telegram-plane"></i></button>
											</div>
										</div>
									</div>
								</div>
							
								
							</div>
						</div>
					</div>
				
				</div>

			</div>		
							{/* modal for video*/}
                            <Modal show={this.state.activeModal === 'video'} onHide={this.handleCloseModal} centered>
					<Modal.Body>	
						<div class="call-box incoming-box">
							<div class="call-wrapper">
								<div class="call-inner">
									<div class="call-user">
										<img alt="User" src={IMG02} class="call-avatar" />
										<h4>Charles Reed</h4>
										<span>Connexion...</span>
									</div>							
									<div class="call-items">
										<a href="#0" class="btn call-item call-end" data-dismiss="modal" aria-label="Close">
										<Icon>call_end</Icon>
										</a>
										<Link to="/voice-call" class="btn call-item call-start"><i class="material-icons">call</i></Link>
									</div>
								</div>
							</div>
						</div>
						</Modal.Body>
				</Modal>
				{/* modal for call*/}
				<Modal show={this.state.activeModal === 'voice'} onHide={this.handleCloseModal} centered>
					<Modal.Body>	
						<div class="call-box incoming-box">
							<div class="call-wrapper">
								<div class="call-inner">
									<div class="call-user">
										<img alt="User" src={IMG02} class="call-avatar" />
										<h4>Charles Reed</h4>
										<span>Connexion...</span>
									</div>							
									<div class="call-items">
										<a href="#0" class="btn call-item call-end" data-dismiss="modal" aria-label="Close">
										<Icon>call_end</Icon>
										</a>
										<Link to="/voice-call" class="btn call-item call-start"><i class="material-icons">call</i></Link>
									</div>
								</div>
							</div>
						</div>
						</Modal.Body>
				</Modal>
			
		  </div>

        );
    }
}
export default DoctorChat;      