import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// import { Tab, Tabs, Modal } from 'react-bootstrap';
// import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
// import Calendar from 'react-calendar';
import { ToastContainer, toast } from "react-toastify";
import host from "../../../../host";
import Loader from "react-loader-spinner";
import { Image } from "antd";
import Alert from 'react-bootstrap/Alert'
import numStr from '../separator/separator'

import { MDBDataTableV5, MDBTable } from "mdbreact";

function isNumeric(number) {
  return !isNaN(parseFloat(number)) && !isNaN(+number);
}

class Prestation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isTrue: false,
      professionnel: {
        creneau_dispos: [],
        specialite: [],
        centres: [],
        photo: [],
        prestation: [],
      },
      photo: "",
      prestationChecked:{},
      prestation: [],
      prixEuro: [],
      prixXAF: [],
      modalite: [],

      checked: false,

      prestationChoisi: null,
      prixEuroChoisi: null,
      prixXAFChoisi: null,
      modaliteChoisi: null,
      idpro: null,
      // row: [],
      checkbox1: "",
      datatable: {
        columns:[],
        rows:[],     
      },
    };
  }

  // La prestation  choisie
  showLogs2 = (e) => {
               console.log("isChecked: ", e)                     
    this.setState({
      checkbox1: e,
      checked: e.checked,
      prestationChecked:{
      idProfessionel:this.state.professionnel.idprofessionnelsante,
      idPrestation: e.idprestation,
      prestations:{
        id: e.idprestation,
        libelle:e.libelle,
        modalite:e.modalite,
       }, 
      prixEuro:e.pEUR,    
      prixXAF: e.pXAF,
      }
    });
  };

  async componentDidMount() {
    
    const {
      match: { params },
    } = this.props;

    if (isNumeric(params.id)) {
      const id = parseInt(params.id);
      fetch(host + `/ps/${id}`)
        .then((response) => {
          if (response.status === 404) {
            this.props.history.push("/404");
          } else if (response.status === 200) {
            return response.json();
          }
        })
        .then((data) => {
          let photo =
            "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png";
          if (data.user.photo !== null || data.user.photo !== undefined) {
            photo = data.user.photo;
          }
            // console.log('dataPS:', data.prestation);
            let rowElements = [];
            for (let index = 0; index < data.prestation.length; index++) {
              const element = data.prestation[index];

              // console.log("Elements:", element)
              if(element.idcentre2 === null){
                rowElements.push({
                  idprestation: element.id,
                  libelle: element.libelle,
                  modalite: element.modalite,
                  pEUR: element.pEUR,
                  pXAF: numStr(element.pXAF,'.'),
                  centre: "--",
                })
              }else{
                rowElements.push({
                  idprestation: element.id,
                  libelle: element.libelle,
                  modalite: element.modalite,
                  pEUR: element.pEUR,
                  pXAF: numStr(element.pXAF,'.'),
                  centre: element.idcentre2.libelle,
                })
              }
            }

          this.setState({
            professionnel: data,
            photo: photo,
            isLoading: false,
            idpro: id,
              datatable: {
                columns: [
                  {
                    label: "Prestation",
                    field: "libelle",
                    //   sort: 'asc',
                    width: 270,
                  },
                  {
                    label: "Modalité",
                    field: "modalite",
                    //   sort: 'asc',
                    width: 200,
                  },
                  {
                    label: "Prix en Euro",
                    field: "pEUR",
                    // sort: 'asc',
                    width: 150,
                  },
                  {
                    label: "Prix en XAF",
                    field: "pXAF",
                    //   sort: 'asc',
                    width: 100,
                  },
                  {
                    label: "Centre",
                    field: "centre",
                    //   sort: 'asc',
                    width: 100,
                  },
                ],
                rows: rowElements
              }        
          });
        });
    } else {
      this.props.history.push("/404");
    }
  }



  render() {
    const { professionnel } = this.state;
    // {console.log("prestationChecked:", this.state.prestationChecked)}
    if (this.state.isLoading)
      {
      return (
        <div
          style={{
            textAlign: "center",
            marginTop: "50vh",
            marginBottom: "40vh",
          }}
        >
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      );
    } else {
      return (
        <div>
          <div className="content">
            <ToastContainer />
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="card" id="prestation-doctor">
                    <div className="card-body">
                      <div className="doctor-widget">
                        <div className="doc-info-left">
                          <div className="doctor-img">
                          <Image
                           width={150}
                           height={150}
                          src={
                            this.state.photo ? this.state.photo  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"                              
                          }
                          className="img-fluid"
                          style={{ borderRadius: "2%" }}
                          alt={professionnel.nom + " " + professionnel.prenom}
                        />
                          </div>
                          <div className="doc-info-cont">
                            <h4>
                              <Link
                                to={
                                  "/patient/doctor-profile/" +
                                  this.state.professionnel.idprofessionnelsante
                                }
                              >
                                {this.state.professionnel.nom}{" "}
                                {this.state.professionnel.prenom}
                              </Link>
                            </h4>
                            <div>
                              <h5 className="doc-department">
                                {this.state.professionnel.specialite === null &&
                                this.state.professionnel.specialite === undefined
                                  ? "Aucune spécialité spécifiée"
                                  : this.state.professionnel.specialite.libelle}
                              </h5>
                            </div>                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="container">
                <div className="col-12">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">                                
                        {/* {
                          // Conditionné le message à affiché quand y'a pas de prestations
                        this.state.professionnel.prestation.length === 0 ?
                        <Alert variant={"info"}>
                                              Il n'y a aucune prestation.
                              </Alert> : ""
                        } */}
                        {
                          !this.state.checked ?
                          <Alert variant={"info"}>
                            { this.state.professionnel.prestation.length === 0 ?
                            "Il n’y a aucune prestation" : 
                             "Veuillez sélectionner une prestation"}
                        </Alert> : ""
                            
                            }
                            <MDBTable responsive>
                            <MDBDataTableV5
                                    //   La table des prestations with mdbreact 
                                                 hover
                                                 searchTop
                                                 searchBottom={false}
                                                 entriesLabel={"Lignes par pages"}
                                                 searchLabel={"Rechercher"}
                                                 infoLabel={["", "-", "/", ""]}
                                                 noRecordsFoundLabel={
                                                   "Aucune donnée ne correspond"
                                                 }
                                                entriesOptions={[5, 20, 25]}
                                                entries={5}
                                                pagesAmount={4}
                                                data={this.state.datatable}
                                                checkbox
                                                headCheckboxID='id2'
                                                bodyCheckboxID='checkboxes2'
                                                getValueCheckBox={(e) => {
                                                   this.showLogs2(e);
                                                 
                                                }}
                                                // checkboxFirstColumn={false}
                                            />
                            </MDBTable>
                                    
                                  
                         </div>

                            
                            
                         {
                        //  Affiché le bouton si est seulement si un élement à été coché *
                           this.state.checked ? 
                           <Link to={{
                            pathname: `/patient/booking/prestation=${this.state.prestationChecked.idPrestation}&ps=${this.state.prestationChecked.idProfessionel}`,
                            // prestation: this.state.prestationChecked
                          }}>
                           <button style={{ color: "white" }} className=" btn btn-info mt-10">
                             Suivant
                          </button>
                            </Link>
                           :
                           <button  disabled style={{ color: "white" }} className=" btn btn-info mt-10">
                                            Suivant     
                                </button>
                         }                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Prestation;
