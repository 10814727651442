import React, { Component } from "react";
import { Link } from "react-router-dom";
// import IMG01 from "../../../assets/images/patient2.jpg";
// import PaiementService from "../../../../stripe/PaiementService";
// import axios from "axios";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./style.css";
import Loader from "react-loader-spinner";
import swal from "sweetalert";
import host from "../../../../host";
import moment from "moment";
import { Image } from "antd";
import { Spin } from "antd";
import numStr from '../separator/separator'

const ModalAntd = require("antd").Modal;
const Button = require("antd").Button;

class CheckoutMtn extends Component {
  constructor(props) {
    super(props);

    this.phoneRef = React.createRef();
    this.state = {
      isLoading: false,
      idIntervalle: 0,
      message: "",
      erreur: null,
      isVisible: false,
      show: false,
      // professionnel: {},
      creneau: "",
      patient: "",
      urlPhoto: "",
      // centre: JSON.parse(window.sessionStorage.getItem("rdv")).centre,
      professionnel: [],
      isLoading: false,
      date: "",
      heureDebut: "",
      heureFin: "",
      prix: "",
      idPatient: "",
      rdvCreer: "",
      creneauCreer: "",
      numero: "",
      idTransaction: "",
      messageSucces: "",
      etatSendPaie: false,
      idsCreneauReserve: "",
      idPro: "",
      proId: "",
      prestationId: "",
      creneauId: "",
      patientId: "",
      airtelmoneyNumtel: "",
      codeStatus: "",
      datePaiement: "",
      creneauDate: "",
    };
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeInput = (e) => {
    this.setState({
      numero: e.target.value,
      erreur: "",
      messageSucces: "",
    });
  };

  guid = () => {
    let s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return s4() + s4() + s4();
  };

  handleSubmit = (e) => {
    // swal("Trying");
    this.setState({
      isLoading: true,
    });

    e.preventDefault();
    const reg = /^(06)[0-9]{3}[0-9]{2}[0-9]{2}/;
    const numero = reg.test(this.state.numero);

    if (numero) {
      // const id = Math.floor(Math.random(1000) * 1000000000000);
      //     const today = new Date();
      //     const dateNow = new today.now();
      var myHeaders = new Headers();
      const total = this.state.prixXAF;
      // const test = 50;
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        amount: total,
        applicationId: "kwaber-sante",
        currency: "XAF",
        callback: "oriondev",
        motif: "paiement kwaber sante",
        status: 200,
        transactionId: `kwaber-sante-${this.guid()}`,
        phoneNumber: this.state.numero,
      });

    //   var requestOptions = {
    //     method: "POST",
    //     headers: myHeaders,
    //     body: raw,
    //     // mode: "no-cors",
    //     redirect: "follow",
    //   };

    //   fetch(
    //     "https://kwaber-paiement-api-jkwol.ondigitalocean.app/payment/airtel",
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((result) => {
    //       // console.log("PAYER_NOW", result);
    //       if (result.payment.statusCode === "1000") {
    //         this.setState({
    //           idTransaction: result.payment.transactionId,
    //           messageSucces:
    //             "Veuillez confirmer le paiement ou tapez " +
    //             result.payment.ussdCode,
    //           etatSendPaie: true,
    //           //   isLoading: false,
    //         });
    //         this.showModal();

    //         this.state.idIntervalle = setInterval(() => {
    //           this.handleCheckPay();
    //         }, 5000);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //       swal("Le service a été temporairement interempu réessayé dans un instant svp!" );
    //       this.setState({
    //         isLoading: false,
    //       });       
    //     });

        //initie le paiement Mobile Money
        var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({
                 "amount": total,
                "currency": "XAF",
                "phoneNumber": "242"+this.state.numero
                });

                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
                };

                fetch("https://mobilepay.kwaber.com/payment/mtn", requestOptions)
                .then(response => response.json())
                .then(result =>{
                    if(result.status === "PENDING"){
                        this.setState({
                            idTransaction: result.externalId,
                            messageSucces:
                              "Veuillez confirmer le paiement en tapant *105# " ,                           
                            etatSendPaie: true,
                            //   isLoading: false,
                          });
                          this.showModal();
                           this.state.idIntervalle = setInterval(() => {
                            this.handleCheckPay();
                          }, 5000);
                    }else if(result.status === "FAILED"){
                        swal("La transaction n'a pas aboutie");
                              this.setState({
                                isLoading: false,
                              }); 
                    }
                    // else{
                    //     swal("Le service a été temporairement interempu réessayé dans un instant svp!" );
                    //           this.setState({
                    //             isLoading: false,
                    //           });   
                    // }
                    // console.log("resultMtn:", result)
                })
                .catch(error =>{
                    console.log('error', error)
                    swal("Le service a été temporairement interempu réessayé dans un instant svp!" );
                          this.setState({
                            isLoading: false,
                          }); 
                });

    } else {
      this.setState({
        erreur:
          "Le numéro saisi est invalide! Veuillez respecter le format Ex: 06",
        isLoading: false,
      });
      // this.reserveCreneau();
      // this.props.history.push("/patient/airtel/book/success")
    }
  };
  // Vérification du Paiement..... 
  handleCheckPay = () => {
    this.setState({
      isLoading: true,
    });

    // var requestOptions = {
    //   method: "GET",
    //   redirect: "follow",
    // };

    // if (this.state.idTransaction !== "" || this.state.idTransaction !== null) {
    //   fetch(
    //     `https://kwaber-paiement-api-jkwol.ondigitalocean.app/payment/${this.state.idTransaction}`,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((result) => {
    //       this.setState({
    //         codeStatus: result.statusCode,
    //       });
    //       if (result.statusCode == "200") {
    //         // si le paiyement a bien été réalisé
    //         clearInterval(this.state.idIntervalle);
    //         this.reserveCreneau();
    //         this.setState({
    //           isLoading: false,
    //           idTransaction: result.transactionId,
    //           codeStatus: result.statusCode,
    //           datePaiement: result.createdAt,
    //           message: "Paiement effectué avec succès",
    //           //  idTransaction: result.payment.transactionId,
    //           //  codeStatus: result.payment.statusCode,
    //           //  datePaiement: result.payment.createdAt
    //         });
    //         // swal(result.statusText);
    //         this.reserveCreneau();
    //         this.reserveConsultation();
    //         this.insererRDV();
    //         this.createPaymode();
    //         this.paiementInfo();
    //         // setTimeout(() => {
    //         //   this.props.history.push("/patient/airtel/book/success");
    //         // }, 3000);
    //         sessionStorage.setItem(
    //           "userCommande",
    //           JSON.stringify({
    //             idCommande: this.state.idTransaction,
    //             modePaiement: "Airtel Money",
    //             datePaiement: moment(this.state.datePaiement).format(
    //               "YYYY-MM-DD"
    //             ),
    //             //   state: true,
    //           })
    //         );

    //         // swal("Ce service est indisponible pour le moment. Veuillez Réassyez la confirmation dans un instant SVP ");
    //       } else if (result.statusCode == "60019") {
    //         // si les fonds sont insuffisants
    //         this.setState({
    //           isLoading: false,
    //           message: "Votre solde est insuffisant.",
    //         });
    //         clearInterval(this.state.idIntervalle);
    //       } else if (result.statusCode == "1000") {
    //         // si le paiyement est en cours
    //         this.setState({});
    //       } else if (result.statusCode == "01035") {
    //         // si le service est indisponible
    //         this.setState({
    //           isLoading: false,
    //           message:
    //             "Le service a été temporairement interempu réessayé svp.",
    //         });
    //         clearInterval(this.state.idIntervalle);
    //       } else {
    //         // Pour les autres erreurs
    //         this.setState({
    //           isLoading: false,
    //           message: "Une erreur c'est produite réessayé svp.",
    //         });
    //         clearInterval(this.state.idIntervalle);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("error", error);
    //       this.setState({
    //         isLoading: false,
    //         message: "Le service a été temporairement interempu réessayé svp.",
    //       });
    //       clearInterval(this.state.idIntervalle);
    //       //   this.setState({
    //       //     isLoading: false,
    //       //   });
    //     });
    // }
        console.log("this.state.idTransaction:", this.state.idTransaction)
    // Vérification du paiement
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch(`https://mobilepay.kwaber.com/payment/mtn/status?referenceID=${this.state.idTransaction}`, requestOptions)
        .then(response => {
          // console.log("response:", response)
          return response.json()
        })
        .then(result => {
            console.log("responseResult:", result)
            this.setState({
                        codeStatus: result.status,
                      });
           if (result.status == "SUCCESSFUL") {
            // si le paiyement a bien été réalisé
            let day = new Date();
            clearInterval(this.state.idIntervalle);
            this.reserveCreneau();
            this.setState({
              isLoading: false,
              idTransaction: result.financialTransactionId,
              codeStatus: result.status,
              datePaiement: day,
              message: "Paiement effectué avec succès",
              //  idTransaction: result.payment.transactionId,
              //  codeStatus: result.payment.statusCode,
              //  datePaiement: result.payment.createdAt
            });
            // swal(result.statusText);
            this.reserveCreneau();
            this.reserveConsultation();
            this.insererRDV();
            // this.createPaymode();
            this.paiementInfo();
            // setTimeout(() => {
            //   this.props.history.push("/patient/airtel/book/success");
            // }, 3000);
            sessionStorage.setItem(
              "userCommande",
              JSON.stringify({
                idCommande: this.state.idTransaction,
                modePaiement: "Mobile Money",
                datePaiement: moment(this.state.datePaiement).format(
                  "YYYY-MM-DD"
                ),
                //   state: true,
              })
            );

           }else if(result.status === "FAILED"){
                this.setState({
                    isLoading: false,
                    message: "La transaction n'a pas  aboutie.",
                  });
                  clearInterval(this.state.idIntervalle);
            }else if (result.status == "PENDING") {
                // si le service est indisponible
                // this.setState({
                //   isLoading: false,
                //   message:
                //     " Le paiement est en attente de confirmation, tapez *105# pour validé le paiement .",
                // });
                // clearInterval(this.state.idIntervalle);
              } else {
                // Pour les autres erreurs
                this.setState({
                  isLoading: false,
                  message: "Une erreur c'est produite réessayé svp.",
                });
                clearInterval(this.state.idIntervalle);
              }
            // console.log(result)
        })
        .catch(error => {
            console.log('error', error)
            this.setState({
                isLoading: false,
                message: "Le service a été temporairement interempu réessayé svpH.",
              });
              clearInterval(this.state.idIntervalle);
        });

    
  };

  reserveCreneau = () => {
    const idsCreneaux = JSON.parse(window.sessionStorage.getItem("rdv")).idCRDI;
    const idUser = JSON.parse(window.sessionStorage.getItem("user")).user
      .patient.id;

    this.setState({
      idsCreneauReserve: idsCreneaux.idcreneau,
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      idpatient: idUser,
      ids: idsCreneaux[0].idcreneau,
      // "ids": this.state.idsCreneauReserve
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    // console.log("RAW", raw)

    fetch(host + "/creneau/reserve", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("reserveCreneau: ", result);
        // console.log("TEXPPPP: ", idsCreneaux.idcreneau);
      })
      .catch((error) => console.log("error", error));
  };
  // Créatio de la consultation
  reserveConsultation = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      idpatient: this.state.idPatient,
      idprofessionnelsante: this.state.idPro,
      dateconsultation: this.state.creneauDate,
      heuredebutconsultation: this.state.heureDebut,
      commentaireclinique: "commentaire d'une clinique",
      teleconsultation: "true",
      idprestation: this.state.prestationId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(host + "/consultation/create/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("reserveConsultation: ", result)
      })
      .catch((error) => console.log("error", error));
  };

  //création du modepaiement
  createPaymode = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      slug: "paiement_effectue",
      libelle: "MOBILE MONEY",
      airtelmoneyNumtel: this.state.numero,
      mtnmoneyNumtel: "",
      cbXxx: "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(host + "/paymode/create", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("PAIEMENT_MODE",result)
      })
      .catch((error) => console.log("error", error));
  };

  //Sauvegarde paiement informations
  // paiementInfo1 = () => {
  //   const idUser = JSON.parse(window.sessionStorage.getItem("user")).user
  //     .patient.id;
  //   var myHeaders = new Headers();
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     mode: "AIRTEL",
  //     phoneNumber: this.state.numero,
  //     transactionId: this.state.idTransaction,
  //     statusCode: this.state.codeStatus,
  //     montant: this.state.prixXAF,
  //     idpatient: idUser,
  //     idtypepaiement: 1,
  //     idstatutpaiement: 2,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   fetch(host + "/paiement/create", requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       // console.log("PAIEMENt_RESULT: ",result)
  //     })
  //     .catch((error) => console.log("error", error));
  // };

  paiementInfo = () => {
    console.log("paiementInfo1")
    const nomUser = JSON.parse(window.sessionStorage.getItem("user")).user
      .patient.nom;
      const prenomUser = JSON.parse(window.sessionStorage.getItem("user")).user
      .patient.prenom;

    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");



var raw = JSON.stringify({
  "professionnelSante": this.state.professionnel.idcentre ? this.state.idCentre : this.state.idPro,
  "patient": this.state.idPatient,
  "prestation": this.state.prestation ? this.state.prestation : "",
  "prixPrestation": this.state.prixXAF,
  "phoneNumber": this.state.numero,
  "transactionId": this.state.idTransaction,
  "statut":"payé",
  "modePaiement": "MTN MONEY"
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(host+"/paiement/create", requestOptions)
  .then(response => response.json())
  .then(result => console.log("resultpaiementInfo: ",result))
  .catch(error => console.log('errorpaiementInfo: ', error));
  }
  // ************fin Sauvegarde paiement informations***********************

  async payNow(e) {
    e.preventDefault();

    // this.setState(
    //     {
    //         isLoading: true
    //     }
    // );
    // e.preventDefault()
    // let phone = this.phoneRef.current.value
    // let url = "https://kwaber-paiement-api.herokuapp.com";
    // if (phone.trim() != "") {
    //     let response = await fetch(url + "/airtel/pay", {
    //         "method": "POST",
    //         body: JSON.stringify({
    //             phoneNumber: phone,
    //             montant: 25
    //         }),
    //         "headers": {
    //             "Content-Type": "application/json"
    //         }
    //     })
    //     if (response.status == 200) {
    //         let data = await response.json()
    //         data = data.ussd
    //         sessionStorage.setItem("ussd",
    //             data.substr(data.indexOf("*"), data.lastIndexOf("#") - 2)
    //         )

    //         this.props.history.push("/patient/airtel/book/success")
    //     } else {
    //         this.setState({
    //             error: "Erreur lors d'un paiement de la transaction"
    //         })
    //     }
    // } else {
    //     this.setState({
    //         error: "Numéro de téléphone invalide"
    //     })
    // }

    // this.setState(
    //     {
    //         isLoading: false
    //     }
    // );
  }

  async componentDidMount() {
    const info = JSON.parse(window.sessionStorage.getItem("user"));
    const infoRdv = JSON.parse(window.sessionStorage.getItem("rdv"));
     let idPro = infoRdv.idPro ? infoRdv.idPro : null;
     let idCentre = infoRdv.idCentre ? infoRdv.idCentre : null;
      console.log("infoRDVLombre: ",infoRdv)
 
      let condition = infoRdv.idPro ? `/ps/${infoRdv.idPro}` : `/centres/${infoRdv.idCentre}`;
 
     let pfSanteResponse = await fetch(host + condition );

    // let pfSanteResponse = await fetch(host + `/ps/${infoRdv.idPro}`);

    // if (pfSanteResponse.status == 200) {
    //     let data = await pfSanteResponse.json()
    //     this.setState({
    //         professionnel: data,
    //         urlPhoto: data.photo[0].url,
    //         isLoading: false
    //     });
    // }
    if (pfSanteResponse.status == 200) {
      let data = await pfSanteResponse.json();
      this.setState({
        professionnel: data,
        urlPhoto: data.user ? data.user.photo : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" ,
        isLoading: false,
        creneau: infoRdv.idCRDI[0],
        prestation: infoRdv.prestation.libelle,
        prix: infoRdv.prix,
        prixXAF: infoRdv.prixXAF,
        idPro: idPro,
        idCentre: idCentre,
        heureDebut: infoRdv.idCRDI[0].heuredebut,
        heureFin: infoRdv.idCRDI[0].heurefin,
        creneauId: infoRdv.idCRDI[0].idcreneau,
        proId: infoRdv.idPro,
        prestationId: infoRdv.prestation.id,
        patientId: info.user.patient.id,
        creneauDate: infoRdv.idCRDI[0].datecreneau,
      });
    }

    const infoPatient = JSON.parse(window.sessionStorage.getItem("user"));
    const idPatient = infoPatient.user.patient.id;
    this.setState({
      idPatient: idPatient,
    });

    let patientResponse = await fetch(host + `/patient/${idPatient}`);

    if (patientResponse.status == 200) {
      let dataP = await patientResponse.json();
      this.setState({
        patient: dataP,
      });
    }
  }

  ecrireDate(date) {
    //2021-03-30
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    var str = new Date(date);
    //var tab = str.split("-");

    var jour = listeJours[str.getDay()];
    var mois = listeMois[str.getMonth()];

    return jour + " " + str.getDate() + " " + mois + " " + str.getFullYear();
  }

  heureCreneau = (heure) => {
    var str = heure;
    var tab = str.split(":");
    return tab[0] + "h" + tab[1];
  };

  insererRDV() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    moment.locale();

    var raw = JSON.stringify({
      daterdv: this.state.creneauDate,
      heurerdv: this.state.heureDebut,
      idcentre:this.state.idCentre,
      idpatient: this.state.idPatient,
      idprofessionnelsante: this.state.idPro ,
      idprestation: this.state.prestationId,
      idcreneau: this.state.creneauId,
      // "datepaiement":  moment(this.state.datePaiement).format('LLLL')
      datepaiement: moment(this.state.datePaiement).format("YYYY-MM-DD"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(host + "/rdv/create/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("RDV_Créé",result)
      })
      .catch((error) => console.log("error", error));
    // axios({
    //     method: 'post',
    //     url: 'https://kwaber-sante.herokuapp.com/RDVS',
    //     data: {
    //         dateRDV: 'Finn',
    //         heureRDV: 'Williams',
    //         commentaire: "",
    //         profesionnel_de_sante: "",
    //         creneau: "",
    //     }
    // });
  }

  handleChange = () => {
    this.props.history.push("/patient/booking-success");
  };

  cancel = () => {
    this.setState({
      isVisible: false,
      message: "",
    });
  };

  showModal = () => {
    this.setState({
      isVisible: true,
    });
  };

  reessayer = () => {
    this.state.idIntervalle = setInterval(() => {
      this.handleCheckPay();
    }, 5000);
    this.setState({
      isLoading: true,
      message: "",
    });
  };

  continuer = () => {
    this.props.history.push("/patient/airtel/book/success");
  };

  render() {
    return (
      <div>
        <ModalAntd
          title="Vérification de l'état du paiement"
          visible={this.state.isVisible}
          //   onCancel={this.state.isLoading && this.state.codeStatus == "200" ? null : this.cancel}
          footer={[
            [
              ...(this.state.codeStatus != "SUCCESSFUL"
                ? [
                    <Button
                      key="back"
                      onClick={this.state.isLoading ? null : this.cancel}
                      disabled={this.state.isLoading}
                    >
                      Retour
                    </Button>,

                    <Button
                      key="back"
                      onClick={this.state.isLoading ? null : this.reessayer}
                      disabled={this.state.isLoading}
                    >
                      Réessayer
                    </Button>,
                  ]
                : []),
            ],
            [
              ...( this.state.codeStatus === "SUCCESSFUL"
                ? [
                    <Button
                      key="submit"
                      type={"primary"}
                      disabled={this.state.isLoading}
                      loading={this.state.isLoading}
                      onClick={this.state.isLoading ? null : this.continuer}
                    >
                      Continuer
                    </Button>,
                  ]
                : []),
            ],
          ]}
          destroyOnClose={true}
        >
          <p>
            {/* <Loader
              style={{
                position: "absolute",
                textAlign: "center",
                marginLeft: "auto",
                left: "0",
                right: "0",
                marginRight: "auto",
                zIndex: "20px",
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            /> */}
            <Spin
              size="large"
              spinning={this.state.isLoading}
              style={{ marginLeft: "45%" }}
            />
          </p>
          <p>{this.state.messageSucces}</p>
          <p
            style={{ color: this.state.codeStatus == "SUCCESSFUL" ? "green" : "red" }}
          >
            {this.state.message}
          </p>
        </ModalAntd>

        <div
          className={
            this.state.isLoading ? "content page_wrapper_blur" : "content"
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-lg-8">
                <div className="card">
                  <div className="card-body">
                    <div className="blog-details-area ptb-70">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-8 col-md-12">
                            <div className="blog-details">
                              {/* "article-content" */}
                              <div
                                className={
                                  this.state.isLoading
                                    ? "content page_wrapper_blur"
                                    : "content"
                                }
                              >
                                <h3 className="widget-title">
                                 Paiement par MTN Money
                                </h3>
                                <br />
                                <h6>Numéro de téléphone MTN Money</h6>
                                <br />
                               

                                <div className="sr-combo-inputs">
                                  <div className="form-group row">
                                    <div className="col-sm-8">
                                      {this.state.codeStatus != "200" ? (
                                        <input
                                          type="phone"
                                          value={this.state.numero}
                                          required
                                          className="form-control"
                                          placeholder="Ex: 066785678"
                                          onChange={this.handleChangeInput}
                                          maxLength="9"
                                        />
                                      ) : (
                                        <input
                                          disabled
                                          type="phone"
                                          value={this.state.numero}
                                          required
                                          className="form-control"
                                          placeholder="Ex: 066785678"
                                          onChange={this.handleChangeInput}
                                          maxLength="9"
                                        />
                                      )}

                                      <span style={{ color: "red" }}>
                                        {" "}
                                        {this.state.erreur}{" "}
                                      </span>
                                      {/* <span style={{ color: "green" }}>
                                        {" "}
                                        <b>{this.state.messageSucces}</b>{" "}
                                      </span> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  {this.state.codeStatus == "200" ? (
                                    <button
                                      onClick={this.handleSubmit}
                                      disabled
                                      className="btn  btn-bluefonce mr-3"
                                    >
                                      Payer maintenant
                                    </button>
                                  ) : (
                                    <button
                                      onClick={this.handleSubmit}
                                      className="btn  btn-bluefonce mr-3"
                                    >
                                      Payer maintenant
                                    </button>
                                  )}

                                  {/* {this.state.etatSendPaie == false ? (
                                    <button
                                      disabled
                                      onClick={this.handleCheckPay}
                                      className="btn  btn-bluefonce"
                                    >
                                      Vérifiez le paiement
                                    </button>
                                  ) : (
                                    <button
                                      onClick={this.handleCheckPay}
                                      className="btn  btn-bluefonce"
                                    >
                                      Vérifiez le paiement
                                    </button>
                                  )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                  {
                     this.state.professionnel.length === 0 ? 
                     <div className="spin col-md-5 col-lg-4 theiaStickySidebar">
                       <Spin />
                     </div> :
                      <div className="col-md-5 col-lg-4 theiaStickySidebar">
                      <div className="card booking-card">
                        <div className="card-header">
                          <h4 className="card-title">Résumé du rendez-vous </h4>
                        </div>
                        <div className="card-body">
                          <div className="booking-doc-info">
                            <Link
                              to={this.state.professionnel.idcentre ? `/centreProfil/${this.state.professionnel.idcentre} ` : `/patient/doctor-profile/${this.state.professionnel.idprofessionnelsante}`}
                              className="booking-doc-img"
                            >
                             {
                            this.state.professionnel.idcentre ? 
                            <Image
                            width={150}
                            // src="https://cdn0.iconfinder.com/data/icons/medical-and-clinical-healthcare/78/5-512.png"                              
                            src={
                              this.state.professionnel.photo ? this.state.professionnel.photo  : "https://cdn0.iconfinder.com/data/icons/medical-and-clinical-healthcare/78/5-512.png"                              
                            }
                            className="img-fluid"
                            style={{ borderRadius: "2%" }}
                            alt={this.state.professionnel.libelle}
                          />
                              :
                              <img
                              src={
                                this.state.urlPhoto
                                  ? this.state.urlPhoto
                                  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                              }
                              alt="User"
                            />
                          }
                            </Link>
                            <div className="booking-info">
                              <h4>
                                <Link
                                  to={this.state.professionnel.idcentre ? `/centreProfil/${this.state.professionnel.idcentre} ` : `/patient/doctor-profile/${this.state.professionnel.idprofessionnelsante}`}
                                  >
                                                             {this.state.professionnel.libelle ? this.state.professionnel.libelle : this.state.professionnel.nom+" "+this.state.professionnel.prenom }
      
                                </Link>
                              </h4>
                              <h4 className="doc-department">
                              {this.state.professionnel.specialite == null ||
                                this.state.professionnel.specialite == undefined
                                  ? this.state.professionnel.adresse
                                  : this.state.professionnel.specialite.libelle}
                              </h4>
                              <div>
                                <p className="doc-location">
                                  {/* <i className="fas fa-map-marker-alt"></i>{this.state.professionnel.ville.libelle} */}
                                </p>
                              </div>
      
                              {/* <div className="rating">
                                <i className="fas fa-star filled"></i>
                                <i className="fas fa-star filled"></i>
                                <i className="fas fa-star filled"></i>
                                <i className="fas fa-star filled"></i>
                                <i className="fas fa-star"></i>
                                <span className="d-inline-block average-rating">35</span>
                              </div> */}
                              {/* <div className="clinic-details">
                                {
                                                                  this.state.professionnel.centres.map((centre) => {
                                                                      return <div key={"centre" + centre.id}>
                                                                          <div>
                                                                              <p className="doc-location">
                                                                                  <i className="fas fa-map-marker-alt"></i>{"  " + centre.libelle}
                                                                              </p>
                                                                          </div></div>
                                                                  })
                                                              }
                                {(this.state.centre == 0) ?
                                  <div>
                                    <p className="doc-location">
                                      <i className="fas fa-map-marker-alt"></i>{"  " + "Tous les centres"}
                                    </p>
                                  </div> :
                                  <div>
                                    <p className="doc-location">
                                      <i className="fas fa-map-marker-alt"></i>{"  " + this.state.centre.libelle}
                                    </p>
                                  </div>
                                }
                              </div> */}
                            </div>
                          </div>{" "}
                          <br />
                          <br />
                          <br />
                          <div className="booking-summary">
                            <div className="booking-item-wrap">
                              <ul className="booking-date">
                                <li>
                                  Date{" "}
                                  <span>
                                    {this.ecrireDate(this.state.creneau.datecreneau)}
                                  </span>
                                </li>
      
                                {/* <h5> <strong>Date</strong></h5> 
                                {
                                  this.state.creneau.map((creneaux) => {
                                        return(
                                          <li>{this.ecrireDate(creneaux.datecreneau)}, Heure: <strong>{creneaux.heuredebut} - {creneaux.heurefin}</strong></li>
                                        )
                                      })
                                    } */}
                              </ul>
                              <ul className="booking-fee">
                                {/* <h5> <strong></strong></h5>   */}
                                <li>
                                  Prestation <span>{this.state.prestation}</span>{" "}
                                </li>
      
                                <li>
                                  Montant
                                  <span>
                                    {numStr(this.state.prixXAF,'.') + " XAF "}/
                                    {this.state.prix + " € "}
                                  </span>
                                </li>
                              </ul>
                              <div className="booking-total">
                                <ul className="booking-total-list">
                                  <li>
                                    <span>Total</span>
                                    <span className="total-cost">
                                      {numStr(this.state.prixXAF,'.') + " XAF "}/
                                      {this.state.prix + " € "}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
             
            </div>
          </div>
        </div>
        <Loader
          style={{ position: "absolute", top: "50%", left: "50%" }}
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
          visible={this.state.isLoading && !this.state.isVisible}
        />
      </div>
    );
  }
}

export default CheckoutMtn;