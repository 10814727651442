import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Table } from "antd";
import { Modal } from 'react-bootstrap';
import SidebarNav from "../sidebar";
import host from "../../../host";
import moment from "moment";

import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import IMG01 from "../../assets/images/doctor-thumb-01.jpg";
import IMG02 from "../../assets/images/doctor-thumb-02.jpg";
import IMG03 from "../../assets/images/doctor-thumb-03.jpg";
import IMG04 from "../../assets/images/doctor-thumb-04.jpg";
import IMG05 from "../../assets/images/doctor-thumb-01.jpg";
import IMG06 from "../../assets/images/doctor-thumb-02.jpg";
import IMG07 from "../../assets/images/doctor-thumb-03.jpg";


class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
        show: null,
        idUser:"",
      data: [],
    };
  }

  getPayment = () => {
    var myHeaders = new Headers();
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(host+"/paiement", requestOptions)
      .then(response => {
          if(response.status == 200){
            return response.json();
          }
      })
      .then(result => {
        let rows = [];
        
        for (let index = 0; index < result.length; index++) {
          const element = result[index];
          if(element.idcentre2 == null || element.idcentre2 == undefined || element.idcentre2 == ""){
            rows.push({
              idpaiement:element.idpaiement,
              centre: "---",
              patient: element.idpatient2.nom+" "+element.idpatient2.prenom,
              professionnel: element.idprofessionnelsante.nom+" "+element.idprofessionnelsante.prenom,
              patientimg: IMG01,
              montant: element.prixPrestation+" XAF",
              modePaiement: element.modePaiement,
              date: moment(element.datepaiement).format("YYYY-MM-DD hh:mm:ss"),
              status: element.statut
          })
          }
          if(element.idprofessionnelsante == null || element.idprofessionnelsante == undefined || element.idprofessionnelsante == ""){
            rows.push({
              idpaiement:element.idpaiement,
              centre: element.idcentre2.libelle,
              patient: element.idpatient2.nom+" "+element.idpatient2.prenom,
              professionnel: "---",
              patientimg: IMG01,
              montant: element.prixPrestation+" XAF",
              modePaiement: element.modePaiement,
              date: moment(element.datepaiement).format("YYYY-MM-DD hh:mm:ss"),
              status: element.statut
          })
          }
          
        }
        this.setState({
            data: rows
        })
      })
      .catch(error => console.log('error', error));


  }

  componentDidMount() { 
    this.getPayment();
   }

  handleClose=()=>{
    this.setState({
        show:false
    });
}
    handleDelete = () =>{

       var myHeaders = new Headers();

      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(host+`/paiement/delete/${this.state.idUser}`, requestOptions)
        .then(response => {
          if(response.status == 200){
             return response.json() 
          }
        })
        .then(result => {
          this.handleClose();
          this.getPayment();
           console.log(result)
        }
        )
        .catch(error => console.log('error', error));

          }

handleShow=(id)=>{
    this.setState({
        show: true,
        idUser: id
    });
}
  render() {
    const { data } = this.state;

    const columns = [
      {
        title: "Centre",
        dataIndex: "centre",
        // render: (text, record) => (
        
        //       <Link to="/admin/invoice" className="invoice-link">{text}</Link>
            
        //   ),
        sorter: (a, b) => a.id.length - b.id.length,
      },
      {
        title: "Patient",
        dataIndex: "patient",
        sorter: (a, b) => a.patientid.length - b.patientid.length,
      },
      {
        title: "Professionnel de santé",
        dataIndex: "professionnel",
        render: (text, record) => (
          <h2 className="table-avatar">
            <a
              className="avatar avatar-sm mr-2"
            >
              {/* <img alt="" src={record.patientimg} /> */}
            </a>
            <h6>{record.professionnel}</h6>
          </h2>
        ),
        sorter: (a, b) => a.professionnel.length - b.professionnel.length,
      },
      {
        title: "Montant",
        dataIndex: "montant",
        sorter: (a, b) => a.montant.length - b.montant.length,
      },
      {
        title: "Mode de paiement",
        dataIndex: "modePaiement",
        sorter: (a, b) => a.modePaiement.length - b.modePaiement.length,
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => a.date.length - b.date.length,
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record)=>(
        <span className="badge badge-pill bg-success inv-badge">{record.status}</span>
        ),
        sorter: (a, b) => a.status.length - b.status.length,
      },
    {
        title: 'Action',
        dataIndex: "idpaiement",
        render: (text, record) => (
            <div className="actions">
              
                <a href="#0"className="btn btn-sm bg-danger-light" onClick={()=>this.handleShow(record.idpaiement)}><i className="fe fe-trash"></i> Delete</a>
            </div>
        ),
       
      }
    ];

    return (
      <>
        <SidebarNav />

        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Transactions</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/admin">Dashboard</a>
                    </li>
                    <li className="breadcrumb-item active">Transactions</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        className="table-striped"
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={data}
                        rowKey={(record) => record.id}
                        showSizeChanger={true}
                        pagination={{
                          total: data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
				   {/* Delete Modal */}
				 <Modal show={this.state.show === true} onHide={this.handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Suppression</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
					
							<div className="form-content p-2">
								<h4 className="modal-title"></h4>
								<p className="mb-4">Voulez-vous vraiment supprimer?</p>
								<button type="button" onClick={this.handleDelete} className="btn btn-primary">validé </button>
								<button type="button" onClick={this.handleClose} className="btn btn-danger" data-dismiss="modal">annulé</button>
							</div>
					
                        </Modal.Body>
                    </Modal>
				  {/* Delete Modal */}
        </div>
      </>
    );
  }
}

export default Transaction;
