import React, { Component } from "react";
import DashboardSidebar from "../sidebar/sidebar.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import host from "../../../../../host";

import StickyBox from "react-sticky-box";
class Password extends Component {
  constructor(props) {
    super(props);
    this.email = React.createRef();
    //this.password = React.createRef();
    //this.confirmPassword = React.createRef();
    //this.oldPassword = React.createRef();
    this.state = {
      password: "",
      confirmPassword: "",
      oldPassword: "",
      isLoading: false,
      messageError: undefined,
      email: "",
      idUser: null,
      token: "",
    };
    this.submit = this.submit.bind(this);
  }
  componentDidMount() {
    document.body.classList.add("account-page");
    let dbUser =
      JSON.parse(sessionStorage.getItem("user")) ||
      JSON.parse(sessionStorage.getItem("user"));

    if (dbUser == null || dbUser == undefined) {
      this.props.history.push("/login");
    }
    this.setState({
      email: dbUser.user.email,
      idUser: dbUser.user.id,
      token: dbUser.token,
    });

    document.body.classList.add("account-page");
    console.log("recuperer: ", dbUser.token);
  }

  // validate = () =>{
  // 	var reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
  // 	var test = reg.test(this.state.password);
  // 	if (test) {
  // 		this.setState({messageError: undefined});
  // 	}else{
  // 		this.setState({messageError: "Le mot de passe doit contenir au moins (une majuscule, une minuscule et un chiffre). Taille minimale 8 caractères."})
  // 		toast.error("Erreur format mot de passe");
  // 		return false;
  // 	}
  // }
  componentWillUnmount() {
    document.body.classList.remove("account-page");
  }

  submit(event) {
    event.preventDefault();
    let oldPassword = this.state.oldPassword;
    this.setState({
      isLoading: true,
    });

    var myHeaders = new Headers();
    myHeaders.append("auth", `${this.state.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(host + "/auth/change-password/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("changeP:", result);
        toast.success("le mot de passe à été modifier avec succès !", {});
      })
      .catch((error) => {
        toast.error("L'ancien mot de passe est invalide");
        console.log("erro:", error);
      });

    // if(this.state.messageError !== undefined){
    // 	return false;
    // }

    // if(
    // 	!(oldPassword.trim() === "")) {

    // 		var raw = JSON.stringify({
    // 			"identifier": this.state.email,
    // 			"password": this.state.oldPassword,
    // 		});

    // 		var requestOptions = {
    // 		method: 'POST',
    // 		headers: {
    // 			"content-type": "application/json"
    // 		},
    // 		body: raw,
    // 		redirect: 'follow'
    // 		};

    // 		fetch("https://kwaber-sante.herokuapp.com/auth/local", requestOptions)
    // 		.then(response => {
    // 			if(response.status == 200)
    // 			{
    // 				var myHeaders = new Headers();
    // 				myHeaders.append("Authorization", `Bearer ${this.state.token}` );
    // 				myHeaders.append("Content-Type", "application/json");

    // 				var raw = JSON.stringify({"password":this.state.password});

    // 				var requestOptions = {
    // 				method: 'PUT',
    // 				headers: myHeaders,
    // 				body: raw,
    // 				redirect: 'follow'
    // 				};

    // 				if(this.state.password == this.state.confirmPassword)
    // 				{
    // 					fetch(`https://kwaber-sante.herokuapp.com/users/${this.state.idUser} `, requestOptions)
    // 					.then(response => {
    // 						if(response.status == 200)
    // 						{
    // 							toast.success("Mot de passe modifié avec succès")
    // 							return response.text();
    // 						}})
    // 					.then(result => console.log(result))
    // 					.catch(error => console.log('error', error));
    // 				}else
    // 				{
    // 					toast.error("Confirmation du nouveau mot de passe eronnée")
    // 				}

    // 			} else {
    // 				toast.error("Ancien mot de passe invalide")
    // 				console.log(response)
    // 				return null
    // 			}
    // 		})
    // 		.then(result => console.log(result))
    // 		.catch(error => console.log('error', error));
    // 	}
  }

  render() {
    const boutonFormulaire =
      this.state.oldPassword === "" ||
      this.state.password === "" ||
      this.state.password.length < 6 ||
      this.state.confirmPassword === "" ||
      this.state.password !== this.state.confirmPassword ? (
        <button disabled type="submit" className="btn btn-bluefonce ">
          Enregistrer
        </button>
      ) : (
        <button type="submit" className="btn btn-bluefonce ">
          Enregistrer
        </button>
      );

    return (
      <div>
        {/* <div className="breadcrumb-bar">
								<div className="container-fluid">
									<div className="row align-items-center">
										<div className="col-md-12 col-12">
											<nav aria-label="breadcrumb" className="page-breadcrumb">
												<ol className="breadcrumb">
													<li className="breadcrumb-item"><a href="/home">Accueil</a></li>
													<li className="breadcrumb-item active" aria-current="page">Paramètres du profil</li>
												</ol>
											</nav>
											<h2 className="breadcrumb-title">Paramètres du profil</h2>
										</div>
									</div>
								</div>
							</div> */}
        <div className="content">
          <ToastContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <DashboardSidebar />
                </StickyBox>
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="card">
                  <div className="card-body">
                    <form id="form-1" onSubmit={this.submit}>
                      <div className="form-group">
                        <label for="current-password">
                          Ancien mot de passe
                        </label>
                        <input
                          id="current-password"
                          name="oldPassword"
                          value={this.state.oldPassword}
                          onChange={(e) =>
                            this.setState({ oldPassword: e.target.value })
                          }
                          type="password"
                          className="form-control"
                          placeholder="Votre ancien mot de passe"
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label for="new-password">Nouveau mot de passe</label>
                        <p>
                          <input
                            name="password"
                            value={this.state.password}
                            onChange={(e) =>
                              this.setState({ password: e.target.value })
                            }
                            id="new_password"
                            type="password"
                            className="form-control"
                            placeholder="Votre nouveau mot de passe"
                          />
                        </p>
                        <em className="etoile">
                          {" "}
                          le mot de passe doit contenir au moins 6 caractères ou
                          plus{" "}
                        </em>
                      </div>
                      <div className="form-group">
                        <label>Confirmer le mot de passe</label>
                        <input
                          type="password"
                          name="confirmPassword"
                          value={this.state.confirmPassword}
                          className="form-control"
                          onChange={(e) =>
                            this.setState({ confirmPassword: e.target.value })
                          }
                          placeholder="Confirmez votre nouveau mot de passe"
                        />
                      </div>
                      <div className="submit-section">{boutonFormulaire}</div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Password;
