import React, {useEffect, useState} from 'react';
import "./checkmail.css";
import validate from "../../assets/images/coche.png";
import host from '../../../host';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from 'antd';


function CheckMail() {
 const [getUsername, setGetUsername] = useState('');
 const [getMail, setGetMail] = useState('');
 const [getToken, setGetToken] = useState('');
 const [loadings, setLoadings] = useState(false);


    useEffect(() => {
        let dbUser = JSON.parse(sessionStorage.getItem("userInfo"));
        if (!dbUser) {
          window.location.assign('/')
          document.body.classList.add('account-page');
      }
        if(dbUser){
          setGetMail(dbUser.email);
          setGetUsername(dbUser.username);
          setGetToken(dbUser.token);
        }


    },[])

    const resendMail = () =>{
      setLoadings(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          username: getUsername,
          email: getMail,
          token: getToken
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(host+"/mail/welcome", requestOptions)
          .then(response => {
            // response.json()
            if(response.status === 200){
              setLoadings(false);
              toast.success("le mail à été renvoyé avec succès !");
            }
          
          })
          .catch(error => {
            // console.log('error', error)
            setLoadings(false)
            toast.warn("echec d'envoi")
          });
    }


    return (
        <div className="container">
            <ToastContainer />
	        <div className="row"><br/>
		    <div className=" text-center jumbotron">
                <p className="text-center"> <img  src={validate} alt="" width="200px" height="200px" /> </p>
                <h1>Votre compte a été créé avec succès</h1>
                <p>Un email de confirmation a été envoyé à votre adresse email pour activer votre compte, veuillez vérifier votre boîte mail.</p><br />
                <br /><br /><p>
                  {/* <a  onClick={resendMail} class="btn btn-bluefonce btn-lg">Renvoyer le lien de vérification</a> */}
                <Button
                          type="submit"
                          className="btn btn-bluefonce"
                          loading={loadings}
                          onClick={resendMail}
                        >
                          Renvoyer le lien de vérification
                        </Button>
                </p>
            </div>
	</div>	
</div>
    )
}

export default CheckMail
