        //------ Lien de API en LOCAL -------
//  const host = "http://localhost:8000/api/v1";

       //------ Lien de API en PROD -------
const host = "https://api.sante.kwaber.com/api/v1";

      //------ Lien de API en TEST -------
//  const host = "https://api-kwaber-sante.herokuapp.com/api/v1";
// const host = "https://ks-api-node-1-jdmdi.ondigitalocean.app";
export default host;
// https://kwaber-paiement-api-jkwol.ondigitalocean.app/payment/