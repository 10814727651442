import React, { Component } from 'react';
import loginBanner from '../../../assets/images/login-banner.png';
import { Link } from 'react-router-dom';
import FadeIn from 'react-fade-in';
// import CountrySelector from "./country_list"
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import App from "../../../../utils/app_utils"
// import Moment from "moment"
import Loader from "react-loader-spinner"
import host from "../../../../host";
import { Input } from 'antd';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Button } from 'antd';



class DoctorRegister extends Component {
	constructor(props) {
		super(props)
		this.state = {
			step: 0,
			countryValue: { "value": "CG", "label": "Congo" },
			options: [],
			villes: [],
			pays: [],
			tokenUser:"",
			ville:null,
			specialites: [],
			specialite: null,
			isLoading: false,
			nom:"",
			prenom:"",
			telephone1:"",
			email:"",
			specialite:"",
			mdp: "",
			confirmMdp: ""
		}

	}

	nextStep = (e) => {
		e.preventDefault()
		this.setState({
			step: 1
		})
	}

	backStep = (e) => {
		e.preventDefault()

		this.setState({
			step: 0
		})
	}
	changeHandler = (value) => {
		this.setState({
			countryValue: value,
			villes: value.villes,
			ville: value.villes[0],
		})
	}
	changeVille = (value) => {
		this.setState({
			ville: value
		})
	}


	componentDidMount() {
	
		
		let dbUser = JSON.parse(sessionStorage.getItem("user")) || JSON.parse(sessionStorage.getItem("user"));
		if (dbUser != null || dbUser != undefined) {
			this.props.history.push('/home')

		}
		document.body.classList.add('account-page');
		


		let url = host+"/pays";

		fetch(url).then((resp) => {
			if(resp.status === 200){
			 return	resp.json()
			}
		}).then((data) => {
			let pays = [];
			

			// 	// console.log(data);
			data.forEach(item => {
				let villes = [];

				for(let index = 0;index < item.villes.length;index++){
					const ville = item.villes[index];
					villes.push({
						label: ville.libelle,
						value: ville.idville
					})
				}

				pays.push({
					label: item.libelle,
					value: item.idpays,
					villes : villes
				
				})
			});

			this.setState({
				pays:pays,
				countryValue : pays[0],
				villes : pays[0].villes,
				ville : pays[0].villes[0]
			})

		}).catch(err => console.log(err))
		this.setState({
			options: countryList().getData()
		})
		document.getElementsByTagName('body')[0].className = 'account-page';



		let lien =host+"/specialite"

		fetch(lien).then((resp) => {
			if(resp.status === 200){
				return resp.json()
			}
		
		}).then((data) => {
			let specialites = []

			data.forEach(_specialite => {
				specialites.push({
					label: _specialite.libelle,
					value: _specialite.idspecialite
				})
			});

			this.setState({
				specialites
			})

		}).catch(err => console.log(err) )
	}
	componentWillUnmount() {
		document.body.classList.remove('account-page');
	}
	// submit = (e) => {
	// 	e.preventDefault();
	// 	console.log("nom", this.state.nom);
	// 	console.log("prenom", this.state.prenom);
	// 	console.log("email", this.state.email);
	// 	console.log("spécialité", this.state.specialite.value);
	// 	console.log("ville", this.state.ville.value);
	// 	console.log("MDP", this.state.mdp);
	// }

	submit = (event) => {
		event.preventDefault();
		this.setState({
		  isLoading: true,
		})
		if(this.state.email !== ""){
		  const email = this.state.email;
		  var myHeaders = new Headers();
		  myHeaders.append("Content-Type", "application/json");
		  
		  var raw = JSON.stringify({
			"username": email.trim(),
			"password": this.state.mdp,
			"role": "PS",
			"numtel1": this.state.telephone1,
			"nom": this.state.nom,
			"prenom": this.state.prenom,
			"email": email.trim(),
			// "sexe": "masculin",
			"idville": this.state.ville.value,
			"idspecialite": this.state.specialite.value
		  });
		  
		  var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		  };
		  
		  fetch(host+"/user/register/", requestOptions)
			.then(response =>{
				  if(response.status === 200){
					return response.json();
				  }
			  if(response.status === 408){
				  // console.log("409: ",response)
				  toast.warn("L'adresse mail est déjà utilisé !");
				  this.setState({
					isLoading: false,
				  })
			  }
			  if(response.status === 409){
				// console.log("409: ",response)
				toast.warn("numéro déja utilise !");
				this.setState({
				  isLoading: false,
				})
			}
			})
			.then(response => {
			  if (response) {
			   
				// console.log("200: ",  response.token)
	
				this.setState({
				  // isLoading: true,
				  tokenUser: response.token
				});
				 var myHeaders = new Headers();
							  myHeaders.append("Content-Type", "application/json");
	
							  var raw = JSON.stringify({
								username: this.state.nom + " " + this.state.prenom,
								email: this.state.email,
								token: this.state.tokenUser
							  });
	
							  var requestOptions = {
								method: 'POST',
								headers: myHeaders,
								body: raw,
								redirect: 'follow'
							  };
	
							  fetch(host+"/mail/welcome", requestOptions)
								.then(response => {
								  // response.json()
								  if(response.status === 200){
									toast.success("Nous avons terminé votre inscription, un mail d'activation de compte à été envoyé !");
									console.log("token: ",this.state.tokenUser);
									console.log("userINFO: ",{
									  username: this.state.nom + " " + this.state.prenom,
									  email: this.state.email,
									  token: this.state.tokenUser,
									  state: true,
									});
									sessionStorage.setItem(
									  "userInfo",
									  JSON.stringify({
										username: this.state.nom + " " + this.state.prenom,
										email: this.state.email,
										token: this.state.tokenUser,
										state: true,
									  })
									);
									window.location.assign("/checkMail");
								  }
								
								})
								.catch(error => {
								  console.log('error', error)
								  toast.warn("Nous avons terminé votre inscription, L'email n'a pas pu être envoyé ")
								  sessionStorage.setItem(
									"userInfo",
									JSON.stringify({
									  username: this.state.nom + " " + this.state.prenom,
									  email: this.state.email,
									  token: this.state.tokenUser,
								
									  state: true,
									})
								  );
								});
	
								window.location.assign("/checkMail");
								 }
			})
			.catch(error => {
			  console.log('error', error)
			  this.setState({
				isLoading: false,
			  })
			});
	  }
	  else if(this.state.telephone1 !== ""){
		  const email = this.state.email;
	  
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({
			"username": email.trim(),
			"password": this.state.mdp,
			"role": "PS",
			"numtel1": this.state.telephone1,
			"nom": this.state.nom,
			"prenom": this.state.prenom,
			"email": email.trim(),
			// "sexe": "masculin",
			"idville": this.state.ville.value,
			"idspecialite": this.state.specialite.value
		});
		
		var requestOptions = {
		  method: 'POST',
		  headers: myHeaders,
		  body: raw,
		  redirect: 'follow'
		};
		
		fetch(host+"/user/register/", requestOptions)
		  .then(response =>{
			if (response.status === 200) {
			   console.log("200: ",  response)     
				//  setTimeout(() => {
				  var myHeaders = new Headers();
				  myHeaders.append("Content-Type", "application/json");
	
				  //----------BEGIN l'api de l'envoi du code d'activation du compte par SMS
				  var raw = JSON.stringify({
					"numtel1": this.state.telephone1
				  });
	
				  var requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: raw,
					redirect: 'follow'
				  };
	
				  fetch(host+"/user/sendcode", requestOptions)
					.then(response => {
					  if (response.status === 200) {
						return response.json()                          
					  }if(response.status === 417){
						return response.json()
						// toast.success("Nous avons terminé votre inscription veuillez confirmer votre compte !");
						// sessionStorage.setItem(
						//   "userPhone",
						//   JSON.stringify({
						//     phone: this.state.phone1,
							
						//   })
						// );
						//   window.location.assign("/validation");
					  }
					  
					  if(response.status === 404){
						toast.success("une erreur s'est produite");
						// console.log("404: ", response);
					  }
					})
					.then(result =>{ 
					  toast.success("Nous avons terminé votre inscription veuillez confirmer votre compte !");
					  sessionStorage.setItem(
						"userPhone",
						JSON.stringify({
						  phone: this.state.telephone1,
						  id: result.idUser   
						})
					  );
						window.location.assign("/validation");
					})
					.catch(error => console.log('error', error));
				 // -----------END l'api de l'envoi du code d'activation du compte par SMS
				// //  }, 4000);
			}
			if(response.status === 409){
				// console.log("408: ",response)
				// console.log("40: ",response)
				toast.warn("Le numéro saisi est déjà utilisé !");
				this.setState({
				  isLoading: false,
				})
			}
		  })
		  .catch(error => {
			toast.warn('une erreur s\'est produite')
			console.log('error', error)
			this.setState({
			  isLoading: false,
			})
		  });
	}
		
	  }


	handleSpecialiteChange = (value) => {
		// console.log(value,"specialite")
		this.setState({
			specialite: value
		})
	}

	componentWillUnmount() {
		document.getElementsByTagName('body')[0].className = '';
	}
	render() {
		const buttonNext = this.state.nom === "" || this.state.prenom === "" || 
		 this.state.telephone1 === "" ?  <Button disabled className="btn btn-bluefonce pull-right" style={this.state.step == 0 ? {} : { display: 'none' }} onClick={this.nextStep} type="button">Suivant</Button>
		 : 		<Button className="btn btn-bluefonce pull-right" style={this.state.step == 0 ? {} : { display: 'none' }} onClick={this.nextStep} type="button">Suivant</Button>

		 const buttonValider = this.state.specialite === "" || this.state.specialite === null || this.state.specialite === undefined ||
		 this.state.ville === "" || this.state.ville === null || this.state.ville === undefined || this.state.mdp === "" ||
		 this.state.confirmMdp === "" || this.state.mdp.length < 6 || this.state.mdp !== this.state.confirmMdp ?
		 <Button disabled className="btn btn-bluefonce pull-right m-2" style={this.state.step == 1 ? {} : { display: 'none' }} onClick={this.submit}  type="button">Valider</Button> :
		 <Button className="btn btn-bluefonce pull-right m-2" style={this.state.step == 1 ? {} : { display: 'none' }} onClick={this.submit} loading={this.state.isLoading} type="button">Valider</Button>



		return (
			<div className="content" >

				<div className="container-fluid" style={{ display: "flex", justifyContent: "center", position: "relative" }}>
					<ToastContainer />

					{/* <div className={this.state.isLoading ? "container-fluid page_wrapper_blur" : "container-fluid"}> */}
					<div>
						<div className="col-md-10">
							<div className="account-content">
								<div className="row align-items-center justify-content-center">
									<div className="col-md-7 col-lg-6 login-left">
										<img src={loginBanner} className="img-fluid" alt="Login Banner" />
									</div>
									<div className="col-md-12 col-lg-6 col-sm-12 col-12 login-right">
										<div className="login-header">
											<h1 style={{ fontSize: "25px" }}>Création de compte professionnel de santé</h1>
											<br />
											 {/* <Link to="/register" > 	 */}
											 <span style={{ color: "rgb(21, 85, 141)" }}>Êtes-vous professionnel de santé ?  </span> 
											
													<input type="radio" name="etat" id="oui" value="oui" onChange={this.handleChnge}  checked= "true"/><span className="mr-3"> Oui</span>
												<Link to="/register">
                                                 <input  type="radio" name="etat" id="non" value="non"    /> <span> Non</span>
												</Link>
											
											<br />
										</div>
										<form >
											<FadeIn visible={this.state.step == 0 ? true : false}>
												<div style={this.state.step == 0 ? {} : { display: "none" }}>
													<div className="form-group">
													<label className="focus-label" htmlFor="name">Nom <span className="etoile"> * </span> </label>
														<input className="form-control" type="text" value={this.state.nom}   onChange={(e) => this.setState({nom: e.target.value})} className="form-control floating" placeholder="Votre nom" id="name" />
													</div>
													<div className="form-group">
													<label className="focus-label" htmlFor="name">Prénom <span className="etoile"> * </span> </label>
														<input className="form-control" type="text"  value={this.state.prenom}   onChange={(e) => this.setState({prenom: e.target.value})}  className="form-control" placeholder="Votre prénom" id="prenom" />
													</div>
													<div className="form-group">
													<label className="focus-label" htmlFor="name">Téléphone <span className="etoile"> * </span> </label>
														{/* <input type="text" value={this.state.telephone1}   onChange={(e) => this.setState({telephone1: e.target.value})}  className="form-control" placeholder="Votre numéro de téléphone " id="mobile1" /> */}
														<PhoneInput
															className="form-control"															
															country={'cg'}
															value={this.state.telephone1}
															
															onChange={telephone1 => this.setState({ telephone1 })}
															inputProps={{
															name: 'Numéro de téléphone',
															required: true,
															
															}}
														/>
													</div>

													<div className="form-group">
													<label className="focus-label" htmlFor="name">Adresse mail <span className="etoile"> (optionnel) </span> </label>
														<input className="form-control" type="email" value={this.state.email}   onChange={(e) => this.setState({email: e.target.value})}  className="form-control" placeholder="Votre adresse email" id="e-mail" />
													</div>

													<div className="text-right">
														<Link  to="/login" className="forgot-link" style={{ color: "rgb(21, 85, 141)" }}>Vous avez déjà un compte?</Link>
													</div>
												</div>



											</FadeIn>

											<FadeIn visible={this.state.step == 1 ? true : false}>
												<div style={this.state.step == 1 ? {} : { display: "none" }}>

													{/** <div className="form-group">
														<input type="text" ref={this.profession} className="form-control" placeholder="Votre profession médicale" id="profession" />
													</div>*/}

													<div className="form-group">
													<label className="focus-label" htmlFor="name"> Spécialité <span className="etoile"> * </span> </label>
														<Select options={this.state.specialites} value={this.state.specialite} onChange={this.handleSpecialiteChange} placeholder=" votre spécialité" />

													</div>
													<div className="form-group ">
													<label className="focus-label" htmlFor="name">Pays <span className="etoile"> * </span> </label>
														<Select options={this.state.pays} value={this.state.countryValue} onChange={this.changeHandler} />

													</div>
													  
													  {/* Ajout de l'option select pour la ville */}

													<div className="form-group ">
													<label className="focus-label" htmlFor="name">Ville <span className="etoile"> * </span> </label>
														<Select options={this.state.villes} value={this.state.ville} onChange={this.changeVille} />
														
													</div>
													{/* <div className="form-group ">
														<input type="text" ref={this.ville} className="form-control" placeholder="Votre ville" id="ville" />
													</div> */}

													<div className="form-group">
													<label className="focus-label" htmlFor="name">Mot de passe <span className="etoile"> * </span> </label>
														<Input.Password type="password" value={this.state.mdp}   onChange={(e) => this.setState({mdp: e.target.value})}  className="form-control" placeholder="Votre mot de passe" id="password" />
														<em className="etoile">le mot de passe doit contenir au moins 6 caractèresou plus </em>
													</div>
													<div className="form-group">
													<label className="focus-label" htmlFor="name">Comfirmé le mot de passe <span className="etoile"> * </span> </label>
														<Input.Password type="password" value={this.state.confirmMdp}   onChange={(e) => this.setState({confirmMdp: e.target.value})} className="form-control" placeholder="Confirmé le mot de passe" id="password" />
													</div>
												</div>
											</FadeIn>
													<br />
													<p>
                        
														(<span className="etoile">*</span>)
														<em>le champ est obligatoire </em>
														</p>
											<br />

											{buttonValider}
											{buttonNext}
												{/* <button className="btn btn-bluefonce pull-right" style={this.state.step == 0 ? {} : { display: 'none' }} onClick={this.nextStep} type="button">Suivant</button> */}

											<Button className="btn btn-bluefonce pull-right m-2" style={this.state.step == 1 ? {} : { display: 'none' }} onClick={this.backStep} type="button">Précédent</Button>
											
										</form>

									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <Loader
						style={{ position: "absolute", textAlign: "center", marginLeft: "auto", left: "0", right: "0", marginRight: "auto" }}
						type="Puff"
						color="#00BFFF"
						height={100}
						width={100}
						visible={this.state.isLoading}
					/> */}
				</div>

			</div>
		);
	}
}

export default DoctorRegister;