import React, { Component } from "react";
import DoctorSidebar from "../sidebar";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
// import ProgressBar from "react-customizable-progressbar";
import StickyBox from "react-sticky-box";
import { MDBDataTableV5, MDBTable } from "mdbreact";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";



import {
  Icon01,
  Icon02,
  // Icon03,
  // IMG01,
  // IMG02,
  // IMG03,
  // IMG04,
  // IMG05,
  // IMG06,
} from "./img";
import host from "../../../../host";

class DoctorDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      patients: [],
      professionnel: { rdvs: [], patients: [], usersPatient: [] },
      rdv: [],
      nbreRdv:0,
      idPatientControl:"",
      idPrestaControl:"",
      prestaLibelleControl:"",
      patientNomControl:"",
      patientPrenomControl:"",
      heureDebutControl:"",
      idCreneauToReserve: "",
      heureFinControl:"",
      dateControl:"",
      datepaiement:"",
      modaliteControl:"",
      centresPs: [],
      centreControl:"",
      isCentreTrue:false,
      tabAffichage: [],
      tabAjd: [],
      tabAVenir: [],
      tabPasse: [],
      tabTT: [],
      nbPasse: 0,
      nbPtaient: 0,
      creneauAll: [],
      show: false,

      datatable: {
        columns: [
          {
            label: "Nom",
            field: "nom",
            //   sort: 'asc',
            width: 270,
          },
          {
            label: "Date de rendez-vous",
            field: "date",
            //   sort: 'asc',
            width: 200,
          },
          {
            label: "Prestation",
            field: "prestation",
            // sort: 'asc',
            width: 150,
            
          },
          {
            label: "Centre",
            field: "centre",
            // sort: 'asc',
            width: 150,
            
          },
          {
            label: "Statut",
            field: "statut",
            // sort: 'asc',
            width: 150,
          },
          {
            label: "Action",
            field: "action",
            // sort: 'asc',
            width: 150,
          }
        ],
        rows:[],     
      },
      datatablePasse: {
        columns: [
          {
            label: "Nom",
            field: "nom",
            //   sort: 'asc',
            width: 270,
          },
          {
            label: "Date de rendez-vous",
            field: "date",
            //   sort: 'asc',
            width: 200,
          },
          {
            label: "Prestation",
            field: "prestation",
            // sort: 'asc',
            width: 150,
            
          },
          {
            label: "Centre",
            field: "centre",
            // sort: 'asc',
            width: 150,
            
          },
          {
            label: "Statut",
            field: "statut",
            // sort: 'asc',
            width: 150,
          }
        ],
        rows: []
      },
      datatableFutur: {
        columns: [
          {
            label: "Nom",
            field: "nom",
            //   sort: 'asc',
            width: 270,
          },
          {
            label: "Date de rendez-vous",
            field: "date",
            //   sort: 'asc',
            width: 200,
          },
          {
            label: "Prestation",
            field: "prestation",
            // sort: 'asc',
            width: 150,
            
          },
          {
            label: "Centre",
            field: "centre",
            // sort: 'asc',
            width: 150,
            
          },
          {
            label: "Statut",
            field: "statut",
            // sort: 'asc',
            width: 150,
          }
        ],
        rows: []
      },
      datatableAll: {
        columns: [
          {
            label: "Nom",
            field: "nom",
            //   sort: 'asc',
            width: 270,
          },
          {
            label: "Date de rendez-vous",
            field: "date",
            //   sort: 'asc',
            width: 200,
          },
          {
            label: "Prestation",
            field: "prestation",
            // sort: 'asc',
            width: 150,
            
          },
          {
            label: "Centre",
            field: "centre",
            // sort: 'asc',
            width: 150,
            
          },
          {
            label: "Statut",
            field: "statut",
            // sort: 'asc',
            width: 150,
          }
        ],
        rows: []
      },
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmitControl = this.handleSubmitControl.bind(this);

    this.handleChangeDateControl = this.handleChangeDateControl.bind(this);
    this.handleChangeHeureDebutControl = this.handleChangeHeureDebutControl.bind(this);
    this.handleChangeHeureFinControl = this.handleChangeHeureFinControl.bind(this);
    this.handleChangeModaliteControl = this.handleChangeModaliteControl.bind(this);
    this.handleChangeCentreControl = this.handleChangeCentreControl.bind(this);
    
  }


   handleClose = () => {
     this.setState({
    show: false
     })
   };
   handleShow = () => {
        this.setState({
          show:true
        })
   };

   showModal = (item) => {
    console.log("showModal:",item)
    if(item.idprestation2.idcentre2){
      this.setState({
        isCentreTrue: true,
        datepaiement: item.datepaiement,
        idPatientControl: item.idpatient2.id,
        idPrestaControl: item.idprestation2.id,
        prestaLibelleControl: item.idprestation2.libelle,
        patientNomControl: item.idpatient2.nom,
        patientPrenomControl: item.idpatient2.prenom,
        modaliteControl: item.idpatient2.modalite,
        centreControl: item.idprestation2.idcentre2.libelle,
       })
       this.handleShow();
    }else{
      this.setState({
        datepaiement: item.datepaiement,
        idPatientControl: item.idpatient2.id,
        idPrestaControl: item.idprestation2.id,
        prestaLibelleControl: item.idprestation2.libelle,
        patientNomControl: item.idpatient2.nom,
        patientPrenomControl: item.idpatient2.prenom,
        modaliteControl: item.idpatient2.modalite,
       })
       this.handleShow();
    }
    }

    handleChangeDateControl(event) {
      this.setState({dateControl: event.target.value});
    }
    handleChangeHeureDebutControl(event) {
      this.setState({heureDebutControl: event.target.value});
    }
    handleChangeHeureFinControl(event) {
      this.setState({heureFinControl: event.target.value});
    }
    handleChangeModaliteControl(event) {
      if(event.target.value == "physique"){
        this.setState({
          modaliteControl: event.target.value,
          isCentreTrue: true,
        });
      }else{
        this.setState({
          modaliteControl: event.target.value,
          isCentreTrue: false
  
        });
      } 
    }
    handleChangeCentreControl(event) {
      this.setState({centreControl: event.target.value});
    }
    handleSubmitControl(event) {
      event.preventDefault();
      if ((this.state.dateControl == "" || this.state.dateControl == null ) || (this.state.heureDebutControl == "" ||
      this.state.heureDebutControl == null) || (this.state.heureFinControl == "" || this.state.heureFinControl == null)){
        toast.error("Veuillez indiquer la date, l'heure de début et l'heure de fin.");
      }else if(this.state.heureFinControl < this.state.heureDebutControl){
        toast.warning("L'heure de fin ne peut être inferieur à l'heure de debut.");
      }
      else if(this.state.modaliteControl == "physique" && (this.state.centreControl == "" || this.state.centreControl == null || this.state.centreControl == undefined)){
        toast.warning("Veuillez sélectionner un centre pour une prestation physique.");
      }
      else{
        this.updatePrestation(this.state.idPrestaControl);
        this.saveCreneaux();
        this.handleClose();
      }
      // console.log("heureDebutControl:",this.state.heureDebutControl)
      // console.log("heureFinControl:",this.state.heureFinControl)
      // console.log("dateControl:",this.state.dateControl)
     
      // this.updatePrestation(this.state.idPrestaControl);
       
    }

    //Création rdv pour le control
    saveCreneaux = async () => {
  
      var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");

              var raw = JSON.stringify({
                "datecreneau": this.state.dateControl,
                "heuredebut": this.state.heureDebutControl,
                "heurefin": this.state.heureFinControl,
                "idps": this.state.idPs,
                "idprestation": this.state.idPrestaControl,
                "idcentre": this.state.centreControl ? this.state.centreControl : null
              });

              var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
              };

              fetch(host+"/creneau/create", requestOptions)
                .then(response => {
                  if(response.status == 201){
                    return response.json();
                  }
                })
                .then(result =>{
                          console.log("resultCREATE:",result);
                          
                          this.reserveCreneau(result.creneau.idcreneau);
                          this.reserveConsultation();
                          this.insererRDV(result.creneau.idcreneau);
                          toast.success("le rendez-vous a été créé  et le mail a été envoyé au patient.");
                     
                          // this.handleClose();
                })
                .catch(error => console.log('error', error));
              
    };
    // Réservé un créneau 
    reserveCreneau = (id) => {
  
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        idpatient: this.state.idPatientControl,
        ids: id
      });
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      // console.log("RAW", raw)
  
      fetch(host + "/creneau/reserve", requestOptions)
        .then((response) => response.json())
        .then((result) => {
           console.log("reserveCreneau: ", result);
        })
        .catch((error) => console.log("error", error));
    };
      // Création du rdv de control
      insererRDV = (creneauId) => {
        console.log( "daterdv", this.state.dateControl);
        console.log("heurerdv", this.state.heureDebutControl);
        console.log("idcentre", this.state.centreControl ? this.state.centreControl : null);
        console.log( "idpatient", this.state.idPatientControl);
        console.log( "idprofessionnelsante", this.state.idPs);
        console.log("idprestation", this.state.idPrestaControl);
        console.log( "idcreneau", creneauId);
        console.log("datepaiement", this.state.datepaiement);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        moment.locale();
    
        var raw = JSON.stringify({
          daterdv: this.state.dateControl,
          heurerdv: this.state.heureDebutControl,
          idcentre: this.state.centreControl,
          idpatient: this.state.idPatientControl,
          idprofessionnelsante: this.state.idPs,
          idprestation: this.state.idPrestaControl,
          idcreneau: creneauId,
          control: true,
          datepaiement: moment(this.state.datepaiement).format("YYYY-MM-DD"),
        });
    
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
    
        fetch(host + "/rdv/create/", requestOptions)
          .then((response) => response.json())
          .then((result) => {
             console.log("RDV_Créé",result)
          })
          .catch((error) => console.log("error", error));
        
      }
    //mis à jour de la prestation
    updatePrestation = (id) => {
      
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        "idprofessionnelsante": this.state.idPs,
        "modalite": this.state.modaliteControl,
        "idcentre": this.state.centreControl ? this.state.centreControl : 0
      });
      
      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(host+`/prestations/update/${id}`, requestOptions)
        .then(response => {
          if(response.status == 201){
            return response.json();
          }
        })
        .then(result => {
          console.log("UPDATE_PRESTA",result)
        })
        .catch(error => console.log('error', error));
    }

    //création  de la consultation et l'envoi de mail
    reserveConsultation = () => {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        idpatient: this.state.idPatientControl,
        idprofessionnelsante: this.state.idPs,
        dateconsultation: this.state.dateControl,
        heuredebutconsultation: this.state.heureDebutControl,
        commentaireclinique: "commentaire d'une clinique",
        teleconsultation: "true",
        idprestation: this.state.idPrestaControl,
      });
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
  
      fetch(host + "/consultation/create/", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("reserveConsultation: ", result);
        })
        .catch((error) => console.log("error", error));
    };
  async componentDidMount() {
    const dataJson = sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const data = dataJson && JSON.parse(dataJson);
    const id = 1;
    if (!data) {
      window.location.assign("/");
    }  
    this.setState({
      idPs: data.user.professionnelsante.idprofessionnelsante
    }) 
    

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      host + `/ps/${data.user.professionnelsante.idprofessionnelsante}`,
      requestOptions
    )
      .then((response) => {
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result != null) {
          let patients = [];
          console.log("resultPS: ",result)
					for (let index = 0; index < result.creneaux.length; index++) {
						const element = result.creneaux[index];
						if(element.patient != null){
							patients.push(element.patient)
						}
					}
					//supression des doublant

					for (let i = 0; i < patients.length; i++) {
						const first = patients[i];

						for (let j = 0; j < patients.length; j++) {
							const second = patients[j];
							if(i != j){
								if(first.id === second.id){
									patients.splice(j,1);
									j--;
								}
							}
						}
						
					}
          this.setState({
            professionnel: result,
            rdv: result.rdvs,
            creneauAll: result.creneaux,
            nbPatient: patients.length,
            centresPs: result.centres,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error)
      });

    await this.remplirTableau();

 
  }

  handleSelect(key) {
    this.setState({ key });
  }

  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  ecrireDate(date) {
    //2021-03-30
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    var str = new Date(date);
    //var tab = str.split("-");

    var jour = listeJours[str.getDay()];
    var mois = listeMois[str.getMonth()];

    return str.getDate() + " " + mois + " " + str.getFullYear();
  }
  ecrireDate2(date) {
    var d = new Date(date);

    var mois = parseInt(d.getMonth());
    var jour = parseInt(d.getDate());
    mois = mois + 1;
    if (mois < 10) {
      mois = "0" + mois;
    }
    if (jour < 10) {
      jour = "0" + jour;
    }

    return d.getFullYear() + "-" + mois + "-" + jour;
  }

  

  async remplirTableau() {
    let today = [];
    let avenir = [];
    let passe = [];
    let all = [];
    let rowElement = [];
    let rowElementPasse = [];
    let rowElementFutur = [];
    let rowElementAll = [];

    let tabToday = [];
    let tabAll = [];
    let nbreRdv = [];
    let tabFutur = [];
    let tabPass = [];

      if(this.state.professionnel.rdvs.length > 0){
        for (let i = 0; i < this.state.professionnel.rdvs.length; i++) {
          const element = this.state.professionnel.rdvs[i];
          // console.log("ELEMENT-BEFOR: ",element)
          if (element.idpatient2 !== null) {
                if(element.control == false){
                  console.log("ELEMENT: ",element)
                  all.push(element);
                }
          }
        }
      }
    

    for (let i = 0; i < all.length; i++) {
      const element = all[i];
      console.log("ALL: ", all)
      const dateToday = new Date();
      const date = new Date(element.daterdv + " " + element.heurerdv);
     
      var time1 = moment(dateToday).format('YYYY-MM-DD');
      var time2 = moment(element.daterdv).format('YYYY-MM-DD');
    

      // const isToday = d === d1 && m === m1 && y === y1;

      if(time2 == time1){
        // if(element.control == false){
          today.push(element);
        // }
        
      } else if (time2 > time1){
        // if(element.control == false){
            avenir.push(element);
        // }
      } else {
        // if(element.control == false){
          passe.push(element);
        // }
      }
    }
    
    nbreRdv = all.length;
    tabToday = today.reverse();
    tabAll = all.reverse();
    tabFutur = avenir.reverse();
    tabPass = passe.reverse();
    
    
    


    for (let index = 0; index < tabToday.length; index++) {
      const element = tabToday[index];
      console.log("TODAY: ",element)
          rowElement.push({
            nom: element.idpatient2.prenom+" "+element.idpatient2.nom,
            date: element.daterdv+" "+element.heurerdv,
            prestation: element.idprestation2.libelle,
            centre: element.idprestation2.idcentre2 ? element.idprestation2.idcentre2.libelle : '---',
            statut: <span  className="btn btn-sm bg-info-light"> Confirmé</span>,
            action:   <Button variant="primary" onClick={ () => this.showModal(element)}> Créer un rendez-vous de contrôle</Button>,
    
          }) 

    }
    //tableau for pass
    for (let index = 0; index < tabPass.length; index++) {
      const element = tabPass[index];
      console.log("PASS: ",element)
      rowElementPasse.push({
        nom: element.idpatient2.prenom+" "+element.idpatient2.nom,
        centre: element.idprestation2.idcentre2 ? element.idprestation2.idcentre2.libelle : '---',
        date: element.daterdv+" "+element.heurerdv,
        prestation: element.idprestation2.libelle,
        statut: <span  className="btn btn-sm bg-info-light"> Confirmé</span>
      }) 
    }
    // //tableau for futur
    for (let index = 0; index < tabFutur.length; index++) {
      const element = tabFutur[index];
      console.log("FUTUR: ",element)
      rowElementFutur.push({
        nom: element.idpatient2.prenom+" "+element.idpatient2.nom,
        date: element.daterdv+" "+element.heurerdv,
        prestation: element.idprestation2.libelle,
        centre: element.idprestation2.idcentre2 ? element.idprestation2.idcentre2.libelle : '---',
        statut: <span  className="btn btn-sm bg-info-light"> Confirmé</span>
      }) 
    }
     //tableau for all
     for (let index = 0; index < tabAll.length; index++){
      const element = tabAll[index];
      rowElementAll.push({
        nom: element.idpatient2.prenom+" "+element.idpatient2.nom,
        date: element.daterdv+" "+element.heurerdv,
        prestation: element.idprestation2.libelle,
        centre: element.idprestation2.idcentre2 ? element.idprestation2.idcentre2.libelle : '---',
        statut: <span  className="btn btn-sm bg-info-light"> Confirmé</span>
      }) 
    }

    tabToday = today.reverse();
    tabAll = all.reverse();
    tabFutur = avenir.reverse();
    tabPass = passe.reverse();

   
    this.setState({
      tabAjd: tabToday,
      tabTT: tabAll,
      nbreRdv: all.length,
      tabAVenir: tabFutur,
      tabPasse: tabPass,
      datatable: {
        columns: this.state.datatable.columns,
        rows: rowElement
      },
      datatablePasse: {
        columns: this.state.datatablePasse.columns,
        rows: rowElementPasse 
      },
      datatableFutur: {
        columns: this.state.datatableFutur.columns,
        rows: rowElementFutur 
      },
      datatableAll: {
        columns: this.state.datatableAll.columns,
        rows: rowElementAll
      }
    });
  }
  estDansPatient(patient, tabPatient) {
    for (let i = 0; i < tabPatient.length; i++) {
      if (patient.id == tabPatient[i].id) {
        return true;
      }
    }
    return false;
  }

  render() {
    console.log("this.state.centreControl",this.state.centreControl)
    return (
      <div>
        <div className="content">
        <ToastContainer />
          {/* Modal for edit RDV Control */}
          
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Création d'un rendez-vous de contrôle</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <div className="row">
                    <div className="col-12">
                        <label><strong>Date du rendez-vous</strong></label>
                          <input  className="form-control"   type="date" value={this.state.dateControl} onChange={this.handleChangeDateControl}/>
                    </div><br/>
                    <div className="col-6"><br/>
                      <label><strong>Heure de debut</strong></label>
                    <input  className="form-control"   type="time" value={this.state.heureDebutControl} onChange={this.handleChangeHeureDebutControl}/>
                    </div>
                    <div className="col-6"><br/>
                      <label><strong>Heure de fin</strong></label>
                         <input  className="form-control"   type="time" value={this.state.heureFinControl} onChange={this.handleChangeHeureFinControl}/>
                    </div>
                    <div className="col-12"><br/>
                      <label><strong>Prestation</strong></label>
                         <input disabled  className="form-control" value={this.state.prestaLibelleControl}   type="text"/>
                    </div><br/>
                    <div className="col-12"><br/>
                      <label><strong>Modalité</strong></label>
                            <select className="form-control" value={this.state.modaliteControl} onChange={this.handleChangeModaliteControl}>
                              <option value="physique">Physique</option>
                              <option value="video">vidéo</option>
                            </select>
                    </div><br/>
                    <div className="col-12"><br/>
                    {
                      this.state.isCentreTrue ?  <><label><strong>Centre</strong></label>
                       <select className="form-control" value={this.state.centreControl} onChange={this.handleChangeCentreControl}>
                       <option value="">Selectionné votre centre</option>
                        {
                          this.state.centresPs.map((centre) =>{
                            return(
                                  <>
                                   <option value={centre.idcentre}>{centre.libelle}</option>
                                  </>
                            )
                          })
                        }
                      </select></>  :  ""
                    }
                     
                    </div><br/>
                    
                    <div className="col-12"><br/>
                      <label><strong>Patient</strong></label>
                         <input disabled  className="form-control"  value={this.state.patientNomControl+" "+this.state.patientNomControl}  type="text"/>
                    </div><br/>

                  </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Annuler
            </Button>
            <Button onClick={this.handleSubmitControl} variant="primary">
              Valider
            </Button>
          </Modal.Footer>
        </Modal>
          {/* Fin Modal for edit RDV Control */}



          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <DoctorSidebar />
                </StickyBox>
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card dash-card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12 col-lg-6">
                            <div className="dash-widget dct-border-rht">
                             
                              <div className="indicator-volume m-3">
                                <img
                                  src={Icon01}
                                  className="img-fluid"
                                  alt="Patient"
                                />
                              </div>
                              <div className="dash-widget-info">
                                <h6>Nombre de rendez-vous</h6>
                                <h3>{this.state.nbreRdv}</h3>
                                {/* <p className="text-muted">Jusqu'à aujourd'hui</p> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <div className="dash-widget">
                             
                              <div className="indicator-volume m-3">
                                <img
                                  src={Icon02}
                                  className="img-fluid"
                                  alt="Patient"
                                />
                              </div>
                              <div className="dash-widget-info">
                                <h6>Nombre de patients </h6>
                                {/* {console.log(
                                  this.state.professionnel,
                                  "le pro"
                                )} */}
                                <h3>{this.state.nbPatient}</h3>
                                {/* <p className="text-muted">Aujourd'hui</p> */}
                              </div>
                            </div>
                          </div>

                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h4 className="mb-4">Réservation(s) des patients</h4>
                    <div className="appointment-tab">
                      <Tabs
                        className="tab-view"
                        activeKey={this.state.key}
                        onSelect={this.handleSelect}
                        id="controlled-tab-example"
                      >
                        <Tab
                          className="nav-item"
                          eventKey={1}
                          title="Aujourd'hui"
                        >
                          <div className="card card-table mb-0">
                            <div className="card-body">
                                
                                { this.state.tabAjd.length === 0 ?
                               <div class="jumbotron jumbotron-fluid">
                               <div class="container">
                                 {/* <h1 class="display-4">Mes Rendez-vous</h1> */}
                                 <p class="lead">
                                   {" "}
                                   Vous n'avez pas de rendez-vous
                                 </p>
                               </div>
                             </div> :
                                <MDBTable responsive>
                                <MDBDataTableV5
                                        //   La table des prestations with mdbreact 
                                                     hover
                                                     searchTop
                                                     searchBottom={false}
                                                     entriesLabel={"Lignes par pages"}
                                                     searchLabel={"Rechercher"}
                                                     infoLabel={["", "-", "/", ""]}
                                                     noRecordsFoundLabel={
                                                       "Aucune donnée ne correspond"
                                                     }
                                                    entriesOptions={[5, 20, 25]}
                                                    entries={5}
                                                    pagesAmount={4}
                                                    data={this.state.datatable}
                                                    
                                                />
                                </MDBTable>
                                
                              }
                            </div>
                          </div>
                        </Tab>
                        <Tab className="nav-item" eventKey={2} title="A venir">
                          <div className="card card-table mb-0">
                            <div className="card-body">
                            { this.state.tabAVenir.length === 0 ?
                               <div class="jumbotron jumbotron-fluid">
                               <div class="container">
                                 <p class="lead">
                                   {" "}
                                   Vous n'avez pas de rendez-vous
                                 </p>
                               </div>
                             </div> :
                                <MDBTable responsive>
                                <MDBDataTableV5
                                        //   La table des prestations with mdbreact 
                                                     hover
                                                     searchTop
                                                     searchBottom={false}
                                                     entriesLabel={"Lignes par pages"}
                                                     searchLabel={"Rechercher"}
                                                     infoLabel={["", "-", "/", ""]}
                                                     noRecordsFoundLabel={
                                                       "Aucune donnée ne correspond"
                                                     }
                                                    entriesOptions={[5, 20, 25]}
                                                    entries={5}
                                                    pagesAmount={4}
                                                     data={this.state.datatableFutur}                                                    
                                                    
                                                />
                                </MDBTable>
                                
                              }
                            </div>
                          </div>
                        </Tab>
                        <Tab className="nav-item" eventKey={3} title="Passé">
                          <div className="card card-table mb-0">
                            <div className="card-body">
                                { this.state.tabPasse.length === 0 ?
                               <div class="jumbotron jumbotron-fluid">
                               <div class="container">
                                 {/* <h1 class="display-4">Mes Rendez-vous</h1> */}
                                 <p class="lead">
                                   {" "}
                                   Vous n'avez pas de rendez-vous
                                 </p>
                               </div>
                             </div> :
                                <MDBTable responsive>
                                <MDBDataTableV5
                                        //   La table des prestations with mdbreact 
                                                     hover
                                                     searchTop
                                                     searchBottom={false}
                                                     entriesLabel={"Lignes par pages"}
                                                     searchLabel={"Rechercher"}
                                                     infoLabel={["", "-", "/", ""]}
                                                     noRecordsFoundLabel={
                                                       "Aucune donnée ne correspond"
                                                     }
                                                    entriesOptions={[5, 20, 25]}
                                                    entries={5}
                                                    pagesAmount={4}
                                                     data={this.state.datatablePasse}
                                                    // data={[]}
                                                    
                                                />
                                </MDBTable>
                                
                              }
                            </div>
                          </div>
                        </Tab>
                        <Tab className="nav-item" eventKey={4} title="Tout">
                          <div className="card card-table mb-0">
                            <div className="card-body">
                              { this.state.tabTT.length === 0 ?
                               <div class="jumbotron jumbotron-fluid">
                               <div class="container">
                                 {/* <h1 class="display-4">Mes Rendez-vous</h1> */}
                                 <p class="lead">
                                   {" "}
                                   Vous n'avez pas de rendez-vous
                                 </p>
                               </div>
                             </div> :
                                <MDBTable responsive>
                                <MDBDataTableV5
                                        //   La table des prestations with mdbreact 
                                                     hover
                                                     searchTop
                                                     searchBottom={false}
                                                     entriesLabel={"Lignes par pages"}
                                                     searchLabel={"Rechercher"}
                                                     infoLabel={["", "-", "/", ""]}
                                                     noRecordsFoundLabel={
                                                       "Aucune donnée ne correspond"
                                                     }
                                                    entriesOptions={[5, 20, 25]}
                                                    entries={5}
                                                    pagesAmount={4}
                                                    data={this.state.datatableAll}
                                                    
                                                    
                                                />
                                </MDBTable>
                                
                              }
                             
                            </div>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DoctorDashboard;
