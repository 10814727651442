import React from "react";
import { NavLink } from "react-router-dom";
// import IMG01 from '../../../../assets/images/patient.jpg';
// import host from '../../../../../host';

export const DashboardSidebar = () => {
  const dataJson = sessionStorage.getItem("user");
  const data = dataJson && JSON.parse(dataJson);
  if (!data) {
    window.location.assign('/')
}

  const logout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("user");
    window.location.assign("/login");
  };

  return (
    <div class="profile-sidebar">
      <div class="widget-profile pro-widget-content">
        <div class="profile-info-widget">
          <a href="#0" class="booking-doc-img">
            {/* <img src={IMG01} alt="User" /> */}
            {/* {
							console.log("DATA_USER: ",data.user)
						} */}
            <img
              src={
                data.user.photo
                  ? data.user.photo
                  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
              }
            />
            {/* <img src={ data.user.photo  ?  host+`/user/images/${data.user.photo} `  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} /> */}
            {/* <img src="http://localhost:3000/image/Acer_Wallpaper_01_5000x2814.jpg" /> */}
          </a>
          <div class="profile-det-info">
            <h3>
              {data
                ? data.user.patient.prenom + " " + data.user.patient.nom
                : ""}
            </h3>
          </div>
        </div>
      </div>
      <div class="dashboard-widget">
        <nav class="dashboard-menu">
          <ul>
            <li class="">
              <NavLink to="/patient/dashboard" activeClassName="active">
                <i class="fas fa-columns"></i>
                <span>Tableau de bord</span>
              </NavLink>
            </li>
            <li>
							<NavLink to="/patient/mytansaction" activeClassName="active">
								<i class="fas fa-bookmark"></i>
								<span>Mes transactions </span>
							</NavLink>
						</li>

            <li>
              <NavLink to="/patient/profile" activeClassName="active">
                <i class="fas fa-user-cog"></i>
                <span>Paramètres du profil</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/patient/change-password" activeClassName="active">
                <i class="fas fa-lock"></i>
                <span>Changer de mot de passe</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/">
                <i class="fas fa-sign-out-alt"></i>
                <span onClick={logout}>Déconnexion</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
export default DashboardSidebar;
