import React, { Component } from "react";
import { Link } from "react-router-dom";
// import $, { data } from "jquery";
import "bootstrap-daterangepicker/daterangepicker.css";
import "../booking/style.css"
import "bootstrap";
import AirtelLogo from "../../../../client/assets/images/airtel_money.png";
import MtnLogo from "../../../../client/assets/images/mtnmomo.png";
import CartMethod from "../../../../client/assets/images/cart_bancaire.jpg";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
// import Button from 'react-bootstrap/Button';
import { Accordion, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import FadeIn from "react-fade-in";
import { Input } from "antd";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Image } from "antd";
import { Button } from 'antd';
import numStr from '../separator/separator'




// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "../../../../../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// import { Error } from "../../../components/Error404/index";
import host from "../../../../host";
import Loader from "react-loader-spinner";
import { Alert } from "antd";

var _ = require("lodash");

class BookingCentre extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nom: "",
      istrue: false,
      firstName: "",
      email: "",
      phone1: "",
      phone2: "",
      dateNaissance: "",
      adress: "",
      complementAdress: "",
      value: "",
      passwrd: "",
      confirmPassword: "",
      regle: false,
      genre: "",
      msgError: false,

      show: false,
      showModal: false,
      centre: [],
      crenaux: [],
      urlPhoto: null,
      isLoading: false,
      creneauDispo: "",
      jourDebutDeSemaine: "",
      jourAJD: "",
      methodeinfSemaine: false,
      number: 0,
      creneauSelectionne: [],
      texteCreneau: "",
      dateMin: "",

      tabLundi: [],
      tabMardi: [],
      tabMercredi: [],
      tabJeudi: [],
      tabVendredi: [],
      tabSamedi: [],
      tabDimanche: [],
      inputValue: undefined,
      heure: undefined,
      showC: false,
      loadings: false,
      isLoading: false,
      redirectToReferre: false,
      preserveLogin: true,
      passwordConnexion: "",

      lien: null,
      onlyPrestation: null,
      prixXafPrestaChoisi: "",
      prixEuroPrestaChoisi:"",
      prestation: "",
      prestaChoisiLibelle:"",   
      prestaChoisiID:"",
      prestaChoisiIDSelect:null,
      centrePretaChoisi:"",
      affichagePrestation: [],
      centreEntier: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCloseC = this.handleCloseC.bind(this);
    this.handleShowC = this.handleShowC.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.email = React.createRef();
    // this.password = React.createRef();
    this.submit = this.submit.bind(this);
  }

  handleCloseC = () => this.setState({ showC: false });
  handleShowC = () => this.setState({ showC: true });


//Authentification Login
  connexion = () => {
    let email = this.email.current.value;

    if (!(email.trim() === "")) {
      var axios = require("axios");
      var data = JSON.stringify({
        username: this.email.current.value,
        password: this.state.password,
      });

      var config = {
        method: "post",
        url: host + "/auth/login",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          // this.setState({
          // 	isLoading: true,
          // 	redirectToReferre: true
          // });
          const user = response.data;
          //   console.log("USER:",user);

          if (user.patient !== null) {
            sessionStorage.setItem(
              "user",
              JSON.stringify({
                token: user.token,
                userId: user.userId,
                user: user,
                state: true,
              })
            );

            this.handleCloseC();
            this.handleShow();
          }
          if (user.professionnelsante !== null) {
            //  window.location.assign('/doctor/doctor-dashboard');

            sessionStorage.setItem(
              "user",
              JSON.stringify({
                token: user.token,
                userId: user.userId,
                user: user,
                state: true,
              })
            );
            this.handleCloseC();
          }
        })
        .catch(function (error) {
          toast.warn("l'adresse mail ou le mot de passe est incorrect", {});
          // console.log(error);
        });
    } else {
      toast.warn("Veuillez à bien remplir tous les champs !", {});
    }
  };


  //Création d'un compte patient ou centre de santé
  submit(event) {
    event.preventDefault();
    this.setState({
      loadings: true
    })

    let email = this.state.email;
    let regMail  = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var phoneExp = /^((\+)33|0|0033)[1-9](\d{2}){4}$/g;
 

    var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "username": this.state.email,
          "password": this.state.passwordConnexion
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

  fetch(host+"/auth/login", requestOptions)
        .then(response => {
          if(response.status === 200){
            return response.json() 
            this.setState({
              loadings: false
            })
          }
          if(response.status === 401){
            toast.warn("Identifiant incorrect")
            this.setState({
              loadings: false
            })
          }
          if(response.status === 403){
            toast.warn("Votre compte n'est pas encore activé, vueillez consulter votre boite mail")
            this.setState({
              loadings: false
            })
          }
        })
        .then(result => {
          if (result.patient !== null) {
            // window.location.assign("/patient/dashboard");
            sessionStorage.setItem(
              "user",
              JSON.stringify({
                token: result.token,
                userId: result.userId,
                user: result,
                state: true,
              })
            );
            this.handleCloseC();
            this.handleShow();
          }
          if (result.professionnelsante !== null) {
                    window.location.assign("/doctor/doctor-dashboard");
                    sessionStorage.setItem(
                      "user",
                      JSON.stringify({
                        token: result.token,
                        userId: result.userId,
                        user: result,
                        state: true,
                      })
                    );
                    // this.handleCloseC();
                    // this.handleShow();
                  }
                  if (result.gestionnairecentre && result.gestionnairecentre !== null) {
                            // window.location.assign("/gestionnaire/dashboard");
                            sessionStorage.setItem(
                              "user",
                              JSON.stringify({
                                token: result.token,
                                userId: result.userId,
                                user: result,
                                state: true,
                              })
                            );
                            this.handleCloseC();
                            this.handleShow();
                          }

        })
        .catch(error =>{
          //  console.log('error', error)
            toast.warn("Erreur")
            this.setState({
              loadings: false
            })
          });
  }
 

  //Création de compte 
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      isLoading: true,
    })
    if(this.state.email !== ""){
      const email = this.state.email;
      // alert(this.state.email)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        "username": email.trim(),
        "password": this.state.passwrd,
        "role": "PATIENT",
        "slug": this.state.nom,
        "nom": this.state.nom,
        "prenom": this.state.firstName,
        "datenaissance": this.state.dateNaissance,
        "numtelephone1": this.state.phone1,
        "numtelephone2": this.state.phone2,
        "email": email.trim(),
        "sexe": this.state.genre,
        "adresse": this.state.adress,
        "groupesangin": this.state.value,
        "tokenUser": "",
        "complementadresse": this.state.complementAdress,
        // "poids": "",
        // "taille": "",
       
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(host+"/user/register/", requestOptions)
        .then(response =>{
              if(response.status === 200){
                return response.json();
              }
          if(response.status === 408){
              // console.log("409: ",response)
              toast.warn("L'adresse mail est déjà utilisé !");
              this.setState({
                isLoading: false,
              })
          }
          if(response.status === 409){
            // console.log("409: ",response)
            toast.warn("numéro déja utilise !");
            this.setState({
              isLoading: false,
            })
        }
        })
        .then(response => {
          if (response) {           
            // console.log("200: ",  response.token)
            this.setState({
              // isLoading: true,
              tokenUser: response.token
            });
             var myHeaders = new Headers();
                          myHeaders.append("Content-Type", "application/json");

                          var raw = JSON.stringify({
                            username: this.state.nom + " " + this.state.firstName,
                            email: this.state.email,
                            token: this.state.tokenUser
                          });

                          var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                          };

                          fetch(host+"/mail/welcome", requestOptions)
                            .then(response => {
                              // response.json()
                              if(response.status === 200){
                                toast.success("Nous avons terminé votre inscription, un mail d'activation de compte à été envoyé !");
                                // console.log("token: ",this.state.tokenUser);
                                // console.log("userINFO: ",{
                                //   username: this.state.nom + " " + this.state.firstName,
                                //   email: this.state.email,
                                //   token: this.state.tokenUser,
                                //   state: true,
                                // });
                                sessionStorage.setItem(
                                  "userInfo",
                                  JSON.stringify({
                                    username: this.state.nom + " " + this.state.firstName,
                                    email: this.state.email,
                                    token: this.state.tokenUser,
                                    state: true,
                                  })
                                );
                                // this.handleCloseC();
                                window.location.assign("/checkMail");
                              }
                            
                            })
                            .catch(error => {
                              // console.log('error', error)
                              toast.warn("Nous avons terminé votre inscription, L'email n'a pas pu être envoyé ")
                              sessionStorage.setItem(
                                "userInfo",
                                JSON.stringify({
                                  username: this.state.nom + " " + this.state.firstName,
                                  email: this.state.email,
                                  token: this.state.tokenUser,
                                
                                  state: true,
                                })
                              );
                            });

                            window.location.assign("/checkMail");
                             }
        })
        .catch(error => {
          console.log('error', error)
          this.setState({
            isLoading: false,
          })
        });
  }
  else if(this.state.phone1 !== ""){
  
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "username": this.state.email,
      "password": this.state.password,
      "numtel1": this.state.phone1,
      "role": "PATIENT",
      "slug": this.state.name+" "+this.state.firstName,
      "numtelephone2":this.state.phone2 ,
      // "poids": "",
      // "taille": "",
      "nom": this.state.name,
      "prenom": this.state.firstName,
      "datenaissance": this.state.dateNaissance,
      "adresse": this.state.adress,
      "complementadresse": this.state.complementAdress,
      "groupesangin": this.state.value,
      "email": this.state.email,
      "sexe": this.state.genre
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(host+"/user/register/", requestOptions)
      .then(response =>{
        if (response.status === 200) {
          //  console.log("200: ",  response)     
            //  setTimeout(() => {
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");

              //----------BEGIN l'api de l'envoi du code d'activation du compte par SMS
              var raw = JSON.stringify({
                "numtel1": this.state.phone1
              });

              var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
              };

              fetch(host+"/user/sendcode", requestOptions)
                .then(response => {
                  if (response.status === 200) {
                    return response.json()                          
                  }if(response.status === 417){
                    return response.json()
                    // toast.success("Nous avons terminé votre inscription veuillez confirmer votre compte !");
                    // sessionStorage.setItem(
                    //   "userPhone",
                    //   JSON.stringify({
                    //     phone: this.state.phone1,
                        
                    //   })
                    // );
                    //   window.location.assign("/validation");
                  }
                  
                  if(response.status === 404){
                    toast.success("une erreur s'est produite");
                    // console.log("404: ", response);
                  }
                })
                .then(result =>{ 
                  toast.success("Nous avons terminé votre inscription veuillez confirmer votre compte !");
                  sessionStorage.setItem(
                    "userPhone",
                    JSON.stringify({
                      phone: this.state.phone1,
                      id: result.idUser   
                    })
                  );
                    window.location.assign("/validation");
                })
                .catch(error => console.log('error', error));
             // -----------END l'api de l'envoi du code d'activation du compte par SMS
            // //  }, 4000);
        }
        if(response.status === 409){
            // console.log("408: ",response)
            // console.log("40: ",response)
            toast.warn("Le numéro saisi est déjà utilisé !");
            this.setState({
              isLoading: false,
            })
        }
      })
      .catch(error => {
        toast.warn('une erreur s\'est produite')
        // console.log('error', error)
        this.setState({
          isLoading: false,
        })
      });
}
    
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    console.log("params:", params)
    const idCentre = parseInt(params.id);
    const idprestation = parseInt(params.idprestation);


    let pfSanteResponse = await fetch(host+`/centres/${idCentre}`);

    if (pfSanteResponse.status === 200) {
      let data = await pfSanteResponse.json();
       console.log("INfos_PS:", data);
      let photo = "";
    //   if (data.user.photo !== null || data.user.photo !== undefined) {
    //     photo = data.user.photo;
    //     this.setState({
    //       urlPhoto: photo,
    //     });
    //   }

      //Récupération de la prestation spécifique du médecin 
      let iprestation;
        for (let index = 0; index < data.prestations.length; index++) {
          const element = data.prestations[index];
          console.log("element:",idprestation );
          if(element.id === idprestation){
            iprestation = element.id;
                  this.setState({
                    prestaChoisiLibelle:element.libelle,
                    prestaChoisiID: element.id,
                    prixEuroPrestaChoisi: element.pEUR,
                    prixXafPrestaChoisi: element.pXAF,
                    onlyPrestation: element
                  })         
               }        
        }
                // Vérification de la prestation
            if(iprestation !== idprestation){
              // this.props.history.push("/404");
            }


      this.setState({
        centre: data,
        isLoading: false,
        creneauDispo: data.creneaux,
        crenaux: data.creneaux,
        dateMin: this.ecrireDate3(new Date()),
        inputValue: this.ecrireDate3(new Date()),
        // lien: dataPresta,
      });
    }else{
      // this.props.history.push("/404");
    }

    this.getDateDebutDeSemaine();
    this.remplirTableauJour();
    this.remplirPrestation();

    let btnI = document.getElementById("<");
    btnI.style.display = "none";
    
    let tabAll = [];
    const timestampToday = new Date().getTime();
    let tabAllDispo = [];

    for (const cr of this.state.centre.creneaux) {  
      let condition = this.state.prestaChoisiIDSelect ? this.state.prestaChoisiIDSelect : this.state.prestaChoisiID;
 
      if (cr.reserve !== true) {
        if (
          //revoir
          cr.idprestation.id ==  condition
          // cr.idprestation.id == 0
          ) {
            console.log("Creneaux_CENTRE: ", cr);
            
          tabAll.push(cr);
        }
      }
    }

    for (let index = 0; index < tabAll.length; index++) {
      const element = tabAll[index];
      const timestamp = new Date(
        element.datecreneau + " " + element.heuredebut
      ).getTime();
      if (timestamp > timestampToday) {
        tabAllDispo.push(element);
      }
    }
    tabAllDispo = _.sortBy(tabAllDispo, [
      function (o) {
        return new Date(o.datecreneau + " " + o.heuredebut).getTime();
      },
    ]);

    // console.log("tabAllDispo: ", tabAllDispo);
    if (tabAllDispo.length > 0) {
      this.getDateDebutDeSemaine(new Date(tabAllDispo[0].datecreneau));
      this.remplirTableauJour();
      
    }
    if (tabAllDispo.length === 0) {
      this.setState({
        msgError: true,
      });
    }
  }

  ecrireHeure(heure) {
    var str = heure;
    var tab = str.split(":");
    return tab[0] + "h" + tab[1];
  }

  heureEcrite(heure) {
    var str = heure;
    var tab = str.split(":");
    return tab[0];
  }

  minuteEcrite(heure) {
    var str = heure;
    var tab = str.split(":");
    return tab[1];
  }

  ecrireDate(date) {
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    var nombre = date.getDate();
    var jour = listeJours[date.getDay()];
    var mois = listeMois[date.getMonth()];
    return jour + " " + nombre + " " + mois;
  }

  ecrireDate2(date) {
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    var nombre = date.getDate();
    var jour = listeJours[date.getDay()];
    var mois = listeMois[date.getMonth()];
    return nombre + " " + mois;
  }
  ecrireDate3(date) {
    var d = new Date(date);

    var mois = parseInt(date.getMonth());
    var jour = parseInt(d.getDate());
    mois = mois + 1;
    if (mois < 10) {
      mois = "0" + mois;
    }
    if (jour < 10) {
      jour = "0" + jour;
    }

    return date.getFullYear() + "-" + mois + "-" + jour;
  }
  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  removeDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  handleClose() {
    this.setState({
      showModal: false,
    });
  }
  handleShow() {
    this.setState({
      showModal: true,
    });
  }
  getJourDebut(jour, d) {
    switch (jour) {
      case "Lundi":
        return d;
      case "Mardi":
        return this.removeDays(d, 1);
      case "Mercredi":
        return this.removeDays(d, 2);
      case "Jeudi":
        return this.removeDays(d, 3);
      case "Vendredi":
        return this.removeDays(d, 4);
      case "Samedi":
        return this.removeDays(d, 5);
      case "Dimanche":
        return this.removeDays(d, 6);
      default:
        return "erreur";
    }
  }

  getDateDebutDeSemaine(date) {
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
    var jourDebutSemaine = "";

    if (date == undefined) {
      var d = new Date();
      var jour = listeJours[d.getDay()];
      var mois = listeMois[d.getMonth()];

      jourDebutSemaine = this.getJourDebut(jour, d);
      sessionStorage.setItem("debutSemaine", JSON.stringify(jourDebutSemaine));
    } else {
      var d = new Date(date);
      var jour = listeJours[d.getDay()];
      var mois = listeMois[d.getMonth()];

      jourDebutSemaine = this.getJourDebut(jour, d);
      sessionStorage.setItem("debutSemaine", JSON.stringify(jourDebutSemaine));
    }

    const info = JSON.parse(window.sessionStorage.getItem("debutSemaine"));
  }

  afficherDate(jour) {
    const debutSemaine = new Date(
      JSON.parse(window.sessionStorage.getItem("debutSemaine"))
    );

    switch (jour) {
      case "Lundi":
        return (
          <span className="slot-date">
            {this.ecrireDate2(debutSemaine)}
            <small className="slot-year"></small>
          </span>
        );
      case "Mardi":
        return (
          <span className="slot-date">
            {this.ecrireDate2(this.addDays(debutSemaine, 1))}
            <small className="slot-year"></small>
          </span>
        );
      case "Mercredi":
        return (
          <span className="slot-date">
            {this.ecrireDate2(this.addDays(debutSemaine, 2))}
            <small className="slot-year"></small>
          </span>
        );
      case "Jeudi":
        return (
          <span className="slot-date">
            {this.ecrireDate2(this.addDays(debutSemaine, 3))}
            <small className="slot-year"></small>
          </span>
        );
      case "Vendredi":
        return (
          <span className="slot-date">
            {this.ecrireDate2(this.addDays(debutSemaine, 4))}
            <small className="slot-year"></small>
          </span>
        );
      case "Samedi":
        return (
          <span className="slot-date">
            {this.ecrireDate2(this.addDays(debutSemaine, 5))}
            <small className="slot-year"></small>
          </span>
        );
      case "Dimanche":
        return (
          <span className="slot-date">
            {this.ecrireDate2(this.addDays(debutSemaine, 6))}
            <small className="slot-year"></small>
          </span>
        );
      default:
        return "erreur";
    }
  }

  remove(tab, i) {
    let tabReturn = [];
    tab.map((e) => {
      if (e.cr.id != i.cr.id) {
        tabReturn.push(e);
      }
    });
    return tabReturn;
  }

  estDans(tab, i) {
    let r = false;
    tab.map((e) => {
      if (e.cr.id == i.cr.id) {
        r = true;
      }
    });
    return r;
  }

  triTableau(tabRecup) {
    let tabI = tabRecup;
    let tabTriee = [];
    let tab = [];
    let i = 0;
    let j = 0;
    let min = 0;
    let temp = 0;

    if (tabRecup.length > 1) {
      tabI.map((e) => tab.push(e.cr.heuredebut));

      for (i; i <= tab.length - 1; i++) {
        min = i;
        for (j = i; j <= tab.length; j++) {
          if (tab[j] < tab[min]) {
            min = j;
          }
        }
        temp = tab[min];
        tab[min] = tab[i];
        tab[i] = temp;
      }

      let tabIe = tabI;
      i = 0;
      j = 0;
      for (i; i < tabI.length; i++) {
        if (tabI[i].cr.heuredebut == tab[j] && this.estDans(tabIe, tabI[i])) {
          tabTriee.push(tabI[i]);
          j++;
          tabIe = this.remove(tabIe, tabI[i]);
          if (tabTriee.length != tabI.length) {
            i = -1;
          }
        }
      }
    } else {
      tabTriee = tabRecup;
    }
    return tabTriee;
  }

  async remplirPrestation() {
    let tabPresta = [];
    let presta = null;
    let presta2 = null;
    let prestaResponse = await fetch(
      host + `/prestations/${this.state.prestaChoisiID}`
    );

    if (prestaResponse.status == 200) {
      let data = await prestaResponse.json();
      presta = data;
    }
    tabPresta.push(presta);
    for (let i = 0; i < this.state.centre.prestations.length; i++) {
      if (
        this.state.centre.prestations[i].id !== this.state.prestaChoisiID
      ) {
        let prestaResponse2 = await fetch(
          host + `/prestations/${this.state.centre.prestations[i].id}`
        );

        if (prestaResponse2.status === 200) {
          let data = await prestaResponse2.json();
          console.log("dataCentre:",data)
          presta2 = data;
        }
        tabPresta.push(presta2);
      }
    }
    // console.log(tabPresta);
    this.setState({
      affichagePrestation: tabPresta,
    });
  }

  remplirTableauJour() {
    var tabL = [];
    var tabMa = [];
    var tabMe = [];
    var tabJ = [];
    var tabV = [];
    var tabS = [];
    var tabD = [];

    var debutSemaine = new Date(
      JSON.parse(window.sessionStorage.getItem("debutSemaine"))
    );
    //var dateActuel = new Date("2021-04-01")
    var dateActuel = new Date();

    let month = false;
    let years = false;

    //2021-02-18
      
    for (const cr of this.state.centre.creneaux) {
      var d = new Date(cr.datecreneau);

      let condition = this.state.prestaChoisiIDSelect ? this.state.prestaChoisiIDSelect : this.state.prestaChoisiID;

      if (cr.reserve !== true) {
        // tabAll.push(cr);
        
        if (
            
           cr.idprestation.id ===   condition
          // // cr.idprestation.id === this.state.prestaChoisiID ||
          // cr.idprestation.id === 0
        ) {
          month = false;
          years = false;
          if (d.getMonth() > dateActuel.getMonth()) {
            // console.log("reservTRue 3");
            month = true;
          }
          if (d.getFullYear() > dateActuel.getFullYear()) {
            years = true;
          }
          if (month || years) {
            if (d.getDay() == 1) {
              if (
                d.getDate() == debutSemaine.getDate() &&
                d.getMonth() == debutSemaine.getMonth() &&
                d.getFullYear() == debutSemaine.getFullYear()
              ) {
                tabL.push({ cr: cr, selected: false });
              }
            }
            if (d.getDay() == 2) {
              var mardi = this.addDays(debutSemaine, 1);
              if (
                d.getDate() == mardi.getDate() &&
                d.getMonth() == mardi.getMonth() &&
                d.getFullYear() == mardi.getFullYear()
              ) {
                tabMa.push({ cr: cr, selected: false });
              }
            }
            if (d.getDay() == 3) {
              var mercredi = this.addDays(debutSemaine, 2);
              if (
                d.getDate() == mercredi.getDate() &&
                d.getMonth() == mercredi.getMonth() &&
                d.getFullYear() == mercredi.getFullYear()
              ) {
                tabMe.push({ cr: cr, selected: false });
              }
            }
            if (d.getDay() == 4) {
              // console.log("check : 1",)
              var jeudi = this.addDays(debutSemaine, 3);
              if (
                d.getDate() == jeudi.getDate() &&
                d.getMonth() == jeudi.getMonth() &&
                d.getFullYear() == jeudi.getFullYear()
              ) {
                tabJ.push({ cr: cr, selected: false });

                // console.log("check : 2")
              }
            }
            if (d.getDay() == 5) {
              var vendredi = this.addDays(debutSemaine, 4);
              if (
                d.getDate() == vendredi.getDate() &&
                d.getMonth() == vendredi.getMonth() &&
                d.getFullYear() == vendredi.getFullYear()
              ) {
                tabV.push({ cr: cr, selected: false });
              }
            }
            if (d.getDay() == 6) {
              var samedi = this.addDays(debutSemaine, 5);
              if (
                d.getDate() == samedi.getDate() &&
                d.getMonth() == samedi.getMonth() &&
                d.getFullYear() == samedi.getFullYear()
              ) {
                tabS.push({ cr: cr, selected: false });
              }
            }
            if (d.getDay() == 0) {
              var dimanche = this.addDays(debutSemaine, 6);
              if (
                d.getDate() == dimanche.getDate() &&
                d.getMonth() == dimanche.getMonth() &&
                d.getFullYear() == dimanche.getFullYear()
              ) {
                tabD.push({ cr: cr, selected: false });
              }
            }
          } else {
            if (
              d.getDate() > dateActuel.getDate() &&
              d.getMonth() == dateActuel.getMonth()
            ) {
              if (d.getDay() == 1) {
                if (
                  d.getDate() == debutSemaine.getDate() &&
                  d.getMonth() == debutSemaine.getMonth() &&
                  d.getFullYear() == debutSemaine.getFullYear()
                ) {
                  tabL.push({ cr: cr, selected: false });
                }
              }
              if (d.getDay() == 2) {
                var mardi = this.addDays(debutSemaine, 1);
                if (
                  d.getDate() == mardi.getDate() &&
                  d.getMonth() == mardi.getMonth() &&
                  d.getFullYear() == mardi.getFullYear()
                ) {
                  tabMa.push({ cr: cr, selected: false });
                }
              }
              if (d.getDay() == 3) {
                var mercredi = this.addDays(debutSemaine, 2);
                if (
                  d.getDate() == mercredi.getDate() &&
                  d.getMonth() == mercredi.getMonth() &&
                  d.getFullYear() == mercredi.getFullYear()
                ) {
                  tabMe.push({ cr: cr, selected: false });
                }
              }
              if (d.getDay() == 4) {
                // console.log("testJ 1")
                var jeudi = this.addDays(debutSemaine, 3);
                if (
                  d.getDate() == jeudi.getDate() &&
                  d.getMonth() == jeudi.getMonth() &&
                  d.getFullYear() == jeudi.getFullYear()
                ) {
                  tabJ.push({ cr: cr, selected: false });
                  // console.log("testJ 2")
                }
              }
              if (d.getDay() == 5) {
                var vendredi = this.addDays(debutSemaine, 4);
                if (
                  d.getDate() == vendredi.getDate() &&
                  d.getMonth() == vendredi.getMonth() &&
                  d.getFullYear() == vendredi.getFullYear()
                ) {
                  tabV.push({ cr: cr, selected: false });
                }
              }
              if (d.getDay() == 6) {
                var samedi = this.addDays(debutSemaine, 5);
                if (
                  d.getDate() == samedi.getDate() &&
                  d.getMonth() == samedi.getMonth() &&
                  d.getFullYear() == samedi.getFullYear()
                ) {
                  tabS.push({ cr: cr, selected: false });
                }
              }
              if (d.getDay() == 0) {
                var dimanche = this.addDays(debutSemaine, 6);
                if (
                  d.getDate() == dimanche.getDate() &&
                  d.getMonth() == dimanche.getMonth() &&
                  d.getFullYear() == dimanche.getFullYear()
                ) {
                  tabD.push({ cr: cr, selected: false });
                }
              }
            } else {
              if (d.getDate() == dateActuel.getDate()) {
                if (this.heureEcrite(cr.heuredebut) > dateActuel.getHours()) {
                  if (d.getDay() == 1) {
                    if (
                      d.getDate() == debutSemaine.getDate() &&
                      d.getMonth() == debutSemaine.getMonth() &&
                      d.getFullYear() == debutSemaine.getFullYear()
                    ) {
                      tabL.push({ cr: cr, selected: false });
                    }
                  }
                  if (d.getDay() == 2) {
                    var mardi = this.addDays(debutSemaine, 1);
                    if (
                      d.getDate() == mardi.getDate() &&
                      d.getMonth() == mardi.getMonth() &&
                      d.getFullYear() == mardi.getFullYear()
                    ) {
                      tabMa.push({ cr: cr, selected: false });
                    }
                  }
                  if (d.getDay() == 3) {
                    var mercredi = this.addDays(debutSemaine, 2);
                    if (
                      d.getDate() == mercredi.getDate() &&
                      d.getMonth() == mercredi.getMonth() &&
                      d.getFullYear() == mercredi.getFullYear()
                    ) {
                      tabMe.push({ cr: cr, selected: false });
                    }
                  }
                  if (d.getDay() == 4) {
                    var jeudi = this.addDays(debutSemaine, 3);
                    if (
                      d.getDate() == jeudi.getDate() &&
                      d.getMonth() == jeudi.getMonth() &&
                      d.getFullYear() == jeudi.getFullYear()
                    ) {
                      tabJ.push({ cr: cr, selected: false });
                    }
                  }
                  if (d.getDay() == 5) {
                    var vendredi = this.addDays(debutSemaine, 4);
                    if (
                      d.getDate() == vendredi.getDate() &&
                      d.getMonth() == vendredi.getMonth() &&
                      d.getFullYear() == vendredi.getFullYear()
                    ) {
                      tabV.push({ cr: cr, selected: false });
                    }
                  }
                  if (d.getDay() == 6) {
                    var samedi = this.addDays(debutSemaine, 5);
                    if (
                      d.getDate() == samedi.getDate() &&
                      d.getMonth() == samedi.getMonth() &&
                      d.getFullYear() == samedi.getFullYear()
                    ) {
                      tabS.push({ cr: cr, selected: false });
                    }
                  }
                  if (d.getDay() == 0) {
                    var dimanche = this.addDays(debutSemaine, 6);
                    if (
                      d.getDate() == dimanche.getDate() &&
                      d.getMonth() == dimanche.getMonth() &&
                      d.getFullYear() == dimanche.getFullYear()
                    ) {
                      tabD.push({ cr: cr, selected: false });
                    }
                  }
                } else {
                  if (
                    this.heureEcrite(cr.heuredebut) == dateActuel.getHours() &&
                    this.minuteEcrite(cr.heuredebut) > dateActuel.getMinutes()
                  ) {
                    if (d.getDay() == 1) {
                      if (
                        d.getDate() == debutSemaine.getDate() &&
                        d.getMonth() == debutSemaine.getMonth() &&
                        d.getFullYear() == debutSemaine.getFullYear()
                      ) {
                        tabL.push({ cr: cr, selected: false });
                      }
                    }
                    if (d.getDay() == 2) {
                      var mardi = this.addDays(debutSemaine, 1);
                      if (
                        d.getDate() == mardi.getDate() &&
                        d.getMonth() == mardi.getMonth() &&
                        d.getFullYear() == mardi.getFullYear()
                      ) {
                        tabMa.push({ cr: cr, selected: false });
                      }
                    }
                    if (d.getDay() == 3) {
                      var mercredi = this.addDays(debutSemaine, 2);
                      if (
                        d.getDate() == mercredi.getDate() &&
                        d.getMonth() == mercredi.getMonth() &&
                        d.getFullYear() == mercredi.getFullYear()
                      ) {
                        tabMe.push({ cr: cr, selected: false });
                      }
                    }
                    if (d.getDay() == 4) {
                      var jeudi = this.addDays(debutSemaine, 3);
                      if (
                        d.getDate() == jeudi.getDate() &&
                        d.getMonth() == jeudi.getMonth() &&
                        d.getFullYear() == jeudi.getFullYear()
                      ) {
                        tabJ.push({ cr: cr });
                      }
                    }
                    if (d.getDay() == 5) {
                      var vendredi = this.addDays(debutSemaine, 4);
                      if (
                        d.getDate() == vendredi.getDate() &&
                        d.getMonth() == vendredi.getMonth() &&
                        d.getFullYear() == vendredi.getFullYear()
                      ) {
                        tabV.push({ cr: cr, selected: false });
                      }
                    }
                    if (d.getDay() == 6) {
                      var samedi = this.addDays(debutSemaine, 5);
                      if (
                        d.getDate() == samedi.getDate() &&
                        d.getMonth() == samedi.getMonth() &&
                        d.getFullYear() == samedi.getFullYear()
                      ) {
                        tabS.push({ cr: cr, selected: false });
                      }
                    }
                    if (d.getDay() == 0) {
                      var dimanche = this.addDays(debutSemaine, 6);
                      if (
                        d.getDate() == dimanche.getDate() &&
                        d.getMonth() == dimanche.getMonth() &&
                        d.getFullYear() == dimanche.getFullYear()
                      ) {
                        tabD.push({ cr: cr, selected: false });
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    tabL = _.sortBy(tabL, [
      function (o) {
        return new Date(o.cr.datecreneau + " " + o.cr.heuredebut).getTime();
      },
    ]);
    tabMa = _.sortBy(tabMa, [
      function (o) {
        return new Date(o.cr.datecreneau + " " + o.cr.heuredebut).getTime();
      },
    ]);
    tabMe = _.sortBy(tabMe, [
      function (o) {
        return new Date(o.cr.datecreneau + " " + o.cr.heuredebut).getTime();
      },
    ]);
    tabJ = _.sortBy(tabJ, [
      function (o) {
        return new Date(o.cr.datecreneau + " " + o.cr.heuredebut).getTime();
      },
    ]);
    tabV = _.sortBy(tabV, [
      function (o) {
        return new Date(o.cr.datecreneau + " " + o.cr.heuredebut).getTime();
      },
    ]);
    tabS = _.sortBy(tabS, [
      function (o) {
        return new Date(o.cr.datecreneau + " " + o.cr.heuredebut).getTime();
      },
    ]);
    tabD = _.sortBy(tabD, [
      function (o) {
        return new Date(o.cr.datecreneau + " " + o.cr.heuredebut).getTime();
      },
    ]);
    // console.log("tabCRE: ", tabAllDispo );

    this.setState({
      tabLundi: tabL,
      tabMardi: tabMa,
      tabMercredi: tabMe,
      // tabJeudi: this.triTableau(tabJ),
      tabJeudi: tabJ,
      tabVendredi: tabV,
      tabSamedi: tabS,
      tabDimanche: tabD,
    });
  }

  handleChange(e, date) {
    if (!date) {
      var d = new Date(e.target.value);
      this.setState({
        inputValue: e.target.value,
      });
      sessionStorage.setItem("debutSemaine", JSON.stringify(e.target.value));
      this.getDateDebutDeSemaine(d);
      this.remplirTableauJour();
    } else {
      sessionStorage.setItem("debutSemaine", JSON.stringify(date));
      this.setState({
        inputValue: this.ecrireDate3(new Date(date)),
      });
      this.getDateDebutDeSemaine(date);
      this.remplirTableauJour();
    }
  }
  infSemaine() {
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];

    var dateDebutDeSemaine = new Date(
      JSON.parse(window.sessionStorage.getItem("debutSemaine"))
    );

    var d = new Date();
    var jour = listeJours[d.getDay()];
    var jourDebutSemaine = this.getJourDebut(jour, d);

    var jourSemaineInf = this.removeDays(dateDebutDeSemaine, 7);
    var testDateBouton = this.removeDays(dateDebutDeSemaine, 14);

    if (jourDebutSemaine.getFullYear() < jourSemaineInf.getFullYear()) {
      this.setState({
        tabLundi: [],
        tabMardi: [],
        tabMercredi: [],
        tabJeudi: [],
        tabVendredi: [],
        tabSamedi: [],
        tabDimanche: [],
      });
      var e = "";
      this.handleChange(e, jourSemaineInf);
    }
    if (jourDebutSemaine.getFullYear() == jourSemaineInf.getFullYear()) {
      if (jourDebutSemaine.getMonth() < jourSemaineInf.getMonth()) {
        this.setState({
          tabLundi: [],
          tabMardi: [],
          tabMercredi: [],
          tabJeudi: [],
          tabVendredi: [],
          tabSamedi: [],
          tabDimanche: [],
        });
        var e = "";
        this.handleChange(e, jourSemaineInf);
      }
      if (jourDebutSemaine.getMonth() == jourSemaineInf.getMonth()) {
        if (jourDebutSemaine.getDate() <= jourSemaineInf.getDate()) {
          this.setState({
            tabLundi: [],
            tabMardi: [],
            tabMercredi: [],
            tabJeudi: [],
            tabVendredi: [],
            tabSamedi: [],
            tabDimanche: [],
          });
          var e = "";
          this.handleChange(e, jourSemaineInf);
        }
      }
    }
    let cmp = false;
    if (jourDebutSemaine.getFullYear() == testDateBouton.getFullYear()) {
      if (jourDebutSemaine.getMonth() < testDateBouton.getMonth()) {
        cmp = true;
      }
      if (jourDebutSemaine.getMonth() == testDateBouton.getMonth()) {
        if (jourDebutSemaine.getDate() <= testDateBouton.getDate()) {
          cmp = true;
        }
      }
    }
    if (cmp == false) {
      let btnI = document.getElementById("<");
      btnI.style.display = "none";
    }
  }
  supSemaine() {
    this.setState({
      tabLundi: [],
      tabMardi: [],
      tabMercredi: [],
      tabJeudi: [],
      tabVendredi: [],
      tabSamedi: [],
      tabDimanche: [],
    });
    var dateActuel = new Date(
      JSON.parse(window.sessionStorage.getItem("debutSemaine"))
    );
    var d = this.addDays(dateActuel, 7);
    var e = "";
    this.handleChange(e, d);

    let btnI = document.getElementById("<");
    btnI.style.display = "block";
  }
  selectTime = (name, cr) => {
    // console.log("ONE_CRENeaux:", cr)
    this.setState({
      texteCreneau: "",
    });

    if (name == "lundi") {
      let tabLundi = this.state.tabLundi;
      tabLundi.forEach((current) => {
        if (current.cr.idcreneau == cr.cr.idcreneau) {
          current.cr.selected = !current.cr.selected;
          if (current.cr.selected == true) {
            this.setState({
              tabLundi,
              creneauSelectionne: [
                ...this.state.creneauSelectionne,
                current.cr,
              ],
            });
          } else if (current.cr.selected == false) {
            let Tab = [];
            this.state.creneauSelectionne.pop();
            Tab = this.state.creneauSelectionne;
            // console.log("NEWtab: ", Tab)
            this.setState({
              tabLundi,
              creneauSelectionne: Tab,
            });
          } else {
            this.setState({
              tabLundi,
              creneauSelectionne: [],
            });
          }
        }
      });
    }
    if (name == "mardi") {
      let tabMardi = this.state.tabMardi;
      tabMardi.forEach((current) => {
        if (current.cr.idcreneau == cr.cr.idcreneau) {
          current.cr.selected = !current.cr.selected;
          if (current.cr.selected == true) {
            this.setState({
              tabMardi,
              creneauSelectionne: [
                ...this.state.creneauSelectionne,
                current.cr,
              ],
            });
          } else if (current.cr.selected == false) {
            let Tab = [];
            this.state.creneauSelectionne.pop();
            Tab = this.state.creneauSelectionne;
            // console.log("NEWtab: ", Tab)
            this.setState({
              tabMardi,
              creneauSelectionne: Tab,
            });
          } else {
            this.setState({
              tabMardi,
              creneauSelectionne: [],
            });
          }
        }
      });
    }
    if (name == "mercredi") {
      let tabMercredi = this.state.tabMercredi;
      tabMercredi.forEach((current) => {
        if (current.cr.idcreneau == cr.cr.idcreneau) {
          current.cr.selected = !current.cr.selected;
          if (current.cr.selected == true) {
            this.setState({
              tabMercredi,
              creneauSelectionne: [
                ...this.state.creneauSelectionne,
                current.cr,
              ],
            });
          } else if (current.cr.selected == false) {
            let Tab = [];
            this.state.creneauSelectionne.pop();
            Tab = this.state.creneauSelectionne;
            // console.log("NEWtab: ", Tab)
            this.setState({
              tabMercredi,
              creneauSelectionne: Tab,
            });
          } else {
            this.setState({
              tabMercredi,
              creneauSelectionne: [],
            });
          }
        }
      });
    }
    if (name == "jeudi") {
      let tabJeudi = this.state.tabJeudi;
      tabJeudi.forEach((current) => {
        // console.log("CRENX: ", current)
        // console.log("CRENX-tabjeudi: ", current.cr.idcreneau);
        // console.log("CRENX-Clické: ", cr.cr.idcreneau);

        if (current.cr.idcreneau == cr.cr.idcreneau) {
          current.cr.selected = !current.cr.selected;
          if (current.cr.selected == true) {
            this.setState({
              tabJeudi,
              creneauSelectionne: [
                ...this.state.creneauSelectionne,
                current.cr,
              ],
            });
          } else if (current.cr.selected == false) {
            let Tab = [];
            this.state.creneauSelectionne.pop();
            Tab = this.state.creneauSelectionne;
            // console.log("NEWtab: ", Tab)
            this.setState({
              tabJeudi,
              creneauSelectionne: Tab,
            });
          } else {
            this.setState({
              tabJeudi,
              creneauSelectionne: [],
            });
          }
        }
      });
    }
    if (name == "vendredi") {
      let tabVendredi = this.state.tabVendredi;
      tabVendredi.forEach((current) => {
        if (current.cr.idcreneau == cr.cr.idcreneau) {
          current.cr.selected = !current.cr.selected;
          if (current.cr.selected == true) {
            this.setState({
              tabVendredi,
              creneauSelectionne: [
                ...this.state.creneauSelectionne,
                current.cr,
              ],
            });
          } else if (current.cr.selected == false) {
            let Tab = [];
            this.state.creneauSelectionne.pop();
            Tab = this.state.creneauSelectionne;
            // console.log("NEWtab: ", Tab)
            this.setState({
              tabVendredi,
              creneauSelectionne: Tab,
            });
          } else {
            this.setState({
              tabVendredi,
              creneauSelectionne: [],
            });
          }
        }
      });
    }
    if (name == "samedi") {
      let tabSamedi = this.state.tabSamedi;
      tabSamedi.forEach((current) => {
        if (current.cr.idcreneau == cr.cr.idcreneau) {
          current.cr.selected = !current.cr.selected;
          if (current.cr.selected == true) {
            this.setState({
              tabSamedi,
              creneauSelectionne: [
                ...this.state.creneauSelectionne,
                current.cr,
              ],
            });
          } else if (current.cr.selected == false) {
            let Tab = [];
            this.state.creneauSelectionne.pop();
            Tab = this.state.creneauSelectionne;
            // console.log("NEWtab: ", Tab)
            this.setState({
              tabSamedi,
              creneauSelectionne: Tab,
            });
          } else {
            this.setState({
              tabSamedi,
              creneauSelectionne: [],
            });
          }
        }
      });
    }
    if (name == "dimanche") {
      let tabDimanche = this.state.tabDimanche;
      tabDimanche.forEach((current) => {
        if (current.cr.idcreneau == cr.cr.idcreneau) {
          current.cr.selected = !current.cr.selected;
          if (current.cr.selected == true) {
            this.setState({
              tabDimanche,
              creneauSelectionne: [
                ...this.state.creneauSelectionne,
                current.cr,
              ],
            });
          } else if (current.cr.selected == false) {
            let Tab = [];
            this.state.creneauSelectionne.pop();
            Tab = this.state.creneauSelectionne;
            // console.log("NEWtab: ", Tab)
            this.setState({
              tabDimanche,
              creneauSelectionne: Tab,
            });
          } else {
            this.setState({
              tabDimanche,
              creneauSelectionne: [],
            });
          }
        }
      });
    }
  };

  paiement() {
    let info = this.state.lien;
    let infos = this.state.onlyPrestation;
    // console.log("AIRTTTTT: ", info)

    if (
      this.state.creneauSelectionne.length === 0 ||
      this.state.creneauSelectionne.length > 1
    ) {
      this.setState({
        texteCreneau: "",
      });
      swal("Oops", "Veuillez choisir qu'un seul créneau SVP!.", "error");
    } else if (JSON.parse(window.sessionStorage.getItem("user")) === null) {
      this.handleShowC();
    } else {
      this.handleShow();
    }
    if (
      JSON.parse(window.sessionStorage.getItem("user")) !== null &&
      this.state.creneauSelectionne.length !== 0
    ) {
        if(info){
          // console.log("info: ", info)
          let rdv = {
            idCentre: this.state.centre.idcentre,
            idCRDI: this.state.creneauSelectionne,
            prix: info.pEUR,
            prixXAF: info.pXAF,
            prestation: info,
            // centre: this.state.centreEntier
          };
          window.sessionStorage.setItem("rdv", JSON.stringify(rdv));
          window.location.replace("/patient/checkout");
        }else{
          // console.log("infos: ", infos)
          let rdv = {
            idCentre: this.state.centre.idcentre,
            idCRDI: this.state.creneauSelectionne,
            prix: infos.pEUR,
            prixXAF: infos.pXAF,
            prestation: this.state.onlyPrestation,
            // centre: this.state.centreEntier
          };
          window.sessionStorage.setItem("rdv", JSON.stringify(rdv));
          window.location.replace("/patient/checkout");
        }
    }
  }

  checkoutAirtel(){
    let info = this.state.lien;
    let infos = this.state.onlyPrestation;
    if (
      this.state.creneauSelectionne.length == 0 ||
      this.state.creneauSelectionne.length > 1
    ) {
      this.setState({
        texteCreneau: "",
      });
      swal("Oops", "Veuillez choisir qu'un seul créneau SVP!.", "error");
      //sessionStorage.setItem("prix", this.state.prix);
    } else if (JSON.parse(window.sessionStorage.getItem("user")) == null) {
      this.handleShowC();
    } else {
      this.handleShow();
    }
    if (
      JSON.parse(window.sessionStorage.getItem("user")) !== null &&
      this.state.creneauSelectionne.length !== 0
    ) {
      if(info){
        
        let rdv = {
          idCentre: this.state.centre.idcentre,
          idCRDI: this.state.creneauSelectionne,
          prix: info.pEUR,
          prixXAF: info.pXAF,
          prestation: info,
          // centre: this.state.centreEntier
        };
        window.sessionStorage.setItem("rdv", JSON.stringify(rdv));
        window.location.replace("/patient/checkout/airtel");
      }else{
        let rdv = {
          idCentre: this.state.centre.idcentre,
          idCRDI: this.state.creneauSelectionne,
          prix: infos.pEUR,
          prixXAF: infos.pXAF,
          prestation: this.state.onlyPrestation,
          // centre: this.state.centreEntier
        };
        window.sessionStorage.setItem("rdv", JSON.stringify(rdv));
        window.location.replace("/patient/checkout/airtel");
      }
    }
  }

  //Paiement MTN
  checkoutMtn() {
    let info = this.state.lien;
    let infos = this.state.onlyPrestation;
    if (
      this.state.creneauSelectionne.length == 0 ||
      this.state.creneauSelectionne.length > 1
    ) {
      this.setState({
        texteCreneau: "",
      });
      swal("Oops", "Veuillez choisir qu'un seul créneau SVP!.", "error");
      //sessionStorage.setItem("prix", this.state.prix);
    } else if (JSON.parse(window.sessionStorage.getItem("user")) == null) {
      this.handleShowC();
    } else {
      this.handleShow();
    }
    if (
      JSON.parse(window.sessionStorage.getItem("user")) !== null &&
      this.state.creneauSelectionne.length !== 0
    ) {
      if(info){
        
        let rdv = {
          idCentre: this.state.centre.idcentre,
          idCRDI: this.state.creneauSelectionne,
          prix: info.pEUR,
          prixXAF: info.pXAF,
          prestation: info,
          // centre: this.state.centreEntier
        };
        window.sessionStorage.setItem("rdv", JSON.stringify(rdv));
        window.location.replace("/patient/checkout/mtn");
      }else{
        let rdv = {
          idCentre: this.state.centre.idcentre,
          idCRDI: this.state.creneauSelectionne,
          prix: infos.pEUR,
          prixXAF: infos.pXAF,
          prestation: this.state.onlyPrestation,
          // centre: this.state.centreEntier
        };
        window.sessionStorage.setItem("rdv", JSON.stringify(rdv));
        window.location.replace("/patient/checkout/mtn");
      }
    }
  }

  //  begin Fonction changement de la prestation dans le select
  async handleChangeSelect(e) {
    let presta = "";
    // console.log("E.Target:",e.target.value)
    let prestaResponse = await fetch(host+`/prestations/${e.target.value}`);
    if (prestaResponse.status === 200) {
      let presta = await prestaResponse.json();
      // presta = data;
       console.log("PRESTA_SELECT:",presta)

      if( presta.creneaux.length > 0){
        console.log("PRESTA_CRENAAUX_1: ", presta)
        this.setState({
          msgError: false,
          prestaChoisiIDSelect: presta.id,
          prestaChoisiLibelle: presta.libelle,
          lien: presta
        })
      }if( presta.creneaux.length === 0){
        console.log("PRESTA_CRENAAUX_2: ", presta)
        this.setState({
          msgError: true,
          prestaChoisiIDSelect: presta.id,
          prestaChoisiLibelle: presta.libelle,
        })
      }
      // console.log("objectPresta: ", presta);
            
    }

    this.componentDidMount();
  }
  //  fin Fonction changement de la prestation dans le select

  checkCreneau() {
    if (
      this.state.creneauSelectionne.length === 0 ||
      this.state.creneauSelectionne.length > 1
    ) {
      this.setState({
        texteCreneau: "",
      });
      swal("Oops", "Veuillez choisir qu'un seul créneau SVP!.", "error");
      // console.log("taille: ", this.state.creneauSelectionne);
      //sessionStorage.setItem("prix", this.state.prix);
    } else if (JSON.parse(window.sessionStorage.getItem("user")) === null) {
      this.handleShowC();
    } else {
      this.handleShow();
    }
    // console.log("taille: ", this.state.creneauSelectionne);
  }

  render() {
    // console.log("JEUDI: ", this.state.tabJeudi);
    console.log("this.state.lien:",this.state.lien)
    console.log("this.state.onlyPrestation:",this.state.onlyPrestation)

    const btnSendFormulaire =
      this.state.nom.trim() === "" ||
      this.state.firstName.trim() === "" ||
      this.state.email.trim() === "" ||
      this.state.phone1 === "" ||
      this.state.dateNaissance === "" ||
      this.state.adress === "" ||
      this.state.genre === "" ||
      this.state.passwrd === "" ||
      this.state.passwrd.length < 6 ||
      this.state.confirmPassword === "" ||
      this.state.passwrd !== this.state.confirmPassword ||
      this.state.regle == false ? (
        <button
          disabled
          style={{ backgroundColor: "rgb(21,85,141)", color: "white" }}
          className="btn  pull-right"
          type="submit"
        >
          S'inscrire
        </button>
      ) : (
        <button
          style={{ backgroundColor: "rgb(21,85,141)", color: "white" }}
          className="btn  pull-right"
          type="submit"
        >
          S'inscrire
        </button>
      );

    const { centre } = this.state;
    if (centre.length === 0) {
      return (
        <div
          style={{
            textAlign: "center",
            marginTop: "50vh",
            marginBottom: "40vh",
          }}
        >
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      );
    } else {
      return (
        <div>
          <div className="content">
            <ToastContainer />
            <div
              className={
                this.state.isLoading
                  ? "container-fluid page_wrapper_blur"
                  : "container-fluid"
              }
            >
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    {/* <div className="col-12 col-sm-4 col-md-6"> */}
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="booking-doc-info">                         
                          <Image
                          width={150}
                          // src="https://cdn0.iconfinder.com/data/icons/medical-and-clinical-healthcare/78/5-512.png"                              
                          src={
                            centre.photo ? centre.photo  : "https://cdn0.iconfinder.com/data/icons/medical-and-clinical-healthcare/78/5-512.png"                              
                          }
                          className="img-fluid"
                          style={{ borderRadius: "2%" }}
                          alt={centre.libelle}
                        />
                            <div className="booking-info ml-3">
                              <h4>
                                <Link
                                  to={`/patient/centreProfile/${this.state.centre.idcentre}`}
                                >
                                  {centre.libelle}
                                </Link>
                              </h4>
                              <h5 className="doc-department">
							  {centre.adresse ? centre.adresse : ""}
                              </h5>
                              {/* <p className="text-muted mb-0"><i className="fas fa-map-marker-alt"></i> Newyork, USA</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-8 col-md-6 text-sm-right">
                      <div className="datepicker-icon">
                        <input
                          type="date"
                          value={this.state.inputValue}
                          min={this.state.dateMin}
                          onChange={this.handleChange}
                          id="debut"
                          name="debut"
                          className="select form-control"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    {/* <div className="col-12 col-sm-12 col-md-8"> */}
                    <div className="col-md-6 text-sm-left">
                      <div style={{ display: "flex" }}>
                        <div
                          className="form-group"
                          style={{ marginLeft: "2%", width: "50%" }}
                        >
                          <div>
                            <strong>Prestation</strong>
                          </div>
                          <select
                            id="lang"
                            onChange={(e) => this.handleChangeSelect(e)}
                            value={this.state.prestaChoisiLibelle.libelle}
                            className="form-control"
                          >
                            {this.state.affichagePrestation.map((presta) => (
                              <option value={presta.id}>
                                {console.log("seeeelpresta1:",presta)}
                                {presta.libelle} {numStr(presta.pXAF,'.') + " XAF"}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                    <div className="col-12 col-sm-8 col-md-6 text-sm-right">
                      {/* <div className="datepicker-icon">
                        <strong>Sélectionné la date</strong>
                        <input
                          type="date"
                          value={this.state.inputValue}
                          min={this.state.dateMin}
                          onChange={this.handleChange}
                          id="debut"
                          name="debut"
                          className="select form-control"
                        ></input>
                      </div> */}
                    </div>
                        {/* <div className="form-group" style={{ marginLeft: "2%", width: "50%" }}>
														<div ><strong>Centre</strong></div>
														<select id="lang" onChange={e => this.handleChangeSelect(e, "centre")} value={this.state.centreChoisi} className="form-control">
															<option value="Tous les centres">Tous les centres</option>
															{this.state.centre.centres.map(centre => (
																<option value={centre.idcentre}>
																	{centre.libelle}
																</option>
															))}
														</select>
													</div> */}
                      </div>
                    </div>

                   
                    <div className="col-12 col-sm-8 col-md-6 text-sm-right"></div>
                  </div>

                  <div className="card table-responsive booking-schedule schedule-widget">
                    <div className="schedule-header">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          {this.state.msgError ? (
                            <Alert
                              message="Il n'y a aucun créneau de disponible pour la prestation choisie"
                              type="info"
                              showIcon
                            />
                          ) : (
                            ""
                          )}
                          <br /> <br />
                          <div className="day-slot">
                            <ul>
                              <li className="left-arrow">
                                <Link to="#">
                                  <i
                                    className="fa fa-chevron-left"
                                    id="<"
                                    onClick={this.infSemaine.bind(this)}
                                  ></i>
                                </Link>
                              </li>
                              <li>
                                <span>lundi</span>
                                {this.afficherDate("Lundi")}
                              </li>
                              <li>
                                <span>Mardi</span>
                                {this.afficherDate("Mardi")}
                              </li>
                              <li>
                                <span>Mercredi</span>
                                {this.afficherDate("Mercredi")}
                              </li>
                              <li>
                                <span>Jeudi</span>
                                {this.afficherDate("Jeudi")}
                              </li>
                              <li>
                                <span>Vendredi</span>
                                {this.afficherDate("Vendredi")}
                              </li>
                              <li>
                                <span>Samedi</span>
                                {this.afficherDate("Samedi")}
                              </li>
                              <li>
                                <span>Dimanche</span>
                                {this.afficherDate("Dimanche")}
                              </li>
                              <li className="right-arrow">
                                <Link to="#">
                                  <i
                                    className="fa fa-chevron-right"
                                    onClick={this.supSemaine.bind(this)}
                                  ></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="schedule-cont">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="time-slot">
                            <ul className="clearfix">
                              <li>
                                {this.state.tabLundi.length === 0 ? (
                                  <div>
                                    <center> Aucun créneau </center>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "grid",
                                      width: "100%",
                                      justifyContent: "center",
                                      alignItems: "center ",
                                    }}
                                  >
                                    {this.state.tabLundi.map((cr, index) => {
                                      return (
                                        <span key={"cr" + cr.cr.idcreneau}>
                                          <button
                                            style={
                                              cr.cr.selected
                                                ? {
                                                    backgroundColor: "#42c0fb",
                                                    border: "1px solid #42c0fb",
                                                    color: "#fff",
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                                : {
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                            }
                                            className="timing"
                                            href="#"
                                            onClick={() =>
                                              this.selectTime("lundi", cr)
                                            }
                                          >
                                            {this.ecrireHeure(cr.cr.heuredebut)}
                                            - {this.ecrireHeure(cr.cr.heurefin)}
                                          </button>
                                        </span>
                                      );
                                    })}
                                  </div>
                                )}
                              </li>
                              <li>
                                {this.state.tabMardi.length === 0 ? (
                                  <div>
                                    <center> Aucun créneau </center>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "grid",
                                      width: "100%",
                                      justifyContent: "center",
                                      alignItems: "center ",
                                    }}
                                  >
                                    {this.state.tabMardi.map((cr, index) => {
                                      return (
                                        <span key={"cr" + cr.cr.idcreneau}>
                                          <button
                                            style={
                                              cr.cr.selected
                                                ? {
                                                    backgroundColor: "#42c0fb",
                                                    border: "1px solid #42c0fb",
                                                    color: "#fff",
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                                : {
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                            }
                                            className="timing"
                                            href="#"
                                            onClick={() =>
                                              this.selectTime("mardi", cr)
                                            }
                                          >
                                            {this.ecrireHeure(cr.cr.heuredebut)}
                                            - {this.ecrireHeure(cr.cr.heurefin)}
                                          </button>
                                        </span>
                                      );
                                    })}
                                  </div>
                                )}
                              </li>
                              <li>
                                {this.state.tabMercredi.length === 0 ? (
                                  <div>
                                    <center> Aucun créneau </center>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "grid",
                                      width: "100%",
                                      justifyContent: "center",
                                      alignItems: "center ",
                                    }}
                                  >
                                    {this.state.tabMercredi.map((cr, index) => {
                                      return (
                                        <span key={"cr" + cr.cr.idcreneau}>
                                          <button
                                            style={
                                              cr.cr.selected
                                                ? {
                                                    backgroundColor: "#42c0fb",
                                                    border: "1px solid #42c0fb",
                                                    color: "#fff",
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                                : {
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                            }
                                            className="timing"
                                            href="#"
                                            onClick={() =>
                                              this.selectTime("mercredi", cr)
                                            }
                                          >
                                            {this.ecrireHeure(cr.cr.heuredebut)}
                                            - {this.ecrireHeure(cr.cr.heurefin)}
                                          </button>
                                        </span>
                                      );
                                    })}
                                  </div>
                                )}
                              </li>
                              <li>
                                {this.state.tabJeudi.length === 0 ? (
                                  <>
                                    <div>
                                      <center> Aucun créneau</center>
                                    </div>
                                    {/* <div><center>-</center></div>
																			<div><center>-</center></div>
																			<div><center>-</center></div> */}
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      display: "grid",
                                      width: "100%",
                                      justifyContent: "center",
                                      alignItems: "center ",
                                    }}
                                  >
                                    {this.state.tabJeudi.map((cr, index) => {
                                      return (
                                        <span key={"cr" + cr.cr.idcreneau}>
                                          <button
                                            style={
                                              cr.cr.selected
                                                ? {
                                                    backgroundColor: "#42c0fb",
                                                    border: "1px solid #42c0fb",
                                                    color: "#fff",
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                                : {
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                            }
                                            className="timing"
                                            href="#"
                                            onClick={() =>
                                              this.selectTime("jeudi", cr)
                                            }
                                          >
                                            {this.ecrireHeure(cr.cr.heuredebut)}
                                            - {this.ecrireHeure(cr.cr.heurefin)}
                                          </button>
                                        </span>
                                      );
                                    })}
                                  </div>
                                )}
                              </li>
                              <li>
                                {this.state.tabVendredi.length === 0 ? (
                                  <>
                                    <div>
                                      <center> Aucun créneau </center>
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      display: "grid",
                                      width: "100%",
                                      justifyContent: "center",
                                      alignItems: "center ",
                                    }}
                                  >
                                    {this.state.tabVendredi.map((cr, index) => {
                                      return (
                                        <span key={"cr" + cr.cr.idcreneau}>
                                          <button
                                            style={
                                              cr.cr.selected
                                                ? {
                                                    backgroundColor: "#42c0fb",
                                                    border: "1px solid #42c0fb",
                                                    color: "#fff",
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                                : {
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                            }
                                            className="timing"
                                            href="#"
                                            onClick={() =>
                                              this.selectTime("vendredi", cr)
                                            }
                                          >
                                            {this.ecrireHeure(cr.cr.heuredebut)}
                                            - {this.ecrireHeure(cr.cr.heurefin)}
                                          </button>
                                        </span>
                                      );
                                    })}
                                  </div>
                                )}
                              </li>
                              <li>
                                {this.state.tabSamedi.length === 0 ? (
                                  <div>
                                    <center>Aucun créneau </center>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "grid",
                                      width: "100%",
                                      justifyContent: "center",
                                      alignItems: "center ",
                                    }}
                                  >
                                    {this.state.tabSamedi.map((cr, index) => {
                                      return (
                                        <span key={"cr" + cr.cr.idcreneau}>
                                          <button
                                            style={
                                              cr.cr.selected
                                                ? {
                                                    backgroundColor: "#42c0fb",
                                                    border: "1px solid #42c0fb",
                                                    color: "#fff",
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                                : {
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                            }
                                            className="timing"
                                            href="#"
                                            onClick={() =>
                                              this.selectTime("samedi", cr)
                                            }
                                          >
                                            {this.ecrireHeure(cr.cr.heuredebut)}
                                            - {this.ecrireHeure(cr.cr.heurefin)}
                                          </button>
                                        </span>
                                      );
                                    })}
                                  </div>
                                )}
                              </li>
                              <li>
                                {this.state.tabDimanche.length === 0 ? (
                                  <div>
                                    <center>Aucun créneau</center>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "grid",
                                      width: "100%",
                                      justifyContent: "center",
                                      alignItems: "center ",
                                    }}
                                  >
                                    {this.state.tabDimanche.map((cr, index) => {
                                      return (
                                        <span key={"cr" + cr.cr.idcreneau}>
                                          <button
                                            style={
                                              cr.cr.selected
                                                ? {
                                                    backgroundColor: "#42c0fb",
                                                    border: "1px solid #42c0fb",
                                                    color: "#fff",
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                                : {
                                                    marginBottom: "5%",
                                                    width: "100%",
                                                    textAlign: "center",
                                                  }
                                            }
                                            className="timing"
                                            href="#"
                                            onClick={() =>
                                              this.selectTime("dimanche", cr)
                                            }
                                          >
                                            {this.ecrireHeure(cr.cr.heuredebut)}
                                            - {this.ecrireHeure(cr.cr.heurefin)}
                                          </button>
                                        </span>
                                      );
                                    })}
                                  </div>
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Modal
                    show={this.state.showModal}
                    onHide={this.handleClose.bind(this)}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Mode de paiement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <img
                          className="img_filter"
                          style={{
                            marginLeft: "15%",
                            marginRight: "5%",
                          }}
                          onClick={this.checkoutAirtel.bind(this)}
                          src={AirtelLogo}
                          height="90"
                        />

                          <img src={MtnLogo}
                          onClick={this.checkoutMtn.bind(this)}
														className="img_filter"
														style={{
															marginRight: "5%",
														}}
														height="90" />


                        <img
                          src={CartMethod}
                          className="img_filter"
                          onClick={this.paiement.bind(this)}
                          height="120"
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                  </Modal>

                  <div className="submit-section proceed-btn text-right">
                  {
                        this.state.msgError ? 
                        <button disabled
                        style={{
                          fontSize: "15px",
                        }}
                        className="btn btn-bluefonce btn-sm"
                        onClick={this.checkCreneau.bind(this)}
                      >
                        Procéder au paiement
                      </button>
                        : 
                        <button
                        style={{
                          fontSize: "15px",
                        }}
                        className="btn btn-bluefonce btn-sm"
                        onClick={this.checkCreneau.bind(this)}
                      >
                        Procéder au paiement
                      </button>


                    }
                    {/* <Link to="/patient/checkout" className="btn btn-primary submit-btn">Procéder au Paiment</Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <>
            <Modal show={this.state.showC} onHide={this.handleCloseC}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Votre rendez-vous n'est pas encore confirmé. Veuillez cliquer
                  sur l'un des textes affichés ci-dessous
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Accordion>
                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      style={{ cursor: "pointer" }}
                    >
                      <h3 style={{ cursor: "pointer" }}>
                        Nouveau sur Kwaber santé ?
                      </h3>
                      {/* <button className="btn btn-bluefonce" type="submit">S'inscrire</button> */}
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                            <h1>Création du compte patient</h1> <br /><br />
                        <div className="col-md-12 col-lg-12 login-right">
                          <form onSubmit={this.handleSubmit}>
                            <FadeIn>
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label className="focus-label" htmlFor="name">
                                  {" "}
                                  Nom <span className="etoile"> * </span>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="ex: BALOU"
                                  id="name"
                                  onChange={(e) => {
                                    this.setState({ nom: e.target.value });
                                  }}
                                  value={this.state.nom}
                                />
                              </div>{" "}
                              <br />
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label className="focus-label" htmlFor="prenom">
                                  Prenom <span className="etoile"> * </span>
                                </label>
                                <input
                                  type="text"
                                  name="firstName"
                                  className="form-control"
                                  placeholder="ex: enock"
                                  id="prenom"
                                  onChange={(e) => {
                                    this.setState({
                                      firstName: e.target.value,
                                    });
                                  }}
                                  value={this.state.firstName}
                                />
                              </div>{" "}
                              <br />
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label className="focus-label" htmlFor="mail">
                                  Email <span className="etoile"> * </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="email"
                                  placeholder="Votre adresse mail"
                                  onChange={(e) => {
                                    this.setState({ email: e.target.value });
                                  }}
                                  value={this.state.email}
                                  id="mail"
                                />
                              </div>
                              <br />
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label className="focus-label" htmlFor="mobile">
                                  Numéro de téléphone N°1{" "}
                                  <span className="etoile"> * </span>
                                </label>
                                <PhoneInput
                                    className="form-control"
                                    country={'cg'}
                                    value={this.state.phone1}
                                    
                                    onChange={phone1 => this.setState({ phone1 })}
                                    inputProps={{
                                    name: 'Numéro de téléphone',
                                    required: true,
                                    
                                    }}
                                  />
                              </div>
                              <br />
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label className="focus-label" htmlFor="phone1">
                                  Numéro de téléphone N°2
                                  <span className="etoile"> (optionnel) </span>
                                </label>
                                <PhoneInput
                                    className="form-control"
                                    country={'cg'}
                                    value={this.state.phone2}
                                    
                                    onChange={phone2 => this.setState({ phone2 })}
                                    inputProps={{
                                    name: 'Numéro de téléphone',
                                    required: true,
                                    
                                    }}
                                  />
                                {/* <input
                                  type="text"
                                  className="form-control"
                                  name="phone2"
                                  placeholder="Votre numéro de téléphone2"
                                  id="phone1"
                                  onChange={(e) => {
                                    this.setState({ phone2: e.target.value });
                                  }}
                                  value={this.state.phone2}
                                /> */}
                              </div>{" "}
                              <br />
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label
                                  className="focus-label"
                                  htmlFor="dateNaissance"
                                >
                                  Date de Naissance{" "}
                                  <span className="etoile"> * </span>
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="dateNaissance"
                                  placeholder="votre date de naissance"
                                  id="dateNaissance"
                                  onChange={(e) => {
                                    this.setState({
                                      dateNaissance: e.target.value,
                                    });
                                  }}
                                  value={this.state.dateNaissance}
                                />
                              </div>{" "}
                              <br />
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label
                                  className="focus-label"
                                  htmlFor="adresse"
                                >
                                  Adresse<span className="etoile"> * </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="adress"
                                  placeholder="votre adresse "
                                  id="adresse"
                                  onChange={(e) => {
                                    this.setState({ adress: e.target.value });
                                  }}
                                  value={this.state.adress}
                                />
                              </div>
                              <br />
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label
                                  className="focus-label"
                                  htmlFor="adresseComplement"
                                >
                                  Complement d'adresse
                                  <span className="etoile"> (optionnel) </span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="complementAdress"
                                  placeholder="votre adresse "
                                  id="adresseComplement"
                                  onChange={(e) => {
                                    this.setState({
                                      complementAdress: e.target.value,
                                    });
                                  }}
                                  value={this.state.complementAdress}
                                />
                              </div>
                              <br />
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label
                                  className="focus-label"
                                  htmlFor="groupSanguin"
                                >
                                  Groupe Sanguin
                                  <span className="etoile"> * </span>
                                </label>
                                <br />
                                <select
                                  className="form-group form-control"
                                  onChange={(e) => {
                                    this.setState({ value: e.target.value });
                                  }}
                                  value={this.state.value}
                                  name="groupSanguin"
                                  id="groupSanguin"
                                >
                                  <option value="">
                                    {" "}
                                    votre groupe sanguin{" "}
                                  </option>
                                  <option value="a"> Groupe A</option>
                                  <option value="b">Groupe B</option>
                                  <option value="ab">Groupe AB</option>
                                  <option value="o">Groupe O</option>
                                </select>
                              </div>
                              <br />
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label className="focus-label" htmlFor="sexe">
                                  Sexe<span className="etoile"> * </span>
                                </label>{" "}
                                <br />
                                <input
                                  type="radio"
                                  name="genre"
                                  id="m"
                                  value="masculin"
                                  onChange={(e) => {
                                    this.setState({ genre: e.target.value });
                                  }}
                                />
                                <span className="mr-3"> M</span>
                                <input
                                  type="radio"
                                  name="genre"
                                  id="f"
                                  value="feminin"
                                  onChange={(e) => {
                                    this.setState({ genre: e.target.value });
                                  }}
                                />{" "}
                                <span>F</span>
                              </div>
                              <br />
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label
                                  className="focus-label"
                                  htmlFor="password"
                                >
                                  {" "}
                                  Mot de passe
                                  <span className="etoile"> * </span>
                                </label>
                                <Input.Password
                                  type="password"
                                  className="form-control"
                                  name="password"
                                  placeholder="votre Mot de passe"
                                  id="password"
                                  onChange={(e) => {
                                    this.setState({ passwrd: e.target.value });
                                  }}
                                  value={this.state.passwrd}
                                />
                                <br />
                                <em className="etoile">
                                  {" "}
                                  le mot de passe doit contenir au moins 6
                                  caractères ou plus{" "}
                                </em>
                              </div>
                              <br />
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <label
                                  className="focus-label"
                                  htmlFor="ConfirmPassword"
                                >
                                  {" "}
                                  Confirmation du Mot de passe
                                  <span className="etoile"> * </span>
                                </label>
                                <Input.Password
                                  type="password"
                                  className="form-control"
                                  placeholder="votre mot de passe "
                                  id="ConfirmPassword"
                                  onChange={(e) => {
                                    this.setState({
                                      confirmPassword: e.target.value,
                                    });
                                  }}
                                  value={this.state.confirmPassword}
                                />
                              </div>
                              <br />
                              {/* <input name="" type="checkbox"  required /> I accept the terms of service
														<label> </label> <br /> <br /> */}
                              <div
                                className="form-group"
                                style={{ margin: "0" }}
                              >
                                <input
                                  type="checkbox"
                                  name="regles"
                                  id="regles"
                                  onChange={(e) => {
                                    this.setState({ regle: e.target.value });
                                  }}
                                  checked={this.state.regle}
                                />{" "}
                                <span>
                                  {" "}
                                  <a href="#">
                                    J’accepte les conditions d’utilisation
                                  </a>{" "}
                                </span>
                              </div>
                              <br />
                              <p>
                                {" "}
                                (<span className="etoile">*</span>){" "}
                                <em>le champ est obligatoire </em>
                              </p>{" "}
                              <br />
                              <br />
                              {btnSendFormulaire}
                            </FadeIn>
                          </form>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="1"
                      style={{ cursor: "pointer" }}
                    >
                      <h3 style={{ cursor: "pointer" }}>
                        J'ai déjà un compte Kwaber santé
                      </h3>
                      {/* <button className="btn btn-bluefonce" type="submit">Connexion</button> */}
                    </Accordion.Toggle>

                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <div className="col-md-12 col-lg-12 login-right">
                          <div className="login-header">
                            <h1 style={{ fontSize: "25px" }}>Se connecter</h1>
                          </div>
                          <form onSubmit={this.submit}>
                          <FadeIn>
                          <div className="form-group" style={{ margin: "0" }}>
                            
                            <label
                              className="focus-label"
                              htmlFor="email"
                            ></label>
                          </div>
                          <div className="form-group" style={{ margin: "0" }}>
                            {
                              this.state.istrue ?
                              <PhoneInput
                                className="form-control"
                                country={'cg'}
                                value={this.state.email}
                                // onChange={(e) => {
                                //   this.setState({ phone1 });
                                // }}
                                onChange={email => {
                                  this.setState({ email })
                                  const regNumberPhone = /^[0-9]/;                               
                                    if(email == ""){
                                      this.setState({
                                        istrue: false
                                    })
                                    }
                                    
                                  
                                }}
                                inputProps={{
                                  name: 'Numéro de téléphone',
                                  required: true,                           
                                }}
                              /> :
                              <>
                                  <input
                              type="text"
                              name="email"
                              required="true"
                              value={this.state.email}
                              onChange={(e) =>{
                                 this.setState({email: e.target.value})
                                 const regNumberPhone = /^[0-9]/;
                                  if(regNumberPhone.test(e.target.value) || e.target.value[0] == '+' ){                              
                                    this.setState({
                                        istrue: true
                                    })
                                  }
                                } }
                              className="form-control"
                              placeholder="Votre adresse mail ou numéro de téléphone"
                            />
                              </>
                     }
                            <br /><br />
                              <Input.Password
                              type="password"
                              name="password"
                              required="true"
                              // ref={this.password}
                              onChange={(e) => {
                                this.setState({ passwordConnexion: e.target.value }
                                 
                                  );
                              }}
                              className="form-control"
                              name="password"
                              placeholder="Votre mot de passe"
                            />
                            
                            
                            <label
                              className="focus-label"
                              htmlFor="password"
                            ></label>
                            
                          </div>
                          <br />
                          <div className="text-right">
                            <Link to="/forgot-password" className="forgot-link">
                              Mot de passe oublié ?
                            </Link>
                          </div>

                          {/* <button
                            className="btn btn-bluefonce pull-right"
                            type="submit"
                          >
                            Se connecter
                          </button> */}
                          <Button type="submit"  className="btn btn-bluefonce pull-right" loading={this.state.loadings} onClick={this.submit}>
                          Se connecter
                              </Button>
                          <br />
                          <br />

                          <div className="text-center dont-have">
                            Vous n'avez pas de compte?
                            <Link
                              to="/register"
                              style={{ color: "rgb(21, 85, 141)" }}
                            >
                              {" "}
                              'S'inscrire
                            </Link>
                          </div>
                        </FadeIn>
                          </form>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Modal.Body>
              {/* <Modal.Footer>
				  <Button variant="secondary" onClick={this.handleCloseC}>
					Close
				  </Button>
				  <Button variant="primary" onClick={this.handleCloseC}>
					Save Changes
				  </Button>
				</Modal.Footer> */}
            </Modal>
          </>
        </div>
      );
    }
  }
}
export default BookingCentre;
