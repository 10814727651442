import React from "react";
import DashboardSidebar from "../sidebar/sidebar.jsx";
import IMG01 from "../../../../assets/images/patient.jpg";
import StickyBox from "react-sticky-box";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import swal from 'sweetalert';
import host from "../../../../../host.js";
import { Image } from "antd";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      firstName: "",
      email: "",
      phoneNumber: "",
      idpatient: null,
      idUser: null,
      token: null,
      uploadedFile: null,
      imageProfile: null,
      color: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeProfileImage = this.changeProfileImage.bind(this);
  }

  componentDidMount() {
    document.body.classList.add("account-page");
    let dbUser =
      JSON.parse(sessionStorage.getItem("user")) ||
      JSON.parse(sessionStorage.getItem("user"));

    // console.log("Session:", dbUser);

    if (dbUser === null || dbUser === undefined) {
      this.props.history.push("/login");
    }
    // console.log("session: ", dbUser.token);
    this.setState({
      name: dbUser.user.patient.nom,
      firstName: dbUser.user.patient.prenom,
      email: dbUser.user.patient.email,
      phoneNumber: dbUser.user.patient.numtelephone1,
      idpatient: dbUser.user.patient.id,
      idUser: dbUser.user.userId,
      token: dbUser.token,
       imageProfile: dbUser.user.photo,
    });
    // if (data.user.photo !== result.user.photo){
    //     data.user.photo = result.user.photo;
    //     this.setState({
    //         imageProfile
    //     })
    //     sessionStorage.setItem("user", JSON.stringify(data));
    //     window.location.reload();
    //   }

    // this.updateImage();
  }

  changeProfileImage = (event) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = () => {
      this.setState({
        uploadedFile: reader.result,
        color: false,
        // imageProfile: Date.now() + event.target.files[0].name
      });
      // console.log("PHOTO_USER", event.target.files[0].name);
    };
    this.updateImage();
  };

  updateImage = () => {
    // var formdata = new FormData();
    // formdata.append("userImage", this.state.uploadedFile);

    // var requestOptions = {
    //   method: 'POST',
    //   body: formdata,
    //   redirect: 'follow'
    // };
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      data: this.state.uploadedFile,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(host + `/user/updateImage/${this.state.idUser}`, requestOptions)
      // fetch(host+`/user/updateImage/22`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "error") {
          console.log('objectErr',result );
          this.setState({
            color: true,
          });
        }
        this.fetchDataUse();
      })
      .catch((error) => {
        // console.log("error", error);
      });
  };

  fetchDataUse = () => {
    var myHeaders = new Headers();
    myHeaders.append("auth", `${this.state.token}`);
    myHeaders.append("Content-Type", "application/json");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(host+`/user/${this.state.idUser}`, requestOptions)
      .then(response => {
        if(response.status === 200){
          // console.log("token", this.state.token);
          return response.json()
        }
        // console.log("token1", this.state.token);
      })
      .then(result => { 
        // console.log("SS",result)
        this.setState({
          imageProfile: result.photo
        })
      })
      .catch(error => console.log('error::', error));
  } 
  handleSubmit(event) {
    event.preventDefault();
    var myHeaders = new Headers();

    myHeaders.append("auth", `${this.state.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      username: this.state.email,
      role: "PATIENT",
      nom: this.state.name,
      photo: this.uploadedFile,
      patient: {
        id: this.state.idpatient,
        nom: this.state.name,
        prenom: this.state.firstName,
        slug: this.state.name,
        // "numidnat": null,
        // "datenaissance": null,
        // "numtelephone1": null,
        // "numtelephone2": null,
        email: this.state.email,
        // "datecreation": null,
        // "sexe": "FEMININ",
        // "adresse": null,
        // "complementadresse": null,
        // "groupesangin": "A+"
      },
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(host + `/user/update/${this.state.idUser}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const dataJson = sessionStorage.getItem("user");
        const data2 = dataJson && JSON.parse(dataJson);
        data2.user.patient.nom = this.state.name;
        data2.user.patient.prenom = this.state.firstName;
        data2.user.patient.email = this.state.email;
        data2.user.username = this.state.email;
        data2.user.photo = this.state.imageProfile;
        sessionStorage.setItem("user", JSON.stringify(data2));
        // console.log(result);
        toast.success(" le profil à été modifié!", {});
        window.location.reload();
      })
      .catch((error) => console.log("error", error));
  }

  render() {
    return (
      <div className="content">
        <ToastContainer />
        <div>
          {/* <div className="breadcrumb-bar">
                                <div className="container-fluid">
                                    <div className="row align-items-center">
                                        <div className="col-md-12 col-12">
                                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item"><a href="/home">Accueil</a></li>
                                                    <li className="breadcrumb-item active" aria-current="page">Paramètres du profil</li>
                                                </ol>
                                            </nav>
                                            <h2 className="breadcrumb-title">Paramètres du profil</h2>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
          <div class="content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                  <StickyBox offsetTop={20} offsetBottom={20}>
                    <DashboardSidebar />
                  </StickyBox>
                </div>
                <div className="col-md-7 col-lg-8 col-xl-9">
                  <div className="card">
                    <div className="card-body">
                      <form>
                        <div className="row form-row">
                          <div className="col-12 col-md-12">
                            <div className="form-group">
                              <div className="change-avatar">
                                <div className="profile-img">
                                <Image
                                width={150}
                                src={
                                  this.state.imageProfile === null ||
                                  this.state.imageProfile === "" ||
                                  this.state.imageProfile === undefined
                                    ? IMG01
                                    : this.state.imageProfile
                                }
                                className="img-fluid"                             
                                alt=""
                                  />
                                  {/* <img
                                    src={
                                      this.state.imageProfile === null ||
                                      this.state.imageProfile === "" ||
                                      this.state.imageProfile === undefined
                                        ? IMG01
                                        : this.state.imageProfile
                                    }
                                    alt="User"
                                  /> */}
                                </div>
                                <div className="upload-img">
                                  <div className="change-photo-btn">
                                    <span>
                                      <i className="fa fa-upload"></i> Importer
                                      une photo
                                    </span>
                                    <input
                                      type="file"
                                      className="upload"
                                      name="userImage"
                                      onChange={this.changeProfileImage}
                                    />
                                  </div>
                                  <small className="form-text text-muted">
                                    {" "}
                                    <strong
                                      className={
                                        this.state.color == true ? "etoile" : ""
                                      }
                                    >
                                      Format autorisé JPG, JPEG ou PNG. Taille
                                      max de 2MB
                                    </strong>{" "}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label>Prénom</label>
                              <input
                                type="text"
                                onChange={(e) =>
                                  this.setState({ firstName: e.target.value })
                                }
                                value={this.state.firstName}
                                className="form-control"
                                placeholder="Modifier le prénom"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label>Nom</label>
                              <input
                                type="text"
                                value={this.state.name}
                                onChange={(e) =>
                                  this.setState({ name: e.target.value })
                                }
                                className="form-control"
                                placeholder="Modifier le Nom"
                              />
                            </div>
                          </div>

                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                value={this.state.email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                                className="form-control"
                                placeholder="nom.prenom@example.com"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group">
                              <label>Numéro de téléphone</label>
                              <input
                                type="text"
                                placeholder="+1 202-555-0125"
                                value={this.state.phoneNumber}
                                onChange={(e) =>
                                  this.setState({ phoneNumber: e.target.value })
                                }
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="submit-section">
                          <button onClick={this.handleSubmit} type="submit" className="btn btn-bluefonce">
                            Enregistrer
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Profile;
