import React, { Component } from "react";
// import { Link } from 'react-router-dom';

class Contact extends Component {
  render() {
    return (
      <div style={{ margin: "10%" }}>
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="row blog-grid-row">
                  <div className="col-lg-12 col-md-12 mb-5">
                    <h2 className="text-center">
                      Des questions ? Des suggestions ? Des propositions de
                      partenariats ?
                    </h2>
                    <br />
                    <div className="mt-4">
                      <h3 className="text-center">
                        <strong>Contactez-nous:</strong> <br /> <br />{" "}
                        <a href="mailto:hello@kwaber.com">hello@kwaber.com</a>
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <h2 className="text-center">
                      Vous êtes un professionnel de santé et souhaitez devenir
                      partenaire de Kwaber Santé ?
                    </h2>
                    <br />
                    <br />
                    <div className="mb-5">
                      <h3 className="text-center">
                        <strong>Contactez Ninel : </strong> <br /> <br />{" "}
                        <a href="mailto:Ninel@Kwaber.com">Ninel@Kwaber.com</a>
                      </h3>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <h2 className="text-center mb-5">
                      Vous souhaitez rejoindre nos équipes ?
                    </h2>
                    <div>
                      <h3 className="text-center">
                        <a href="mailto:Hello@Kwaber.com">Hello@Kwaber.com</a>
                      </h3>
                    </div>
                  </div>

                  {/* <div className="col-md-4 col-sm-12">
                                        <div>
                                            <div className="blog-content">
                                                <h2 className="blog-title">
                                                    <strong><center>Pour toute question d'ordre général</center></strong></h2>
                                                <center><p className="mb-0">hello@kwaber.com</p></center>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div>
                                            <div className="blog-content">
                                                <h2 className="blog-title">
                                                    <strong><center>Jobs</center></strong></h2>
                                                <center><p className="mb-0">hello@kwaber.com</p></center>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                                    <div className="col-md-4 col-sm-12">
                                        <div>
                                            <div className="blog-content">
                                                <h2 className="blog-title">
                                                    <strong><center>Service client</center></strong></h2>
                                                <center><p className="mb-0">hello@kwaber.com</p></center>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div>
                                            <div className="blog-content">
                                                <h2 className="blog-title">
                                                    <strong><center>Presse</center></strong></h2>
                                                <center><p className="mb-0">presse@kwaber.com</p></center>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div>
                                            <div className="blog-content">
                                                <h2 className="blog-title">
                                                    <strong><center>Support partenaires</center></strong></h2>
                                                <center><p className="mb-0">partenaire@kwaber.com</p></center>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                                    <div className="col-md-12 col-sm-12">
                                        <div>
                                            <div className="blog-content">
                                                <h2 className="blog-title">
                                                    <strong><center>Adresse</center></strong></h2>
                                                <center><p className="mb-0">2 rue De Mongo Bissafi, Mpita. Pointe-Noire / République du Congo</p></center>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br><br></br><br></br><br></br><br></br><br></br><br></br> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Contact;
