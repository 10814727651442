import firebase from "firebase/app";

let app = firebase.initializeApp({
    apiKey: "AIzaSyB5SlCN2PAJJPQmYw1rVr4B0ZzTFGmwyDA",
    authDomain: "kwaber-sante.firebaseapp.com",
    projectId: "kwaber-sante",
    storageBucket: "kwaber-sante.appspot.com",
    messagingSenderId: "641341226571",
    appId: "1:641341226571:web:4c74b057d9781ca83597ce",
    measurementId: "G-185BTJMZZ9"
});


export default app;