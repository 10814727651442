import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import { Tabs, Tab } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { IMG01, IMG02, IMG03, IMG04, IMG05, IMG06, IMG07, IMG08 } from './img';
import img1 from "../../../assets/images/dr_armel.png";
import img2 from "../../../assets/images/dr_armel.png";
import img3 from "../../../assets/images/feature/feature-03.jpg";
import img4 from "../../../assets/images/feature/feature-04.jpg";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'


const images = [img1, img2, img3, img4];



function isNumeric(number) {
	return !isNaN(parseFloat(number)) && !isNaN(+number);
}

class LaboProfil extends Component {
	constructor(props) {
		super(props);
		this.state = {
			photoIndex: 0,
			isOpen: false,
			key: 1,
			activeModal: null,
			professionnel: null,
			prestation: []
		}
		this.handleSelect = this.handleSelect.bind(this);
	}
	componentDidMount() {
		const { match: { params } } = this.props;
		const id = parseInt(params.id);
		if (isNumeric(params.id)) {
			const id = parseInt(params.id);
			fetch(`https://kwaber-sante.herokuapp.com/type-centres/${4}`)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					console.log("tt",data)
					this.setState({
						professionnel: data.centres
					});
				});
		} else {
			this.props.history.push("/404")
		}

		fetch(`https://kwaber-sante.herokuapp.com/type-centres/${4}`)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					console.log("beuu: ",data.prestations)
					this.setState({
						prestation: []
					});
				});
	}
	
	handleSelect(key) {
		console.log("selected " + key);
		this.setState({ key })
	}

	openModal = (id) => {
		this.setState({ activeModal: id }, () => {
			console.log(this.state.activeModal, 'value');
		});
	}
	handleCloseModal = () => {
		this.setState({ activeModal: false }, () => {
			console.log(this.state.activeModal, 'state');
		});
	};

	render() {


		const { photoIndex, isOpen, professionnel } = this.state;

		if (professionnel == null) {
			return (<div style={{ textAlign: "center", marginTop: "50vh", marginBottom: "40vh" }}>
				<Loader
					type="Puff"
					color="#00BFFF"
					height={100}
					width={100}
					timeout={3000} //3 secs

				/>
			</div>)
		} else
			return (

				<div style={{ marginTop: "20px" }}>
					<div className="content">
						<div className="container">
							<div className="card">
								<div className="card-body">
									<div className="doctor-widget">
										<div className="doc-info-left">
											<div className="doctor-img">
												<img src="https://www.publicdomainpictures.net/pictures/90000/velka/emergency-sign-at-hospital.jpg" className="img-fluid" style={{ borderRadius: "2%" }} alt={professionnel.Nom + " " + professionnel.prenom} />
											</div>
											<div className="doc-info-cont">
												<h4 className="doc-name" >{professionnel[0].libelle }</h4>
												<p className="doc-department">{professionnel[0].adresse}</p>
												 {/* <strong>Quartier:</strong> <p className="doc-department"> {professionnel.quartier.libelle === null ? "" : professionnel.quartier.libelle }</p> 
												 <strong>Type de centre:</strong> <p className="doc-department"> {professionnel.type_centres[0].libelle}</p>  */}
											</div>           
										</div>
                                        <div className="doc-info-right">
											<div className="clini-infos">

											</div>
											<div className="clinic-booking"><Link to={{
												pathname: "/prestationCentre/"+ 5,
												aboutProps: {
													idProfessionel: professionnel.id
												}
											}} className="btn book-btn">Réserver</Link>
											</div>
										</div>
									</div>
								</div>
							</div>

                            <div className="card">
                            <div className="card-body pt-0">
                            <Tabs
										className="tab-view"
										activeKey={this.state.key}
										onSelect={this.handleSelect}
										id="controlled-tab-example"
									  >
										<Tab className="nav-item" eventKey={1} title="Description">
											<div className="col-md-1 col-lg-12">
												<div className="widget about-widget">
													
												</div>
											</div>
											<div className="row" >

												<div className="col-md col-lg" >
													<div className="widget education-widget">
														{/* <h4 className="widget-title">Formation</h4> */}
														<div className="experience-box">
                                                        <h4 className="widget-title">A propos de Nous</h4>

                                                        <p> 
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                     tempor incididunt ut labore et dolore magna aliqua. Bibendum neque 
                                                     egestas congue quisque egestas diam in. Cursus turpis massa tincidunt
                                                      dui ut ornare. In metus vulputate eu scelerisque felis imperdiet proin. Risus viverra adipiscing at in tellus. Quis blandit turpis cursus in hac habitasse platea dictumst. Dignissim enim sit amet venenatis urna cursus eget. Ac tincidunt vitae semper quis lectus nulla at volutpat. Convallis tellus id interdum velit laoreet id donec ultrices tincidunt. Elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus at. Nibh tortor id aliquet lectus. Ut tristique et egestas quis.                                                                                                
                                                    </p>
														</div>
													</div>
												</div>

												{/* <div className="col-md col-lg" >
													<div className="widget expertise-widget">
														<h4 className="widget-title">Centres</h4>
														<div className="experience-box">
															<ul className="experience-list">
																<li>Centre1</li>
																<li>Centre1</li>
																<li>Centre1</li>
																<li>Centre1</li>
																<li>Centre1</li>
															</ul>
														</div>
													</div>
												</div> */}

												{/* <div className="col-md col-lg" >
													<div className="widget education-widget">
														<h4 className="widget-title">Prix</h4>
														<div className="experience-box">
															<ul className="experience-list">
																<li>Prix1</li>
																<li>Prix1</li>
																<li>Prix1</li>
																<li>Prix1</li>
																<li>Prix1</li>
															</ul>
														</div>
													</div>
												</div> */}

											</div>
										</Tab>
										<Tab className="nav-item" eventKey={2} title="Nos prestations">
											<div>
												<div className="location-list">
													<div className="row">

														<div className="col-md-6">
															<div className="clinic-content">
																<ul>
																	{
																		this.state.prestation.map((data) => {
																			return(
																				<>
																					<li key={data.id} >
																				{	data.libelle}
																				</li> <br/>
																				</>
																			)
																		})
																	}
																</ul>													
															</div>
														</div>

														<div className="col-md-6">
															<div className="clinic-content">
																<ul style={{listStyle:"none"}}>
																	{
																		this.state.prestation.map((data) => {
																			return(
																				<>
																					<li key={data.id} >
																					{data.prixXAF !== null ? data.prixXAF + " XAF" : "Pas de prix fixe" }
																				</li> <br/>
																				</>
																			)
																		})
																	}
																</ul>													
															</div>
														</div>
																																				
													</div>
												</div>											
											</div>

										</Tab>
										
										<Tab className="nav-item" eventKey={4} title="Horaires">
											<div className="row">
												<div className="col-md-6 offset-md-3">

													<div className="widget business-widget">
														<div className="widget-content">
															<div className="listing-hours">
																<div className="listing-day current">
																	<div className="day"> Aujourd'hui <span>5 Nov 2019</span></div>
																	<div className="time-items">
																		<span className="open-status"><span className="badge bg-success-light">Ouvert </span></span>
																		<span className="time">07:00 du Matin - 09:00 du soir</span>
																	</div>
																</div>
																<div className="listing-day">
																	<div className="day">Lundi</div>
																	<div className="time-items">
																		<span className="time">07:00 du Matin - 09:00 du soir</span>
																	</div>
																</div>
																<div className="listing-day">
																	<div className="day">Mardi</div>
																	<div className="time-items">
																		<span className="time">07:00 du Matin - 09:00 du soir</span>
																	</div>
																</div>
																<div className="listing-day">
																	<div className="day">Mercredi</div>
																	<div className="time-items">
																		<span className="time">07:00 du Matin - 09:00 du soir</span>
																	</div>
																</div>
																<div className="listing-day">
																	<div className="day">Jeudi</div>
																	<div className="time-items">
																		<span className="time">07:00 AM - 09:00 du soir</span>
																	</div>
																</div>
																<div className="listing-day">
																	<div className="day">Vendredi</div>
																	<div className="time-items">
																		<span className="time">07:00 AM - 09:00 du soir</span>
																	</div>
																</div>
																<div className="listing-day">
																	<div className="day">Samedi</div>
																	<div className="time-items">
																		<span className="time">07:00 AM - 09:00 du soir</span>
																	</div>
																</div>
																<div className="listing-day closed">
																	<div className="day">Dimanche</div>
																	<div className="time-items">
																		<span className="time"><span className="badge bg-danger-light">fermé</span></span>
																	</div>
																</div>
															</div>
														</div>
													</div>


												</div>
											</div>
										</Tab>
									</Tabs>

                            </div>

                            </div>

						</div>
					</div>
				</div >

			);

	}

}



export default LaboProfil;
