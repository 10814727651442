import React, { Component } from "react";
import Select from "react-select";
import SelectSearch, { fuzzySearch } from 'react-select-search';
// import { Link } from "react-router-dom";
import { Alert } from "antd";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
// import { WindowScrollController } from "@fullcalendar/react";
import host from "../../../../host";
// import SearchMedecin from '../../pages/SearchMedecin';

// import { colourOptions, groupedOptions } from '../data';

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};
const imgStyle = {
  borderRadius: '50%',
  verticalAlign: 'middle',
  marginRight: 10,
  width:"50px",
  height: "50px"
};

const formatGroupLabel = (data) => (
  

// return (
//     <button {...props} className={className} type="button">
//         <span><img alt="" style={imgStyle} width="32" height="32" src={option.photo} /><span>{option.name}</span></span>
//     </button>
// );
  <div style={groupStyles}>
    <h6>
      <strong>{data.label}</strong>
    </h6>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);



export default class homeSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      villes: [],
      specialites: [],
      medecinSpecialite: [],
      nameVille: "",
      msgError: "",
      selectValue: "Toutes les villes",
      selectValueProf: "1",
      medecin: [],
      centre: [],
      
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeProf = this.handleChangeProf.bind(this);
  }
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.selectValueProf) {
      return <Redirect to="/patient/doctor-profile/130" />;
    }
  };

  handleChangeSelect = (selectedOption) => {
    //Le choix de l'élement dans le select Home page.

    // console.log(`Option selected:`, selectedOption);
    if (selectedOption.idprofessionnelsante) {
      //-----------------Si c'est un professionnel de Santé
      const id = selectedOption.idprofessionnelsante;
      this.setState({
        msgError: "",
      });
      window.location.assign(`/patient/doctor-profile/${id}`);
    } else if (selectedOption.idcentre) {

      // --------------Si c'est un Centre de Santé.
      const id = selectedOption.idcentre;
      this.setState({
        msgError: "",
      });
      window.location.assign(`/centreProfil/${id}`);
    } else if (selectedOption.idspecialite) {

      //---------------Si c'est une Spécialité.
      const id = selectedOption.idspecialite;
      if (selectedOption.professionnelsante.length === 0) {
        this.setState({
          msgError:"Il n'y a aucun professionnel de santé dans cette spécialité.",
        });
      } else {
        this.setState({
          msgError: "",
        });
        window.location.assign(`/specialite/${id}`);
      }
    } else {
      window.location.assign(`/home`);
    }
  };

  //Render la liste
//    renderFriend = (props, option, snapshot, className) => {
//     const imgStyle = {
//         borderRadius: '50%',
//         verticalAlign: 'middle',
//         marginRight: 10,
//     };

//     return (
//         <button {...props} className={className} type="button">
//             <span><img alt="" style={imgStyle} width="32" height="32" src={option.photo} /><span>{option.name}</span></span>
//         </button>
//     );
// }
  componentDidMount() {
    fetch(host + `/ps`)
      //------------Appel API de la liste des professionnels de Santé.
      .then((response) => {
        if(response.status === 200){
          return response.json();
        }
      })
      .then((data) => {
        // console.log("DATATEST",data);
        let tab = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
           if (element.user.isActive === true) {
               tab.push(element)
           }
        }

        const villeData = tab.map((list) => {
          
            { console.log("LIST_de: ", list)}
         

          // const prenom = list.nom + " " + list.prenom;
          
          const prenom =  <div className="row">
          <div className="col-2">
           <img style={imgStyle} src={list.user.photo ? list.user.photo : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} alt="" />
          </div>
          <div className="col-10">
         { list.nom + " " + list.prenom}
          <br/> <em> {list.specialite ? list.specialite.libelle : ""}</em>
          </div>
  </div>
    
          return {
            label:  list.nom + " " + list.prenom,
            // img: list.photo ? list.photo : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png",
            value: list.idprofessionnelsante,
            ...list,
          };
        })
        this.setState({
          villes: villeData,
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    //-----------L'appel API centre
    fetch(host + "/centres")
      .then((response) => {
        if (response.status === 200) {
          return response.json(); 
        }
      })
      .then((donnee) => {
        // console.log("CENTRE",donnee.centre);
        let specialitesData = donnee.centre.map((list) => {
          return {
            label: list.libelle,
            value: list.idcentre,
            ...list,
          };
        });
        this.setState({
          specialites: specialitesData,
          formatGroupLabel: formatGroupLabel,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    // *************L'appel API centre*********************************

    //-------------L'appel de l'api spécialité
    fetch(host + "/specialite")
      .then((response) => {
        if(response.status === 200){
          return response.json();
        }
      })
      .then((donnee) => {
        // console.log("spécialité : ",donnee);
        let specialites = donnee.map((presta) => {
          return {
            label: presta.libelle,
            value: presta.id,
            ...presta,
          };
        })
        // console.log("SPEC:", )
        this.setState({
          medecinSpecialite: specialites,
          formatGroupLabel: formatGroupLabel,
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    //**********************L'appel de l'api spécialité********************** */
  }

  handleChange = (e) => {
    this.setState({
      selectValue: e.target.value,
    });
  };

  handleChangeProf = (e) => {
    this.setState({
      selectValueProf: e.target.value,
    });
  };

  render() {
    const groupedOptions = [
      {
        label: "Médecin",
        options: this.state.villes,
      },
      {
        label: 'centre',
        options: this.state.specialites,
      },
      {
        label: "Spécialité",
        options: this.state.medecinSpecialite,
      },
    ];
    return (
      <section className="section section-search">
        <div className="container-fluid">
          <div className="banner-wrapper">
            <div className="banner-header text-center">
              <h2>
                Réservez une consultation ou payez les frais médicaux chez un
                professionnel de santé au Congo Brazzaville
              </h2>
              <p>
                Découvrez les meilleurs médecins, cliniques, hôpitaux près de
                chez vous.
              </p>
            </div>

            <div className="row">
              <div className="col-md-12 col-sm-12">
                <form
                  action={
                    "/patient/search-doctor"
                    // this.state.selectValueProf == 1
                    //   ? "/patient/search-doctor"
                    //   : this.state.selectValueProf == 2
                    //   ? "/patient/search-doctor"
                    //   : this.state.selectValueProf == 3
                    //   ? "/patient/searchCentre"
                    //   : this.state.selectValueProf == 4
                    //   ? "/patient/searchLabo"
                    //   : "/home"
                  }
                >
                  <div className="row">
                    {/* <div className="col-md-3 col-sm-12">
                      <div className="form-group">
                        <select
                          class="form-control"
                          name="ville"
                          id="ville-select"
                          value={this.state.selectValue}
                          onChange={this.handleChange}
                        >
                          <option value="Toutes les villes" selected="selected">
                            Toutes les villes
                          </option>
                          {this.state.villes.map((ville) => {
                            return (
                              <option value={ville.libelle}>
                                {ville.libelle}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div> */}
                    <div className="col-md-8 col-sm-12">
                      {/* <div className="form-group">
                        <select class="form-control" name="specialite" id="specialite-select">
                                <option value="1">Toutes les spécialités</option>
                                <option value="2">Médecin, dentiste, sage-femme... </option>
                                <option value="3">Hôpital, clinique, cabinet... </option>
                                <option value="4">Laboratoire d'analyse</option>
                                </select>
                        <select
                          class="form-control"
                          name="specialite"
                          id="specialite-select"
                          value={this.state.selectValueProf}
                          onChange={this.handleChangeProf}
                        >
                          <option value="1" selected="selected">Tous les personnels de santé, établissement...</option>
                          <option value="2" selected="selected">
                            Médecin, dentiste, sage-femme...
                          </option>
                          <option value="3">
                            Hôpital, clinique, cabinet...
                          </option>
                          <option value="4"> Laboratoire d'analyse </option>
                        </select>
                      </div> */}
                     
                      <Select
                     
                        defaultValue={this.state.specialites[1]}
                        options={groupedOptions}
                        value="text"
                        placeholder="Médecin, dentiste, sage-femme , spécialité ..."
                        onChange={this.handleChangeSelect}
                        formatGroupLabel={formatGroupLabel}
                      />{" "}
                      <br />
                      {this.state.msgError ? (
                        <Alert
                          message={this.state.msgError}
                          type="info"
                          showIcon
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <br /> <br />
                    <div className="col-md-3 col-sm-12">
                      <button
                        style={{
                          backgroundColor: "rgb(21,85,141)",
                          color: "white",
                          height: "3px !important" 
                          // marginBottom: "0px",
                        }}
                        type="submit"
                        className="btn "
                        
                      >
                        <i
                          className="fas fa-search"
                          width="1px"
                          height="3px"
                        ></i>{" "}
                        Rechercher
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
