import React, { Component } from "react";
// import { IMG01, IMG02, IMG03, IMG04 } from './img.jsx';
//slider
// import { Link } from "react-router-dom";
import Slider from "react-slick";
// import axios from "axios"
import Skeleton from "react-loading-skeleton";
import host from "../../../../host.js";
import { HashLink as Link } from 'react-router-hash-link';

class HomeBookDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = { professionnel: [] };
  }
  // handleClick = () => {
  //     console.log('this is:');
  // }
  componentDidMount() {
    this.setState({
      profesionnel: [],
    });
    fetch(host + `/ps`)
      .then((response) => {
        if(response.status === 200){
          return response.json();
        }
      })
      .then((data) => {
        let tab = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
           if (element.user.isActive == true) {
               tab.push(element)
           }
        }

        this.setState({
          professionnel: tab,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // getInfo() {
  //     const nom = document.getElementById('nom').innerText
  //     window.sessionStorage.setItem("name", nom)
  //     window.location.replace("/inconnue");
  //     console.log("text -- ");
  //     console.log("text -- ", nom);
  // };

  render() {
    const settings = {
      width: 400,
      dots: false,

      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      centerPadding: "10px",
      arrows: true,
      centerMode: true,
      responsive: [
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          },
        },
      ],
    };

    return (
      <section className="section section-doctor">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 col-sm-12">
              <div className="section-header">
                <h2 className="text-justify">Prenez rendez-vous avec un professionnel de santé</h2>
              </div>
              <div className="about-content text-justify">
                <p>
                  Avec Kwaber Santé, prenez rendez-vous en ligne auprès de
                  professionnels de santé assermentés, pour une consultation
                  physique ou à distance. Les consultations sont payables en ligne
                  et à l’avance. <br />
                  Si vous êtes à l’étranger, Kwaber Santé vous permet également
                  de prendre rendez-vous et de payer la consultation pour le
                  compte d'un bénéficiaire situé au Congo. Une fois votre
                  rendez-vous confirmé, vous recevrez un rappel automatique par
                  SMS ou par email{" "}
                </p>
                {/* <Link to="">Lire plus</Link> */}
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="doctor-slider slider">
                <Slider {...settings}>
                  {this.state.professionnel.length === 0
                    ? [1, 2, 3, 4, 5].map((_) => {
                        return <Skeleton height={350} width={250} />;
                      })
                    : this.state.professionnel.map((profesionnel) => (
                        <ProfessionnelDeSante
                          key={profesionnel.idprofessionnelsante}
                          professionneldata={profesionnel}
                        />
                      ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

/*
                    <button onClick={this.getInfo}>Get info</button>
                    <button onClick={() => this.handleClick()}>
                        Click me
                    </button>
                    */
class ProfessionnelDeSante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      professionnel: this.props.professionneldata,
      tabFavoris: [],
    };
  }
  componentDidMount() {
    // fetch(`https://kwaber-sante.herokuapp.com/patients/${idPatient}`)
    //     .then((response) => {
    //         return response.json();
    //     })
    //     .then((data) => {
    //         console.log(data)
    //         this.setState({
    //             tabFavoris: data.favoris
    //         });
    //         console.log(data.favoris, "compo di month")
    //     });
  }

  // estPresent(pro) {
  //     let retour = false
  //     this.state.tabFavoris.forEach(element => {
  //         if (pro.id == element.id) {
  //             retour = true
  //         }
  //     });
  //     return retour
  // }

  // async favori() {
  //     let idPatient = 1

  //     let tabFavoris = this.state.tabFavoris

  //     if (this.estPresent(this.state.professionnel) == true) {
  //         console.log("ici")
  //         let tabIntermediaire = []
  //         this.state.tabFavoris.forEach(element => {
  //             if (this.state.professionnel.id == element.id) { }
  //             else {
  //                 tabIntermediaire.push(element)
  //                 console.log(element, "element")
  //             }
  //         });
  //         axios.put(`https://kwaber-sante.herokuapp.com/patients/${idPatient}`, {
  //             favoris: tabIntermediaire
  //         })
  //             .then(response => {
  //                 this.componentDidMount()
  //                 console.log(response, "supprimer")
  //             });

  //     } else {

  //         console.log(tabFavoris, "avant push")
  //         tabFavoris.push(this.state.professionnel)
  //         axios.put(`https://kwaber-sante.herokuapp.com/patients/${idPatient}`, {
  //             favoris: tabFavoris
  //         })
  //             .then(response => {
  //                 this.componentDidMount()
  //                 console.log(response, "ajout")
  //             });

  //     }

  // }

  render() {
    // console.log("Voici le console.log ", this.state.professionnel);
    return (
      <div className="profile-widget" style={{margin: "20px"}}>
        <div className="">
          <Link
            to={
              "/patient/doctor-profile/" +
              this.state.professionnel.idprofessionnelsante
            }
          >
            <img
              className="img-fluid text-center"
              style={{
                width: "200px",
                height: "150px",
                borderRadius: "3%",
                
              }}
              alt="User"
              src={
                this.state.professionnel.user.photo
                  ? this.state.professionnel.user.photo
                  : // host + `/user/images/${this.state.professionnel.user.photo}`
                    "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
              }
            />
          </Link>

          {/* <div className="fav-btn">
                        <i className="far fa-bookmark" aria-hidden="true" onClick={this.testC()}></i>
                        <button className="btn" aria-hidden="true" onClick={this.favori.bind(this)}>
                            <i className="far fa-bookmark"></i>
                        </button>
                    </div> */}
        </div>

        <div className="pro-content">
          <h3 className="title">
            <Link
              id="nom"
              to={
                "/patient/doctor-profile/" +
                this.props.professionneldata.idprofessionnelsante+"#profil-doctor"
              }
            >
              {this.props.professionneldata.nom +
                " " +
                this.props.professionneldata.prenom}{" "}
            </Link>
          </h3>
          <p className="speciality">
            {this.state.professionnel.specialite == null ||
            this.state.professionnel.specialite == undefined
              ? "Aucune spécialité "
              : this.state.professionnel.specialite.libelle}{" "}
          </p>

          {/* <ul className="available-info">
                        <li>
                            {
                                this.props.professionneldata.centres.map((centre) => {
                                    return <li key={"centre" + centre.id}>
                                        <div>
                                            <i className="fas fa-map-marker-alt "></i>{centre.libelle}
                                        </div></li>
                                })
                            }
                    
                        </li>
                    </ul> */}
          <div className="row">
          {/* <div className="row row-sm"> */}
            <div className="col-6">
              <Link
                to={
                  "/patient/doctor-profile/" +
                  this.state.professionnel.idprofessionnelsante+"#profil-doctor"
                }
                className="btn view-btn"
              >
                Voir <br/> profil
              </Link>
            </div>
            <div className="col-6">
              <Link
                to={{
                  pathname:
                    "/prestation/" +
                    this.state.professionnel.idprofessionnelsante,
                    // this.state.professionnel.idprofessionnelsante+"#prestation-doctor",
                  aboutProps: {
                    idProfessionel:
                      this.state.professionnel.idprofessionnelsante,
                  },
                }}
                className="btn book-btn"
              >
                Prendre RDV
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HomeBookDoctor;
