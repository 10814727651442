// import React, { Component } from 'react';
// import { Link, NavLink } from 'react-router-dom';
// import Nav from 'react-bootstrap/Nav';
// import IMG01 from '../../../assets/images/doctor-thumb-02.jpg';
// class DoctorSidebar extends Component {

//     logout = (e) => {
//         e.preventDefault()
//         sessionStorage.removeItem("user")
//         sessionStorage.removeItem("user")
//         window.location.assign('/login')
//     }

//     render() {
//         const dataJson = sessionStorage.getItem('user');
//         const data = dataJson && JSON.parse(dataJson);

//         return (
//             <div className="profile-sidebar">
              
//                 <div className="widget-profile pro-widget-content">
//                     <div className="profile-info-widget">
//                         <Link href="#" className="booking-doc-img">
//                             {/* <img src={data.user.photo[0].url} alt="User" /> */}
//                            <img src={(data.user.photo[0] !== null && data.user.photo[0] !== undefined) ? data.user.photo[0].url : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} />
//                         </Link>
//                         <div className="profile-det-info">
//                             <h3>
//                                 {
//                                     data ? data.user.prenom + " " + data.user.nom : ""
//                                 }

//                             </h3>

//                             <div className="patient-details">
//                                 <h5 className="mb-0"> {data ? data.user.specialite : ""} </h5>
//                                 <h6> {data ? data.user.email : ""} </h6>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="dashboard-widget">


//                     <Nav className="dashboard-menu">

//                         <Nav.Item>
//                             <NavLink to="/doctor/doctor-dashboard" activeClassName="active">
//                                 <i className="fas fa-columns"></i>
//                                 <span>Tableau de bord</span>
//                             </NavLink>
//                         </Nav.Item>

//                         {/* <Nav.Item>
//                             <NavLink to="/doctor/appointments" activeClassName="active">
//                                 <i className="fas fa-calendar-check"></i>
//                                 <span>Rendez-vous</span>
//                             </NavLink>
//                         </Nav.Item> */}
//                         <Nav.Item>
//                             <NavLink to="/doctor/mes-prestations">
//                                 <i class="fas fa-align-justify"></i>
//                                 <span>Mes Prestations</span>
//                             </NavLink>
//                         </Nav.Item>
//                         <Nav.Item>
//                             <NavLink to="/doctor/my-patients" activeClassName="active">
//                                 <i className="fas fa-user-injured"></i>
//                                 <span>Mes patients</span>
//                             </NavLink>
//                         </Nav.Item>
//                         <Nav.Item>
//                             <NavLink to="/doctor/schedule-timing" activeClassName="active">
//                                 <i className="fas fa-hourglass-start"></i>
//                                 <span>Mes disponibilités</span>
//                             </NavLink>
//                         </Nav.Item>
//                         {/*
//                         <Nav.Item>
//                             <NavLink to="/doctor/invoice">
//                                 <i className="fas fa-file-invoice"></i>
//                                 <span>Mes factures</span>
//                             </NavLink>
//                         </Nav.Item>

//                          */}

//                         {/*
//                           <Nav.Item>
//                             <NavLink to="/doctor/chat-doctor">
//                                 <i className="fas fa-comments"></i>
//                                 <span>Mes messages</span>
//                                 <small className="unread-msg">23</small>
//                             </NavLink>
//                         </Nav.Item>
//                          */}

//                         {/**  <Nav.Item>
//                             <NavLink to="/doctor/profile-setting">
//                                 <i className="fas fa-user-cog"></i>
//                                 <span>Paramètres du profil</span>
//                             </NavLink>
//                         </Nav.Item> */}
//                         {/**
//                         <Nav.Item>
//                             <NavLink to="/social-media">
//                                 <i className="fas fa-share-alt"></i>
//                                 <span>Mes réseaux sociaux</span>
//                             </NavLink>
//    </Nav.Item>**/}
//                         {/** <Nav.Item>
//                             <NavLink to="/doctor-change-passwword">
//                                 <i className="fas fa-lock"></i>
//                                 <span>Changer de mot de passe</span>
//                             </NavLink>
//                            </Nav.Item>**/}
//                         <Nav.Item>
//                             <NavLink to="" activeClassName="active">
//                                 <i className="fas fa-sign-out-alt"></i>
//                                 <span onClick={this.logout}>Déconnexion</span>
//                             </NavLink>
//                         </Nav.Item>
//                     </Nav>

//                 </div>
//             </div>
//         );
//     }
// }
// class DetailProfDeSante extends Component {
//     render() {
//         return (
//             <div className="widget-profile pro-widget-content">
//                 <div className="profile-info-widget">
//                     <Link href="#" className="booking-doc-img">
//                         <img src={
//                             this.props.professionneldata.photo[0] == null ? "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" : this.props.professionneldata.photo[0].url
//                         } alt="User" />
//                     </Link>
//                     <div className="profile-det-info">
//                         <h3>{this.props.professionneldata.Nom + " " + this.props.professionneldata.prenom}</h3>

//                         <div className="patient-details">
//                             <h5 className="mb-0">BDS, MDS - Oral & Maxillofacial Surgery</h5>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//         );
//     }
// }
// export default DoctorSidebar;

import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
// import IMG01 from '../../../assets/images/doctor-thumb-02.jpg';
// import host from '../../../../host';

class DoctorSidebar extends Component {

    logout = (e) => {
        e.preventDefault()
        sessionStorage.removeItem("user")
        sessionStorage.removeItem("user")
        window.location.assign('/login')
    }

    render() {
        const dataJson = sessionStorage.getItem('user');
        const data = dataJson && JSON.parse(dataJson);
        if (!data) {
            window.location.assign("/");
                  } 

        // console.log("Session:",data);
        return (
            <div className="profile-sidebar">
              
                <div className="widget-profile pro-widget-content">
                    <div className="profile-info-widget">
                        <span className="booking-doc-img">
                            {/* <img src={data.user.photo[0].url} alt="User" /> */}
                            <img  alt="" src={(data.user.photo) ? data.user.photo  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} />
                            {/* <img src={(data.user.photo) ? host+`/user/images/${data.user.photo} `  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} /> */}
                           {/* <img src="https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"/> */}
                        </span>
                        <div className="profile-det-info">
                            <h3>
                                {
                                    data ? data.user.professionnelsante.prenom + " " + data.user.professionnelsante.nom : ""
                                }

                            </h3>

                            <div className="patient-details">
                                {/* <h5 className="mb-0"> {data ? data.user.specialite : ""} </h5> */}
                                <h6> {data ? data.user.professionnelsante.email : ""} </h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-widget">


                    <Nav className="dashboard-menu">

                        <Nav.Item>
                            <NavLink to="/doctor/doctor-dashboard" activeClassName="active">
                                <i className="fas fa-columns"></i>
                                <span>Tableau de bord</span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to="/doctor/doctor-profile" activeClassName="active">
                                <i className="fas fa-user-cog"></i>
                                <span>Profil</span>
                            </NavLink>
                        </Nav.Item>

                        {/* <Nav.Item>
                            <NavLink to="/doctor/appointments" activeClassName="active">
                                <i className="fas fa-calendar-check"></i>
                                <span>Rendez-vous</span>
                            </NavLink>
                        </Nav.Item> */}
                        <Nav.Item>
                            <NavLink to="/doctor/mes-prestations">
                                <i class="fas fa-align-justify"></i>
                                <span>Mes Prestations</span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to="/doctor/my-patients" activeClassName="active">
                                <i className="fas fa-user-injured"></i>
                                <span>Mes patients</span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to="/doctor/schedule-timing" activeClassName="active">
                                <i className="fas fa-hourglass-start"></i>
                                <span>Mes disponibilités</span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to="/salle-dattente" activeClassName="active">
                                <i className="fas fa-hourglass-start"></i>
                                <span>Salle d'attente</span>
                            </NavLink>
                        </Nav.Item>
                        
                        <Nav.Item>
                            <NavLink to="/doctor/mesfactures">
                                <i className="fas fa-file-invoice"></i>
                                <span>Mes factures</span>
                            </NavLink>
                        </Nav.Item>

                        {/* <Nav.Item>
                            <NavLink to="/doctor/invoice">
                                <i className="fas fa-file-invoice"></i>
                                <span>Mes factures</span>
                            </NavLink>
                        </Nav.Item>

                        

                        
                          <Nav.Item>
                            <NavLink to="/doctor/chat-doctor">
                                <i className="fas fa-comments"></i>
                                <span>Mes messages</span>
                                <small className="unread-msg">23</small>
                            </NavLink>
                        </Nav.Item>
                        

                          <Nav.Item>
                            <NavLink to="/doctor/profile-setting">
                                <i className="fas fa-user-cog"></i>
                                <span>Paramètres du profil</span>
                            </NavLink>
                        </Nav.Item>
                        
                        <Nav.Item>
                            <NavLink to="/social-media">
                                <i className="fas fa-share-alt"></i>
                                <span>Mes réseaux sociaux</span>
                            </NavLink>
    </Nav.Item> */}

                          <Nav.Item>
                            <NavLink to="/doctor-change-passwword">
                                <i className="fas fa-lock"></i>
                                <span>Changer de mot de passe</span>
                            </NavLink>
                           </Nav.Item>
                        <Nav.Item>
                            <NavLink to="" activeClassName="active">
                                <i className="fas fa-sign-out-alt"></i>
                                <span onClick={this.logout}>Déconnexion</span>
                            </NavLink>
                        </Nav.Item>
                    </Nav>

                </div>
            </div>
        );
    }
}
class DetailProfDeSante extends Component {
    render() {
        return (
            <div className="widget-profile pro-widget-content">
                <div className="profile-info-widget">
                    <Link href="#" className="booking-doc-img">
                        {/* <img src={
                            this.props.professionneldata.photo[0] == null ? "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" : this.props.professionneldata.photo[0].url
                        } alt="User" /> */}
                        <img src={
                            "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                        } alt="User" />
                    </Link>
                    <div className="profile-det-info">
                        <h3>{this.props.professionneldata.Nom + " " + this.props.professionneldata.prenom}</h3>

                        <div className="patient-details">
                            <h5 className="mb-0">BDS, MDS - Oral & Maxillofacial Surgery</h5>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default DoctorSidebar;
