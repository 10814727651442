import React, { useEffect, useState } from "react";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";

import api from "./api";
// import Router from "next/router";
// import axios from 'axios';
import jQuery from "jquery";
// import Link from 'next/link';
// import Settings from "./sending";
import { API_KEY_SECRET } from "./config";
import host from "../host";
import moment from "moment";


export default function PayementServiceCard() {
    // const [amount, setAmount] = useState(0);
    // const [currency, setCurrency] = useState("");
    const [clientSecret, setClientSecret] = useState(null);
    const [error, setError] = useState(null);
    const [metadata, setMetadata] = useState(null);
    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState(false);
    // const [token, setToken] = useState(undefined);
    // const [users, setUsers] = useState(undefined);
    // const [infoPay, setInfoPay] = useState(undefined);
    // const [frais, setFrais] = useState(undefined);
    // const [type, setType] = useState(undefined);
    // const [codePromo, setCodePromo] = useState(undefined);
    // const [idTransaction, setIdTransaction] = useState(undefined);
    // const [smsData, setSmsData] = useState(undefined);
    // const [smsText, setSmsText] = useState(undefined);
    // const [remise, setRemise] = useState(undefined);
    // const [idsCreneauReserve, setidsCreneauReserve] = useState([])
    const [transactionId, setTransactionId] = useState("");
    const [prix, setPrix] = useState("");
    const stripe = useStripe();
    const elements = useElements();
    // const url = Settings.url;
    // const handleTotal = (montant, frais) => {
    //     return parseFloat(montant) + parseFloat(frais);
    // }

    // const handleGrille = value => {
    //     if (value >= 1 && value < 10) {
    //         return 2;
    //     }
    //     if (value >= 10 && value <= 30) {
    //         return 3;
    //     }
    //     if (value >= 31 && value <= 50) {
    //         return 4;
    //     }
    //     if (value >= 51 && value <= 100) {
    //         return 5.5;
    //     }
    //     if (value >= 101 && value <= 150) {
    //         return 6.5;
    //     }
    //     if (value >= 151 && value <= 200) {
    //         return 7.5;
    //     }
    //     if (value >= 201 && value <= 250) {
    //         return 8.5;
    //     }
    //     if (value >= 251 && value <= 300) {
    //         return 9.5;
    //     }
    //     if (value >= 301 && value <= 350) {
    //         return 10.5;
    //     }
    //     if (value >= 351 && value <= 400) {
    //         return 12;
    //     }
    //     if (value >= 401 && value <= 450) {
    //         return 13.5;
    //     }
    //     if (value >= 451 && value <= 500) {
    //         return 15;
    //     }
    //     return 0;
    // }
    // const handleGrilleStand = value => {
    //     if (value >= 1 && value < 10) {
    //         return 1;
    //     }
    //     if (value >= 10 && value <= 30) {
    //         return 2;
    //     }
    //     if (value >= 31 && value <= 50) {
    //         return 3;
    //     }
    //     if (value >= 51 && value <= 100) {
    //         return 4;
    //     }
    //     if (value >= 101 && value <= 150) {
    //         return 5;
    //     }
    //     if (value >= 151 && value <= 200) {
    //         return 6;
    //     }
    //     if (value >= 201 && value <= 250) {
    //         return 7;
    //     }
    //     if (value >= 251 && value <= 300) {
    //         return 8;
    //     }
    //     if (value >= 301 && value <= 350) {
    //         return 9;
    //     }
    //     if (value >= 351 && value <= 400) {
    //         return 10;
    //     }
    //     if (value >= 401 && value <= 450) {
    //         return 11;
    //     }
    //     if (value >= 451 && value <= 500) {
    //         return 12;
    //     }
    //     return 0;
    // }
    // const handleFrais = (frais, montantEnvoyer) => {
       
    // }
    useEffect(() => {
        
        // Step 2: Create PaymentIntent over Stripe API

        const infoRdv = JSON.parse(window.sessionStorage.getItem('rdv'));
        // const info = JSON.parse(window.sessionStorage.getItem('user'));

        
        setPrix(infoRdv.prix)

            api.createPaymentIntent({
            payment_method_types: ["card"],
            amount:  infoRdv.prix * 100,
            description: "Test Stripe Kwaber sante"
        })
            .then(clientSecret => {
                setClientSecret(clientSecret);
            })
            .catch(err => {
                setError(err.message);
                console.log("ERRORCONsole", err)
            });

    }, []);

    //Réservé le créneau
const reserveCreneau = () => {
        const idsCreneaux = JSON.parse(window.sessionStorage.getItem('rdv')).idCRDI;
        const idUser = JSON.parse(window.sessionStorage.getItem('user')).user.patient.id;
        
     var myHeaders = new Headers();
         myHeaders.append("Content-Type", "application/json");

         var raw = JSON.stringify({
         "idpatient": idUser,
         "ids": idsCreneaux[0].idcreneau
         // "ids": this.state.idsCreneauReserve
         });

         var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: raw,
         redirect: 'follow'
         };

         // console.log("RAW", raw)
         fetch(host+"/creneau/reserve", requestOptions)
         .then(response => {
             console.log("Response: ", response)
             if(response.status === 200){
                response.json()
             }
            })
         .then(result => {
            //  console.log("reserveCreneau: ",result)
                 console.log("TEXPPPP: ",result)
             })
         .catch(error => console.log('error', error));
 }

 //Réservé  la téléconsultation
    const  reserveConsultation = () => {
        const idsCreneaux = JSON.parse(window.sessionStorage.getItem('rdv'));
        const idUser = JSON.parse(window.sessionStorage.getItem('user'));
        // const idUser = JSON.parse(window.sessionStorage.getItem('user')).user.patient.id;
        console.log("USER: ",idsCreneaux)
    var myHeaders = new Headers();
   myHeaders.append("Content-Type", "application/json");
        
   var raw = JSON.stringify({
   "idpatient":idUser.user.patient.id,
   "idprofessionnelsante": idsCreneaux.idPro,
   "dateconsultation": idsCreneaux.idCRDI[0].datecreneau,
   "heuredebutconsultation": idsCreneaux.idCRDI[0].heuredebut,
   "commentaireclinique": "commentaire d'une clinique",
   "teleconsultation": "true",
   "idprestation"   : idsCreneaux.idCRDI[0].idprestation.id
   });

   var requestOptions = {
   method: 'POST',
   headers: myHeaders,
   body: raw,
   redirect: 'follow'
   };

   fetch(host+"/consultation/create/", requestOptions)
   .then(response => response.json())
   .then(result => console.log("reserveConsultation: ",result))
   .catch(error => console.log('error', error));
}
//
 const createPaymode = () => {
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
    "slug": "paiement_effectue",
    "libelle": "Carte Bancaire",
    // "airtelmoneyNumtel": this.state.numero,
    "mtnmoneyNumtel": "",
    "cbXxx": ""
    });

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    fetch(host+"/paymode/create", requestOptions)
    .then(response => response.json())
    .then(result =>{ 
        //  console.log("PAIEMENT_MODE",result)
    })
    .catch(error => console.log('error', error));
        }

  const    paiementInfo = () => {
            const idUser = JSON.parse(window.sessionStorage.getItem('user'));
         var myHeaders = new Headers();
             myHeaders.append("Content-Type", "application/json");
 
             var raw = JSON.stringify({
             "mode": "CB",
             "phoneNumber": "",
             "transactionId": transactionId,
             "statusCode": "200",
             "montant": prix,
             "idpatient": idUser.user.patient.id,
             "idtypepaiement": 1,
             "idstatutpaiement": 2
             });
 
             var requestOptions = {
             method: 'POST',
             headers: myHeaders,
             body: raw,
             redirect: 'follow'
             };
 
             fetch(host+"/paiement/create", requestOptions)
             .then(response => response.json())
             .then(result => {
                //  console.log("PAIEMENt_RESULT: ",result)
             })
             .catch(error => console.log('error', error));
 
     }

  const  insererRDV = () => {
    const idsCreneaux = JSON.parse(window.sessionStorage.getItem('rdv'));
    const idUser = JSON.parse(window.sessionStorage.getItem('user'));
    let today = new Date();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    moment.locale();

    var raw = JSON.stringify({
      daterdv: idsCreneaux.idCRDI[0].datecreneau,
      heurerdv: idsCreneaux.idCRDI[0].heuredebut,
      idcentre:this.state.idCentre,
      idpatient: idUser.user.patient.id,
      idprofessionnelsante:idsCreneaux.idPro,
      idprestation: idsCreneaux.prestation.id,
      idcreneau: idsCreneaux.idCRDI[0].idcreneau,
      // "datepaiement":  moment(this.state.datePaiement).format('LLLL')
      datepaiement: moment(today).format("YYYY-MM-DD"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(host + "/rdv/create/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log("RDV_Créé",result)
      })
      .catch((error) => console.log("error", error));
  }


    //La méthode submit du payement par stripe  
    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        //  Step 3: Use clientSecret from PaymentIntent and the CardElement
        //  to confirm payment with stripe.confirmCardPayment()
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: "Simon Pere"
                }
            }
        });

        if (payload.error) {
            setError(`Paiement échoué: ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
             setSucceeded(true);
            setProcessing(false);
            setMetadata(payload.paymentIntent);

            const paymentIntents = payload.paymentIntent.id;
           

            const axios = require('axios');

            const config = {
                method: 'post',
                url: 'https://api.stripe.com/v1/payment_intents/' + paymentIntents,
                headers: {
                    'Authorization': 'Bearer ' + API_KEY_SECRET,
                }
            };
            axios(config)
                .then((response) => {
                    const data = response.data.charges.data;
                   
                    console.log(response, "Stripe")
                    reserveCreneau();
                    reserveConsultation();
                    createPaymode();
                    insererRDV();
                    paiementInfo();
                    sessionStorage.setItem(
                        "userCommande",
                        JSON.stringify({
                          idCommande: transactionId,
                          modePaiement: "Carte Bancaire",
                          datePaiement : new Date()
                        //   state: true,
                        })
                      );
                    setSucceeded(true);
                    console.log("test reussi")
                    jQuery(data).each(function (i, obj) {
                        const transactionId = obj.balance_transaction;
                        const cardDetail = obj.payment_method_details;
                        // const ID = Math.random().toString().slice(2);
                        // const dating = new Date().toLocaleDateString();
                        // const timing = new Date().toLocaleTimeString();

                        setTransactionId(transactionId);


                        sessionStorage.setItem("transactionId", transactionId);
                        jQuery(cardDetail.card).each(function (i, obj) {
                            window.cardBrand = obj.brand;
                            window.cardMonth_exp = obj.exp_month;
                            window.cardYear_exp = obj.exp_year;
                            window.cardLast4 = obj.last4;


                        });
                    })
                        .catch((erreur) => {
                            //On traite ici les erreurs éventuellement survenues
                            console.log(erreur);
                        });
                       
                       
                     
                      
                })
                .catch(function (error) {
                    console.log(error)
                });
        }
    };

    //Functiion de reservation d'un ou plusieurs créneaux
//  const reserveCreneau = () => {
//         const idsCreneaux = JSON.parse(window.sessionStorage.getItem('rdv')).idCRDI;
//         const idUser = JSON.parse(window.sessionStorage.getItem('user')).user.patient.id;

//          idsCreneaux.map((cr) =>{
//              return(
//                 setidsCreneauReserve(idsCreneauReserve.push(cr.idcreneau))       
//              )
//          })

//      var myHeaders = new Headers();
//          myHeaders.append("Content-Type", "application/json");

//          var raw = JSON.stringify({
//          "idpatient": idUser,
//          "ids": this.state.idsCreneauReserve
//          });

//          var requestOptions = {
//          method: 'POST',
//          headers: myHeaders,
//          body: raw,
//          redirect: 'follow'
//          };

//          fetch(host+"/creneau/reserve", requestOptions)
//          .then(response => response.json())
//          .then(result => console.log("loggggggggg: ",result))
//          .catch(error => console.log('error', error));
//  }
// ****************fin Functiion de reservation d'un ou plusieurs créneaux*****************

    const renderSuccess = () => {
    //    setTimeout(() => {
        window.location.replace("/patient/booking-success")
    //    }, 3000);
        return (
            <div className="sr-field-success message">
                <h1>Montant prélevé avec succès sur la carte </h1>
                <p>Merci de nous faire confiance</p>
            </div>

        );

    };


    const renderForm = () => {
        const options = {
            style: {
                base: {
                    color: "#32325d",
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a"
                }
            }
        };

        return (

            <form onSubmit={handleSubmit}>
                <h3 className="widget-title"> Paiement par Carte Bancaire</h3><br />
                {/* <h6>Numéro de  Carte Bancaire</h6><br /> */}

                <div className="sr-combo-inputs">

                    <div className="form-group row">
                        <label htmlFor="CardNumber" className="col-sm-4 col-form-label">
                            N° carte </label>
                        <div className="col-sm-8">
                            <CardNumberElement
                                options={options}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="expirationDate" className="col-sm-4 col-form-label">
                            Date expiration</label>
                        <div className="col-sm-8">
                            <CardExpiryElement
                                options={options}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="CVC" className="col-sm-4 col-form-label">
                            CVC
                        </label>
                        <div className="col-sm-8">
                            <CardCvcElement
                                options={options}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    {error && <div className="message sr-field-error">{error}</div>}
                </div>
                <div className="form-group row">
                    <button className="btn btn-danger" type="button">
                        {/* <Link href="/envoyer-vers-numero" activeClassName="active">
                            <a className="btn-danger">Annuler</a>
                        </Link> */}
                        <a className="btn-danger" href="/patient/checkout" activeClassName="active" >Annuler</a>
                    </button>

                    <button
                        onClick={handleSubmit}
                        className="btn btn btn-primary btnStart" style={{ marginLeft: "5%" }}
                        disabled={processing || !clientSecret || !stripe}
                    >
                        {processing ? "Paiement en cours…" : "Payer"}
                    </button>
                </div>
            </form>
        );
    };

    return (
        <div className="checkout-form">
            <div className="sr-payment-form">
                <div className="sr-form-row" />
                {succeeded ? renderSuccess() : renderForm()}
            </div>
        </div>
    );
}
