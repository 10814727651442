import React, { Component } from "react";
import SidebarNav from "../sidebar";
import StatusChart from "./status";
import RevenueChart from "./revenue";
import TableDoctor from "./tableDoctor";
import TablePatientsList from "./tablePatientList";
import TableAppointmentList from "./appointment";
import host from "../../../host";
import ProgressBar from 'react-bootstrap/ProgressBar'

class Dashboard extends Component {
  constructor(props) {
    super(props);

    let token = "";

    if (sessionStorage.getItem("admin")) {
      token = JSON.parse(sessionStorage.getItem("admin")).token;
    }

    this.state = {
      auth: token,
      countPs: 0,
      countPatient: 0,
      countRdv: 0,
    };
  }

  componentDidMount() {
    this.getCountPatient();
    this.getCountPs();
	this.getCountRdv();
	
	// this.setState({

	// })
	// setCountOfProgess((oldProgress) => {
    //     return Math.min(oldProgress + Math.random() * 10, 100);
    //   });
  }



  getCountPs = () => {
    let myHeaders = new Headers();
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(host + "/ps/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        return response.text();
      })
      .then((result) => {
        if (result == null) {
        } else {
          const data = JSON.parse(result);

          this.setState({
            countPs: data.length,
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  getCountPatient = () => {
    let myHeaders = new Headers();
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(host + "/patient/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        return response.text();
      })
      .then((result) => {
        if (result == null) {
        } else {
          const data = JSON.parse(result);

          this.setState({
            countPatient: data.length,
          });
        }
      })
      .catch((error) => console.log("error", error));
  };
  getCountRdv = () => {
    let myHeaders = new Headers();
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(host + "/rdv/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        return response.text();
      })
      .then((result) => {
        if (result == null) {
        } else {
          const data = JSON.parse(result);

          this.setState({
            countRdv: data.length,
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  render() {
    if (!sessionStorage.getItem("admin")) {
      this.props.history.push("/admin/login");
    }
    return (
      <>
        <SidebarNav />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">
                    Bienvenue dans l'administration!
                  </h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item active">Tableau de bord</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* breadcrumb */}

            <div className="row">
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon text-primary border-primary">
                        <i className="fe fe-users"></i>
                      </span>
                      <div className="dash-count">
                        <h3>{this.state.countPs}</h3>
                      </div>
                    </div>
                    <div className="dash-widget-info">
                      <h6 className="text-muted">Professionnel de santé</h6>
					   {/* <ProgressBar now={this.state.countPs} /> */}
                      {/* <div className="progress progress-sm">
                        <div className="progress-bar bg-primary w-100"></div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon text-success">
                        <i className="fe fe-credit-card"></i>
                      </span>
                      <div className="dash-count">
                        <h3>{this.state.countPatient}</h3>
                      </div>
                    </div>
                    <div className="dash-widget-info">
                      <h6 className="text-muted">Patients</h6>
                      {/* <div className="progress progress-sm"> */}
					  {/* <ProgressBar now={this.state.countPatient} /> */}
                        {/* <div className="progress-bar bg-success w-100"></div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon text-danger border-danger">
                        <i className="fe fe-money"></i>
                      </span>
                      <div className="dash-count">
                        <h3>{this.state.countRdv} </h3>
                      </div>
                    </div>
                    <div className="dash-widget-info">
                      <h6 className="text-muted">Rendez-vous</h6>
                      {/* <div className="progress progress-sm"> */}
                        {/* <div className="progress-bar bg-danger w-10"></div> */}
						{/* <ProgressBar  now={this.state.countRdv}/> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon text-warning border-warning">
                        <i className="fe fe-folder"></i>
                      </span>
                      <div className="dash-count">
                        <h3>5 000 XAF</h3>
                      </div>
                    </div>
                    <div className="dash-widget-info">
                      <h6 className="text-muted">Revenue</h6>
                      {/* <div className="progress progress-sm">
                        <div className="progress-bar bg-warning w-50"></div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                {/* Sales Chart */}
                <div className="card card-chart">
                  <div className="card-header">
                    <h4 className="card-title">Revenue</h4>
                  </div>
                  <div className="card-body">
                    <RevenueChart />
                  </div>
                </div>
                {/* /Sales Chart */}
              </div>
              <div className="col-md-12 col-lg-6">
                {/* Sales Chart */}
                <div className="card card-chart">
                  <div className="card-header">
                    <h4 className="card-title">Revenue</h4>
                  </div>
                  <div className="card-body">
                    <StatusChart />
                  </div>
                </div>
                {/* /Sales Chart */}
              </div>
            </div>
            {/*  row */}
            {/* <div className="row">
						<div className="col-md-6 col-lg-6">
							<div className="card card-table flex-fill">
							<div className="card-header">
									<h4 className="card-title">Liste de professionnel de sante</h4>
								</div>
								<div className="card-body">
									<TableDoctor />
								</div>
							</div>
						</div>
						<div className="col-md-6 col-lg-6">
							<div className="card card-table flex-fill">
							<div className="card-header">
									<h4 className="card-title">Liste de patients</h4>
								</div>
								<div className="card-body">
									<TablePatientsList />
								</div>
							</div>
						</div>
					</div>	
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card card-table flex-fill">
									<TableAppointmentList />
							</div>
						</div>
					</div>	 */}
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
