import React, { Component } from "react";
import { Link } from "react-router-dom";
// import jsPDF from 'jspdf';
// import logo from "../../../assets/images/kwaber.png"
import axios from "axios";
// import Facture from './facture';
import host from "../../../../host";
import { Spin } from "antd";


class AirtelBookingSuccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patient: [],
      show: false,
      professionnel: {},
      urlPhoto: "",
      rdvs: [],
      isLoading: false,
      isTrue: false,
      session: undefined,
      dateRDV: "",
      heureDebut: [],
      heureFIN: [],
      creneau: undefined,
      prix: undefined,
      prestation: "",
    };
  }

  async componentDidMount() {
    // const URL = "http://95.111.234.161:5000/";
    const URL = "https://api.sante.kwaber.com/";

    const infoR = JSON.parse(window.sessionStorage.getItem("user"));
    const infoU = JSON.parse(window.sessionStorage.getItem("rdv"));
    // console.log(infoU," infoU")
    // console.log(infoR," infoR")
    let condition = infoU.idPro ? `/ps/${infoU.idPro}` : `/centres/${infoU.idCentre}`;
    const idpro = infoU === null || infoU === undefined ? 0 : infoU.idPro;
    let pfSanteResponse = await fetch(host + condition);

    if (pfSanteResponse.status === 404) {
      this.props.history.push("/404");
    } else if (pfSanteResponse.status === 200) {
      let data = await pfSanteResponse.json();
      let photo =
        "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png";
      // if(data.photo[0] !== null && data.photo[0] !== undefined){
      //     photo = data.photo[0].url
      // }
      if (photo) {
        photo = photo[0];
      }
      let centre = "Aucun centre spécifié";
      if (infoU.centre !== 0) {
        centre = centre;
      }

      this.setState({
        professionnel: data,
        adresseCentre: "centre",
        rdvs: infoU.idCRDI[0],
        urlPhoto: photo,
        isLoading: false,
        dateRDV: infoU.idCRDI[0],
        heureDebut: infoU.idCRDI[0],
        heureFIN: infoU.idCRDI[0],
        prix: "infoU.prix",
        prestation: infoU.prestation.libelle,
      });

      let patientResponse = await fetch(
        host +
          `/patient/${
            JSON.parse(window.sessionStorage.getItem("user")).user.userId
          }`
      );

      if (patientResponse.status === 200) {
        let data = await patientResponse.json();
        this.setState({
          patient: data,
        });
        // this.creation()
      }
    }
  }
  ecrireDate(date) {
    //2021-03-30
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    var str = new Date(date);
    //var tab = str.split("-");

    var jour = listeJours[str.getDay()];
    var mois = listeMois[str.getMonth()];

    return jour + " " + str.getDate() + " " + mois + " " + str.getFullYear();
  }
  dateAJD() {
    let date = new Date();
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    var nombre = date.getDate();
    var jour = listeJours[date.getDay()];
    var mois = listeMois[date.getMonth()];
    return jour + " " + nombre + " " + mois;
  }
  ecrireHeure(heure) {
    //11:00:00.000
    var str = heure;
    var tab = str.split(":");
    return tab[0] + "h" + tab[1];
  }

  // creationCreneau = async () => {
  //     let info = JSON.parse(window.sessionStorage.getItem('infoRDV'));
  //     const infoU = JSON.parse(window.sessionStorage.getItem("rdv"))
  //     let CreneauCreer = ""
  //     try {
  //         const response = await axios({
  //             method: 'post',
  //             url: host+'/creneaus',
  //             data: {
  //                 heureDebut: info.heureDebut,
  //                 heureFin: info.heureFin,
  //                 dateCreneau: info.dateRDV,
  //                 user: info.patient,
  //                 rdv: "",
  //                 aidants: info.aidants,
  //                 userPro: info.profesionnel_de_sante,
  //                 prestation:infoU.prestation
  //             }
  //         }).then(function (response) {
  //             //On traite la suite une fois la réponse obtenue
  //             CreneauCreer = response.data;
  //         })
  //             .catch(function (erreur) {
  //                 //On traite ici les erreurs éventuellement survenues
  //                 console.log(erreur);
  //             });
  //     } catch (err) {
  //         // Handle Error Here
  //         console.error(err);
  //     }
  //     return CreneauCreer
  // };

  // creationRDV = async () => {
  //     let info = JSON.parse(window.sessionStorage.getItem('infoRDV'));
  //     const infoU = JSON.parse(window.sessionStorage.getItem("rdv"))
  //     let rdvCreer = "";

  //     try {
  //         const response = await axios({
  //             method: 'post',
  //             url: 'https://kwaber-sante.herokuapp.com/RDVS',
  //             data: {
  //                 dateRDV: info.dateRDV,
  //                 heureRDV: info.heureDebut,
  //                 commentaire: info.commentaire,
  //                 profesionnel_de_sante: info.profesionnel_de_sante,
  //                 creneau: "",
  //                 userPro:this.state.professionnel,
  //                 prestation: infoU.prestation
  //             }
  //         }).then(function (response) {
  //             //On traite la suite une fois la réponse obtenue
  //             rdvCreer = response.data
  //         })
  //     } catch (err) {
  //         // Handle Error Here
  //         console.error(err);
  //     }
  //     return rdvCreer
  // }

  // async creation() {
  //     let info = JSON.parse(window.sessionStorage.getItem('infoRDV'));
  //     let creneauCreer = await this.creationCreneau()
  //     let rdvCreer = await this.creationRDV()
  //     let tab = this.state.rdvs
  //     let tabPatient = this.state.patient.creneaus
  //     let userPatient = this.state.professionnel.usersPatient
  //     let userPro = this.state.patient.usersPro

  //     tab.push(rdvCreer)
  //     tabPatient.push(creneauCreer)
  //     let estDansPatient = false
  //     let estDansPro = false

  //     for(let i=0;i<userPatient.length;i++){
  //         if(userPatient[i].id==this.state.patient.id){
  //             estDansPatient = true

  //         }
  //     }
  //     for(let i=0;i<userPro.length;i++){
  //         if(userPro[i].id!==this.state.patient.id){
  //             estDansPro = true
  //         }
  //     }
  //     if(estDansPatient == false){
  //         userPatient.push(this.state.patient)
  //     }
  //     if(estDansPro == false){
  //         userPro.push(this.state.professionnel)
  //     }

  //     axios.put(`https://kwaber-sante.herokuapp.com/RDVS/${rdvCreer.id}`, {
  //         creneau: creneauCreer
  //     })
  //         .then(response => {
  //             console.log(response, "reponse1");
  //         });

  //     axios.put(`https://kwaber-sante.herokuapp.com/creneaus/${creneauCreer.id}`, {
  //         rdv: rdvCreer
  //     })
  //         .then(response => {
  //             console.log(response, "reponse2");
  //         });
  //         console.log(this.state.professionnel.id)
  //     axios.put(`https://kwaber-sante.herokuapp.com/users/${this.state.professionnel.id}`, {
  //         rdvs: tab,
  //         usersPatient : userPatient,
  //     })
  //         .then(response => {
  //             console.log(response, "reponse3");
  //         });
  //     axios.put(`https://kwaber-sante.herokuapp.com/creneau-dispos/${info.creneauDispo.id}`, {
  //         reserve: true
  //     })
  //         .then(response => {
  //             console.log(response, "reponse4");
  //         });
  //         console.log(JSON.parse(window.sessionStorage.getItem('user')).user.id)
  //     axios.put(host+`/patient/${JSON.parse(window.sessionStoragege.getItem('user')).user.id}`, {
  //         creneaus: tabPatient,
  //         usersPro:userPro
  //     })
  //         .then(response => {
  //             console.log(response, "reponse5");
  //         });
  // }
  // generatePDF = () => {
  //  return <Facture

  // }

  render() {
    // console.log("DOCMEDE", this.state.rdvs)
    return (
      <div className="content success-page-cont">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="card success-card">
                <div className="card-body">
                  <div className="success-cont">
                    <i className="fas fa-check"></i>
                    <h3>Rendez-vous Confirmé !</h3> <br />
                    {/* <p>Rendez-vous réservé avec <strong>{this.state.professionnel.nom} {this.state.professionnel.prenom}</strong><br /> le
                                         <strong>{this.state.dateRDV} </strong> 
                                         <strong>{this.state.dateRDV} </strong> 
                                          entre <strong> {this.state.heureDebut} </strong>  et 
                                          <strong> {this.state.heureFIN}</strong>.</p>
                                        <p>Prestation réservé : <strong>{this.state.prestation} </strong></p> */}
                    <p>
                      
                      {
                        this.state.professionnel.libelle ? "Rendez-vous réservé avec " : "Rendez-vous réservé avec docteur " 
                      }
                      <strong>
                      {this.state.professionnel.libelle ? this.state.professionnel.libelle : this.state.professionnel.nom+" "+this.state.professionnel.prenom }
                        {/* {this.state.professionnel.nom}{" "}
                        {this.state.professionnel.prenom} */}
                      </strong>
                      <br />
                    </p>
                    <ul className="booking-date">
                      <h5>
                        {" "}
                        <strong>Date</strong>
                      </h5>

                      <li>
                        {this.ecrireDate(this.state.dateRDV.datecreneau)},
                        Heure:{" "}
                        <strong>
                          {this.state.dateRDV.heuredebut} -{" "}
                          {this.state.dateRDV.heurefin}
                        </strong>
                      </li>
                    </ul>
                    <button className="btn btn-primary view-inv-btn">
                      <Link to={"/facture"}>voir la facture</Link>
                    </button>
                  </div>
                </div>
                <a hrref="#" className="ml-2"> <Link to="/" style={{color:"bleu"}}>Retour à l'accueil </Link> </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AirtelBookingSuccess;
// export default AirtelAirtelBookingSuccess;
