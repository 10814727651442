import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Select from 'react-select';
import StickyBox from "react-sticky-box";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Skeleton from "react-loading-skeleton"
import SearchBar from "material-ui-search-bar";

class CentreProfil extends Component {
	constructor(props) {
		super(props)
		this.state = {
			Localisation: "Toutes les villes",
			Specialiste: "Tous les spécialistes",
			photoIndex: 0,
			searchValue: "",
			isOpen: false,
			professionnel: []
		}
		this.handleChange1 = this.handleChange1.bind(this)
		this.handleChange2 = this.handleChange2.bind(this)
		this.filterData = this.filterData.bind(this)
	}
	handleChange1(e) {
		// let specialite = e.target.name
		this.setState({
			Localisation: e.target.value,
		})

		this.filterData();

	};
	handleChange2(e) {
		this.setState({
			Specialiste: e.target.name,
		})
		this.filterData();
	};


	filterData() {

		fetch("https://kwaber-sante.herokuapp.com/centres/")
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (this.state.Specialiste === "Tous les spécialistes" && this.state.searchValue === "") {
					this.setState({
						professionnel: data
					});
				} else if (this.state.Specialiste === "Tous les spécialistes" && this.state.searchValue !== "") {
					const specialistes = data.filter(
						(local) => ((
							local.libelle.toLowerCase().includes(this.state.searchValue.toLowerCase()) 
							
						)));
					this.setState({
						professionnel: specialistes
					});
				} else if (this.state.Specialiste !== "Tous les spécialistes" && this.state.searchValue === "") {
					const specialistes = data.filter(
						(local) =>
						
							(local.specialites === this.state.Specialiste)
					);
					this.setState({
						professionnel: specialistes
					});
				} else {
					const specialistes = data.filter(
						(local) =>
						((local.specialites[0].libelle === this.state.Specialiste) && (
							local.Nom.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
							local.prenom.toLowerCase().includes(this.state.searchValue.toLowerCase()) ||
							(local.Nom.toLowerCase() + " " + local.prenom.toLowerCase()).includes(this.state.searchValue.toLowerCase()) ||

							(local.prenom.toLowerCase() + " " + local.Nom.toLowerCase()).includes(this.state.searchValue.toLowerCase()) ||

							local.numeroTelephone.toLowerCase().includes(this.state.searchValue.toLowerCase())
						)))
					this.setState({
						professionnel: specialistes
					});
				}
			});
	}
	componentDidMount() {

		const urlParams = new URLSearchParams(window.location.search);
		
		const paramsCity = urlParams.get('ville') == "" || urlParams.get('ville') == null ? "Toutes les villes" : urlParams.get('ville');
		const paramsSpecialite = urlParams.get('specialite') == "" || urlParams.get('specialite') == null ? "Tous les spécialistes" : urlParams.get('specialite');
		this.setState({
			Localisation: paramsCity,
			// Specialiste: paramsSpecialite,
		})


		fetch("https://kwaber-sante.herokuapp.com/centres/")
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (this.state.Specialiste === "Tous les spécialistes") {
					this.setState({
						professionnel: data
					});
				}
			});
	}

	getInfo() {
		const nom = document.getElementById('nom').innerText
		window.sessionStorage.setItem("name", nom)
		window.location.replace("/inconnue");
	};
	render() {
		return (
			<div>
				<div className="row">
					<div className="col-md-3">

					</div>
					<div className="col-md-9">
						<div className="ml-4 mr-4">
							<SearchBar
								placeholder="Chercher par  nom "
								value={this.state.searchValue}
								onChange={(newValue) => {
									this.filterData()
									this.setState({ searchValue: newValue })
								}}
							/>
						</div>
					</div>
				</div>
				<div className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12 col-lg-4 col-xl-3">
								<StickyBox offsetTop={20} offsetBottom={20}>

									<div className="card search-filter mb-4">
										<div className="card-header">
											<h4 className="card-title mb-0">Filtre de recherche</h4>
										</div>
										<div className="card-body">
											<div className="filter-widget">
												<h4>Localisation</h4>
												<div>
													<label className="custom_check">
														<input type="radio" value="Toutes les villes" checked={this.state.Localisation === "Toutes les villes"} onChange={this.handleChange1} />
														<span className="checkmark"></span> Toutes les villes
													</label>
												</div>
												<div>
													<label className="custom_check">
														<input type="radio" value="Brazzaville" checked={this.state.Localisation === "Brazzaville"} onChange={this.handleChange1} />
														<span className="checkmark"></span> Brazzaville
													</label>
												</div>
												<div>
													<label className="custom_check">
														<input type="radio" value="Pointe-Noire" checked={this.state.Localisation === "Pointe-Noire"} onChange={this.handleChange1} />
														<span className="checkmark"></span> Pointe-Noire
													</label>
												</div>
											</div>


											<div className="filter-widget">
												<h4>Spécialité</h4>
												<div>
													<label className="custom_check">
														<input type="radio" name="Tous les spécialistes" checked={this.state.Specialiste === "Tous les spécialistes"} onChange={this.handleChange2} />
														<span className="checkmark"></span> Tous les spécialistes
													</label>
												</div>
												<div>
													<label className="custom_check">
														<input type="radio" name="Chirurgien(ne)" checked={this.state.Specialiste === "Chirurgien(ne)"} onChange={this.handleChange2} />
														<span className="checkmark"></span> Chirurgien(ne)
													</label>
												</div>
												<div>
													<label className="custom_check">
														<input type="radio" name="Coordonatrice de soins" checked={this.state.Specialiste === "Coordonatrice de soins"} onChange={this.handleChange2} />
														<span className="checkmark"></span> Coordonateur(trice) de soins
													</label>
												</div>
												<div>
													<label className="custom_check">
														<input type="radio" name="Dentiste" checked={this.state.Specialiste === "Dentiste"} onChange={this.handleChange2} />
														<span className="checkmark"></span> Dentiste
													</label>
												</div>
												<div>
													<label className="custom_check">
														<input type="radio" name="Echographiste" checked={this.state.Specialiste === "Echographiste"} onChange={this.handleChange2} />
														<span className="checkmark"></span> Echographiste
													</label>
												</div>
												<div>
													<label className="custom_check">
														<input type="radio" name="Transfusion sanguine" checked={this.state.Specialiste === "Transfusion sanguine"} onChange={this.handleChange2} />
														<span className="checkmark"></span> Transfusion sanguine
													</label>
												</div>
												<div>
													<label className="custom_check">
														<input type="radio" name="Neurologue" checked={this.state.Specialiste === "Neurologue"} onChange={this.handleChange2} />
														<span className="checkmark"></span> Neurologue
													</label>
												</div>
												<div>
													<label className="custom_check">
														<input type="radio" name="Urologue" checked={this.state.Specialiste === "Urologue"} onChange={this.handleChange2} />
														<span className="checkmark"></span> Urologue
													</label>
												</div>
											</div>
										</div>
									</div>

								</StickyBox>
							</div>

							<div className="col-md-12 col-lg-8 col-xl-9 mt-4">
								{
									this.state.professionnel.length === 0 ? (
										<React.Fragment>
											<Skeleton height={140} width={900} />
											<br />
											<br />
											<Skeleton height={140} width={900} />
											<br />
											<br />
											<Skeleton height={140} width={900} />
											<br />
											<br />
											<Skeleton height={140} width={900} />
										</React.Fragment>
									) : (
										this.state.professionnel.map((profesionnel) => (
											<ProfessionnelDeSante key={profesionnel.id}
												professionneldata={profesionnel} />
										))
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
class ProfessionnelDeSante extends Component {



	render() {
		const { professionneldata } = this.props;

		return (
			<div className="card">
				<div className="card-body">
					<div className="doctor-widget">
						<div className="doc-info-left">
							<div className="doctor-img">
								<Link to={"/centreProfil/" + this.props.professionneldata.id}>
									<img className="img-fluid" alt="User" src= "https://www.publicdomainpictures.net/pictures/90000/velka/emergency-sign-at-hospital.jpg"  />
								</Link>
							</div>
							<div className="doc-info-cont">
								<h4><Link id="nom" to={"/centreProfil/"+ this.props.professionneldata.id}>{this.props.professionneldata.libelle} </Link></h4>

								<div>
									{
									<h5 className="doc-department">
										{this.props.professionneldata.adresse}
									  </h5>
									}
								</div>

								{/* <div className="clinic-details">
									{
										this.props.professionneldata.centres.map((centre) => {
											return <div key={"centre" + centre.id}>
												<div>
													<p className="doc-location">
														<i className="fas fa-map-marker-alt"></i>{"  " + centre.libelle}
													</p>
												</div></div>
										})
									}
								</div> */}
								<br/><br/><br/>
								<div >
									<Link to={"/centreProfil/" + this.props.professionneldata.id} className=""> <button class="btn form-group  btn-outline-info-kw">Voir le profil</button></Link>
									<Link to={"#" + this.props.professionneldata.id} className=""> <button style={{ backgroundColor: "rgb(32,192,243)", color: "white" }} className=" form-group btn btn-info-kw">Prendre RDV</button> </Link>
								</div><br /><br />
							</div>
						</div>
						<div className="doc-info-right">
							{/* <div className="clini-infos">
								{
									this.props.professionneldata.centres.map((centre) => {
										return <div key={"centre" + centre.id}>
											<ul>
												<li><i className="fas fa-map-marker-alt"></i><font size="2pt">{" " + centre.adresse}</font></li>
											</ul>
										</div>
									})
								}
								<div><br></br></div>
								

							</div> */}
						</div>
					</div>
				</div>
			</div>
		);

	}

}
export default CentreProfil;
