import React, { Component } from 'react';
import GestionnaireSidebar from '../sidebar';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import ProgressBar from 'react-customizable-progressbar';
import StickyBox from "react-sticky-box";
import host from "../../../../host";
import { Icon01, Icon02, Icon03, IMG01, IMG02, IMG03, IMG04, IMG05, IMG06 } from './img';
import { Select } from 'antd';
import { MDBDataTableV5 } from 'mdbreact';
import { Radio } from 'antd';
import moment from 'moment';



class GestionDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  key: 1,
		  patients: [],
		  tabCentre:[],
		  centres:[],
		  rdv: [],
		  nbreRdv:0,
		  nbPtaient: 0,
		  centreSelected:null,
		  datatable:{
			columns: [],
			rows: []
		}
		};
		this.handleSelect = this.handleSelect.bind(this);
	  }
	  
	  //bouton radio for Today appointment
   today = () => {
		if(this.state.centreSelected){	
			let tabRdvToday = [];
			let dateToday = new Date();
			let newTabtoDayRdv= [];
			for (let index = 0; index < this.state.centreSelected.rdvs.length; index++) {
				const element = this.state.centreSelected.rdvs[index];
					// console.log("RDV&:", element)
					var time1 = moment(dateToday).format('YYYY-MM-DD');
					 var time2 = moment(element.daterdv).format('YYYY-MM-DD');
					if(time2 === time1){
						// console.log('date2 is  equal date1');
						tabRdvToday.push(element)
					}						
			}
				if(tabRdvToday.length !== 0){
					for (let i = 0; i < tabRdvToday.length; i++) {
						const elementRdv = tabRdvToday[i];
						newTabtoDayRdv.push({
						 patient: elementRdv.idpatient2.nom+" "+elementRdv.idpatient2.prenom,
						 prestation:elementRdv.idprestation2.libelle,
						rdv: elementRdv.daterdv,
						paiement: elementRdv.datepaiement,
					  },)
						
					}
				}
				this.setState({
					datatable:{
						columns:  [
							{
							  label: 'Patient ',
							  field: 'patient',
							  width: 150,
							  attributes: {
								'aria-controls': 'DataTable',
								'aria-label': 'Name',
							  },
							},
							{
								label: 'Prestation',
								field: 'prestation',
								width: 270,
							  },
							{
							  label: 'Date du rendez-vous',
							  field: 'rdv',
							  width: 270,
							},
							{
							  label: 'Date du paiement',
							  field: 'paiement',
							  width: 200,
							  sort: 'asc',
							}
						  ],
						rows: newTabtoDayRdv
					}
				})

		 }
	};

	//bouton radio for passe appointment
	passe = () => {
		if(this.state.centreSelected){	
			let tabRdvToday = [];
			let dateToday = new Date();
			let newTabPasserRdv= [];
			for (let index = 0; index < this.state.centreSelected.rdvs.length; index++) {
				const element = this.state.centreSelected.rdvs[index];
					// console.log("RDV&:", element)
					var time1 = moment(dateToday).format('YYYY-MM-DD');
					 var time2 = moment(element.daterdv).format('YYYY-MM-DD');
					if(time2 < time1){
						tabRdvToday.push(element)
					}						
			}
				if(tabRdvToday.length !== 0){
					for (let i = 0; i < tabRdvToday.length; i++) {
						const elementRdv = tabRdvToday[i];
						newTabPasserRdv.push({
						 patient: elementRdv.idpatient2.nom+" "+elementRdv.idpatient2.prenom,
						 prestation:elementRdv.idprestation2.libelle,
						rdv: elementRdv.daterdv,
						paiement: elementRdv.datepaiement,
					  },)
						
					}
				}
				this.setState({
					datatable:{
						columns:  [
							{
							  label: 'Patient ',
							  field: 'patient',
							  width: 150,
							  attributes: {
								'aria-controls': 'DataTable',
								'aria-label': 'Name',
							  },
							},
							{
								label:"Prestation",
								field:"prestation",
								width:270

							},
							{
							  label: 'Date du rendez-vous',
							  field: 'rdv',
							  width: 270,
							},
							{
							  label: 'Date du paiement',
							  field: 'paiement',
							  width: 200,
							  sort: 'asc',
							}
						  ],
						rows: newTabPasserRdv
					}
				})

		 }
	};

	//bouton radio for forever appoitment
	avenir = () => {
		if(this.state.centreSelected){	
			let tabRdvToday = [];
			let dateToday = new Date();
			let newTabFuturRdv= [];
			for (let index = 0; index < this.state.centreSelected.rdvs.length; index++) {
				const element = this.state.centreSelected.rdvs[index];
					// console.log("RDV&:", element)
					var time1 = moment(dateToday).format('YYYY-MM-DD');
					 var time2 = moment(element.daterdv).format('YYYY-MM-DD');
				
					if(time2 > time1){
						// console.log('date2 is  equal date1');
						tabRdvToday.push(element)
					}						
			}
				if(tabRdvToday.length !== 0){
					for (let i = 0; i < tabRdvToday.length; i++) {
						const elementRdv = tabRdvToday[i];
						newTabFuturRdv.push({
						 patient: elementRdv.idpatient2.nom+" "+elementRdv.idpatient2.prenom,
						 prestation:elementRdv.idprestation2.libelle,
						rdv: elementRdv.daterdv,
						paiement: elementRdv.datepaiement,
					  },)
						
					}
				}
				this.setState({
					datatable:{
						columns:  [
							{
							  label: 'Patient ',
							  field: 'patient',
							  width: 150,
							  attributes: {
								'aria-controls': 'DataTable',
								'aria-label': 'Name',
							  },
							},
							{
								label:"Prestation",
								field:"prestation",
								width:270
							},
							{
							  label: 'Date du rendez-vous',
							  field: 'rdv',
							  width: 270,
							},
							{
							  label: 'Date du paiement',
							  field: 'paiement',
							  width: 200,
							  sort: 'asc',
							}
						  ],
						rows: newTabFuturRdv
					}
				})

		 }
	};
	
	//bouton radio for all appoitment
	allAppointment = () => {
		if(this.state.centreSelected){	
			let tabRdvToday = [];
			let dateToday = new Date();
			let newTabPasserRdv= [];
			for (let index = 0; index < this.state.centreSelected.rdvs.length; index++) {
				const element = this.state.centreSelected.rdvs[index];
					// console.log("RDV&:", element)
					var time1 = moment(dateToday).format('YYYY-MM-DD');
					 var time2 = moment(element.daterdv).format('YYYY-MM-DD');
						tabRdvToday.push(element)							
			}
				if(tabRdvToday.length !== 0){
					for (let i = 0; i < tabRdvToday.length; i++) {
						const elementRdv = tabRdvToday[i];
						newTabPasserRdv.push({
						 patient: elementRdv.idpatient2.nom+" "+elementRdv.idpatient2.prenom,
						 prestation:elementRdv.idprestation2.libelle,
						rdv: elementRdv.daterdv,
						paiement: elementRdv.datepaiement,
					  },)
						
					}
				}
				this.setState({
					datatable:{
						columns:  [
							{
							  label: 'Patient ',
							  field: 'patient',
							  width: 150,
							  attributes: {
								'aria-controls': 'DataTable',
								'aria-label': 'Name',
							  },
							},
							{
								label:"Prestation",
								field:"prestation",
								width:270
							},
							{
							  label: 'Date du rendez-vous',
							  field: 'rdv',
							  width: 270,
							},
							{
							  label: 'Date du paiement',
							  field: 'paiement',
							  width: 200,
							  sort: 'asc',
							}
						  ],
						rows: newTabPasserRdv
					}
				})

		 }
	};



	  async componentDidMount() {
		const dataJson = sessionStorage.getItem("user") || sessionStorage.getItem("user");
		const data = dataJson && JSON.parse(dataJson);
		const id = 1;
		if (!data) {
		  window.location.assign("/");
		}   
	
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
	
		let requestOptions = {
		  method: "GET",
		  headers: myHeaders,
		  redirect: "follow",
		};
	
		await fetch(
		  host + `/gestionnairecentre/${data.user.gestionnairecentre.idgestionnairecentre}`,
		  requestOptions
		)
		  .then((response) => {
			if (response.status !== 200 && response.status !== 201) {
			  return null;
			}
			return response.json();
		  })
		  .then((result) => {
			//   console.log("ResultGest:", result)
				this.setState({
					centres: result.gestionnaire.centre
				})

			  let tabCentre=[];
			  let allRdv=[];
			  let allPatient=[];
			  for (let index = 0; index < result.gestionnaire.centre.length; index++) {
				  const element = result.gestionnaire.centre[index];
				  	// console.log('ELE:',element.rdvs)	
					  for (let i = 0; i < element.rdvs.length; i++) {
						const elementRdv = element.rdvs[i];
							allRdv.push(elementRdv)  
							allPatient.push(elementRdv.idpatient2)  
					}		  
			  }
			  this.setState({
				nbreRdv: allRdv.length,
				nbPatient:allPatient.length
			  })
			  
		  })
		  .catch((error) => {
			// console.log("error", error)
		  });

		  this.reservedDefault();

		
	  }
	
	  handleSelect(key) {
		this.setState({ key });
	  }
	

	  //change centre 
	   handleChangeSelect(e) {
		   console.log("e.target: ",e.target.value)
			let tabRdv = [];
			let tabCentreSelected = [];
			for (let index = 0; index < this.state.centres.length; index++) {
				const element = this.state.centres[index];
				const elemenT = this.state.centres[0];

					if(elemenT.idcentre){
						console.log("ElementCENTRE: ",elemenT)

					}
					if(element.idcentre == e.target.value){
							// console.log("ELEMENT:",element);
							this.setState({
								centreSelected:element
							})
							for (let i = 0; i < element.rdvs.length; i++) {
								const elementRdv = element.rdvs[i];
							tabRdv.push({
							 	patient: elementRdv.idpatient2.nom+" "+elementRdv.idpatient2.prenom,
								 prestation:elementRdv.idprestation2.libelle,
								rdv: elementRdv.daterdv,
								paiement: elementRdv.datepaiement,
							  },)
								
							}
					}		
			}
			this.setState({
				datatable:{
					columns:  [
						{
						  label: 'Patient ',
						  field: 'patient',
						  width: 150,
						  attributes: {
							'aria-controls': 'DataTable',
							'aria-label': 'Name',
						  },
						},
						{
						  label: "Prestation",
						  field:"prestation",
						  width:270
						},
						{
						  label: 'Date du rendez-vous',
						  field: 'rdv',
						  width: 270,
						},
						{
						  label: 'Date du paiement',
						  field: 'paiement',
						  width: 200,
						  sort: 'asc',
						}
					  ],
					rows: tabRdv
				}
			})
				
		}
	
	reservedDefault() {
		console.log("reservedDefault")
			 if(this.state.centres.length > 0){
				let tabRdv = [];
				let tabCentreSelected = [];
   
				for (let index = 0; index < this.state.centres[0].rdvs.length; index++) {
					const element = this.state.centres[0].rdvs;
					 const elemenT = this.state.centres[0];
				  
					 console.log("SSSbefor: ",element)
				   console.log("elemenT: ",elemenT)
	
						if(elemenT.idcentre){
						   this.setState({
							   centreSelected:elemenT
						   })
						   for(let i = 0; i < element.length; i++) {
							   const elementRdv = element[i];
							   console.log("SSSAfter: ",element)
						   tabRdv.push({
								patient: elementRdv.idpatient2.nom+" "+elementRdv.idpatient2.prenom,
								prestation:elementRdv.idprestation2.libelle,
							   rdv: elementRdv.daterdv,
							   paiement: elementRdv.datepaiement,
							 },)
							   
						   }
						}	
				}
				this.setState({
					datatable:{
						columns:  [
							{
							  label: 'Patient ',
							  field: 'patient',
							  width: 150,
							  attributes: {
								'aria-controls': 'DataTable',
								'aria-label': 'Name',
							  },
							},
							{
							  label: "Prestation",
							  field:"prestation",
							  width:270
							},
							{
							  label: 'Date du rendez-vous',
							  field: 'rdv',
							  width: 270,
							},
							{
							  label: 'Date du paiement',
							  field: 'paiement',
							  width: 200,
							  sort: 'asc',
							}
						  ],
						rows: tabRdv
					}
				})
			 }
			
				 
		 }

		
	  
	
	  render() {
		

		return (
		  <div>
			<div className="content">
			  <div className="container-fluid">
				<div className="row">
				  <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
					<StickyBox offsetTop={20} offsetBottom={20}>
					  <GestionnaireSidebar />
					</StickyBox>
				  </div>
				  <div className="col-md-7 col-lg-8 col-xl-9">
					<div className="row">
					  <div className="col-md-12">
						<div className="card dash-card">
						  <div className="card-body">
							<div className="row">
							  <div className="col-md-12 col-lg-6">
								<div className="dash-widget dct-border-rht">
								  <div className="indicator-volume m-3">
									<img
									  src={Icon01}
									  className="img-fluid"
									  alt="Patient"
									/>
								  </div>
								  <div className="dash-widget-info">
									<h6>Nombre de rendez-vous</h6>
									<h3>{this.state.nbreRdv}</h3>
									{/* <p className="text-muted">Jusqu'à aujourd'hui</p> */}
								  </div>
								</div>
							  </div>
							  <div className="col-md-12 col-lg-6">
								<div className="dash-widget">
								  {/* <ProgressBar
																	width={20}
																	radius={100}
																	progress={60}
	
																	rotate={-210}
																	strokeWidth={16}
																	strokeColor="#68dda9"
																	strokeLinecap="square"
																	trackStrokeWidth={8}
																	trackStrokeColor="#e6e6e6"
																	trackStrokeLinecap="square"
																	pointerRadius={0}
																	initialAnimation={true}
																	transition="1.5s ease 0.5s"
																	trackTransition="0s ease"
																>
																	<div className="indicator-volume">
																		<img src={Icon02} className="img-fluid" alt="Patient" />
																	</div>
																</ProgressBar> */}
								  <div className="indicator-volume m-3">
									<img
									  src={Icon02}
									  className="img-fluid"
									  alt="Patient"
									/>
								  </div>
								  <div className="dash-widget-info">
									<h6>Nombre de patients </h6>
									{/* {console.log(
									  this.state.professionnel,
									  "le pro"
									)} */}
									<h3>{this.state.nbPatient}</h3>
									{/* <p className="text-muted">Aujourd'hui</p> */}
								  </div>
								</div>
							  </div>
	
							  {/* <div className="col-md-12 col-lg-4">
								<div className="dash-widget">
								  <ProgressBar
																	width={20}
																	radius={100}
																	progress={70}
	
																	rotate={-210}
																	strokeWidth={16}
																	strokeColor="#1b5a90"
																	strokeLinecap="square"
																	trackStrokeWidth={8}
																	trackStrokeColor="#e6e6e6"
																	trackStrokeLinecap="square"
																	pointerRadius={0}
																	initialAnimation={true}
																	transition="1.5s ease 0.5s"
																	trackTransition="0s ease"
																>
																	<div className="indicator-volume">
																		<img src={Icon03} className="img-fluid" alt="Patient" />
																	</div>
																</ProgressBar>
								  <div className="indicator-volume m-3">
									<img
									  src={Icon03}
									  className="img-fluid"
									  alt="Patient"
									/>
								  </div>
								  <div className="dash-widget-info">
									<h6>Nombre des prestations réalisé</h6>
									<h3>{this.state.nbPasse}</h3>
									<p className="text-muted">Aujourd'hui</p>
								  </div>
								</div>
							  </div> */}
							</div>
						  </div>
						</div>
					  </div>
					</div>
	
					<div className="row">
					  <div className="col-md-12">
						<h4 className="mb-4">Réservation des patients</h4>
						<div className="appointment-tab">
							<div className='col-md-4'>
							<select
                            id="lang"
                            onChange={(e) => this.handleChangeSelect(e)}
                            // value={this.state.prestaChoisiLibelle.libelle}
                            className="form-control"
                          >
                            <option value="default"> selectionnez un centre</option>
                            {this.state.centres.map((centre) => (
                              <option value={centre.idcentre}>
                                {centre.libelle}
                              </option>
                            ))}
                          </select>
							</div>
							  <div className="card card-table mb-0">
								<div className="card-body"><br />
								<span>Filtre:</span>
								<Radio.Group defaultValue={this.state.centreSelected ? "d" : ""} buttonStyle="solid">
										<Radio.Button onClick={this.today}  value="a">Aujourd'hui</Radio.Button>
										<Radio.Button onClick={this.avenir} value="c">Avenir</Radio.Button>
										<Radio.Button onClick={this.passe} value="b">Passé</Radio.Button>
										<Radio.Button onClick={this.allAppointment} value="d">Tous les rendez-vous</Radio.Button>
										</Radio.Group> <br/><br/>

								  { 
								  this.state.centreSelected ?
									<MDBDataTableV5 hover 
												searchTop
                                                searchBottom={false}
                                                entriesLabel={"Lignes par pages"}
                                                searchLabel={"Rechercher"}
                                                infoLabel={["", "-", "/", ""]}
                                                noRecordsFoundLabel={
                                                  "Aucune donnée ne correspond"
                                                }
									entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={this.state.datatable}   />
									: <h1>Veuillez choisir un centre SVP!</h1>
								  }
								 
								</div>
							  </div>
						</div>
					  </div>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		);
	  }
	}
export default GestionDashboard;