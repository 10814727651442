import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { IMG01, IMG02, IMG03, IMG04, IMG05, IMG06, IMG07, IMG08, IMG012 } from './img';
import DoctorSidebar from '../sidebar';
import { MDBDataTableV5, MDBTable } from "mdbreact";
import host from "../../../../host";

class MyPatient extends Component {
	constructor(props) {
		super(props);

		this.state = {
			token:"",
			professionnel: [],
			rdv: [],
			patient: [],
			datatable: {
				columns:[{
                    label: "Image",
                    field: "image",
                    //   sort: 'asc',
                    width: 270,
                  },
				  {
                    label: "Nom",
                    field: "nom",
                    //   sort: 'asc',
                    width: 270,
                  },
				  {
                    label: "Age",
                    field: "age",
                    //   sort: 'asc',
                    width: 270,
                  },
				  {
                    label: "Sexe",
                    field: "sexe",
                    //   sort: 'asc',
                    width: 270,
                  },
				  {
                    label: "Téléphone",
                    field: "telephone",
                    //   sort: 'asc',
                    width: 270,
                  },
				  {
                    label: "Action",
                    field: "action",
                    //   sort: 'asc',
                    width: 270,
                  },
				
				],
				rows:[],     
			  },
		}
	}
	async componentDidMount() {
		// let patient=[]
		const dataJson = sessionStorage.getItem('user') || sessionStorage.getItem('user');;
		const data = dataJson && JSON.parse(dataJson);
		this.setState({token:data.token})
		// const idProfessionnel = data.user.profesionnel_de_sante.id  ;

		// fetch(`https://kwaber-sante.herokuapp.com/users/${data.user.id}`)
		fetch(host+`/ps/${data.user.professionnelsante.idprofessionnelsante}`)
			.then((response) => {
				if(response.status !== 200 && response.status !== 200){
					return null;
				}  
				return response.json();
			})
			.then((data) => {
				if(data != null){
					let patients = [];
					for (let index = 0; index < data.creneaux.length; index++) {
						const element = data.creneaux[index];
						if(element.patient != null){
							patients.push(element.patient)
						}
					}
					//supression des doublant
					for (let i = 0; i < patients.length; i++) {
						const first = patients[i];

						for (let j = 0; j < patients.length; j++) {
							const second = patients[j];
							if(i != j){
								if(first.id === second.id){
									patients.splice(j,1);
									j--;
								}
							}
						}
						
					}

					// console.log("___________",patients)
					// console.log(data)
					let rowElements = [];
						for (let index = 0; index < patients.length; index++) {
							const element = patients[index];
							// console.log("Patien-Lombr: ", element);
							rowElements.push({
								nom: element.nom,
								age: this.calculate_age(element.datenaissance)+" ans",
								sexe: element.sexe,
								telephone: element.numtelephone1,
								 image: <img className='rounded-circle' width={"50px"} height={"50px"} src={(element.user && element.user.photo) ? element.user.photo  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} alt={element.nom} />,
								 action:<span  className="btn btn-sm bg-info-light"> <Link onClick={()=>this.save(element)} to={{pathname: "/doctor/patient-profile"}}>
							            <i className="far fa-eye"></i> Voir
							            </Link> 
								 </span>		
							  })
						}

					this.setState({
						professionnel: data,
						patient: patients,
						datatable:{
							columns: this.state.datatable.columns,
							rows: rowElements
						}

						

					});
				}
				
				//alert(JSON.stringify(data.diplomes))
				// console.log('test1', data.patients)

			});
		// let rdvResponse = await
		// fetch(`https://kwaber-sante.herokuapp.com/RDVS?creneau.userPro=${data.user.id}`)

		// if (rdvResponse.status == 200) {
		// 	let data2 = await rdvResponse.json()
			
		// 	for (let y = 0; y < data2.length; y++) {
		// 		let crResponse = await
		// 		fetch(`https://kwaber-sante.herokuapp.com/creneaus/${data2[y].creneau.id}`)

		// 		if (crResponse.status == 200) {
		// 			let data = await crResponse.json()
		// 			if(this.estDansPatient(data.user,patient)==false){
		// 				patient.push(data.user)
		// 			}
					
		// 		}
		// 	}
		// 	this.setState({
		// 		rdv: data2,
		// 		patient:patient
		// 	});
		// }


	}
	estDansPatient(patient, tabPatient) {
		for (let i = 0; i < tabPatient.length; i++) {
			if (patient.id == tabPatient[i].id) {
				return true
			}
		}
		return false
	}
	calculate_age = (dob1) => {
		var today = new Date();
		var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
		var age_now = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age_now--;
		}
		// console.log(age_now);
		return age_now;
	}

	save = (patient)=>{
		// console.log("_________________",patient)
		sessionStorage.setItem(
			"patientProfile",
			JSON.stringify({
			  patient,
			  token : this.state.token
			})
		);
	}

	render() {


		return (
			<div>

				<div className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-4 col-lg-3 col-sm-12 theiaStickySidebar">
								<DoctorSidebar />
							</div>
							<div className="col-md-8 col-lg-8 col-sm-12">
								<div className="flex-box">
								<MDBTable responsive>
                            <MDBDataTableV5
                                    //   La table des prestations with mdbreact 
                                                 hover
                                                 searchTop
                                                 searchBottom={false}
                                                 entriesLabel={"Lignes par pages"}
                                                 searchLabel={"Rechercher"}
                                                 infoLabel={["", "-", "/", ""]}
                                                 noRecordsFoundLabel={
                                                   "Aucune donnée ne correspond"
                                                 }
                                                entriesOptions={[5, 20, 25]}
                                                entries={5}
                                                pagesAmount={4}
                                                data={this.state.datatable}
                                                
                                            />
                            </MDBTable>
								</div>



								{/* <div className="row row-grid" style={{marginRight:"9px"}}>
									{
										this.state.professionnel.patients.map((patient) => {
											return <span key={"patient" + patient.id}>

												<div className="card widget-profile pat-widget-profile">
													<div className="card-body">
														<div className="pro-widget-content">
															<div className="profile-info-widget">
																<Link to="/doctor/patient-profile" className="booking-doc-img">
																	<img src="https://doccure-react.dreamguystech.com/template/56a326a6aa580d332697e0f26bebf219.jpg" alt="User" />
																</Link>
																<div className="profile-det-info">
																	<h3><Link to="/doctor/patient-profile">{patient.nom} {patient.prenom}</Link></h3>

																	<div className="patient-details">
																		<h5><b>Patient ID :</b> {patient.id}</h5>
																		<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> {patient.adresse}</h5>
																	</div>
																</div>
															</div>
														</div>
														<div className="patient-info">
															<ul>
																<li>Téléphone 1 <span>{patient.numTelephone1}</span></li>
																<li>Téléphone 2 <span>{patient.numTelephone2}</span></li>
																<li>Age <span>{this.calculate_age(patient.dateNaissance)}, {patient.sexe}</span></li>
																<li>Email <span>{patient.email}</span></li>
																<li>Groupe sanguin <span>?</span></li>
															</ul>
														</div>
													</div>
												</div>
											</span>
										})
									}
								</div> */}
							</div> <br /><br />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default MyPatient;