import React, { Component } from "react";
import SignaturePad from "react-signature-canvas";
import { ToastContainer, toast } from "react-toastify";
import {
  // BrowserRouter as Router,
  // Route,
  Link,
} from "react-router-dom";
import logo from "../../../assets/images/logoK.jpeg";
import QRCode from "qrcode";
import qrCode from "../../../assets/images/qrCode.png";
import signature from "../../../assets/images/signature3.png";
import "reactjs-popup/dist/index.css";
import jsPDF from "jspdf";
// import axios from "axios";
import host from "../../../../host";
import Loader from "react-loader-spinner";
import { Modal, Button } from "antd";
// import IMG01 from "./logosignature.jpg";

class AddPescription extends Component {
  sigPad = {};
  constructor(props) {
    super(props);

    this.state = {
      trimmedDataURL: null,
      professionnel: { specialite: [] },
      adresseCentre: "",
      patient: [],
      urlPhoto: "",
      isLoading: false,
      matin: [false],
      aprem: [false],
      soir: [false],
      nuit: [false],
      nom: [""],
      detail:[""],
      galenique:[""],
      quantite: [""],
      jourDebut: [""],
      jourFin: [""],
      nbLigne: 0,
      tabAffichage: [1],
      signature: "null",
      documentPDF: "null",
      idconsultation: 0,
      idprestation: 0,
      isSave: false,
      padVisibility: false,
      idordonnance: 0,
    };
  }
  sigPad = {};

  async componentDidMount() {
    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const data = dataJson && JSON.parse(dataJson);

    this.setState({
      idconsultation: sessionStorage.getItem("idconsultation"),
      idprestation: sessionStorage.getItem("idprestation"),
    });

    // this.setState({
    //   professionnel: data.user.professionnelsante,
    // });
    const info =
      sessionStorage.getItem("patientProfile") ||
      sessionStorage.getItem("patientProfile");

    const tmp = info && JSON.parse(info);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", tmp.token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      host + `/ps/${data.user.professionnelsante.idprofessionnelsante}`,
      requestOptions
    )
      .then((response) => {
        if (response.status == 401) {
          sessionStorage.removeItem("user");
          this.props.history.push("/login");
          return null;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        // console.log(result);
        if (result === null) {
        } else {
          this.setState({
            professionnel: result,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error);
      });

    let tabJourDebut = [];
    tabJourDebut.push(this.ecrireDate());

    await fetch(host + `/patient/${tmp.patient.id}`, requestOptions)
      .then((response) => {
        if (response == 401) {
          alert("token non valide");
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result != null) {
          this.setState({
            patient: result,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error)
      });

    // let pfSanteResponse = await
    // 	fetch(`https://kwaber-sante.herokuapp.com/users/${data.user.id}`)

    // let photo = "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
    // let adresse = ""
    // if (data.user.photo[0] !== null && data.user.photo[0] !== undefined) {
    // 	photo = data.user.photo[0].url
    // }
    // if (data.centres !== undefined) {
    // 	adresse = data.centres[0].adresse
    // }

    // if (pfSanteResponse.status == 200) {
    // 	let data = await pfSanteResponse.json()
    // 	this.setState({
    // 		professionnel: data,
    // 		urlPhoto: photo,
    // 		isLoading: false,
    // 		adresseCentre: adresse,
    // 		jourDebut: tabJourDebut
    // 	});

    // }

    // let patientResponse = await
    // 	fetch(`https://kwaber-sante.herokuapp.com/users/${this.props.location.patient.idPatient}`)

    // if (patientResponse.status == 200) {
    // 	let data = await patientResponse.json()
    // 	this.setState({
    // 		patient: data,
    // 	});
    // }
  }

  CalculAge(date) {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  clear = () => {
    this.sigPad.clear();
  };
  save = () => {
    // console.log(this.sigPad.getCanvas().toDataURL("image/png"));
    this.setState({
      signature: this.sigPad.getCanvas().toDataURL("image/png"),
    });
  };
  show() {
    // let cmp = this.state.data.fromData()
    // //this.sigPad = this.state.data.getCanvas().fromData()
    // console.log(this.state.signature, "mmoonn");
  }

  handleChange(e, index, input) {
    if (input == "nom") {
      let tabNom = this.state.nom;
      tabNom[index] = e.target.value;
      this.setState({ nom: tabNom });
    }
    if (input == "galenique") {
      let tabGalenique = this.state.galenique;
      tabGalenique[index] = e.target.value;
      this.setState({ galenique: tabGalenique });
    }
    if (input == "detail") {
      let tabDetail = this.state.detail;
      tabDetail[index] = e.target.value;
      this.setState({ detail: tabDetail });
    }
    if (input == "quantite") {
      let tabQ = this.state.quantite;
      tabQ[index] = e.target.value;
      this.setState({ quantite: tabQ });
    }
    if (input == "jourDebut") {
      let tabJ = this.state.jourDebut;
      tabJ[index] = e.target.value;
      this.setState({ jourDebut: tabJ });
    }
    if (input == "jourFin") {
      let tabJ = this.state.jourFin;
      tabJ[index] = e.target.value;
      this.setState({ jourFin: tabJ });
    }
  }

  handleChangeCheck(e, index, moment) {
    if (moment == "matin") {
      let tabM = this.state.matin;
      tabM[index] = e.target.checked;
      this.setState({
        matin: tabM,
      });
    }
    if (moment == "aprem") {
      let tabA = this.state.aprem;
      tabA[index] = e.target.checked;
      this.setState({
        aprem: tabA,
      });
    }
    if (moment == "soir") {
      let tabS = this.state.soir;
      tabS[index] = e.target.checked;
      this.setState({
        soir: tabS,
      });
    }
    if (moment == "nuit") {
      let tabN = this.state.nuit;
      tabN[index] = e.target.checked;
      this.setState({
        nuit: tabN,
      });
    }
  }
  ecrireDate() {
    var d = new Date();

    var mois = parseInt(d.getMonth());
    var jour = parseInt(d.getDate());
    mois = mois + 1;
    if (mois < 10) {
      mois = "0" + mois;
    }
    if (jour < 10) {
      jour = "0" + jour;
    }

    return d.getFullYear() + "-" + mois + "-" + jour;
  }
  ecrireDate2(date) {
    var d = new Date(date);

    var mois = parseInt(d.getMonth());
    var jour = parseInt(d.getDate());
    mois = mois + 1;
    if (mois < 10) {
      mois = "0" + mois;
    }
    if (jour < 10) {
      jour = "0" + jour;
    }

    return jour + "-" + mois + "-" + d.getFullYear();
  }

  dateAJD() {
    let date = new Date();
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    var nombre = date.getDate();
    var jour = listeJours[date.getDay()];
    var mois = listeMois[date.getMonth()];
    let annee = date.getFullYear()
    return jour + " " + nombre + " " + mois +" "+annee;
  }

  clean() {
    let jourD = [];
    jourD.push(this.ecrireDate());
    this.setState({
      matin: [false],
      aprem: [false],
      soir: [false],
      nuit: [false],
      nom: [""],
      quantite: [""],
      jourDebut: jourD,
      jourFin: [""],
      tabAffichage: [1],
    });
  }

  ajoutElement() {
    let tab = this.state.tabAffichage;
    tab.push(tab[tab.length - 1] + 1);

    let tabM = this.state.matin;
    let tabA = this.state.aprem;
    let tabS = this.state.soir;
    let tabN = this.state.nuit;
    let tabNom = this.state.nom;
    let tabQ = this.state.quantite;
    let tabJD = this.state.jourDebut;
    let tabJF = this.state.jourFin;
    let tabG = this.state.galenique;
    let tabD = this.state.detail;

    tabM.push(false);
    tabA.push(false);
    tabS.push(false);
    tabN.push(false);
    tabNom.push("");
    tabQ.push("");
    tabJD.push(this.ecrireDate());
    tabJF.push("");

    this.setState({
      tabAffichage: tab,
      matin: tabM,
      aprem: tabA,
      soir: tabS,
      nuit: tabN,
      nom: tabNom,
      quantite: tabQ,
      jourDebut: tabJD,
      jourFin: tabJF,
      galenique: tabG,
      detail: tabD
    });
  }

  supprimerElement(index) {
    if (this.state.tabAffichage.length > 1) {
      let tableau = this.state.tabAffichage;
      tableau.splice(index - 1, 1);

      let tabNom = this.state.nom;
      let tabQ = this.state.quantite;
      let tabJD = this.state.jourDebut;
      let tabJF = this.state.jourFin;
      let tabM = this.state.matin;
      let tabA = this.state.aprem;
      let tabS = this.state.soir;
      let tabNuit = this.state.nuit;
      let tabG = this.state.galenique;
    let tabD = this.state.detail;

      for (let i = index; i < tabNom.length; i++) {
        tabNom[i] = tabNom[i + 1];
        tabQ[i] = tabQ[i + 1];
        tabJD[i] = tabJD[i + 1];
        tabJF[i] = tabJF[i + 1];
        tabM[i] = tabM[i + 1];
        tabA[i] = tabA[i + 1];
        tabS[i] = tabS[i + 1];
        tabNuit[i] = tabNuit[i + 1];
        tabG[i] = tabG[i + 1];
        tabD[i] = tabD[i + 1];

      }
      tabNom.pop(); // supression du dernier élément
      tabQ.pop();
      tabJD.pop();
      tabJF.pop();
      tabM.pop();
      tabA.pop();
      tabS.pop();
      tabNuit.pop();
      tabG.pop();
      tabD.pop();

      this.setState({
        tabAffichage: tableau,
        nom: tabNom,
        quantite: tabQ,
        jourDebut: tabJD,
        jourFin: tabJF,
        matin: tabM,
        aprem: tabA,
        soir: tabS,
        nuit: tabNuit,
        galenique: tabG,
        detail:  tabD
      });
      // console.log(
      //   "Nom : ",
      //   this.state.nom,
      //   "; Quantite : ",
      //   this.state.quantite,
      //   "; Jour Début: ",
      //   this.state.jourDebut,
      //   "; JourFin: ",
      //   this.state.jourFin
      // );
      // console.log("--------------------");
      // console.log(
      //   "Matin : ",
      //   this.state.matin,
      //   "; Aprem : ",
      //   this.state.aprem,
      //   "; Soir : ",
      //   this.state.soir,
      //   "; Nuit : ",
      //   this.state.nuit
      // );
    }
  }

  generatePDF = () => {
    var doc = new jsPDF("p", "pt");

    // Haut de la page en bleu
    doc.setFontSize(11);
    doc.addFont("arial", "normal");
    doc.setTextColor(0, 140, 255);
    //gauche
    doc.text(20, 40, "Docteur machin");
    doc.text(20, 55, "Son corps de métier");
    //droite
    doc.text(400, 40, "1, rue de La Pointe");
    doc.text(400, 55, "33 000 Bordeaux");
    doc.text(400, 70, "––––––––––––––––––––");
    doc.text(400, 85, "tel : 06 65 98 74 18");
    doc.text(400, 200, "BrazzaVille, le 10 mai 2021");

    // Corps de page
    doc.setFontSize(12);
    doc.setFont("calibri", "bold");
    doc.text(20, 60, "This is the second title.");
    doc.setFont("arial", "normal");
    doc.text(20, 100, "This is the thrid title.");

    this.setState({
      //documentPDF: doc.output("datauristring")
      documentPDF: doc.output("dataurlnewwindow"),
    });
  };

  generatePDF2 = async () => {
    if (this.state.isSave) {
      var doc = new jsPDF("p", "pt");
      // var doc = new jsPDF("landscape");

      // text pour les commandes à inserer
      // let numéroCommande = "0013";
      let numéroCommande = this.state.idordonnance;
      let dateCommande = this.dateAJD();
      let client = this.state.patient.nom + " " + this.state.patient.prenom;
      let ageClient = this.CalculAge(this.state.patient.datenaissance);
      let adresseClient = this.state.patient.adresse;
      let docteur =
        this.state.professionnel.nom + " " + this.state.professionnel.prenom;
      let adresseCentre = this.state.adresseCentre;
      let ModePaiement = "Carte Bancaire";
      let hauteurTableauDescription = 220;
      let total = this.state.prix;

      var imgData = logo;
      // var imgCode = qrCode;
      // var imgSignature = signature;

      let text = "Ordonnance n° " + numéroCommande + "\n";
      text += "Délivrée le " + dateCommande + "\n";
      text += "À destination de : " + client + "\n"+" Age: "+ageClient;
      text += "Par le docteur " + docteur + "\n";

      let tabD = this.state.nom;
      let tabQ = this.state.quantite;
      let tabT = this.state.jourFin;
      let tabF = this.state.jourDebut;
      let tabG = this.state.galenique;
      let tabDetail = this.state.detail;

      // Haut de la page en bleu
      doc.addImage(imgData, "JPEG", 20, 20, 100, 60);
      //droite
      doc.setFontSize(12);
      doc.addFont("arial", "normal");
      doc.text(270, 35, "Ordonnance : ");
      doc.setFont("arial", "bold");
      doc.text(342, 35, "#" + numéroCommande);
      doc.setFont("arial", "normal");
      doc.text(380, 35, "Délivrée le ");
      doc.setFont("arial", "bold");
      doc.text(440, 35, dateCommande);

      doc.setFontSize(16);
      doc.setFont("arial", "bold");
      doc.text(420, 70, "À destination de :");
      doc.setFontSize(12);
      doc.setFont("arial", "normal");
      doc.text(420, 85, client);
      doc.text(420, 110, " Age: "+ageClient+" ans");
      // doc.text(adresseClient, 400, 100)

      doc.setFontSize(16);
      doc.setFont("arial", "bold");
      doc.text(20, 115, "Docteur");
      doc.setFontSize(12);
      doc.setFont("arial", "normal");
      doc.text(20, 130, docteur);
      // doc.text(20, 145, " Adresse du centre   " + adresseCentre)

      // doc.setFontSize(16);
      // doc.setFont("arial", "bold")
      // doc.text(20, 170, "Moyen de paiment")
      // doc.setFontSize(12);
      // doc.setFont("arial", "normal")
      // doc.text(20, 185, ModePaiement)

      doc.setFontSize(14);
      doc.setFont("arial", "bold");
      doc.text(20, 220, "Produit");
      doc.text(100, 220, "Qté");
      doc.text(150, 220, "Galénique");
      doc.text(240, 220, "Détail");
      doc.text(310, 220, "Periode");
      doc.text(380, 220, "Date de Début");
      doc.text(500, 220, "Date de Fin");

      doc.setFontSize(12);
      doc.setFont("arial", "normal");

      text += "Produits : \n\n";
      for (let i = 0; i < tabT.length; i++) {
        let periode = this.state.matin[i] ? "matin" : "";
        periode += this.state.aprem[i] ? " midi" : "";
        periode += this.state.soir[i] ? " soire" : "";
        periode += this.state.nuit[i] ? " nuit" : "";

        text += "____________________________ \n\n";

        text += tabD[i] + " | Quantite: " + tabQ[i] + " | " + periode + "\n";
        text +=
          "Date de Début: " + tabT[i] + " | Date de Fin: " + tabF[i] + "\n";

        hauteurTableauDescription = hauteurTableauDescription + 30;
        doc.text(20, hauteurTableauDescription, tabD[i]);
        doc.text(tabQ[i], 100, hauteurTableauDescription);
        doc.text(tabG[i], 150, hauteurTableauDescription+'\n');
        doc.text(tabDetail[i], 240, hauteurTableauDescription);
        doc.text(310, hauteurTableauDescription, periode);
        doc.text(this.ecrireDate2(tabF[i]), 380, hauteurTableauDescription);
        doc.text(this.ecrireDate2(tabT[i]), 500, hauteurTableauDescription);
      }

      doc.setFontSize(14);
      doc.setFont("arial", "bold");
      doc.text(350, hauteurTableauDescription + 60, "Signature :");
      doc.setFontSize(14);
      doc.setFont("arial", "normal");
      // doc.addImage(
      //   imgSignature,
      //   "PNG",
      //   350,
      //   hauteurTableauDescription + 90,
      //   200,
      //   50
      // );
      doc.addImage(
        // `${host}/ps/signatures/${this.state.professionnel.signature}`,
        this.state.professionnel.signature,
        "PNG",
        350,
        hauteurTableauDescription + 90,
        200,
        50
      );
      // doc.text(480, hauteurTableauDescription + 60, total + " XAF")

      // doc.setFontSize(14);
      // doc.setFont("arial", "bold")
      // doc.text(350, hauteurTableauDescription + 90, "Réduction :")
      // doc.setFontSize(14);
      // doc.setFont("arial", "normal")
      // doc.text(480, hauteurTableauDescription + 90, total + " XAF")

      // doc.setFontSize(14);
      // doc.setFont("arial", "bold")
      // doc.text(350, hauteurTableauDescription + 120, "Montant total :")
      // doc.setFontSize(14);
      // doc.setFont("arial", "normal")
      // doc.text(480, hauteurTableauDescription + 120, total + " XAF")

      // doc.addImage(imgCode, "PNG", 200, 600, 200, 120);
      const code = await QRCode.toDataURL(text);
      doc.addImage(code, "PNG", 200, 600, 200, 200);

      //doc.setTextColor(0,140,255)

      // //gauche
      // doc.text(20, 40, 'Docteur machin')
      // doc.text(20, 55, 'Son corps de métier')

      // // Corps de page
      // doc.setFontSize(12)
      // doc.setFont("calibri", "bold")
      // doc.text(20, 60, 'This is the second title.')
      // doc.setFont("arial","normal")
      // doc.text(20, 100, 'This is the thrid title.')

      doc.save("ordonnace.pdf");
      // this.setState({
      //   //documentPDF: doc.output("datauristring")
      //   documentPDF: doc.output("dataurlnewwindow"),
      // });
    } else {
      toast.warning("Veuillez sauvegarder avant de génerer le pdf");
    }
  };

  créerPDF() {
    //this.state.documentPDF.save()
    // console.log(this.state.documentPDF, "le doc");
  }

  //   async creerProduit() {
  //     let produitCreer = [];
  //     for (let i = 0; i < this.state.nom.length; i++) {
  //       try {
  //         const response = await axios({
  //           method: "post",
  //           url: "https://kwaber-sante.herokuapp.com/produit-pharmaceutiques",
  //           data: {
  //             libelle: this.state.nom[i],
  //             slug: null,
  //           },
  //         }).then(function (response) {
  //           //On traite la suite une fois la réponse obtenue
  //           produitCreer[i] = response.data;
  //           console.log("produit creer");
  //         });
  //       } catch (err) {
  //         // Handle Error Here
  //         console.error(err);
  //       }
  //     }
  //     return produitCreer;
  //   }

  //   async creerQuantite() {
  //     let quantiteCreer = [];
  //     for (let i = 0; i < this.state.quantite.length; i++) {
  //       try {
  //         const response = await axios({
  //           method: "post",
  //           url: "https://kwaber-sante.herokuapp.com/ligne-produit-prescriptions",
  //           data: {
  //             quantite: this.state.quantite[i],
  //           },
  //         }).then(function (response) {
  //           //On traite la suite une fois la réponse obtenue
  //           quantiteCreer[i] = response.data;
  //           console.log("quantite creer");
  //         });
  //       } catch (err) {
  //         // Handle Error Here
  //         console.error(err);
  //       }
  //     }
  //     return quantiteCreer;
  //   }

  //   async creerPrescription() {
  //     let prescriptionsCreer = [];
  //     for (let i = 0; i < this.state.jourDebut.length; i++) {
  //       try {
  //         const response = await axios({
  //           method: "post",
  //           url: "https://kwaber-sante.herokuapp.com/prescriptions",
  //           data: {
  //             datePrescription: this.state.jourDebut[i],
  //             dateFin: this.state.jourFin[i],
  //             matin: this.state.matin[i],
  //             apresMidi: this.state.aprem[i],
  //             Soir: this.state.soir[i],
  //             Nuit: this.state.nuit[i],
  //           },
  //         }).then(function (response) {
  //           //On traite la suite une fois la réponse obtenue
  //           prescriptionsCreer[i] = response.data;
  //           console.log("prescription creer");
  //         });
  //       } catch (err) {
  //         // Handle Error Here
  //         console.error(err);
  //       }
  //     }
  //     return prescriptionsCreer;
  //   }

  //   async creerOrdonnance(tabPrescription) {
  //     let ordonnanceCreer = "";
  //     try {
  //       const response = await axios({
  //         method: "post",
  //         url: "https://kwaber-sante.herokuapp.com/ordonnances",
  //         data: {
  //           userPro: this.state.professionnel,
  //           userPatient: this.state.patient,
  //           prescriptions: tabPrescription,
  //           date: this.ecrireDate(),
  //         },
  //       }).then(function (response) {
  //         //On traite la suite une fois la réponse obtenue
  //         ordonnanceCreer = response.data;
  //         console.log("ordonnance creer");
  //       });
  //     } catch (err) {
  //       // Handle Error Here
  //       console.error(err);
  //     }
  //     return ordonnanceCreer;
  //   }

  toast() {
    toast.success("Prescription enregistrer avec succès", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    toast.success(
      <Link
        to={{
          pathname: "/doctor/patient-profile",
          patient: {
            idPatient: this.state.patient.id,
          },
        }}
      >
        {" "}
        Voir la prescription
      </Link>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }

  verif() {
    let verifTotal = true;
    for (let i = 0; i < this.state.jourFin.length; i++) {
      if (
        this.state.jourFin[i] == "" ||
        this.state.nom[i] == "" ||
        this.state.quantite[i] == ""
      ) {
        verifTotal = false;
      }
      if (
        this.state.matin[i] == false &&
        this.state.aprem[i] == false &&
        this.state.soir[i] == false &&
        this.state.nuit[i] == false
      ) {
        verifTotal = false;
      }
      if (this.state.jourDebut[i] > this.state.jourFin) {
        verifTotal = false;
      }
    }
    return verifTotal;
  }

  dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  saveSignature = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
      isLoading: true,
    });
    // var formdata = new FormData();
    // formdata.append(
    //   "signature",
    //   this.dataURLtoFile(
    //     this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
    //     "teste.png"
    //   )
    //   // this.sigPad.getTrimmedCanvas().toDataURL("image/png")
    // );

    // var requestOptions = {
    //   method: "POST",
    //   body: formdata,
    //   redirect: "follow",
    // };
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      data: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      host + "/ps/signature/" + this.state.professionnel.idprofessionnelsante,
      requestOptions
    )
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        // console.log(result)
        if (result !== null) {
          this.state.professionnel.signature = result.filename;
          this.setState({});
          toast.success("Signature mise à jour");
          this.cancelSignature();
        } else {
          toast.error("Une erreur c'est produite");
        }
      })
      .catch((error) => {
        // console.log("error", error)
        toast.error("Une erreur c'est produite");
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  nettoyer = () => {
    this.sigPad.clear();
  };

  showSignature = () => {
    this.setState({
      padVisibility: true,
    });
  };

  cancelSignature = () => {
    this.setState({
      padVisibility: false,
    });
  };

  async submit() {
    if (this.verif()) {
      if (this.state.professionnel.signature) {
        this.setState({
          isLoading: true,
        });
        let prescriptions = [];

        for (let i = 0; i < this.state.nom.length; i++) {
          prescriptions.push({
            libelle: this.state.nom[i],
            quantite: this.state.quantite[i],
            matin: this.state.matin[i],
            soire: this.state.soir[i],
            nuit: this.state.nuit[i],
            apresmidi: this.state.aprem[i],
            dateprescription: this.state.jourDebut[i],
            datefin: this.state.jourFin[i],
            detail: this.state.detail[i],
            galenique: this.state.galenique[i],
          });
        }

        // console.table(prescriptions);

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const date = new Date();

        let raw = JSON.stringify({
          idps: this.state.professionnel.idprofessionnelsante,
          idconsultation: this.state.idconsultation,
          idprestation: this.state.idprestation,
          idpatient: this.state.patient.id,
          date:
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate(),
        });

        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        await fetch(host + "/ordonnance/create", requestOptions)
          .then((response) => {
            if (response.status != 200 && response.status != 201) {
              return null;
            }
            return response.json();
          })
          .then((result) => {
            // console.log(result);
            if (result !== null) {
              let heads = new Headers();
              heads.append("Content-Type", "application/json");
              this.setState({
                idordonnance: result.ordonnance.idordonnance,
              });
              let data = JSON.stringify({
                id: result.ordonnance.idordonnance,
                prescriptions: prescriptions,
              });

              let requestOptions = {
                method: "POST",
                headers: heads,
                body: data,
                redirect: "follow",
              };

              fetch(host + "/ordonnance/add-prescription", requestOptions)
                .then((response) => {
                  if (response.status != 200 && response.status != 201) {
                    return null;
                  }
                  return response.json();
                })
                .then((result) => {
                  // console.log(result);
                  if (result !== null) {
                    //   this.clean.bind(this);
                    toast.success("Ordonnance ajouté avec succes");
                    this.setState({
                      isSave: true,
                    });
                  } else {
                    toast.error(
                      "une erreur s'est produite lors de l'ajout de la ordonnance"
                    );
                  }
                })
                .catch((error) => {
                  // console.log("error", error);
                  toast.error(
                    "une erreur s'est produite lors de l'ajout de la ordonnance"
                  );
                });
            } else {
              toast.error(
                "une erreur s'est produite lors de l'ajout de la ordonnance"
              );
            }
          })
          .catch((error) => {
            // console.log("error", error);
            toast.error(
              "une erreur s'est produite lors de l'ajout de la ordonnance"
            );
          })
          .then(() => {
            this.setState({
              isLoading: false,
            });
          });

        //   let tabProduitCreer = await this.creerProduit();
        //   let tabQuantiteCreer = await this.creerQuantite();
        //   let tabPrescription = await this.creerPrescription();
        //   for (let index = 0; index < this.state.tabAffichage.length; index++) {
        //     console.log("boucle submit");
        //     axios
        //       .put(
        //         `https://kwaber-sante.herokuapp.com/ligne-produit-prescriptions/${tabQuantiteCreer[index].id}`,
        //         {
        //           prescriptions: tabPrescription[index],
        //           produit_pharmaceutiques: tabProduitCreer[index],
        //         }
        //       )
        //       .then((response) => {
        //         console.log(response, "reponse ligne produit");
        //       });
        //     axios
        //       .put(
        //         `https://kwaber-sante.herokuapp.com/produit-pharmaceutiques/${tabProduitCreer[index].id}`,
        //         {
        //           ligne_produit_prescriptions: tabQuantiteCreer[index],
        //         }
        //       )
        //       .then((response) => {
        //         console.log(response, "reponse produit");
        //       });
        //     axios
        //       .put(
        //         `https://kwaber-sante.herokuapp.com/prescriptions/${tabPrescription[index].id}`,
        //         {
        //           ligne_produit_prescriptions: tabQuantiteCreer[index],
        //         }
        //       )
        //       .then((response) => {
        //         console.log(response, "reponse prescription");
        //         if (index == this.state.tabAffichage.length - 1) {
        //           this.clean();
        //         }
        //       });
        //   }
        //   let ordonnanceCreer = await this.creerOrdonnance(tabPrescription);
        //   let ordonnancePa = this.state.patient.ordonnances;
        //   let ordonnancesPro = this.state.professionnel.ordonnances;
        //   ordonnancesPro.push(ordonnanceCreer);
        //   ordonnancePa.push(ordonnanceCreer);
        //   axios
        //     .put(
        //       `https://kwaber-sante.herokuapp.com/users/${this.state.professionnel.id}`,
        //       {
        //         ordonnances: ordonnancesPro,
        //       }
        //     )
        //     .then((response) => {
        //       console.log(response, "reponse modif pro");
        //     });
        //   axios
        //     .put(
        //       `https://kwaber-sante.herokuapp.com/users/${this.state.patient.id}`,
        //       {
        //         ordonnances: ordonnancePa,
        //       }
        //     )
        //     .then((response) => {
        //       console.log(response, "reponse modif patient");
        //     });
        //   this.toast();
        // } else {
        //   toast.warning(
        //     "Un ou plusieurs champs sont vides ou une date est incorrect",
        //     {
        //       position: "top-right",
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //     }
        //   );
      } else {
        toast.warning("Veuillez signer puis sauvegarder le document");
      }
    }
    //this.créerPDF()
    // console.log("Nom : ", this.state.nom, "; Quantite : ", this.state.quantite, "; Jour Début: ", this.state.jourDebut, "; JourFin: ", this.state.jourFin)
    // console.log("--------------------")
    // console.log("Matin : ", this.state.matin, "; Aprem : ", this.state.aprem, "; Soir : ", this.state.soir, "; Nuit : ", this.state.nuit)
  }

  render() {
    return (
      <div>
        <Modal
          title={"Mettre à jour la signature"}
          visible={this.state.padVisibility}
          onCancel={this.state.isLoading ? null : this.cancelSignature}
          footer={[
            <Button
              key="back"
              onClick={this.state.isLoading ? null : this.cancelSignature}
              disabled={this.state.isLoading}
            >
              Retour
            </Button>,
            <Button
              key="clear"
              type="primary"
              onClick={this.state.isLoading ? null : this.nettoyer}
              disabled={this.state.isLoading}
            >
              Nettoyer
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={this.state.isLoading}
              loading={this.state.isLoading}
              onClick={this.state.isLoading ? null : this.saveSignature}
            >
              Mise à jour
            </Button>,
          ]}
          // cancelText={"Retour"}
          // okText={"Mise à jour"}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <Loader
            style={{
              position: "absolute",
              textAlign: "center",
              marginLeft: "auto",
              left: "0",
              right: "0",
              marginRight: "auto",
              zIndex: "20px",
            }}
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            visible={this.state.isLoading}
          />
          <SignaturePad
            canvasProps={{ style: { width: "100%", height: "100%" } }}
            ref={(ref) => {
              this.sigPad = ref;
            }}
          />
        </Modal>

        {/* <div className="breadcrumb-bar">
					<div className="container-fluid">
						<div className="row align-items-center">
							<div className="col-md-12 col-12">
								<nav aria-label="breadcrumb" className="page-breadcrumb">
									<ol className="breadcrumb">
										<li className="breadcrumb-item"><a href="/home">Home</a></li>
										<li className="breadcrumb-item active" aria-current="page">Ajouter une Prescription</li>
									</ol>
								</nav>
								<h2 className="breadcrumb-title">Ajouter une Prescription</h2>
							</div>
						</div>
					</div>
				</div> */}
        <div className="content">
          <div className="container-fluid">
            <ToastContainer />
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar dct-dashbd-lft">
                <div className="card widget-profile pat-widget-profile">
                  <div className="card-body">
                    <div className="pro-widget-content">
                      <div className="profile-info-widget">
                        {/* <img src={(this.state.patient.photo[0] !== null && this.state.patient.photo[0] !== undefined) ? this.state.patient.photo[0].url : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} /> */}
                        <a href="#0" class="booking-doc-img">
                          <img
                            src={
                              this.state.patient &&
                              this.state.patient.user &&
                              this.state.patient.user.photo
                                ? 
                                // host + `/user/images/${this.state.patient.user.photo} `
                                this.state.patient.user.photo
                                : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                            }
                          />
                        </a>
                        <br /> <br />{" "}
                        <div className="profile-det-info">
                          <h3>
                            {" "}
                            {this.state.patient.nom} {this.state.patient.prenom}
                          </h3>

                          <div className="patient-details">
                            {/* <h5>
                              <b>Patient ID :</b> {this.state.patient.id}
                            </h5> */}
                            <h5 className="mb-0">
                              {/* <i className="fas fa-map-marker-alt"></i>{" "} */}
                              {this.state.patient.email}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="patient-info">
                      <ul>
                        <li>
                          Téléphone 1
                          <span>{this.state.patient.numtelephone1}</span>
                        </li>
                        <li>
                          Téléphone 2
                          <span>{this.state.patient.numtelephone2}</span>
                        </li>
                        <li>
                          Age{" "}
                          <span>
                            {" "}
                            {this.CalculAge(
                              this.state.patient.datenaissance
                            )}{" "}
                            ans
                          </span>
                        </li>
                        <li>
                          Sexe <span> {this.state.patient.sexe}</span>
                        </li>
                        <li>
                          Groupe sanguin{" "}
                          <span>{this.state.patient.groupesangin}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <div className="profile-sidebar">
                  <div className="card widget-profile pat-widget-profile">
                    <div className="card-body">
                      <div className="pro-widget-content">
                        <div className="profile-info-widget">
                          <a href="#0" class="booking-doc-img">
                            <img src={this.state.urlPhoto} alt="User" />
                          </a>
                          <div className="profile-det-info">
                            <h3>
                              {this.state.patient.nom}{" "}
                              {this.state.patient.prenom}
                            </h3>

                            <div className="patient-details">
                              <h5>
                                <b>Patient ID :</b> {this.state.patient.id}
                              </h5>
                              <h5 className="mb-0">
                                <i className="fas fa-map-marker-alt"></i>{" "}
                                {this.state.patient.adresse}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patient-info">
                        <ul>
                          <li>
                            Téléphone{" "}
                            <span>+{this.state.patient.numTelephone1}</span>
                          </li>
                          <li>
                            Age{" "}
                            <span>
                              {this.CalculAge(this.state.patient.dateNaissance)}
                            </span>
                          </li>
                          <li>
                            Sexe<span> {this.state.patient.sexe}</span>
                          </li>
                          <li>
                            Groupe sanguin <span>AB+</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div
                  className={
                    this.state.isLoading ? "page_wrapper_blur card" : "card"
                  }
                >
                  <div className="card-header">
                    <h4 className="card-title mb-0">Ajouter une ordonnance</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="biller-info">
                          <h4 className="d-block">
                            {/* {this.state.professionnel.prenom}{" "}
                            {this.state.professionnel.nom} */}
                          </h4>
                          <span className="d-block text-sm text-muted">
                            {/* {this.state.professionnel.specialites == undefined
                              ? "Spécialité non spécifiée"
                              : this.state.professionnel.specialites[0].libelle} */}
                          </span>
                          <span className="d-block text-sm text-muted">
                            {/* {this.state.adresseCentre} */}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-6 text-sm-right">
                        <div className="billing-info">
                          <h4 className="d-block">{this.dateAJD()}</h4>
                          {/* <span className="d-block text-muted">#INV0001</span> */}
                        </div>
                      </div>
                    </div>

                    <div className="add-more-item text-right">
                      <button
                        type="button"
                        class="btn btn-link btn-sm"
                        onClick={this.ajoutElement.bind(this)}
                      >
                        <i className="fas fa-plus-circle"></i> ajouter un
                        élément
                      </button>
                    </div>
                    <Loader
                      style={{
                        position: "absolute",
                        textAlign: "center",
                        marginLeft: "auto",
                        left: "0",
                        right: "0",
                        marginRight: "auto",
                        zIndex: "20px",
                      }}
                      type="Puff"
                      color="#00BFFF"
                      height={100}
                      width={100}
                      visible={this.state.isLoading}
                    />

                    <div className="card card-table">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-hover table-center">
                            <thead>
                              <tr>
                                <th style={{ minWidth: "200px" }}>Nom</th>
                                <th style={{ minWidth: "80px" }}>Quantité</th>
                                <th style={{ minWidth: "80px" }}>Forme galénique</th>
                                <th style={{ minWidth: "220px" }}>Détail</th>
                                <th style={{ minWidth: "20px" }}>Début</th>
                                <th style={{ minWidth: "20px" }}>Fin</th>
                                <th style={{ minwWidth: "200px" }}>Période</th>
                                <th style={{ minWidth: "80px" }}></th>
                              </tr>
                            </thead>

                            {this.state.tabAffichage.map((t, index) => {
                              return (
                                <tr>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="text"
                                      value={this.state.nom[index]}
                                      onChange={(e) =>
                                        this.handleChange(e, index, "nom")
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="number"
                                      value={this.state.quantite[index]}
                                      onChange={(e) =>
                                        this.handleChange(e, index, "quantite")
                                      }
                                    />
                                  </td>
                                  <td>
                                      <select  className="form-control"
                                       value={this.state.galenique[index]}
                                       onChange={(e) =>
                                         this.handleChange(e, index, "galenique")
                                       }
                                      >
                                        <option  value="Voie orale">Voie orale ( administré par la bouche)</option>
                                        <option value="Injectable">Injectable (administré par injection)</option>
                                        <option valeur="Dermique">Dermique (administré sur la peau)</option>
                                        <option value="Voie rectale">Voie rectale (administré par le rectum)</option>
                                        <option value="Inhalé">Inhalé ( administré par aérosol)</option>
                                      </select>
                                  </td>
                                  <td>
                                    <textarea 
                                    className="form-control" 
                                    value={this.state.detail[index]}
                                       onChange={(e) =>
                                         this.handleChange(e, index, "detail")
                                       }></textarea>
                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="date"
                                      value={this.state.jourDebut[index]}
                                      onChange={(e) =>
                                        this.handleChange(e, index, "jourDebut")
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      className="form-control"
                                      type="date"
                                      value={this.state.jourFin[index]}
                                      onChange={(e) =>
                                        this.handleChange(e, index, "jourFin")
                                      }
                                    />
                                  </td>
                                  <td>
                                    <div className="form-check form-check-inline">
                                      <label className="form-check-label">
                                        <input
                                          className="form-check-input"
                                          checked={this.state.matin[index]}
                                          onChange={(e) =>
                                            this.handleChangeCheck(
                                              e,
                                              index,
                                              "matin"
                                            )
                                          }
                                          type="checkbox"
                                        />{" "}
                                        Matin
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <label className="form-check-label">
                                        <input
                                          className="form-check-input"
                                          checked={this.state.aprem[index]}
                                          onChange={(e) =>
                                            this.handleChangeCheck(
                                              e,
                                              index,
                                              "aprem"
                                            )
                                          }
                                          type="checkbox"
                                        />{" "}
                                        Après-midi
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <label className="form-check-label">
                                        <input
                                          className="form-check-input"
                                          checked={this.state.soir[index]}
                                          onChange={(e) =>
                                            this.handleChangeCheck(
                                              e,
                                              index,
                                              "soir"
                                            )
                                          }
                                          type="checkbox"
                                        />{" "}
                                        Soirée
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <label className="form-check-label">
                                        <input
                                          className="form-check-input"
                                          checked={this.state.nuit[index]}
                                          onChange={(e) =>
                                            this.handleChangeCheck(
                                              e,
                                              index,
                                              "nuit"
                                            )
                                          }
                                          type="checkbox"
                                        />{" "}
                                        Nuit
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    <button
                                      className="btn bg-danger-light trash"
                                      onClick={this.supprimerElement.bind(
                                        this,
                                        index
                                      )}
                                    >
                                      <i className="far fa-trash-alt"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-right">
                        <div className="signature-wrap">
                          <div className="sign-name">
                            {this.state.professionnel &&
                            this.state.professionnel.signature ? null : (
                              <>
                                <p className="mb-0">
                                  ( {this.state.professionnel.nom}{" "}
                                  {this.state.professionnel.prenom} )
                                </p>
                                <span className="text-muted">Signature</span>
                                <div>
                                  <button
                                    type="button"
                                    class="btn btn-outline-info-lb btn-rounded"
                                    data-mdb-ripple-color="dark"
                                    onClick={this.showSignature}
                                  >
                                    {" "}
                                    Signer
                                  </button>
                                  {/* &nbsp;&nbsp;&nbsp;
															<button class="btn btn-outline-dark btn-rounded btn-sm" data-mdb-ripple-color="dark" onClick={this.clear}>
																Effacer
															</button> */}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="submit-section">
                        <button
                          type="submit"
                          className="btn btn-bluefonce"
                          onClick={this.submit.bind(this)}
                        >
                          sauvegarder
                        </button>
                        &nbsp;
                        <button
                          type="reset"
                          className="btn btn-dark"
                          onClick={this.clean.bind(this)}
                        >
                          Effacer
                        </button>
                        &nbsp;
                        <button
                          type="reset"
                          className="btn btn-dark"
                          onClick={this.generatePDF2.bind(this)}
                        >
                          Générer le PDF
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddPescription;
