import React from "react";
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Header from './client/components/header.jsx';
import Footer from './client/components/footer.jsx';
import LoginContainer from './client/components/login/login.jsx';
import Register from './client/components/register/register.jsx';
import ForgotPassword from './client/components/forgot-password';
import ResetPassword from './client/components/reset-password';
import Home from './client/components/home';
//blog
import BlogList from './client/components/blog/bloglist';
import BlogGrid from './client/components/blog/bloggrid';
import BlogDetails from './client/components/blog/blogdetails';
//pages
import VideoCall from './client/components/pages/videocall';
import VoiceCall from './client/components/pages/voicecall';
import SearchDoctor from './client/components/pages/searchdoctor';
import Calendar from './client/components/pages/calender';
import Invoice from './client/components/pages/invoices/invoices';
import InvoiceView from './client/components/pages/invoices/view';
import DoctorGrid from './client/components/patients/doctorgrid';
import DoctorList from './client/components/patients/doctorlist';
import DoctorProfile from './client/components/patients/doctorprofile';
import DoctorChat from './client/components/doctors/chat';
import PatientChat from './client/components/patients/chat';
import MyPatient from './client/components/doctors/mypatient';
import Booking from './client/components/patients/booking';

import Checkout from './client/components/patients/checkout';
import BookingSuccess from './client/components/patients/booking-success';
import Facture from './client/components/patients/booking/facture';
import Dashboard from './client/components/patients/dashboard';
import Dashboard2 from './client/components/patients/dashboardM';
import Favourties from './client/components/patients/dashboard/favourties';
import Profile from './client/components/patients/dashboard/profile';
import Password from './client/components/patients/dashboard/password';
import DoctorDashboard from './client/components/doctors/dashboard';
import SocialMedia from './client/components/doctors/socialmedia';
import ScheduleTiming from './client/components/doctors/scheduletimings';
import DoctorPassword from './client/components/doctors/password';
import Appointments from './client/components/doctors/appointments';
import PatientProfile from './client/components/doctors/patientprofile';
import AddPrescription from './client/components/doctors/addPrescription';
import AddBilling from './client/components/doctors/addbilling';
import Prestation from './client/components/patients/prestation';
import Ordonnance from './client/components/patients/dashboard/ordonnance';
import ProfileSetting from './client/components/doctors/profilesetting';
import Review from './client/components/doctors/reviews';
import DoctorRegister from './client/components/doctors/register';
import ProfileDoctor from './client/components/doctors/profile';
import Terms from './client/components/pages/terms';
import Policy from './client/components/pages/policy';
import AppUniversal from "./admin/app-universal";
import CheckoutAirtel from "./client/components/patients/booking/airtel_paiement";
import CheckoutAirtelCentre from "./client/components/patients/bookingCentre/airtel_paiementCentre"
import CheckoutMtn from "./client/components/patients/booking/mtn_paiement";
import AirtelBookingSuccess from "./client/components/patients/booking/airtel_book_success"
import AboutUs from './client/components/pages/aboutus/index';
import Contact from './client/components/pages/contact/index';
import MesPrestations from "./client/components/doctors/mesPrestations/index.jsx";
import CheckMail from "./client/components/CheckMail/index.js";
import Error from "./client/components/Error404/index.js";
import SearchMedecin from "./client/components/pages/SearchMedecin/index.js";
import SearchCentre from "./client/components/pages/SearchCentre/index.js";
import SearchLabo from "./client/components/pages/SearchLabo/index.js";
import SearchAll from "./client/components/pages/SearchAll/index.js";
import CentreProfil from "./client/components/patients/centreProfil/index";
import PrestationCentre from "./client/components/patients/prestationCentre/index.js";
import LaboProfil from "./client/components/patients/laboProfil/index.js";

import VideoCall2 from './client/components/pages/videocall1';
import WaitingRoom from './client/components/pages/videocall1/waiting';
import BookingCentre from "./client/components/patients/bookingCentre/index.js";

import GestionDashboard from "./client/components/gestionnnaire/dashboard/index.jsx";
import Prestations from "./client/components/gestionnnaire/prestations/index.jsx";
import Centre from "./client/components/gestionnnaire/centre/index.jsx";
import CentreDoctor from "./client/components/gestionnnaire/doctor/index.jsx";
import Creneau from "./client/components/gestionnnaire/scheduletimings/index.jsx";
import SearchSpecialite from "./client/components/pages/searchSpeciate/index.jsx";
import Confirmation from "./client/components/ConfirmationMail/index.js";
import ErrorToken from "./client/components/ErrorToken/index.js";
import ValidateCompte from "./client/components/ValidateCompte/index.js";
import MyTransaction from "./client/components/patients/dashboard/myTransactions/index.js";
import MyFacture from "./client/components/doctors/mesFactures/index.js";


   


const AppContainer = function (props) {
  if (props) {
    const url = props.location.pathname.split("/")[1];

    // console.log('url', url);
    return (   
      <Router>
        {url === 'admin' ?
          (
            <div>
              <Switch>
                <Route path="/admin" component={AppUniversal} />
              </Switch>
            </div>
          ) : (
            <div>
              {/* <Route path="/sider-menu" exact component={SideMenu} /> */}
              <Route render={(props) => <Header {...props} />} />
              <br />
              <br />
              <Switch>
                <Route path="/qui-sommes-nous" exact component={AboutUs} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/patient/doctor-grid" exact component={DoctorGrid} />
                <Route path="/patient/doctor-list" exact component={DoctorList} />
                <Route path="/pages/video-call" exact component={VideoCall} />
                <Route path="/pages/voice-call" exact component={VoiceCall} />
                <Route path="/doctor/chat-doctor" exact component={DoctorChat} />
                <Route path="/patient/mytansaction" exact component={MyTransaction} />

                <Route path="/login" exact component={LoginContainer} />
                <Route path="/register" exact component={Register} />
                <Route path="/forgot-password/" exact component={ForgotPassword} />
                <Route path="/reset-password/:token" exact component={ResetPassword} />
                <Route path="(/|/home)" exact component={Home} />
                <Route path="/" exact component={Home} />
                {/* blog */}
                <Route path="/blog/blog-list" exact component={BlogList} />
                <Route path="/blog/blog-grid" exact component={BlogGrid} />
                
					      <Route path="/gestionnaire/dashboard" exact component={GestionDashboard} />
                <Route path="/blog/blog-details/:id" exact component={BlogDetails} />
                {/* pages */}
                {/* <Route path="/video-call" exact component={VideoCall} />
              <Route path="/voice-call" exact component={VoiceCall} /> */}
                <Route path="/patient/search-doctor" exact component={SearchDoctor} />
                <Route path="/pages/calendar" exact component={Calendar} />
                <Route path="/doctor/invoice" exact component={Invoice} />
                <Route path="/pages/invoice-view" exact component={InvoiceView} />

                <Route path="/patient/doctor-profile/:id" exact component={DoctorProfile} />
                <Route path="/doctor/my-patients" exact component={MyPatient} />
                <Route path="/patient/booking/prestation=:idprestation&ps=:id" exact component={Booking} />
                <Route path="/patient/bookingCentre/prestation=:idprestation&idcentre=:id" exact component={BookingCentre} />
                <Route path="/patient/patient-chat" exact component={PatientChat} />
                <Route path="/patient/checkout" exact component={Checkout} />
                <Route path="/patient/checkout/airtel" exact component={CheckoutAirtel} />
                <Route path="/patient/checkout/mtn" exact component={CheckoutMtn} />
                <Route path="/patient/checkout/c_airtel" exact component={CheckoutAirtelCentre} />
                <Route path="/patient/airtel/book/success" exact component={AirtelBookingSuccess} />
                <Route path="/patient/booking-success" exact component={BookingSuccess} />
                <Route path="/patient/dashboard" exact component={Dashboard} />
                <Route path="/patient/dashboard2" exact component={Dashboard2} />
                <Route path="/patient/favourites" exact component={Favourties} />
                <Route path="/patient/profile" exact component={Profile} />
                <Route path="/patient/change-password" exact component={Password} />
                <Route path="/doctor/doctor-dashboard" exact component={DoctorDashboard} />  
                <Route path="/doctor/doctor-profile" exact component={ProfileDoctor} />  
					      <Route path="/gestionnaire/prestations" exact component={Prestations} />                
					      <Route path="/gestionnaire/centre" exact component={Centre} />
					      <Route path="/gestionnaire/ps" exact component={CentreDoctor} />
					      <Route path="/gestionnaire/schedule-timing" exact component={Creneau} />
                <Route path="/social-media" exact component={SocialMedia} />
                <Route path="/doctor/schedule-timing" exact component={ScheduleTiming} />
                <Route path="/doctor-change-passwword" exact component={DoctorPassword} />
                {/* <Route path="/chat-doctor" exact component={DoctorChat} /> */}
                <Route path="/doctor/appointments" exact component={Appointments} />
                <Route path="/ordonnance/:id" exact component={Ordonnance} />
                <Route path="/doctor/patient-profile" exact component={PatientProfile} />
                <Route path="/prestation/:id" exact component={Prestation} />
                <Route path="/prestationCentre/:id" exact component={PrestationCentre} />
                <Route path="/add-prescription" exact component={AddPrescription} />
                <Route path="/doctor/mes-prestations" exact component={MesPrestations} />
                <Route path="/add-billing" exact component={AddBilling} />
                <Route path="/doctor/profile-setting" exact component={ProfileSetting} />
                <Route path="/doctor/review" exact component={Review} />
                <Route path="/doctor/doctor-register" exact component={DoctorRegister} />
                <Route path="/doctor/mesfactures" exact component={MyFacture} />
                <Route path="/terms" exact component={Terms} />
                <Route path="/checkMail" exact component={CheckMail} />
                <Route path="/confirmation/:token" exact component={Confirmation } />
                <Route path="/erreur" exact component={ErrorToken } />
                <Route path="/privacy-policy" exact component={Policy}/>
                <Route path="/patient/searchMedecin/:id" exact component={SearchMedecin} />
                <Route path="/patient/searchCentre" exact component={SearchCentre} />
                <Route path="/patient/searchLabo" exact component={SearchLabo} />
                <Route path="/patient/searchAll" exact component={SearchAll} />
                <Route path="/centreProfil/:id" exact component={CentreProfil} />
                <Route path="/LaboProfil/:id" exact component={LaboProfil} />
                <Route path="/validation" exact component={ValidateCompte } />

                
                <Route path="/teleconsultation"  component={VideoCall2}/>
                <Route path="/salle-dattente" exact component={WaitingRoom}/>
                <Route path="/facture" exact component={Facture} />
                <Route path="/specialite/:id" exact component={SearchSpecialite} />
                <Route path="**"  component={Error}/>
              </Switch>
              <Route render={(props) => <Footer {...props} />} />
            </div>
          )}
      </Router>
    )
  }
  return null;
}

export default AppContainer;
