import React, { useState, useEffect } from "react";
import "./facture.css";
import boulle from "../../../assets/images/boulle.jpg";
import logo from "../../../assets/images/kwaber-logor.png";
import barre from "../../../assets/images/barre.jpg";
import jsPDF from "jspdf";
import host from "../../../../host";

function Facture() {
  const [profesionnel_sante, setProfesionnel_sante] = useState("");
  const [patientdata, setPatientdata] = useState("");
  const [prestation, setPrestation] = useState("");
  const [idCommande, setIdCommande] = useState("");
  const [modePaiement, setModePaiement] = useState("");
  const [paiementDate, setPaiementDate] = useState("");
  //  const [state, setstate] = useState(initialState);

  const generatePdf = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [900, 2000],
    });
    doc.html(document.querySelector("#pdf"), {
      callback: function (pdf) {
        pdf.save("facture.pdf");
      },
    });
  };

  useEffect(() => {
    const infoRDV = JSON.parse(window.sessionStorage.getItem("rdv"));
    const idPatient = JSON.parse(window.sessionStorage.getItem("user"));
    let idPro = infoRDV.idPro ? infoRDV.idPro : null;
    let idCentre = infoRDV.idCentre ? infoRDV.idCentre : null;

    let condition = infoRDV.idPro ? `/ps/${infoRDV.idPro}` : `/centres/${infoRDV.idCentre}`;
    const userCommande = JSON.parse(
      window.sessionStorage.getItem("userCommande")
    );
    setPatientdata(idPatient.user.patient);
    setPrestation(infoRDV.idCRDI[0].idprestation);
    setIdCommande(userCommande.idCommande);
    setModePaiement(userCommande.modePaiement);
    setPaiementDate(userCommande.datePaiement);

    // console.log("PRESTATION: ",infoRDV.creneauDispo.idprestation.libelle)

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };


    fetch(host + condition, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("profesionnel_sante:",result)
        setProfesionnel_sante(result);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (

    //  { console.log("profesionnel_sante:",profesionnel_sante) }

    <html lang="fr">
      <button
        onClick={generatePdf}
        className="btn btn-bluefonce text-centre ml-5"
      >
        {" "}
        Téléchargé la facture(pdf)
      </button>
      <body>
        <div id="pdf" class="container" style={{ width: "70%" }}>
          <header>
            <div class="row">
              <div className="col-sm-8">
                <img
                  class="col-auto me-auto"
                  src={boulle}
                  alt=""
                  width="20"
                  height="20"
                  style={{ marginTop: "70px" }}
                />
              </div>
              <div className="col-md-4">
                <img class=" " src={logo} alt="" />
              </div>
            </div>
            <br />
            <br />
          </header>

          {/* <!-- Section-1 --> */}
          <section>
            <div class="row ">
              <div class="col-md-6">
                <h5> Facture de </h5>
                <br />
                <p>
                {profesionnel_sante.libelle ? profesionnel_sante.libelle : profesionnel_sante.nom+" "+profesionnel_sante.prenom }
                  {/* {profesionnel_sante.prenom + " " + profesionnel_sante.nom}{" "} */}
                  <br /> {profesionnel_sante.email} <br />
                  {/* {profesionnel_sante.numtelephone} */}
                  {profesionnel_sante.tel1 ? profesionnel_sante.tel1 : profesionnel_sante.numtelephone}
                   
                </p>
              </div>
              <div class="col-md-6 text-center columns">
                <p></p>
                <h6> N° Commande</h6>
                <p> {idCommande} </p>

                <p></p>
                <h6>Date de commnde</h6>
                <p> {paiementDate} </p>
                <p></p>
              </div>
            </div>
          </section>
          <br />

          <section class="mb-20">
            <div>
              <h5> A destination de</h5>
              <p> {patientdata.prenom + " " + patientdata.nom} </p>
            </div>
            <br />
            <br />
            <br />
            <table
              class="table table-striped  text-center"
              style={{ height: "20em" }}
            >
              <thead>
                <tr>
                  <th scope="col">Prestation</th>
                  <th scope="col">Modalité</th>
                  <th scope="col">Centre</th>
                  <th scope="col">Prix</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{prestation.libelle} </td>
                  <td>{prestation.modalite} </td>
                  <td>{prestation.idcentre2 !== null ? prestation.idcentre2 : "---"} </td>
                  <td>{modePaiement === "Carte Bancaire" ? prestation.pEUR + " EUR" :  prestation.pXAF + " XAF"} </td>
                </tr>

                {/* <tr>
                                <td>Appel vidéo</td>
                                <td>2</td>
                                <td>80</td>
                            </tr> */}
                {/* <tr class="bg-color">
                                <td>Consultation</td>
                                <td>2</td>
                                <td>80</td>
                            </tr> */}
                {/* <tr>
                                <td>Appel vidéo</td>
                                <td>2</td>
                                <td>80</td>
                            </tr>
                            <tr class="bg-color">
                                <td>Consultation</td>
                                <td>2</td>
                                <td>80</td>
                            </tr> */}
                {/* <tr>
                                <td>Appel vidéo</td>
                                <td>2</td>
                                <td>80</td>
                            </tr> */}
              </tbody>
            </table>
            <br />
            <br />

            {/* <div class="col-md-8">
                        <h5>Moyen de Paiement</h5>
                        <p>Carte Bancaire</p>
                    </div> */}
          </section>
          <br />

          <section>
            <div class="row">
              <div class="col-md-8">
                <h5>
                  <b>Moyen de Paiement</b>
                </h5>
                <p> {modePaiement} </p>
              </div>
              <br />
              <br />

              <div class="col-md-4 p-2 bd-highlight mx-auto">
                <table class="table">
                  <tbody>
                    <tr>
                      <td>
                        <b>Frais à payé</b>
                      </td>
                      <td>
                        <b>{modePaiement === "Carte Bancaire" ? prestation.pEUR + " EUR" :  prestation.pXAF + " XAF"}</b>
                      </td>
                    </tr>
                    {/* <tr class="bg-color text-bold">
                                        <td><b>Reduction</b></td>
                                        <td><b>100 XAF</b></td>
                                    </tr> */}
                    <tr>
                      <td>
                        <h5>Montant Total</h5>
                      </td>
                      <td class="text-color">{modePaiement === "Carte Bancaire" ? prestation.pEUR + " EUR" :  prestation.pXAF + " XAF"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />

          <footer>
            <div class="row">
              <div class="col-md-4">
                <h6> ccontact@kwaber.com </h6>
                <p> www.sante.kwaber.com</p>
              </div>
              <div class="col-md-1">
                <img
                  class="col-auto me-auto"
                  src={barre}
                  alt=""
                  width="60"
                  height="60"
                />
              </div>
              <div class="col-md-2" style={{ marginLeft: "-20px" }}>
                <img
                  class="col-auto me-auto"
                  src={boulle}
                  alt=""
                  width="30"
                  height="30"
                />
              </div>
              {/* <div class="col-md-5">
                            <p>Retrouvez nous au 9001, Avenu saint Denis, Nantes Paris </p>
                        </div> */}
            </div>
          </footer>
          <br />
          <br />
        </div>

        {/* <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous"/> */}
      </body>
    </html>
  );
}
export default Facture;
