import React, { Component } from 'react';
import GestionnaireSidebar from '../sidebar';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import ProgressBar from 'react-customizable-progressbar';
import StickyBox from "react-sticky-box";
import host from "../../../../host";
// import { Icon01, Icon02, Icon03, IMG01, IMG02, IMG03, IMG04, IMG05, IMG06 } from '../img';
import { Select } from 'antd';
import { MDBDataTableV5 } from 'mdbreact';
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal';
import {Button, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ModalAntd = require("antd").Modal;



class GestionDashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  key: 1,
		  patients: [],
		  tabCentre:[],
      modal1Visible: false,
      centres:[],
		  rdv: [],
      allPs: [],
      newTabPs: [],
      idCentre: '',
      idPsSelected: "",
      idCentreSelected: "",
      idPs:"",
		  nbreRdv:0,
      show: false,
		  nbPtaient: 0,
		  centreSelected:null,
		  datatable:{
        columns: [
          {
            label: "",
            field: "prenom_nom",
            width: 100,
            attributes: {
              "aria-controls": "DataTable",
              "aria-label": "libelle",
            },
          },
          {
            label: "Email",
            field: "email",
            width: 100,
          },
          {
            label: "Tel",
            field: "numtelephone",
            width: 100,
          },
          {
            label: "Centre",
            field: "centre",
            width: 100,
          },
          {
            label: "Genre",
            field: "genre",
            width: 50,
          },
          {
            label: "Actions",
            field: "actions",
            width: 50,
          },
        ],
			rows: []
		}
		};
		this.handleSelect = this.handleSelect.bind(this);
	  }
   

     handleClose = () => {
          this.setState({
            show: false,
          })
     } 
     handleShow = () => {
       this.setState({
         show: true
       })
     };
	
    // For Modal delete item
    setModal1Visible(modal1Visible) {
      this.setState({ modal1Visible });
    }

    

	  async componentDidMount() {
      const dataJson = sessionStorage.getItem("user") || sessionStorage.getItem("user");
      const data = dataJson && JSON.parse(dataJson);
		const id = 1;
		if (!data) {
		  window.location.assign("/");
		}   

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
    
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
    
      await fetch(
        host + `/gestionnairecentre/${data.user.gestionnairecentre.idgestionnairecentre}`,
        requestOptions
      )
        .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
        })
        .then((result) => {
          //  console.log("ResultGest:", result)
          this.setState({
            centres: result.gestionnaire.centre
          })
        })
        .catch((error) => {
        // console.log("error", error)
        });
	
        this.getAllPS();
    this.reservedDefault();
      this.getAllCenter();

		
	  }
	
	  handleSelect(key) {
		this.setState({ key });
	  }

      getAllPS = () => {
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        
        fetch(host+"/ps", requestOptions)

          .then(response => {
            if(response.status == 200){
                return response.json();
            }
          })
          .then(result => {
            console.log("ALLPS:",result)
             this.setState({
               allPs: result
             })
          })
          .catch(error => console.log('error', error));
      }

    getAllCenter = async () => {
      const dataJson = sessionStorage.getItem("user") || sessionStorage.getItem("user");
		const data = dataJson && JSON.parse(dataJson);
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
    
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
    
      await fetch(
        host + `/gestionnairecentre/${data.user.gestionnairecentre.idgestionnairecentre}`,
        requestOptions
      )
        .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
        })
        .then((result) => {
          //  console.log("ResultGest:", result)
          this.setState({
            centres: result.gestionnaire.centre
          })
        })
        .catch((error) => {
        // console.log("error", error)
        });
    }
	

	  //change centre 
	   handleChangeSelect(e) {
			let tabRdv = [];
			let tabCentreSelected = [];
			for (let index = 0; index < this.state.centres.length; index++) {
				const element = this.state.centres[index];

					if(element.idcentre == e.target.value){
							 console.log("ELEMENT-CHECKED:",element);
							this.setState({
								centreSelected:element
							})
							for (let i = 0; i < element.professionnelsantes.length; i++) {
								const elementRdv = element.professionnelsantes[i];
							tabRdv.push({
                prenom_nom: elementRdv.nom+" "+elementRdv.prenom,
                email: elementRdv.email,
                numtelephone: elementRdv.numtelephone,
                centre:"centre",
                genre: elementRdv.sexe == "masculin" ? <i class="fas fa-male">{"   " + elementRdv.sexe}</i> : <i class="fas fa-female">{"   " +elementRdv.sexe}</i>,
                actions:
                  <div className="actions">
                    <a
                      href="#0"
                      className="btn btn-sm bg-success-light"
                      onClick={() => this.showDetail(elementRdv)}
                    >
                      <i className="fe fe-eye"></i> Detail
                    </a>
                    <a
                      className="btn btn-sm bg-danger-light"
                      onClick={() => this.showDelete(elementRdv, element.idcentre)}
                    >
                      <i className="fe fe-trash"></i> Supprimer
                    </a>
                  </div>
							  },)
								
							}
					}		
			}
			this.setState({
				datatable:{
					columns: this.state.datatable.columns,
					rows: tabRdv
				}
			})
				
		}
	
    //select the centre for add new PS
    handleSelectCenter(e) {
      let tabPs = [];
			let tabCentreSelected = [];
			for (let index = 0; index < this.state.centres.length; index++) {
				const element = this.state.centres[index];

					if(element.idcentre == e.target.value){
                if(element.professionnelsantes.length > 0){
                      for (let i = 0; i < element.professionnelsantes.length; i++) {
                        const elementPsCentre = element.professionnelsantes[i];
                        console.log("elementPsCentre: ",elementPsCentre)
                        for (let j = 0; j < this.state.allPs.length; j++) {
                          const elementPs = this.state.allPs[j];
                          console.log("elementPs :",elementPs)
                      if(elementPsCentre.idprofessionnelsante !== elementPs.idprofessionnelsante){

                        tabPs.push(elementPs)
                      }
                      
                    }
                      }
                }
                if(element.professionnelsantes.length == 0){
                  tabPs = this.state.allPs;
                }
               
                
                
					}		
			}
     
			this.setState({
        newTabPs: tabPs,
        idCentreSelected: e.target.value
			})
    }

    //the ps Selected
    handleSelectPs (e) {
      this.setState({
        idPsSelected: e.target.value
      })
    }

    //the submit form add a new Ps
    handlSubmitAdd = () => {
      console.log("idPsSelected: ",this.state.idPsSelected)
      console.log("idCentreSelected: ",this.state.idCentreSelected)

      let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
  
        let raw = JSON.stringify({
          idcentre: this.state.idCentreSelected,
          idps: this.state.idPsSelected,
        });
  
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
  
        fetch(host + "/gestionnairecentre/add-ps", requestOptions)
          .then((response) => {
            if (response.status == 401) {
              sessionStorage.removeItem("user")
              this.props.history.push("/admin/login");
              return null;
            }
            if (response.status != 200 && response.status != 201) {
              return null;
            }
            return response.json();
          })
          .then((result) => {
            if (result == null) {
              toast.error("La tentative a échoué");
            } else {
              toast.success("Insertion réalisée avec succès");
              this.handleClose();
            }
          })
          .catch((error) => {
            toast.error("La tentative a échoué");
            console.log("error", error);
          });
      
    }


	reservedDefault = () => {
			 if(this.state.centres.length > 0){
				let tabPs = [];
				let tabCentreSelected = [];

        console.log("CENTRE-PP: ",this.state.centres)
   
				// for (let index = 0; index < this.state.centres.length; index++) {
					const element = this.state.centres[0].professionnelsantes;
					 const elemenT = this.state.centres[0];
				  
					 console.log("SSSbefor: ",element)
				   console.log("elemenT: ",elemenT)
	
						if(elemenT.idcentre){
						   this.setState({
							   centreSelected:elemenT
						   })
						   for(let i = 0; i < element.length; i++) {
							   const elementPs = element[i];
							   console.log("SSSAfter: ",elementPs)
                 tabPs.push({
                  prenom_nom: elementPs.nom+" "+elementPs.prenom,
                  email: elementPs.email,
                  numtelephone: elementPs.numtelephone,
                  centre:"centre",
                  genre: elementPs.sexe == "masculin" ? <i class="fas fa-male">{"   " + elementPs.sexe}</i> : <i class="fas fa-female">{"   " +elementPs.sexe}</i>,
                  actions:
                    <div className="actions">
                      <a
                        href="#0"
                        className="btn btn-sm bg-success-light"
                        onClick={() => this.showDetail(elementPs)}
                      >
                        <i className="fe fe-eye"></i> Detail
                      </a>
                      <a
                        className="btn btn-sm bg-danger-light"
                        onClick={() => this.showDelete(elementPs, elemenT.idcentre)}
                      >
                        <i className="fe fe-trash"></i> Supprimer
                      </a>
                    </div>
                 }) 
						   }
						}	
				// }
				this.setState({
					datatable:{
						columns: this.state.datatable.columns,
						rows: tabPs
					}
				})
			 }
			
				 
		 }


     showDelete = (id, idCentre) => {
       this.setModal1Visible(true);
       this.setState({
         idPs: id,
         idCentre: idCentre
       })
       console.log("Element-Delete :",id);
       console.log("Element-idCentre :",idCentre);
     }
		
     delete = () => {
      // this.setState({ isLoading: true });
  
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);
  
      let raw = JSON.stringify({
        idcentre: this.state.idCentre,
        idps: this.state.idPs,
      });
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(host + `/gestionnairecentre/remove-ps/`, requestOptions)
        .then((response) => {
          if (response.status == 401) {
            sessionStorage.removeItem("user")
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200 && response.status != 201) {
            return null;
          }
          return "201";
        })
        .then((result) => {
          console.log("----------", result);
          if (result == null) {
            toast.error("La suppression de la prestation a échoué");
          } else {
            // let tmp = this.state.ps;
            // for (let index = 0; index < tmp.length; index++) {
            //   const element = tmp[index];
            //   if (element.idprofessionnelsante == this.state.idPs) {
            //     tmp.splice(index, 1);
            //     break;
            //   }
            // }
            // this.setState({ ps: [...tmp] });
            toast.success("Suppression réalisée avec succès");
            this.setModal1Visible(false)
            this.getAllCenter();
            this.reservedDefault();
          }
        })
        .catch((error) => {
          toast.error("La suppression de la prestation a échoué");
          console.log("error", error);
        });
    };
	  
	
	  render() {
		

		return (
		  <div>
			<div className="content">
			  <div className="container-fluid">
               {/* modal for delete item */}
               <ModalAntd
                title="Suppression"
                style={{ top: 20 }}
                visible={this.state.modal1Visible}
                onOk={this.delete}
                onCancel={() => this.setModal1Visible(false)}
              >
                <p>Voulez-vous réellement le supprimer.</p>
               
              </ModalAntd>

              {/* //Modal for add item  */}
              <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Ajouter un professionnel de santé</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                          <div className='row'>
                          <div className='col-12'>
                              <label htmlFor=""><strong>Centre</strong> </label><br />
                              <select
                                    id="lang"
                                    onChange={(e) => this.handleSelectCenter(e)}
                                    // value={this.state.prestaChoisiLibelle.libelle}
                                    className="form-control"
                                    required = "required"
                                  >
                                    <option value="default"> selectionnez un centre</option>
                                    {this.state.centres.map((centre) => (
                                      <option value={centre.idcentre}>
                                        {centre.libelle}
                                      </option>
                                    ))}
                            </select>
                                    
                              </div> <br />
                              <div className='col-12'>
                                    <label htmlFor=""><strong>Professionnel de santé</strong> </label><br />
                                    <select
                                    id="lang"
                                    onChange={(e) => this.handleSelectPs(e)}
                                    className="form-control"
                                    required = "required"
                                  >
                                    <option value="default"> selectionnez un professionnel de santé</option>
                                    {this.state.newTabPs.map((ps) => (
                                      <option value={ps.idprofessionnelsante}>
                                        {ps.nom+" "+ps.prenom}
                                      </option>
                                    ))}
                            </select>
                                   
                                    {/* <select className='form-control'>
                                      <option value="">item1</option>
                                      <option value="">item2</option>
                                    </select> */}
                              </div>

                          </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                    Annulé
                  </Button>
                  {/* <Button variant="primary" onClick={this.handleClose}> */}
                  <Button variant="primary" onClick={this.handlSubmitAdd}>
                    valider
                  </Button>
                </Modal.Footer>
              </Modal>

				<div className="row">
				  <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
					<StickyBox offsetTop={20} offsetBottom={20}>
					  <GestionnaireSidebar />
					</StickyBox>
				  </div>
				  <div className="col-md-7 col-lg-8 col-xl-9">
					<div className="row">
					  <div className="col-md-12">
						{/* <h4 className="mb-4">Réservation des patients</h4> */}
						<div className="appointment-tab">
							<div className='col-md-4'>
                <label htmlFor=""> <strong>Centre</strong> </label>
							<select
                            id="lang"
                            onChange={(e) => this.handleChangeSelect(e)}
                            // value={this.state.prestaChoisiLibelle.libelle}
                            className="form-control"
                            
                          >
                            <option value="default"> selectionnez un centre</option>
                            {this.state.centres.map((centre) => (
                              <option value={centre.idcentre}>
                                {centre.libelle}
                              </option>
                            ))}
                          </select>
							</div>
							  <div className="card card-table mb-0">
								<div className="card-body"><br />
								  { 
								  this.state.centreSelected ?
									<MDBDataTableV5 hover 
												searchTop
                                                searchBottom={false}
                                                entriesLabel={"Lignes par pages"}
                                                searchLabel={"Rechercher"}
                                                infoLabel={["", "-", "/", ""]}
                                                noRecordsFoundLabel={
                                                  "Aucune donnée ne correspond"
                                                }
									entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={this.state.datatable}  />
									: <h1>Veuillez choisir un centre SVP!</h1>
								  }
								</div>
               
							  </div> <br/>
                <div className='col-md-4'>
								 <button className='btn btn-primary' onClick={this.handleShow}>Ajouter</button>
                 {/* <Button onClick={this.confirm}>Confirm</Button> */}

                </div>
						</div>
					  </div>
					</div>
				  </div>
				</div>
			  </div>
			</div>
		  </div>
		);
	  }
	}
export default GestionDashboard;