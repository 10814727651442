const urlStripe = {
   // url: 'https://apistripe.transfert.kwaber.com'
      //url: "http://52.47.61.219:3022"
   //url: "http://stripeapitest-env-1.eba-ne4c2fee.eu-central-1.elasticbeanstalk.com"
   //url: "http://stripe-test.kwaber.com/"
    url: "https://kwaber-stripe-paiement-gmneh.ondigitalocean.app"
   // url: "https://kwaber-stripe-api.herokuapp.com/"

};
export default urlStripe;
