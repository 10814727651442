import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AppUtils from "../../../../utils/app_utils";

class TagsWidget extends Component {
    constructor(props) {
        super(props);
        this.state = { data: [], isLoading: true }
    }
    async componentDidMount() {
        let URL = AppUtils.baseURL + "/blog-tags"
        let response = await fetch(URL);
        if (response.status === 200) {
            let data = await response.json();
            this.setState({ data: data, isLoading: false })
        }

    }
    render() {
        if (this.state.isLoading) {
            return <p>IS LOADING WILL REPLACE BY SKELETON</p>
        } else
            return (
                <div className="card tags-widget">
                    <div className="card-header">
                        <h4 className="card-title">Tags</h4>
                    </div>
                    <div className="card-body">
                        <ul className="tags">
                            {this.state.data.map((tag) => {
                                return (
                                    <li><Link href="#" className="tag">{tag.tag}</Link></li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            );
    }
}
export default TagsWidget;