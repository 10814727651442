import React, { Component } from "react";
import {
  Table,
  Switch,
  Button,
  Modal,
  DatePicker,
  Space,
  Collapse,
} from "antd";
import { Link } from "react-router-dom";
import SidebarNav from "../sidebar";
import { PlusOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import {
  MDBDataTableV5,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import { itemRender, onShowSizeChange } from "../paginationfunction";
import host from "../../../host";
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

class Gestionnaire extends Component {
  constructor(props) {
    super(props);

    let token = "";

    if (sessionStorage.getItem("admin")) {
      token = JSON.parse(sessionStorage.getItem("admin")).token;
    }

    this.state = {
      auth: token,
      isLoading: false,
      isModalVisible: false,
      isDetail: false,
      isDelete: false,
      isUpdate: false,
      active:false,
      disabled: true,

      nom: "",
      prenom: "",
      email: "",
      password: "",
      sexe: "",
      number1: "",
      number2: "",
      createdAt: "",

      gestionnaire: null,

      idPs: "",
      idUser: "",
      index: "",
      allData: [],
      columns: [
        {
          label: "Nom",
          field: "nom",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "nom",
          },
        },
        {
          label: "Prenom",
          field: "prenom",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "nom",
          },
        },
        {
          label: "Email",
          field: "email",
          width: 100,
        },
        {
          label: "Tel 1",
          field: "numtel1",
          sort: "asc",
          width: 50,
        },
        {
          label: "Tel 2",
          field: "numtel2",
          sort: "asc",
          width: 50,
        },
        {
          label: "Activé",
          field: "active",
          width: 20,
        },
      ],
      rows: [{}],
    };
  }

  show = (item) => {
    console.log(item);
    this.setState({
      nom: item.nom,
      prenom: item.prenom,
      email: item.email,
      number1: item.numtel1,
      number2: item.numtel2,
      createdAt: item.user.createdAt,
      active: item.user.isActive,
      isDetail: true,
      idUser: item.user.id,
      gestionnaire: item,

    });
  };

  getAllGestionnaires = async () => {
    this.setState({ isLoading: true });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);
    

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    //debut------------ ------recuperation gestionnaire de centre
    await fetch(host + "/gestionnairecentre/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        return response.text();
      })
      .then((result) => {
        if (result == null) {
        } else {
          const data = JSON.parse(result).gestionnaire;
          let items = [];
          for (let i = 0; data.length > i; i++) {
            // console.log("data", data);
            if(data[i].user.isActive === true){
              //  console.log("ok")
              this.setState({
                active: true
              })
            }else(
              this.setState({
                active: false
              })
            )
            items.push({
              // ...data[i],
                nom: data[i].nom,
                prenom: data[i].prenom,
                email: data[i].email,
                number1: data[i].numtel1,
                number2: data[i].numtel2,
                createdAt: data[i].user.createdAt,
                active: <Switch  checked={this.state.active}  disabled={this.state.disabled}/>,
                isDetail: true,
                idUser:  data[i].user.id,
                gestionnaire:  data[i],
          
              
              clickEvent: () => this.show(data[i]),
            });
          }
          this.setState({
            allData: items,
            rows: items,
          });
        }
      })
      .catch((error) => console.log("error", error));
    this.setState({ isLoading: false });
  }

   componentDidMount() {
        this.getAllGestionnaires();
  }

  handleOnclick = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleOk = () => {
    this.setState({
      isModalVisible: false,
      isDetail: false,
    });
  };

  handleChange = (e, tag) => {
    this.state[tag] = e.target.value;
    this.setState({});
  };

  handleOkDelete = () => {
    this.setState({
      isLoading: true,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      host +
        `/gestionnairecentre/delete/${this.state.gestionnaire.idgestionnairecentre}`,
      requestOptions
    )
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status == 200) {
          let tmp = this.state.rows;
          for (let index = 0; index < tmp.length; index++) {
            const element = tmp[index];
            if (
              element.idgestionnairecentre ==
              this.state.gestionnaire.idgestionnairecentre
            ) {
              tmp.splice(index, 1);
              break;
            }
          }
          this.setState({
            allData: [...tmp],
            rows: [...tmp],
          });
          this.setState({ email: "" });
          this.setState({ sexe: "" });
          this.setState({ password: "" });
          this.setState({ nom: "" });
          this.setState({ prenom: "" });
          this.setState({ number: "" });
          this.setState({ biographie: "" });
          this.setState({ createdAt: "" });
          this.setState({ idPs: null });
          this.setState({ idUser: null });
          this.setState({ index: null });
          toast.success("La suppression a été réalisé avec succès");
          this.handleCancel();
          this.handleCancelDelete();
          this.getAllGestionnaires();

          this.setState({
            isDelete: false,
          });
        } else {
          toast.error("La suppression n'a pas été réalisé avec succès");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("La suppression n'a pas été réalisé avec succès" + error);
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  handleCancelDelete = () => {
    this.setState({
      isDelete: false,
    });
    this.setState({ email: "" });
    this.setState({ sexe: "" });
    this.setState({ password: "" });
    this.setState({ nom: "" });
    this.setState({ prenom: "" });
    this.setState({ number: "" });
    this.setState({ biographie: "" });
    this.setState({ createdAt: "" });
    this.setState({ idPs: null });
    this.setState({ idUser: null });
    this.setState({ index: null });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      isDetail: false,
    });
    this.setState({ email: "" });
    this.setState({ sexe: "" });
    this.setState({ password: "" });
    this.setState({ nom: "" });
    this.setState({ prenom: "" });
    this.setState({ number: "" });
    this.setState({ biographie: "" });
    this.setState({ createdAt: "" });
    this.setState({ idspecialite: "" });
    this.setState({ idville: "" });
  };

  handleDelete = (e) => {
    this.setState({
      isDetail: false,
      isDelete: true,
    });
  };

  handleUpdate = (e) => {
    this.setState({
      isDetail: false,
      isUpdate: true,
      isModalVisible: true,
    });
  };

  handleSubmit = (e) => {
    if (this.state.isUpdate) {
      this.setState({
        isLoading: true,
      });

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let raw = JSON.stringify({
        username: this.state.email,
        isActive: this.state.active,
        role: "GESTIONNAIRE",
        gestionnaire: {
          idgestionnairecentre: this.state.gestionnaire.idgestionnairecentre,
          nom: this.state.nom,
          prenom: this.state.prenom,
          email: this.state.email,
          numtel1: this.state.number1,
          numtel2: this.state.number2,
        },
      });

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + `/user/update/${this.state.gestionnaire.user.id}`, requestOptions)
        .then((response) => {
          console.log(response);
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200 && response.status != 201) {
            return null;
          }
          return response.text();
        })
        .then((result) => {
          if (result == null) {
            this.setState({
              isLoading: false,
            });
            toast.error("L'email est deja utilisé");
          } else {
            let tmp = this.state.rows;
            for (let index = 0; index < tmp.length; index++) {
              const element = tmp[index];
              if (
                element.idgestionnairecentre ==
                this.state.gestionnaire.idgestionnairecentre
              ) {
                tmp[index]["nom"] = this.state.nom
                tmp[index]["prenom"] = this.state.prenom
                tmp[index]["email"] = this.state.email
                tmp[index]["numtel1"] = this.state.number1
                tmp[index]["numtel2"] = this.state.number2
                tmp[index]["clickEvent"] = () => this.show(tmp[index])
                break;
              }
            }
            this.setState({
              allData: [...tmp],
              rows: [...tmp],
            });

            this.setState({
              isLoading: false,
            });
            toast.success("Modification réalisé avec succès");
            this.getAllGestionnaires();
            this.handleCancel();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          toast.error("Une erreure c'est produite");
        });
    } else {
      this.setState({
        isLoading: true,
      });
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let raw = JSON.stringify({
        username: this.state.email,
        password: this.state.password,
        isActive: this.state.active,
        role: "GESTIONNAIRE",
        nom: this.state.nom,
        prenom: this.state.prenom,
        email: this.state.email,
        numtel1: this.state.number1,
        numtel2: this.state.number2,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + "/user/register/", requestOptions)
        .then((response) => {
          console.log(response);
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status == 409) {
            return null;
          }
          return response.text();
        })
        .then((result) => {
          if (result == null) {
            this.setState({
              isLoading: false,
            });
            toast.error("L'email est deja utilisé");
          } else {
            console.log("retour", result);
            console.log("result", JSON.parse(result));
            const data = JSON.parse(result);

            this.setState({
              isLoading: false,
            });
            toast.success("Insertion réalisé avec succes");

            this.setState({
              rows: [
                {
                  id: 0,
                  nom: this.state.nom,
                  slug: null,
                  prenom: this.state.prenom,
                  email: this.state.email,
                  numtel1: this.state.number1,
                  numtel2: this.state.number2,
                  user: null,
                  active: <Switch  checked={this.state.active}  disabled={this.state.disabled}/>
                },
                ...this.state.rows,
              ],
            });
            this.handleCancel();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          console.log("error", error);
          toast.error("Une erreure c'est produite");
        });
    }
    e.preventDefault();
  };

  handleSwitch = (e) => {
    // console.log("handleSwitch:", e)
    this.setState({
      active:e
    })
}

  handleCalendar = ([debut, fin], dateStrings, info) => {
    if (debut && fin) {
      const a = Date.parse(debut);
      const b = Date.parse(fin);

      let items = this.state.allData.map((item) => {
        const x = Date.parse(item.user.createdAt);
        if (x >= a && x <= b) {
          return item;
        }
      });

      this.setState({
        rows: items,
      });
    }
  };

  render() {
    return (
      <>
        <ToastContainer />

        {/* Delete Modal */}
        <Modal
          title="Suppression du professionnel de santé"
          visible={this.state.isDelete}
          okText="Confirmer"
          cancelText="Annuler"
          okType="danger"
          onOk={this.state.isLoading ? null : this.handleOkDelete}
          onCancel={this.state.isLoading ? null : this.handleCancelDelete}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <div className="form-content p-2">
            <h4 className="modal-title">
              Supprimer {this.state.prenom + " " + this.state.nom}
            </h4>
            <Loader
              style={{
                position: "absolute",
                textAlign: "center",
                marginLeft: "auto",
                left: "0",
                right: "0",
                marginRight: "auto",
                zIndex: "20px",
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            />
            <p className="mb-4">Confimer vous la suppression</p>
          </div>
        </Modal>

        {/* Delete Modal */}

        <Modal
          title="Détail du professionnel de santé"
          visible={this.state.isDetail}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
        >
          <div className="row">
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Nom :
            </p>
            <p className="col-sm-9">{this.state.nom}</p>
          </div>
          <div className="row">
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Prenom :
            </p>
            <p className="col-sm-9">{this.state.prenom}</p>
          </div>
          <div className="row">
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Email :
            </p>
            <p className="col-sm-9">{this.state.email}</p>
          </div>
          <div className="row">
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Telephone 1:
            </p>
            <p className="col-sm-9">{this.state.number1}</p>
          </div>
          <div className="row">
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Telephone 2:
            </p>
            <p className="col-sm-9">{this.state.number2}</p> <br />
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Compte active 
            </p>
            <p className="col-sm-9">
            <Switch  checked={this.state.active}  disabled={this.state.disabled}/>
            </p><br /><br />
          </div>
          
          {this.state.gestionnaire != null &&
          this.state.gestionnaire.centre.length > 0 ? (
            <>
              <label>Centres</label>
              <Collapse accordion>
                {this.state.gestionnaire.centre.map((centre) => {
                  return (
                    <Panel header={centre.libelle} key={centre.idcentre}>
                      <p>
                        Telephone :{" "}
                        {centre.tel2
                          ? centre.tel1 + " / " + centre.tel2
                          : centre.tel1}
                      </p>
                      <p>
                        Adresses : {centre.adresse}
                        <br />
                        {centre.complementadresse}
                      </p>
                    </Panel>
                  );
                })}
              </Collapse>
              <br />
              <br />
            </>
          ) : null}

          <div className="row">
            <div className="col-sm-5"></div>
            <div className="col-sm-7">
              <a
                className="btn btn-sm bg-success-light"
                onClick={this.handleUpdate}
              >
                <i className="fe fe-pencil"></i> Modifier
              </a>
              <a
                className="btn btn-sm bg-danger-light"
                onClick={this.handleDelete}
              >
                <i className="fe fe-trash"></i> Supprimer
              </a>
            </div>
          </div>
        </Modal>

        <Modal
          title={
            this.state.isUpdate
              ? "Modification du gestionnaire de centre"
              : "Ajouter un gestionnaire de centre"
          }
          visible={this.state.isModalVisible}
          onOk={this.state.isLoading ? null : this.handleOk}
          onCancel={this.state.isLoading ? null : this.handleCancel}
          footer={null}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <form
            action="/"
            onSubmit={this.state.isLoading ? null : this.handleSubmit}
            id="addform"
          >
            <div className="row">
              <div className="form-group col-6 ml-6 mr-6">
                <label>Nom</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Nom"
                  name="nom"
                  value={this.state.nom}
                  disabled={this.state.isLoading}
                  onChange={(e) => this.handleChange(e, "nom")}
                  required
                />
              </div>
              <div className="form-group col-6 ml-6 mr-6">
                <label>Prénom</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Prenom"
                  name="prenom"
                  disabled={this.state.isLoading}
                  value={this.state.prenom}
                  onChange={(e) => this.handleChange(e, "prenom")}
                  required
                />
              </div> <br />

              <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Compte active 
            </p>
            <p className="col-sm-9">
            <Switch  checked={this.state.active}  onChange={this.handleSwitch}/>
            </p><br /><br />

            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                className="form-control"
                type="email"
                placeholder="Email"
                name="email"
                value={this.state.email}
                disabled={this.state.isLoading}
                onChange={(e) => this.handleChange(e, "email")}
                required
              />
            </div>
            <Loader
              style={{
                position: "absolute",
                textAlign: "center",
                marginLeft: "auto",
                left: "0",
                right: "0",
                marginRight: "auto",
                zIndex: "20px",
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            />
            <div className="form-group">
              <label>Tel :</label>
              <input
                className="form-control"
                type="text"
                placeholder="Numero de telephone"
                name="number1"
                value={this.state.number1}
                disabled={this.state.isLoading}
                onChange={(e) => this.handleChange(e, "number1")}
                required
              />
            </div>
            <div className="form-group">
              <label>Tel 2 ( optionnel ) </label>
              <input
                className="form-control"
                type="text"
                placeholder="Numero de telephone 2 ( optionnel )"
                name="number2"
                value={this.state.number2}
                disabled={this.state.isLoading}
                onChange={(e) => this.handleChange(e, "number2")}
              />
            </div>
            {this.state.isUpdate ? (
              false
            ) : (
              <div className="form-group">
                <label>Mot de passe</label>
                <input
                  className="form-control"
                  type="password"
                  placeholder="Mot de passe"
                  name="password"
                  value={this.state.password}
                  disabled={this.state.isLoading}
                  onChange={(e) => this.handleChange(e, "password")}
                  required
                />
              </div>
            )}
            <div className="form-group mb-0">
              <button
                className="btn btn-primary btn-block"
                type="submit"
                disabled={this.state.isLoading}
              >
                {this.state.isUpdate ? "Modifier" : "Ajouter"}
              </button>
            </div>
          </form>
        </Modal>

        <SidebarNav />

        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7">
                  <h3 className="page-title">
                    Liste des gestionnaires de centre
                  </h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admin">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#0">Utilisateur</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      Gestionnaires de centre
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Button
                  className="btn btn-primary  mb-2"
                  type="primary"
                  size="large"
                  onClick={this.handleOnclick}
                >
                  AJOUTER
                </Button>
                <div className="pull-right">
                  <RangePicker
                    placeholder={["Du", "Au"]}
                    onCalendarChange={this.handleCalendar}
                  />
                </div>
                <div
                  className={
                    this.state.isLoading &&
                    !this.state.isDelete &&
                    !this.state.isUpdate
                      ? "page_wrapper_blur card"
                      : "card"
                  }
                >
                  <div className="card-body">
                    <div className="table">
                      <Loader
                        style={{
                          position: "absolute",
                          textAlign: "center",
                          marginLeft: "auto",
                          left: "0",
                          right: "0",
                          marginRight: "auto",
                          zIndex: "2000",
                        }}
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={
                          this.state.isLoading &&
                          !this.state.isDelete &&
                          !this.state.isUpdate
                        }
                      />
                      <MDBDataTableV5
                        entriesOptions={[10, 20, 30]}
                        entries={10}
                        responsive={true}
                        entriesLabel={"Lignes par pages"}
                        searchLabel={"Rechercher"}
                        infoLabel={["", "-", "/", ""]}
                        noRecordsFoundLabel={"Aucune données de correspond"}
                        pagesAmount={1}
                        data={{
                          columns: this.state.columns,
                          rows: this.state.rows,
                        }}
                        searchTop
                        searchBottom={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Gestionnaire;
