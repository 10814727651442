
import Settings from "./sending";
import urlStripe from "./urlStripe";
const url = urlStripe.url;
const defautToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJLV0FCRVIgQVBQUyIsImF1ZCI6IkNMSUVOVCBLV0FCRVIgQVBQIiwic3ViIjoibG9naW5fdXNlciIsImV4cCI6MjQxMzYyMzk0NDQ5fQ.cLDdbWQ04CNfADLzNKYCUk3X0xl0TDAeEebTPqP8Z7BIli1aEu3roPPr_Axc7Yal7XstYmbCspaaqi81z80CLQ";
const createPaymentIntent = options => {
    return window
        .fetch(url + `/create-payment-intent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(options)
        })
        .then(res => {
            if (res.status === 200) {
                return res.json();
            } else {
                return null;
            }
        })
        .then(data => {
            if (!data || data.error) {
                console.log("API error:", {data});
                throw new Error("PaymentIntent API Error");
            } else {
                return data.client_secret;
            }
        });
};
const sendMailExpress = options => {
    return window
        .axios.post(Settings.url + `/api/V1/kwaber/cash/in/airtel/marchand/api`, {
            method: "POST",
            headers: {
                "authorization": "Bearer " + defautToken,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(options)
        })
        .then(res => {
            if (res.status === 200) {
                return res.json();
            } else {
                return null;
            }
        })
        .then(data => {
            if (!data || data.error) {
                console.log("API error:", {data});
                throw new Error("PaymentIntent API Error");
            } else {
                return data;
            }
        });
};
const standardTransfert = (options, token) => {
    return window
        .fetch(Settings.url + `/api/V1/strandardTransfers/`, {
            method: "POST",
            headers: {
                "authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(options)
        })
        .then(res => {
            if (res.status === 200) {
                return res.json();
            } else {
                return null;
            }
        })
        .then(data => {
            if (!data || data.error) {
                console.log("API error:", {data});
                throw new Error("PaymentIntent API Error");
            } else {
                return data;
            }
        });
};
const airtel = (options, token) => {
    return window
        .fetch(Settings.url + `/api/V1/kwaber/cash/in/airtel/marchand/api`, {
            method: "POST",
            headers: {
                "authorization": "Bearer " + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(options)
        })
        .then(res => {
            if (res.status === 200) {
                return {status: res.status, message: res.body};
            } else {
                return {status: res.status, message: res};
            }
        })
        .catch(data => {
            console.log(data, "data datat")
                return data;

        });
};


const getPublicStripeKey = options => {
    return
    fetch(url + `/public-key`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then(res => {
            if (res.status === 200) {
                return res.json();
            } else {
                return null;
            }
        })
        .then(data => {
            if (!data || data.error) {
                console.log("API error:", {data});
                throw Error("API Error");
            } else {
                return data.publicKey;
            }
        });
};

const api = {
    createPaymentIntent,
    getPublicStripeKey: getPublicStripeKey,
    apiAirtel: airtel,
    standardTransfert: standardTransfert
};

export default api;
