import React, {Component} from 'react';
import "../CheckMail/checkmail.css";
import host from '../../../host';
// import validate from "../../../assets/images/coche.png";
import logo from "../../assets/images/good.png"
import { Button } from 'antd';


export class Confirmation extends Component {
        constructor(props) {
            super(props)
        
            this.state = {
                 token:"",
                 loadings: false
            }
           this.loginUser = this.loginUser.bind(this) 
           this.confimToken = this.confimToken.bind(this) 
        }

     loginUser = () => {
         this.setState({
            loadings: true
         })
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
              };
              
              fetch(host+"/user/loginUser/"+this.state.token, requestOptions)
                .then(response => response.json())
                .then(result =>{ 
                    // console.log("RESULT: ",result)
                    if (result.patient) {
                        sessionStorage.setItem(
                            "user",
                            JSON.stringify({
                                token: result.token,
                                userId: result.userId,
                                user: result,
                                state: true,
                            })
                            );
                            // setTimeout(() => {
                                window.location.assign("/patient/dashboard");
                                // window.location.assign("/doctor/doctor-dashboard");

                            // }, 5000);
                      }
                      if (result.professionnelsante) {
                        sessionStorage.setItem(
                            "user",
                            JSON.stringify({
                                token: result.token,
                                userId: result.userId,
                                user: result,
                                state: true,
                            })
                            );
                            setTimeout(() => {
                                window.location.assign("/doctor/doctor-dashboard");
                            }, 4000);
                      }
                      if (result.gestionnairecentre && result.gestionnairecentre !== null) {
                        window.location.assign("/gestionnaire/dashboard");
                        sessionStorage.setItem(
                          "user",
                          JSON.stringify({
                            token:result.token,
                            userId:result.userId,
                            user: result,
                            state: true,
                          })
                        );
                      }
                })
                .catch(error => {
                    // console.log('error', error)
                    this.setState({
                        loadings: false
                    })
            });
        }
    confimToken = () => {
        console.log('tokn', this.state.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          
          fetch(host+"/user/confirm/"+this.state.token, requestOptions)
            .then(response => {
                response.json()
                // if(response.status == 404){
                //     window.location.assign('/')
                // }
            })
            .then(result => {console.log("result", result)})
            .catch(error => console.log('error', error));
    }



    componentDidMount() {
        const {
            match: { params },
          } = this.props;
          const token = params.token;
          this.setState({
            token: token
          })
        //  this.confimToken();
    }
    

    render() {
        // console.log("token: ", this.state.token);
        return (
            <div className="container">
                <div className="row"><br/>
                <div className=" text-center jumbotron">
                    <p className="text-center"> <img  src={logo} alt="" width="200px" height="200px" /> </p>
                    <h1>Votre compte a été activé avec succès</h1><br /><br />
                    <p>
                        {/* <a class="btn btn-primary btn-lg" onClick={this.loginUser} >Accéder à votre compte</a> */}
                    <Button  class="btn btn-primary btn-lg" loading={this.state.loadings} onClick={this.loginUser}>
                            Accéder à votre compte
                    </Button>   
                    </p>
                </div>
        </div>	
    </div>
        )
    }
}

export default Confirmation

