// key prod
//const API_KEY_SECRET="sk_live_3YvUSOAPFLJHxSpeIZ4RacUC00ALIOm5IJ"
//const API_KEY_PUBLIC = "pk_live_geIaqusrHThHpxZUAWBeD3tI00laas8xFd"
// key test

const API_KEY_SECRET="sk_test_cv3MTNk7vsKI6Qd1EtZqIPEm00q71QlsGf"
const API_KEY_PUBLIC = "pk_test_BakcgBeVEAP8bKjjBrCnb4Nr00UuUQvHQQ"


export {
    API_KEY_PUBLIC,
    API_KEY_SECRET
}
