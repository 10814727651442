import React, { Component } from "react";

// import { IMG01, IMG02 } from "./img";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Countdown from "react-countdown";
import CountDownTimer from "./Counter";
import { Link } from 'react-router-dom';
import { Table } from "antd";
import host from "../../../../host";
import hostMeet from "../../../../hostMeet";
import DoctorSidebar from "../../doctors/sidebar";
import StickyBox from "react-sticky-box";



import {
  itemRender,
  onShowSizeChange,
} from "../../../../admin/components/paginationfunction";
import IMG01 from "../../../../admin/assets/images/doctor-thumb-01.jpg";
import IMG02 from "../../../../admin/assets/images/doctor-thumb-02.jpg";
import IMG03 from "../../../../admin/assets/images/doctor-thumb-03.jpg";
import IMG04 from "../../../../admin/assets/images/doctor-thumb-04.jpg";
import IMG05 from "../../../../admin/assets/images/doctor-thumb-01.jpg";
import IMG06 from "../../../../admin/assets/images/doctor-thumb-02.jpg";
import IMG07 from "../../../../admin/assets/images/doctor-thumb-03.jpg";

//const hoursMinSecs = {hours:0, minutes: 1, seconds: 10}
import { MDBDataTable } from 'mdbreact';
import { WindowsFilled } from "@ant-design/icons";

  const data = {
    columns: [
      {
        label: 'Id',
        field: 'id',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Prénom & Nom',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Prestation',
        field: 'position',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Heure du RDV',
        field: 'office',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Heure d’arrivée',
        field: 'age',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Durée d’attente',
        field: 'date',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Actions',
        field: 'salary',
        sort: 'asc',
        width: 100
      }
    ],
    rows: [
      {
        name: ' ',
        position: '',
        office: '',
        age: '',
        date: '',
        salary: ''
      },
      {
        name: '',
        position: '',
        office: '',
        age: '',
        date: '',
        salary: ''
      },
  
      {
        name: '',
        position: '',
        office: '',
        age: '',
        date: '',
        salary: ''
      },


    ]
  };

class WaitingRoom extends Component {
  constructor(props) {
		super(props);

    this.state = {
      hoursMinSecs: "",
      show: null,
      idPs:"",
      tabSalle: [],
      tabAll:[],
      tabTimestamp: [],
      tabHeureDebut: [],
      tabDteConsult: [],
      token: [],
      heureRdv: [],
      data: []
      // data: [
      //   {
      //     id: "Arold ben",
      //     patientid: "Consulation generale",
      //     patientName: "12h30",
      //     patientimg: "12h20",
      //     Amount: "10mins",
      //     date: "20 Septembre 2021",
      //   },
      //   {
      //     id: "Harold  H",
      //     patientid: " generale",
      //     patientName: "12h30",
      //     patientimg: "12h20",
      //     Amount: "10mins",
      //     date: "20 Septembre 2021",
      //   },
      // ],
    };


	}


  deletePatient = () => {

  }
 
  closingCode = () => {
    
    for (let index = 0; index < this.state.tabSalle.length; index++) {
      const elementTokenSalle = this.state.tabSalle[index];
          for (let i = 0; i < elementTokenSalle.patient.consultation.length; i++) {
            const elementConsultToken = elementTokenSalle.patient.consultation[i];
            for (let j = 0; j < this.state.token.length; j++) {
              const elementtoken = this.state.token[j];
              if( elementtoken === elementConsultToken.token){
                console.log("Ok: ");
                  this.setState({
                    tabAll : [...this.state.tabAll, {"consultation":elementConsultToken, "salleAttente":elementTokenSalle}],
                    tabDteConsult: [...this.state.tabDteConsult, elementConsultToken.dateconsultation],
                    tabHeureDebut: [...this.state.tabHeureDebut, elementConsultToken.heuredebutconsultation]
                  })    
               }
            }
            
          }

    }
    //       let tabTimestamp = [];
    // for (let i = 0; i < this.state.tabDteConsult.length; i++) {
    //     const element1 = this.state.tabDteConsult[i];
    //         for (let j = 0; j < this.state.tabHeureDebut.length; j++) {
    //           const element2 = this.state.tabHeureDebut[j];
    //               if(element1 && element2 ){
    //                 const tab= element1+" "+element2;
    //                 let timeDebut = new Date(element1 + " " + element2).getTime() + 3*60*60*1000;


    //                      tabTimestamp = [...tabTimestamp, timeDebut]
    //                      this.setState({
    //                       tabTimestamp:tabTimestamp
    //                      })
    //                     // console.log("TOUT:")
    //               }
    //         }
      
    // }
      
              
          let today = new Date();
          today = today.getTime();

        for (let index = 0; index < this.state.tabAll.length; index++) {
          const element = this.state.tabAll[index];
          const tab= element.consultation.dateconsultation+" "+element.consultation.heuredebutconsultation;
          let timeDebut = new Date(tab).getTime() + 3*60*60*1000;
        //   console.log("timeDebut: ", timeDebut);
        //  console.log("TimeToday: ", today);

                if (today > timeDebut) {
                      //  console.log("TRRRR: ", element.salleAttente);
                      let myHeaders = new Headers();
                      let raw = "";

                      let requestOptions = {
                        method: "DELETE",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow",
                      };

                      fetch(
                        host + "/salle-dattente/delete/" + element.salleAttente.id,
                        requestOptions
                      )
                        .then((response) => response.text())
                        .then((result) => console.log(result))
                        .catch((error) =>
                          console.log("error", host + "/salle-dattente/delete/" )
                        );
                      return null;
                }else{
                  console.log("obj");
                }
        }

    // let myHeaders = new Headers();
    // let raw = "";

    // let requestOptions = {
    //   method: "DELETE",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // fetch(
    //   host + "/salle-dattente/delete/" + sessionStorage.getItem("id"),
    //   requestOptions
    // )
    //   .then((response) => response.text())
    //   .then((result) => console.log(result))
    //   .catch((error) =>
    //     console.log("error", host + "/salle-dattente/delete/" )
    //   );
    // return null;
  }

  componentDidMount(){
        const dataJson = sessionStorage.getItem('user');
        const dataUser = dataJson && JSON.parse(dataJson);
        this.setState({
          idPs: dataUser.user.professionnelsante.idprofessionnelsante
        })

        // console.log("DataPS_K: ",dataUser.user.professionnelsante.idprofessionnelsante);

  setInterval(() => {
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      
      fetch( host+"/salle-dattente/", requestOptions)
        .then(response => response.json())
        .then(result => {
          //  console.log("RESULT_T: ",  result);
          let items = [];
          let tabToken = [];
          this.setState({
            tabSalle: result
          })
          

          
          // if(result.online == 0){
            for (let i = 0; i < result.length; i++) {       
              
               if(result[i].online === true && (result[i].professionnelsante.idprofessionnelsante === this.state.idPs) ){
              //  if(result){
                items = [...items ,{
                  token: result[i].token,
                  Id: result[i].id,
                  id: result[i].patient.prenom+" "+result[i].patient.nom,
                  patientid:  result[i].prestation,
                  patientName: result[i].heureRdv,
                  patientimg: result[i].heureArrivee,
                  Amount: result[i].heureArrivee,
                  date: result[i].duree       
                }];
                tabToken = [...tabToken, result[i].token]
              }
              //  if(){

              // }
              this.setState({
                  data: items,
                  token:tabToken,
                   heureRdv: [...this.state.heureRdv, result[i].heureRdv]
              })
              }     
               this.closingCode();
        })
        .catch(error => console.log('error', error));
    }, 2000);

   

       

    this.setState({
			hoursMinSecs: {hours:0, minutes: 1, seconds: 0},
		});

   

  }

  

    // closingCode()


  handleClose = () => {
    this.setState({
      show: false
    });
  }

  handleShow = (id) => {
    // this.setState({
    //   show: id
    // });
   
    window.location.assign(hostMeet+`ps/${id} `)

  }

  render() {

        const dataJson = sessionStorage.getItem('user');
        const dataUser = dataJson && JSON.parse(dataJson);

      console.log("TAB_All: ",this.state.tabAll);
      // console.log("DATA_Token: ",this.state.token);
      // console.log("DATA-Salle: ", this.state.tabSalle);
      // console.log("TAB_DATE: ",this.state.tabDteConsult);
      // console.log("tabTimestamp: ",this.state.tabTimestamp);

      
    const { data } = this.state;

    const columns = [ 
      {
        title: "Id",
        dataIndex: "Id",
        render: (text, record) => (

          <Link to="/admin/invoice" className="invoice-link">{text}</Link>

        ),
        sorter: (a, b) => a.id.length - b.id.length,
      },
      {
        title: "Prénom & Nom",
        dataIndex: "id",
        render: (text, record) => (

          <Link to="/admin/invoice" className="invoice-link">{text}</Link>

        ),
        sorter: (a, b) => a.id.length - b.id.length,
      },
      {
        title: "Prestation",
        dataIndex: "patientid",
        sorter: (a, b) => a.patientid.length - b.patientid.length,
      },
      {
        title: "Heure de RDV",
        dataIndex: "patientName",
        render: (text, record) => (
          <h2 className="table-avatar">
            <a
              href="/admin/profile"
              className="avatar avatar-sm mr-2"
            >
              <img alt="" src={record.patientimg} />
            </a>
            <a href="/admin/profile">{text}</a>
          </h2>
        ),
        sorter: (a, b) => a.patientName.length - b.patientName.length,
      },
      {
        title: "Heure d’arrivée",
        dataIndex: "Amount",
        sorter: (a, b) => a.Amount.length - b.Amount.length,
      },
      {
        title: "Durée d’attente",
        dataIndex: "date",
        sorter: (a, b) => a.date.length - b.date.length,
      },
      {
        title: 'Action',
        dataIndex: "token",
        render: (text, record) => (
          <div className="actions">

            {/* <a href='#'className="btn btn-sm bg-danger-light" onClick={() => this.handleShow(record.token)}><i className="fe fe-trash"></i> Rejoindre</a> */}
            {/* <Link  to="#" className="btn btn-sm bg-danger-light" onClick={() => this.handleShow(record.token)}><i className="fe fe-trash"></i> Rejoindre</Link> */}
            <Link  to="#" className="btn btn-sm bg-primary-light" onClick={() => this.handleShow(record.token)}><i className="fas fa-video"></i> Rejoindre</Link>
          </div>
        ),

      }
    ];

    return (
      
      <>
        <div className="content">
          <div className="container-fluid">
              <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <DoctorSidebar />
                </StickyBox>
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="call-wrapper">
              <div className="call-main-row">
                <div className="call-main-wrapper">                 
                  <div className="call-view">
                    <div className="call-window">
                      <div className="fixed-header">
                        <div className="navbar">
                          <div className="user-details">
                            <div className="float-left user-img">
                              <a
                                className="avatar avatar-sm mr-2"
                                href="/doctor/patient-profile"
                                title={ dataUser ? dataUser.user.professionnelsante.prenom + " " + dataUser.user.professionnelsante.nom : ""}
                              >
                                <img
                                  src={IMG02}
                                  alt="User"
                                  className="rounded-circle"
                                />
                                <span className="status online"></span>
                              </a>
                            </div>
                            <div className="user-info float-left">
                              <a href="/doctor/patient-profile">
                                <span> { dataUser ? dataUser.user.professionnelsante.prenom + " " + dataUser.user.professionnelsante.nom : ""} </span>
                              </a>
                              <span className="last-seen">En ligne</span>
                            </div>
                          </div>
                          <ul className="float-center ">
                            <h2>Patients en salle d’attente​</h2>
                          </ul>
                        </div>
                      </div>

                      <div className="call-contents">
                        <div className="call-content-wrap">
                          <div className="user-video">
                          <br/> <br/>
                          <div className="container">
                              <MDBDataTable
                              striped
                              bordered
                              small
                              data={data}
                            />
                              <Table
                                className="table-striped"
                                style={{ overflowX: "auto" }}
                                columns={columns}
                                // bordered
                                dataSource={data}
                                rowKey={(record) => record.id}
                                showSizeChanger={true}
                                pagination={{
                                  total: "20",
                                  showTotal: (total, range) =>
                                    `Affichage ${range[0]} de ${range[1]} à ${total} participant(s)`,
                                  showSizeChanger: true,
                                  onShowSizeChange: onShowSizeChange,
                                  itemRender: itemRender,
                                }}
                              />
                          </div>
                          </div>
                          
                        </div>
                      </div>
                      <div className="call-footer">
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              </div>
              
              </div>
          </div>
        </div>
      </>
    );
  }
}

export default WaitingRoom;
