import React, { Component } from "react";
import loginBanner from "../../assets/images/login-banner.png";
//Icon
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Link } from "react-router-dom";
import { Input } from "antd";
import host from "../../../host";
import { Button } from 'antd';


class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      loadings: false,
      redirectToReferre: false,
      code: "",
      password: "",
      password_confirm: "",
      token: "",
    };
    this.submit = this.submit.bind(this);
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const token = params.token;
    this.setState({
      token: token,
    });
    document.body.classList.add("account-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("account-page");
  }

  submit(event) {
    event.preventDefault();
    this.setState({
      isLoading: true,
      redirectToReferre: true,
      loadings: true
    });

    if (
      this.state.password === "" ||
      this.state.password.length < 6 ||
      this.state.password_confirm === "" ||
      this.state.password !== this.state.password_confirm
    ) {
      toast.warn("Veuillez bien remplir les champs demandés.", {});
      this.setState({
        loadings: false
      })
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        password: this.state.password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + "/auth/" + this.state.token, requestOptions)
        .then((response) => {
			// response.json()
			if (response.status === 201) {
				toast.success("le mot de passe à été modifié avec succès.",{})
        this.setState({
          loadings: false
        })
			}
			if(response.status === 401) {
				toast.warn("Le lien de reinitialisation du password n'est plus valide.",{})
        this.setState({
          loadings: false
        })
			}
		})
        .catch((error) => {
			toast.success("l'erreur lors de la soumission.")
			this.setState({
        loadings: false
      })
		});
    }
    
  }

  render() {
    return (
      <React.Fragment>
        <div className="content">
          <ToastContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="account-content">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-md-7 col-lg-6 login-left">
                      <img
                        src={loginBanner}
                        className="img-fluid"
                        alt="Login Banner"
                      />
                    </div>
                    <div className="col-md-12 col-lg-6 login-right">
                      <div className="login-header">
                        <h3>Choisissez un nouveau mot de passe</h3>
                      </div>

                      <form onSubmit={this.submit}>
                        <div className="form-group" style={{ margin: "0" }}>
                          <label className="focus-label" htmlFor="password">
                            {" "}
                            Mot de passe<span className="etoile"> * </span>
                          </label>
                            <Input.Password
                            type="password"
                            className="form-control"
                            name="password"
                            placeholder="votre Mot de passe"
                            id="password"
                            onChange={(e) => {
                              this.setState({ password: e.target.value });
                            }}
                            value={this.state.password}
                          />
                          <br />
                          <em className="etoile">
                            {" "}
                            le mot de passe doit contenir au moins 6 caractères
                            ou plus{" "}
                          </em>
                        </div>
                        <br />
                        <div className="form-group" style={{ margin: "0" }}>
                          <label
                            className="focus-label"
                            htmlFor="ConfirmPassword"
                          >
                            {" "}
                            Confirmation du Mot de passe
                            <span className="etoile"> * </span>
                          </label>
                          <Input.Password
                            type="password"
                            className="form-control"
                            placeholder="votre mot de passe "
                            id="ConfirmPassword"
                            onChange={(e) => {
                              this.setState({
                                password_confirm: e.target.value,
                              });
                            }}
                            value={this.state.password_confirm}
                          />
                        </div>
                        <br />
                      <Button type="submit" className="btn btn-bluefonce pull-right" loading={this.state.loadings} onClick={this.submit}>
                      Mettre à jour
												</Button> 
                        {/* <button
                          className="btn btn-bluefonce btn-block btn-lg login-btn"
                          type="submit"
                        >
                          Mettre à jour
                        </button> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ResetPassword;
