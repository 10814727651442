import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import IMG01 from '../../../assets/images/doctor-thumb-02.jpg';
class GestionnaireSidebar extends Component {

    logout = (e) => {
        e.preventDefault()
        sessionStorage.removeItem("user")
        sessionStorage.removeItem("user")
        window.location.assign('/login')
    }

    render() {
        const dataJson = sessionStorage.getItem('user');
        const data = dataJson && JSON.parse(dataJson);

        console.log("Session:",data);
        return (
            <div className="profile-sidebar">
              
                <div className="widget-profile pro-widget-content">
                    <div className="profile-info-widget">
                        <Link href="#" className="booking-doc-img">
                            {/* <img src={data.user.photo[0].url} alt="User" /> */}
                           {/* <img src={(data.user.photo[0] !== null && data.user.photo[0] !== undefined) ? data.user.photo[0].url : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} /> */}
                           <img src="https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"/>
                        </Link>
                        <div className="profile-det-info">
                            <h3>
                                {
                                    data ? data.user.gestionnairecentre.prenom + " " + data.user.gestionnairecentre.nom : ""
                                }

                            </h3>

                            <div className="patient-details">
                                <h6> {data ? data.user.gestionnairecentre.email : ""} </h6>
                                <h5 className="mb-0"> {"Gestionnaire de centre"} </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-widget">


                    <Nav className="dashboard-menu">

                        <Nav.Item>
                            <NavLink to="/gestionnaire/dashboard" activeClassName="active">
                                <i className="fas fa-columns"></i>
                                <span>Tableau de bord</span>
                            </NavLink>
                        </Nav.Item>
                        
                        <Nav.Item>
                            <NavLink to="/gestionnaire/centre" activeClassName="active">
                                <i className="fas fa-columns"></i>
                                <span>Centre</span>
                            </NavLink>
                        </Nav.Item>

                        {/* <Nav.Item>
                            <NavLink to="/doctor/appointments" activeClassName="active">
                                <i className="fas fa-calendar-check"></i>
                                <span>Rendez-vous</span>
                            </NavLink>
                        </Nav.Item> */}
                        <Nav.Item>
                            <NavLink to="/gestionnaire/prestations">
                                <i class="fas fa-align-justify"></i>
                                <span>Prestations</span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item>
                            <NavLink to="/gestionnaire/ps" activeClassName="active">
                                <i className="fas fa-user-injured"></i>
                                <span>Professionnel de santé</span>
                            </NavLink>
                        </Nav.Item>
                        {/* <Nav.Item>
                            <NavLink to="/gestionnaire/patients" activeClassName="active">
                                <i className="fas fa-user-injured"></i>
                                <span>Patients</span>
                            </NavLink>
                        </Nav.Item> */}
                        <Nav.Item>
                            <NavLink to="/gestionnaire/schedule-timing" activeClassName="active">
                                <i className="fas fa-hourglass-start"></i>
                                <span>Disponibilités</span>
                            </NavLink>
                        </Nav.Item>
                        
                        <Nav.Item>
                            <NavLink to="" activeClassName="active">
                                <i className="fas fa-sign-out-alt"></i>
                                <span onClick={this.logout}>Déconnexion</span>
                            </NavLink>
                        </Nav.Item>
                    </Nav>

                </div>
            </div>
        );
    }
}
class DetailProfDeSante extends Component {
    render() {
        return (
            <div className="widget-profile pro-widget-content">
                <div className="profile-info-widget">
                    <Link href="#" className="booking-doc-img">
                        {/* <img src={
                            this.props.gestionnairecentre.photo[0] == null ? "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" : this.props.gestionnairecentre.photo[0].url
                        } alt="User" /> */}
                        <img src={
                            "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                        } alt="User" />
                    </Link>
                    <div className="profile-det-info">
                        <h3>{this.props.gestionnairecentre.nom + " " + this.props.gestionnairecentre.prenom}</h3>

                        <div className="patient-details">
                            <h5 className="mb-0">BDS, MDS - Oral & Maxillofacial Surgery</h5>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default GestionnaireSidebar;
