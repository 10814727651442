import React, { Component } from "react";

import { Modal } from "react-bootstrap";
import { MDBDataTableV5, MDBTable } from "mdbreact";
import numStr from '../../patients/separator/separator';



import DoctorSidebar from "../sidebar";
import StickyBox from "react-sticky-box";
import axios from "axios";
import host from "../../../../host";
import Loader from "react-loader-spinner";
const ModalAntd = require("antd").Modal;

class MesPrestations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      professionnel: { specialite: [], prestation: [], centres: [] },
      prestationsAll: [],
      aPrestations: [],
      adresseCentre: "",
      patient: [],
      urlPhoto: "",
      isLoading: false,
      isDelete: false,
      idCentre: 0,

      itemDelete: null,
      nom: null,
      prixEuro: null,
      prixXAF: null,
      modalite: "physique",
      prestaModifier: null,
      msgErreur: "",
      isMsg: false,

      prestation: "Toutes les prestations",
      centre: "Tous les centres",
      centreEntier: 0,
      prestaEntier: 0,
      centreAll: [],
      datatable: {
        columns: [
          {
            label: "Désignation",
            field: "designation",
            //   sort: 'asc',
            width: 270,
          },
          {
            label: "Prix En euro",
            field: "prixEuro",
            //   sort: 'asc',
            width: 200,
          },
          {
            label: "Prix En XAF",
            field: "prixXaf",
            //   sort: 'asc',
            width: 200,
          },
          {
            label: "Modalité",
            field: "modalite",
            // sort: 'asc',
            width: 150,
          },
          {
            label: "Centre",
            field: "centre",
            // sort: 'asc',
            width: 150,
          },
          {
            label: "Action",
            field: "action",
            // sort: 'asc',
            width: 150,
          }
        ],
        rows:[],     
      },
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    let rowElement = [];
    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const data = dataJson && JSON.parse(dataJson);
    // console.log(data.user.id, "id");

    //const idProfessionnel =  data.user.profesionnel_de_sante.id;
    //console.log(idProfessionnel, "lombre");

    // let pfSanteResponse = await
    //     fetch(`https://kwaber-sante.herokuapp.com/users/${data.user.id}`)
    let pfSanteResponse =
      await // fetch(`https://kwaber-sante.herokuapp.com/users/${data.user.id}`)
      fetch(host + `/ps/${data.user.professionnelsante.idprofessionnelsante}`);
    if (pfSanteResponse.status == 200) {
      let data = await pfSanteResponse.json();
     
      for (let index = 0; index < data.prestation.length; index++) {
        const element = data.prestation[index];
        console.log("LOMBRE: ", element)
        rowElement.push({
          designation: element.libelle,
          prixEuro: element.pEUR,
          prixXaf: numStr(element.pXAF,'.'),
          modalite: element.modalite,
          centre: element.idcentre2 ? element.idcentre2.libelle : "----",
          action: <>
                                 <button
                                          className="btn btn-sm bg-info-light p-2 mr-2"
                                          onClick={this.modifierPrestation.bind(
                                            this,
                                            element,
                                            index
                                          )}
                                        >
                                            <i className="fe fe-pencil"></i> Modifier
                                        </button>

                                        <button
                                          className="btn bg-danger-light trash p-2"
                                          onClick={() =>
                                            this.showDelete(element)
                                          }
                                        >
                                          <i className="fe fe-trash"></i> Supprimer
                                        </button>
          </>
        }) 
        
      }

      this.setState({
        professionnel: data,
        // urlPhoto: data.photo[0].url,
        isLoading: false,
        datatable: {
          columns: this.state.datatable.columns,
          rows: rowElement
        }
        // adresseCentre: data.centres[0].adresse == null && "",
      });
    }
    // let prestaResponse = await
    //     fetch(`https://kwaber-sante.herokuapp.com/prestations`)

    // if (prestaResponse.status == 200) {
    //     let data1 = await prestaResponse.json()
    //     this.setState({
    //         prestationsAll: data1
    //     });
    // }

    let centreResponse = await fetch(host + `/centres/`);

    if (centreResponse.status == 200) {
      let centre = await centreResponse.json();
      this.setState({
        centreAll: centre,
      });
    }
  }

  afficherPrix(presta, index, input) {
    if (input == "euro") {
      for (let i = 0; i < this.state.professionnel.prestation.length; i++) {
        if (presta.id == this.state.professionnel.prestation[i].id) {
          return this.state.professionnel.prestation[i].pEUR + " €";
        }
      }
      // for (let i = 0; i < this.state.prestationsAll.length; i++) {
      //     if (presta.id == this.state.prestationsAll[i].id) {
      //         return this.state.prestationsAll[i].professionel_sante_prestations[0].prixEuro + " €"
      //     }
      // }
    } else {
      for (let i = 0; i < this.state.professionnel.prestation.length; i++) {
        if (presta.id == this.state.professionnel.prestation[i].id) {
          let cmp = this.sepMillier(
            this.state.professionnel.prestation[i].pXAF
          );
          return cmp + " XAF";
        }
      }
      // for (let i = 0; i < this.state.prestationsAll.length; i++) {
      //     if (presta.id == this.state.prestationsAll[i].id) {
      //         let cmp = this.sepMillier(this.state.prestationsAll[i].professionel_sante_prestations[0].prixXAF)
      //         return cmp + " XAF"
      //     }
      // }
    }
  }

  openModal = (id) => {
    this.setState({ activeModal: id }, () => {
      // console.log(this.state.activeModal, "value");
    });
  };

  handleCloseModal = () => {
    this.setState({ activeModal: false }, () => {
      // console.log(this.state.activeModal, "state");
    });
    this.setState({
      nom: null,
      prixEuro: null,
      prixXAF: null,
      modalite: "physique",
      prestaModifier: null,
    });
  };

  supprimerPrestation(presta) {
    this.setState({
      isLoading: true,
    });
    for (let i = 0; i < this.state.professionnel.prestation.length; i++) {
      if (presta.id == this.state.professionnel.prestation[i].id) {
        axios
          .delete(
            host +
              `/prestations/delete/${this.state.professionnel.prestation[i].id}`
          )
          .then((res) => {
            // console.log(res.status, "supprimer presta");
            // this.componentDidMount()
            window.location.reload();
            this.handleCancelDelete();
          })
          .then(() => {
            this.setState({
              isLoading: false,
            });
          });

        break;
      }
    }
  }

  clean() {
    this.setState({
      nom: [""],
      quantite: [0],
      prixEuro: [0],
      prixXAF: [0],
      tabAffichage: [1],
    });
  }

  ajoutElement() {
    let tab = this.state.tabAffichage;
    tab.push(tab[tab.length - 1] + 1);

    let tabNom = this.state.nom;
    let tabQ = this.state.quantite;
    let tabE = this.state.prixEuro;
    let tabX = this.state.prixXAF;
    let tabM = this.state.modalite;

    tabNom.push("");
    tabQ.push(0);
    tabE.push(0);
    tabX.push(0);
    tabM.push("");

    this.setState({
      tabAffichage: tab,
      nom: tabNom,
      quantite: tabQ,
      prixEuro: tabE,
      prixXAF: tabX,
      modalite: tabM,
    });
  }

  supprimerElement(index) {
    if (this.state.tabAffichage.length > 1) {
      let tableau = this.state.tabAffichage;
      tableau.splice(index - 1, 1);

      let tabNom = this.state.nom;
      let tabQ = this.state.quantite;
      let tabE = this.state.prixEuro;
      let tabX = this.state.prixXAF;
      let tabM = this.state.modalite;

      for (let i = index; i < tabNom.length; i++) {
        tabNom[i] = tabNom[i + 1];
        tabQ[i] = tabQ[i + 1];
        tabE[i] = tabE[i + 1];
        tabX[i] = tabX[i + 1];
        tabM[i] = tabM[i + 1];
      }
      tabNom.pop(); // supression du dernier élément
      tabQ.pop();
      tabE.pop();
      tabX.pop();
      tabM.pop();

      this.setState({
        tabAffichage: tableau,
        nom: tabNom,
        quantite: tabQ,
        prixEuro: tabE,
        prixXAF: tabX,
        modalite: tabM,
      });
      //console.log("Nom : ", this.state.nom, "; Quantite : ", this.state.quantite, "; PrixEuro : ", this.state.prixEuro, "; Prix XAF : ", this.state.prixXAF)
      // console.log("--------------------")
      // console.log("Matin : ",this.state.matin,"; Aprem : ",this.state.aprem,"; Soir : ",this.state.soir,"; Nuit : ",this.state.nuit)
    }
  }

  creerPresta = async () => {
    this.setState({
      isLoading: true,
    });
    let prestationCreer = "";

      try {
        const response = await axios({
          method: "post",
          url: host + "/prestations/create",
          data: {
            professionnelsante: this.state.professionnel.idprofessionnelsante,
            libelle: this.state.nom,
            Slug: this.state.nom,
            modalite: this.state.modalite,
            prixXAF: Math.round(this.state.prixXAF * 100) / 100,
            prixEuro: Math.round(this.state.prixEuro * 100) / 100,
            idcentre: this.state.idCentre,
          },
        }).then(function (response) {
          //On traite la suite une fois la réponse obtenue
          // this.setState({
          //     idcentre: 0
          // })
          // console.log(response);
          window.location.reload();
          prestationCreer = response.data;
          // console.log(prestationCreer, "presta creer");
        });
      } catch (err) {
        // Handle Error Here
        // console.error(err);
      }
      this.setState({
        isLoading: false,
      });
      return prestationCreer;
    }



    
   

  async modif(prestaCREER) {
    this.setState({
      isLoading: true,
    });
    let tabPresta = this.state.professionnel.prestations;
    tabPresta.push(prestaCREER);

    axios
      .put(
        `https://kwaber-sante.herokuapp.com/users/${this.state.professionnel.id}`,
        {
          prestations: tabPresta,
        }
      )
      .then((response) => {
        // console.log(response, "reponse prof modifier");
        this.componentDidMount();
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleChange(e, input) {
    if (input == "nom") {
      let nom = this.state.nom;
      nom = e.target.value;
      this.setState({ nom: nom });
    }
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '' || e.target.value === '-') {
      if (input == "euro") {
        let prixE = this.state.prixEuro;
        prixE = e.target.value;
        let prixX = this.state.prixXAF;
        prixX = Number(prixE) * 680.8;
        this.setState({ prixEuro: prixE, prixXAF: prixX });
        // console.log(Number(prixE), " prix euro");
      }
      if (input == "xaf") {
        let prixX = this.state.prixXAF;
        prixX = e.target.value;
        let prixE = this.state.prixEuro;
        prixE = Number(prixX) / 680.8;
        // console.log(prixE);
        this.setState({ prixXAF: prixX, prixEuro: prixE });
      }
    }
    if (input == "modalite") {
      let modalite = this.state.modalite;
      modalite = e.target.value;
      this.setState({ modalite: modalite });
    }
  }

  modifierPrestation(presta, index) {
    // console.log(presta, index);
    let centre = 0;
    let centreEntier = 0;
    if (presta.centre == 0) {
      centre = "tous les centres";
    } else {
      for (let i = 0; i < this.state.centreAll.length; i++) {
        if (this.state.centreAll[i].id == presta.centre) {
          centre = this.state.centreAll[i];
          centreEntier = this.state.centreAll[i];
        }
      }
    }

    for (let i = 0; i < this.state.professionnel.prestation.length; i++) {
      if (presta.id == this.state.professionnel.prestation[i].id) {
        this.setState({
          nom: presta.libelle,
          prixEuro: this.state.professionnel.prestation[i].pEUR,
          prixXAF: this.state.professionnel.prestation[i].pXAF,
          modalite: presta.modalite,
          prestaModifier: this.state.professionnel.prestation[i],
          centre: centre.libelle,
          centreEntier: centreEntier,
        });
        this.openModal("modifier");
      }
    }
  }

  modifierPrestationRequete() {
    // console.log(this.state.prestaModifier, "modifier");
    let prestationID = this.state.prestaModifier.id;

    axios
      .put(host + `/prestations/update/${prestationID}`, {
        idprofessionnelsante: this.state.professionnel.idprofessionnelsante,
        libelle: this.state.nom,
        modalite: this.state.modalite,
        prixXAF: Math.round(this.state.prixXAF * 100) / 100,
        prixEuro: Math.round(this.state.prixEuro * 100) / 100,
        idcentre: this.state.idCentre,
      })
      .then((response) => {
        this.componentDidMount();
        this.setState({
          idcentre: 0,
        });
        // console.log(response.status, "reponse prestation modifier");
        // window.location.reload();
      });

    this.handleCloseModal();
  }

  sepMillier(a, b) {
    var str = a.toString();

    a = str.split(".")[0];
    a = "" + a;
    b = b || " ";
    var c = "",
      d = 0;
    while (a.match(/^0[0-9]/)) {
      a = a.substr(1);
    }
    for (var i = a.length - 1; i >= 0; i--) {
      c = d != 0 && d % 3 == 0 ? a[i] + b + c : a[i] + c;
      d++;
    }
    if (str.split(".").length == 1) {
      return c;
    } else {
      if (str.split(".").length == 2) {
        return c + "." + str.split(".")[1];
      } else {
        return c + "." + str.split(".")[1] + str.split(".")[2];
      }
    }
  }

  async handleChange2(e, input) {
    let presta = 0;
    let centre = 0;
    // console.log(e.target.value, "value");
    if (input == "presta") {
      if (e.target.value !== "Toutes les prestations") {
        let prestaResponse = await fetch(
          `https://kwaber-sante.herokuapp.com/prestations/${e.target.value}`
        );

        if (prestaResponse.status == 200) {
          let data = await prestaResponse.json();
          presta = data;
        }
        this.setState({
          prestation: presta.libelle,
          prestaEntier: presta,
        });
      } else {
        this.setState({
          prestation: "Toutes les prestations",
          prestaEntier: 0,
        });
      }
    }
    if (input == "centre") {
      if (e.target.value !== "Tous les centres") {
        let centreResponse = await fetch(host + `/centres/${e.target.value}`);

        if (centreResponse.status == 200) {
          let data = await centreResponse.json();
          centre = data;
        }
        this.setState({
          centre: centre.libelle,
          centreEntier: centre.idcentre,
        });
      } else {
        this.setState({
          centreEntier: 0,
        });
      }
    }
  }

  afficherCentre(id) {
    // console.log(id, "id");
    if (id == 0) {
      return "Tous les centres";
    } else {
      for (let i = 0; i < this.state.centreAll.length; i++) {
        // console.log(this.state.centreAll[i].id, "centre id");
        if (this.state.centreAll[i].id == id) {
          // console.log(this.state.centreAll[i].id);
          return this.state.centreAll[i].libelle;
        }
      }
    }
  }

  async submit() {
    // let prestaCREER = await this.creerPresta();
    // await this.modif(prestaCREER)
    // this.handleCloseModal();
    // if(this.state.modalite == "video"){
    //   this.setState({
    //     isMsg: false,
    //     msgErreur: ""
    //   }) 
    // }
     if(this.state.modalite == "physique" &&  !this.state.idCentre){
      this.setState({
        isMsg: true,
        msgErreur: "Veuillez choisir un centre pour tout prestation physique"
      })  
    }else{
      this.setState({
        isMsg: false,
        msgErreur: ""
      }) 
      this.creerPresta();
      this.handleCloseModal();
    }
  }

  showDelete = (item) => {
    this.setState({
      isDelete: true,
      itemDelete: item,
    });
  };

  handleCancelDelete = () => {
    this.setState({
      isDelete: false,
      itemDelete: null,
    });
  };

  handleChangeCentre = (e) => {
    this.setState({
      idCentre: e.target.value,
    });
  };

  render() {
    const {professionnel} = this.state;
    const prestations = professionnel ? professionnel : null;
    console.log("professionnelLombre: ",prestations)
    return (
      <div>
        {/* Delete Modal */}
        <ModalAntd
          title="Suppression d'une prestation"
          visible={this.state.isDelete}
          okText="Confirmer"
          cancelText="Annuler"
          okType="danger"
          onOk={
            this.state.isLoading
              ? null
              : this.supprimerPrestation.bind(this, this.state.itemDelete)
          }
          onCancel={this.state.isLoading ? null : this.handleCancelDelete}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <div className="form-content p-2">
            <h4 className="modal-title">Suppression d'une prestation</h4>
            <Loader
              style={{
                position: "absolute",
                textAlign: "center",
                marginLeft: "auto",
                left: "0",
                right: "0",
                marginRight: "auto",
                zIndex: "20px",
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            />

            <p className="mb-4">Confimer vous la suppression</p>
          </div>
        </ModalAntd>

        {/* Delete Modal */}
        {/* <div className="breadcrumb-bar">
                                    <div className="container-fluid">
                                        <div className="row align-items-center">
                                            <div className="col-md-12 col-12">
                                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item"><a href="/home">Accueil</a></li>
                                                        <li className="breadcrumb-item active" aria-current="page">Mes Prestations</li>
                                                    </ol>
                                                </nav>
                                                <h2 className="breadcrumb-title">Mes Prestations</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-3 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <DoctorSidebar />
                </StickyBox>
              </div>

              <div className="col-md-6 col-lg-9 col-xl-9">
                <div
                  className={
                    this.state.isLoading ? "page_wrapper_blur card" : "card"
                  }
                >
                  <div className="card-body">
                    <div className="card card-table">
                      <div className="card-body">
                        {
                        //   this.state.professionnel.prestation.length ===
                        //   0 ?
                        //   <div class="jumbotron jumbotron-fluid">
                        //   <div class="container">
                        //     <h1 class="display-4">Mes Prestations</h1>
                        //     <p class="lead">
                        //       {" "}
                        //       Vous n'avez pas encore de préstation.
                        //     </p>
                        //   </div>
                        // </div>
                        //  : 
                          <MDBTable responsive>
                         <MDBDataTableV5
                                 //   La table des prestations with mdbreact 
                                             hover
                                             searchTop
                                             searchBottom={false}
                                             entriesLabel={"Lignes par pages"}
                                             searchLabel={"Rechercher"}
                                             infoLabel={["", "-", "/", ""]}
                                             noRecordsFoundLabel={
                                               "Aucune donnée ne correspond"
                                             }
                                             entriesOptions={[5, 20, 25]}
                                             entries={5}
                                             pagesAmount={4}
                                             data={this.state.datatable}
                                             
                                         />
                         </MDBTable>
                        }
                     
                      
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="submit-section">
                          <button
                            type="submit"
                            className="btn btn-bluefonce submit-btn"
                            onClick={this.openModal.bind(this, "add")}
                          >
                            Ajouter une Prestation
                          </button>
                          &nbsp;&nbsp;
                          {/* <button type="reset" className="btn btn-dark" onClick={this.supprimerPrestation.bind(this)}>Effacer</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.activeModal === "add"} size="xl" centered>
          <Modal.Header>
            <h5 className="modal-title">Ajouter une Prestation</h5>
          </Modal.Header>
          <Modal.Body>
            <form
              className={
                this.state.isLoading ? "page_wrapper_blur form" : "form"
              }
            >
              <div style={{ display: "flex" }}>
                <div
                  className="form-group"
                  style={{ marginLeft: "2%", width: "100%" }}
                >
                  <label htmlFor="dateDebut">&nbsp;Nom</label>
                  <input
                    disabled={this.state.isLoading}
                    className="form-control"
                    type="text"
                    value={this.state.nom}
                    onChange={(e) => this.handleChange(e, "nom")}
                  />
                </div>
                <div
                  className="form-group"
                  style={{ marginLeft: "2%", width: "100%", float: "ri" }}
                >
                  <label htmlFor="heureFin">&nbsp;Modalité</label>
                  <select
                    disabled={this.state.isLoading}
                    id="lang"
                    onChange={(e) => this.handleChange(e, "modalite")}
                    value={this.state.modalite}
                    className="form-control"
                  >
                    <option value="physique">physique</option>
                    <option value="video">video</option>
                  </select>
                </div>
              </div>
              <Loader
                style={{
                  position: "absolute",
                  textAlign: "center",
                  marginLeft: "auto",
                  left: "0",
                  right: "0",
                  marginRight: "auto",
                  zIndex: "20px",
                }}
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                visible={this.state.isLoading}
              />

              <div style={{ display: "flex" }}>
                <div
                  className="form-group"
                  style={{ marginLeft: "2%", width: "100%" }}
                >
                  <label htmlFor="heureDebut">&nbsp;Prix en XAF</label>
                  <input
                    disabled={this.state.isLoading}
                    className="form-control"
                    type="text"
                    value={Math.round(this.state.prixXAF * 100) / 100}
                    onChange={(e) => this.handleChange(e, "xaf")}
                  />
                </div>

                <div
                  className="form-group"
                  style={{ marginLeft: "2%", width: "100%" }}
                >
                  <label htmlFor="dateFin">&nbsp;Prix en Euro</label>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={Math.round(this.state.prixEuro * 100) / 100}
                    onChange={(e) => this.handleChange(e, "euro")}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                {/* <div className="form-group" style={{ marginLeft: "2%", width: "100%" }}>
                                                                                <di>Choisir la prestation pour ce créneau</di>
                                                                            </div> */}

                <div
                  className="form-group"
                  style={{ marginLeft: "2%", width: "50%" }}
                >
                  <div>Choisir un centre</div>
                  {/* <select 
                    disabled={this.state.isLoading} id="lang" onChange={e => this.handleChange2(e, "centre")} value={this.state.prestation.libelle} className="form-control">
                                        <option value="Toutes les prestations">Tous les centres</option>
                                        {this.state.professionnel.centres.map(centre => (
                                            <option value={centre.id}>
                                                {centre.libelle}
                                            </option>
                                        ))}
                                    </select> */}
                  <select
                    disabled={this.state.isLoading}
                    onChange={this.handleChangeCentre}
                    value={this.state.idCentre}
                    className="form-control"
                  >
                    <option value="0">Sélectionner un centre</option>
                    {this.state.professionnel.centres.map((centre) => (
                      <option value={centre.idcentre}>{centre.libelle}</option>
                    ))}
                  </select>
                  { this.state.isMsg ? <em className="etoile">{this.state.msgErreur}</em> : "" }
                </div>
              </div>
              {this.state.messageError !== undefined ? (
                <label style={{ color: "red" }}>
                  {this.state.messageError}
                </label>
              ) : (
                ""
              )}
              <div className="submit-section text-center">
                <button
                  type="button"
                  className="btn btn-bluefonce submit-btn"
                  onClick={this.state.isLoading ? null : this.submit.bind(this)}
                >
                  Ajouter
                </button>
                <button
                  type="button"
                  className="btn btn-danger submit-btn"
                  onClick={this.state.isLoading ? null : this.handleCloseModal}
                >
                  Annuler
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.activeModal === "modifier"} size="xl" centered>
          <Modal.Header>
            <h5 className="modal-title">Modifier une Prestation</h5>
          </Modal.Header>
          <Modal.Body>
            <form
              className={
                this.state.isLoading ? "page_wrapper_blur form" : "form"
              }
            >
              <div style={{ display: "flex" }}>
                <div
                  className="form-group"
                  style={{ marginLeft: "2%", width: "100%" }}
                >
                  <label htmlFor="dateDebut">&nbsp;Nom</label>
                  <input
                  required="required"
                    disabled={this.state.isLoading}
                    className="form-control"
                    type="text"
                    value={this.state.nom}
                    onChange={(e) => this.handleChange(e, "nom")}
                  />
                </div>
                <div
                  className="form-group"
                  style={{ marginLeft: "2%", width: "100%", float: "ri" }}
                >
                  <label htmlFor="heureFin">&nbsp;Modalité</label>
                  <select
                    disabled={this.state.isLoading}
                    id="lang"
                    onChange={(e) => this.handleChange(e, "modalite")}
                    value={this.state.modalite}
                    className="form-control"
                  >
                    <option value="physique">physique</option>
                    <option value="video">video</option>
                  </select>
                </div>
              </div>

              <Loader
                style={{
                  position: "absolute",
                  textAlign: "center",
                  marginLeft: "auto",
                  left: "0",
                  right: "0",
                  marginRight: "auto",
                  zIndex: "20px",
                }}
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                visible={this.state.isLoading}
              />

              <div style={{ display: "flex" }}>
                <div
                  className="form-group"
                  style={{ marginLeft: "2%", width: "100%" }}
                >
                  <label htmlFor="heureDebut">&nbsp;Prix en XAF</label>
                  <input
                    disabled={this.state.isLoading}
                    className="form-control"
                    type="text"
                    value={Math.round(this.state.prixXAF * 100) / 100}
                    onChange={(e) => this.handleChange(e, "xaf")}
                  />
                </div>
                <div
                  className="form-group"
                  style={{ marginLeft: "2%", width: "100%" }}
                >
                  <label htmlFor="dateFin">&nbsp;Prix en Euro</label>
                  <input
                    disabled
                    className="form-control"
                    type="text"
                    value={Math.round(this.state.prixEuro * 100) / 100}
                    onChange={(e) => this.handleChange(e, "euro")}
                  />
                </div>
              </div>
              <div style={{ display: "flex" }}>
                {/* <div className="form-group" style={{ marginLeft: "2%", width: "100%" }}>
                                                                                <di>Choisir la prestation pour ce créneau</di>
                                                                            </div> */}

                <div
                  className="form-group"
                  style={{ marginLeft: "2%", width: "50%" }}
                >
                  <div>Choisir un centre</div>
                  {/* <select 
                    disabled={this.state.isLoading} id="lang" onChange={e => this.handleChange2(e, "centre")} value={this.state.prestation.libelle} className="form-control">
                                        <option value="Toutes les prestations">Tous les centres</option>
                                        {this.state.professionnel.centres.map(centre => (
                                            <option value={centre.idcentre}>
                                                {centre.libelle}
                                            </option>
                                        ))}
                                    </select> */}
                  <select
                    disabled={this.state.isLoading}
                    onChange={this.handleChangeCentre}
                    value={this.state.idCentre}
                    className="form-control"
                  >
                    <option value={0}>Sélectionner un centre</option>
                    {this.state.professionnel.centres.map((centre) => (
                      <option value={centre.idcentre}>{centre.libelle}</option>
                    ))}
                  </select>
                </div>
              </div>
              {this.state.messageError !== undefined ? (
                <label style={{ color: "red" }}>
                  {this.state.messageError}
                </label>
              ) : (
                ""
              )}
              <div className="submit-section text-center">
                <button
                  type="button"
                  className="btn btn-bluefonce submit-btn"
                  onClick={
                    this.state.isLoading
                      ? null
                      : this.modifierPrestationRequete.bind(this)
                  }
                >
                  Modifier
                </button>
                <button
                  type="button"
                  className="btn btn-danger submit-btn"
                  onClick={this.state.isLoading ? null : this.handleCloseModal}
                >
                  Annuler
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default MesPrestations;
