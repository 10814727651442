import React, { Component } from "react";
import GestionnaireSidebar from "../sidebar";
// import { Link } from "react-router-dom";
// import { Tab, Tabs } from "react-bootstrap";
// import ProgressBar from "react-customizable-progressbar";
import StickyBox from "react-sticky-box";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Collapse, Image, Button, Input } from "antd";
import Loader from "react-loader-spinner";
import host from "../../../../host";
import SignaturePad from "react-signature-canvas";
import IMG01 from "./logosignature.jpg";
import { MDBDataTableV5 } from "mdbreact";
// import styles from './styles.module.css'
// const { Panel } = Collapse;

class ProfileDoctor extends Component {
  sigPad = {};
  constructor(props) {
    super(props);

    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const token = dataJson && JSON.parse(dataJson).token;

    this.state = {
      trimmedDataURL: null,
      pick: false,
      idpro:"",
      auth: token,
      photo: null,
      isAddDiplome: false,
      isAddDistinction: false,
      isModifDiplome: false,
      libelle: "",
      etablissement: "",
      annee: "",
      professionnel: {
        nom: null,
        prenom: null,
        email: null,
        password: null,
        sexe: null,
        numtelephone: null,
        biographie: null,
        ville: null,
        specialite: null,
      },

      nom: "",
      prenom: "",
      email: "",
      password: "",
      sexe: "",
      number: "",
      biographie: "",
      idspecialite: "",
      idville: "",
      villes: [],
      specialites: [],
      idDiplome:'',
      idDistinction:'',

      isVisible: false,
      padVisibility: false,
      isUpdate: false,
      isLoading: false,
      columnsDiplomes: [
        {
          label: "Etablissement",
          field: "etablissement",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "nom",
          },
        },
        {
          label: "Diplome",
          field: "libelle",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "nom",
          },
        },
        {
          label: "Année",
          field: "annee",
          width: 100,
        },
        {
          label: "Actions",
          field: "action",
          width: 100,
        },
      ],
      columnsDistinctions: [
        {
          label: "Distinction",
          field: "libelle",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "nom",
          },
        },
        {
          label: "Année",
          field: "annee",
          width: 100,
        },
        {
          label: "Actions",
          field: "action",
          width: 100,
        },
      ],
      diplomes: [],
      distinctions: [],
    };
  }

  showDelete = (item) => {
    // alert(item)
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    };
    
    fetch(host+`/diplome/delete/${item} `, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.listDiplome();
        this.setState({
          isDelete: true,
          // diplomes: result,
        });
      })
      .catch(error => console.log('error', error));
  };
  DeleteDistinction = (id) => {
     var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    };
    
    fetch(host+`/distinctions/delete/${id} `, requestOptions)
      .then(response => response.json())
      .then(result =>{
         console.log(result)
         this.listDistinction();
        this.setState({
          isDelete: true,
          // diplomes: result,
        });
      })
      .catch(error => console.log('error', error));
  };
  // showUpdate = (id) => {

  // }

  showUpdate = (item) => {
      this.setState({
        isModifDiplome: true,
        idDiplome:item.iddiplome,
        // etablissement: item.etablissement,
        etablissement: item.etablissement,
        annee: item.annee,
        libelle: item.libelle 
      })
     this.showDiplome()
  };

  showUpdateDistinction = (item) => {
    //  alert("UP_DIST: ", item.iddistrinction);
     console.log("UP_DIST: ", item.iddistrinction);
    this.setState({
      isModifDiplome: true,
      idDistinction: item.iddistrinction,
      libelle: item.libelle,
      annee: item.annee
    })
    this.showDistinction()
  }

  listDiplome = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch(host+"/ps/"+this.state.idpro, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log( "DIPLOMEs",result)
        if (result.diplome != null) {
          for (let index = 0; index < result.diplome.length; index++) {
            result.diplome[index]["action"] = (
              <div className="actions">
                <a
                  href="#0"
                  className="btn btn-sm bg-success-light"
                  onClick={() => this.showUpdate(result.diplome[index])}
                >
                  <i className="fe fe-pencil"></i> Modifier
                </a>
                <a
                  className="btn btn-sm bg-danger-light"
                  onClick={() => this.showDelete(result.diplome[index].iddiplome)}
                >
                  <i className="fe fe-trash"></i> Supprimer
                </a>
              </div>
            );
          }
          this.setState({
            rows: result.diplome,
          });
        }
        this.setState({
          diplomes: result.diplome
        })
      })
      .catch(error => console.log('error', error));
  }
  listDistinction = () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch(host+`/ps/${this.state.idpro} `, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("DIstinction",result.distinctions)
        if (result.distinctions != null) {
          for (let index = 0; index < result.distinctions.length; index++) {
            result.distinctions[index]["action"] = (
              <div className="actions">
                <a
                  href="#0"
                  className="btn btn-sm bg-success-light"
                  onClick={() => this.showUpdateDistinction( result.distinctions[index])}
                >
                  <i className="fe fe-pencil"></i> Modifier
                </a>
                <a
                  className="btn btn-sm bg-danger-light"
                  onClick={() => this.DeleteDistinction( result.distinctions[index].iddistrinction)}
                >
                   <i className="fe fe-trash"></i> Supprimer
                 </a>
               </div>
            );
          }
        //   this.setState({
        //     rows: result,
        //   });
        }
        this.setState({
          distinctions: result.distinctions
        })
      
      })
      .catch(error => console.log('error', error));
    
    
  }


  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const data = dataJson && JSON.parse(dataJson);
    if(!data){
      window. window.location.assign('/');
    }

        this.setState({
          idpro: data.user.professionnelsante.idprofessionnelsante
        })
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    // debut----------------------recuperation du professionnel
    await fetch(
      host + `/ps/${data.user.professionnelsante.idprofessionnelsante}`,
      requestOptions
    )
      .then((response) => {
        if (response.status == 401) {
          sessionStorage.removeItem("user");
          this.props.history.push("/login");
          return null;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        // console.log(result);
        if (result == null) {
        } else {
          this.setState({
            professionnel: result,
          });
          if (data.user.photo !== result.user.photo) {
            data.user.photo = result.user.photo;
            sessionStorage.setItem("user", JSON.stringify(data));
            window.location.reload();
          }
        }
      })
      .catch((error) => {
        // console.log("error", error);
      });
    // ---------------------------fin

    // debut----------------------recuperation des villes
    await fetch(host + "/ville/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          sessionStorage.removeItem("user");
          this.props.history.push("/login");
          return null;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        // console.log(result);
        if (result != null) {
          this.setState({
            villes: result,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error)
      });
    // ---------------------------fin

    // debut----------------------recuperation des specialites
    await fetch(host + "/specialite/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          sessionStorage.removeItem("user");
          this.props.history.push("/login");
          return null;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        // console.log(result);
        if (result != null) {
          this.setState({
            specialites: result,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error)
      });
    // ---------------------------fin
    this.setState({
      isLoading: false,
    });
     this.listDiplome();
     this.listDistinction();
  }

  handleChange = (e, tag) => {
    this.state[tag] = e.target.value;
    this.setState({});
  };

  handleUpdate = () => {
    this.setState({
      isUpdate: true,
      isVisible: true,
      nom: this.state.professionnel.nom,
      prenom: this.state.professionnel.prenom,
      email: this.state.professionnel.email,
      password: this.state.professionnel.password,
      sexe: this.state.professionnel.sexe,
      number: this.state.professionnel.numtelephone,
      biographie: this.state.professionnel.biographie,
      idspecialite:
        this.state.professionnel.specialite != null
          ? this.state.professionnel.specialite.idspecialite
          : "",
      idville:
        this.state.professionnel.ville != null
          ? this.state.professionnel.ville.idville
          : "",
    });
  };

  handleCancel = () => {
    this.setState({
      nom: "",
      prenom: "",
      email: "",
      password: "",
      sexe: "",
      number: "",
      biographie: "",
      idspecialite: "",
      idville: "",
      isVisible: false,
      isUpdate: false,
      isLoading: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (true) {
      if (this.state.isUpdate) {
        this.setState({
          isLoading: true,
        });
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("auth", this.state.auth);
        let ville, specialite;
        for (let i = 0; i < this.state.villes.length; i++) {
          const element = this.state.villes[i];
          if (element.idville == this.state.idville) {
            ville = element;
            break;
          }
        }
        for (let i = 0; i < this.state.specialites.length; i++) {
          const element = this.state.specialites[i];
          if (element.idspecialite == this.state.idspecialite) {
            specialite = element;
            break;
          }
        }

        let raw = JSON.stringify({
          username: this.state.email,
          role: "PS",
          ps: {
            idprofessionnelsante: this.state.professionnel.idprofessionnelsante,
            nom: this.state.nom,
            prenom: this.state.prenom,
            email: this.state.email,
            sexe: this.state.sexe,
            numtelephone: this.state.number,
            biographie: this.state.biographie,
            ville,
            specialite,
            // idville: this.state.idville,
            // idspecialite: this.state.idspecialite,
          },
        });

        let requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          host + `/user/update/${this.state.professionnel.user.id}`,
          requestOptions
        )
          .then((response) => {
            // console.log(response);
            if (response.status == 401) {
              sessionStorage.removeItem("user");
              this.props.history.push("/login");
              return null;
            }
            if (response.status != 200) {
              return null;
            }
            return response.text();
          })
          .then((result) => {
            if (result == null) {
              this.setState({
                isLoading: false,
              });
              toast.error("L'email est deja utilisé");
            } else {
              // console.log("retour", result);
              // console.log("result", JSON.parse(result));
              const data = JSON.parse(result);

              this.state.professionnel.nom = this.state.nom;
              this.state.professionnel.prenom = this.state.prenom;
              this.state.professionnel.email = this.state.email;
              this.state.professionnel.sexe = this.state.sexe;
              this.state.professionnel.numtelephone = this.state.number;
              this.state.professionnel.biographie = this.state.biographie;

              try {
                const dataJson = sessionStorage.getItem("user");
                const data2 = dataJson && JSON.parse(dataJson);
                data2.user.professionnelsante.nom = this.state.nom;
                data2.user.professionnelsante.prenom = this.state.prenom;
                data2.user.professionnelsante.email = this.state.email;
                data2.user.username = this.state.email;
                sessionStorage.setItem("user", JSON.stringify(data2));
              } catch (e) {}

              if (
                this.state.professionnel.ville.idville != this.state.idville
              ) {
                this.state.professionnel.ville = ville;
                // for(let i=0; i < this.state.villes.length; i++){
                //   let ville = this.state.villes[i];
                //   if(ville.idville === this.state.idville){
                //     this.state.professionnel.ville = ville;
                //     break
                //   }

                // }
              }

              if (
                this.state.professionnel.specialite.idspecialite !=
                this.state.idspecialite
              ) {
                this.state.professionnel.specialite = specialite;
                // for(let i=0; i < this.state.specialites.length; i++){
                //   let specialite = this.state.specialites[i];
                //   if(specialite.idspecialite === this.state.idspecialite){
                //     this.state.professionnel.specialite = specialite;
                //     break
                //   }

                // }
              }
              this.setState({ professionnel: { ...this.state.professionnel } });

              this.setState({
                isLoading: false,
              });
              toast.success("Modification réalisé avec succès");
              this.handleCancel();
              // window.location.reload();
            }
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
            toast.error("Une erreure c'est produite");
          });
      }
    }
  };

  showSignature = () => {
    this.setState({
      padVisibility: true,
    });
  };

  cancelSignature = () => {
    this.setState({
      padVisibility: false,
    });
  };

  cancelPick = () => {
    this.setState({
      pick: false,
      photo: null,
    });
  };

  showPick = () => {
    this.setState({
      pick: true,
    });
  };

  nettoyer = () => {
    this.sigPad.clear();
  };

  changeProfileImage = (event) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = () => {
      this.setState({
        photo: reader.result,
      });
    };
  };

  updateImage = () => {
    if (this.state.photo !== null) {

      console.log("IMAGEuPdate:", this.state.photo)

      this.setState({
        isLoading: true,
      });
      // let formdata = new FormData();
      // formdata.append("userImage", this.state.photo);
      // // console.log(this.state.photo);

      // let requestOptions = {
      //   method: "POST",
      //   body: formdata,
      //   redirect: "follow",
      // };
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        data: this.state.photo,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        host + `/user/updateImage/${this.state.professionnel.user.id}`,
        requestOptions
      )
        .then((response) => {
          // console.log(response);
          if (response.status !== 200 && response.status !== 201) {
            return null;
          }
          return response.json();
        })
        .then((result) => {
          if (result !== null) {
            toast.success("insertion réussi");
            window.location.reload();
            this.cancelPick();
          } else {
            toast.error("Une erreur c'est produite");
          }
        })
        .catch((error) => {
          // console.log("error", error);
          toast.error("Une erreur c'est produite");
        })
        .then(() => {
          this.setState({
            isLoading: false,
          });
        });
    } else {
      toast.warning("veuillez selectionner une image");
    }
  };

  dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  saveSignature = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
      isLoading: true,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      data: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      host + "/ps/signature/" + this.state.professionnel.idprofessionnelsante,
      requestOptions
    )
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        // console.log(result)
        if (result !== null) {
          toast.success("Signature mise à jour");

          this.cancelSignature();
        } else {
          toast.error("Une erreur c'est produite");
        }
      })
      .catch((error) => {
        // console.log("error", error)
        toast.error("Une erreur c'est produite");
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });

    // this.setState({
    //   trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
    //   isLoading: true,
    // });
    // var formdata = new FormData();
    // formdata.append(
    //   "signature",
    //   this.dataURLtoFile(
    //     this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
    //     "teste.png"
    //   )
    //   // this.sigPad.getTrimmedCanvas().toDataURL("image/png")
    // );

    // var requestOptions = {
    //   method: "POST",
    //   body: formdata,
    //   redirect: "follow",
    // };

    // fetch(
    //   host + "/ps/signature/" + this.state.professionnel.idprofessionnelsante,
    //   requestOptions
    // )
    //   .then((response) => {
    //     if (response.status !== 200 && response.status !== 201) {
    //       return null;
    //     }
    //     return response.json();
    //   })
    //   .then((result) => {
    //     // console.log(result)
    //     if (result !== null) {
    //       toast.success("Signature mise à jour");

    //       this.cancelSignature();
    //     } else {
    //       toast.error("Une erreur c'est produite");
    //     }
    //   })
    //   .catch((error) => {
    //     // console.log("error", error)
    //     toast.error("Une erreur c'est produite");
    //   })
    //   .then(() => {
    //     this.setState({
    //       isLoading: false,
    //     });
    //   });
  };

  showDiplome = () => {
    this.setState({
      isAddDiplome: true,
    });
  };

  showDistinction = () => {
    this.setState({
      isAddDistinction: true,
    });
  };

  cancelAdd = () => {
    this.setState({
      isAddDiplome: false,
      isAddDistinction: false,
      libelle: "",
      etablissement: "",
      annee: "",
      isLoading: false,
      isModifDiplome: false
    });
  };


 

  addDiplome = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "idprosante": this.state.idpro,
      "libelle": this.state.libelle,
      "etablissement": this.state.etablissement,
      "annee": this.state.annee
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(host+"/diplome/create", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        this.listDiplome();
        this.cancelAdd()
      })
      .catch(error => console.log('error', error));
  };
  updateDiplome = () => {
    // alert(this.state.idDiplome)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "iddiplome": 3,
      "etablissement": this.state.etablissement,
      "libelle": this.state.libelle,
      "annee": this.state.annee
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(host+`/diplome/update/${this.state.idDiplome}`, requestOptions)
      .then(response => response.text())
      .then(result =>{ 
        console.log(result)
        this.listDiplome();
        this.cancelAdd();
      
      })
      .catch(error => console.log('error', error));
      
      
      }

   updateDistinction = () => {
        //  alert(this.state.idDistinction)
        var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "id": this.state.idpro,
      "libelle": this.state.libelle,
      "annee": this.state.annee
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(host+`/distinctions/update/${this.state.idDistinction}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("Modif ", result)
        this.listDistinction();
            this.cancelAdd();

      })
      .catch(error => console.log('error', error));
            this.cancelAdd()
          }

  addDistinction = () => {
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "id": this.state.idpro,
  "libelle": this.state.libelle,
  "annee": this.state.annee
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(host+"/distinctions", requestOptions)
  .then(response => response.json())
  .then(result =>{ 
    console.log(result)
    this.listDistinction();
    this.cancelAdd();
  })
  .catch(error => console.log('error', error));
  };

  render() {
    return (
      <div>
        <ToastContainer />
        <Modal
          title={this.state.isModifDiplome ? "Modifier l'élement " : "Ajouter un Diplôme"}
          visible={this.state.isAddDiplome}
          onCancel={this.state.isModifDiplome ? this.cancelAdd : this.cancelAdd}
          onOk={this.state.isModifDiplome  ? this.updateDiplome  : this.addDiplome}
          cancelText={"Retour"}
          okText={ this.state.isModifDiplome == true ? "Mise à jour" : "Ajouter"}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <Input
            className="form-control"
            type="text"
            placeholder="Etablissement"
            name="etablissement"
            value={this.state.etablissement}
            disabled={this.state.isLoading}
            onChange={(e) => this.handleChange(e, "etablissement")}
            required
          />
          <br/> <br />
          <Input
            type="text"
            placeholder="Diplôme"
            name="diplome"
            // value={this.state.libelle}
            value={this.state.libelle}
            disabled={this.state.isLoading}
            onChange={(e) => this.handleChange(e, "libelle")}
            required
          />
          <br/> <br />
          <Input
            type="number"
            placeholder="Annee"
            name="annee"
            value={this.state.annee}
            disabled={this.state.isLoading}
            onChange={(e) => this.handleChange(e, "annee")}
            required
          />
        </Modal>

        <Modal
          title={this.state.isModifDiplome ? "Modifier l'élement"  : "Ajouter une distinction"}
          visible={this.state.isAddDistinction}
          // onCancel={this.state.isLoading ? null : this.cancelAdd}
          onCancel={this.state.isModifDiplome  ? this.cancelAdd : this.cancelAdd}
          onOk={this.state.isModifDiplome?  this.updateDistinction : this.addDistinction}
          cancelText={"Retour"}
          okText={this.state.isModifDiplome ? "Mise à jour" : "Ajouter"}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <Input
            type="text"
            placeholder="Distinction"
            name="diplome"
            value={this.state.libelle}
            disabled={this.state.isLoading}
            onChange={(e) => this.handleChange(e, "libelle")}
            required
          />
          <br/> <br />
          <Input
            type="number"
            placeholder="Annee"
            name="annee"
            value={this.state.annee}
            disabled={this.state.isLoading}
            onChange={(e) => this.handleChange(e, "annee")}
            required
          />
        </Modal>

        <Modal
          title={"Mettre à jour la photo de profil"}
          visible={this.state.pick}
          onCancel={this.state.isLoading ? null : this.cancelPick}
          onOk={this.state.isLoading ? null : this.updateImage}
          cancelText={"Retour"}
          okText={"Mise à jour"}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <Loader
            style={{
              position: "absolute",
              textAlign: "center",
              marginLeft: "auto",
              left: "0",
              right: "0",
              marginRight: "auto",
              zIndex: "20px",
            }}
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            visible={this.state.isLoading}
          />
          <input
            type="file"
            className="upload"
            name="userImage"
            onChange={this.changeProfileImage}
          />
        </Modal>

        <Modal
          title={"Mettre à jour la signature"}
          visible={this.state.padVisibility}
          onCancel={this.state.isLoading ? null : this.cancelSignature}
          footer={[
            <Button
              key="back"
              onClick={this.state.isLoading ? null : this.cancelSignature}
              disabled={this.state.isLoading}
            >
              Retour
            </Button>,
            <Button
              key="clear"
              type="primary"
              onClick={this.state.isLoading ? null : this.nettoyer}
              disabled={this.state.isLoading}
            >
              Nettoyer
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={this.state.isLoading}
              loading={this.state.isLoading}
              onClick={this.state.isLoading ? null : this.saveSignature}
            >
              Mise à jour
            </Button>,
          ]}
          // cancelText={"Retour"}
          // okText={"Mise à jour"}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <Loader
            style={{
              position: "absolute",
              textAlign: "center",
              marginLeft: "auto",
              left: "0",
              right: "0",
              marginRight: "auto",
              zIndex: "20px",
            }}
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            visible={this.state.isLoading}
          />
          <SignaturePad
            canvasProps={{ style: { width: "100%", height: "100%" } }}
            ref={(ref) => {
              this.sigPad = ref;
            }}
          />
        </Modal>

        <Modal
          title={
            this.state.isUpdate
              ? "Modification du professionnel de sante"
              : "Ajouter un professionnel de sante"
          }
          visible={this.state.isVisible}
          onCancel={this.state.isLoading ? null : this.handleCancel}
          footer={null}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <form action="/" onSubmit={this.handleSubmit} id="addform">
            <div className="row">
              <div className="form-group col-6 ml-6 mr-6">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Nom"
                  name="nom"
                  value={this.state.nom}
                  disabled={this.state.isLoading}
                  onChange={(e) => this.handleChange(e, "nom")}
                  required
                />
              </div>
              <div className="form-group col-6 ml-6 mr-6">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Prenom"
                  name="prenom"
                  value={this.state.prenom}
                  disabled={this.state.isLoading}
                  onChange={(e) => this.handleChange(e, "prenom")}
                  required
                />
              </div>
            </div>
            <div className="row">
              <label
                className="mr-5"
                style={{ verticalAlign: "center", marginLeft: "30px" }}
              >
                <input
                  className="mr-1"
                  type="radio"
                  name="sexe"
                  id="sexe"
                  value="masculin"
                  disabled={this.state.isLoading}
                  onChange={(e) => this.handleChange(e, "sexe")}
                  required
                  checked={this.state.sexe == "masculin" ? true : false}
                />
                <span className="">H</span>
              </label>
              <label className="" style={{ verticalAlign: "center" }}>
                <input
                  className="mr-1"
                  type="radio"
                  name="sexe"
                  id="sexe"
                  value="feminin"
                  disabled={this.state.isLoading}
                  onChange={(e) => this.handleChange(e, "sexe")}
                  required
                  checked={this.state.sexe == "feminin" ? true : false}
                />
                <span className="">F</span>
              </label>
            </div>
            <div className="row">
              <div className="form-group col-6 ml-6 mr-6">
                <label>Ville</label>
                <select
                  className="form-control"
                  type="text"
                  name="ville"
                  value={this.state.idville}
                  disabled={this.state.isLoading}
                  onChange={(e) => this.handleChange(e, "idville")}
                  required
                >
                  <option value="">Selectionner une ville</option>
                  {this.state.villes != null
                    ? this.state.villes.map((ville) => {
                        return (
                          <option value={ville.idville}>{ville.libelle}</option>
                        );
                      })
                    : ""}
                </select>
              </div>
              <div className="form-group col-6 ml-6 mr-6">
                <label>Spécialite</label>
                <select
                  className="form-control"
                  type="text"
                  name="specialite"
                  value={this.state.idspecialite}
                  disabled={this.state.isLoading}
                  onChange={(e) => this.handleChange(e, "idspecialite")}
                  required
                >
                  <option value="">Specialité</option>
                  {this.state.specialites != null
                    ? this.state.specialites.map((specialite) => {
                        return (
                          <option value={specialite.idspecialite}>
                            {specialite.libelle}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                placeholder="Email"
                name="email"
                value={this.state.email}
                disabled={this.state.isLoading}
                onChange={(e) => this.handleChange(e, "email")}
                required
              />
            </div>
            <Loader
              style={{
                position: "absolute",
                textAlign: "center",
                marginLeft: "auto",
                left: "0",
                right: "0",
                marginRight: "auto",
                zIndex: "20px",
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            />
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Numero de telephone"
                name="number"
                value={this.state.number}
                disabled={this.state.isLoading}
                onChange={(e) => this.handleChange(e, "number")}
                required
              />
            </div>
            {this.state.isUpdate ? (
              false
            ) : (
              <div className="form-group">
                <input
                  className="form-control"
                  type="password"
                  placeholder="Mot de passe"
                  name="password"
                  value={this.state.password}
                  disabled={this.state.isLoading}
                  onChange={(e) => this.handleChange(e, "password")}
                  required
                />
              </div>
            )}

            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="biographie"
                name="biographie"
                value={this.state.biographie}
                disabled={this.state.isLoading}
                onChange={(e) => this.handleChange(e, "biographie")}
                required
              />
            </div>
            <div className="form-group mb-0">
              <button
                className="btn btn-primary btn-block"
                type="submit"
                disabled={this.state.isLoading}
              >
                {this.state.isUpdate ? "Modifier" : "Ajouter"}
              </button>
            </div>
          </form>
        </Modal>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-3 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <GestionnaireSidebar />
                </StickyBox>
              </div>
              <div
                className={
                  this.state.isLoading
                    ? "page_wrapper_blur col-md-10 col-lg-10 col-xl-9"
                    : "col-md-10 col-lg-8 col-xl-9"
                }
              >
                <div className="card">
                  <div className="card-body">
                    <div>
                      <div className="card-body">
                        <div className="table">
                          <div className=" card-table pt-5">
                            <div className="row">
                              <div className="col-8">
                                <div className="row">
                                  <p className="col-md-4">
                                    Nom :
                                  </p>
                                  <p className="col-md-8">
                                    {this.state.professionnel.nom}
                                  </p>
                                </div>
                                <div className="row">
                                  <p className="col-md-4">
                                    Prenom :
                                  </p>
                                  <p className="col-md-8">
                                    {this.state.professionnel.prenom}
                                  </p>
                                </div>
                                <div className="row">
                                  <p className="col-md-4">
                                    Sexe :
                                  </p>
                                  <p className="col-md-8">
                                    {this.state.professionnel.sexe}
                                  </p>
                                </div>
                                <div className="row">
                                  <p className="col-md-4">
                                    Email :
                                  </p>
                                  <p className="col-md-8">
                                    {this.state.professionnel.email}
                                  </p>
                                </div>
                                <div className="row">
                                  <p className="col-md-4">
                                    Telephone :
                                  </p>
                                  <p className="col-md-8">
                                    {this.state.professionnel.numtelephone}
                                  </p>
                                </div>

                                <div className="row">
                                  <p className="col-md-4">
                                    Spécialité :
                                  </p>
                                  <p className="col-md-8">
                                    {this.state.professionnel.specialite
                                      ? this.state.professionnel.specialite
                                          .libelle
                                      : "-----"}
                                  </p>
                                </div>
                                <div className="row">
                                  <p className="col-md-4">
                                    Ville :
                                  </p>
                                  <p className="col-md-8">
                                    {this.state.professionnel.ville
                                      ? this.state.professionnel.ville.libelle
                                      : "-----"}
                                  </p>
                                </div>
                                <div className="row">
                                  <p className="col-md-4">
                                    Biogaphie :
                                  </p>
                                  <p className="col-md-8">
                                    {this.state.professionnel.biographie}
                                  </p>
                                </div>
                                <div className="row">
                                  <div className="col-md-6"></div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="submit-section">
                                      <a
                                        className="btn btn-sm bg-success-light"
                                        disabled={this.state.isLoading}
                                        onClick={this.handleUpdate}
                                      >
                                        <i
                                          className="fe fe-pencil"
                                          disabled={this.state.isLoading}
                                        ></i>{" "}
                                        Modifier
                                      </a>
                                      &nbsp;&nbsp;
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-4"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Image
                                    width={100}
                                    src={
                                      this.state.professionnel &&
                                      this.state.professionnel.user &&
                                      this.state.professionnel.user.photo
                                        ? // host +
                                          //   `/user/images/${this.state.professionnel.user.photo}`
                                          this.state.professionnel.user.photo
                                        : // this.state.professionnel.user.photo
                                          "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                                    }
                                  /> <br />
                                  {"   "}
                                  <a
                                    className="btn btn-sm bg-success-light"
                                    disabled={this.state.isLoading}
                                    onClick={this.showPick}
                                  >
                                    <i
                                      className="fe fe-pencil"
                                      disabled={this.state.isLoading}
                                    ></i>{" "}
                                    Modifier
                                  </a>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Image
                                    width={100}
                                    src={
                                      this.state.trimmedDataURL !== null
                                        ? this.state.trimmedDataURL
                                        : this.state.professionnel &&
                                          this.state.professionnel.signature
                                        ? // host +
                                          //   `/ps/signatures/${this.state.professionnel.signature}`
                                          this.state.professionnel.signature
                                        : IMG01
                                    }
                                  />
                                   <em>Votre signature</em>
                                  {"   "}
                                  <a
                                    className="btn btn-sm bg-success-light"
                                    disabled={this.state.isLoading}
                                    onClick={this.showSignature}
                                  >
                                    <i
                                      className="fe fe-pencil"
                                      disabled={this.state.isLoading}
                                    ></i>{" "}
                                    Modifier
                                  </a><br />
                                 
                                </div>
                               
                              </div>
                            </div>
                          </div>
                          <br />
                          <br />
                          <div className="row">
                            <div className="card card-table col-12 p-5">
                              <h3> Vos diplômes</h3>
                              <br />
                              <br />
                              <Button
                                type={"primary"}
                                style={{ width: 150 }}
                                onClick={this.showDiplome}
                              >
                                Ajouter
                              </Button>
                              <br />
                              <MDBDataTableV5
                                entriesOptions={[5, 10]}
                                displayEntries={false}
                                entries={5}
                                responsive={true}
                                entriesLabel={"Lignes par pages"}
                                searchLabel={"Rechercher"}
                                infoLabel={["", "-", "/", ""]}
                                noRecordsFoundLabel={
                                  "Aucune données de correspondant"
                                }
                                data={{
                                  columns: this.state.columnsDiplomes,
                                  rows: this.state.diplomes,
                                }}
                                searchTop
                                searchBottom={false}
                              />
                            </div>

                            <div className="card card-table col-12 p-5">
                              <h3> Vos distinctions</h3>
                              <br />
                              <br />
                              <Button
                                type={"primary"}
                                style={{ width: 150 }}
                                onClick={this.showDistinction}
                              >
                                Ajouter
                              </Button>
                              <br />
                              <MDBDataTableV5
                                entriesOptions={[5, 10]}
                                displayEntries={false}
                                entries={5}
                                responsive={true}
                                entriesLabel={"Lignes par pages"}
                                searchLabel={"Rechercher"}
                                infoLabel={["", "-", "/", ""]}
                                noRecordsFoundLabel={
                                  "Aucune données de correspondant"
                                }
                                data={{
                                  columns: this.state.columnsDistinctions,
                                  rows: this.state.distinctions,
                                }}
                                searchTop
                                searchBottom={false}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Loader
            style={{
              position: "absolute",
              marginTop: "-30%",
              zIndex: 100,
            }}
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            visible={
              this.state.isLoading &&
              !this.state.isVisible &&
              !this.state.padVisibility &&
              !this.state.pick
            }
          />
        </div>
      </div>
    );
  }
}

export default ProfileDoctor;
