import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import StickyBox from "react-sticky-box";
import DashboardSidebar from "./sidebar/sidebar";
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import host from "../../../../host";
import { MDBDataTableV5, MDBTable } from 'mdbreact';
import { Radio } from 'antd';
import { Checkbox } from 'antd';


import moment from "moment";
// import { Table, Input, Space } from 'antd';
// import Highlighter from 'react-highlight-words';
// import { SearchOutlined } from '@ant-design/icons';



class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
    searchedColumn: '',
      key: 1,
      data: [],
      patient: { ordonnances: [], creneaus: [], },
      rdv: [],
      tabAffichage: [],
      check: [true, false, false, false],
      date: 0,
      ordonnance: [],
      tableauOrdonnance:[],
      show: false,
      rdvbyId: null,
      rdvCentre:[],
      columns:  [
        {
          label: 'Centre',
          field: 'centre',
          width: 150,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
          },
        },
        {
          label: 'Prestation',
          field: 'prestation',
          width: 150,
          attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
          },
        },
        {
          label: 'Date du rendez-vous',
          field: 'rdv',
          width: 270,
        },
        {
          label: 'Date du paiement',
          field: 'paiement',
          width: 200,
          sort: 'asc',
        }
      ],
      datatable:{
        columns:[],
        rows: [],
      },


    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handlerClickMedecin = this.handlerClickMedecin.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    
  }

  //affichage des RDV AVEC  centres 
  

  //affichage des RDV AVEC  centres 






  // const [show, setShow] = useState(false);

   handleClose = () => this.setState({ show: false});
   handleShow = () => this.setState({ show: true});

   handlerClickMedecin = (id) => {
    // alert(id);
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    
    fetch(  host+`/rdv/${id}`, requestOptions)
      .then(response => response.json())
      .then(result =>{ 

            this.setState({
              rdvbyId: result
            })
        // console.log("RDV&&&&&: ",result.idprofessionnelsante2)       
              this.handleShow();
      })
      .catch(error => console.log('error', error));


      

  }

  async componentDidMount() {
    let dbUser = JSON.parse(localStorage.getItem("user"))
      || JSON.parse(sessionStorage.getItem("user"));

      if (!dbUser) {
          window.location.assign('/')
      }
    let identifiant = JSON.parse(sessionStorage.getItem("userID"))

    // console.log("USER_ID", dbUser.user.patient.id)


    if (dbUser === null || dbUser === undefined) {
      this.props.history.push('/login')
    } else {
      // console.log("LOGIN ICI")
    }
    let ordonnanceResponse = await
      fetch(host+`/patient/${dbUser.user.patient.id} `)

    if (ordonnanceResponse.status == 200) {
      let data = await ordonnanceResponse.json()
      this.setState({
        tableauOrdonnance: data.ordonnances,
      });
    }
    let tabRdv = [];
    let tabRdvCentre = [];
    let response = await fetch(host+`/patient/${dbUser.user.patient.id} `)
    if (response.status === 200) {
      let data = (await response.json())
      for (let index = 0; index < data.rdvs.length; index++) {
        const element = data.rdvs[index];
        // console.log("rdv_D: ", element)
         
        if(element.idprofessionnelsante2 !== null){
            tabRdv.push(element)
            // console.log("rdv_CENTRE: ", element)

        }
          {
          // console.log("PS-RDV: ", element)
        }
         if(element.idcentre !== null && element.idprofessionnelsante2 == null ){
          console.log("rdv_ICentre: ", element)
            tabRdvCentre.push({
              centre: element.idcentre.libelle,
              prestation: element.idprestation2.libelle,
              rdv: element.daterdv+" "+element.heurerdv,
              paiement: element.datepaiement,
            },) 
        }
      }
        this.setState({
          rdv: tabRdv,
          rdvCentre: tabRdvCentre,
          datatable:{
            columns : this.state.columns,
            rows: tabRdvCentre
          }
        })
      
    }
    this.remplirTableau()
  }

  	  //bouton radio for Today appointment
      today = () => {
        if(this.state.rdvCentre){
          let tabRdvToday = [];
          let dateToday = new Date();
         let newTabtoDayRdv= [];
         for (let index = 0; index < this.state.rdvCentre.length; index++) {
           const element = this.state.rdvCentre[index];
            //  console.log("RDV--CENTRET:", element)
             var time1 = moment(dateToday).format('YYYY-MM-DD');
              var time2 = moment(element.rdv).format('YYYY-MM-DD');
             if(time2 === time1){
               // console.log('date2 is  equal date1');
               tabRdvToday.push(element)
             }						
         }
           if(tabRdvToday.length !== 0){
             for (let i = 0; i < tabRdvToday.length; i++) {
               const elementRdv = tabRdvToday[i];
               console.log("elementRdv:", elementRdv)
               newTabtoDayRdv.push({
                 centre: elementRdv.centre,
                 prestation: elementRdv.prestation,
                 rdv: elementRdv.rdv,
                 paiement: elementRdv.paiement,
               },)
               
             }
           }
           this.setState({
             datatable:{
               columns: this.state.columns,
               rows: newTabtoDayRdv
             }
           })
        }
      };

      //bouton radio for forever appoitment
      avenir = () => {
        if(this.state.rdvCentre){	
          let tabRdvToday = [];
          let dateToday = new Date();
          let newTabFuturRdv= [];
          for (let index = 0; index < this.state.rdvCentre.length; index++) {
            const element = this.state.rdvCentre[index];
              var time1 = moment(dateToday).format('YYYY-MM-DD');
              var time2 = moment(element.rdv).format('YYYY-MM-DD');
            
              if(time2 > time1){
                // console.log('date2 is  equal date1');
                tabRdvToday.push(element)
              }						
          }
            if(tabRdvToday.length !== 0){
              for (let i = 0; i < tabRdvToday.length; i++) {
                const elementRdv = tabRdvToday[i];
                newTabFuturRdv.push({
                  centre: elementRdv.centre,
                  prestation: elementRdv.prestation,
                  rdv: elementRdv.rdv,
                  paiement: elementRdv.paiement,
                },)
                
              }
            }
            this.setState({
              datatable:{
                columns: this.state.columns,
                rows: newTabFuturRdv
              }
            })

        }
      };
      //bouton radio for passe appointment
      passe = () => {
        if(this.state.rdvCentre){	
          let tabRdvToday = [];
          let dateToday = new Date();
          let newTabPasserRdv= [];
          for (let index = 0; index < this.state.rdvCentre.length; index++) {
            const element = this.state.rdvCentre[index];
              // console.log("RDV&:", element)
              var time1 = moment(dateToday).format('YYYY-MM-DD');
              var time2 = moment(element.rdv).format('YYYY-MM-DD');
              if(time2 < time1){
                tabRdvToday.push(element)
              }						
          }
            if(tabRdvToday.length !== 0){
              for (let i = 0; i < tabRdvToday.length; i++) {
                const elementRdv = tabRdvToday[i];
                newTabPasserRdv.push({
                  centre: elementRdv.centre,
                  prestation: elementRdv.prestation,
                  rdv: elementRdv.rdv,
                  paiement: elementRdv.paiement,
                },)
                
              }
            }
            this.setState({
              datatable:{
                columns: this.state.columns,
                rows: newTabPasserRdv
              }
            })

        }
      };

      //bouton radio for all appoitment
	allAppointment = () => {
		if(this.state.rdvCentre){	
			let tabRdvToday = [];
			let dateToday = new Date();
			let newTabPasserRdv= [];
			for (let index = 0; index < this.state.rdvCentre.length; index++) {
				const element = this.state.rdvCentre[index];
				 console.log("RDVaLLcRNTRE:", element)
					var time1 = moment(dateToday).format('YYYY-MM-DD');
					 var time2 = moment(element.rdv).format('YYYY-MM-DD');
						tabRdvToday.push(element)							
			}
				if(tabRdvToday.length !== 0){
					for (let i = 0; i < tabRdvToday.length; i++) {
						const elementRdv = tabRdvToday[i];
            console.log("RDVaLLcRNTRE2:", elementRdv)
						newTabPasserRdv.push({
              centre: elementRdv.centre,
              prestation: elementRdv.prestation,
              rdv: elementRdv.rdv,
              paiement: elementRdv.paiement,
					  })
						
					}
				}
				this.setState({
					datatable:{
						columns: this.state.columns,
						rows: newTabPasserRdv
					}
				})

		 }
	};
  


  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  remplirTableau() {
    let tab = this.state.rdv;
    let newTab = []
    let tabInverse = []
    if (this.state.date == 0) {
      this.setState({
        tabAffichage: tab.reverse()
      })
    }

    if (this.state.date == 1) {
      for (let i = 0; i < tab.length; i++) {
        if (new Date(tab[i].daterdv) > new Date()) {
          newTab.push(tab[i])
        }
      }
      this.setState({
        tabAffichage: newTab.reverse()
      })
    }

    if (this.state.date == 2) {
      for (let i = 0; i < tab.length; i++) {
        let demain = this.addDays(new Date(), 1)
        demain = this.ecrireDate2(demain)

        if (new Date(this.ecrireDate2(new Date())) <= new Date(tab[i].daterdv) && new Date(tab[i].daterdv) < new Date(demain)) {
          newTab.push(tab[i])
        }
      }
      this.setState({
        tabAffichage: newTab.reverse()
      })
    }

    if (this.state.date == 3) {
      for (let i = 0; i < tab.length; i++) {
        if (new Date(tab[i].daterdv) < new Date()) {
          newTab.push(tab[i])
        }
      }
      this.setState({
        tabAffichage: newTab.reverse()
      })
    }
  }

  ecrireDate2(date) {
    var d = new Date(date)

    var mois = parseInt(d.getMonth())
    var jour = parseInt(d.getDate())
    mois = mois + 1
    if (mois < 10) {
      mois = "0" + mois
    }
    if (jour < 10) { 
      jour = "0" + jour
    }

    return d.getFullYear() + "-" + mois + "-" + jour
  }

  ecrireDate(date) {
    //2021-03-30
    var listeJours = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    var listeMois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

    var str = new Date(date);
    //var tab = str.split("-");

    var jour = listeJours[str.getDay()]
    var mois = listeMois[str.getMonth()]

    return jour + " " + str.getDate() + " " + mois + " " + str.getFullYear()

  }
  ecrireDate3(date) {
    //2021-03-30
    var listeJours = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    var listeMois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

    var str = new Date(date);
    //var tab = str.split("-");

    var jour = listeJours[str.getDay()]
    var mois = listeMois[str.getMonth()]

    return str.getDate() + " " + mois + " " + str.getFullYear()

  }
  handleChangeCheck(e, index) {
    let tab = this.state.check
    // console.log(index)
    for (let i = 0; i < tab.length; i++) {
      if (i == index) {
        tab[i] = true
      } else {
        tab[i] = false
      }
    }
    this.setState({
      check: tab,
      date: index,
    })
    this.componentDidMount()
  }

  handleSelect(key) {
    // console.log("selected " + key);
    this.setState({ key });
  }
  render() {
    

    //  console.log("RDV_PRIX:", this.state.tabAffichage)
    return (
      <div class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DashboardSidebar />
              </StickyBox>
            </div>
            <div class="col-md-7 col-lg-8 col-xl-9">
              <div class="card">
                <div class="card-body pt-0">
                  <Tabs
                    className="tab-view"
                    activeKey={this.state.key}
                    onSelect={this.handleSelect}
                    id="controlled-tab-example"
                  >
                    <Tab className="nav-item" eventKey={1} title="Rendez-vous">
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input className="form-check-input" type="radio" checked={this.state.check[2]} onChange={e => this.handleChangeCheck(e, 2)} /> Aujourd'hui
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input className="form-check-input" type="radio" checked={this.state.check[1]} onChange={e => this.handleChangeCheck(e, 1)} /> À venir
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input className="form-check-input" type="radio" checked={this.state.check[3]} onChange={e => this.handleChangeCheck(e, 3)} /> Passé
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input className="form-check-input" type="radio" checked={this.state.check[0]} onChange={e => this.handleChangeCheck(e, 0)} /> Tous les rendez-vous
                        </label>
                      </div>
                     
                      <div className="card card-table mb-0"><br />
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Médécin</th>
                                  <th>Prestation</th>
                                  <th>Controle</th>
                                  <th>Centre</th>
                                  <th>Date du rendez-vous</th>
                                  <th>Date du paiement</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.tabAffichage.map((data) => {
                                    return <tr>
                                      <td>
                                        { console.log("DATA-CENTR:",data)}
                                        <h2 className="table-avatar">                                       
                                          <Link
                                            to={"/patient/doctor-profile/" + data.idprofessionnelsante2.idprofessionnelsante}
                                            className="avatar avatar-sm mr-2"
                                          >
                                            {/* {console.log("PSSSSSSSS: ",data)} */}
                                            <img
                                              className="avatar-img rounded-circle"
                                              src={data.idprofessionnelsante2.user && data.idprofessionnelsante2.user.photo  ?  data.idprofessionnelsante2.user.photo : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"}
                                              alt="User"
                                            />
                                            {/* <img
                                              className="avatar-img rounded-circle"
                                              src="https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                                              alt="User"
                                            /> */}
                                          </Link>
                                          <Link to={"/patient/doctor-profile/" + data.idprofessionnelsante2.idprofessionnelsante}>
                                            {data.idprofessionnelsante2.nom
                                              + " "
                                              + data.idprofessionnelsante2.prenom}
                                          </Link>
                                        </h2>
                                      </td>
                                      <td>  {data.idprestation2.libelle}  </td>
                                      <td>  {data.control ? "Oui" : "Non"}  </td>
                                      <td>  {data.idcentre ? data.idcentre.libelle : "---"}  </td>
                                      <td>
                                        {this.ecrireDate3(data.daterdv)}{" "}
                                        <span className="d-block text-info">
                                          {/* {data.heurerdv.substring(0, 5)} */}
                                          {data.heurerdv} 
                                        </span>
                                      </td>
                                      <td>{ data.datepaiement === null || data.datepaiement === "" ? "" : this.ecrireDate3(data.datepaiement)   }</td>

                                      <td className="text-right">
                                        <div className="table-action">
                                          {/* <Link className="btn btn-sm bg-primary-light">
                                            <i className="fas fa-print"></i> Imprimer
                                          </Link> */}
                                          <span onClick = {() => this.handlerClickMedecin(data.idrdv)} className="btn btn-sm bg-info-light">
                                            <i className="far fa-eye"></i> Voir
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                  })
                                }


                              </tbody>
                            </table>
                          </div><br /><br />
                          <div>
                          <hr />
                          <hr />
                          <h2> Rendez-vous avec un centre</h2><br />
                          {/* <span>Filtre:</span> */}
                          {/* <Checkbox>Aujourd'hui</Checkbox>
                          <Checkbox> À venir</Checkbox>
                          <Checkbox>Passé</Checkbox>
                          <Checkbox>Tous les rendez-vous</Checkbox> <br /> */}
                          <Radio.Group name="radiogroup" defaultValue={4}>
                            <Radio onClick={this.today} value={1}>Aujourd'hui</Radio>
                            <Radio onClick={this.avenir} value={2}>À venir</Radio>
                            <Radio onClick={this.passe}  value={3}>Passé</Radio>
                            <Radio onClick={this.allAppointment} value={4}>Tous les rendez-vous</Radio>
                          </Radio.Group>
                           
                            <br/><br/>
                            <MDBTable responsive>            
                              <MDBDataTableV5 hover
                          searchTop
                          searchBottom={false}
                          entriesLabel={"Lignes par pages"}
                          searchLabel={"Rechercher"}
                          infoLabel={["", "-", "/", ""]}
                          noRecordsFoundLabel={
                            "Aucune donnée ne correspond"
                          }
                           entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={this.state.datatable}  />
                            </MDBTable>
                        </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab className="nav-item" eventKey={2} title="Ordonnance">
                      <div className="text-right">
                       
                      </div>
                      <div className="card card-table mb-0">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Date </th>
                                  <th>ID</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.tableauOrdonnance.map((ordonnance, index) => {
                                  return <tr>
                                    <td>{this.ecrireDate(ordonnance.date)}</td>
                                    <td>{ordonnance.idordonnance}</td>

                                    <td className="text-right">
                                      <div className="table-action">
                                        {/* <a className="btn btn-sm bg-primary-light">
                                          <i className="fas fa-print"></i> imprimer
                                        </a> */}
                                        <a  className="btn btn-sm bg-info-light">
                                          <Link to={"/ordonnance/"+ordonnance.idordonnance} className="far fa-eye"></Link>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                })
                                }
                              </tbody>
                            </table>
                            


                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>

                  

                </div>
              </div>
            </div>
          </div>
        </div>

        <>
    <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Détail du rendez-vous</Modal.Title>
        </Modal.Header>
        <Modal.Body>
             <div className="card">
              <div className="doctor-widget"> 
                <div className="doc-info-left">
                  <div className="doctor-img">
                    <Link>
                      {/* <img className="img-fluid" alt="User" src={(professionnel.photo[0] != null && professionnel.photo[0] !== undefined) ? professionnel.photo[0].url : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} />
                      <img
                        className="img-fluid"
                        alt="User"
                        src="https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                      /> */}
                      <img className="img-fluid" alt="User"
                        // src="https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                       src={!this.state.rdvbyId || !this.state.rdvbyId.idprofessionnelsante2 || !this.state.rdvbyId.idprofessionnelsante2.user || !this.state.rdvbyId.idprofessionnelsante2.user.photo  ?  "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" : this.state.rdvbyId.idprofessionnelsante2.user.photo}
                      //  src={!this.state.rdvbyId || !this.state.rdvbyId.idprofessionnelsante2 || !this.state.rdvbyId.idprofessionnelsante2.user || !this.state.rdvbyId.idprofessionnelsante2.user.photo  ?  "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" : host +`/user/images/${this.state.rdvbyId.idprofessionnelsante2.user.photo}`}
                       />
                      {/* {console.log("rrrr: ", this.state.rdvbyId.idprofessionnelsante2)} */}
                    </Link>
                  </div>
                </div>
                <div className="doc-info-cont">
                  <h4>
                    <Link id="nom" to="/patient/doctor-profile">
                      { this.state.rdvbyId == null || this.state.rdvbyId == undefined ? "" :  this.state.rdvbyId.idprofessionnelsante2.nom + " " + this.state.rdvbyId.idprofessionnelsante2.prenom}{" "}
                    </Link>
                  </h4>
                  
                 <h4>
                   Email:
                 { this.state.rdvbyId == null || this.state.rdvbyId == undefined ? "" :  this.state.rdvbyId.idprofessionnelsante2.email}{" "}
                 </h4>
                  
                  <br />
                  {/* <div>
                    <h5 className="doc-department">
                      {(this.state.rdvbyId == null && this.state.rdvbyId.idprofessionnelsante2.specialite.libelle) ||
                      (this.state.rdvbyId == null && this.state.rdvbyId.idprofessionnelsante2.specialite.libelle)
                        ? "Aucune spécialité spécifiée"
                        : this.state.rdvbyId.idprofessionnelsante2.specialite.libelle}
                    </h5>
                    <h5 className="doc-department">
                      <i className="fas fa-map-marker-alt"></i>
                      {(this.state.rdvbyId == null && this.state.rdvbyId.idprofessionnelsante2.ville.libelle) ||
                        (this.state.rdvbyId == null && this.state.rdvbyId.idprofessionnelsante2.ville.libelle)                      
                        ? "Aucune ville spécifiée"
                        : this.state.rdvbyId.idprofessionnelsante2.ville.libelle}
                    </h5>
                  </div> */}
                  <br />
                </div>
                   


              </div>
              <ListGroup variant="flush">
               
                      <ListGroup.Item> <strong>Date du rendez-vous</strong> : { this.state.rdvbyId == null || this.state.rdvbyId == undefined ? "" : this.ecrireDate3(this.state.rdvbyId.daterdv) } </ListGroup.Item>
                      <ListGroup.Item> <strong>Heure du rendez-vous</strong> :  { this.state.rdvbyId == null || this.state.rdvbyId == undefined ? "" : this.state.rdvbyId.heurerdv}</ListGroup.Item>
                      <ListGroup.Item> <strong>Heure de fin du rendez-vous</strong> :  { this.state.rdvbyId == null || this.state.rdvbyId == undefined ? "" : this.state.rdvbyId.idcreneau2.heurefin}</ListGroup.Item>
                      {/* <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item> */}
                    </ListGroup>
            </div> 
         
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button> */}
          <Button className="btn btn-bluefonce" onClick={this.handleClose}>
            ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
      </div>
      
    );
  }
}
export default Dashboard;