import React, { Component } from "react";
// import SignaturePad from 'react-signature-canvas';
import jsPDF from "jspdf";
// import axios from 'axios';
import host from "../../../../../host";
import { ToastContainer, toast } from "react-toastify";
// import logo from "../../../assets/images/logoK.jpeg";
import qrCode from "../../../../assets/images/qrCode.png";
import signature from "../../../../assets/images/signature3.png";
import logo from "../../../../assets/images/logoK.jpeg";
import QRCode from "qrcode";

class Ordonnance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ordonnance: [],
      professionnel: { specialite: [] },
      prescription: [],
      adresseCentre: "",
      patient: [],
      isLoading: false,

      produit: [],
      ligneProduit: [],
      prescription: [],

      tabAffichage: [],
      nom: "text",
      quantite: 2,
      jourDebut: "",
      jourFin: "",
      matin: "",
      aprem: "",
      soir: "",
      nuit: "",
      urlPhoto: null,
      nomProduit: "",
      quantiteProduit: "",
      jourFinProduit: "",
      jourDebutProduit: "",
      nomPs: "",
      prenomPs: "",
      imgSignature: "",
      idOrdonnance: "",
    };
  }

  generatePdf = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [900, 2000],
    });
    doc.html(document.querySelector("#pdf"), {
      callback: function (pdf) {
        pdf.save("ordonnance.pdf");
        toast.success("Téléchargement effectué aves succès !");
      },
    });
  };

  dateAJD() {
    let date = new Date();
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    var nombre = date.getDate();
    var jour = listeJours[date.getDay()];
    var mois = listeMois[date.getMonth()];
    var annee = date.getFullYear();
    return jour + " " + nombre + " " + mois + " " + annee;
  }
  ecrireDate2(date) {
    var d = new Date(date);

    var mois = parseInt(d.getMonth());
    var jour = parseInt(d.getDate());
    mois = mois + 1;
    if (mois < 10) {
      mois = "0" + mois;
    }
    if (jour < 10) {
      jour = "0" + jour;
    }

    return jour + "-" + mois + "-" + d.getFullYear();
  }

  generatePDF2 = async () => {
    var doc = new jsPDF("p", "pt");

    // text pour les commandes à inserer
    let numéroCommande = this.state.idOrdonnance;
    let dateCommande = this.dateAJD();
    let client = this.state.patient.nom + " " + this.state.patient.prenom;
    let adresseClient = this.state.patient.adresse;
    let docteur = this.state.nomPs + " " + this.state.prenomPs;
    let adresseCentre = this.state.adresseCentre;
    let ModePaiement = "Carte Bancaire";
    let hauteurTableauDescription = 220;
    let total = this.state.prix;

    let text = "Ordonnance n° " + numéroCommande + "\n";
    text += "Délivrée le " + dateCommande + "\n";
    text += "À destination de : " + client + "\n";
    text += "Par le docteur " + docteur + "\n";

    var imgData = logo;
    var imgCode = qrCode;
    var imgSignature = signature;

    // let tabD = this.state.nomProduit;
    // let tabQ = this.state.quantiteProduit;
    // let tabT = this.state.jourFin;
    // let tabF = this.state.jourDebut;
    let tabD = "";
    let tabQ = "";
    let tabT = "";
    let tabF = this.state.jourDebut;

    // Haut de la page en bleu
    doc.addImage(imgData, "JPEG", 20, 20, 100, 60);
    //droite
    doc.setFontSize(12);
    doc.addFont("arial", "normal");
    doc.text(230, 35, "Ordonnance : ");
    doc.setFont("arial", "bold");
    doc.text(300, 35, "#" + numéroCommande);
    doc.setFont("arial", "normal");
    doc.text(340, 35, "Délivrée le ");
    doc.setFont("arial", "bold");
    doc.text(399, 35, dateCommande);

    doc.setFontSize(16);
    doc.setFont("arial", "bold");
    doc.text(420, 100, "À destination de :");
    doc.setFontSize(12);
    doc.setFont("arial", "normal");
    doc.text(400, 120, client);
    // doc.text(adresseClient, 400, 100)

    doc.setFontSize(16);
    doc.setFont("arial", "bold");
    doc.text(40, 120, "Medecin");
    doc.setFontSize(12);
    doc.setFont("arial", "normal");
    doc.text(40, 140, docteur);
    // doc.text(20, 145, " Adresse du centre   " + adresseCentre)

    // doc.setFontSize(16);
    // doc.setFont("arial", "bold")
    // doc.text(20, 170, "Moyen de paiment")
    // doc.setFontSize(12);
    // doc.setFont("arial", "normal")
    // doc.text(20, 185, ModePaiement)

    doc.setFontSize(14);
    doc.setFont("arial", "bold");
    doc.text(20, 220, "Produit");
    doc.text(100, 220, "Quantité");
    doc.text(200, 220, "Période");
    doc.text(350, 220, "Date de Début");
    doc.text(500, 220, "Date de Fin");

    doc.setFontSize(12);
    doc.setFont("arial", "normal");

    // for (let i = 0; i < tabT.length; i++) {
    //   hauteurTableauDescription = hauteurTableauDescription + 30;
    //   doc.text(20, hauteurTableauDescription, tabD[i]);
    //   doc.text(tabQ[i], 170, hauteurTableauDescription);
    //   doc.text(this.ecrireDate2(tabF[i]), 350, hauteurTableauDescription);
    //   doc.text(this.ecrireDate2(tabT[i]), 500, hauteurTableauDescription);
    // }

    text += "Produits : \n\n";

    for (let index = 0; index < this.state.prescription.length; index++) {
      const element = this.state.prescription[index];
      let periode = this.state.prescription[index].matin ? "matin" : "";
      periode += this.state.prescription[index].apresmidi ? " midi" : "";
      periode += this.state.prescription[index].soire ? " soire" : "";
      periode += this.state.prescription[index].nuit ? " nuit" : "";

      hauteurTableauDescription = hauteurTableauDescription + 30;
      text += "____________________________ \n\n";

      text +=
        this.state.prescription[index].libelle +
        " | Quantite: " +
        this.state.prescription[index].quantite +
        " | " +
        periode +
        "\n";
      text +=
        "Date de Début: " +
        this.state.prescription[index].dateprescription +
        " | Date de Fin: " +
        this.state.prescription[index].datefin +
        "\n";

      // this.state.prescription[index].quantite,
      console.log("QUANTITE: ", this.state.prescription[index].quantite);

      doc.text(
        20,
        hauteurTableauDescription,
        this.state.prescription[index].libelle
      );
      doc.text(
        this.state.prescription[index].quantite + "",
        100,
        hauteurTableauDescription
      );
      doc.text(200, hauteurTableauDescription, periode);
      doc.text(
        this.state.prescription[index].dateprescription,
        350,
        hauteurTableauDescription
      );
      doc.text(
        this.state.prescription[index].datefin,
        500,
        hauteurTableauDescription
      );
    }

    doc.setFontSize(14);
    doc.setFont("arial", "bold");
    doc.text(350, hauteurTableauDescription + 60, "Signature :");
    doc.setFontSize(14);
    doc.setFont("arial", "normal");
    doc.addImage(
      // `${host}/ps/signatures/${this.state.imgSignature}`,
      this.state.imgSignature,
      "PNG",
      350,
      hauteurTableauDescription + 90,
      200,
      50
    );
    const code = await QRCode.toDataURL(text);
    doc.addImage(code, "PNG", 200, 600, 200, 200);
    // doc.addImage(
    //   imgSignature,
    //   "PNG",
    //   350,
    //   hauteurTableauDescription + 90,
    //   200,
    //   50
    // );
    // doc.text(480, hauteurTableauDescription + 60, total + " XAF")

    // doc.setFontSize(14);
    // doc.setFont("arial", "bold")
    // doc.text(350, hauteurTableauDescription + 90, "Réduction :")
    // doc.setFontSize(14);
    // doc.setFont("arial", "normal")
    // doc.text(480, hauteurTableauDescription + 90, total + " XAF")

    // doc.setFontSize(14);
    // doc.setFont("arial", "bold")
    // doc.text(350, hauteurTableauDescription + 120, "Montant total :")
    // doc.setFontSize(14);
    // doc.setFont("arial", "normal")
    // doc.text(480, hauteurTableauDescription + 120, total + " XAF")

    // doc.addImage(imgCode, "PNG", 200, 600, 200, 120);

    //doc.setTextColor(0,140,255)

    // //gauche
    // doc.text(20, 40, 'Docteur machin')
    // doc.text(20, 55, 'Son corps de métier')

    // // Corps de page
    // doc.setFontSize(12)
    // doc.setFont("calibri", "bold")
    // doc.text(20, 60, 'This is the second title.')
    // doc.setFont("arial","normal")
    // doc.text(20, 100, 'This is the thrid title.')

    // this.setState({
    //   //documentPDF: doc.output("datauristring")
    //   documentPDF: doc.output("dataurlnewwindow"),
    // });
    doc.save("ordonnace.pdf");
  };

  // let tabD = this.state.nom;
  // let tabQ = this.state.quantite;
  // let tabT = this.state.jourFin;
  // let tabF = this.state.jourDebut;

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const id = parseInt(params.id);
    let dbUser = JSON.parse(sessionStorage.getItem("user"));
    let idpatient = dbUser.user.patient.id;
    // alert(id);
    // alert(idpatient);

    // const id = 4
    let tabJourDebut = [];
    tabJourDebut.push(this.ecrireDate());
    // this.props.location.ordonnance.idOrdonnance

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    //   fetch(host+`/ordonnance/${id}`, requestOptions)
    // 	.then(response => response.json())
    // 	.then(result => {
    // 		console.log(result)
    // 		if(result.status == 200){
    // 			this.setState({
    // 				ordonnance: result,
    // 				prescription: result.prescriptions,
    // 			});
    // 		}
    // 	})
    // 	.catch(error => console.log('error', error));

    let ordonnanceResponse = await fetch(host + `/ordonnance/${id}`);

    if (ordonnanceResponse.status == 200) {
      let data = await ordonnanceResponse.json();

      // console.log("ORDONN_PS: ", data)

      this.setState({
        ordonnance: data,
        prescription: data.prescriptions,
        nomPs: data.professionnelsante.nom,
        prenomPs: data.professionnelsante.prenom,
        imgSignature: data.professionnelsante.signature,
        idOrdonnance: data.idordonnance,
      });
    }

    // let PrescriptionResponse = await
    // 	fetch(host+`/prescription`)

    // if (PrescriptionResponse.status == 200) {
    // 	let data = await PrescriptionResponse.json()
    // 	this.setState({
    // 		prescription: data,
    // 	});
    // }

    // var requestOptions = {
    // 	method: 'GET',
    // 	redirect: 'follow'
    //   };

    //   fetch(host+`/patient/${idpatient}`, requestOptions)
    // 	.then(response => response.json())
    // 	.then(result => {
    // 		console.log(result)
    // 		if(result.status == 200){
    // 			this.setState({
    // 				patient: result
    // 			});
    // 		}

    // 	})
    // 	.catch(error => console.log('error', error));

    let patientResponse = await fetch(host + `/patient/${idpatient}`);

    if (patientResponse.status == 200) {
      let data = await patientResponse.json();
      console.log("DATA_user: ", data);
      this.setState({
        patient: data,
        urlPhoto: data.user.photo,
        // urlPhoto: data.user.photo
      });
    }
    // await this.remplirTableau()
  }

  CalculAge(date) {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  handleChange(e, index, input) {
    if (input == "nom") {
      let tabNom = this.state.nom;
      tabNom[index] = e.target.value;
      this.setState({ nom: tabNom });
    }
    if (input == "quantite") {
      let tabQ = this.state.quantite;
      tabQ[index] = e.target.value;
      this.setState({ quantite: tabQ });
    }
    if (input == "jourDebut") {
      let tabJ = this.state.jourDebut;
      tabJ[index] = e.target.value;
      this.setState({ jourDebut: tabJ });
    }
    if (input == "jourFin") {
      let tabJ = this.state.jourFin;
      tabJ[index] = e.target.value;
      this.setState({ jourFin: tabJ });
    }
  }

  // handleChangeCheck(e, index, moment) {
  // 	if (moment == "matin") {
  // 		let tabM = this.state.matin
  // 		tabM[index] = e.target.checked
  // 		this.setState({
  // 			matin: tabM
  // 		})
  // 	}
  // 	if (moment == "aprem") {
  // 		let tabA = this.state.aprem
  // 		tabA[index] = e.target.checked
  // 		this.setState({
  // 			aprem: tabA
  // 		})
  // 	}
  // 	if (moment == "soir") {
  // 		let tabS = this.state.soir
  // 		tabS[index] = e.target.checked
  // 		this.setState({
  // 			soir: tabS
  // 		})
  // 	}
  // 	if (moment == "nuit") {
  // 		let tabN = this.state.nuit
  // 		tabN[index] = e.target.checked
  // 		this.setState({
  // 			nuit: tabN
  // 		})
  // 	}
  // }
  ecrireDate(date) {
    //2021-03-30
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    var str = new Date(date);
    //var tab = str.split("-");

    var jour = listeJours[str.getDay()];
    var mois = listeMois[str.getMonth()];

    return jour + " " + str.getDate() + " " + mois + " " + str.getFullYear();
  }

  // dateAJD() {
  // 	let date = new Date()
  // 	var listeJours = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  // 	var listeMois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']

  // 	var nombre = date.getDate()
  // 	var jour = listeJours[date.getDay()];
  // 	var mois = listeMois[date.getMonth()];
  // 	return jour + " " + nombre + " " + mois
  // }

  // estDansOrdonnance(e) {
  // 	let tabO = this.state.prescription
  // 	for (let index = 0; index < tabO.length; index++) {
  // 		if (tabO[index].id == e.id) {
  // 			return true
  // 		}
  // 	}
  // 	return false
  // }

  // async afficherNom(ordonnance) {
  // 	let PrescriptionResponse = await
  // 		fetch(host+`/ordonnance/`)

  // 	if (PrescriptionResponse.status == 200) {
  // 		let data = await PrescriptionResponse.json()
  // 		this.setState({
  // 			prescription: data.prescriptions
  // 		});
  // 	}
  // }

  // async remplirTableau() {
  // 	let tabA = []
  // 	let tabP = []
  // 	let tabLPP = []
  // 	let tabProduit = []
  // 	for (let i = 0; i < this.state.prescription.length; i++) {
  // 		if (this.estDansOrdonnance(this.state.prescription[i])) {
  // 			tabA.push(this.state.prescription[i])
  // 		}
  // 	}

  // 	for (let i = 0; i < tabA.length; i++) {
  // 		console.log(tabA[i].id, "tabA")
  // 		let PrescriptionResponse = await
  // 			fetch(`https://kwaber-sante.herokuapp.com/prescriptions/${tabA[i].id}`)

  // 		if (PrescriptionResponse.status == 200) {

  // 			let data = await PrescriptionResponse.json()
  // 			tabP.push(data)

  // 			let ligneProduitResponse = await
  // 				fetch(`https://kwaber-sante.herokuapp.com/ligne-produit-prescriptions/${data.ligne_produit_prescriptions[0].id}`)

  // 			if (ligneProduitResponse.status == 200) {
  // 				let data = await ligneProduitResponse.json()
  // 				console.log(data, "datatatat")
  // 				tabLPP.push(data)

  // 				let produitResponse = await
  // 					fetch(`https://kwaber-sante.herokuapp.com/produit-pharmaceutiques/${data.produit_pharmaceutiques[0].id}`)

  // 				if (produitResponse.status == 200) {
  // 					let data = await produitResponse.json()
  // 					console.log(data, "testdata")
  // 					tabProduit.push(data)
  // 				}
  // 			}
  // 		}

  // 	}
  // 	this.setState({
  // 		tabAffichage: tabA,
  // 		produit: tabProduit,
  // 		ligneProduit: tabLPP,
  // 		prescription: tabP,
  // 	})

  // }

  render() {
    return (
      <div>
        <ToastContainer />
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <div className="profile-sidebar">
                  <div className="card widget-profile pat-widget-profile">
                    <div className="card-body">
                      <div className="pro-widget-content">
                        <div className="profile-info-widget">
                          <div className="profile-det-info">
                            <a href="#0" class="booking-doc-img">
                              {/* <img src="https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" /> */}
                              <img
                                src={
                                  this.state.urlPhoto == null ||
                                  this.state.urlPhoto == "" ||
                                  this.state.urlPhoto == undefined
                                    ? "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                                    : this.state.urlPhoto
                                }
                              />
                              {/* <img src={ this.state.urlPhoto == null || this.state.urlPhoto == "" || this.state.urlPhoto == undefined ? "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" : host+`/user/images/${this.state.urlPhoto}`} /> */}
                            </a>
                            {/* {
															  console.log("PATTTT: ", this.state.urlPhoto)
														  } */}
                            <h3>
                              {this.state.patient.nom}{" "}
                              {this.state.patient.prenom}
                            </h3>

                            <div className="patient-details">
                              <h5>
                                <b>Patient ID :</b> {this.state.patient.id}
                              </h5>
                              <h5 className="mb-0">
                                <i className="fas fa-map-marker-alt"></i>{" "}
                                {this.state.patient.adresse}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patient-info">
                        <ul>
                          <li>
                            Téléphone{" "}
                            <span>
                              +
                              {this.state.patient.numtelephone1 == null ||
                              this.state.patient.numtelephone1 == undefined
                                ? ""
                                : this.state.patient.numtelephone1}
                            </span>
                          </li>
                          <li>
                            Age{" "}
                            <span>
                              {this.state.patient.datenaissance == null ||
                              this.state.patient.datenaissance == undefined
                                ? ""
                                : this.CalculAge(
                                    this.state.patient.datenaissance
                                  )}
                            </span>
                          </li>
                          <li>
                            Sexe<span> {this.state.patient.sexe}</span>
                          </li>
                          <li>
                            Groupe sanguin{" "}
                            <span>
                              {" "}
                              {this.state.patient.groupesangin == null ||
                              this.state.patient.groupesangin == undefined
                                ? ""
                                : this.state.patient.groupesangin}{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9">
                <div className="row">
                  <div className="col-sm-6">
                    <button
                      onClick={this.generatePDF2}
                      className="btn btn-bluefonce"
                    >
                      {" "}
                      Téléchargé l'ordonnance
                    </button>
                  </div>
                </div>
                <div className="card">
                  <div id="pdf" className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        {/* <div className="biller-info">
													<h4 className="d-block">{this.state.professionnel.nom} {this.state.professionnel.prenom}</h4>
													<span className="d-block text-sm text-muted">{this.state.professionnel.specialite.libelle}</span>
													<span className="d-block text-sm text-muted">{this.state.adresseCentre}</span>
												</div> */}
                      </div>
                      <div className="col-sm-6 text-sm-right">
                        <div className="billing-info">
                          <h4 className="d-block">
                            {" "}
                            Ordonnance du :{" "}
                            <strong>
                              {this.ecrireDate(this.state.ordonnance.date)}
                            </strong>
                          </h4>
                          {/* <span className="d-block text-muted">#INV0001</span> */}
                        </div>
                      </div>
                    </div>

                    <div className="card card-table">
                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-hover table-center">
                            <thead>
                              <tr>
                                <th style={{ minWidth: "200px" }}>Nom</th>
                                <th style={{ minWidth: "80px" }}>Quantité</th>
                                <th style={{ minWidth: "80px" }}>Galénique</th>
                                <th style={{ minWidth: "80px" }}>Détail</th>
                                <th style={{ minWidth: "20px" }}>Début</th>
                                <th style={{ minWidth: "20px" }}>Fin</th>
                                <th style={{ minwWidth: "200px" }}>Période</th>
                              </tr>
                            </thead>

                            {this.state.prescription.map((produit) => {
                              { console.log("produit: ",produit) }
                              return (
                                <tr key={produit.idprescription}>
                                  <td>
                                    <div>{produit.libelle}</div>
                                  </td>
                                  <td>
                                    <div>{produit.quantite}</div>
                                  </td>
                                  <td>
                                    <div>{produit.galenique ? produit.galenique : ""}</div>
                                  </td>
                                  <td>
                                    <div>{produit.detail ? produit.detail : ""}</div>
                                  </td>
                                  <td>
                                    <div>
                                      {this.ecrireDate(
                                        produit.dateprescription
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div>{produit.datefin}</div>
                                  </td>
                                  <td>
                                    <div className="form-check form-check-inline">
                                      <label className="form-check-label">
                                        <input
                                          className="form-check-input"
                                          checked={produit.matin}
                                          type="checkbox"
                                        />{" "}
                                        Matin
                                      </label>
                                    </div>{" "}
                                    <br />
                                    <div className="form-check form-check-inline">
                                      <label className="form-check-label">
                                        <input
                                          className="form-check-input"
                                          checked={produit.apresmidi}
                                          type="checkbox"
                                        />{" "}
                                        Après-midi
                                      </label>
                                    </div>{" "}
                                    <br />
                                    <div className="form-check form-check-inline">
                                      <label className="form-check-label">
                                        <input
                                          className="form-check-input"
                                          checked={produit.soire}
                                          type="checkbox"
                                        />{" "}
                                        Soirée
                                      </label>
                                    </div>{" "}
                                    <br />
                                    <div className="form-check form-check-inline">
                                      <label className="form-check-label">
                                        <input
                                          className="form-check-input"
                                          checked={produit.nuit}
                                          type="checkbox"
                                        />{" "}
                                        Nuit
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Ordonnance;
