import React, { Component } from "react";
import { Table, Switch, Button, Modal, DatePicker, Space } from "antd";
import { Link } from "react-router-dom";
import SidebarNav from "../sidebar";
import { MDBDataTableV5 } from "mdbreact";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { itemRender, onShowSizeChange } from "../paginationfunction";
import host from "../../../host";

const { RangePicker } = DatePicker;

class Pays extends Component {
  constructor(props) {
    super(props);

    let token = "";

    if (sessionStorage.getItem("admin")) {
      token = JSON.parse(sessionStorage.getItem("admin")).token;
    }

    this.state = {
      auth: token,
      isLoading: false,
      isModalVisible: false,
      isDetail: false,
      isDelete: false,
      isUpdate: false,

      slug: "",
      libelle: "",
      pays: null,
      columns: [
        {
          label: "id",
          field: "idpays",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "nom",
          },
        },
        {
          label: "Nom du pays",
          field: "libelle",
          width: 100,
        },
        {
          label: "Actions",
          field: "action",
          width: 100,
        },
      ],
      rows: [{}],
    };
  }

  showUpdate = (item) => {
    this.setState({
      isUpdate: true,
      isModalVisible: true,
      pays: item,
      libelle: item.libelle,
    });
  };

  showDelete = (item) => {
    this.setState({
      isDelete: true,
      pays: item,
    });
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(host + "/pays/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status != 200) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        if (result != null) {
          for (let index = 0; index < result.length; index++) {
            result[index]["action"] = (
              <div className="actions">
                <a
                  href="#0"
                  className="btn btn-sm bg-success-light"
                  onClick={() => this.showUpdate(result[index])}
                >
                  <i className="fe fe-pencil"></i> Modifier
                </a>
                <a
                  className="btn btn-sm bg-danger-light"
                  onClick={() => this.showDelete(result[index])}
                >
                  <i className="fe fe-trash"></i> Supprimer
                </a>
              </div>
            );
          }
          this.setState({
            rows: result,
          });
        }
      })
      .catch((error) => console.log("error", error))
      .then(() => this.setState({ isLoading: false }));
  }

  handleOnclick = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleOk = () => {
    this.setState({
      isModalVisible: false,
      isDetail: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      isDetail: false,
      isDelete: false,
      libelle: "",
      pays: null,
      isLoading: false,
    });
  };

  handleOkDelete = () => {
    if (this.state.pays != null) {
      this.setState({
        isLoading: true,
      });
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(host + `/pays/delete/${this.state.pays.idpays}`, requestOptions)
        .then((response) => {
          console.log(response);
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200 && response.status != 201) {
            return null;
          }
          return response.json();
        })
        .then((result) => {
          console.log(result);
          if (result == null) {
            toast.error("Echec de la suppression");
          } else {
            let tmp = this.state.rows;
            for (let index = 0; index < tmp.length; index++) {
              const element = tmp[index];
              if (element.idpays == this.state.pays.idpays) {
                tmp.splice(index, 1);
                break;
              }
            }
            this.setState({
              rows: [...tmp],
            });
            toast.success("Suppression réalisée avec succés");
            this.handleCancel();
          }
          this.setState({
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          toast.error("Echec de la suppression");
          console.log(error);
        });
    }
  };

  handleChangeLibelle = (e) => {
    this.setState({ libelle: e.target.value });
  };

  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    console.log(this.props);

    if (this.state.isUpdate) {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let raw = JSON.stringify({
        slug: this.state.libelle,
        libelle: this.state.libelle,
      });

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(host + `/pays/update/${this.state.pays.idpays}`, requestOptions)
        .then((response) => {
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200 && response.status != 201) {
            return null;
          }
          return response.json();
        })
        .then((result) => {
          console.log(result);
          if (result == null) {
            toast.error("Les modifications n'ont pas été apportées");
          } else {
            for (let index = 0; index < this.state.rows.length; index++) {
              const element = this.state.rows[index];
              if (element.idpays == this.state.pays.idpays) {
                this.state.rows[index].libelle = this.state.libelle;
                break;
              }
            }
            this.setState({});
            toast.success("Modification réalisée avec succès");
            this.handleCancel();
          }
        })
        .catch((error) => {
          console.log("error", error);
          toast.error("Error : " + error);
        })
        .then(() => {
          this.setState({ isLoading: false });
        });
    } else {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);
      let raw = JSON.stringify({
        slug: this.state.libelle,
        libelle: this.state.libelle,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + "/pays/create/", requestOptions)
        .then((response) => {
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200 && response.status != 201) {
            return null;
          }
          return response.json();
        })
        .then((result) => {
          console.log(result);
          if (result == null) {
            toast.error("Le pays ne c'est pas correctement enregistrée");
          } else {
            result.pays["action"] = (
              <div className="actions">
                <a
                  href="#0"
                  className="btn btn-sm bg-success-light"
                  onClick={() => this.showUpdate(result.pays)}
                >
                  <i className="fe fe-pencil"></i> Modifier
                </a>
                <a
                  className="btn btn-sm bg-danger-light"
                  onClick={() => this.showDelete(result.pays)}
                >
                  <i className="fe fe-trash"></i> Supprimer
                </a>
              </div>
            );
            this.setState({
              rows: [result.pays, ...this.state.rows],
            });
            toast.success("Insertion réalisée avec succès");
            this.handleCancel();
          }
        })
        .catch((error) => {
          console.log("error", error);
          toast.error("Error : " + error);
        })
        .then(() => {
          this.setState({ isLoading: false });
        });
    }

    e.preventDefault();
  };

  render() {
    return (
      <>
        <SidebarNav />
        <ToastContainer />

        {/* Delete Modal */}
        <Modal
          title="Suppression dun pays"
          visible={this.state.isDelete}
          okText="Confirmer"
          cancelText="Annuler"
          okType="danger"
          onOk={this.state.isLoading ? null : this.handleOkDelete}
          onCancel={this.state.isLoading ? null : this.handleCancel}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <div className="form-content p-2">
            <h4 className="modal-title">
              Supprimer {this.state.pays ? this.state.pays.libelle : ""}
            </h4>
            <Loader
              style={{
                position: "absolute",
                textAlign: "center",
                marginLeft: "auto",
                left: "0",
                right: "0",
                marginRight: "auto",
                zIndex: "20px",
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            />
            <p className="mb-4">Confimer vous la suppression</p>
          </div>
        </Modal>

        {/* Delete Modal */}

        <Modal
          title={
            this.state.isUpdate ? "Modification d'un pays" : "Ajouter un pays"
          }
          visible={this.state.isModalVisible}
          onOk={this.state.isLoading ? null : this.handleOk}
          onCancel={this.state.isLoading ? null : this.handleCancel}
          footer={null}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <form
            form
            action="/"
            onSubmit={this.state.isLoading ? null : this.handleSubmit}
            id="addform"
          >
            <div className="row form-row">
              <div className="col-12">
                <div className="form-group">
                  <div className="col-12">
                    <div className="form-group">
                      <Loader
                        style={{
                          position: "absolute",
                          textAlign: "center",
                          marginLeft: "auto",
                          left: "0",
                          right: "0",
                          marginRight: "auto",
                          zIndex: "200px",
                        }}
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={this.state.isLoading}
                      />
                      <label>Nom du pays</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Entrer le nom de la ville"
                        name="libelle"
                        value={this.state.libelle}
                        disabled={this.state.isLoading}
                        onChange={this.handleChangeLibelle}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn btn-primary btn-block"
              type="submit"
              disabled={this.state.isLoading}
            >
              {this.state.isUpdate ? "Modifier" : "Ajouter"}
            </button>
          </form>
        </Modal>

        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Liste des pays</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admin">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item active">pays</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Button
                  className="btn btn-primary  mb-2"
                  type="primary"
                  size="large"
                  onClick={this.state.isLoading ? null : this.handleOnclick}
                >
                  AJOUTER
                </Button>
                <div
                  className={
                    this.state.isLoading &&
                    !this.state.isDelete &&
                    !this.state.isUpdate
                      ? "page_wrapper_blur card"
                      : "card"
                  }
                >
                  <div className="card-body">
                    <div className="table">
                      <Loader
                        style={{
                          position: "absolute",
                          textAlign: "center",
                          marginLeft: "auto",
                          left: "0",
                          right: "0",
                          marginRight: "auto",
                          zIndex: "2000",
                        }}
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={
                          this.state.isLoading &&
                          !this.state.isDelete &&
                          !this.state.isUpdate
                        }
                      />
                      <MDBDataTableV5
                        entriesOptions={[10, 20, 30]}
                        entries={10}
                        responsive={true}
                        entriesLabel={"Lignes par pages"}
                        searchLabel={"Rechercher"}
                        infoLabel={["", "-", "/", ""]}
                        noRecordsFoundLabel={"Aucune données de correspond"}
                        data={{
                          columns: this.state.columns,
                          rows: this.state.rows,
                        }}
                        searchTop
                        searchBottom={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Pays;
