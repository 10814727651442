import React, { Component } from "react";
import { Table, Switch, Button, Modal, DatePicker, Space } from "antd";
import { Link } from "react-router-dom";
import SidebarNav from "../sidebar";
import { MDBDataTableV5 } from "mdbreact";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { Image } from "antd";

import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import IMG01 from "../../assets/images/doctor-thumb-01.jpg";
import IMG02 from "../../assets/images/doctor-thumb-02.jpg";
import IMG03 from "../../assets/images/doctor-thumb-03.jpg";
import IMG04 from "../../assets/images/doctor-thumb-04.jpg";
import IMG05 from "../../assets/images/doctor-thumb-01.jpg";
import IMG06 from "../../assets/images/doctor-thumb-02.jpg";
import IMG07 from "../../assets/images/doctor-thumb-03.jpg";
import IMG08 from "../../assets/images/doctor-thumb-04.jpg";
import host from "../../../host";

const { RangePicker } = DatePicker;

class Patients extends Component {
  constructor(props) {
    super(props);

    let token = "";

    if (sessionStorage.getItem("admin")) {
      token = JSON.parse(sessionStorage.getItem("admin")).token;
    }

    this.state = {
      auth: token,
      isLoading: false,
      isModalVisible: false,
      isDetail: false,
      isDelete: false,
      isUpdate: false,
      active:false,
      disabled: true,


      nom: "",
      prenom: "",
      email: "",
      password: "",
      sexe: "",
      number: "",
      number2: "",
      datenaissance: "",
      photo: "",
      groupesangin: "",
      adresse: "",
      createdAt: "",

      idPatient: null,
      idUser: null,

      allData: [],
      datatable: {
        columns: [
          {
            label: "Nom",
            field: "nom",
            width: 100,
            attributes: {
              "aria-controls": "DataTable",
              "aria-label": "nom",
            },
          },
          {
            label: "Prenom",
            field: "prenom",
            width: 100,
            attributes: {
              "aria-controls": "DataTable",
              "aria-label": "nom",
            },
          },
          {
            label: "Email",
            field: "email",
            width: 100,
          },
          {
            label: "Tel",
            field: "tel",
            sort: "asc",
            width: 50,
          },
          {
            label: "Sexe",
            field: "sexe",
            width: 20,
          },
          {
            label: "Activé",
            field: "active",
            width: 20,
          },
        ],
        rows: [{}],
      },
    };
    this.changeProfileImage = this.changeProfileImage.bind(this)
  }


  //Change profile Image
  changeProfileImage = (event) => {
    console.log("target:", event.target.files[0])
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = () => {
      // console.log("target2:", reader.result)
      this.setState({
        uploadedFile: reader.result,
        color: false,
        // imageProfile: Date.now() + event.target.files[0].name
      });
      // console.log("PHOTO_USER", event.target.files[0].name);
    };
      setTimeout(() => {
        this.updateImage();
      }, 3000);

  };

  fetchDataUse = () => {
    var myHeaders = new Headers();
    myHeaders.append("auth", this.state.auth);
    myHeaders.append("Content-Type", "application/json");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(host+`/user/${this.state.idUser}`, requestOptions)
      .then(response => {
        if(response.status === 200){
          // console.log("token", this.state.token);
          return response.json()
        }
        // console.log("token1", this.state.token);
      })
      .then(result => { 
        console.log("SS",result)
        this.setState({
          photo: result.photo
        })
      })
      .catch(error => console.log('error2:', error));
  } 

  //Update image
  updateImage = () => {

    console.log("uploadedFile:", this.state.uploadedFile)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      data: this.state.uploadedFile,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(host + `/user/updateImage/${this.state.idUser}`, requestOptions)
      // fetch(host+`/user/updateImage/22`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "error") {
          console.log('objectErr',result );
          this.setState({
            color: true,
          });
        }
        this.fetchDataUse();
      })
      .catch((error) => {
         console.log("error", error);
      });
  };

  show = (item) => {
    this.setState({
      nom: item.nom,
      photo: item.photo,
      prenom: item.prenom,
      email: item.email,
      sexe: item.sexe,
      number: item.tel,
      number2: item.tel2,
      createdAt: item.createdAt,
      adresse: item.adresse,
      groupesangin: item.groupesangin,
      datenaissance: item.datenaissance,
      isDetail: true,
      idPatient: item.idPatient,
      idUser: item.idUser,
      index: item.index,
    });
  };

  handleOnclick = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleOk = () => {
    this.setState({
      isModalVisible: false,
      isDetail: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      isDetail: false,
    });
    this.setState({ email: "" });
    this.setState({ password: "" });
    this.setState({ sexe: "" });
    this.setState({ nom: "" });
    this.setState({ prenom: "" });
    this.setState({ number: "" });
    this.setState({ number2: "" });
    this.setState({ adresse: "" });
    this.setState({ datenaissance: null });
    this.setState({ groupesangin: "" });
    this.setState({ createdAt: "" });
  };

  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleChangeSexe = (e) => {
    this.setState({ sexe: e.target.value });
  };

  handleChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  handleChangeNom = (e) => {
    this.setState({ nom: e.target.value });
  };

  handleChangePrenom = (e) => {
    this.setState({ prenom: e.target.value });
  };

  handleChangeNumber = (e) => {
    this.setState({ number: e.target.value });
  };

  handleChangeAdresse = (e) => {
    this.setState({ adresse: e.target.value });
  };

  handleChangeSanguin = (e) => {
    this.setState({ groupesangin: e.target.value });
  };

  handleChangeNumber2 = (e) => {
    this.setState({ number2: e.target.value });
  };

  handleChangeDateNaissance = (e) => {
    this.setState({ datenaissance: e.target.value });
  };

  handleSubmit = (e) => {
    if (this.state.isUpdate) {
      this.setState({
        isLoading: true,
      });
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let raw = JSON.stringify({
        username: this.state.email,
        isActive: this.state.active,
        role: "PATIENT",
        patient: {
          id: this.state.idPatient,
          nom: this.state.nom,
          slug: this.state.prenom + "_" + this.state.nom,
          prenom: this.state.prenom,
          email: this.state.email,
          sexe: this.state.sexe,
          numtelephone1: this.state.number,
          numtelephone2: this.state.number2,
          adresse: this.state.adresse,
          datenaissance: this.state.datenaissance,
          groupesangin: this.state.groupesangin,
        },
      });

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + `/user/update/${this.state.idUser}`, requestOptions)
        .then((response) => {
          console.log(response);
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200) {
            return null;
          }
          return response.text();
        })
        .then((result) => {
          if (result == null) {
            this.setState({
              isLoading: false,
            });
            toast.error("L'email est deja utilisé");
          } else {
            console.log("retour", result);
            console.log("result", JSON.parse(result));
            const data = JSON.parse(result);
            this.setState({
              isLoading: false,
            });
            toast.success("Modification réalisé avec succès");
            this.handleCancel();
            window.location.reload();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          console.log("error", error);
          toast.error("Une erreure c'est produite");
        });
    } else {
      this.setState({
        isLoading: true,
      });
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let raw = JSON.stringify({
        username: this.state.email,
        password: this.state.password,
        role: "PATIENT",
        nom: this.state.nom,
        slug: this.state.prenom + "_" + this.state.nom,
        prenom: this.state.prenom,
        email: this.state.email,
        sexe: this.state.sexe,
        numtelephone1: this.state.number,
        numtelephone2: this.state.number2,
        adresse: this.state.adresse,
        datenaissance: this.state.datenaissance,
        groupesangin: this.state.groupesangin,
        isActive: this.state.active
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + "/user/register/", requestOptions)
        .then((response) => {
          console.log(response);
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status == 409) {
            return null;
          }
          return response.text();
        })
        .then((result) => {
          if (result == null) {
            this.setState({
              isLoading: false,
            });
            toast.error("L'email est deja utilisé");
          } else {
            console.log("retour", result);
            console.log("resultPATIENT:", JSON.parse(result));
            const data = JSON.parse(result);

            this.setState({
              isLoading: false,
            });
            toast.success("Insertion réalisé avec succes");
            this.setState({
              datatable: {
                columns: [...this.state.datatable.columns],
                rows: [
                  {
                    id: 0,
                    nom: this.state.nom,
                    slug: this.state.prenom + "_" + this.state.nom,
                    prenom: this.state.prenom,
                    email: this.state.email,
                    tel: this.state.number,
                    tel2: this.state.number2,
                    adresse: this.state.adresse,
                    biographie: this.state.biographie,
                    sexe: this.state.sexe,
                    groupesangin: this.state.groupesangin,
                    datenaissance: this.state.datenaissance,
                    user: null,
                    active: <Switch  checked={this.state.active}  disabled={this.state.disabled}/>
                  },
                  ...this.state.datatable.rows,
                ],
              },
            });
            this.handleCancel();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          console.log("error", error);
          toast.error("Une erreure c'est produite");
        });
    }

    e.preventDefault();
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(host + "/patient/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        return response.text();
      })
      .then((result) => {
        if (result == null) {
        } else {
          const data = JSON.parse(result);
          console.log("data", data);
          let items = [];
          for (let i = 0; data.length > i; i++) {
            if(data[i].user.isActive === true){
              // console.log("ok")
             this.setState({
               active: true
             })
           }
           else(
             this.setState({
               active: false
             })
            ) 
            items.push({
              id: data[i].id,
              nom: data[i].nom,
              slug: data[i].slug,
              prenom: data[i].prenom,
              numidnat: data[i].numidnat,
              datenaissance: data[i].datenaissance,
              datecreation: data[i].datecreation,
              complementadresse: data[i].complementadresse,
              groupesangin: data[i].groupesangin,
              tel: data[i].numtelephone1,
              tel2: data[i].numtelephone2,
              email: data[i].email,
              sexe: data[i].sexe,
              user: data[i].user,
              photo:data[i].user.photo,
              createdAt: data[i].user.createdAt,
              active: <Switch  checked={this.state.active}  disabled={this.state.disabled}/>,
              clickEvent: () =>
                this.show({
                  idPatient: data[i].id,
                  idUser: data[i].user.id,
                  index: i,
                  nom: data[i].nom,
                  slug: data[i].slug,
                  prenom: data[i].prenom,
                  numidnat: data[i].numidnat,
                  datenaissance: data[i].datenaissance,
                  datecreation: data[i].datecreation,
                  complementadresse: data[i].complementadresse,
                  adresse: data[i].adresse,
                  groupesangin: data[i].groupesangin,
                  tel: data[i].numtelephone1,
                  tel2: data[i].numtelephone2,
                  email: data[i].email,
                  sexe: data[i].sexe,
                  user: data[i].user,
                  photo:data[i].user.photo,
                  active: data[i].user.isActive,
                  createdAt: data[i].user.createdAt,
                }),
            });
          }
          console.log("items", items);
          this.setState({
            allData: items,
            datatable: {
              columns: [...this.state.datatable.columns],
              rows: items,
            },
          });
        }
      })
      .catch((error) => console.log("error", error))
      .then(() => this.setState({ isLoading: false }));
  }

  handleDelete = (e) => {
    this.setState({
      isDetail: false,
      isDelete: true,
    });
  };

  handleUpdate = (e) => {
    this.setState({
      isDetail: false,
      isUpdate: true,
      isModalVisible: true,
    });
  };

  handleOkDelete = () => {
    this.setState({
      isLoading: true,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(host + `/patient/delete/${this.state.idPatient}`, requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status == 200) {
          for (
            let index = 0;
            index < this.state.datatable.rows.length;
            index++
          ) {
            const item = this.state.datatable.rows[index];

            if (item.id == this.state.idPatient) {
              this.state.allData.splice(this.state.index, 1);
              // this.state.datatable.rows.splice(index,1);
              this.setState({
                allData: this.state.allData,
                datatable: {
                  columns: [...this.state.datatable.columns],
                  rows: this.state.datatable.rows,
                },
              });
              break;
            }
          }
          this.setState({ email: "" });
          this.setState({ password: "" });
          this.setState({ sexe: "" });
          this.setState({ nom: "" });
          this.setState({ prenom: "" });
          this.setState({ number: "" });
          this.setState({ number2: "" });
          this.setState({ adresse: "" });
          this.setState({ datenaissance: null });
          this.setState({ groupesangin: "" });
          this.setState({ createdAt: "" });
          this.setState({ idPs: null });
          this.setState({ idUser: null });
          this.setState({ index: null });
          toast.success("La suppression a été réalisé avec succès");

          this.setState({
            isDelete: false,
          });
        } else {
          toast.error("La suppression n'a pas été réalisé avec succès");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("La suppression n'a pas été réalisé avec succès" + error);
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  handleCancelDelete = () => {
    this.setState({
      isDelete: false,
    });
    this.setState({ email: "" });
    this.setState({ password: "" });
    this.setState({ sexe: "" });
    this.setState({ nom: "" });
    this.setState({ prenom: "" });
    this.setState({ number: "" });
    this.setState({ number2: "" });
    this.setState({ adresse: "" });
    this.setState({ datenaissance: null });
    this.setState({ groupesangin: "" });
    this.setState({ createdAt: "" });
    this.setState({ idPs: null });
    this.setState({ idUser: null });
    this.setState({ index: null });
  };
  handleSwitch = (e) => {
    // console.log("handleSwitch:", e)
    this.setState({
      active:e
    })
}

  render() {
    return (
      <>
        <SidebarNav />
        <ToastContainer />

        {/* Delete Modal */}
        <Modal
          title="Suppression du patient"
          visible={this.state.isDelete}
          okText="Confirmer"
          cancelText="Annuler"
          okType="danger"
          onOk={this.state.isLoading ? null : this.handleOkDelete}
          onCancel={this.state.isLoading ? null : this.handleCancelDelete}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <div className="form-content p-2">
            <h4 className="modal-title">
              Supprimer {this.state.prenom + " " + this.state.nom}
            </h4>
            <Loader
              style={{
                position: "absolute",
                textAlign: "center",
                marginLeft: "auto",
                left: "0",
                right: "0",
                marginRight: "auto",
                zIndex: "20px",
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            />
            <p className="mb-4">Confimer vous la suppression</p>
          </div>
        </Modal>

        {/* Delete Modal */}

        <Modal
          title="Détail du patient"
          visible={this.state.isDetail}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          destroyOnClose={true}
        >
          <div className="row ml-5" >
              <Image  height="90px" width="90px" alt="" src={this.state.photo ? this.state.photo  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} />
          </div> <br /> <br />
          <div className="row">
            <p className="col-sm-4 text-muted text-sm-right mb-0 mb-sm-4">
              Nom :
            </p>
            <p className="col-sm-8">{this.state.nom}</p>
          </div>
          <div className="row">
            <p className="col-sm-4 text-muted text-sm-right mb-0 mb-sm-4">
              Prenom :
            </p>
            <p className="col-sm-8">{this.state.prenom}</p>
          </div>
          <div className="row">
            <p className="col-sm-4 text-muted text-sm-right mb-0 mb-sm-4">
              Sexe :
            </p>
            <p className="col-sm-8">{this.state.sexe}</p>
            <p className="col-sm-4 text-muted text-sm-right mb-0 mb-sm-4">
            Compte active : 
            </p>
            <p className="col-sm-8">
                <Switch  checked={this.state.active}  disabled={this.state.disabled}/>
            </p>
           
            {/* <p className="col-sm-9">
           
            </p> */}
          </div>
          <div className="row">
            <p className="col-sm-4 text-muted text-sm-right mb-0 mb-sm-4">
              Groupe sanguin :
            </p>
            <p className="col-sm-8">{this.state.groupesangin}</p>
          </div>
          <div className="row">
            <p className="col-sm-4 text-muted text-sm-right mb-0 mb-sm-4">
              Née le:
            </p>
            <p className="col-sm-8">{this.state.datenaissance}</p>
          </div>
          <div className="row">
            <p className="col-sm-4 text-muted text-sm-right mb-0 mb-sm-4">
              Email :
            </p>
            <p className="col-sm-8">{this.state.email}</p>
          </div>
          <div className="row">
            <p className="col-sm-4 text-muted text-sm-right mb-0 mb-sm-4">
              Telephones :
            </p>
            <p className="col-sm-8">
              {this.state.number1
                ? "n°1 : " +
                  this.state.number +
                  " / n°2 : " +
                  this.state.number1
                : this.state.number}
            </p>
          </div>
          <div className="row">
            <p className="col-sm-4 text-muted text-sm-right mb-0 mb-sm-4">
              Adresse :
            </p>
            <p className="col-sm-8">{this.state.adresse}</p>
          </div>

          <div className="row">
            <div className="col-sm-5"></div>
            <div className="col-sm-7">
              <a
                className="btn btn-sm bg-success-light"
                onClick={this.handleUpdate}
              >
                <i className="fe fe-pencil"></i> Modifier
              </a>
              <a
                className="btn btn-sm bg-danger-light"
                onClick={this.handleDelete}
              >
                <i className="fe fe-trash"></i> Supprimer
              </a>
            </div>
          </div>
        </Modal>

        <Modal
          title={
            this.state.isUpdate
              ? "Modification du patient"
              : "Ajouter un patient"
          }
          visible={this.state.isModalVisible}
          onOk={this.state.isLoading ? null : this.handleOk}
          onCancel={this.state.isLoading ? null : this.handleCancel}
          footer={null}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <form form action="/" onSubmit={this.state.isLoading ? null : this.handleSubmit} id="addform">
            <div className="row form-row">
            <div className="col-12 col-md-12">
                            <div className="form-group">
                              <div className="change-avatar">
                                <div className="profile-img">
                                <Image
                                width={150}
                                src={
                                  this.state.photo ? this.state.photo : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                                  // this.state.imageProfile === null ||
                                  // this.state.imageProfile === "" ||
                                  // this.state.imageProfile === undefined
                                  //   ? IMG01
                                  //   : this.state.imageProfile
                                }
                                className="img-fluid"                             
                                alt=""
                                  />
                                </div>
                                <div className="upload-img">
                                  <div className="change-photo-btn">
                                    <span>
                                      <i className="fa fa-upload"></i> Importer
                                      une photo
                                    </span>
                                    <input
                                      type="file"
                                      className="upload"
                                      name="userImage"
                                       onChange={this.changeProfileImage}
                                    />
                                  </div>
                                  <small className="form-text text-muted">
                                    {" "}
                                    <strong
                                      className={
                                        this.state.color == true ? "etoile" : ""
                                      }
                                    >
                                      Format autorisé JPG, JPEG ou PNG. Taille
                                      max de 2MB
                                    </strong>{" "}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>Nom</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Nom"
                    name="nom"
                    value={this.state.nom}
                    disabled={this.state.isLoading}
                    onChange={this.handleChangeNom}
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>Prénom</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Prenom"
                    name="prenom"
                    value={this.state.prenom}
                    disabled={this.state.isLoading}
                    onChange={this.handleChangePrenom}
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>Sexe</label>
                  <div className="row">
                    <label
                      className="mr-5"
                      style={{
                        verticalAlign: "center",
                        marginLeft: "30px",
                        marginTop: "9px",
                      }}
                    >
                      <input
                        className="mr-1"
                        type="radio"
                        name="sexe"
                        id="sexe"
                        value="masculin"
                        disabled={this.state.isLoading}
                        onChange={this.handleChangeSexe}
                        required
                        checked={this.state.sexe == "masculin" ? true : false}
                      />
                      <span className="">H</span>
                    </label>
                    <label
                      className=""
                      style={{ verticalAlign: "center", marginTop: "9px" }}
                    >
                      <input
                        className="mr-1"
                        type="radio"
                        name="sexe"
                        id="sexe"
                        value="feminin"
                        disabled={this.state.isLoading}
                        onChange={this.handleChangeSexe}
                        required
                        checked={this.state.sexe == "feminin" ? true : false}
                      />
                      <span className="">F</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>Groupe sanguin</label>
                  <select
                    className="form-control"
                    type="text"
                    name="sanguin"
                    value={this.state.groupesangin}
                    disabled={this.state.isLoading}
                    onChange={this.handleChangeSanguin}
                    required
                  >
                    <option value="">Groupe sanguin</option>
                    <option>O(+)</option>
                    <option>O(-)</option>
                    <option>A(+)</option>
                    <option>A(-)</option>
                    <option>B(+)</option>
                    <option>B(-)</option>
                    <option>AB(+)</option>
                    <option>AB(-)</option>
                  </select>
                </div>
              </div>
              <div> <br /><br />
            <label className="col-12" style={{ verticalAlign: "center" }}>
            {/* onChange={this.handleSwitch} */}
              <span> Compte active :</span> <Switch checked={this.state.active}  onChange={this.handleSwitch} />
              </label>
              <br /><br />
            </div>


              <div className="col-12">
                <div className="form-group">
                  <label>Date de naissance</label>
                  <input
                    className="form-control"
                    type="date"
                    placeholder="date de naissance"
                    name="date"
                    value={this.state.datenaissance}
                    disabled={this.state.isLoading}
                    onChange={this.handleChangeDateNaissance}
                    required
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Addresse</label>
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder="Adresse"
                    name="adresse"
                    value={this.state.adresse}
                    disabled={this.state.isLoading}
                    onChange={this.handleChangeAdresse}
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>Telephone 1</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Numero de telephone 1"
                    name="number"
                    value={this.state.number}
                    disabled={this.state.isLoading}
                    onChange={this.handleChangeNumber}
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>Telephone 2 (optionnel)</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Numero de telephone 2"
                    name="number2"
                    value={this.state.number2}
                    disabled={this.state.isLoading}
                    onChange={this.handleChangeNumber2}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <Loader
                    style={{
                      position: "absolute",
                      textAlign: "center",
                      marginLeft: "auto",
                      left: "0",
                      right: "0",
                      marginRight: "auto",
                      zIndex: "200px",
                    }}
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                    visible={this.state.isLoading}
                  />
                  <label>Email</label>
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={this.state.email}
                    disabled={this.state.isLoading}
                    onChange={this.handleChangeEmail}
                    required
                  />
                </div>
              </div>

              {this.state.isUpdate ? (
                false
              ) : (
                <div className="col-12">
                  <div className="form-group">
                    <label>Mot de passe</label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Mot de passe"
                      name="password"
                      value={this.state.password}
                      disabled={this.state.isLoading}
                      onChange={this.handleChangePassword}
                      required
                    />
                  </div>
                </div>
              )}
            </div>
            <button className="btn btn-primary btn-block" type="submit" disabled={this.state.isLoading}>
              {this.state.isUpdate ? "Modifier" : "Ajouter"}
            </button>
          </form>
        </Modal>

        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Liste des patients</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admin">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#0">Utilisateur</Link>
                    </li>
                    <li className="breadcrumb-item active">Patient</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Button
                  className="btn btn-primary  mb-2"
                  type="primary"
                  size="large"
                  disabled={this.state.isLoading}
                  onClick={this.state.isLoading ? null : this.handleOnclick}
                >
                  AJOUTER
                </Button>
                <div className="pull-right">
                  <RangePicker
                    placeholder={["Du", "Au"]}
                    disabled={this.state.isLoading}
                    onCalendarChange={this.handleCalendar}
                  />
                </div>
                <div
                  className={
                    this.state.isLoading &&
                    !this.state.isDelete &&
                    !this.state.isUpdate
                      ? "page_wrapper_blur card"
                      : "card"
                  }
                >
                  <div className="card-body">
                    <div className="table">
                      <Loader
                        style={{
                          position: "absolute",
                          textAlign: "center",
                          marginLeft: "auto",
                          left: "0",
                          right: "0",
                          marginRight: "auto",
                          zIndex: "2000",
                        }}
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={
                          this.state.isLoading &&
                          !this.state.isDelete &&
                          !this.state.isUpdate
                        }
                      />
                      <MDBDataTableV5
                        entriesOptions={[10, 20, 30]}
                        entries={10}
                        responsive={true}
                        entriesLabel={"Lignes par pages"}
                        searchLabel={"Rechercher"}
                        infoLabel={["", "-", "/", ""]}
                        noRecordsFoundLabel={"Aucune données de correspond"}
                        data={this.state.datatable}
                        searchTop
                        searchBottom={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Patients;
