import React, { Component } from "react";
import loginBanner from "../../assets/images/login-banner.png";
// export default LoginContainer;
//icon
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ReactLoading from "react-loading";

import { Link } from "react-router-dom";
// import { withRouter } from "react-router";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// import Loader from "react-loader-spinner";
import FadeIn from "react-fade-in";
import host from "../../../host";
import { Input } from "antd";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Button } from 'antd';
// import {
//   isPossiblePhoneNumber,
//   isValidPhoneNumber,
//   validatePhoneNumberLength
// } from 'libphonenumber-js'
import Home from "../home";

//"jwt": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImlhdCI6MTYxMTA1OTIxMiwiZXhwIjoxNjEzNjUxMjEyfQ.6YK_BN5Y5mjz2QTEkN5FinFqLVa_wsZQiVRmdOUjpo0",
class LoginContainer extends Component {
  constructor(props) {
    super(props);
    // this.password = React.createRef();
    this.submit = this.submit.bind(this);
    this.state = {
      isLoading: false,
      loadings:false,
      redirectToReferre: false,
      preserveLogin: true,
      password: "",
      email:"",
      msgError: "",
      getUsername:"",
      getMail:"",
      getToken: "",
      istrue: false,
      showBtnSms: false
    };
  }

  componentDidMount() {
    let dbUser =
      JSON.parse(sessionStorage.getItem("user")) ||
      JSON.parse(sessionStorage.getItem("user"));
    if (dbUser != null || dbUser != undefined) {
      this.props.history.push("/home");
    }
    document.body.classList.add("account-page");
  }

  componentWillUnmount() {
    document.body.classList.remove("account-page");
  }

  // enterLoading = () =>{
  //   this.setState({
  //     loadings: true
  //   })
  //   alert("salut")
     
  //   }
  // Soumission du formulaire du login
  submit(event) {
    event.preventDefault();
    this.setState({
      loadings: true
    })
    let email = this.state.email;    
          
    var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "username": this.state.email,
          "password": this.state.password
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

  fetch(host+"/auth/login", requestOptions)
        .then(response => {
          if(response.status === 200){
            return response.json() 
          }
          if(response.status === 401){
            // toast.warn("Identifiant incorrect")
            this.setState({
              msgError: "Identifiant ou mot de passe incorrect",
              loadings: false
            })
          }
          if(response.status === 403){
            // toast.warn("Votre compte n'est pas encore activé, vueillez consulter votre boite mail")
            this.setState({
              showBtnSms: true,
              msgError: "Votre compte n'est pas encore activé.",
              loadings: false
            })
          }

        })
        .then(result => {
          if (result.patient !== null) {
            window.location.assign("/patient/dashboard");
            sessionStorage.setItem(
              "user",
              JSON.stringify({
                token: result.token,
                userId: result.userId,
                user: result,
                state: true,
              })
            );
          }
          if (result.professionnelsante !== null) {
                    window.location.assign("/doctor/doctor-dashboard");

                    sessionStorage.setItem(
                      "user",
                      JSON.stringify({
                        token: result.token,
                        userId: result.userId,
                        user: result,
                        state: true,
                      })
                    );
                    
                  }
                  if (result.gestionnairecentre && result.gestionnairecentre !== null) {
                            window.location.assign("/gestionnaire/dashboard");
                            sessionStorage.setItem(
                              "user",
                              JSON.stringify({
                                token: result.token,
                                userId: result.userId,
                                user: result,
                                state: true,
                              })
                            );
                          }

        })
        .catch(error =>{
          //  console.log('error', error)
            //  toast.warn("Erreur produite")
            this.setState({
              loadings: false
            })
          });
  }

  // l'envoi du code d'activation par SMS
  resendSms = () => {
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");        
        var raw = JSON.stringify({
        "numtel1": this.state.email
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

    fetch(host+"/user/sendcode", requestOptions)
    .then(response =>{ 
        response.text()
        if (response.status === 200) {
            toast.success("le code à été renvoyé avec succès.")
            setTimeout(() => {
              window.location.assign("/validation");
            }, 2000);
        }
        if(response.status === 201){
          toast.success("votre compte est déja actif.")
        }
        if(response.status === 417){
          toast.warn("erreur, Veuillez réessayer dans un instant.")
        }
    })
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
  }

  //l'envoi du lien d'activation de compte par mail
   resendMail = () =>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      username: this.state.getUsername,
      email: this.state.getMail,
      token: this.state.getToken
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(host+"/mail/welcome", requestOptions)
      .then(response => {
        // response.json()
        if(response.status === 200){
          toast.success("le mail à été renvoyé avec succès !");
          window.location.assign("/checkMail");
        }
        window.location.assign("/checkMail");
      })
      .catch(error => {
        // console.log('error', error)
        toast.warn("echec d'envoi")
      });
}

  // l'envoi du code ou lien 
  sendCode = () => {
    this.setState({
      loadings: true
    })
    const regNumberPhone = /^[0-9]+$/;
    if(regNumberPhone.test(this.state.email) || this.state.email[0] == '+' ){   
      // alert(this.state.email+'num')
       var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
          "dest": this.state.email
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(host+"/user/resend", requestOptions)
          .then(response => {
            if (response.status === 200) {
             return  response.json() 
            }
            if (response.status === 404) {
              toast.warn("erreur de l'identifiant saisi, il n'existe pas.")
              this.setState({
                loadings: false
              })
            }
          })
          .then(result => {
            // console.log( 'RRR:', result.data.userId)
            sessionStorage.setItem(
              "userPhone",
              JSON.stringify({
                phone: this.state.email,
                id: result.data.userId   
              })
            );
            this.resendSms();
            this.setState({
              loadings: false
            })
          })
          .catch(error => {
            // console.log('error', error)
            toast.warn("une erreur s'est produite lors de l'envoi de l'sms.")
            this.setState({
              loadings: false
            })
          });

      
    }
    else{
      //Quand il s'agit de l'email, on envoi  du lien

      // alert(this.state.email)
       var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "dest": this.state.email 
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        fetch(host+"/user/resend", requestOptions)
          .then(response => {
            if (response.status === 200) {
             return  response.json() 
            }
            if (response.status === 404) {
              toast.warn("erreur de l'identifiant saisi, il n'existe pas.")
              this.setState({
                loadings: false
              })
            }
          })
          .then(result => {
            // console.log( 'RRR:', result.data)
            this.setState({
              loadings: false
            })
            if(result.data.patient !== null){
              this.setState({
                getUsername: result.data.patient.nom + " " + result.data.patient.prenom,
                getMail: result.data.username,
                getToken: result.data.token
              })
              sessionStorage.setItem(
                "userInfo",
                JSON.stringify({
                  username: result.data.patient.nom + " " + result.data.patient.prenom,
                  email: result.data.username,
                  token: result.data.token,
                  state: true,
                })
              );
              this.resendMail();
            }
            if(result.data.professionnelsante !== null){
              this.setState({
                loadings: false
              })
              this.setState({
                getUsername: result.data.professionnelsante.nom + " " + result.data.professionnelsante.prenom,
                getMail: result.data.username,
                getToken: result.data.token
              })
              sessionStorage.setItem(
                "userInfo",
                JSON.stringify({
                  username: result.data.professionnelsante.nom + " " + result.data.professionnelsante.prenom,
                  email: result.data.username,
                  token: result.data.token,
                  state: true,
                })
              );
              this.resendMail()
            }
            if(result.data.gestionnairecentre !== null){
              this.setState({
                loadings: false
              })
              this.setState({
                getUsername: result.data.gestionnairecentre.nom + " " + result.data.gestionnairecentre.prenom,
                getMail: result.data.username,
                getToken: result.data.token
              })
              sessionStorage.setItem(
                "userInfo",
                JSON.stringify({
                  username: result.data.gestionnairecentre.nom + " " + result.data.gestionnairecentre.prenom,
                  email: result.data.username,
                  token: result.data.token,
                  state: true,
                })
              );
              this.resendMail()
            }
            
            
            // this.resendSms();
          })
          .catch(error => {
            // console.log('error:', error)
            toast.warn("une erreur s'est produite lors de l'envoi de l'email.")
            this.setState({
              loadings: false
            })
          });
    
    }
  }

  render() {
    /*
        const {redirectToReferrer} = this.state;
        if(redirectToReferrer) {
          return <Redirect to="/home" />
        }
        */
    //{redirectToReferrer ? <Redirect to="/home" /> : null}
    return (
      <React.Fragment>
        <div
          className="content">
          <ToastContainer />
          <div
            className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="account-content">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-md-7 col-lg-6 login-left">
                      <img
                        src={loginBanner}
                        className="img-fluid"
                        alt="Doccure Login"
                      />
                    </div>
                    <div className="col-md-12 col-lg-6 login-right">
                      <div className="login-header">
                        <h1 style={{ fontSize: "25px" }}>Se connecter</h1>
                        <br />
                        <em className="etoile text-center"><strong>{this.state.msgError} </strong></em>
                      </div>
                      <form onSubmit={this.submit}>
                        <FadeIn>
                          <div className="form-group" style={{ margin: "0" }}>
                            
                            <label
                              className="focus-label"
                              htmlFor="email"
                            ></label>
                          </div>
                          <div className="form-group" style={{ margin: "0" }}>
                            {
                              this.state.istrue ?
                              <PhoneInput
                                className="form-control"
                                country={'cg'}
                                value={this.state.email}
                                // onChange={(e) => {
                                //   this.setState({ phone1 });
                                // }}
                                onChange={email => {
                                  this.setState({ email })
                                  const regNumberPhone = /^[0-9]/;
                                  // this.setState({
                                  //   msgError: ""
                                  // })
                                  
                                    if(email == ""){
                                      this.setState({
                                        istrue: false,
                                        msgError: ""
                                    })
                                    }
                                    
                                  
                                }}
                                inputProps={{
                                  name: 'Numéro de téléphone',
                                  required: true,
                                
                                }}
                              /> :
                              <>
                                  <input
                                  type="text"
                                  name="email"
                                  required="true"
                                  value={this.state.email}
                                  onChange={(e) =>{
                                    this.setState({email: e.target.value})
                                    const regNumberPhone = /^[0-9]/;
                                  if(regNumberPhone.test(e.target.value) || e.target.value[0] == '+' ){
                                    
                                    this.setState({
                                        istrue: true,
                                        msgError: ""
                                    })
                                  }
                                } }
                              className="form-control"
                              placeholder="Votre adresse mail ou numéro de téléphone"
                            />
                              </>
                     }
                            <br /><br />
                            {
                             this.state.showBtnSms ?
                             ""
                              :
                              <Input.Password
                              type="password"
                              name="password"
                              required="true"
                              // ref={this.password}
                              onChange={(e) => {
                                this.setState({ password: e.target.value, msgError: "" }
                                 
                                  );
                              }}
                              className="form-control"
                              // name="password"
                              placeholder="Votre mot de passe"
                            />
                            
                            
                            }
                             
                            
                          </div>
                          <br />
                          <div className="text-right">
                            <Link to="/forgot-password" className="forgot-link">
                              Mot de passe oublié ?
                            </Link>
                          </div>                          
                          <br />
                          {
                            this.state.showBtnSms ?
                          //   <button onClick={this.sendCode}
                          //   className="btn btn-bluefonce pull-left"
                          //   type="submit"
                          // >
                          //   renvoyé le code/lien d'activation
                          // </button> 
                              <Button type="submit" className="btn btn-bluefonce pull-left" loading={this.state.loadings} onClick={this.sendCode}>
                               renvoyé le code/lien d'activation
                              </Button>
                          
                          :
                        //   <button
                        //   className="btn btn-bluefonce pull-right"
                        //   type="submit"
                        // >
                        //  {this.state.isLoading  ? "chargement..." : " Se connecter"}
                        // </button>    
                        <Button type="submit" className="btn btn-bluefonce pull-right" loading={this.state.loadings} onClick={this.submit}>
                            Se connecter
                          </Button>                

                          }
                          <br /><br />
                          
                          <div className="text-center dont-have">
                            Vous n'avez pas de compte?
                            <Link
                              to="/register"
                              style={{ color: "rgb(21, 85, 141)" }}
                            >
                             
                              'S'inscrire
                            </Link>
                          </div>
                        </FadeIn>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginContainer;
