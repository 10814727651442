import React, { Component } from "react";
import DoctorSidebar from "../sidebar";
import Slot from "./slot";
import { Link } from "react-router-dom";
import { Tab, Tabs, Modal, Button } from "react-bootstrap";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Calendar from "react-calendar";
import FullCalendar, { identity } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./time.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Moment from "moment";
import { extendMoment } from "moment-range";
import Loader from "react-loader-spinner";
import GestionnaireSidebar from "../sidebar";
import host from "../../../../host";

const moment = extendMoment(Moment);
const ModalAntd = require("antd").Modal;
let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

function unique(arr) {
  var u = {},
    a = [];
  for (var i = 0, l = arr.length; i < l; ++i) {
    if (!u.hasOwnProperty(arr[i])) {
      a.push(arr[i]);
      u[arr[i]] = 1;
    }
  }
  return a;
}

const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: "All-day event",
    start: todayStr,
  },
  {
    id: createEventId(),
    title: "Timed event",
    start: todayStr + "T12:00:00",
  },
];

function createEventId() {
  return String(eventGuid++);
}
const dureeCreneau = [
  {
    label: "15 min",
    value: "15",
  },
  {
    label: "30 min",
    value: "30",
  },
  {
    label: "45 min",
    value: "45",
  },
  {
    label: "60 min",
    value: "60",
  },
];
const selectPlages = [
  {
    label: "plage de créneau",
    value: "plage",
  },
  {
    label: "un créneau",
    value: "un",
  },
];

class Creneau extends Component {
  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  handleShow = (e) => {
    e.preventDefault();
    this.setState({
      show: true,
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showButton: false,
      selectInfo: null,
      heureFin: null,
      heureDebut: null,
      creneaux: [],
      isLoading: false,
      weekend: [],
      lundi: false,
      mardi: false,
      mercredi: false,
      jeudi: false,
      vendredi: false,
      samedi: false,
      dimanche: false,
      events: [],
      centre: [],
      creneauClicker: [],
      prestationCentre: [],
      centreSelected: "",
      prestation: "Toutes les prestations",
      idprestation: 0,
      centreEntier: 0,
      prestaEntier: 0,
      isModalVisible: false,
      isModalVisibleAdd: false
    };
    this.dateDebut = React.createRef();
    this.dateFin = React.createRef();
    this.heureDebut = React.createRef();
    this.heureFin = React.createRef();
    this.dureeCreneau = React.createRef();
  }



  showModal = () => {
    this.setState({
     isModalVisible: true,
    })
   };


  handleOk = () => {
    this.setState({
      isModalVisible: false,
     })
  }

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
     })
  };
    //Part of delete creneau

    showModalAdd = () => {
      this.setState({
        isModalVisibleAdd: true,
      })
     };
     deleteCreneau = () => {
     
    };
  
    handleOkAdd = (id) => {
      // this.setState({
      //   isModalVisibleAdd: false,
      //  })
      // alert(id)
      if (id) {
        // this.setState({
        //   isLoading: true,
        // });
        let myHeaders = new Headers();
        let raw = "";
        let requestOptions = {
          method: "DELETE",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
  
        fetch(
          host + "/creneau/delete/" + id,
          requestOptions
        )
          .then((response) => {
            if (response.status !== 200 && response.status !== 201) {
              return null;
            }
            return response.json();
          })
          .then((result) => {
            if (result) {
                toast.success("Suppression réalisé avec succès");
                let events = this.state.events;
                console.log("__________1", events);
                for (let index = 0; index < events.length; index++) {
                  const element = events[index];
                  if (element.extendedProps.data) {
                    if (
                      element.extendedProps.data.idcreneau === id
                    ) {
                      events.splice(index, 1);
                      break;
                    }
                  }
                }
                console.log("__________2", events);
    
                this.setState({
                  events: [...events],
                });
              
             this.setState({
              isModalVisibleAdd: false,
             })
             
              // window.location.reload()
            } else {
              toast.error("Erreur lors de la suppression");
              this.setState({
                isModalVisibleAdd: false,
               })
            }
          })
          .catch((error) => {
            toast.error("Erreur lors de la suppression");
          })
          .then(() => {
            this.setState({
              isModalVisibleAdd: false,
             })
          });
      }
    }
  
    handleCancelAdd = () => {
      this.setState({
        isModalVisibleAdd: false,
       })
    };



  showUpdate = (creneauSelected) => {
    // console.log("ID_Lombre:", id)
    this.setState({
        creneauClicker: creneauSelected
    })
    this.showModal()
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const data = dataJson && JSON.parse(dataJson);
    let URL =
      host +
      `/gestionnairecentre/${data.user.gestionnairecentre.idgestionnairecentre}`;

    let response = await fetch(URL);
    let centre = (await response.json()).gestionnaire.centre;
    let datas = centre[0].creneaux;
    let events = this.state.events;


    // for (let index = 0; index < this.state.centre.length; index++) {
    //   const centre = this.state.centre[index];
    //   // if (centre.idcentre == this.state.idLanda) {
    //   if (centre.idcentre == this.state.centreSelected) {
    //     let centres = [...this.state.centre];
    //     // centres[index].creneaux.push(_api.creneau);
    //     this.setState({
    //       // centre: [...centres]
    //     }, ()=>{
    //       // centre.creneaux.push(_api.creneau)
    //       centre.creneaux.forEach((data) => {
    //         console.log("data1:", data);
    //         let title =
    //           data.heuredebut.substring(0, 5) +
    //           "-" +
    //           data.heurefin.substring(0, 5);
    //         events.push({
    //           title: title,
    //           extendedProps: {
    //             data: centre,
    //           },
    //           date: data.datecreneau,
    //           color: data.reserve ? "red" : "green",
    //         });
    //       });
    //       this.setState({
    //         events: events,
    //         isLoading: false,
    //       });
    //     });
        
    //   }
    // }
    
    // datas.forEach((data) => {
    //   let title =
    //     data.heuredebut.substring(0, 5) + "-" + data.heurefin.substring(0, 5);
    //   events.push({
    //     title: title,
    //     extendedProps: {
    //       data: data,
    //     },
    //     date: data.datecreneau,
    //     color: data.reserve ? "red" : "green",
    //   });
    // });

    this.setState({
       events: events,
      isLoading: false,
      centre: centre,
    });
  }

  saveCreneaux = async (arg) => {
    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const user = dataJson && JSON.parse(dataJson);

    this.setState({
      isLoading: true,
    });
    let URL = host + "/creneau/create";
    let start = this.state.selectInfo.startStr;
    let end = this.state.selectInfo.endStr;
    let a = moment(start);
    let b = moment(end);
    let diff = b.diff(a, "days");
    let _apiCreneaux = [];
    let status = 0;

    let dayOfBreak = null;

    this.state.creneaux.forEach(async (data) => {
      let current = a;
      let doctorGlob = null;
      for (let index = 0; index < diff; index++) {
        let _current = current.add(index == 0 ? 0 : 1, "days");
        let _date = _current.format("YYYY-MM-DD").toString();
        let response = await fetch(URL, {
          headers: {
            "Content-type": "application/json",
          },
          method: "POST",

          body: JSON.stringify({
            idcentre: this.state.centreSelected,
            idprestation: this.state.idprestation,
            heuredebut: data.heureDebut + ":00:00",
            heurefin: data.heureFin + ":00:00",
            datecreneau: _date,
          }),
        });
        if (true) {
          let _api = await response.json();
          console.log('_api :>> ', _api);
          let events = [];
          this.setState({
            events: [],
            isLoading: false,
          });
          for (let index = 0; index < this.state.centre.length; index++) {
            const centre = this.state.centre[index];
            // if (centre.idcentre == this.state.idLanda) {
            if (centre.idcentre == this.state.centreSelected) {
              let centres = [...this.state.centre];
              centres[index].creneaux.push(_api.creneau);
              this.setState({
                centre: [...centres]
              }, ()=>{
                // centre.creneaux.push(_api.creneau)
                centre.creneaux.forEach((data) => {
                  console.log("data1:", data);
                  let title =
                    data.heuredebut.substring(0, 5) +
                    "-" +
                    data.heurefin.substring(0, 5);
                  events.push({
                    title: title,
                    extendedProps: {
                      data: "_api",
                    },
                    date: data.datecreneau,
                    color: data.reserve ? "red" : "green",
                  });
                });
                this.setState({
                  events: events,
                  isLoading: false,
                });
              });
              
            }
          }
          // _apiCreneaux.push(_api);
          // status = 200;
        }
      }

      // if (true) {
      //   let calendarApi = this.state.selectInfo.view.calendar;
      //   let title = this.state.creneaux.map((params) => {
      //     return params.heureDebut + "-" + params.heureFin + " ";
      //   });
      //   let a = moment(this.state.selectInfo.startStr);
      //   let b = moment(this.state.selectInfo.endStr);
      //   let diff = b.diff(a, "days");
      //   let current = a;
      //   for (let index = 0; index < diff; index++) {
      //     let _current = current.add(index == 0 ? 0 : 1, "days");
      //     let _date = _current.format("YYYY-MM-DD").toString();
      //     if (_date == dayOfBreak) {
      //       break;
      //     }
      //     calendarApi.addEvent({
      //       id: createEventId(),
      //       title,
      //       start: _date,
      //       end: _date,
      //       allDay: this.state.selectInfo.allDay,
      //       color: "green",
      //     });
      //     if (index == 0) {
      //       dayOfBreak = _date;
      //     }
      //   }
      // }
      this.setState({
        isLoading: false,
        // prestation: "Toutes les prestations",
        // creneaux: [],
        // idprestation:0
      });
    });
  };

  submit = (arg) => {
    if (this.state.heureFin == null || this.state.heureDebut == null) {
      toast.error("Veuillez indiquer l'heure de début et l'heure de fin !");
    } else if (this.state.idprestation == 0) {
      toast.warning("Veuillez sélectionnez une prestations !");
    } else {
      let creneaux = this.state.creneaux;
      let _h1 = moment({
        hour: this.state.heureDebut.split(":")[0],
        minute: this.state.heureDebut.split(":")[1],
      });

      let _h2 = moment({
        hour: this.state.heureFin.split(":")[0],
        minute: this.state.heureFin.split(":")[1],
      });
      let canApply = true;

      creneaux.forEach((creneau) => {
        let h1 = moment({
          hour: creneau.heureDebut.split(":")[0],
          minute: creneau.heureDebut.split(":")[1],
        });

        let h2 = moment({
          hour: creneau.heureFin.split(":")[0],
          minute: creneau.heureFin.split(":")[1],
        });

        if (
          _h1.isBetween(h1, h2) ||
          _h2.isBetween(h1, h2) ||
          _h1.isSame(h1) ||
          _h2.isSame(h2)
        ) {
          canApply = false;
        }
      });

      if (canApply) {
        creneaux.push({
          id: new Date().getTime(),
          dateDebut: this.state.selectInfo.startStr,
          dateFin: this.state.selectInfo.endStr,
          heureFin: this.state.heureFin,
          heureDebut: this.state.heureDebut,
        });
      } else {
        toast.error("ce créneau est en conflit avec un autre dejà existant");
      }

      this.setState({
        creneaux,
        show: false,
        heureDebut: null,
        heureFin: null,
      });
      let calendarApi = this.state.selectInfo.view.calendar;

      calendarApi.select(
        this.state.selectInfo.startStr,
        this.state.selectInfo.endStr
      );
      this.saveCreneaux();
    }
  };

  changeHeureDebut = (arg) => {
    var date = moment(arg.target.value, "HH:mm")
      .add(this.dureeCreneau.current.value, "minutes")
      .format("HH:mm");

    this.setState({
      heureFin: date,
      heureDebut: arg.target.value,
    });
  };

  changeBox = (event) => {
    let tab = this.state.weekend;
    if (tab.length < 6) {
      if (tab.includes(parseInt(event.target.value))) {
        const index = tab.indexOf(parseInt(event.target.value));
        if (index > -1) {
          tab.splice(index, 1);
        }
      } else {
        tab.push(parseInt(event.target.value));
      }
      this.setState({
        weekend: tab,
      });
    } else {
      toast.error("Vous avez atteint le nombre maximun de jours de congé  !");
    }
  };

  deleteItem = (arg) => {
    let items = [];
    this.state.creneaux.forEach((item) => {
      if (item.id != arg) {
        items.push(item);
      }
    });

    this.setState({
      creneaux: items,
    });
  };

  delete = () => {
    alert("ahhhhhhhh")
  }

  handleDateClick = (arg, b) => {
    if (arg.event._def.ui.backgroundColor == "red") {
      const data =  arg.event.extendedProps.data;
      console.log("CRNX-ROUGE:",data)
           this.showUpdate( arg.event.extendedProps.data)
    } else {
      const data =  arg.event.extendedProps.data;
      console.log("CRNX-VERT:",data)
            this.setState({
              creneauClicker: data
            })
            this.showModalAdd()
    }
  };

  handleUnselect = (arg) => {
    //   toast.error("Vous avez supprimé votre plage de date")
    this.setState({
      //selectInfo: null,
      // showButton: false
    });
  };

  handleDateSelect = (selectInfo) => {
    //toast.warn("Vous avez sélectionné une plage de date, veuillez attribuer un créneau pour terminer l'opération ")
    this.setState({
      selectInfo: selectInfo,
      showButton: true,
    });
  };

  //Choose centre for display créneaux
  handleChangeCentreCreneau = (e) => {
    let creneaux = [];
    // let datas = creneaux;
    // let events = this.state.events;
    let events = [];
    this.setState({
      events: events,
      isLoading: false,
      idLanda: e.target.value
    });
    for (let index = 0; index < this.state.centre.length; index++) {
      const centre = this.state.centre[index];
      if (centre.idcentre == e.target.value) {
        console.log("handleChangeCentreCreneau:", centre);
        creneaux.push(centre.creneaux);
        centre.creneaux.forEach((data) => {
          console.log("data:", data);
          let title =
            data.heuredebut.substring(0, 5) +
            "-" +
            data.heurefin.substring(0, 5);
          events.push({
            title: title,
            extendedProps:{
              data: data
            },
            date: data.datecreneau,
            color: data.reserve ? "red" : "green",
          });
        });
        this.setState({
          events: events,
          isLoading: false,
        });
      }
    }
  };

  //Choose a center
  handleChangeCentre = (e) => {
    this.setState({
      centreSelected: e.target.value,
    });
    for (let index = 0; index < this.state.centre.length; index++) {
      const centre = this.state.centre[index];
      if (centre.idcentre == e.target.value) {
        this.setState({
          prestationCentre: centre.prestations,
        });
      }
    }
  };

  async handleChange(e, input) {
    let presta = 0;
    console.log(e.target.value, "value");
    if (input == "presta") {
      this.setState({
        idprestation: e.target.value,
      });

      // if (e.target.value !== "Toutes les prestations") {
      //   let prestaResponse = await fetch(
      //     host + `/prestations/${e.target.value}`
      //   );

      //   if (prestaResponse.status == 200) {
      //     let data = await prestaResponse.json();
      //     presta = data;
      //   }
      //   this.setState({
      //     prestation: presta.libelle,
      //     prestaEntier: presta,
      //   });
      //   console.log(this.state.prestation);
      // } else {
      //   this.setState({
      //     prestation: "Toutes les prestations",
      //     prestaEntier: 0,
      //   });
      // }
    }
  }
  render() {

      // console.log("---creneauClicker--",this.state.creneauClicker.idprestation.libelle)
      console.log("---creneauClicker--",this.state.creneauClicker)

    return (
      <div style={{ marginTop: "4%" }}>
        <ToastContainer />

         {/* //Modal Modifier  centre */}
         <ModalAntd title="Détail créneau occupé" visible={this.state.isModalVisible} cancelText="Annuler" okText="valider" onOk={this.handleOk} onCancel={this.handleCancel}>
            
                      <div>
                              <span> <strong>Horaire : </strong>  {this.state.creneauClicker.heuredebut + " - " +  this.state.creneauClicker.heurefin } </span><br />
                      </div><br />
                      <div>
                          <span> <strong>Prestation : </strong> {this.state.creneauClicker.patient && this.state.creneauClicker.idprestation.libelle} </span><br />
                      </div><br />
 
                      <div>
                          <span> réservé par: {this.state.creneauClicker.patient && this.state.creneauClicker.patient.nom +" "+ this.state.creneauClicker.patient.prenom}   </span><br />
                      </div> <br /><br />
                
                       <div>
                              <span> <strong>Contact : </strong>  </span><br /><br />
                              <span> Numero téléphone:{this.state.creneauClicker.patient && this.state.creneauClicker.patient.numtelephone1}  </span><br />
                              <span> E-mail:{this.state.creneauClicker.patient && this.state.creneauClicker.patient.email}  </span><br />
                      </div><br />
                      
                    
              

            
            
            
            
              {/* <form action="">
                <div>
                    <label htmlFor="adresse">adresse </label><br />
                </div><br />
                <div>
                    <label htmlFor="complementadresse">complementadresse</label><br />
                </div><br />
                <div>
                    <label htmlFor="tel1">Téléphone 1</label><br />
                </div><br />
                <div>
                    <label htmlFor="tel2">Téléphone 2</label><br />
                </div>
              </form> */}
         </ModalAntd>

          {/* //Modal Modifier  centre */}
          <ModalAntd title="Détail créneau disponible" visible={this.state.isModalVisibleAdd} cancelText="Annuler" okText="Supprimé" onOk={() => this.handleOkAdd(this.state.creneauClicker.idcreneau)} onCancel={this.handleCancelAdd}>

                       <div>
                              <span> <strong>Horaire : </strong>  {this.state.creneauClicker.heuredebut + " - " +  this.state.creneauClicker.heurefin } </span><br />
                      </div><br />
                      <div>
                          <span> <strong>Prestation : </strong> {this.state.creneauClicker.idprestation && this.state.creneauClicker.idprestation.libelle} </span><br />
                      </div><br />           
                
     </ModalAntd>


        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-lg-3  col-sm-12">
                <GestionnaireSidebar />
              </div>
              <div
                className={
                  this.state.isLoading
                    ? "page_wrapper_blur col-md-6 col-lg-6 col-sm-12 card "
                    : "col-md-6 col-lg-6 card p-5"
                }
              >
                <div style={{ display: "flex" }}>
                  <div
                    className="form-group"
                    style={{ marginLeft: "2%", width: "100%" }}
                  >
                    <div>Choisir un centre</div>
                    <select
                      id="lang"
                      onChange={(e) => this.handleChangeCentreCreneau(e)}
                      // value={this.state.idprestation}
                      className="form-control"
                    >
                      <option value="0">Sélectionner un centre</option>
                      {this.state.centre.map((centre) => (
                        <option value={centre.idcentre}>
                          {centre.libelle}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <FullCalendar
                  events={[...this.state.events]}
                  hiddenDays={this.state.weekend}
                  locale="fr"
                  locales="fr"
                  validRange={{
                    start: new Date().toISOString().slice(0, 10),
                  }}
                  buttonText={{
                    today: "Aujourd'hui",
                    month: "Mois",
                    week: "Semaine",
                    day: "Jour",
                    list: "Liste",
                  }}
                  firstDay={1}
                  editable={true}
                  droppable={false}
                  dragScroll={false}
                  unselect={this.handleUnselect}
                  selectable={true}
                  select={this.handleDateSelect}
                  eventClick={this.handleDateClick}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                />
              </div>
              <div className="col-md-2 col-lg-2 card ml-3  p-2">
                <h3>Gérer mes disponibilités</h3>

                <form className="form" style={{ paddingTop: "10%" }}>
                  <div className="form-group">
                    <label htmlFor="durée">Durée du créneau</label>
                    <select
                      ref={this.dureeCreneau}
                      className="form-control"
                      disabled={!this.state.showButton}
                    >
                      <option value="15">15 minutes</option>
                      <option value="30">30 minutes</option>
                      <option value="60">1 heure</option>
                      <option value="90">1 h 30 minutes</option>

                      <option value="120">2 heures</option>
                    </select>
                  </div>

                  {this.state.showButton && (
                    <React.Fragment>
                      {" "}
                      <button
                        className="btn btn-bluefonce"
                        onClick={this.handleShow}
                      >
                        Ajouter
                      </button>
                      <Modal show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Ajouter un créneau</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form className="form">
                            <div style={{ display: "flex" }}>
                              <div
                                className="form-group"
                                style={{ marginLeft: "2%", width: "100%" }}
                              >
                                <label htmlFor="dateDebut">
                                  &nbsp;Date de début
                                </label>
                                <input
                                  id="dateDebut"
                                  value={
                                    this.state.selectInfo != null
                                      ? this.state.selectInfo.startStr
                                      : ""
                                  }
                                  ref={this.dateDebut}
                                  className="form-control"
                                  style={{ margin: "2%" }}
                                  type="date"
                                />
                              </div>
                              <div
                                className="form-group"
                                style={{ marginLeft: "2%", width: "100%" }}
                              >
                                <label htmlFor="dateFin">
                                  &nbsp;Date de fin
                                </label>
                                <input
                                  id="dateFin"
                                  value={
                                    this.state.selectInfo != null
                                      ? this.state.selectInfo.endStr
                                      : ""
                                  }
                                  ref={this.dateDebut}
                                  ref={this.dateFin}
                                  className="form-control"
                                  style={{ margin: "2%" }}
                                  type="date"
                                />
                              </div>
                            </div>

                            <div style={{ display: "flex" }}>
                              <div
                                className="form-group"
                                style={{ marginLeft: "2%", width: "100%" }}
                              >
                                <label htmlFor="heureDebut">
                                  &nbsp;Heure de début
                                </label>
                                <input
                                  id="heureDebut"
                                  onChange={this.changeHeureDebut}
                                  value={this.state.heureDebut}
                                  className="form-control"
                                  style={{ margin: "2%" }}
                                  type="time"
                                />
                              </div>
                              <div
                                className="form-group"
                                style={{
                                  marginLeft: "2%",
                                  width: "100%",
                                  float: "ri",
                                }}
                              >
                                <label htmlFor="heureFin">
                                  &nbsp;Heure de fin
                                </label>
                                <input
                                  id="heureFin"
                                  value={this.state.heureFin}
                                  ref={this.HeureFin}
                                  className="form-control"
                                  style={{ margin: "2%" }}
                                  type="time"
                                />
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div
                                className="form-group"
                                style={{ marginLeft: "2%", width: "100%" }}
                              >
                                <div>Choisir un centre</div>
                                <select
                                  id="lang"
                                  onChange={(e) => this.handleChangeCentre(e)}
                                  // value={this.state.idprestation}
                                  className="form-control"
                                >
                                  <option value="0">
                                    Sélectionner un centre
                                  </option>
                                  {this.state.centre.map((centre) => (
                                    <option value={centre.idcentre}>
                                      {centre.libelle}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div style={{ display: "flex" }}>
                              <div
                                className="form-group"
                                style={{ marginLeft: "2%", width: "100%" }}
                              >
                                <div>Choisir une prestation</div>
                                <select
                                  id="lang"
                                  onChange={(e) =>
                                    this.handleChange(e, "presta")
                                  }
                                  // value={this.state.idprestation}
                                  className="form-control"
                                >
                                  <option value="0">
                                    Sélectionner une prestation
                                  </option>
                                  {this.state.prestationCentre.map((presta) => (
                                    <option value={presta.id}>
                                      {presta.libelle}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </form>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            className="btn btn-bluefonce"
                            onClick={this.submit}
                          >
                            Ajouter
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </React.Fragment>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Loader
            style={{
              position: "absolute",
              marginTop: "-30%",
              zIndex: 100,
            }}
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            visible={this.state.isLoading}
          />
        </div>
      </div>
    );
  }
}

export default Creneau;
