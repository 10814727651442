import React, {Component} from 'react';
import Settings from "./sending";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import PayementServiceCard from "./PayementServiceCard";
import {API_KEY_PUBLIC} from  "./config"
import axios from "axios";

const stripePromise = loadStripe(API_KEY_PUBLIC);

class PaiementService extends Component {
    state = {
        infoPay: [],
        url: Settings.url,
        idUser: undefined,
        headers: undefined,
        codePro: undefined,
        typeCodePromo: undefined,
        valueCodePromo: undefined,
    }

    componentDidMount() {  

    }

    render() {
        return (
            <div className="blog-details-area ptb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details">
                                <div className="article-content">
                                    <Elements stripe={stripePromise}>
                                        <PayementServiceCard/>
                                    </Elements>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaiementService;
