import React, { Component } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import SidebarNav from "../sidebar";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import IMG01 from "../../assets/images/specialities-01.png";
import IMG02 from "../../assets/images/specialities-02.png";
import IMG03 from "../../assets/images/specialities-03.png";
import IMG04 from "../../assets/images/specialities-04.png";
import IMG05 from "../../assets/images/specialities-05.png";
import host from "../../../host";

class Specialities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: null,
      libelle: "",
      idspecialite: null,
      specialites: [],
      // data:[
      //     {id:1, image:IMG01, specialities:'Cardiologie'},
      //     {id:2, image:IMG02, specialities:'Dentaire'},
      //     {id:3, image:IMG03, specialities:'Neurologie'},
      //     {id:4, image:IMG04, specialities:'Orthopedie'},
      //     {id:5, image:IMG05, specialities:'Urologie'}

      // ]
    };
  }

  componentDidMount() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(host + "/specialite/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status != 200) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        if (result != null) {
          this.setState({
            specialites: result,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }

  handleClose = () => {
    this.setState({
      show: false,
      libelle: "",
      idspecialite: null,
    });
  };

  handleLibelle = (e) => {
    this.setState({
      libelle: e.target.value,
    });
  };

  handleShow = (id, specialite) => {
    this.setState({
      show: id,
    });
    if (id !== "add") {
      this.setState({
        libelle: specialite.libelle,
        idspecialite: specialite.idspecialite,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.show === "add") {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let raw = JSON.stringify({
        slug: this.state.libelle + "country",
        libelle: this.state.libelle,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + "/specialite/create/", requestOptions)
        .then((response) => {
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200 && response.status != 201) {
            return null;
          }
          return response.json();
        })
        .then((result) => {
          console.log(result);
          if (result == null) {
            toast.error("Echec de l'insertion");
          } else {
            this.setState({
              specialites: [result.specialite, ...this.state.specialites],
            });
            toast.success("Insertion réalisée avec succés");
            this.handleClose();
          }
        })
        .catch((error) => {
          console.log("error", error);
          toast.error("Echec de l'insertion");
        });
    } else if (this.state.show === "edit") {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let raw = JSON.stringify({
        slug: this.state.libelle + "country",
        libelle: this.state.libelle,
      });

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        host + `/specialite/update/${this.state.idspecialite}`,
        requestOptions
      )
        .then((response) => {
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200 && response.status != 201) {
            return null;
          }
          return response.json();
        })
        .then((result) => {
          console.log(result);
          if (result == null) {
            toast.error("Echec de la modification");
          } else {
            let tmp = this.state.specialites;
            console.log(tmp);
            for (let index = 0; index < tmp.length; index++) {
              const element = tmp[index];
              if (element.idspecialite == this.state.idspecialite) {
                tmp[index]["libelle"] = this.state.libelle;
                break;
              }
            }
            console.log(tmp);

            this.setState({
              specialites: [...tmp],
            });
            toast.success("Modification réalisée avec succés");
            this.handleClose();
          }
        })
        .catch((error) => {
          console.log("error", error);
          toast.error("Echec de la modification");
        });
    }
  };

  handleDelete = (e) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      host + `/specialite/delete/${this.state.idspecialite}`,
      requestOptions
    )
      .then((response) => {
        console.log(response);
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        if (result == null) {
          toast.error("Echec de la suppression");
        } else {
          let tmp = this.state.specialites;
          console.log(tmp);
          for (let index = 0; index < tmp.length; index++) {
            const element = tmp[index];
            if (element.idspecialite == this.state.idspecialite) {
              tmp.splice(index, 1);
              break;
            }
          }
          console.log(tmp);

          this.setState({
            specialites: [...tmp],
          });
          toast.success("Suppression réalisée avec succés");
          this.handleClose();
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Echec de la suppression");
      });
  };
  handleChange = (e) => {
    console.log(e);
  };
  render() {
    const columns = [
      {
        title: "id",
        dataIndex: "idspecialite",
        sorter: (a, b) => parseInt(a.idspecialite) - parseInt(b.idspecialite),
      },
      {
        title: "Specialité",
        dataIndex: "specialities",
        render: (text, record) => (
          <h2 className="table-avatar">
            {/* <Link to="/admin/profile" className="avatar avatar-sm mr-2"><img src={record.image} className="avatar-img" alt=""  /></Link> */}
            <Link to="/admin/profile">{record.libelle}</Link>
          </h2>
        ),
        sorter: (a, b) => a.libelle - b.libelle,
      },
      {
        title: "Actions",
        render: (text, record) => (
          <div className="actions">
            <a
              href="#0"
              className="btn btn-sm bg-success-light"
              onClick={() => this.handleShow("edit", record)}
            >
              <i className="fe fe-pencil"></i> Modifier
            </a>
            <a
              href="#0"
              className="btn btn-sm bg-danger-light"
              onClick={() => this.handleShow("delete", record)}
            >
              <i className="fe fe-trash"></i> Supprimer
            </a>
          </div>
        ),
      },
    ];

    return (
      <>
        <SidebarNav />
        <ToastContainer />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7 col-auto">
                  <h3 className="page-title">Spécialités</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admin">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item active">Spécialité</li>
                  </ul>
                </div>
                <div className="col-sm-5 col">
                  <a
                    href="#0"
                    className="btn btn-primary float-right mt-2"
                    onClick={() => this.handleShow("add")}
                  >
                    Ajouter
                  </a>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table
                        className="table-striped"
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        // bordered
                        dataSource={this.state.specialites}
                        ascend={true}
                        rowKey={(record) => record.id}
                        showSizeChanger={true}
                        onChange={this.handleChange}
                        pagination={{
                          total: this.state.specialites.length,
                          showTotal: (total, range) =>
                            `Vue ${range[0]} sur ${range[1]} des ${total} entrées`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Edit Modal */}
          <Modal
            show={this.state.show === "edit" || this.state.show === "add"}
            onHide={this.handleClose}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h5 className="modal-title">
                  {this.state.show === "edit"
                    ? "Modifier la specialite"
                    : "Ajouter une specialité"}
                </h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={this.handleSubmit}>
                <div className="row form-row">
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Nom de la specialité</label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.libelle}
                        onChange={this.handleLibelle}
                        placeholder="Entrer le nom de la specialité"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="form-group">
                      <label>Image</label>
                      <input type="file" className="form-control" />
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn btn-primary btn-block">
                  {this.state.show === "edit" ? "Modifier" : "Ajouter"}
                </button>
              </form>
            </Modal.Body>
          </Modal>
          {/* Edit Modal */}
          {/* Delete Modal */}
          <Modal
            show={this.state.show === "delete"}
            onHide={this.handleClose}
            centered
          >
            <Modal.Body className="text-center">
              <div className="form-content p-2">
                <h4 className="modal-title">Supprimer</h4>
                <p className="mb-4">
                  Confimer vous la suppression de la {this.state.libelle}
                </p>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.handleClose}
                >
                  Annuler
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.handleDelete}
                >
                  Supprimer{" "}
                </button>
              </div>
            </Modal.Body>
          </Modal>
          {/* Delete Modal */}
        </div>
      </>
    );
  }
}

export default Specialities;
