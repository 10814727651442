import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import Lightbox from 'react-image-lightbox';
import { Tabs, Tab } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { IMG01, IMG02, IMG03, IMG04, IMG05, IMG06, IMG07, IMG08 } from './img';
// import img1 from "../../../assets/images/dr_armel.png";
// import img2 from "../../../assets/images/dr_armel.png";
// import img3 from "../../../assets/images/feature/feature-03.jpg";
// import img4 from "../../../assets/images/feature/feature-04.jpg";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import host from '../../../../host';
import numStr from '../separator/separator'

import { Image } from "antd";



// const images = [img1, img2, img3, img4];



function isNumeric(number) {
	return !isNaN(parseFloat(number)) && !isNaN(+number);
}

class CentreProfil extends Component {
	constructor(props) {
		super(props);
		this.state = {
			photoIndex: 0,
			isOpen: false,
			key: 1,
			activeModal: null,
			centre: null
		}
		this.handleSelect = this.handleSelect.bind(this);
	}
	componentDidMount() {
		//Récupération du Centre Médical
		const { match: { params } } = this.props;
		if (isNumeric(params.id)) {
			const id = parseInt(params.id);
			fetch(host+`/centres/${id}`)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					console.log("dataCentre",data)
					this.setState({
						centre: data
					});
				});
		} else {
			this.props.history.push("/404")
		}
	}
	
	
	handleSelect(key) {
		// console.log("selected " + key);
		this.setState({ key })
	}

	// openModal = (id) => {
	// 	this.setState({ activeModal: id }, () => {
	// 		// console.log(this.state.activeModal, 'value');
	// 	});
	// }
	handleCloseModal = () => {
		this.setState({ activeModal: false }, () => {
			console.log(this.state.activeModal, 'state');
		});
	};

	render() {

		const { photoIndex, isOpen, centre } = this.state;
		console.log(centre, "le pro")

		if (centre == null) {
			return (<div style={{ textAlign: "center", marginTop: "50vh", marginBottom: "40vh" }}>
				<Loader
					type="Puff"
					color="#00BFFF"
					height={100}
					width={100}
					timeout={3000} //3 secs

				/>
			</div>)
		} else
			return (

				<div style={{ marginTop: "20px" }}>
					<div className="content">
						<div className="container">
							<div className="card">
								<div className="card-body">
									<div className="doctor-widget">
										<div className="doc-info-left">
											<div className="doctor-img">
												
											<Image
												width={150}
												src={
												centre.photo  ? centre.photo : "https://cdn0.iconfinder.com/data/icons/medical-and-clinical-healthcare/78/5-512.png"
												
												}
												className="img-fluid"                             
												alt=""
												/>
												{/* <img 
												src="https://cdn0.iconfinder.com/data/icons/medical-and-clinical-healthcare/78/5-512.png"                              
												className="img-fluid" style={{ borderRadius: "2%" }} alt={centre.libelle} /> */}
												</div>
											<div className="doc-info-cont" style={{ marginTop: "5%" }}>
												<h4 className="doc-name" >{centre.libelle}</h4>
												 <p className="doc-department">{centre.adresse}</p> 

											</div>
										</div>
										<div className="doc-info-right">
											<div className="clini-infos">

											</div>
											{/* <div className="doctor-action">
												<a href="#0" className="btn btn-white fav-btn">
													<i className="far fa-bookmark"></i>
												</a>
												<Link to="/doctor/chat-doctor" className="btn btn-white msg-btn">
													<i className="far fa-comment-alt"></i>
												</Link>
												<a href="#0"
													className="btn btn-white call-btn"
													data-toggle="modal"
													onClick={() => this.openModal('voice')}
												>
													<i className="fas fa-phone"></i>
												</a>
												<a href="#0"
													className="btn btn-white call-btn"
													data-toggle="modal"

													onClick={() => this.openModal('video')}
												>
													<i className="fas fa-video"></i>
												</a>
											</div> */}
											<div className="clinic-booking"><Link to={{
												// pathname: "/patient/booking",
												pathname: "/prestationCentre/" + this.state.centre.idcentre,
												aboutProps: {
													idProfessionel: this.state.centre.id
												}
											}} className="btn book-btn">Prendre RDV</Link>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="card">
								<div className="card-body pt-0">
									<Tabs
										className="tab-view"
										activeKey={this.state.key}
										onSelect={this.handleSelect}
										id="controlled-tab-example"
									>
										<Tab className="nav-item" eventKey={1} title="Vue d'ensemble">
											<div className="col-md-1 col-lg-12">
												<div className="widget about-widget">
													<h4 className="widget-title">A propos de nous</h4>
													{/* <p>{centre.biographie} </p> */}
												</div>
											</div>
											<div className="row" >

												{/* <div className="col-md col-lg" >
													<div className="widget education-widget">
														<h4 className="widget-title">Formation</h4>
															
													</div>
												</div> */}

											{/* <div className="col-md col-lg" >
													<div className="widget expertise-widget">
														<h4 className="widget-title">Formations</h4>
														<div className="experience-box">
															<ul className="experience-list">
																
					
																		 <li>
																			<div className="experience-user">
																				<div className="before-circle"></div>
																			</div>
																			<div className="experience-content">
																				<div className="timeline-content">
																					<a href="#/" className="name">NOM formation</a>
																					<br></br><span className="adresse">formation </span>
																				</div>
																			</div>
																		</li>	
                                                                        <li>
																			<div className="experience-user">
																				<div className="before-circle"></div>
																			</div>
																			<div className="experience-content">
																				<div className="timeline-content">
																					<a href="#/" className="name"> formation</a>
																					<br></br><span className="adresse"> formation</span>
																				</div>
																			</div>
																		</li>						
															</ul>
														</div>
													</div>
												</div> */}

												{/* <div className="col-md col-lg" >
													<div className="widget expertise-widget">
														<h4 className="widget-title">Disctintions</h4>
														<div className="experience-box">
															<ul className="experience-list">
																
					
																		 <li>
																			<div className="experience-user">
																				<div className="before-circle"></div>
																			</div>
																			<div className="experience-content">
																				<div className="timeline-content">
																					<a href="#/" className="name">NOM distinction</a>
																					<br></br><span className="adresse">distinction </span>
																				</div>
																			</div>
																		</li>	
                                                                        <li>
																			<div className="experience-user">
																				<div className="before-circle"></div>
																			</div>
																			<div className="experience-content">
																				<div className="timeline-content">
																					<a href="#/" className="name">NOM distinction</a>
																					<br></br><span className="adresse"> distinction</span>
																				</div>
																			</div>
																		</li>						
															</ul>
														</div>
													</div>
												</div> */}





												{/* <div className="col-md col-lg" >
													<div className="widget expertise-widget">
														<h4 className="widget-title">Experience</h4>
														<div className="experience-box">
															<ul className="experience-list">
																
					
																		 <li>
																			<div className="experience-user">
																				<div className="before-circle"></div>
																			</div>
																			<div className="experience-content">
																				<div className="timeline-content">
																					<a href="#/" className="name">Experience1</a>
																					<br></br><span className="adresse">Experience </span>
																				</div>
																			</div>
																		</li>	
                                                                        <li>
																			<div className="experience-user">
																				<div className="before-circle"></div>
																			</div>
																			<div className="experience-content">
																				<div className="timeline-content">
																					<a href="#/" className="name"> Experience2</a>
																					<br></br><span className="adresse"> Experience</span>
																				</div>
																			</div>
																		</li>						
															</ul>
														</div>
													</div>
												</div> */}

												{/* <div className="col-md col-lg" >
													<div className="widget education-widget">
														<h4 className="widget-title">Prix</h4>
														<div className="experience-box">
															<ul className="experience-list">
															
														
																		 <li>
																			<div className="experience-user">
																				<div className="before-circle"></div>
																			</div>
																			<div className="experience-content">
																				<div className="timeline-content">
																					<a href="#/" className="name">distinction.libelle</a>
																					<span className="time">distinction.annee</span>
																				</div>
																			</div>
																		</li>
																	
															
															</ul>
														</div>
													</div>
												</div> */}

											</div>
										</Tab>
										<Tab className="nav-item" eventKey={2} title="Prestation(s)">
											<div className="row" >

												<div className="col-md col-lg" >
													<div className="widget education-widget">
														<div className="col-md-1 col-lg-4">
															<h4 class="p-3 mb-2 bg-info text-white">Consultation(s)  physique</h4>
														</div>
														<div className="experience-box">
															{
																centre.prestations.length === 0 ? (
																	<div className="col-md-1 col-lg-6">
																		<h4 >Aucune consultations présentielle disponible</h4>
																	</div>
																) : ""
															}
															<ul className="experience-list">

																{
																	centre.prestations.length === 0 ? (
																		""
																	) :
																		centre.prestations.map((presta) => {
																			if (presta.modalite === "physique") {
																				return <li className="experience-content" key={"prestation" + presta.id}>
																					<div className="experience-user">
																						<div className="before-circle"></div>
																					</div>
																					<div className="experience-content">
																						<div className="timeline-content">
																							<Link to={{
																								pathname: "/patient/booking",
																								prestation: {
																									idProfessionel: this.state.centre.id,
																									prestations: presta,
																									prixEuro: presta.prixEuro,
																									prixXAF: presta.prixXAF
																								}
																							}} ><strong>{presta.libelle}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{ numStr(presta.pXAF,'.') + " XAF"}</Link>
																							</div>
																					</div>
																				</li>
																			}
																		})
																}

															</ul>
														</div>


														<br></br>
														<br></br>
														<br></br>
														<br></br>
														{/* <div className="col-md-1 col-lg-4">
															<h4 class="p-3 mb-2 bg-info text-white">Consultations en Vidéo</h4>
														</div>
														<div className="experience-box">
															{
																centre.prestations.length === 0 ? (
																	<div className="col-md-1 col-lg-6">
																		<h4 >Aucune consultations Vidéo disponible</h4>
																	</div>
																) : ""
															}
															<ul className="experience-list">

																{
																	centre.prestations.length === 0 ? (
																		""
																	) :
																		centre.prestations.map((presta) => {
																			if (presta.modalite === "video") {
																				return <li className="experience-content" key={"prestation" + presta.id}>
																					<div className="experience-user">
																						<div className="before-circle"></div>
																					</div>
																					<div className="experience-content">
																						<div className="timeline-content">
																							<Link to={{
																								pathname: "/patient/booking",
																								// prestation: {
																								// 	idProfessionel: this.state.centre.id,
																								// 	prestations: presta,
																								// 	prixEuro: presta.PrixEUR,
																								// 	prixXAF: presta.PrixXAF
																								// }
																							}} ><strong>{presta.libelle}</strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{presta.pXAF + " XAF"}</Link>
																						</div>
																					</div>
																				</li>
																			}
																		})
																}

															</ul>
														</div> */}
													</div>
												</div>

											</div>
										</Tab>
									<Tab className="nav-item" eventKey={3} title="Nos professionels de sante">
										<div>
											<div className="location-list">
												<div className="row">

													<div className="col-md-6">
														<div className="clinic-content">

															{
																centre.professionnelsantes.map((prosante) => {
																	return <li key={"centres" + prosante.idprofessionnelsante}>
																		<div className="Clinic information">
																			{/* <Link to=`patient/doctor-profile/${prosante.idprofessionnelsante}` className="name"><h4>{prosante.nom +" "+ prosante.prenom}</h4></Link> */}
																			<Link to={"/patient/doctor-profile/"+prosante.idprofessionnelsante} className="name"><h4>{prosante.nom +" "+ prosante.prenom}</h4></Link>
																			{/* <p className="doc-speciality">Centre dentaire</p>
																			<div className="rating">
																				<i className="fas fa-star filled"></i>
																				<i className="fas fa-star filled"></i>
																				<i className="fas fa-star filled"></i>
																				<i className="fas fa-star filled"></i>
																				<i className="fas fa-star"></i>
																				<span className="d-inline-block average-rating">(4)</span>
																			</div> */}
																			<div className="clinic-details mb-0">
																				<h5 className="clinic-direction">
																					{/* <i className="fas fa-map-marker-alt"></i> */}
																					<span className="adresse">{prosante.specialite.libelle}</span>

																					<a href="#0"></a></h5>

																				{/* <div>
																					{isOpen && (
																						<Lightbox
																							enableZoom={false}
																							mainSrc={images[photoIndex]}
																							nextSrc={images[(photoIndex + 1) % images.length]}
																							prevSrc={images[(photoIndex + images.length - 1) % images.length]}
																							imageTitle={photoIndex + 1 + "/" + images.length}

																							onCloseRequest={() => this.setState({ isOpen: false })}
																							onMovePrevRequest={() =>
																								this.setState({
																									photoIndex: (photoIndex + images.length - 1) % images.length
																								})
																							}
																							onMoveNextRequest={() =>
																								this.setState({
																									photoIndex: (photoIndex + 1) % images.length
																								})
																							}
																						/>
																					)}
																				</div> */}
																				{/* <ul className="clinic-gallery">
																					<li>
																						<a href="#0" onClick={() => this.setState({ isOpen: true, photoIndex: 0 })}>
																							<img src={IMG03} alt="Feature" />
																						</a>
																					</li>
																					<li>
																						<a href="#0" onClick={() => this.setState({ isOpen: true, photoIndex: 1 })}>
																							<img src={IMG04} alt="Feature" />
																						</a>
																					</li>
																					<li>
																						<a href="#0" onClick={() => this.setState({ isOpen: true, photoIndex: 2 })}>
																							<img src={IMG05} alt="Feature" />
																						</a>
																					</li>
																					<li>
																						<a href="#0" onClick={() => this.setState({ isOpen: true, photoIndex: 3 })}>
																							<img src={IMG06} alt="Feature" />
																						</a>
																					</li>
																				</ul> */}
																			</div>

																		</div>
																	</li>
																})
															}

														</div>
													</div>

													{/* <div className="col-md-4">
														<div className="clinic-timing">
															<div>
																<p className="timings-days">
																	<span> Lun - Sam </span>
																</p>
																<p className="timings-times">
																	<span>10:00 AM - 2:00 PM</span>
																	<span>4:00 PM - 9:00 PM</span>
																</p>
															</div>
															<div>
																<p className="timings-days">
																	<span>Sun</span>
																</p>
																<p className="timings-times">
																	<span>10:00 AM - 2:00 PM</span>
																</p>
															</div>
														</div>
													</div> */}
													
												</div>
											</div>

											<div className="location-list">
												<div className="row">

													{/* <div className="col-md-6">
														<div className="clinic-content">
															<h4 className="clinic-name"><a href="#0">La clinique de dentisterie familiale</a></h4>
															<p className="doc-speciality">MDS - Parodontologie et Implantologie orale, BDS</p>
															<div className="rating">
																<i className="fas fa-star filled"></i>
																<i className="fas fa-star filled"></i>
																<i className="fas fa-star filled"></i>
																<i className="fas fa-star filled"></i>
																<i className="fas fa-star"></i>
																<span className="d-inline-block average-rating">(4)</span>
															</div>
															<div className="clinic-details mb-0">
																<p className="clinic-direction"> <i className="fas fa-map-marker-alt"></i>
																	86, bd du Général Charles de Gaulle, évêché POINTE-NOIRE CONGO<a href="#0">Obetnir la direction</a></p>
																<div>

																	{isOpen && (
																		<Lightbox
																			enableZoom={false}
																			mainSrc={images[photoIndex]}
																			nextSrc={images[(photoIndex + 1) % images.length]}
																			prevSrc={images[(photoIndex + images.length - 1) % images.length]}
																			imageTitle={photoIndex + 1 + "/" + images.length}

																			onCloseRequest={() => this.setState({ isOpen: false })}
																			onMovePrevRequest={() =>
																				this.setState({
																					photoIndex: (photoIndex + images.length - 1) % images.length
																				})
																			}
																			onMoveNextRequest={() =>
																				this.setState({
																					photoIndex: (photoIndex + 1) % images.length
																				})
																			}
																		/>
																	)}
																</div>
																<ul className="clinic-gallery">
																	<li>
																		<a href="#0" onClick={() => this.setState({ isOpen: true, photoIndex: 0 })}>
																			<img src={IMG03} alt="Feature" />
																		</a>
																	</li>
																	<li>
																		<a href="#0" onClick={() => this.setState({ isOpen: true, photoIndex: 1 })}>
																			<img src={IMG04} alt="Feature" />
																		</a>
																	</li>
																	<li>
																		<a href="#0" onClick={() => this.setState({ isOpen: true, photoIndex: 2 })}>
																			<img src={IMG05} alt="Feature" />
																		</a>
																	</li>
																	<li>
																		<a href="#0" onClick={() => this.setState({ isOpen: true, photoIndex: 3 })}>
																			<img src={IMG06} alt="Feature" />
																		</a>
																	</li>
																</ul>
															</div>

														</div>
													</div> */}

													{/* <div className="col-md-4">
														<div className="clinic-timing">
															<div>
																<p className="timings-days">
																	<span> Mar - Jeu </span>
																</p>
																<p className="timings-times">
																	<span>11:00 AM - 1:00 PM</span>
																	<span>6:00 PM - 11:00 PM</span>
																</p>
															</div>
															<div>
																<p className="timings-days">
																	<span>Sam - Dim</span>
																</p>
																<p className="timings-times">
																	<span>8:00 AM - 10:00 AM</span>
																	<span>3:00 PM - 7:00 PM</span>
																</p>
															</div>
														</div>
													</div> */}


													
												</div>
											</div>
										</div>

									</Tab>
									
									{/* <Tab className="nav-item" eventKey={4} title="Horaires">
										<div className="row">
											<div className="col-md-6 offset-md-3">

												<div className="widget business-widget">
													<div className="widget-content">
														<div className="listing-hours">
															<div className="listing-day current">
																<div className="day"> Aujourd'hui <span>5 Nov 2019</span></div>
																<div className="time-items">
																	<span className="open-status"><span className="badge bg-success-light">Ouvert </span></span>
																	<span className="time">07:00 AM - 09:00 PM</span>
																</div>
															</div>
															<div className="listing-day">
																<div className="day">Lundi</div>
																<div className="time-items">
																	<span className="time">07:00 AM - 09:00 PM</span>
																</div>
															</div>
															<div className="listing-day">
																<div className="day">Mardi</div>
																<div className="time-items">
																	<span className="time">07:00 AM - 09:00 PM</span>
																</div>
															</div>
															<div className="listing-day">
																<div className="day">Mercredi</div>
																<div className="time-items">
																	<span className="time">07:00 AM - 09:00 PM</span>
																</div>
															</div>
															<div className="listing-day">
																<div className="day">Jeudi</div>
																<div className="time-items">
																	<span className="time">07:00 AM - 09:00 PM</span>
																</div>
															</div>
															<div className="listing-day">
																<div className="day">Vendredi</div>
																<div className="time-items">
																	<span className="time">07:00 AM - 09:00 PM</span>
																</div>
															</div>
															<div className="listing-day">
																<div className="day">Samedi</div>
																<div className="time-items">
																	<span className="time">07:00 AM - 09:00 PM</span>
																</div>
															</div>
															<div className="listing-day closed">
																<div className="day">Dimanche</div>
																<div className="time-items">
																	<span className="time"><span className="badge bg-danger-light">fermé</span></span>
																</div>
															</div>
														</div>
													</div>
												</div>


											</div>
										</div>
									</Tab> */}
									</Tabs>

							</div>
						</div>

					</div>
				</div>
					{/* modal hmlFor video*/ }
		<Modal show={this.state.activeModal === 'video'} onHide={this.handleCloseModal} centered>
			<Modal.Body>
				<div class="call-box incoming-box">
					<div class="call-wrapper">
						<div class="call-inner">
							<div class="call-user">
								<img alt="User" src={IMG01} class="call-avatar" />
								<h4>Dr. Darren Elder</h4>
								<span>Connecting...</span>
							</div>
							<div class="call-items">
								<a href="#0" class="btn call-item call-end" data-dismiss="modal" aria-label="Close">
									<Icon>call_end</Icon>
								</a>
								<Link to="/voice-call" class="btn call-item call-start"><i class="material-icons">call</i></Link>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
		{/* modal hmlFor call*/ }
		<Modal show={this.state.activeModal === 'voice'} onHide={this.handleCloseModal} centered>
			<Modal.Body>
				<div class="call-box incoming-box">
					<div class="call-wrapper">
						<div class="call-inner">
							<div class="call-user">
								<img alt="User" src={IMG01} class="call-avatar" />
								<h4>Dr. Darren Elder</h4>
								<span>Connecting...</span>
							</div>
							<div class="call-items">
								<a href="#0" class="btn call-item call-end" data-dismiss="modal" aria-label="Close">
									<Icon>call_end</Icon>
								</a>
								<Link to="/voice-call" class="btn call-item call-start"><i class="material-icons">call</i></Link>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>

				</div >

			);

	}

}

class Centres extends Component {
	render() {
		const { photoIndex, isOpen, centre } = this.state;

		if (centre == null) {
			return (<div style={{ textAlign: "center", }}>
				<Loader
					type="Puff"
					color="#00BFFF"
					height={100}
					width={100}
					timeout={3000} //3 secs

				/>
			</div>)
		} else
			return (
				<div className="col-md col-lg" >
					<div className="widget expertise-widget">
						<h4 className="widget-title">Centres</h4>
						<div className="experience-box">
							<ul className="experience-list">
								{
									centre.centres.map((centres) => {
										return <li key={"distinction" + centres.id}>
											<div className="experience-user">
												<div className="before-circle"></div>
											</div>
											<div className="experience-content">
												<div className="timeline-content">
													<a href="#/" className="name">{centres.libelle}</a>
													{/* <div>{diplome.nomEtablissement}</div> */}
													<br></br><span className="adresse">{centres.adresse}</span>
												</div>
											</div>
										</li>
									})
								}
							</ul>
						</div>
					</div>
				</div>
			)
	}
}

export default CentreProfil;



