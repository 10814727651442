import React from 'react';
import { NavLink } from 'react-router-dom';
import IMG01 from '../../../../assets/images/patient.jpg';
export const DashboardSidebar = () => {
	const dataJson = sessionStorage.getItem('user');
    const data =  dataJson && JSON.parse(dataJson);

	const logout = (e) => {
        e.preventDefault()
        sessionStorage.removeItem("user")
        sessionStorage.removeItem("user")
        window.location.assign('/login')
      }

	return (
		
		<div class="profile-sidebar">
			<div class="widget-profile pro-widget-content">
				<div class="profile-info-widget">
					<a href="#0" class="booking-doc-img">
						<img src={IMG01} alt="User" />
					</a>
					<div class="profile-det-info">
						<h3>
								{
                                    data?data.user.prenom+" "+data.user.nom: ""
                                } 
						</h3>
					</div>
				</div>
			</div>
			<div class="dashboard-widget">
				<nav class="dashboard-menu">
					<ul>
						<li class="">
							<NavLink to="/patient/dashboard" activeClassName="active">
								<i class="fas fa-columns"></i>
								<span>Tableau de bord</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/patient/favourites" activeClassName="active">
								<i class="fas fa-bookmark"></i>
								<span>Favoris</span>
							</NavLink>
						</li>

						<li>
							<NavLink to="/patient/profile" activeClassName="active">
								<i class="fas fa-user-cog"></i>
								<span>Paramètres du profil</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/patient/change-password" activeClassName="active">
								<i class="fas fa-lock"></i>
								<span>Changer de mot de passe</span>
							</NavLink>
						</li>
						<li>
							<NavLink to="/">
								<i class="fas fa-sign-out-alt"></i>
								<span onClick={logout}>Déconnexion</span>
							</NavLink>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	);
}
export default DashboardSidebar;