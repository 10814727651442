import React from 'react';
// import "./checkmail.css";
// import validate from "../../assets/images/coche.png";

function ErrorToken() {
    return (
        <div className="container">
	        <div className="row"><br/>
		    <div className=" text-center jumbotron">
                <p className="text-center"> <img  src="" alt="" width="200px" height="200px" /> </p>
                <h1>Désolé le lien n'est plus validé </h1>
                {/* <p>Un mail de confirmation a été envoyé à votre adresse mail, veuillez consulter votre boîte mail.</p> */}
               
            </div>
	</div>	
</div>
    )
}

export default ErrorToken
