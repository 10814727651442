// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import React, { Component } from "react";
// import Select from "react-select";
import StickyBox from "react-sticky-box";
// import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import host from "../../../../host";

class SearchDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      professionnel: [],
      allProfessionnel: [],
      selectedVille: "",
      specialites: [],
      selectedspecialite: "",
      villes: [],
      newTabProf: [],
      search: "",
      getVille: "",
      getSpecialite: "",
    };
  }

  componentDidMount() {
    fetch(host+"/ps")
    //L'appel API les professionnels de Santé.
      .then((response) => {
        return response.json();
      })
      .then((data) => {
         let tab = [];
         for (let index = 0; index < data.length; index++) {
           const element = data[index];
            if (element.user.isActive === true) {
                tab.push(element)
            }
         }
        this.setState({
          professionnel: tab,
          allProfessionnel: tab,
        });
      })
      .catch(error => console.log('error', error))

    fetch(host+"/specialite")
    //L'appel API des Spécialités.
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // console.log("Spécialté:222 ",data.professionnelsante)
        this.setState({
          specialites: data,
        });
      })
      .catch(error => console.log('error', error))

    fetch(host+`/ville`)
    //L'appel API des Villes.
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.setState({
          villes: data,
        });
      })
      .catch(error => console.log('error', error))
  }

  //Le Filtre pour la barre de recherche (page trouver un médecin).
  handleChange = (e) => {
    this.setState({
      search: e.target.value,
      professionnel: this.state.allProfessionnel.filter((professionnel) =>
        new RegExp(e.target.value, "i").exec(
          professionnel.nom +
            " " +
            professionnel.prenom 
            
        )
      ),
    });
  };
  //********** fin function filtre barre de recherche  ******** */

  render() {
    // const filtre = this.state.professionnel.filter((professionnel) => {
    //   if( professionnel.specialite === null || professionnel.specialite === undefined ){
    //     console.log("PROFFFF: ",professionnel) 
    //     return false
    //   }else{
    //     return ( 
    //       professionnel.specialite.libelle.includes(this.state.selectedspecialite) &&
    //       professionnel.ville.libelle.includes(this.state.selectedVille)
    //     );
    //   }
    // });
  
    return (
      <div>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-9">
            
            <div className="ml-4 mr-4">
              <input
                className="form-control"
                type="text"
                placeholder="Rechercher par nom"
                value={this.state.search}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-4 col-xl-3">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <div className="card search-filter mb-4">
                    <div className="card-header">
                      <h4 className="card-title mb-0">Filtre de recherche</h4>
                    </div>
                    <div className="card-body">
                      <div className="filter-widget">
                        <h4>Localisation</h4>
                        <div>
                          <div>
                            <label className="custom_check">
                              <input
                                type="checkbox"
                                name="Tous les villes"
                                // checked= {() => {this.setState({selectedVille: ""})}}
                                checked= {this.state.selectedVille === "" ? true : false}
                                onChange={() => {
                                  this.setState({ selectedVille: "" });
                                }}
                              />
                              <span className="checkmark"></span> Toutes les
                              villes
                            </label>
                          </div>
                          <br />
                          {this.state.villes.map((ville) => {
                            return (
                              <>
                                <label key={ville.libelle} className="custom_check">
                                  <input
                                    type="radio"
                                    value={ville.libelle}
                                    checked={
                                      ville.libelle === this.state.selectedVille
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        selectedVille: e.target.value,
                                      })
                                    }
                                  />
                                  <span className="checkmark"></span>{" "}
                                  {ville.libelle}
                                </label>
                                <br />
                              </>
                            );
                          })}
                        </div>
                      </div>

                      <div className="filter-widget">
                        <h4>Spécialité</h4>
                        <div>
                          <label className="custom_check">
                            <input
                              type="checkbox"
                              name="Tous les villes"
                              // checked= {() => {this.setState({selectedVille: ""})}}
                              checked= {this.state.selectedspecialite === "" ? true : false}
                              onChange={() => {
                                this.setState({ selectedspecialite: "" });
                              }}
                            />
                            <span className="checkmark"></span> Toutes les
                            spécialités
                          </label>
                        </div>
                        <br />
                        {this.state.specialites.map((specialite) => {
                          return (
                            <>
                              <label
                                key={specialite.libelle}
                                className="custom_check"
                              >
                                <input
                                  type="radio"
                                  value={specialite.libelle}
                                  checked={
                                    specialite.libelle ===
                                    this.state.selectedspecialite
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      selectedspecialite: e.target.value,
                                    })
                                  }
                                />
                                <span className="checkmark"></span>{" "}
                                {specialite.libelle}
                              </label>
                              <br />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </StickyBox>
              </div>

                      {/* {
                        console.log("object:", this.state.newTabProf)
                      } */}
              <div className="col-md-12 col-lg-8 col-xl-9 mt-4">
                {this.state.professionnel.length === 0 ? (
                  <React.Fragment>
                    <Skeleton height={140} width={900} />
                    <br />
                    <br />
                    <Skeleton height={140} width={900} />
                    <br />
                    <br />
                    <Skeleton height={140} width={900} />
                    <br />
                    <br />
                    <Skeleton height={140} width={900} />
                  </React.Fragment>
                ) : (
                  <ProfessionnelDeSante
                    professionneldata={this.state.professionnel}
                    selectedspecialite={this.state.selectedspecialite}
                    selectedVille={this.state.selectedVille}
                    // filtre={filtre}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
class ProfessionnelDeSante extends Component {
  render() {
    const { professionneldata, selectedspecialite, selectedVille,  } = this.props;
    // console.log("PROOOOF: ", professionneldata)

    const filtre = professionneldata.filter((professionnel) => {
      if( professionnel.specialite === null || professionnel.specialite === undefined ){
        // console.log("PROFFFF: ",professionnel) 
        return false
      }else{
        return ( 
          professionnel.specialite.libelle.includes(selectedspecialite) &&
          professionnel.ville.libelle.includes(selectedVille)
        );
      }
    });

    return (
      <>
        {
          filtre.length === 0 ? (
              <div>
                  <h2> Aucun professionnel de santé trouvé  </h2>
              </div>
          ):  null
        }
        
        {filtre.map((professionnel) => {
          return (
            <div className="card" key={ professionnel.idprofessionnelsante}>
              <div className="doctor-widget" style={{marginTop: "20px", marginLeft: "20px"}}>
                <div className="doc-info-left">
                  <div className="doctor-img">
                    <Link to={"/patient/doctor-profile/" + professionnel.idprofessionnelsante}>
                      <img 
                       style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "3%",
                        // marginTop: "10px",
                      }}
                       className="img-fluid" alt="User" src={professionnel.user.photo ? professionnel.user.photo : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} />
                      {/* <img className="img-fluid" alt="User" src={professionnel.user.photo ? host +`/user/images/${professionnel.user.photo}` : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} /> */}
                    </Link>
                  </div>
                </div>
                <div className="doc-info-cont">
                  <h4>
                    <Link id="nom" to={"/patient/doctor-profile/"+ professionnel.idprofessionnelsante+"#profil-doctor"}>
                      {professionnel.nom + " " + professionnel.prenom}{" "}
                    </Link>
                  </h4>
                  <div>
                    <h5 className="doc-department">
                      {professionnel.specialite === null ||
                      professionnel.specialite === undefined
                        ? "Aucune spécialité spécifiée"
                        : professionnel.specialite.libelle}
                    </h5>
                    <h5 className="doc-department">
                      <i className="fas fa-map-marker-alt"></i>
                      {professionnel.ville === null ||
                      professionnel.ville === undefined
                        ? " Aucune ville spécifiée"
                        : "  "+professionnel.ville.libelle}
                    </h5>
                  </div>
                  {/* <div className="clinic-details">
									{
										professionnel.centres.map((centre) => {
											return <div key={"centre" + centre.id}>
												<div>
													<p className="doc-location">
														<i className="fas fa-map-marker-alt"></i>{"  " + centre.libelle}
													</p>
												</div></div>
										})
									}
								</div> */}
                  <div>
                    <Link
                      to={
                        "/patient/doctor-profile/" +
                        professionnel.idprofessionnelsante
                      }
                      className=""
                    >
                      {" "}
                      <button className="btn form-group  btn-outline-info-kw">
                        Voir le profil
                      </button>
                    </Link>
                    <Link
                      to={
                        "/prestation/" + professionnel.idprofessionnelsante
                      }
                      className=""
                    >
                      {" "}
                      <button
                        style={{
                          backgroundColor: "rgb(32,192,243)",
                          color: "white",
                        }}
                        className=" form-group btn btn-info-kw"
                      >
                        Prendre RDV
                      </button>{" "}
                    </Link>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

export default SearchDoctor;
