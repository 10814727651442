import React, { Component } from "react";
import { Table, Switch, Button, Modal, DatePicker,Upload, Space } from "antd";
import { Link } from "react-router-dom";
import SidebarNav from "../sidebar";
import { PlusOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { Image } from "antd";




import {
  MDBDataTableV5,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdbreact";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import host from "../../../host";
const { RangePicker } = DatePicker;


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class Doctors extends Component {
  constructor(props) {
    super(props);

    let token = "";

    if (sessionStorage.getItem("admin")) {
      token = JSON.parse(sessionStorage.getItem("admin")).token;
    }

    this.state = {
      auth: token,
      active:false,
      chekedBox:false,
      disabled: true,
      isLoading: false,
      isModalVisible: false,
      isDetail: false,
      isDelete: false,
      isUpdate: false,
      uploadedFile: null,

      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        }],

      nom: "",
      prenom: "",
      email: "",
      password: "",
      sexe: "",
      number: "",
      biographie: "",
      createdAt: "",
      idspecialite: "",
      idville: "",
      ville: "",
      specialite: "",
      photo:"",

      idPs: "",
      idUser: "",
      index: "",

      specialites: null,
      villes: null,
      allData: [],
      datatable: {
        columns: [
          {
            label: "Nom",
            field: "nom",
            width: 100,
            action: <input type="submit" value="jkfsdfls" />,
            attributes: {
              "aria-controls": "DataTable",
              "aria-label": "nom",
            },
          },
          {
            label: "Prenom",
            field: "prenom",
            width: 100,
            attributes: {
              "aria-controls": "DataTable",
              "aria-label": "nom",
            },
          },
          {
            label: "Email",
            field: "email",
            width: 100,
          },
          {
            label: "Tel",
            field: "tel",
            sort: "asc",
            width: 50,
          },
          {
            label: "Sexe",
            field: "sexe",
            width: 20,
          },
          {
            label: "Activé",
            field: "active",
            width: 20,
          },
        ],
        rows: [{}],
      },
    };
    this.changeProfileImage = this.changeProfileImage.bind(this);

  }

  show = (item) => {
    console.log("itemClients",item);
    this.setState({
      nom: item.nom,
      prenom: item.prenom,
      email: item.email,
      password: item.password,
      sexe: item.sexe,
      number: item.number,
      biographie: item.biographie,
      createdAt: item.createdAt,
      isDetail: true,
      idPs: item.idPs,
      idUser: item.idUser,
      index: item.index,
      ville: item.ville,
      specialite: item.specialite,
      active: item.active,  
      photo:item.photo,
      fileList: [
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: item.photo ? item.photo : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png",
        }],    

    });
  };

  changeSwitch = (checke) => {
    console.log(`switch to ${checke}`);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    let myHeaders = new Headers();
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    //debut------------ ------recuperation professionnels de santés
    await fetch(host + "/ps/", requestOptions)
      .then((response) => {
        if (response.status === 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        return response.text();
      })
      .then((result) => {
        if (result === null) {
        } else {
          const data = JSON.parse(result);
          // console.log("data", data);
          let items = [];
          for (let i = 0; data.length > i; i++) {
            // console.log("data[i].user.isActive",data[i].user.isActive)
            if(data[i].user.isActive === true){
              //  console.log("ok")
              this.setState({
                active: true
              })
            }else(
              this.setState({
                active: false
              })
            )
            items.push({
              id: data[i].idprofessionnelsante,
              nom: data[i].nom,
              slug: data[i].slug,
              prenom: data[i].prenom,
              email: data[i].email,
              tel: data[i].numtelephone,
              biographie: data[i].biographie,
              sexe: data[i].sexe,
              user: data[i].user,
              ville: data[i].ville,
              specialite: data[i].specialite,
              active: <Switch  checked={this.state.active}  disabled={this.state.disabled}/>,
              photo:data[i].user.photo,
              // active: <Switch disabled={this.disabled} defaultChecked /> ,
              clickEvent: () =>
                this.show({
                  idPs: data[i].idprofessionnelsante,
                  idUser: data[i].user.id,
                  index: i,
                  nom: data[i].nom,
                  prenom: data[i].prenom,
                  email: data[i].email,
                  password: data[i].user.password,
                  sexe: data[i].sexe,
                  number: data[i].numtelephone,
                  biographie: data[i].biographie,
                  createdAt: data[i].user.createdAt,
                  ville: data[i].ville,
                  specialite: data[i].specialite,
                  active: data[i].user.isActive,
                  photo:data[i].user.photo,
                }),
            });
          }
          // console.log("items", items);
          this.setState({
            allData: items,
            datatable: {
              columns: [...this.state.datatable.columns],
              rows: items,
            },
          });
        }
      })
      .catch((error) => console.log("error", error));
    //----------------------fin

    // debut----------------------recuperation des villes
    await fetch(host + "/ville/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        if (result != null) {
          this.setState({
            villes: result,
          });
        }
      })
      .catch((error) => console.log("error", error));
    // ---------------------------fin

    // debut----------------------recuperation des specialites
    await fetch(host + "/specialite/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        if (result != null) {
          this.setState({
            specialites: result,
          });
        }
      })
      .catch((error) => console.log("error", error));
    // ---------------------------fin
    this.setState({ isLoading: false });
  }

  //Change profile Image
  changeProfileImage = (event) => {
    console.log("target:", event.target.files[0])
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = () => {
      // console.log("target2:", reader.result)
      this.setState({
        uploadedFile: reader.result,
        color: false,
        // imageProfile: Date.now() + event.target.files[0].name
      });
      // console.log("PHOTO_USER", event.target.files[0].name);
    };
      setTimeout(() => {
        this.updateImage();
      }, 3000);

  };

  fetchDataUse = () => {
    var myHeaders = new Headers();
    myHeaders.append("auth", this.state.auth);
    myHeaders.append("Content-Type", "application/json");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(host+`/user/${this.state.idUser}`, requestOptions)
      .then(response => {
        if(response.status === 200){
          // console.log("token", this.state.token);
          return response.json()
        }
        // console.log("token1", this.state.token);
      })
      .then(result => { 
        console.log("SS",result)
        this.setState({
          photo: result.photo
        })
      })
      .catch(error => console.log('error2:', error));
  } 

  //Update image
  updateImage = () => {

    console.log("uploadedFile:", this.state.uploadedFile)
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      data: this.state.uploadedFile,
    });

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(host + `/user/updateImage/${this.state.idUser}`, requestOptions)
      // fetch(host+`/user/updateImage/22`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "error") {
          console.log('objectErr',result );
          this.setState({
            color: true,
          });
        }
        this.fetchDataUse();
      })
      .catch((error) => {
         console.log("error", error);
      });
  };

  handlemenu = () => {};

  handleOnclick = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleChangeVille = (e) => {
    this.setState({
      idville: e.target.value,
    });
  };

  handleChangeSpecialite = (e) => {
    this.setState({
      idspecialite: e.target.value,
    });
  };

  handleOk = () => {
    this.setState({
      isModalVisible: false,
      isDetail: false,
    });
  };

  handleOkDelete = () => {
    console.log("this.state.idPs:",this.state.idPs)
    this.setState({
      isLoading: true,
    });
    let myHeaders = new Headers();

    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(host + `/ps/delete/${this.state.idPs}`, requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status == 200) {
          for (
            let index = 0;
            index < this.state.datatable.rows.length;
            index++
          ) {
            const item = this.state.datatable.rows[index];

            if (item.id == this.state.idPs) {
              this.state.allData.splice(this.state.index, 1);
              // this.state.datatable.rows.splice(index,1);
              this.setState({
                allData: this.state.allData,
                datatable: {
                  columns: [...this.state.datatable.columns],
                  rows: this.state.datatable.rows,
                },
              });
              break;
            }
          }
          this.setState({ email: "" });
          this.setState({ sexe: "" });
          this.setState({ password: "" });
          this.setState({ nom: "" });
          this.setState({ prenom: "" });
          this.setState({ number: "" });
          this.setState({ biographie: "" });
          this.setState({ createdAt: "" });
          this.setState({ idPs: null });
          this.setState({ idUser: null });
          this.setState({ index: null });
          toast.success("La suppression a été réalisé avec succès");

          this.setState({
            isDelete: false,
          });
        } else {
          toast.error("La suppression n'a pas été réalisé avec succès");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("La suppression n'a pas été réalisé avec succès" + error);
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  handleCancelDelete = () => {
    this.setState({
      isDelete: false,
    });
    this.setState({ email: "" });
    this.setState({ sexe: "" });
    this.setState({ password: "" });
    this.setState({ nom: "" });
    this.setState({ prenom: "" });
    this.setState({ number: "" });
    this.setState({ biographie: "" });
    this.setState({ createdAt: "" });
    this.setState({ idPs: null });
    this.setState({ idUser: null });
    this.setState({ index: null });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      isDetail: false,
    });
    this.setState({ email: "" });
    this.setState({ sexe: "" });
    this.setState({ password: "" });
    this.setState({ nom: "" });
    this.setState({ prenom: "" });
    this.setState({ number: "" });
    this.setState({ biographie: "" });
    this.setState({ createdAt: "" });
    this.setState({ idspecialite: "" });
    this.setState({ idville: "" });
  };

  handleChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleChangeSexe = (e) => {
    this.setState({ sexe: e.target.value });
  };
  handleSwitch = (e) => {
      console.log("handleSwitch:", e)
      this.setState({
        active:e
      })
  }

  handleChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };

  handleChangeNom = (e) => {
    this.setState({ nom: e.target.value });
  };

  handleChangePrenom = (e) => {
    this.setState({ prenom: e.target.value });
  };

  handleDelete = (e) => {
    this.setState({
      isDetail: false,
      isDelete: true,
    });
  };

  handleUpdate = (e) => {
    this.setState({
      isDetail: false,
      isUpdate: true,
      isModalVisible: true,
      idville: this.state.ville != null ? this.state.ville.idville : "",
      idspecialite:
        this.state.specialite != null ? this.state.specialite.idspecialite : "",
    });
  };

  handleChangeNumber = (e) => {
    this.setState({ number: e.target.value });
  };

  handleChangeBiographie = (e) => {
    this.setState({ biographie: e.target.value });
  };

  handleSubmit = (e) => {
    console.log("etat:", this.state.active)
    if (this.state.isUpdate) {
      this.setState({
        isLoading: true,
      });

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let ville, specialite;
      for (let i = 0; i < this.state.villes.length; i++) {
        const element = this.state.villes[i];
        if (element.idville == this.state.idville) {
          ville = element;
          break;
        }
      }
      for (let i = 0; i < this.state.specialites.length; i++) {
        const element = this.state.specialites[i];
        if (element.idspecialite == this.state.idspecialite) {
          specialite = element;
          break;
        }
      }

      let raw = JSON.stringify({
        username: this.state.email,
        isActive: this.state.active,
        role: "PS",
        photo: this.uploadedFile,
        ps: {
          idprofessionnelsante: this.state.idPs,
          nom: this.state.nom,
          prenom: this.state.prenom,
          email: this.state.email,
          sexe: this.state.sexe,
          numtelephone: this.state.number,
          biographie: this.state.biographie,
          ville,
          specialite,
        },
      });

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + `/user/update/${this.state.idUser}`, requestOptions)
        .then((response) => {
          console.log(response);
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200) {
            return null;
          }
          return response.text();
        })
        .then((result) => {
          if (result == null) {
            this.setState({
              isLoading: false,
            });
            toast.error("L'email est deja utilisé");
          } else {
            console.log("retour", result);
            console.log("result", JSON.parse(result));
            const data = JSON.parse(result);
            // this.updateImage();
            this.setState({
              isLoading: false,
            });
            toast.success("Modification réalisé avec succès");
            this.handleCancel();
            window.location.reload();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          toast.error("Une erreure c'est produite");
        });
    } else {
      this.setState({
        isLoading: true,
      });
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let raw = JSON.stringify({
        username: this.state.email,
        password: this.state.password,
        role: "PS",
        nom: this.state.nom,
        prenom: this.state.prenom,
        email: this.state.email,
        sexe: this.state.sexe,
        numtelephone: this.state.number,
        idville: this.state.idville,
        idspecialite: this.state.idspecialite,
        biographie: this.state.biographie,
        isActive: this.state.active
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + "/user/register/", requestOptions)
        .then((response) => {
          console.log(response);
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status == 409) {
            return null;
          }
          return response.text();
        })
        .then((result) => {
          if (result == null) {
            this.setState({
              isLoading: false,
            });
            toast.error("L'email est deja utilisé");
          } else {
            console.log("retour", result);
            console.log("result", JSON.parse(result));
            const data = JSON.parse(result);

            this.setState({
              isLoading: false,
            });
            toast.success("Insertion réalisé avec succes");

            this.setState({
              datatable: {
                columns: [...this.state.datatable.columns],
                rows: [
                  {
                    id: 0,
                    nom: this.state.nom,
                    slug: null,
                    prenom: this.state.prenom,
                    email: this.state.email,
                    tel: this.state.number,
                    biographie: this.state.biographie,
                    sexe: this.state.sexe,
                    user: null,
                    active: <Switch  checked={this.state.active}  disabled={this.state.disabled}/>
                  },
                  ...this.state.datatable.rows,
                ],
              },
            });
            this.handleCancel();
          }
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          console.log("error", error);
          toast.error("Une erreure c'est produite");
        });
    }
    e.preventDefault();
  };

  handleCalendar = ([debut, fin], dateStrings, info) => {
    if (debut && fin) {
      const a = Date.parse(debut);
      const b = Date.parse(fin);

      let items = this.state.allData.map((item) => {
        const x = Date.parse(item.user.createdAt);
        if (x >= a && x <= b) {
          return item;
        }
      });

      this.setState({
        datatable: {
          columns: [...this.state.datatable.columns],
          rows: items,
        },
      });
    }
  };


   

  // ------------------Gestions de l'image------------------------

  // Updat de l'image
  // updateImage = () => {
  //   console.log("Contenu:", this.state.photo)
  //   console.log("fileList:", this.state.fileList)
  //   if (this.state.fileList) {
  //     this.setState({
  //       isLoading: true,
  //     });
  //     let myHeaders = new Headers();
  //     myHeaders.append("Content-Type", "application/json");

  //     let raw = JSON.stringify({
  //       data: this.state.photo,
  //     });

  //     let requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     };

  //     fetch(
  //       host + `/user/updateImage/${this.state.idUser}`,
  //       requestOptions
  //     )
  //       .then((response) => {
  //         console.log("responseEreur:",response);
  //         if (response.status !== 200 && response.status !== 201) {
  //           return null;
  //         }
  //         return response.json();
  //       })
  //       .then((result) => {
  //         if (result !== null) {
  //           toast.success("insertion réussi");
  //           window.location.reload();
  //           this.cancelPick();
  //         } else {
  //           toast.error("Une erreur c'est produite");
  //         }
  //       })
  //       .catch((error) => {
  //          console.log("error", error);
  //         toast.error("Une erreur c'est produite");
  //       })
  //       .then(() => {
  //         this.setState({
  //           isLoading: false,
  //         });
  //       });
  //   } else {
  //     toast.warning("veuillez selectionner une image");
  //   }
  // };

  handleCancelFile = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChangeImage = ({ fileList }) =>{ 
    this.setState({ fileList })
      
  };
  handleCancelImage = () => this.setState({ previewVisible: false });

    //--------------------fin Gestions de l'image-----------------


  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        <ToastContainer />

        {/* Delete Modal */}
        <Modal
          title="Suppression du professionnel de santé"
          visible={this.state.isDelete}
          okText="Confirmer"
          cancelText="Annuler"
          okType="danger"
          onOk={this.state.isLoading ? null : this.handleOkDelete}
          onCancel={this.state.isLoading ? null : this.handleCancelDelete}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <div className="form-content p-2">
            <h4 className="modal-title">
              Supprimer {this.state.prenom + " " + this.state.nom}
            </h4>
            <Loader
              style={{
                position: "absolute",
                textAlign: "center",
                marginLeft: "auto",
                left: "0",
                right: "0",
                marginRight: "auto",
                zIndex: "20px",
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            />
            <p className="mb-4">Confimer vous la suppression</p>
          </div>
        </Modal>

        {/* Delete Modal */}

        <Modal
          title="Détail du professionnel de santé"
          visible={this.state.isDetail}
          onOk={this.handleOk}
          onCancel={this.handleCancelFile}
          destroyOnClose={true}
        >
          <div className="row ml-5" >
              <Image  height="90px" width="90px" alt="" src={this.state.photo !== "" ? this.state.photo  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} />
          </div> <br /> <br />
          <div className="row">      
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Nom :
            </p>
            <p className="col-sm-9">{this.state.nom}</p>
          </div>
          <div className="row">
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Prenom :
            </p>
            <p className="col-sm-9">{this.state.prenom}</p>
          </div>
          <div className="row">
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Sexe :
            </p>
            <p className="col-sm-9">{this.state.sexe}</p>
          </div>
          <div className="row">
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Email :
            </p>
            <p className="col-sm-9">{this.state.email}</p>
          </div>
          <div className="row">
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Telephone :
            </p>
            <p className="col-sm-9">{this.state.number}</p>
          </div>
          <div className="row">
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Biogaphie :
            </p>
            <p className="col-sm-9">{this.state.biographie}</p>
            <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
              Compte active 
            </p>
            <p className="col-sm-9">
            <Switch  checked={this.state.active}  disabled={this.state.disabled}/>
            </p>
          </div>
          <div className="row">
            <div className="col-sm-5"></div>
            <div className="col-sm-7">
              <a
                className="btn btn-sm bg-success-light"
                onClick={this.handleUpdate}
              >
                <i className="fe fe-pencil"></i> Modifier
              </a>
              <a
                className="btn btn-sm bg-danger-light"
                onClick={this.handleDelete}
              >
                <i className="fe fe-trash"></i> Supprimer
              </a>
            </div>
          </div>
        </Modal>

        <Modal
          title={
            this.state.isUpdate
              ? "Modification du professionnel de sante"
              : "Ajouter un professionnel de sante"
          }
          visible={this.state.isModalVisible}
          onOk={this.state.isLoading ? null : this.handleOk}
          onCancel={this.state.isLoading ? null : this.handleCancel}
          footer={null}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <form action="/" onSubmit={this.handleSubmit} id="addform">
            <div className="row">
            <div className="col-12 col-md-12">
                            <div className="form-group">
                              <div className="change-avatar">
                                <div className="profile-img">
                                <Image
                                width={150}
                                src={
                                  this.state.photo !== "" ? this.state.photo : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                                  // this.state.imageProfile === null ||
                                  // this.state.imageProfile === "" ||
                                  // this.state.imageProfile === undefined
                                  //   ? IMG01
                                  //   : this.state.imageProfile
                                }
                                className="img-fluid"                             
                                alt=""
                                  />
                                </div>
                                <div className="upload-img">
                                  <div className="change-photo-btn">
                                    <span>
                                      <i className="fa fa-upload"></i> Importer
                                      une photo
                                    </span>
                                    <input
                                      type="file"
                                      className="upload"
                                      name="userImage"
                                       onChange={this.changeProfileImage}
                                    />
                                  </div>
                                  <small className="form-text text-muted">
                                    {" "}
                                    <strong
                                      className={
                                        this.state.color == true ? "etoile" : ""
                                      }
                                    >
                                      Format autorisé JPG, JPEG ou PNG. Taille
                                      max de 2MB
                                    </strong>{" "}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>

                {/* <div>
                    <>
                      <Upload
                        // action={host + `/user/updateImage/${this.state.idUser}`}
                        action={this.updateImage}
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={this.handlePreview}
                        onChange={this.handleChangeImage}
                      >
                        {fileList.length >= 1 ? null : uploadButton}
                      </Upload>
                      <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={this.handleCancelImage}
                      >
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                      </Modal>
                    </>
                </div> */}


              <div className="form-group col-6 ml-6 mr-6">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Nom"
                  name="nom"
                  value={this.state.nom}
                  disabled={this.state.isLoading}
                  onChange={this.handleChangeNom}
                  required
                />
              </div>
              <div className="form-group col-6 ml-6 mr-6">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Prenom"
                  name="prenom"
                  value={this.state.prenom}
                  disabled={this.state.isLoading}
                  onChange={this.handleChangePrenom}
                  required
                />
              </div>
            </div>
            <div className="row">
              <label
                className="mr-5"
                style={{ verticalAlign: "center", marginLeft: "30px" }}
              >
                <input
                  className="mr-1"
                  type="radio"
                  name="sexe"
                  id="sexe"
                  value="masculin"
                  disabled={this.state.isLoading}
                  onChange={this.handleChangeSexe}
                  required
                  checked={this.state.sexe == "masculin" ? true : false}
                />
                <span className="">H</span>
              </label>
              <label className="" style={{ verticalAlign: "center" }}>
                <input
                  className="mr-1"
                  type="radio"
                  name="sexe"
                  id="sexe"
                  value="feminin"
                  disabled={this.state.isLoading}
                  onChange={this.handleChangeSexe}
                  required
                  checked={this.state.sexe == "feminin" ? true : false}
                />
                <span className="">F</span>
              </label><br /><br />
            </div>
            <div>
            <label className="" style={{ verticalAlign: "center" }}>
              <span> Compte active :</span> <Switch checked={this.state.active}     onChange={this.handleSwitch} />
              </label>
            </div>
            <div className="row">
              <div className="form-group col-6 ml-6 mr-6">
                <label>Ville</label>
                <select
                  className="form-control"
                  type="text"
                  name="ville"
                  value={this.state.idville}
                  disabled={this.state.isLoading}
                  onChange={this.handleChangeVille}
                  required
                >
                  <option value="">Selectionner une ville</option>
                  {this.state.villes != null
                    ? this.state.villes.map((ville) => {
                        return (
                          <option value={ville.idville}>{ville.libelle}</option>
                        );
                      })
                    : ""}
                </select>
              </div>
              <div className="form-group col-6 ml-6 mr-6">
                <label>Spécialite</label>
                <select
                  className="form-control"
                  type="text"
                  name="specialite"
                  value={this.state.idspecialite}
                  disabled={this.state.isLoading}
                  onChange={this.handleChangeSpecialite}
                  required
                >
                  <option value="">Specialité</option>
                  {this.state.specialites != null
                    ? this.state.specialites.map((specialite) => {
                        return (
                          <option value={specialite.idspecialite}>
                            {specialite.libelle}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
            </div>
            <div className="form-group">
              <input
                className="form-control"
                type="email"
                placeholder="Email"
                name="email"
                value={this.state.email}
                disabled={this.state.isLoading}
                onChange={this.handleChangeEmail}
                required
              />
            </div>
            <Loader
              style={{
                position: "absolute",
                textAlign: "center",
                marginLeft: "auto",
                left: "0",
                right: "0",
                marginRight: "auto",
                zIndex: "20px",
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            />
            <div className="form-group">
              <input
                className="form-control"
                type="text"
                placeholder="Numero de telephone"
                name="number"
                value={this.state.number}
                disabled={this.state.isLoading}
                onChange={this.handleChangeNumber}
                required
              />
            </div>
            {this.state.isUpdate ? (
              false
            ) : (
              <div className="form-group">
                <input
                  className="form-control"
                  type="password"
                  placeholder="Mot de passe"
                  name="password"
                  value={this.state.password}
                  disabled={this.state.isLoading}
                  onChange={this.handleChangePassword}
                  required
                />
              </div>
            )}

            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="biographie"
                name="biographie"
                value={this.state.biographie}
                disabled={this.state.isLoading}
                onChange={this.handleChangeBiographie}
                required
              />
            </div>
            <div className="form-group mb-0">
              <button
                className="btn btn-primary btn-block"
                type="submit"
                disabled={this.state.isLoading}
              >
                {this.state.isUpdate ? "Modifier" : "Ajouter"}
              </button>
            </div>
          </form>
        </Modal>

        <SidebarNav />

        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-7">
                  <h3 className="page-title">
                    Liste des professionnels de sante
                  </h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admin">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="#0">Utilisateur</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      Professionnel de santé
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Button
                  className="btn btn-primary  mb-2"
                  type="primary"
                  size="large"
                  disabled={this.state.isLoading}
                  onClick={this.state.isLoading ? null : this.handleOnclick}
                >
                  AJOUTER
                </Button>
                <div className="pull-right">
                  <RangePicker
                    placeholder={["Du", "Au"]}
                    disabled={this.state.isLoading}
                    onCalendarChange={this.handleCalendar}
                  />
                </div>
                <div
                  className={
                    this.state.isLoading &&
                    !this.state.isDelete &&
                    !this.state.isUpdate
                      ? "page_wrapper_blur card"
                      : "card"
                  }
                >
                  <div className="card-body">
                    <div className="table">
                      <Loader
                        style={{
                          position: "absolute",
                          textAlign: "center",
                          marginLeft: "auto",
                          left: "0",
                          right: "0",
                          marginRight: "auto",
                          zIndex: "2000",
                        }}
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={
                          this.state.isLoading &&
                          !this.state.isDelete &&
                          !this.state.isUpdate
                        }
                      />
                      <MDBDataTableV5
                        entriesOptions={[10, 20, 30]}
                        entries={10}
                        responsive={true}
                        entriesLabel={"Lignes par pages"}
                        searchLabel={"Rechercher"}
                        infoLabel={["", "-", "/", ""]}
                        noRecordsFoundLabel={"Aucune données de correspond"}
                        pagesAmount={1}
                        data={this.state.datatable}
                        searchTop
                        searchBottom={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Doctors;
