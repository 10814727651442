import React,{ Component } from 'react';

import { Link } from 'react-router-dom';
import {Modal } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon';
import { IMG01, IMG02, IMG03, IMG04, IMG05, IMG06, IMG07, IMG08, IMG09, IMG010, IMG011  } from './img';
class PatientChat extends Component{
    constructor(props) {
		super(props);
		this.state = {
			activeModal: null

		}
    }
	openModal= (id)=> {
        this.setState({activeModal: id}, () => {
              console.log(this.state.activeModal, 'value');
            }); 
	  }
	  
      handleCloseModal = () => {
          this.setState({activeModal: false}, () => {
              console.log(this.state.activeModal, 'state');
            }); 
		}

		componentDidMount(){
			document.body.classList.add('chat-page');
		}
		componentWillUnmount(){
			document.body.classList.remove('chat-page');
		}

    render(){
        return(
    <div>
        <div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-12">
							<div className="chat-window">
							
								
								<div className="chat-cont-left">
									<div className="chat-header">
										<span>Messagerie</span>
										<a href="#0" className="chat-compose">
											<i className="material-icons">control_point</i>
										</a>
									</div>
									<form className="chat-search">
										<div className="input-group">
											<div className="input-group-prepend">
												<i className="fas fa-search"></i>
											</div>
											<input type="text" className="form-control" placeholder="Chercher"/>
										</div>
									</form>
									<div className="chat-users-list">
										<div className="chat-scroll">
											<a href="#0" className="media">
												<div className="media-img-wrap">
													<div className="avatar avatar-away">
									<img src={IMG01} alt="User " className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Dr. YABADDA Armel</div>
														<div className="user-last-chat">Hey, comment allez-vous ?</div>
													</div>
													<div>
														<div className="last-chat-time block">2 min</div>
														<div className="badge badge-success badge-pill">15</div>
													</div>
												</div>
											</a>
											<a href="#0" className="media read-chat active">
												<div className="media-img-wrap">
													<div className="avatar avatar-online">
								<img src={IMG02} alt="User " className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Dr. GUY</div>
														<div className="user-last-chat">Je vous rappelle plus tard. </div>
													</div>
													<div>
														<div className="last-chat-time block">8:01 PM</div>
													</div>
												</div>
											</a>
											<a href="#0" className="media">
												<div className="media-img-wrap">
													<div className="avatar avatar-away">
								<img src={IMG03} alt="User " className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Dr. PRESTIGE Valere </div>
														<div className="user-last-chat">Explquez moi votre projet dans sa globalité</div>
													</div>
													<div>
														<div className="last-chat-time block">7:30 PM</div>
														<div className="badge badge-success badge-pill">3</div>
													</div>
												</div>
											</a>
											<a href="#0" className="media">
												<div className="media-img-wrap">
													<div className="avatar avatar-away">
									<img src={IMG04} alt="User " className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Dr. YABADDA Armel</div>
														<div className="user-last-chat">Hey, comment allez-vous ?</div>
													</div>
													<div>
														<div className="last-chat-time block">2 min</div>
														<div className="badge badge-success badge-pill">15</div>
													</div>
												</div>
											</a>
											<a href="#0" className="media read-chat active">
												<div className="media-img-wrap">
													<div className="avatar avatar-online">
								<img src={IMG05} alt="User " className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Dr. GUY</div>
														<div className="user-last-chat">Je vous rappelle plus tard. </div>
													</div>
													<div>
														<div className="last-chat-time block">8:01 PM</div>
													</div>
												</div>
											</a>
											<a href="#0" className="media">
												<div className="media-img-wrap">
													<div className="avatar avatar-away">
								<img src={IMG06} alt="User " className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Dr. PRESTIGE Valere </div>
														<div className="user-last-chat">Explquez moi votre projet dans sa globalité</div>
													</div>
													<div>
														<div className="last-chat-time block">7:30 PM</div>
														<div className="badge badge-success badge-pill">3</div>
													</div>
												</div>
											</a>
											<a href="#0" className="media">
												<div className="media-img-wrap">
													<div className="avatar avatar-away">
									<img src={IMG07} alt="User " className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Dr. YABADDA Armel</div>
														<div className="user-last-chat">Hey, comment allez-vous ?</div>
													</div>
													<div>
														<div className="last-chat-time block">2 min</div>
														<div className="badge badge-success badge-pill">15</div>
													</div>
												</div>
											</a>
											<a href="#0" className="media read-chat active">
												<div className="media-img-wrap">
													<div className="avatar avatar-online">
								<img src={IMG08} alt="User " className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Dr. GUY</div>
														<div className="user-last-chat">Je vous rappelle plus tard. </div>
													</div>
													<div>
														<div className="last-chat-time block">8:01 PM</div>
													</div>
												</div>
											</a>
											<a href="#0" className="media">
												<div className="media-img-wrap">
													<div className="avatar avatar-away">
								<img src={IMG09} alt="User " className="avatar-img rounded-circle" />
													</div>
												</div>
												<div className="media-body">
													<div>
														<div className="user-name">Dr. PRESTIGE Valere </div>
														<div className="user-last-chat">Explquez moi votre projet dans sa globalité</div>
													</div>
													<div>
														<div className="last-chat-time block">7:30 PM</div>
														<div className="badge badge-success badge-pill">3</div>
													</div>
												</div>
											</a>
											
										</div>
									</div>
								</div>
								
								<div className="chat-cont-right">
									<div className="chat-header">
										<a id="back_user_list" href="#0" className="back-user-list">
											<i className="material-icons">chevron_left</i>
										</a>
										<div className="media">
											<div className="media-img-wrap">
												<div className="avatar avatar-online">
								 <img src={IMG02} alt="User " className="avatar-img rounded-circle" />
												</div>
											</div>
											<div className="media-body">
												<div className="user-name">Dr. GUY</div>
												<div className="user-status">En ligne</div>
											</div>
										</div>
										<div className="chat-options">
											<a href="#0" data-toggle="modal" data-target="#voice_call" onClick={()=> this.openModal('voice')}>
												<i className="material-icons">local_phone</i> 
											</a>
											<a href="#0" data-toggle="modal" data-target="#video_call" onClick={()=> this.openModal('video')}>
												<i className="material-icons">videocam</i>
											</a>
											<a href="#0">
												<i className="material-icons">more_vert</i>
											</a>
										</div>
									</div>
									<div className="chat-body">
										<div className="chat-scroll">
											<ul className="list-unstyled">
												<li className="media sent">
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Bonjour, puis-je faire quelque chose pour vous ?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:30 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media received">
													<div className="avatar">
							<img src={IMG02} alt="User " className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Je regarde, c'est tout.</p>
																<p>Voulez-vous me dire quelque chose sur vous ?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:35 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<p>Êtes-vous là ? A ce moment !</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:40 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<div className="chat-msg-attachments">
																	<div className="chat-attachment">
																		<img src={IMG010} alt="Attachment" />
																		<div className="chat-attach-caption">placeholder.jpg</div>
																		<a href="#0" className="chat-attach-download">
																			<i className="fas fa-download"></i>
																		</a>
																	</div>
																	<div className="chat-attachment">
																		<img src={IMG09} alt="Attachment" />
																		<div className="chat-attach-caption">placeholder.jpg</div>
																		<a href="#0" className="chat-attach-download">
																			<i className="fas fa-download"></i>
																		</a>
																	</div>
																</div>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:41 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media sent">
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Où ?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:42 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<p>OK, mon nom est Richard. J'aime chanter, la danse, je joue également au basket.</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:42 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
														<div className="msg-box">
															<div>
																<div className="chat-msg-attachments">
																	<div className="chat-attachment">
																		<img src={IMG011} alt="Attachment" />
																		<div className="chat-attach-caption">placeholder.jpg</div>
																		<a href="#0" className="chat-attach-download">
																			<i className="fas fa-download"></i>
																		</a>
																	</div>
																</div>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:50 AM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="media received">
													<div className="avatar">
				                              <img src={IMG01} alt="User " className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>D'accord, je mets votre dossier à joour et je reviens vers vous.</p>
																<p>Avez vous d'autres choses à rajouter?</p>
																<p>Ok?</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>8:55 PM</span>
																		</div>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												<li className="chat-date">Aujourd'hui</li>
												<li className="media received">
													<div className="avatar">
					                          <img src={IMG02} alt="User " className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<p>Je vous rappelle plus tard dans la journée</p>
																<ul className="chat-msg-info">
																	<li>
																		<div className="chat-time">
																			<span>10:17 AM</span>
																		</div>
																	</li>
																	<li><a href="#0">Modifier</a></li>
																</ul>
															</div>
														</div>
													</div>
												</li>
												
												<li className="media received">
													<div className="avatar">
						          	<img src={IMG02} alt="User " className="avatar-img rounded-circle" />
													</div>
													<div className="media-body">
														<div className="msg-box">
															<div>
																<div className="msg-typing">
																	<span></span>
																	<span></span>
																	<span></span>
																</div>
															</div>
														</div>
													</div>
												</li>
											</ul>
										</div>
									</div>
									<div className="chat-footer">
										<div className="input-group">
											<div className="input-group-prepend">
												<div className="btn-file btn">
													<i className="fa fa-paperclip"></i>
													<input type="file" />
												</div>
											</div>
											<input type="text" className="input-msg-send form-control" placeholder="Ecrivez"/>
											<div className="input-group-append">
												<button type="button" className="btn msg-send-btn"><i className="fab fa-telegram-plane"></i></button>
											</div>
										</div>
									</div>
								</div>
							
								
							</div>
						</div>
					</div>
				
				</div>

			</div>		
							{/* modal for video*/}
                            <Modal show={this.state.activeModal === 'video'} onHide={this.handleCloseModal} centered>
					<Modal.Body>	
						<div class="call-box incoming-box">
							<div class="call-wrapper">
								<div class="call-inner">
									<div class="call-user">
										<img alt="User " src={IMG02} class="call-avatar" />
										<h4>Dr. GUY</h4>
										<span>Connexion...</span>
									</div>							
									<div class="call-items">
										<a href="#0" class="btn call-item call-end" data-dismiss="modal" aria-label="Close">
										<Icon>call_end</Icon>
										</a>
										<Link to="/voice-call" class="btn call-item call-start"><i class="material-icons">call</i></Link>
									</div>
								</div>
							</div>
						</div>
						</Modal.Body>
				</Modal>
				{/* modal for call*/}
				<Modal show={this.state.activeModal === 'voice'} onHide={this.handleCloseModal} centered>
					<Modal.Body>	
						<div class="call-box incoming-box">
							<div class="call-wrapper">
								<div class="call-inner">
									<div class="call-user">
										<img alt="User " src={IMG02} class="call-avatar" />
										<h4>Dr. GUY</h4>
										<span>Connexion...</span>
									</div>							
									<div class="call-items">
										<a href="#0" class="btn call-item call-end" data-dismiss="modal" aria-label="Close">
										<Icon>call_end</Icon>
										</a>
										<Link to="/voice-call" class="btn call-item call-start"><i class="material-icons">call</i></Link>
									</div>
								</div>
							</div>
						</div>
						</Modal.Body>
				</Modal>
			
		  </div>

        );
    }
}
export default PatientChat;