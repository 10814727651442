import React, { Component } from "react";
import loginBanner from "../../assets/images/login-banner.png";
//Icon
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import host from "../../../host";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button } from "antd";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      loadings: false,
      redirectToReferre: false,
      email: "",
      istrue: "",
      phone1: "",
    };
    this.submit = this.submit.bind(this);
  }

  submit(event) {
    event.preventDefault();
    this.setState({
      isLoading: true,
      redirectToReferre: true,
      loadings: true,
    });
    let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const email = reg.test(this.state.email);

    //Envoi du lien du de reinitialisation du mot de passe par mail
    if (email) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        email: this.state.email,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + "/auth/passwordReset", requestOptions)
        .then((response) => {
          console.log("Response: ", response);
          if (response.status == "200") {
            toast.success("Un lien vous a été envoyé sur votre boite mail.");
            // console.log("201: ", response);
            this.setState({
              loadings: false,
            });
          }
          if (response.status == "400") {
            toast.warn(
              "l’utilisateur avec cette adresse mail  n’existe pas.",
              {}
            );
            this.setState({
              loadings: false,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          toast.warn("Une erreur sait produite");
          this.setState({
            loadings: false,
          });
        });
    } else if (this.state.phone1) {
      //Envoi du lien de reinitialisation du mot de passe par SMS.

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        telephone: this.state.phone1,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + "/auth/passwordResetsms", requestOptions)
        .then((response) => {
          console.log("Response: ", response);
          if (response.status == "200") {
            toast.success(
              "Un lien de réinitialisation vous a été envoyé par sms."
            );
            // console.log("201: ", response);
            this.setState({
              loadings: false,
            });
          }
          if (response.status == "404"){
            toast.warn(" le numéro  n’existe pas.", {});
            // console.log("MsgConfirm: ", response);
            this.setState({
              loadings: false,
            });
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      toast.warn("Veuillez à bien remplir le champ.", {});
      this.setState({
        loadings: false,
      });
    }
  }

  componentDidMount() {
    let dbUser =
      JSON.parse(sessionStorage.getItem("user")) ||
      JSON.parse(sessionStorage.getItem("user"));
    if (dbUser != null || dbUser != undefined) {
      this.props.history.push("/home");
    }
    document.body.classList.add("account-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("account-page");
  }

  render() {
    return (
      <React.Fragment>
        <div className="content">
          <ToastContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="account-content">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-md-7 col-lg-6 login-left">
                      <img
                        src={loginBanner}
                        className="img-fluid"
                        alt="Login Banner"
                      />
                    </div>
                    <div className="col-md-12 col-lg-6 login-right">
                      <div className="login-header">
                        <h3>Mot de passe oublié ?</h3>
                        <br />
                        <p className="small text-muted">
                          Entrez votre e-mail{" "}
                          <em className="etoile">(recommandé)</em> ou numéro de
                          téléphone , un lien vous sera envoyé pour modifier
                          votre mot de passe{" "}
                        </p>
                      </div>
                      <form onSubmit={this.submit}>
                        <div className="form-group">
                          <label className="focus-label"></label>
                          {/* <input type="email" className="form-control" value={this.state.email} onChange={(e) => this.setState({email: e.target.value }) } placeholder="Votre adresse mail" required="required"/> */}
                          <div className="form-group" style={{ margin: "0" }}>
                            {this.state.istrue ? (
                              <PhoneInput
                                className="form-control"
                                country={"cg"}
                                value={this.state.phone1}
                                onChange={(phone1) => {
                                  this.setState({ phone1 });
                                  const regNumberPhone = /^[0-9]/;

                                  if (phone1 == "") {
                                    this.setState({
                                      istrue: false,
                                    });
                                  }
                                }}
                                inputProps={{
                                  name: "Numéro de téléphone",
                                  required: true,
                                }}
                              />
                            ) : (
                              <>
                                <input
                                  type="text"
                                  name="email"
                                  required="true"
                                  value={this.state.email}
                                  onChange={(e) => {
                                    this.setState({ email: e.target.value });
                                    const regNumberPhone = /^[0-9]/;
                                    if (
                                      regNumberPhone.test(e.target.value) ||
                                      e.target.value[0] == "+"
                                    ) {
                                      this.setState({
                                        istrue: true,
                                      });
                                    }
                                  }}
                                  className="form-control"
                                  placeholder="Votre adresse mail ou numéro de téléphone"
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="text-right">
                          <Link to="/login" className="forgot-link">
                            Vous vous souvenez de votre mot de passe ?
                          </Link>
                        </div>
                        <Button
                          type="submit"
                          className="btn btn-bluefonce pull-right"
                          loading={this.state.loadings}
                          onClick={this.submit}
                        >
                          Réinitialiser votre mot de passe
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
