import React, { Component } from "react";
import GestionnaireSidebar from "../sidebar";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import ProgressBar from "react-customizable-progressbar";
import StickyBox from "react-sticky-box";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Collapse } from "antd";
import Loader from "react-loader-spinner";
import host from "../../../../host";
import { MDBDataTableV5 } from "mdbreact";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Form from 'react-bootstrap/Form'


const { Panel } = Collapse;

class Centre extends Component {
  constructor(props) {
    super(props);

    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const token = dataJson && JSON.parse(dataJson);

    this.state = {
      auth: token,
      idcentre:"",
      gestionnaire: null,
      idgestionnairecentre:"",
      centres: [],
      centre: {
        libelle: null,
        tel1: null,
        tel2: null,
        fictif: null,
        adresse: null,
        complementadresse: null,
      },
      libelle: null,
      tel1: null,
      tel2: null,
      fictif: 0,
      adresse: null,
      complementadresse: null,
      isVisible: false,
      isUpdate: false,
      isLoading: false,
      columns: [
        {
          label: "Nom",
          field: "nom",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "libelle",
          },
        },
        {
          label: "Téléphone",
          field: "numtelephone",
          width: 100,
        },
        {
          label: "Adresse",
          field: "adresse",
          width: 50,
        },
        {
          label: "Actions",
          field: "actions",
          width: 50,
        },
      ],
      rows: [],
      isModalVisible: false,
      isModalVisibleAdd: false,
    };
  }

  
   showModal = () => {
   this.setState({
    isModalVisible: true,
   })
  };

   handleOk = () => {
        // in update Center
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("auth", this.state.auth);

        let raw = JSON.stringify({
          Slug: this.state.centre.Slug,
          libelle: this.state.libelle,
          tel1: this.state.tel1,
          tel2: this.state.tel2,
          adresse: this.state.adresse,
          fictif: this.state.fictif,
          complementadresse: this.state.complementadresse,
        });

        let requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        fetch(
          host + `/centres/update/${this.state.idcentre}`,
          requestOptions
        )
          .then((response) => {
            if (response.status == 401) {
              sessionStorage.removeItem("user");
              this.props.history.push("/admin/login");
              return null;
            }
            if (response.status != 200 && response.status != 201) {
              return null;
            }
            return response.json();
          })
          .then((result) => {
            if (result === null) {
              toast.error("La modification du centre a échoué");
            } else {
              this.setState({
                centre: {
                  idcentre: this.state.centre.idcentre,
                  Slug: this.state.centre.Slug,
                  libelle: this.state.libelle,
                  tel1: this.state.tel1,
                  tel2: this.state.tel2,
                  adresse: this.state.adresse,
                  fictif: this.state.fictif,
                  complementadresse: this.state.complementadresse,
                  isModalVisible: false,
                },
              });
              this.allCenter()
              toast.success("Modification réalisée avec succès");
            }
            this.setState({
              isModalVisible: false,
            });
          })
          .catch((error) => {
            this.setState({
              isModalVisible: false,
            });
            toast.error("La modification du centre a échoué");
            console.log("error", error);
          });

    // this.setState({
    //   isModalVisible: false,
    //  })
  };

   handleCancel = () => {
    this.setState({
      isModalVisible: false,
     })
  };

  //for add a new center
  showModalAdd = () => {
    this.setState({
      isModalVisibleAdd: true,
      libelle: "",
      tel1: "",
      tel2: "",
      adresse: "",
      complementadresse: ""
    })
   };
 
    handleOkadd = () => {
      this.setState({
        isModalVisibleAdd: false,
        })
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("auth", this.state.auth);

        let raw = JSON.stringify({
          Slug: this.state.libelle,
          libelle: this.state.libelle,
          tel1: this.state.tel1,
          tel2: this.state.tel2,
          adresse: this.state.adresse,
          fictif: 0,
          complementadresse: this.state.complementadresse,
          id: this.state.idgestionnairecentre,
        });

        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        fetch(host + "/centres/create", requestOptions)
          .then((response) => {
            if (response.status == 401) {
              sessionStorage.removeItem("user");
              this.props.history.push("/admin/login");
              return null;
            }
            if (response.status != 200 && response.status != 201) {
              return null;
            }
            return response.json();
          })
          .then((result) => {
            if (result === null) {
              toast.error("L'insertion du centre a échoué");
            } else {
              this.setState({
                isModalVisibleAdd: false,
              });
              toast.success("L'insertion réalisée avec succès");
              this.allCenter()
            }
            this.setState({
              isModalVisibleAdd: false,
            });
          })
          .catch((error) => {
            this.setState({
              isModalVisibleAdd: false,
            });
            toast.error("L'insertion du centre a échoué");
            console.log("error", error);
          });
   };
 
    handleCancelAdd = () => {
     this.setState({
      isModalVisibleAdd: false,
      })
   };


  allCenter = () => {
    this.setState({
      isLoading: true,
    });
    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const data = dataJson && JSON.parse(dataJson);
    this.setState({
      idgestionnairecentre: data.user.gestionnairecentre.idgestionnairecentre
    })

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(
      host +
        `/gestionnairecentre/${data.user.gestionnairecentre.idgestionnairecentre}`,
      requestOptions
    )
      .then((response) => {
        if (response.status == 401) {
          sessionStorage.removeItem("user");
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status !== 200 && response.status !== 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        console.log("result:", result);
        if (result == null) {
        } else {
          if (result.gestionnaire.centre.length > 0) {
            let centres = result.gestionnaire.centre;
            let rows = [];
                for (let index = 0; index < centres.length; index++) {
                    const centre = centres[index];
                      rows.push( {
                        nom: centre.libelle,
                        numtelephone: centre.tel1,
                        adresse: centre.adresse,
                        actions: <div className="actions">
                           <a
                            href="#0"
                            className="btn btn-sm bg-success-light"
                             onClick={() => this.showUpdate(centre)}
                          >
                             <i className="fe fe-pencil"></i> Modifier
                          </a>
                          <a
                            className="btn btn-sm bg-danger-light"
                             onClick={() => this.confirm(centre)}
                          >
                            <i className="fe fe-trash"></i> Supprimer
                          </a>
                        </div>
                       })
                }
                this.setState({
                    rows: rows
                })


          } else {
            this.setState({
              gestionnaire: result.gestionnaire,
            });
          }
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log("error", error);
      });
  }



  componentDidMount() {
    this.allCenter()
  }

  showUpdate = (id) => {
    this.setState({
      libelle: id.libelle,
      idcentre:id.idcentre,
      tel1: id.tel1,
      tel2: id.tel2,
      adresse: id.adresse,
      complementadresse: id.complementadresse,
    })
    this.showModal()
  }
  showDelete = (id) => {
    console.log("delete:",id)
    var requestOptions = {
      method: 'DELETE',
      redirect: 'follow'
    };
    fetch(host+`/centres/delete/${id.idcentre}`, requestOptions)
      .then(response => {
        if(response.status === 200){
          return response.json()
        }
      })
      .then(result =>{
        this.allCenter()
        this.setState({
          isModalVisibleAdd: false,
        });
        // console.log(result)
      })
      .catch(error => {
        // console.log('error', error)
        this.setState({
          isModalVisibleAdd: false,
        });
        toast.error("La suppréssion du centre a échoué");
      });
  }

   confirm = (id) => {
    Modal.confirm({
      title: 'Êtes-vous sûr de supprimer cet élement ?',
      icon: <ExclamationCircleOutlined />,
      // content: 'Voulez-vous ',
      okText: <a onClick={() => this.showDelete(id)}>supprimé</a>,
      cancelText: 'annulé',
    });
  }
  

 



  handleSubmit = (e) => {
    e.preventDefault();

    if (true) {
      this.setState({
        isLoading: true,
      });

      if (!this.state.isUpdate) {
        //in insert
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("auth", this.state.auth);

        let raw = JSON.stringify({
          Slug: this.state.libelle,
          libelle: this.state.libelle,
          tel1: this.state.tel1,
          tel2: this.state.tel2,
          adresse: this.state.adresse,
          fictif: this.state.fictif,
          complementadresse: this.state.complementadresse,
          id: this.state.gestionnaire.idgestionnairecentre,
        });

        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        fetch(host + "/centres/create", requestOptions)
          .then((response) => {
            if (response.status == 401) {
              sessionStorage.removeItem("user");
              this.props.history.push("/admin/login");
              return null;
            }
            if (response.status != 200 && response.status != 201) {
              return null;
            }
            return response.json();
          })
          .then((result) => {
            if (result === null) {
              toast.error("L'insertion du centre a échoué");
            } else {
              this.setState({
                isLoading: false,
                centre: result.centre,
                // centre: {
                //   libelle: this.state.libelle,
                //   tel1: this.state.tel1,
                //   tel2: this.state.tel2,
                //   adresse: this.state.adresse,
                //   fictif: this.state.fictif,
                //   complementadresse: this.state.complementadresse,
                // },
              });
              this.handleCancel();
              toast.success("L'insertion réalisée avec succès");
            }
            this.setState({
              isLoading: false,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
            toast.error("L'insertion du centre a échoué");
            console.log("error", error);
          });
      } else {
        // in update
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("auth", this.state.auth);

        let raw = JSON.stringify({
          Slug: this.state.centre.Slug,
          libelle: this.state.libelle,
          tel1: this.state.tel1,
          tel2: this.state.tel2,
          adresse: this.state.adresse,
          fictif: this.state.fictif,
          complementadresse: this.state.complementadresse,
        });

        let requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        fetch(
          host + `/centres/update/${this.state.centre.idcentre}`,
          requestOptions
        )
          .then((response) => {
            if (response.status == 401) {
              sessionStorage.removeItem("user");
              this.props.history.push("/admin/login");
              return null;
            }
            if (response.status != 200 && response.status != 201) {
              return null;
            }
            return response.json();
          })
          .then((result) => {
            if (result === null) {
              toast.error("La modification du centre a échoué");
            } else {
              this.setState({
                isLoading: false,
                centre: {
                  idcentre: this.state.centre.idcentre,
                  Slug: this.state.centre.Slug,
                  libelle: this.state.libelle,
                  tel1: this.state.tel1,
                  tel2: this.state.tel2,
                  adresse: this.state.adresse,
                  fictif: this.state.fictif,
                  complementadresse: this.state.complementadresse,
                },
              });
              this.handleCancel();
              toast.success("Modification réalisée avec succès");
            }
            this.setState({
              isLoading: false,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
            toast.error("La modification du centre a échoué");
            console.log("error", error);
          });
      }
    }
  };

  render() {
    return (
      <div>
        <ToastContainer />              
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <GestionnaireSidebar />
                </StickyBox>
              </div>
              <div
                className={
                  this.state.isLoading
                    ? "page_wrapper_blur col-md-7 col-lg-8 col-xl-9"
                    : "col-md-7 col-lg-8 col-xl-9"
                }
              >
                <div className="card">
                  <div className="card-body">
                    <div className="card card-table">
                      <div className="card-body">
                      <div className="submit-section"><br />
                            <button
                              type="submit"
                              className="btn btn-md btn-bluefonce submit-btn"                           
                                 onClick={this.showModalAdd}
                            >
                              Inserer le centre
                            </button>
                          
                        </div>
                        <div className="table" style={{ padding: "5%" }}>
                        <MDBDataTableV5
                              entriesOptions={[10, 20, 30]}
                              entries={10}
                              responsive={true}
                              entriesLabel={"Lignes par pages"}
                              searchLabel={"Rechercher"}
                              infoLabel={["", "-", "/", ""]}
                              noRecordsFoundLabel={
                                "Aucune données de correspond"
                              }
                              pagesAmount={1}
                              data={{
                                rows: this.state.rows,
                                columns: this.state.columns,
                              }}
                              searchTop
                              searchBottom={false}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Loader
            style={{
              position: "absolute",
              marginTop: "-30%",
              zIndex: 100,
            }}
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            visible={this.state.isLoading}
          />
        </div>

        {/* //Modal Modifier  centre */}
        <Modal title="Modification" visible={this.state.isModalVisible} cancelText="Annuler" okText="valider" onOk={this.handleOk} onCancel={this.handleCancel}>
              <form action="">
                <div>
                    <label htmlFor="libelle">Nom</label><br />
                    <input className="form-control" type="text" value={this.state.libelle} onChange={(e) => this.setState({libelle: e.target.value})} name="libelle" id="libelle" />
                </div><br />
                <div>
                    <label htmlFor="adresse">adresse </label><br />
                    <input  className="form-control" type="text" value={this.state.adresse} onChange={(e) => this.setState({adresse: e.target.value})} name="adresse" id="adresse" />
                </div><br />
                <div>
                    <label htmlFor="complementadresse">complementadresse</label><br />
                    <input className="form-control" type="text"  value={this.state.complementadresse} onChange={(e) => this.setState({complementadresse: e.target.value})} name="complementadresse" id="complementadresse" />
                </div><br />
                <div>
                    <label htmlFor="tel1">Téléphone 1</label><br />
                    <input  className="form-control" type="text"  value={this.state.tel1}  onChange={(e) => this.setState({tel1: e.target.value})} name="tel1" id="tel1" />
                </div><br />
                <div>
                    <label htmlFor="tel2">Téléphone 2</label><br />
                    <input className="form-control" type="text" value={this.state.tel2}  onChange={(e) => this.setState({tel2: e.target.value})} name="tel2" id="tel2" />
                </div>
              </form>
         </Modal>

       {/* //Modal ajouter un  centre */}
       <Modal title="Ajouter un centre " visible={this.state.isModalVisibleAdd} cancelText="Annuler" okText="valider" onOk={this.handleOkadd} onCancel={this.handleCancelAdd}>
              <form action="">
                <div>
                    <label htmlFor="libelle">Nom</label><br />
                    <input className="form-control" type="text" value={this.state.libelle} onChange={(e) => this.setState({libelle: e.target.value})} name="libelle" id="libelle" />
                </div><br />
                <div>
                    <label htmlFor="adresse">adresse </label><br />
                    <input  className="form-control" type="text" value={this.state.adresse} onChange={(e) => this.setState({adresse: e.target.value})} name="adresse" id="adresse" />
                </div><br />
                <div>
                    <label htmlFor="complementadresse">complementadresse</label><br />
                    <input className="form-control" type="text"  value={this.state.complementadresse} onChange={(e) => this.setState({complementadresse: e.target.value})} name="complementadresse" id="complementadresse" />
                </div><br />
                <div>
                    <label htmlFor="tel1">Téléphone 1</label><br />
                    <input  className="form-control" type="text"  value={this.state.tel1}  onChange={(e) => this.setState({tel1: e.target.value})} name="tel1" id="tel1" />
                </div><br />
                <div>
                    <label htmlFor="tel2">Téléphone 2</label><br />
                    <input className="form-control" type="text" value={this.state.tel2}  onChange={(e) => this.setState({tel2: e.target.value})} name="tel2" id="tel2" />
                </div>
              </form>
      </Modal>
      </div>
    );
  }
}

export default Centre;
