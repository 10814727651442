import React, { Component } from "react";
import { IMG, IMG01, IMG02, IMG03, IMG04, IMG05, IMG06 } from "./img.jsx";

//slider
import Slider from "react-slick";

class HomeFeatures extends Component {
  render() {
    const settings = {
      className: "center",

      centerMode: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 6,
      centerPadding: "50px",
      arrows: false,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 0,
            infinite: true,
          },
        },
        // {
        //   breakpoint: 993,
        //   settings: {
        //     slidesToShow: 3,
        //     slidesToScroll: 3,
        //     infinite: true,

        //   }
        // }
      ],
    };
    return (
      <section className="section section-features">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5 col-sm-12 features-img mt-4">
              <img
                style={{ borderRadius: "2%" }}
                src="https://images.unsplash.com/photo-1516549655169-df83a0774514?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
                className=" img-fluid"
                alt="Feature"
              />
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="section-header">
                <h2 className="mt-4">
                  Prestations proposées par nos professionnels de santé
                </h2>
                <p>
                  Notre plateforme novatrice est conçue pour faciliter l’accès
                  aux interventions et aux consultations, afin que tous puissent
                  recevoir un appui lorsqu’ils en ont besoin.
                </p>
              </div>
              <div className="features-slider slider">
                <Slider {...settings}>
                  {/* <div>
                    <div className="text-center">
                      <img src={IMG01} style={{ 'borderRadius': "5%" }} className="img-fluid" alt="Feature" />
                      <p>Blog ff</p>
                    </div>
                  </div> */}
                  <div>
                    <div className="text-center">
                      <img
                        src={IMG02}
                        style={{ borderRadius: "5%" }}
                        className="img-fluid"
                        alt="Feature"
                      />
                      <p>Cancerologie</p>
                    </div>
                  </div>
                  <div>
                    <div className="text-center">
                      <img
                        src={IMG03}
                        style={{ borderRadius: "5%" }}
                        className="img-fluid"
                        alt="Feature"
                      />
                      <p>Soins intensifs</p>
                    </div>
                  </div>
                  <div>
                    <div className="text-center">
                      <img
                        src={IMG04}
                        style={{ borderRadius: "5%" }}
                        className="img-fluid"
                        alt="Feature"
                      />
                      <p>Laboratoire</p>
                    </div>
                  </div>
                  <div>
                    <div className="text-center">
                      <img
                        src={IMG05}
                        style={{ borderRadius: "5%" }}
                        className="img-fluid"
                        alt="Feature"
                      />
                      <p>Pediatrie </p>
                    </div>
                  </div>
                  <div>
                    <div className="text-center">
                      <img
                        src={IMG06}
                        style={{ borderRadius: "5%" }}
                        className="img-fluid"
                        alt="Feature"
                      />
                      <p>Cardiologie</p>
                    </div>
                  </div>

                  <div>
                    <div className="text-center">
                      <img
                        src={IMG01}
                        className="img-fluid"
                        style={{ borderRadius: "5%" }}
                        alt="Feature"
                      />
                      <p>Bloc opératoire</p>
                    </div>
                  </div>
                  <div>
                    <div className="text-center">
                      <img
                        src={IMG02}
                        className="img-fluid"
                        style={{ borderRadius: "5%" }}
                        alt="Feature"
                      />
                      <p>Diabetologie</p>
                    </div>
                  </div>
                  <div>
                    <div className="text-center">
                      <img
                        src={IMG03}
                        className="img-fluid"
                        style={{ borderRadius: "5%" }}
                        alt="Feature"
                      />
                      <p>Soins intensifs</p>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default HomeFeatures;
