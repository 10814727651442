import React, { Component } from "react";
import Loader from "react-loader-spinner";
import StickyBox from "react-sticky-box";
import { ToastContainer, toast } from "react-toastify";
import GestionnaireSidebar from "../sidebar";
import { MDBDataTableV5 } from "mdbreact";
import host from "../../../../host";
const ModalAntd = require("antd").Modal;

class Prestations extends Component {
  constructor(props) {
    super(props);

    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const token = dataJson && JSON.parse(dataJson).token;

    this.state = {
      auth: token,
      centre: [],
      centreSelected:null,
      gestionnaire: null,
      prestations: [],

      isLoading: false,
      isDelete: false,
      isVisible: false,
      isUpdate: false,

      libelle: null,
      prixEuro: null,
      prixXAF: null,
      modalite: "physique",
      idPrestation: 0,
      rows: [],

      columns: [
        {
          label: "",
          field: "libelle",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "libelle",
          },
        },
        {
          label: "Prix en euro",
          field: "pEUR",
          width: 100,
        },
        {
          label: "Prix en XFA",
          field: "pXAF",
          width: 100,
        },
        {
          label: "Modalité",
          field: "modalite",
          width: 50,
        },
        {
          label: "Actions",
          field: "actions",
          width: 50,
        },
      ],
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const data = dataJson && JSON.parse(dataJson);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);

    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: myHeaders,
    };

    fetch(
      host +
        `/gestionnairecentre/${data.user.gestionnairecentre.idgestionnairecentre}`,
      requestOptions
    )
      .then((response) => {
        if (response.status == 401) {
          sessionStorage.removeItem("user")
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        console.log("resultGEST:",result);
        if (result == null) {
          this.handleCancel();
        } else {
          if (result.gestionnaire.centre.length > 0) {
            // const centre = result.gestionnaire.centre[0];
            const centre = result.gestionnaire.centre;
            const prestations = result.gestionnaire.centre[0].prestations;

            for (let index = 0; index < prestations.length; index++) {
              prestations[index]["actions"] = (
                <div className="actions">
                  <a
                    href="#0"
                    className="btn btn-sm bg-success-light"
                    onClick={() => this.showUpdate(prestations[index])}
                  >
                    <i className="fe fe-pencil"></i> Modifier
                  </a>
                  <a
                    className="btn btn-sm bg-danger-light"
                    onClick={() => this.showDelete(prestations[index])}
                  >
                    <i className="fe fe-trash"></i> Supprimer
                  </a>
                </div>
              );
            }

            this.setState({
              centre,
              prestations: prestations,
              rows: prestations,
              gestionnaire: result.gestionnaire,
            });
          } else {
            this.setState({
              gestionnaire: result.gestionnaire,
            });
          }
          this.handleCancel();
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.handleCancel();
      });
  }

  handleChange = (e, tag) => {
    this.state[tag] = e.target.value;
    this.setState({});
  };

  showDelete = (prestation) => {
    this.setState({
      isDelete: true,
      idPrestation: prestation.id,
    });
  };

  showUpdate = (prestation) => {
    this.setState({
      isVisible: true,
      isUpdate: true,
      libelle: prestation.libelle,
      prixXAF: prestation.pXAF,
      prixEuro: prestation.pEUR,
      modalite: prestation.modalite,
      idPrestation: prestation.id,
    });
  };

  handleCancel = () => {
    this.setState({
      isLoading: false,
      isDelete: false,
      isVisible: false,
      isUpdate: false,
      libelle: null,
      prixEuro: null,
      prixXAF: null,
      modalite: "physique",
      idPrestation: 0,
    });
  };
  handleChangeCentre = (e) =>{
    console.log("handleChangeCentre:", e.target.value)
    this.setState({
      centreSelected:e.target.value
    })
  }

  submit = (e) => {
    e.preventDefault();

    if (true) {
      this.setState({
        isLoading: true,
      });
      if (!this.state.isUpdate) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("auth", this.state.auth);

        let raw = JSON.stringify({
          slug: this.state.libelle,
          prixXAF: Math.round(this.state.prixXAF * 100) / 100,
          prixEuro: Math.round(this.state.prixEuro * 100) / 100,
          modalite: this.state.modalite,
          libelle: this.state.libelle,
          // idcentre: this.state.centre.idcentre,
          idcentre: this.state.centreSelected,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(host + "/prestations/create", requestOptions)
          .then((response) => {
            if (response.status == 401) {
              sessionStorage.removeItem("user")
              this.props.history.push("/admin/login");
              return null;
            }
            if (response.status != 200 && response.status != 201) {
              return null;
            }
            return response.json();
          })
          .then((result) => {
            if (result == null) {
              toast.error("L'insertion de la prestation a échoué");
            } else {
              const prestation = result.prestation;
              prestation["actions"] = (
                <div className="actions">
                  <a
                    href="#0"
                    className="btn btn-sm bg-success-light"
                    onClick={() => this.showUpdate(prestation)}
                  >
                    <i className="fe fe-pencil"></i> Modifier
                  </a>
                  <a
                    className="btn btn-sm bg-danger-light"
                    onClick={() => this.showDelete(prestation)}
                  >
                    <i className="fe fe-trash"></i> Supprimer
                  </a>
                </div>
              );
              this.setState({
                prestations: [prestation, ...this.state.prestations],
              });
              toast.success("Insertion réalisée avec succès");
              this.handleCancel();
            }
            this.setState({
              isLoading: false,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
            toast.error("L'insertion de la prestation a échoué");
            console.log("error", error);
          });
      } else {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("auth", this.state.auth);

        let raw = JSON.stringify({
          slug: this.state.libelle,
          prixXAF: Math.round(this.state.prixXAF * 100) / 100,
          prixEuro: Math.round(this.state.prixEuro * 100) / 100,
          modalite: this.state.modalite,
          libelle: this.state.libelle,
          idcentre: this.state.centreSelected,
        });

        const requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          host + `/prestations/update/${this.state.idPrestation}`,
          requestOptions
        )
          .then((response) => {
            if (response.status == 401) {
              sessionStorage.removeItem("user")
              this.props.history.push("/admin/login");
              return null;
            }
            if (response.status != 200 && response.status != 201) {
              return null;
            }
            return response.json();
          })
          .then((result) => {
            if (result == null) {
              toast.error("La modification de la prestation a échoué");
            } else {
              for (
                let index = 0;
                index < this.state.prestations.length;
                index++
              ) {
                const element = this.state.prestations[index];
                if (element.id === this.state.idPrestation) {
                  this.state.prestations[index].slug = this.state.libelle;
                  this.state.prestations[index].modalite = this.state.modalite;
                  this.state.prestations[index].libelle = this.state.libelle;

                  this.state.prestations[index].pXAF =
                    Math.round(this.state.prixXAF * 100) / 100;

                  this.state.prestations[index].pEUR =
                    Math.round(this.state.prixEuro * 100) / 100;
                }
              }
              this.setState({});
              toast.success("Modification réalisée avec succès");
              this.handleCancel();
            }
            this.setState({
              isLoading: false,
            });
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
            toast.error("La modification de la prestation a échoué");
            console.log("error", error);
          });
      }
    }
  };

    //change centre 
    handleChangeSelect(e) {
			let tabRdv = [];
			let tabCentreSelected = [];
			for (let index = 0; index < this.state.centre.length; index++) {
				const element = this.state.centre[index];
					if(element.idcentre == e.target.value){
							 console.log("ELEMENT_ELL:",element);
							// this.setState({
							// 	centreSelected:element
							// })
							for (let i = 0; i < element.prestations.length; i++) {
								const elementPrestation = element.prestations[i];
							tabRdv.push({
                libelle: elementPrestation.libelle,
                pEUR:elementPrestation.pEUR,
								pXAF: elementPrestation.pXAF,
								modalite: elementPrestation.modalite,
                actions: <div className="actions">
                            <a
                              href="#0"
                              className="btn btn-sm bg-success-light"
                              onClick={() => this.showUpdate(elementPrestation)}
                            >
                              <i className="fe fe-pencil"></i> Modifier
                            </a>
                            <a
                              className="btn btn-sm bg-danger-light"
                              onClick={() => this.showDelete(elementPrestation)}
                            >
                              <i className="fe fe-trash"></i> Supprimer
                            </a>
                          </div>
							  },)
								
							}
					}		
			}
			this.setState({					
					rows: tabRdv
			})
				
		}


  handleEuro = (e) => {
    this.setState({
      prixEuro: e.target.value,
      prixXAF: e.target.value * 650,
    });
  };

  handleXaf = (e) => {
    this.setState({
      prixEuro: e.target.value / 650,
      prixXAF: e.target.value,
    });
  };

  delete = () => {
    this.setState({
      isLoading: true,
    });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: "",
      redirect: "follow",
    };
    fetch(
      host + `/prestations/delete/${this.state.idPrestation}`,
      requestOptions
    )
      .then((response) => {
        if (response.status == 401) {
          sessionStorage.removeItem("user")
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return "201";
      })
      .then((result) => {
        if (result == null) {
          toast.error("La suppression de la prestation a échoué");
        } else {
          let tmp = this.state.prestations;
          for (let index = 0; index < tmp.length; index++) {
            const element = tmp[index];
            if (element.id == this.state.idPrestation) {
              tmp.splice(index, 1);
              break;
            }
          }
          this.setState({ prestations: [...tmp] });
          toast.success("Suppression réalisée avec succès");
          this.handleCancel();
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        toast.error("La suppression de la prestation a échoué");
        console.log("error", error);
      });
  };



  render() {

        console.log("centre:",this.state.centre)

    return (
      <div>
        <ToastContainer />
        {/* Delete Modal */}
        <ModalAntd
          title="Suppression d'une prestation"
          visible={this.state.isDelete}
          okText="Confirmer"
          cancelText="Annuler"
          okType="danger"
          onOk={this.state.isLoading ? null : this.delete}
          onCancel={this.state.isLoading ? null : this.handleCancel}
          destroyOnClose={true}
        >
          <div
            className={
              this.state.isLoading
                ? "page_wrapper_blur form-content p-2"
                : "form-content p-2"
            }
          >
            <h4 className="modal-title">Suppression d'une prestation</h4>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <Loader
                style={{
                  position: "absolute",
                  // marginTop: "-30%",
                  zIndex: 100,
                }}
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                visible={this.state.isLoading}
              />
            </div>
            <p className="mb-4">Confimer vous la suppression</p>
          </div>
        </ModalAntd>

        {/* Delete Modal */}

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <StickyBox offsetTop={20} offsetBottom={20}>
                  <GestionnaireSidebar />
                </StickyBox>
              </div>

              <div
                className={
                  this.state.isLoading
                    ? "page_wrapper_blur col-md-7 col-lg-8 col-xl-9"
                    : "col-md-7 col-lg-8 col-xl-9"
                }
              >
                  
                    
                  
                <div className="card">
                  <div className="card-body">
                    <div className="card card-table">
                    <div className="col-md-6">
                     <span>Centre :</span> <br />
                      <select
                            id="lang"
                             onChange={(e) => this.handleChangeSelect(e)}
                            // value={this.state.prestaChoisiLibelle.libelle}
                            className="form-control"
                          >
                              <option> selectionnez un centre</option>
                            {this.state.centre.map((centre) => (
                              <option value={centre.idcentre}>
                                {centre.libelle}
                              </option>
                            ))}
                          </select>
                     </div>
                      <div className="card-body">
                        <div className="table" style={{ padding: "5%" }}>
                          {this.state.prestations.length > 0 ? (
                            <MDBDataTableV5
                              entriesOptions={[10, 20, 30]}
                              entries={10}
                              responsive={true}
                              entriesLabel={"Lignes par pages"}
                              searchLabel={"Rechercher"}
                              infoLabel={["", "-", "/", ""]}
                              noRecordsFoundLabel={
                                "Aucune données de correspond"
                              }
                              pagesAmount={1}
                              data={{
                                rows: this.state.rows,
                                columns: this.state.columns,
                              }}
                              searchTop
                              searchBottom={false}
                            />
                          ) : (
                            <div class="jumbotron jumbotron-fluid">
                              <div class="container">
                                <h1 class="display-4">Prestations</h1>
                                <p class="lead">
                                  {" "}
                                  Vous n'avez pas encore insérer de prestation.
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="submit-section">
                          {this.state.centre === null ? (
                            <label style={{ color: "red" }}>
                              Inserer le centre pour ajouter des prestations
                            </label>
                          ) : (
                            <button
                              type="submit"
                              disabled={this.state.isLoading}
                              className="btn btn-bluefonce submit-btn"
                              onClick={() => {
                                this.setState({ isVisible: true });
                              }}
                            >
                              Ajouter une Prestation
                            </button>
                          )}
                          &nbsp;&nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Loader
            style={{
              position: "absolute",
              marginTop: "-30%",
              zIndex: 100,
            }}
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            visible={this.state.isLoading && !this.state.isDelete}
          />
        </div>

        <ModalAntd
          title={
            this.state.isUpdate
              ? "Modification d'une prestation"
              : "Ajouter une prestation"
          }
          visible={this.state.isVisible}
          //   onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[]}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <form className="form" onSubmit={this.submit}>
            <div style={{ display: "flex" }}>
              <div
                className="form-group"
                style={{ marginLeft: "2%", width: "100%" }}
              >
                <label htmlFor="dateDebut">&nbsp;Nom</label>
                <input
                  className="form-control"
                  type="text"
                  value={this.state.libelle}
                  required
                  onChange={(e) => this.handleChange(e, "libelle")}
                />
              </div>
              <div
                className="form-group"
                style={{ marginLeft: "2%", width: "100%", float: "ri" }}
              >
                <label htmlFor="heureFin">&nbsp;Modalité</label>
                <select
                  id="lang"
                  onChange={(e) => this.handleChange(e, "modalite")}
                  value={this.state.modalite}
                  className="form-control"
                  required
                >
                  <option value="physique">Physique</option>
                  {/* <option value="video">Video</option> */}
                </select>
              </div>
              <div
                className="form-group"
                style={{ marginLeft: "2%", width: "100%", float: "ri" }}
              >
                <label htmlFor="heureFin">&nbsp;Centre</label>
                <select
                  id="lang"
                  onChange={(e) => this.handleChangeCentre(e)}
                  // value={this.state.modalite}
                  className="form-control"
                  required
                >
                  <option> selectionné un centre </option>
                    {
                   this.state.centre && this.state.centre.map((centre) =>{
                        return(                      
                          <option value={centre.idcentre}> {centre.libelle} </option>
                        )
                      }
                      )
                    }
                </select>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div
                className="form-group"
                style={{ marginLeft: "2%", width: "100%" }}
              >
                <label htmlFor="dateFin">&nbsp;Prix en Euro</label>
                <input
                  className="form-control"
                  type="number"
                  // min={0}
                  required
                  value={Math.round(this.state.prixEuro * 100) / 100}
                  onChange={this.handleEuro}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <Loader
                  style={{
                    position: "absolute",
                    marginTop: "-30%",
                    zIndex: 100,
                  }}
                  type="Puff"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  visible={this.state.isLoading}
                />
              </div>

              <div
                className="form-group"
                style={{ marginLeft: "2%", width: "100%" }}
              >
                <label htmlFor="heureDebut">&nbsp;Prix en XAF</label>
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  required
                  value={Math.round(this.state.prixXAF * 100) / 100}
                  onChange={this.handleXaf}
                />
              </div>
            </div>
            <div className="submit-section text-center">
              <input
                type="submit"
                disabled={this.state.isLoading}
                className="btn btn-bluefonce submit-btn"
                value={this.state.isUpdate ? "Modifier" : "Ajouter"}
              />

              <button
                type="button"
                disabled={this.state.isLoading}
                className="btn btn-danger submit-btn"
                onClick={this.handleCancel}
              >
                Annuler
              </button>
            </div>
          </form>
        </ModalAntd>
      </div>
    );
  }
}

export default Prestations;
