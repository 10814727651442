import React, { Component } from "react";
import DoctorSidebar from "../sidebar";
// import Slot from './slot';
// import { Link } from 'react-router-dom';
import {
  //  Tab,
  //  Tabs,
  Modal,
  //  Button
} from "react-bootstrap";
// import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
// import Calendar from 'react-calendar';
import FullCalendar, { identity } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./time.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import swal from "sweetalert";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Moment from "moment";
import { extendMoment } from "moment-range";
import Loader from "react-loader-spinner";
import host from "../../../../host";
const ModalAntd = require("antd").Modal;
const Button = require("antd").Button;

const moment = extendMoment(Moment);
let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

function unique(arr) {
  var u = {},
    a = [];
  for (var i = 0, l = arr.length; i < l; ++i) {
    if (!u.hasOwnProperty(arr[i])) {
      a.push(arr[i]);
      u[arr[i]] = 1;
    }
  }
  return a;
}

const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: "All-day event",
    start: todayStr,
  },
  {
    id: createEventId(),
    title: "Timed event",
    start: todayStr + "T12:00:00",
  },
];

function createEventId() {
  return String(eventGuid++);
}
const dureeCreneau = [
  {
    label: "15 min",
    value: "15",
  },
  {
    label: "30 min",
    value: "30",
  },
  {
    label: "45 min",
    value: "45",
  },
  {
    label: "60 min",
    value: "60",
  },
];
const selectPlages = [
  {
    label: "plage de créneau",
    value: "plage",
  },
  {
    label: "un créneau",
    value: "un",
  },
];

class ScheduleTiming extends Component {
  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  handleShow = (e) => {
    e.preventDefault();
    this.setState({
      show: true,
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showButton: false,
      selectInfo: null,
      heureFin: null,
      heureDebut: null,
      creneaux: [],
      isLoading: false,
      weekend: [],
      lundi: false,
      mardi: false,
      mercredi: false,
      jeudi: false,
      vendredi: false,
      samedi: false,
      dimanche: false,
      events: [],
      professionnel: { prestation: [] },
      prestation: "Toutes les prestations",
      centre: "Tous les centres",
      idprestation: 0,
      centreEntier: 0,
      prestaEntier: 0,
      creneau: null,
      isVisible: false,
    };
    this.dateDebut = React.createRef();
    this.dateFin = React.createRef();
    this.heureDebut = React.createRef();
    this.heureFin = React.createRef();
    this.dureeCreneau = React.createRef();
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const data = dataJson && JSON.parse(dataJson);

    // let URL = `https://kwaber-sante.herokuapp.com/users/${140}`
    let URL = host + `/ps/${data.user.professionnelsante.idprofessionnelsante}`;

    let response = await fetch(URL);
    let professionnel = await response.json();
    // let datas = professionnel.creneau_dispos
    let datas = professionnel.creneaux;
    // console.log(professionnel, "dataaa")
    let events = this.state.events;

    datas.forEach((data) => {
      let title =
        data.heuredebut.substring(0, 5) + "-" + data.heurefin.substring(0, 5);
      // console.log(data.reserve," la dataaaa")
      let couleur = "#51D128";
      if (data.reserve == true) {
        couleur = "#EA1212";
      }
      events.push({
        title: title,
        date: data.datecreneau,
        extendedProps: {
          data: data,
        },
        color: data.reserve ? "#EA1212" : "#51D128",
      });
    });
    this.setState({
      events: events,
      isLoading: false,
      professionnel: professionnel,
    });
  }

  saveCreneaux = async (arg) => {
    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const user = dataJson && JSON.parse(dataJson);

    this.setState({
      isLoading: true,
    });
    let URL = host + "/creneau/create";
    // let URL = "https://kwaber-sante.herokuapp.com/creneau-dispos";
    let start = this.state.selectInfo.startStr;
    let end = this.state.selectInfo.endStr;
    let a = moment(start);
    let b = moment(end);
    let diff = b.diff(a, "days");
    let _apiCreneaux = [];
    let status = 0;

    let dayOfBreak = null;

    this.state.creneaux.forEach(async (data) => {
      let current = a;
      let doctorGlob = null;
      for (let index = 0; index < diff; index++) {
        let _current = current.add(index == 0 ? 0 : 1, "days");
        let _date = _current.format("YYYY-MM-DD").toString();
        // console.log("__________________",{
        //     "idps":this.state.professionnel.idprofessionnelsante,
        //         "idprestation":this.state.idprestation,
        //         "heuredebut":data.heureDebut + ":00:00",
        //         "heurefin":data.heureFin + ":00:00",
        //         "datecreneau":_date
        // })
        // return;
        let response = await fetch(URL, {
          headers: {
            "Content-type": "application/json",
          },
          method: "POST",

          body: JSON.stringify({
            // "date": _date,
            // "heuredebut": data.heureDebut + ":00:00",
            // "heurefin": data.heureFin + ":00:00",
            // "duree": parseInt(this.dureeCreneau.current.value),
            // "prestation": this.state.prestaEntier,

            idps: this.state.professionnel.idprofessionnelsante,
            idprestation: this.state.idprestation,
            heuredebut: data.heureDebut + ":00:00",
            heurefin: data.heureFin + ":00:00",
            datecreneau: _date,
          }),
        });
        // .then(()=>{
        //     // window.location.reload();
        // })
        if (response.status == 200 || response.status == 201) {
          let _api = await response.json();
          // _apiCreneaux.push(_api);
          _api = _api.creneau;
          // console.log(_api)
          let events = this.state.events;
          events.push({
            title:
              _api.heuredebut.substring(0, 5) +
              "-" +
              _api.heurefin.substring(0, 5),
            date: _api.datecreneau,
            extendedProps: {
              data: _api,
            },
            color: "#51D128",
          });

          this.setState({
            events: [...events],
          });
          /** --------- */
          status = 200;
        }
        // ****************************
        // let responseDoctor = await fetch(host+`/ps/${user.user.professionnelsante.idprofessionnelsante}`)
        // let doctor = await responseDoctor.json()

        // let _doctorCreneaux = doctor.creneau_dispos.map((_cr) => _cr.id);
        // let _tabs = _apiCreneaux.map((_cr) => _cr.id);
        // _doctorCreneaux.push(..._tabs);
        // doctor.creneau_dispos = unique(_doctorCreneaux)
        // doctorGlob = doctor;
      }

      // let responseUpdateDoctor = await
      //     fetch(`https://kwaber-sante.herokuapp.com/users/${user.user.id}`, {
      //         headers: {
      //             "Content-type": "application/json"
      //         },
      //         method: "PUT",
      //         body: JSON.stringify(doctorGlob)
      //     })
      // status = responseUpdateDoctor.status
      // console.log(JSON.stringify(doctorGlob))
      // *****************
      // if (true) {
      //   let calendarApi = this.state.selectInfo.view.calendar;
      //   let title = this.state.creneaux.map((params) => {
      //     return params.heureDebut + "-" + params.heureFin + " ";
      //   });
      //   let a = moment(this.state.selectInfo.startStr);
      //   let b = moment(this.state.selectInfo.endStr);
      //   let diff = b.diff(a, "days");
      //   let current = a;
      //   for (let index = 0; index < diff; index++) {
      //     let _current = current.add(index == 0 ? 0 : 1, "days");
      //     let _date = _current.format("YYYY-MM-DD").toString();
      //     if (_date == dayOfBreak) {
      //       break;
      //     }
      //     calendarApi.addEvent({
      //       id: createEventId(),
      //       title,
      //       start: _date,
      //       end: _date,
      //       allDay: this.state.selectInfo.allDay,
      //       color: "#51D128",
      //       extendedProps: {
      //         data: _apiCreneaux[index],
      //       },
      //     });
      //     if (index == 0) {
      //       dayOfBreak = _date;
      //     }
      //   }
      // }
      this.handleUnselect()
      this.setState({
        isLoading: false,
        prestation: "Toutes les prestations",
        creneaux: [],
        idprestation: 0,
      });
      // window.location.reload();
    });
  };

  submit = (arg) => {
    if (this.state.heureFin == null || this.state.heureDebut == null) {
      toast.error("Veuillez indiquer l'heure de début et l'heure de fin !");
    } else if (this.state.idprestation == 0) {
      toast.warning("Veuillez sélectionnez une prestations !");
    } else {
      let creneaux = this.state.creneaux;
      let _h1 = moment({
        hour: this.state.heureDebut.split(":")[0],
        minute: this.state.heureDebut.split(":")[1],
      });

      let _h2 = moment({
        hour: this.state.heureFin.split(":")[0],
        minute: this.state.heureFin.split(":")[1],
      });
      // const range2 = moment.range(_h1, _h2);
      let canApply = true;

      creneaux.forEach((creneau) => {
        let h1 = moment({
          hour: creneau.heureDebut.split(":")[0],
          minute: creneau.heureDebut.split(":")[1],
        });

        let h2 = moment({
          hour: creneau.heureFin.split(":")[0],
          minute: creneau.heureFin.split(":")[1],
        });

        if (
          _h1.isBetween(h1, h2) ||
          _h2.isBetween(h1, h2) ||
          _h1.isSame(h1) ||
          _h2.isSame(h2)
        ) {
          canApply = false;
        }
      });

      if (canApply) {
        creneaux.push({
          id: new Date().getTime(),
          dateDebut: this.state.selectInfo.startStr,
          dateFin: this.state.selectInfo.endStr,
          heureFin: this.state.heureFin,
          heureDebut: this.state.heureDebut,
        });
      } else {
        toast.error("ce créneau est en conflit avec un autre dejà existant");
      }

      this.setState({
        creneaux,
        show: false,
        heureDebut: null,
        heureFin: null,
      });
      let calendarApi = this.state.selectInfo.view.calendar;

      calendarApi.select(
        this.state.selectInfo.startStr,
        this.state.selectInfo.endStr
      );
      /******************** */
      this.saveCreneaux();
    }
  };

  changeHeureDebut = (arg) => {
    var date = moment(arg.target.value, "HH:mm")
      .add(this.dureeCreneau.current.value, "minutes")
      .format("HH:mm");

    this.setState({
      heureFin: date,
      heureDebut: arg.target.value,
    });
  };

  changeBox = (event) => {
    let tab = this.state.weekend;
    if (tab.length < 6) {
      if (tab.includes(parseInt(event.target.value))) {
        const index = tab.indexOf(parseInt(event.target.value));
        if (index > -1) {
          tab.splice(index, 1);
        }
      } else {
        tab.push(parseInt(event.target.value));
      }
      this.setState({
        weekend: tab,
      });
    } else {
      toast.error("Vous avez atteint le nombre maximun de jours de congé  !");
    }
  };

  deleteItem = (arg) => {
    let items = [];
    this.state.creneaux.forEach((item) => {
      if (item.id != arg) {
        items.push(item);
      }
    });

    this.setState({
      creneaux: items,
    });
  };

  cancel = () => {
    this.setState({
      isVisible: false,
      creneau: null,
    });
  };

  handleDateClick = (arg, b) => {
    console.log("____________", arg.event.extendedProps);
    this.setState({
      isVisible: true,
      creneau: arg.event.extendedProps.data,
    });
    // let text =
    //   "Horaire :" +
    //   arg.el.innerText +
    //   " Prestation: " +
    //   arg.event.extendedProps.data.idprestation.libelle +
    //   (arg.event.extendedProps.data.patient
    //     ? " Patient: " +
    //       arg.event.extendedProps.data.patient.prenom +
    //       " " +
    //       arg.event.extendedProps.data.patient.nom
    //     : "");
    // if (
    //   arg.event._def.ui.backgroundColor == "red" ||
    //   arg.event._def.ui.backgroundColor == "#EA1212"
    // ) {
    //   swal("Creneau occupé ", text, "warning");
    // } else {
    //   swal("Creneau disponible ", text, "info");
    // }
  };

  handleUnselect = (arg) => {
    //   toast.error("Vous avez supprimé votre plage de date")
    this.setState({
      selectInfo: null,
      showButton: false,
    });
  };

  handleDateSelect = (selectInfo) => {
    //toast.warn("Vous avez sélectionné une plage de date, veuillez attribuer un créneau pour terminer l'opération ")
    this.setState({
      selectInfo: selectInfo,
      showButton: true,
    });

    /*
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar
    
        calendarApi.unselect() // clear date selection
        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }*/
  };
  async handleChange(e, input) {
    let presta = 0;
    let centre = 0;
    // console.log(e.target.value, "value")
    if (input == "presta") {
      this.setState({
        idprestation: e.target.value,
      });
      // if (e.target.value !== "Toutes les prestations") {
      //     let prestaResponse = await
      //         fetch(host+`/prestations/${e.target.value}`)

      //     if (prestaResponse.status == 200) {
      //         let data = await prestaResponse.json()
      //         presta = data
      //     }
      //     this.setState({
      //         prestation: presta.libelle,
      //         prestaEntier: presta
      //     })
      //     console.log("---------------")
      //     console.log(this.state.prestation)
      //     console.log(this.state.prestaEntier)
      // } else {
      //     this.setState({
      //         prestation: "Toutes les prestations",
      //         prestaEntier: 0,
      //     })
      // }
    }
    if (input == "centre") {
      if (e.target.value !== "Tous les centres") {
        let centreResponse = await fetch(
          `https://kwaber-sante.herokuapp.com/centres/${e.target.value}`
        );

        if (centreResponse.status == 200) {
          let data = await centreResponse.json();
          centre = data;
        }
        this.setState({
          centre: centre.libelle,
          centreEntier: centre,
        });
      } else {
        this.setState({
          prestation: "Tous les centres",
          prestaEntier: 0,
        });
      }
    }
  }

  deleteCreneau = () => {
    if (this.state.creneau) {
      this.setState({
        isLoading: true,
      });
      let myHeaders = new Headers();
      let raw = "";
      let requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        host + "/creneau/delete/" + this.state.creneau.idcreneau,
        requestOptions
      )
        .then((response) => {
          if (response.status !== 200 && response.status !== 201) {
            return null;
          }
          return response.json();
        })
        .then((result) => {
          if (result) {
            toast.success("Suppression réalisé avec succès");
            let events = this.state.events;
            console.log("__________1", events);
            for (let index = 0; index < events.length; index++) {
              const element = events[index];
              if (element.extendedProps.data) {
                if (
                  element.extendedProps.data.idcreneau ===
                  this.state.creneau.idcreneau
                ) {
                  events.splice(index, 1);
                  break;
                }
              }
            }
            console.log("__________2", events);

            this.setState({
              events: [...events],
            });
            this.cancel();
            // window.location.reload()
          } else {
            toast.error("Erreur lors de la suppression");
          }
        })
        .catch((error) => {
          toast.error("Erreur lors de la suppression");
        })
        .then(() => {
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  render() {
    return (
      <div style={{ marginTop: "4%" }}>
        <ToastContainer />
        <ModalAntd
          title="Detail du creneau"
          visible={this.state.isVisible}
          onCancel={this.state.isLoading ? null : this.cancel}
          footer={[
            <Button
              key="back"
              onClick={this.state.isLoading ? null : this.cancel}
              disabled={this.state.isLoading}
            >
              Retour
            </Button>,

            // [...this.state.creneau && !this.state.creneau.reserve ? [
            //   <Button
            //   key="modifier"
            //   type="primary"
            //   onClick={this.state.isLoading ? null : this.nettoyer}
            //   disabled={this.state.isLoading}
            // >
            //   Modifier
            // </Button>,
            // ] : []],

            <Button
              key="submit"
              type={
                this.state.creneau && this.state.creneau.reserve
                  ? "primary"
                  : "danger"
              }
              disabled={this.state.isLoading}
              loading={this.state.isLoading}
              onClick={
                this.state.isLoading
                  ? null
                  : this.state.creneau && this.state.creneau.reserve
                  ? this.cancel
                  : this.deleteCreneau
              }
            >
              {this.state.creneau && this.state.creneau.reserve
                ? "Ok"
                : "Supprimer"}
            </Button>,
          ]}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <div className="form-content p-2">
            <h3 className="modal-title">
              {this.state.creneau && this.state.creneau.reserve
                ? "Créneau occupé"
                : "Créneau Libre"}
            </h3>
            <h4 className="modal-title">
              Horaire:{" "}
              {this.state.creneau
                ? this.state.creneau.heuredebut.substring(0, 5) +
                  "-" +
                  this.state.creneau.heurefin.substring(0, 5)
                : ""}
            </h4>
                 
            <Loader
              style={{
                position: "absolute",
                textAlign: "center",
                marginLeft: "auto",
                left: "0",
                right: "0",
                marginRight: "auto",
                zIndex: "20px",
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            />

            <p className="mb-4">
              Prestation:{"  "}
              {this.state.creneau
                ? this.state.creneau.idprestation.libelle
                : ""}
            </p>
            {this.state.creneau && this.state.creneau.patient ? (
              <>
                <p className="mb-4">
                  {"Réservé par " +
                    this.state.creneau.patient.prenom +
                    " " +
                    this.state.creneau.patient.nom}
                </p>
                <p className="mb-4">
                  Contact:
                  <br />
                  {"Email: " + this.state.creneau.patient.email}
                  <br />
                  {"Tel: " +
                    (this.state.creneau.patient.numtelephone2
                      ? this.state.creneau.patient.numtelephone1 +
                        "/ " +
                        this.state.creneau.patient.numtelephone2
                      : this.state.creneau.patient.numtelephone1)}
                </p> <br/>
               

              </>
            ) : (
              ""
            )}
          </div>
        </ModalAntd>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-lg-3  col-sm-12">
                <DoctorSidebar />
              </div>
              <div
                className={
                  this.state.isLoading
                    ? "page_wrapper_blur col-md-6 col-lg-6 col-sm-12 card "
                    : "col-md-6 col-sm-12 col-lg-6 card p-5"
                }
              >
                <h1> <strong>Gérer mes disponibilités</strong> </h1> <br />
                <FullCalendar
                  events={[...this.state.events]}
                  eventColor={[...this.state.events]}
                  hiddenDays={this.state.weekend}
                  locale="fr"
                  locales="fr"
                  validRange={{
                    start: new Date().toISOString()
                    // .slice(0, 10),
                  }}
                  buttonText={{
                    today: "Aujourd'hui",
                    month: "Mois",
                    week: "Semaine",
                    day: "Jour",
                    list: "Liste",
                  }}
                  firstDay={1}
                  editable={true}
                  unselectAuto={false}
                  droppable={false}
                  dragScroll={false}
                  unselect={this.handleUnselect}
                  selectable={true}
                  select={this.handleDateSelect}
                  eventClick={this.handleDateClick}
                  plugins={[dayGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                />
                {/* <br />
                                <h3 >
                                    Mes jours de congé
                                </h3>
                                <br />
                                <div className="row" style={{ marginLeft: "2%", marginRight: "2%" }}>
                                    <div class="col">
                                        <input onChange={this.changeBox} name="lundi" class="form-check-input" type="checkbox" id="lundi" value="1" />
                                        <label class="form-check-label" for="lundi">Lundi</label>
                                    </div>
                                    <div class="col">
                                        <input onChange={this.changeBox} name="mardi" class="form-check-input" type="checkbox" id="mardi" value="2" />
                                        <label class="form-check-label" for="mardi">Mardi</label>
                                    </div>
                                    <div class="col">
                                        <input onChange={this.changeBox} name="mercredi" class="form-check-input" type="checkbox" id="mercredi" value="3" />
                                        <label class="form-check-label" for="mercredi">Mercredi</label>
                                    </div>
                                    <div class="col">
                                        <input onChange={this.changeBox} name="jeudi" class="form-check-input" type="checkbox" id="jeudi" value="4" />
                                        <label class="form-check-label" for="jeudi">Jeudi</label>
                                    </div>
                                    <div class="col">
                                        <input onChange={this.changeBox} name="vendredi" class="form-check-input" type="checkbox" id="vendredi" value="5" />
                                        <label class="form-check-label" for="vendredi">Vendredi</label>
                                    </div>
                                    <div class="col">
                                        <input onChange={this.changeBox} name="samedi" class="form-check-input" type="checkbox" id="samedi" value="6" />
                                        <label class="form-check-label" for="samedi">Samedi</label>
                                    </div>
                                    <div class="col">
                                        <input onChange={this.changeBox} name="dimanche" class="form-check-input" type="checkbox" id="dimanche" value="0" />
                                        <label class="form-check-label" for="dimanche">Dimanche</label>
                                    </div>


                                </div> */}
              </div>
              <div className="col-md-2 col-lg-2 card ml-3  p-2">
                <h3>Définir un créneau</h3>

                <form className="form" style={{ paddingTop: "10%" }}>
                  <div className="form-group">
                    <label htmlFor="durée">Durée du créneau</label>
                    <select
                      ref={this.dureeCreneau}
                      className="form-control"
                      disabled={!this.state.showButton}
                    >
                      <option value="15">15 minutes</option>
                      <option value="30">30 minutes</option>
                      <option value="60">1 heure</option>
                      <option value="90">1 h 30 minutes</option>

                      <option value="120">2 heures</option>
                    </select>
                  </div>
                  {!this.state.showButton ? (
                    <em className="etoile">Veuillez d'abord sélectionner une date ou une plage de dates</em>
                  ) : null}

                  {this.state.showButton && (
                    <React.Fragment>
                      {" "} 
                      <button
                        className="btn btn-bluefonce"
                        onClick={this.handleShow}
                      >
                        Ajouter
                      </button>
                      <Modal show={this.state.show} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Ajouter un créneau</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form className="form">
                            <div style={{ display: "flex" }}>
                              <div
                                className="form-group"
                                style={{ marginLeft: "2%", width: "100%" }}
                              >
                                <label htmlFor="dateDebut">
                                  &nbsp;Date de début
                                </label>
                                <input
                                  id="dateDebut"
                                  value={
                                    this.state.selectInfo != null
                                      ? this.state.selectInfo.startStr
                                      : ""
                                  }
                                  ref={this.dateDebut}
                                  className="form-control"
                                  style={{ margin: "2%" }}
                                  type="date"
                                />
                              </div>
                              <div
                                className="form-group"
                                style={{ marginLeft: "2%", width: "100%" }}
                              >
                                <label htmlFor="dateFin">
                                  &nbsp;Date de fin
                                </label>
                                <input
                                  id="dateFin"
                                  value={
                                    this.state.selectInfo != null
                                      ? this.state.selectInfo.endStr
                                      : ""
                                  }
                                  ref={this.dateDebut}
                                  ref={this.dateFin}
                                  className="form-control"
                                  style={{ margin: "2%" }}
                                  type="date"
                                />
                              </div>
                            </div>

                            <div style={{ display: "flex" }}>
                              <div
                                className="form-group"
                                style={{ marginLeft: "2%", width: "100%" }}
                              >
                                <label htmlFor="heureDebut">
                                  &nbsp;Heure de début
                                </label>
                                <input
                                  id="heureDebut"
                                  onChange={this.changeHeureDebut}
                                  value={this.state.heureDebut}
                                  className="form-control"
                                  style={{ margin: "2%" }}
                                  type="time"
                                />
                              </div>
                              <div
                                className="form-group"
                                style={{
                                  marginLeft: "2%",
                                  width: "100%",
                                  float: "ri",
                                }}
                              >
                                <label htmlFor="heureFin">
                                  &nbsp;Heure de fin
                                </label>
                                <input
                                  id="heureFin"
                                  value={this.state.heureFin}
                                  ref={this.HeureFin}
                                  className="form-control"
                                  style={{ margin: "2%" }}
                                  type="time"
                                />
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              {this.state.professionnel.prestation.length ===
                              0 ? (
                                "Veuillez créer une prestation avant de créer un créneau"
                              ) : (
                                // <div className="form-group" style={{ marginLeft: "2%", width: "100%" }}>
                                //     <div>Choisir une prestation</div>
                                //     <select id="lang" onChange={e => this.handleChange(e, "presta")} value={this.state.prestation.libelle} className="form-control">
                                //         <option value="Toutes les prestations">Sélectionner une prestation</option>
                                //         {this.state.professionnel.prestation.map(presta => (
                                //             <option value={presta.id}>
                                //                 {presta.libelle}
                                //             </option>
                                //         ))}
                                //     </select>
                                // </div>
                                <div
                                  className="form-group"
                                  style={{ marginLeft: "2%", width: "100%" }}
                                >
                                  <div>Choisir une prestation</div>
                                  <select
                                    id="lang"
                                    onChange={(e) =>
                                      this.handleChange(e, "presta")
                                    }
                                    value={this.state.idprestation}
                                    className="form-control"
                                    required
                                  >
                                    <option value="0">
                                      Sélectionner une prestation
                                    </option>
                                    {this.state.professionnel.prestation.map(
                                      (presta) => (
                                        <option value={presta.id}>
                                          {presta.libelle}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              )}
                            </div>
                          </form>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            className="btn btn-bluefonce"
                            onClick={this.submit}
                          >
                            Ajouter
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </React.Fragment>
                  )}
                </form>

                {/* <div>
                                    <div className="time_container" >
                                        {
                                            this.state.creneaux.map((current) => {
                                                return <div key={current.id} className="time_row" >
                                                    &nbsp;  {current.heureDebut} - {current.heureFin} &nbsp; <span style={{
                                                        cursor: "pointer",
                                                    }} onClick={() => this.deleteItem(current.id)}><i class="fas fa-times-circle"></i></span> &nbsp;
                                                </div>

                                            })
                                        }
                                    </div>
                                </div> */}

                {/* {this.state.creneaux.length > 0 &&
                                    <button style={{ margin: "10%", color: "rgb(21, 85, 141)" }} onClick={this.saveCreneaux} className="btn btn-sm btn-bluefonce">
                                        sauvegarder
                                    </button>} */}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Loader
              style={{
                position: "absolute",
                marginTop: "-30%",
                zIndex: 100,
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ScheduleTiming;
