import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import StickyBox from "react-sticky-box";
import DashboardSidebar from "./sidebar/sidebar";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';



class Dashboard2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      data: [],
      patient: { ordonnances: [], creneaus: [], },
      rdv: [],
      tabAffichage:[],
      check: [true, false, false, false],
      date: 0,
     
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  async componentDidMount() {
    let dbUser = JSON.parse(sessionStorage.getItem("user"))
      || JSON.parse(sessionStorage.getItem("user"));
    let identifiant = JSON.parse(sessionStorage.getItem("userID"));
      // let email = identifiant.username;

    if ( (identifiant == null   ) || ( identifiant == undefined)) {
      this.props.history.push('/login')

    }else{

     if (identifiant) {
			var raw = JSON.stringify({
				"identifier": identifiant.username,
				"password": identifiant.password,
			});

			
			var requestOptions = {
				method: 'POST',
				headers: {
					"content-type": "application/json"
				},
				body: raw,
				redirect: 'follow'
			};
			
			fetch("https://kwaber-sante.herokuapp.com/auth/local", requestOptions)
				.then(response => {
					if (response.status == 200) {
						return response.json();
					} else {
						toast.error("Il y a une erreur  !")
						this.setState({
							isLoading: false
						})
						return null
					}
				})
				.then(result => {
					if (result != null) {
						this.setState({
							isLoading: false
						})
						toast.success("Vous avez été connecté à votre compte !")
						if (this.state.preserveLogin == true) {
							sessionStorage.setItem("user", JSON.stringify(
								{
									token: result.jwt,
									user: result.user,
									state: true
								}
							))
						} else {
							sessionStorage.setItem("user", JSON.stringify(
								{
									token: result.jwt,
									user: result.user,
									state: true
								}
							))
						}
						if (result.user.type_user.id != null) {
							if (result.user.type_user.id == 2) {
								window.location.assign('/doctor/doctor-dashboard');
                sessionStorage.removeItem("userID");
							} else {
								window.location.assign('/patient/dashboard');
                sessionStorage.removeItem("userID");
							}
						} 
						else {
							window.location.assign('/doctor/doctor-dashboard');
              sessionStorage.removeItem("userID")
						}

					}

				})
				.catch(error => console.log('error', error));
		}
		else {
			toast.warn("Veuillez à bien remplir tous les champs !", {
			});
		}
      

    }
   
     
   

     
    let URL = ` https://kwaber-sante.herokuapp.com/RDVS?creneau.patient= ${1} `;

      console.log(URL, 'lb');

    let response = await fetch(URL)


    if (response.status == 200) {
      let data = (await response.json())
      // console.log(data, "la datetete")
      this.setState({
        rdv: data
      })
    }
    // let id = 1
    // let patientResponse = await
    //   fetch(`https://kwaber-sante.herokuapp.com/patients/${id}`)

    // if (patientResponse.status == 200) {
    //   let data = await patientResponse.json()
    //   this.setState({
    //     patient: data
    //   });
    // }
    this.remplirTableau()

  }

  addDays(date, days) {
		var result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}

  remplirTableau() {
    let tab = this.state.rdv
    let newTab = []
    
    if(this.state.date == 0){
      this.setState({
        tabAffichage:tab
      })
    }

    if(this.state.date == 1){
      for(let i = 0;i<tab.length;i++){
        if(new Date(tab[i].dateRDV) > new Date()){
          newTab.push(tab[i])
        }
      }
      this.setState({
        tabAffichage :newTab
      })
    }
    
    if(this.state.date == 2){
      for(let i = 0;i<tab.length;i++){
        let demain = this.addDays(new Date(),1)
        demain = this.ecrireDate2(demain)

        if(new Date(this.ecrireDate2(new Date()))<= new Date(tab[i].dateRDV) && new Date(tab[i].dateRDV) < new Date(demain)){
          newTab.push(tab[i])
        }
      }
      this.setState({
        tabAffichage :newTab
      })
    }

    if(this.state.date == 3){
      for(let i = 0;i<tab.length;i++){
        if(new Date(tab[i].dateRDV) < new Date()){
          newTab.push(tab[i])
        }
      }
      this.setState({
        tabAffichage :newTab
      })
    }
  }

  ecrireDate2(date) {
		var d = new Date(date)

		var mois = parseInt(d.getMonth())
		var jour = parseInt(d.getDate())
		mois = mois + 1
		if (mois < 10) {
			mois = "0" + mois
		}
		if (jour < 10) {
			jour = "0" + jour
		}

		return d.getFullYear() + "-" + mois + "-" + jour
	}

  ecrireDate(date) {
    //2021-03-30
    var listeJours = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    var listeMois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

    var str = new Date(date);
    //var tab = str.split("-");

    var jour = listeJours[str.getDay()]
    var mois = listeMois[str.getMonth()]

    return jour + " " + str.getDate() + " " + mois + " " + str.getFullYear()

  }
  handleChangeCheck(e, index) {
    let tab = this.state.check
    // console.log(index)
    for (let i = 0; i < tab.length; i++) {
      if (i == index) {
        tab[i] = true
      } else {
        tab[i] = false
      }
    }
    this.setState({
      check: tab,
      date:index,
    })
    this.componentDidMount()
  }

  handleSelect(key) {
    // console.log("selected " + key);
    this.setState({ key });
  }
  render() {

    
    return (
      <div class="content">
      <div class="container-fluid">
          <div class="row">
            <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <StickyBox offsetTop={20} offsetBottom={20}>
                <DashboardSidebar />
              </StickyBox>
            </div>
            <div class="col-md-7 col-lg-8 col-xl-9">
              <div class="card">
                <div class="card-body pt-0">
                  <Tabs
                    className="tab-view"
                    activeKey={this.state.key}
                    onSelect={this.handleSelect}
                    id="controlled-tab-example"
                  >
                    <Tab className="nav-item" eventKey={1} title="Rendez vous">
                    <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" checked={this.state.check[2]} onChange={e => this.handleChangeCheck(e, 2)} /> Aujourd'hui
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" checked={this.state.check[1]} onChange={e => this.handleChangeCheck(e, 1)} /> À venir
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" checked={this.state.check[3]} onChange={e => this.handleChangeCheck(e, 3)} /> Passé
                        </label>
                      </div> 
                      <div className="form-check form-check-inline">
                        <label className="form-check-label">
                          <input className="form-check-input" type="checkbox" checked={this.state.check[0]} onChange={e => this.handleChangeCheck(e, 0)} /> Tous les rendez-vous
                        </label>
                      </div>
                      
                      
                      <div className="card card-table mb-0">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>Médécin</th>
                                  <th>Date du rendez vous</th>
                                  <th>Date du paiement</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  this.state.tabAffichage.map((data) => {
                                      return <tr>
                                        <td>
                                          <h2 className="table-avatar">
                                           
                                           
                                            <Link
                                              to="/patient/doctor-profile"
                                              className="avatar avatar-sm mr-2"
                                            >
                                              <img
                                                className="avatar-img rounded-circle"
                                                src={data.profesionnel_de_sante.photo[0].url}
                                                alt="User"
                                              />
                                            </Link>
                                            <Link to={"/patient/doctor-profile/" + data.profesionnel_de_sante.id}>
                                              {data.profesionnel_de_sante.Nom
                                                + " "
                                                + data.profesionnel_de_sante.prenom}
                                            </Link>
                                          </h2>
                                        </td>
                                        <td>
                                          {data.dateRDV}{" "}
                                          <span className="d-block text-info">
                                            {data.heureRDV.substring(0, 5)}
                                          </span>
                                        </td>
                                        <td>{data.created_at.substring(0, 10)}</td>

                                        <td className="text-right">
                                          <div className="table-action">
                                            <Link className="btn btn-sm bg-primary-light">
                                              <i className="fas fa-print"></i> Print
                                            </Link>
                                            <Link className="btn btn-sm bg-info-light">
                                              <i className="far fa-eye"></i> View
                                            </Link>
                                          </div>
                                        </td>
                                      </tr>
                                  })
                                }


                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Dashboard2;
