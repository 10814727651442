import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// import { Tab, Tabs, Modal } from 'react-bootstrap';
// import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
// import Calendar from 'react-calendar';
import { ToastContainer, toast } from "react-toastify";
import host from "../../../../host";
import Loader from "react-loader-spinner";
import { Image } from "antd";
import Alert from 'react-bootstrap/Alert'
import numStr from '../separator/separator'



import { MDBDataTableV5, MDBTable } from "mdbreact";

function isNumeric(number) {
  return !isNaN(parseFloat(number)) && !isNaN(+number);
}

class Prestation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isTrue: false,
      centre: {},
      photo: "",
      prestationChecked:{},
      prestation: [],
      prixEuro: [],
      prixXAF: [],
      modalite: [],

      checked: false,

      prestationChoisi: null,
      prixEuroChoisi: null,
      prixXAFChoisi: null,
      modaliteChoisi: null,
      idpro: null,
      // row: [],
      checkbox1: "",
      datatable: {
        columns:[],
        rows:[],     
      },
    };
  }

  // La prestation  choisie
  showLogs2 = (e) => {
                                    
    this.setState({
      checkbox1: e,
      checked: e.checked,
      prestationChecked:{
      idCentre:this.state.centre.idcentre,
      idPrestation: e.idprestation,
    //   prestations:{
    //     id: e.idprestation,
    //     libelle:e.libelle,
    //     modalite:e.modalite,
    //    }, 
    //   prixEuro:e.pEUR,    
    //   prixXAF: e.pXAF,
      }
    });
    
  };

  async componentDidMount() {
    
    const {
      match: { params },
    } = this.props;

    if (isNumeric(params.id)) {
      const id = parseInt(params.id);
      fetch(host + `/centres/${id}`)
        .then((response) => {
          if (response.status === 404) {
            this.props.history.push("/404");
          } else if (response.status === 200) {
            return response.json();
          }
        })
        .then((data) => {
          let photo =
            "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png";
        //   if (data.user.photo !== null || data.user.photo !== undefined) {
        //     photo = data.user.photo;
        //   }
            // console.log('dataPS:', data);
            let rowElements = [];
            for (let index = 0; index < data.prestations.length; index++) {
              const element = data.prestations[index];

            //    console.log("Elements:", element)
                rowElements.push({
                  idprestation: element.id,
                  libelle: element.libelle,
                  modalite: element.modalite,
                  pEUR: element.pEUR,
                  pXAF:  numStr(element.pXAF,'.'),
                })
              
            }

          this.setState({
            centre: data,
            photo: photo,
            isLoading: false,
            idpro: id,
              datatable: {
                columns: [
                  {
                    label: "Prestation",
                    field: "libelle",
                    //   sort: 'asc',
                    width: 270,
                  },
                  {
                    label: "Modalité",
                    field: "modalite",
                    //   sort: 'asc',
                    width: 200,
                  },
                  {
                    label: "Prix en Euro",
                    field: "pEUR",
                    // sort: 'asc',
                    width: 150,
                  },
                  {
                    label: "Prix en XAF",
                    field: "pXAF",
                    //   sort: 'asc',
                    width: 100,
                  }
                ],
                rows: rowElements
              }        
          });
        //   if (data.prestations.length === 1) {
        //     this.onChange(0, data.prestation[0]);
        //   }
        });
    } else {
      this.props.history.push("/404");
    }
  }



  render() {
    const { centre } = this.state;
    if (this.state.isLoading)
      {
      return (
        <div
          style={{
            textAlign: "center",
            marginTop: "50vh",
            marginBottom: "40vh",
          }}
        >
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      );
    } else {
      return (
        <div>
          <div className="content">
            <ToastContainer />
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="doctor-widget">
                        <div className="doc-info-left">
                          <div className="doctor-img">
                          <Image
                          width={150}
                          // src="https://cdn0.iconfinder.com/data/icons/medical-and-clinical-healthcare/78/5-512.png"                              
                          src={
                            centre.photo ? centre.photo  : "https://cdn0.iconfinder.com/data/icons/medical-and-clinical-healthcare/78/5-512.png"                              
                          }
                          className="img-fluid"
                          style={{ borderRadius: "2%" }}
                          alt={centre.libelle}
                        />
                          </div>
                          <div className="doc-info-cont">
                            <h4>
                              <Link
                                to={
                                  "/centreProfil/"+
                                  centre.idcentre
                                }
                              >
                                {centre.libelle}                              
                              </Link>
                            </h4>
                            <div>
                              <h5 className="doc-department">
                                {centre.adresse ? centre.adresse : ""}
                              </h5>
                            </div>                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="container">
                <div className="col-12">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">                                
                          {
                          // Conditionné le message à affiché quand y'a pas de prestations
                          !this.state.checked ?
                          <Alert variant={"info"}>
                            { centre.prestations.length === 0 ?
                            "Il n’y a aucune prestation" : 
                             "Veuillez sélectionner une prestation"}
                        </Alert> : ""
                            
                            }
                            <MDBTable responsive>
                            <MDBDataTableV5
                                    //   La table des prestations with mdbreact 
                                                 hover
                                                // searchTop
                                                searchTop
                                                searchBottom={false}
                                                entriesLabel={"Lignes par pages"}
                                                searchLabel={"Rechercher"}
                                                infoLabel={["", "-", "/", ""]}
                                                noRecordsFoundLabel={
                                                  "Aucune donnée ne correspond"
                                                }
                                                entriesOptions={[5, 20, 25]}
                                                entries={5}
                                                pagesAmount={4}
                                                data={this.state.datatable}
                                                checkbox
                                                headCheckboxID='id2'
                                                bodyCheckboxID='checkboxes2'
                                                getValueCheckBox={(e) => {
                                                  this.showLogs2(e);
                                                }}
                                                checkboxFirstColumn
                                            />
                            </MDBTable>
                                     
                                  
                         </div>

                            {/* {
                              // Conditionné le nota bené, l'affiché juste pour un tableau de prestatons
                            centre.prestations.length === 0 ? "" :
                              <p>
                              <span className="etoile">NB:</span> Vueillez choisir une prestation.
                              </p>
                            } */}
                            
                         {
                        //  Affiché le bouton si est seulement si un élement à été coché *
                           this.state.checked ? 
                           <Link to={{
                            pathname: `/patient/bookingCentre/prestation=${this.state.prestationChecked.idPrestation}&idcentre=${this.state.prestationChecked.idCentre}`,
                            // prestation: this.state.prestationChecked
                          }}>
                           <button style={{ color: "white" }} className=" btn btn-info mt-10">
                              Suivant
                          </button>
                            </Link>
                           :
                           <button  disabled style={{ color: "white" }} className=" btn btn-info mt-10">
                                            Suivant      
                                </button>
                         }                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Prestation;
