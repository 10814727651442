import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import loginBanner from "../../assets/images/login-banner.png";
// import AppUtils from "../../../utils/app_utils"
import "react-notifications/lib/notifications.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FirebaseApp from "../../../utils/firebase";
import firebase from "firebase/app";
import "firebase/auth";
import FadeIn from "react-fade-in";
import Loader from "react-loader-spinner";
// import axios from 'axios';
import host from "../../../host";
import { Input } from "antd";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { Button } from 'antd';


class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      loadings: false,
      firstName: "",
      email: "",
      tokenUser: "",
      phone1: "",
      phone2: "",
      dateNaissance: "",
      adress: "",
      complementAdress: "",
      value: "",
      password: "",
      confirmPassword: "",
      regle: false,
      genre: "",
      etat: "",

      isLoading: false,
      preserveLogin: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.googleAuth = this.googleAuth.bind(this);
    // this.facebookAuth = this.facebookAuth.bind(this);
  }

  componentDidMount() {
    let dbUser =
      JSON.parse(sessionStorage.getItem("user")) ||
      JSON.parse(sessionStorage.getItem("user"));
    if (dbUser != null || dbUser != undefined) {
      this.props.history.push("/home");
    }
    document.body.classList.add("account-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("account-page");
  }
  // googleAuth(event) {
  //     event.preventDefault();

  //     let provider = new firebase.auth.GoogleAuthProvider();
  //     FirebaseApp.auth()
  //         .signInWithPopup(provider)
  //         .then((result) => {
  //             var credential = result.credential;
  //             var token = credential.accessToken;
  //             var user = result.user;
  //             alert(user.displayName)
  //         }).catch((error) => {
  //             var errorCode = error.code;
  //             var errorMessage = error.message;
  //             var email = error.email;
  //             var credential = error.credential;
  //         });
  // }

  // facebookAuth(event) {
  //     alert("test connexion facebook")
  //     event.preventDefault();
  //     let provider = new firebase.auth.FacebookAuthProvider();

  //     FirebaseApp.auth()
  //         .signInWithPopup(provider)
  //         .then((result) => {
  //             var credential = result.credential;
  //             var token = credential.accessToken;
  //             var user = result.user;
  //             alert(user.displayName)

  //         }).catch((error) => {
  //             var errorCode = error.code;
  //             var errorMessage = error.message;
  //             var email = error.email;
  //             var credential = error.credential;
  //         });
  // }
  // handleSubmit(e){
  //   e.preventDefault();
    
  //   // else if(this.state.phone1 !== ""){
  //   //   alert(this.state.phone1)
  //   // }
  // }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({
      // isLoading: true,
      loadings:true,
    })
    if(this.state.email !== ""){
      const email = this.state.email;
      // alert(this.state.email)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        "username": email.trim(),
        "password": this.state.password,
        "numtel1": this.state.phone1,
        "role": "PATIENT",
        "slug": this.state.name+" "+this.state.firstName,
        "numtelephone2":this.state.phone2 ,
        // "poids": "",
        // "taille": "",
        "nom": this.state.name,
        "prenom": this.state.firstName,
        "datenaissance": this.state.dateNaissance,
        "adresse": this.state.adress,
        "complementadresse": this.state.complementAdress,
        "groupesangin": this.state.value,
        "email": email.trim(),
        "sexe": this.state.genre
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(host+"/user/register/", requestOptions)
        .then(response =>{
              if(response.status === 200){
                return response.json();
              }
          if(response.status === 408){
              toast.warn("L'adresse mail est déjà utilisé !");
              this.setState({
                // isLoading: false,
                loadings: false
              })
              
          }
          if(response.status === 409){
            this.setState({
              // isLoading: false,
              loadings:false
            })
            toast.warn("numéro déja utilise !");
            
        }
        })
        .then(response => {
          if (response) {
           
            //  console.log("Response2OO: ",  response)

            this.setState({
              // isLoading: true,
              tokenUser: response.token
            });
             var myHeaders = new Headers();
                          myHeaders.append("Content-Type", "application/json");

                          var raw = JSON.stringify({
                            username: this.state.name + " " + this.state.firstName,
                            email: this.state.email,
                            token: this.state.tokenUser
                          });

                          var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                          };

                          fetch(host+"/mail/welcome", requestOptions)
                            .then(response => {
                              // response.json()
                              if(response.status === 200){
                                toast.success("Nous avons terminé votre inscription, un mail d'activation de compte à été envoyé !");
                                // console.log("Welcome: ",response);
                                // console.log("userINFO: ",{
                                //   username: this.state.name + " " + this.state.firstName,
                                //   email: this.state.email,
                                //   token: this.state.tokenUser,
                                //   state: true,
                                // });
                                sessionStorage.setItem(
                                  "userInfo",
                                  JSON.stringify({
                                    username: this.state.name + " " + this.state.firstName,
                                    email: this.state.email,
                                    token: this.state.tokenUser,
                                    state: true,
                                  })
                                );
                                setTimeout(() => {
                                  window.location.assign("/checkMail");
                                }, 5000);
                              }
                            
                            })
                            .catch(error => {
                              console.log('erroR', error)
                              toast.warn("Nous avons terminé votre inscription, L'email n'a pas pu être envoyé ")
                              sessionStorage.setItem(
                                "userInfo",
                                JSON.stringify({
                                  username: this.state.name + " " + this.state.firstName,
                                  email: this.state.email,
                                  token: this.state.tokenUser,
                                
                                  state: true,
                                })
                              );
                            });
                            setTimeout(() => {
                              window.location.assign("/checkMail");
                            }, 5000);
                             }
        })
        .catch(error => {
          console.log('error', error)
          this.setState({
            // isLoading: false,
            //  loadings:false
          })
        });
  }
  else if(this.state.phone1 !== ""){
  
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "username": this.state.email,
      "password": this.state.password,
      "numtel1": this.state.phone1,
      "role": "PATIENT",
      "slug": this.state.name+" "+this.state.firstName,
      "numtelephone2":this.state.phone2 ,
      // "poids": "",
      // "taille": "",
      "nom": this.state.name,
      "prenom": this.state.firstName,
      "datenaissance": this.state.dateNaissance,
      "adresse": this.state.adress,
      "complementadresse": this.state.complementAdress,
      "groupesangin": this.state.value,
      "email": this.state.email,
      "sexe": this.state.genre
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(host+"/user/register/", requestOptions)
      .then(response =>{
        if (response.status === 200) {
           console.log("200: ",  response)     
            //  setTimeout(() => {
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");

              //----------BEGIN l'api de l'envoi du code d'activation du compte par SMS
              var raw = JSON.stringify({
                "numtel1": this.state.phone1
              });

              var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
              };

              fetch(host+"/user/sendcode", requestOptions)
                .then(response => {
                  if (response.status === 200) {
                    return response.json()                          
                  }if(response.status === 417){
                    return response.json()
                    // toast.success("Nous avons terminé votre inscription veuillez confirmer votre compte !");
                    // sessionStorage.setItem(
                    //   "userPhone",
                    //   JSON.stringify({
                    //     phone: this.state.phone1,
                        
                    //   })
                    // );
                    //   window.location.assign("/validation");
                  }
                  
                  if(response.status === 404){
                    toast.success("une erreur s'est produite");
                    // console.log("404: ", response);
                  }
                })
                .then(result =>{ 
                  toast.success("Nous avons terminé votre inscription veuillez confirmer votre compte !");
                  sessionStorage.setItem(
                    "userPhone",
                    JSON.stringify({
                      phone: this.state.phone1,
                      id: result.idUser   
                    })
                  );
                    window.location.assign("/validation");
                })
                .catch(error => console.log('error', error));
             // -----------END l'api de l'envoi du code d'activation du compte par SMS
            // //  }, 4000);
        }
        if(response.status === 409){
            // console.log("408: ",response)
            // console.log("40: ",response)
            toast.warn("Le numéro saisi est déjà utilisé !");
            this.setState({
              isLoading: false,
            })
        }
      })
      .catch(error => {
        toast.warn('une erreur s\'est produite')
        console.log('error', error)
        this.setState({
          isLoading: false,
          // loadings: false
        })
      });
}
    
   
    // if (
      // this.state.name.trim() == "" ||
      // this.state.firstName.trim() == "" ||
      // this.state.email.trim() == "" ||
      // this.state.phone1 == "" ||
      // this.state.dateNaissance == "" ||
      // this.state.adress == "" ||
      // this.state.genre == "" ||
      // this.state.password == "" ||
      // this.state.password.length < 6 ||
      // this.state.confirmPassword == "" ||
      // this.state.password !== this.state.confirmPassword ||
      // this.state.regle == false
    // ) {
      // alert("formulaire non Valider");
    // } else {
    //   var axios = require("axios");
    //   var data = JSON.stringify({
    //     username: this.state.email,
    //     password: this.state.password,
    //     role: "PATIENT",
    //     slug: this.state.name,
    //     nom: this.state.name,
    //     prenom: this.state.firstName,
    //     datenaissance: this.state.dateNaissance,
    //     numtelephone1: this.state.phone1,
    //     numtelephone2: this.state.phone2,
    //     email: this.state.email,
    //     sexe: this.state.genre,
    //     adresse: this.state.adress,
    //     groupesangin: this.state.value,
    //     complementadresse: this.state.complementAdress,
    //   });

    //   var config = {
    //     method: "post",
    //     url: host + "/user/register/",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     data: data,
    //   };

    //   axios(config)
    //     .then((response) => {
    //       console.log("Register: ", response.data.token)
    //       this.setState({
    //         isLoading: true,
    //         tokenUser: response.data.token
    //       });
    //       toast.success(
    //         "Nous avons terminé votre inscription veuillez vérifier votre boite Mail !",
    //         {}
    //       );
    //       var myHeaders = new Headers();
    //       myHeaders.append("Content-Type", "application/json");

    //       var raw = JSON.stringify({
    //         username: this.state.name + " " + this.state.firstName,
    //         email: this.state.email,
    //         token: this.state.tokenUser
    //       });

    //       var requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: "follow",
    //       };

    //       fetch(host + "/mail/welcome", requestOptions)
    //         .then((response) => response.json())
    //         .then((result) => {
    //           // console.log(result)
    //         })
    //         .catch((error) => console.log("error", error));
    //       setInterval(() => {
    //         window.location.assign("/checkMail");
    //       }, 2000);
    //     })
    //     .catch(function (error) {
    //       if (error) {
    //         toast.warn("l'adresse mail est djà utilsé", {});
    //       }
    //       console.log("REGISTER: ", error.message);
    //     });
    // }
  }
  
  handleChnge(e) {
    // this.setState({
    //     etat: e.target.value
    // })

    return window.location.assign("/doctor/doctor-register");
  }

  render() {
    const btnSendFormulaire =
      this.state.name.trim() === "" ||
      this.state.firstName.trim() === "" ||
      this.state.phone1 === "" ||
      this.state.dateNaissance === "" ||
      this.state.adress === "" ||
      this.state.genre === "" ||
      this.state.password === "" ||
      this.state.password.length < 6 ||
      this.state.confirmPassword === "" ||
      this.state.password !== this.state.confirmPassword ||
      this.state.regle == false ? (
        <Button   disabled type="submit" style={{ backgroundColor: "rgb(21,85,141)", color: "white" }}  className="btn  pull-right" loading={this.state.loadings} >
        S'inscrire
        </Button>
      ) : (
        <Button  type="submit" onClick={this.handleSubmit}  style={{ backgroundColor: "rgb(21,85,141)", color: "white" }} className="btn  pull-right" loading={this.state.loadings} >
        S'inscrire
     </Button>
      );

    return (
      <div className="content">
        {/* <div className="container-fluid"> */}
        <div className="container-fluid" style={{ display: "flex", justifyContent: "center", position: "relative" }}>
					<ToastContainer />
          <div className="row">
            <div className="col-md-10">
              <div className="account-content">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-7 col-lg-6 login-left">
                    <img
                      src={loginBanner}
                      className="img-fluid"
                      alt="Doccure Register"
                    />
                  </div>
                  <div className="col-md-12 col-lg-6 login-right">
                    <div className="login-header" style={{ marginTop: "2%" }}>
                      <h1 style={{ fontSize: "25px" }}>
                        Création du compte patient{" "}
                      </h1>
                      <br />
                      {/* <Link to="/doctor/doctor-register" className="" > */}
                      <span style={{ color: "rgb(21, 85, 141)" }}>
                        Êtes-vous professionnel de santé ?{" "}
                      </span>
                      <Link to="/doctor/doctor-register">
                        <input
                          type="radio"
                          name="etat"
                          id="oui"
                          value="oui"
                          onChange={(e) => {
                            this.setState({ etat: e.target.value });
                          }}
                        />
                        <span className="mr-3"> Oui </span>
                      </Link>

                      <Link>
                        <input
                          type="radio"
                          name="etat"
                          id="non"
                          value="non"
                          checked="true"
                          onChange={(e) => {
                            this.setState({ etat: e.target.value });
                          }}
                        />{" "}
                        <span> Non</span>
                      </Link>

                      {/* </Link> */}
                    </div>

                    <form onSubmit={this.handleSubmit}>
                      <FadeIn>
                        <div className="form-group" style={{ margin: "0" }}>
                        <div className="form-group" style={{ margin: "0" }}>
                         
                          {/* <input
                            type="text"
                            className="form-control"
                            name="phone1"
                            placeholder="Votre numéro de téléphone1"
                            id="mobile"
                            onChange={(e) => {
                              this.setState({ phone1: e.target.value });
                            }}
                            value={this.state.phone1}
                          /> */}
                        </div>
                        <br />
                          <label className="focus-label" htmlFor="name">
                            {" "}
                            Nom <span className="etoile"> * </span>{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="ex: BALOU"
                            id="name"
                            onChange={(e) => {
                              this.setState({ name: e.target.value });
                            }}
                            value={this.state.name}
                          />
                        </div>{" "}
                        <br />
                        <div className="form-group" style={{ margin: "0" }}>
                          <label className="focus-label" htmlFor="prenom">
                            Prenom <span className="etoile"> * </span>
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            placeholder="ex: enock"
                            id="prenom"
                            onChange={(e) => {
                              this.setState({ firstName: e.target.value });
                            }}
                            value={this.state.firstName}
                          />
                        </div>{" "}
                        <br />
                        <div className="form-group" style={{ margin: "0" }}>
                          <label className="focus-label" htmlFor="mail">
                            Email 
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder="Votre adresse mail"
                            onChange={(e) => {
                              this.setState({ email: e.target.value });
                            }}
                            value={this.state.email}
                            id="mail"
                          />
                        </div>
                        <br />
                        <label className="focus-label" htmlFor="mobile">
                            Numéro de téléphone N°1{" "}
                            <span className="etoile"> * </span><br />
                            {/* <em className="etoile"> Numéro par lequel sera envoyé le code d'activation du compte</em> */}
                          </label>
                          <PhoneInput
                            className="form-control"
                            country={'cg'}
                            value={this.state.phone1}
                            // onChange={(e) => {
                            //   this.setState({ phone1 });
                            // }}
                            onChange={phone1 => this.setState({ phone1 })}
                            inputProps={{
                              name: 'Numéro de téléphone',
                              required: true,
                             
                            }}
                          /> <br />
                        
                        <div className="form-group" style={{ margin: "0" }}>
                          <label className="focus-label" htmlFor="phone1">
                            Numéro de téléphone N°2
                            <span className="etoile"> (optionnel) </span>
                          </label>
                          <PhoneInput
                            className="form-control"
                            country={'cg'}
                            value={this.state.phone2}
                            // onChange={(e) => {
                            //   this.setState({ phone1 });
                            // }}
                            onChange={phone2 => this.setState({ phone2 })}
                            inputProps={{
                              name: 'Numéro de téléphone',
                              required: true,
                             
                            }}
                          />
                          {/* <input
                            type="text"
                            className="form-control"
                            name="phone2"
                            placeholder="Votre numéro de téléphone2"
                            id="phone1"
                            onChange={(e) => {
                              this.setState({ phone2: e.target.value });
                            }}
                            value={this.state.phone2}
                          /> */}
                        </div>{" "}
                        <br />
                        <div className="form-group" style={{ margin: "0" }}>
                          <label
                            className="focus-label"
                            htmlFor="dateNaissance"
                          >
                            Date de Naissance{" "}
                            <span className="etoile"> * </span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            name="dateNaissance"
                            placeholder="votre date de naissance"
                            id="dateNaissance"
                            onChange={(e) => {
                              this.setState({ dateNaissance: e.target.value });
                            }}
                            value={this.state.dateNaissance}
                          />
                        </div>{" "}
                        <br />
                        <div className="form-group" style={{ margin: "0" }}>
                          <label className="focus-label" htmlFor="adresse">
                            Adresse<span className="etoile"> * </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="adress"
                            placeholder="votre adresse "
                            id="adresse"
                            onChange={(e) => {
                              this.setState({ adress: e.target.value });
                            }}
                            value={this.state.adress}
                          />
                        </div>
                        <br />
                        <div className="form-group" style={{ margin: "0" }}>
                          <label
                            className="focus-label"
                            htmlFor="adresseComplement"
                          >
                            Complement d'adresse
                            <span className="etoile"> (optionnel) </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="complementAdress"
                            placeholder="votre adresse "
                            id="adresseComplement"
                            onChange={(e) => {
                              this.setState({
                                complementAdress: e.target.value,
                              });
                            }}
                            value={this.state.complementAdress}
                          />
                        </div>
                        <br />
                        <div className="form-group" style={{ margin: "0" }}>
                          <label className="focus-label" htmlFor="groupSanguin">
                            Groupe Sanguin <span className="etoile"> (optionnel) </span>
                          </label>
                          <br />
                          <select
                            className="form-group form-control"
                            onChange={(e) => {
                              this.setState({ value: e.target.value });
                            }}
                            value={this.state.value}
                            name="groupSanguin"
                            id="groupSanguin"
                          >
                            <option value=""> votre groupe sanguin </option>
                            <option value="a"> Groupe A</option>
                            <option value="b">Groupe B</option>
                            <option value="ab">Groupe AB</option>
                            <option value="o">Groupe O</option>
                          </select>
                        </div>
                        <br />
                        <div className="form-group" style={{ margin: "0" }}>
                          <label className="focus-label" htmlFor="sexe">
                            Sexe<span className="etoile"> * </span>
                          </label>{" "}
                          <br />
                          <input
                            type="radio"
                            name="genre"
                            id="m"
                            value="masculin"
                            onChange={(e) => {
                              this.setState({ genre: e.target.value });
                            }}
                          />
                          <span className="mr-3"> M</span>
                          <input
                            type="radio"
                            name="genre"
                            id="f"
                            value="feminin"
                            onChange={(e) => {
                              this.setState({ genre: e.target.value });
                            }}
                          />{" "}
                          <span>F</span>
                        </div>
                        <br />
                        <div className="form-group" style={{ margin: "0" }}>
                          <label className="focus-label" htmlFor="password">
                            {" "}
                            Mot de passe<span className="etoile"> * </span>
                          </label>

                          <Input.Password
                            type="password"
                            className="form-control"
                            name="password"
                            placeholder="votre Mot de passe"
                            id="password"
                            onChange={(e) => {
                              this.setState({ password: e.target.value });
                            }}
                            value={this.state.password}
                          />
                          <br />
                          <em className="etoile">
                            {" "}
                            le mot de passe doit contenir au moins 6 caractères
                            ou plus{" "}
                          </em>
                        </div>
                        <br />
                        <div className="form-group" style={{ margin: "0" }}>
                          <label
                            className="focus-label"
                            htmlFor="ConfirmPassword"
                          >
                            {" "}
                            Confirmation du Mot de passe
                            <span className="etoile"> * </span>
                          </label>
                          <Input.Password
                            type="password"
                            className="form-control"
                            placeholder="votre mot de passe "
                            id="ConfirmPassword"
                            onChange={(e) => {
                              this.setState({
                                confirmPassword: e.target.value,
                              });
                            }}
                            value={this.state.confirmPassword}
                          />
                        </div>
                        <br />
                        {/* <input name="" type="checkbox"  required /> I accept the terms of service
                                                <label> </label> <br /> <br /> */}
                        <div className="form-group" style={{ margin: "0" }}>
                          <input
                            type="checkbox"
                            name="regles"
                            id="regles"
                            onChange={(e) => {
                              this.setState({ regle: e.target.value });
                            }}
                            checked={this.state.regle}
                          />{" "}
                          <span>
                            {" "}
                            <a href="#">
                              J’accepte les conditions d’utilisation
                            </a> <span className="etoile">*</span>
                          </span>
                        </div>
                        <br />
                        <p>
                          {" "}
                          (<span className="etoile">*</span>){" "}
                          <em>le champ est obligatoire </em>
                        </p>{" "}
                        <br />
                        <div className="text-right">
                          <Link to="/login" className="forgot-link">
                            Vous avez déjà un compte?
                          </Link>
                          <br />
                        </div>
                        <br />
                        {btnSendFormulaire}                                
                      </FadeIn>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
    );
  }
}

export default Register;
