import React, { Component } from 'react';
import loginBanner from '../../assets/images/login-banner.png';
//Icon
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import AuthCode from 'react-auth-code-input';
import './validate.css'

import host from '../../../host';
// import { Toast } from 'react-toastify/dist/components';

class ValidateCompte extends Component {

	constructor(props) {
      super(props)
		this.state = {
			isLoading: false,
			redirectToReferre: false,
			code:"",
      numtel1: "",
      id: ""
		}
		this.submit = this.submit.bind(this)
		this.resendCode = this.resendCode.bind(this)
		
	}

	submit() {
		// event.preventDefault();
		this.setState({
			isLoading: true,
		});

        var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                "code": this.state.code,
                "id": this.state.id
                });

                var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
                };

                fetch(host+"/user/verification", requestOptions)
                .then(response => {
                    if(response.status === 200){
                        return response.json()
                    }
                    if(response.status === 400){
                        toast.warn("le code saisi n'est plus valide");
                        this.setState({
                            isLoading: false,
                            redirectToReferre: true
                        });
                    }
                  //   if(response.status === 405){
                  //     toast.warn("le code saisi est incorret");
                  //     this.setState({
                  //         isLoading: false,
                  //         redirectToReferre: true
                  //     });
                  // }
                })
                .then(result => {
                    console.log("result: ", result)
                    if (result.data.patient !== null) {
                        window.location.assign("/patient/dashboard");
                        sessionStorage.setItem(
                          "user",
                          JSON.stringify({
                            token: result.data.token,
                            userId: result.data.userId,
                            user: result.data,
                            state: true,
                          })
                        );
                      }
                      if (result.data.professionnelsante !== null) {
                        window.location.assign("/doctor/doctor-dashboard");
                        sessionStorage.setItem(
                          "user",
                          JSON.stringify({
                            token: result.data.token,
                            userId: result.data.userId,
                            user: result.data,
                            state: true,
                          })
                        );
                      }
                      if (result.data.gestionnairecentre && result.data.gestionnairecentre !== null) {
                        window.location.assign("/gestionnaire/dashboard");
                        sessionStorage.setItem(
                          "user",
                          JSON.stringify({
                            token:result.data.token,
                            userId:result.data.userId,
                            user: result.data,
                            state: true,
                          })
                        );
                      }
                    
                })
                .catch(error =>{ 
                    console.log('error: ', error)
                    toast.warn("erreur du code saisi", {});
                    this.setState({
                        isLoading: false,
                        redirectToReferre: true
                    });
                });
                    
	}
    resendCode(e){
      e.preventDefault();
        var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");        
            var raw = JSON.stringify({
            "numtel1": this.state.numtel1
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };

            fetch(host+"/user/sendcode", requestOptions)
            .then(response =>{ 
                response.text()
                if (response.status === 200) {
                    toast.success("le code à été renvoyé avec succès.")
                }
                if(response.status === 201){
                  toast.success("votre compte est déja actif.")
                }
                if(response.status === 417){
                  toast.warn("erreur, Veuillez réessayer dans un instant.")
                }
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
                }

   
    


	componentDidMount() {
		let dbUser = JSON.parse(sessionStorage.getItem("userPhone")) || JSON.parse(sessionStorage.getItem("userPhone"));
    if (!dbUser) {
      this.props.history.push('/')
      document.body.classList.add('account-page');
  }
    if(dbUser){
      this.setState({
        numtel1: dbUser.phone,
        id: dbUser.id
    })
    }
        // console.log('db: ',dbUser.phone);
	}
	componentWillUnmount() {
		document.body.classList.remove('account-page');
	}

	render() {
            // console.log("object", this.state.numtel1);

		return (
			<React.Fragment>
				<div 
        className="content"
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}>
					<ToastContainer />
  
					<div className={this.state.isLoading ? "container-fluid page_wrapper_blur" : "container-fluid"}>

						<div className="row">
							<div className="col-md-8 offset-md-2">
								<div className="account-content">
									<div className="row align-items-center justify-content-center">
										<div className="col-md-7 col-lg-6 login-left">
											<img src={loginBanner} className="img-fluid" alt="Login Banner" />
										</div>
										<div className="col-md-12 col-lg-6 login-right">
											<div className="login-header">
												<h3>Validation du compte</h3>
												<br />
												<p className="small text-muted">Entrez le code qui vous aviez été envoyé par SMS</p>
											</div> 

											<form>
												<div className="form-group">
													{/* <input type="text" className="form-control" value={this.state.code} onChange={(e) => this.setState({code: e.target.value }) } */}
														{/* placeholder="Votre adresse mail" required="required"/> */}
                                                        <AuthCode
                                                            characters={5}
                                                            // onChange={() => null}
                                                            value={this.state.code}
                                                            // onChange={() => this.state.code }
                                                           
                                                                onChange={code => {
                                                                  this.setState({ code });
                                                                  setTimeout(() => {
                                                                    if(code.length === 5){
                                                                      console.log("code: ", this.state.code)
                                                                      this.submit();
                                                                    }
                                                                  }, 250);
                                                              }}
                                                            containerClassName='container'
                                                            inputClassName='input'
                                                            />										
                        
                                                    <label className="focus-label"></label>
												</div>
												<div className="text-right">
													<Link to="#" onClick={this.resendCode} className="forgot-link">Renvoyer le code par SMS</Link> 
													{/* <button  onClick={this.resendCode} className="forgot-link">Renvoyer le code par SMS</button>  */}
												</div><br />
												{/* <button onClick={this.submit} className="btn btn-bluefonce btn-block btn-lg login-btn" type="submit">Valider mon compte</button> */}
											</form>


										</div>
									</div>
								</div>


							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

}
export default ValidateCompte;

