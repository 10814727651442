import React, { Component } from 'react';
import { Link } from 'react-router-dom';
//images
import { IMG01, IMG02, IMG03, IMG04, IMG05, IMG06, IMG07 } from './img.jsx';
//components
import BlogListSearch from '../search/search.jsx';
import TagsWidget from '../tagswidget';
import AppUtils from "../../../../utils/app_utils"
import {InlineShareButtons} from 'sharethis-reactjs';
import Loader from 'react-loader-spinner'


class BlogDetails extends Component {
    constructor(props) {
        super(props)
        this.state = { isLoading: true, scriptLoaded: false, data: [] }
    }



    async componentDidMount() {

        const { match: { params } } = this.props;
        if (AppUtils.isNumeric(params.id)) {
            const id = parseInt(params.id);
            let URL = AppUtils.baseURL + "/articles"
            let response = await fetch(`${URL}/${id}`);
            if (response.status == 200) {
                let data = await response.json()
                this.setState({ data, isLoading: false })
            }
        } else {
            this.props.history.push("/404")
        }

    }
    render() {
        if (this.state.isLoading) {
            /**type="text/javascript"
        src="https://platform-api.sharethis.com/js/sharethis.js#property=601157e3fa4baf00143d79b9&product=inline-share-buttons"
        async="async" */
            return (
                <div style={{ textAlign:"center", marginTop: "50vh", marginBottom: "40vh" }}>
				<Loader
					type="Puff"
					color="#00BFFF"
					height={100}
					width={100}
					timeout={3000} //3 secs

				/>
			</div>
            )
        } else {
            return (
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <div className="blog-view">
                                    <div className="blog blog-single-post">
                                        <div className="blog-image">
                                            <Link><img alt="" src={this.state.data.image[0].url} style={{ borderRadius: "2%" }} className="img-fluid" /></Link>
                                        </div>
                                        <h3 className="blog-title">{this.state.data.title}</h3>
                                        <div className="blog-info clearfix">
                                            <div className="post-left">
                                                <ul>
                                                    <li>
                                                        <div className="post-author">
                                                            <span>{this.state.data.auteur.prenom + " " + this.state.data.auteur.nom}</span>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-calendar"></i>{this.state.data.published_at.substring(0, 10)}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="blog-content text-justify">
                                            <p>{this.state.data.contenu}</p>
                                        </div>
                                    </div>

                                    <div className="card blog-share clearfix">

                                        <div className="card-header">

                                            <h4 className="card-title">Partager la publication</h4>
                                        </div>
                                        <div className="card-body">
                                            <InlineShareButtons
                                                config={{
                                                    alignment: 'left',  // alignment of buttons (left, center, right)
                                                    color: 'social',      // set the color of buttons (social, white)
                                                    enabled: true,        // show/hide buttons (true, false)
                                                    font_size: 16,        // font size for the buttons
                                                    language: 'fr',       // which language to use (see LANGUAGES)
                                                    padding: 12,          // padding within buttons (INTEGER)
                                                    radius: 4,            // the corner radius on each button (INTEGER)
                                                    size: 40,             // the size of each button (INTEGER)

                                                }}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <BlogListSearch />
                                <TagsWidget />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

    }
}
export default BlogDetails;