import React, { Component } from "react";
import { Table, Switch, Button, Modal, DatePicker, Space } from "antd";
import { Link } from "react-router-dom";
import SidebarNav from "../sidebar";
import { MDBDataTableV5 } from "mdbreact";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { itemRender, onShowSizeChange } from "../paginationfunction";
import host from "../../../host";

const { RangePicker } = DatePicker;

class Villes extends Component {
  constructor(props) {
    super(props);

    let token = "";

    if (sessionStorage.getItem("admin")) {
      token = JSON.parse(sessionStorage.getItem("admin")).token;
    }

    this.state = {
      auth: token,
      isLoading: false,
      isModalVisible: false,
      isDetail: false,
      isDelete: false,
      isUpdate: false,

      slug: "",
      libelle: "",
      pays: null,
      ville: null,
      idPays: "",
      allPays: [],
      allData: [],

      columns: [
        {
          label: "id",
          field: "idville",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "nom",
          },
        },
        {
          label: "Nom de la ville",
          field: "libelle",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "nom",
          },
        },
        {
          label: "Nom du pays",
          field: "paysNom",
          width: 100,
        },
        {
          label: "Actions",
          field: "action",
          width: 100,
        },
      ],
      rows: [{}],
    };
  }

  show = (item) => {
    this.setState({});
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(host + "/ville/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status != 200) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        if (result != null) {
          for (let index = 0; index < result.length; index++) {
            result[index]["paysNom"] = result[index].pays.libelle;
            result[index]["action"] = (
              <div className="actions">
                <a
                  href="#0"
                  className="btn btn-sm bg-success-light"
                  onClick={() => this.showUpdate(result[index])}
                >
                  <i className="fe fe-pencil"></i> Modifier
                </a>
                <a
                  href="#0"
                  className="btn btn-sm bg-danger-light"
                  onClick={() => this.showDelete(result[index])}
                >
                  <i className="fe fe-trash"></i> Supprimer
                </a>
              </div>
            );
          }
          this.setState({
            rows: result,
          });
        }
      })
      .catch((error) => console.log("error", error));

    await fetch(host + "/pays/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status != 200) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        if (result != null) {
          this.setState({
            allPays: result,
          });
        }
      })
      .catch((error) => console.log("error", error));

    this.setState({ isLoading: false });
  }

  handleOnclick = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  showUpdate = (item) => {
    this.setState({
      isUpdate: true,
      isModalVisible: true,
      ville: item,
      pays: item.pays,
      idPays: item.pays.idpays,
      libelle: item.libelle,
    });
  };

  showDelete = (item) => {
    this.setState({
      isDelete: true,
      ville: item,
      pays: item.pays,
      idPays: item.pays.idpays,
      libelle: item.libelle,
    });
  };

  handleOk = () => {
    this.setState({
      isModalVisible: false,
      isDetail: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      isDetail: false,
      isDelete: false,
      isUpdate: false,
      libelle: "",
      idPays: "",
      pays: null,
      ville: null,
    });
  };

  handleChangeLibelle = (e) => {
    this.setState({ libelle: e.target.value });
  };

  handleChangeIdPays = (e) => {
    this.setState({ idPays: e.target.value });
  };

  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    console.log(this.props);

    if (this.state.isUpdate) {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let raw = JSON.stringify({
        idpays: this.state.idPays,
        slug: this.state.idPays + "_" + this.state.libelle,
        libelle: this.state.libelle,
      });
      for (let index = 0; index < this.state.allPays.length; index++) {
        const element = this.state.allPays[index];
        if (element.idpays == this.state.idPays) {
          this.setState({
            pays: element,
          });
        }
      }

      let requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + `/ville/update/${this.state.ville.idville}`, requestOptions)
        .then((response) => {
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200 && response.status != 201) {
            return null;
          }
          return response.json();
        })
        .then((result) => {
          console.log(result);
          if (result == null) {
            toast.error("Echec de la modification");
          } else {
            for (let index = 0; index < this.state.rows.length; index++) {
              const element = this.state.rows[index];
              if (element.idville == this.state.ville.idville) {
                this.state.rows[index].libelle = this.state.libelle;
                for (let i = 0; i < this.state.allPays.length; i++) {
                  const element = this.state.allPays[i];
                  if (element.idpays == this.state.idPays) {
                    this.state.rows[index].pays = element;
                    this.state.rows[index]["paysNom"] =
                      this.state.rows[index].pays.libelle;
                    this.state.rows[index]["action"] = (
                      <div className="actions">
                        <a
                          href="#0"
                          className="btn btn-sm bg-success-light"
                          onClick={() =>
                            this.showUpdate(this.state.rows[index])
                          }
                        >
                          <i className="fe fe-pencil"></i> Modifier
                        </a>
                        <a
                          href="#0"
                          className="btn btn-sm bg-danger-light"
                          onClick={() =>
                            this.showDelete(this.state.rows[index])
                          }
                        >
                          <i className="fe fe-trash"></i> Supprimer
                        </a>
                      </div>
                    );
                    break;
                  }
                }
                break;
              }
            }
            this.setState({});
            toast.success("Modification réalisée avec succès");
            this.handleCancel();
          }
        })
        .catch((error) => {
          console.log("error", error);
          toast.error("Echec de la modification : " + error);
        })
        .then(() => {
          this.setState({ isLoading: false });
        });
    } else {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let raw = JSON.stringify({
        idpays: this.state.idPays,
        slug: this.state.idPays + "_" + this.state.libelle,
        libelle: this.state.libelle,
      });
      for (let index = 0; index < this.state.allPays.length; index++) {
        const element = this.state.allPays[index];
        if (element.idpays == this.state.idPays) {
          this.setState({
            pays: element,
          });
        }
      }

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(host + "/ville/create/", requestOptions)
        .then((response) => {
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200 && response.status != 201) {
            return null;
          }
          return response.json();
        })
        .then((result) => {
          console.log(result);
          if (result == null) {
            toast.error("La ville ne c'est pas correctement enregistrée");
          } else {
            const tmp = result.ville;
            tmp["paysNom"] = tmp.pays.libelle;
            tmp["action"] = (
              <div className="actions">
                <a
                  href="#0"
                  className="btn btn-sm bg-success-light"
                  onClick={() => this.showUpdate(tmp)}
                >
                  <i className="fe fe-pencil"></i> Modifier
                </a>
                <a
                  href="#0"
                  className="btn btn-sm bg-danger-light"
                  onClick={() => this.showDelete(tmp)}
                >
                  <i className="fe fe-trash"></i> Supprimer
                </a>
              </div>
            );
            this.setState({
              rows: [tmp, ...this.state.rows],
            });
            toast.success("Insertion réalisée avec succès");
            this.handleCancel();
          }
        })
        .catch((error) => {
          console.log("error", error);
          toast.error("Error : " + error);
        })
        .then(() => {
          this.setState({ isLoading: false });
        });
    }

    e.preventDefault();
  };

  handleOkDelete = () => {
    if (this.state.ville != null) {
      this.setState({
        isLoading: true,
      });

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: "",
        redirect: "follow",
      };

      fetch(host + `/ville/delete/${this.state.ville.idville}`, requestOptions)
        .then((response) => {
          console.log(response);
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200 && response.status != 201) {
            return null;
          }
          return response.json();
        })
        .then((result) => {
          console.log(result);
          if (result == null) {
            toast.error("Echec de la suppression");
          } else {
            let tmp = this.state.rows;
            for (let index = 0; index < tmp.length; index++) {
              const element = tmp[index];
              if (element.idville == this.state.ville.idville) {
                tmp.splice(index, 1);
                break;
              }
            }
            this.setState({
              rows: [...tmp],
            });
            toast.success("Suppression réalisée avec succés");
            this.handleCancel();
          }
          this.setState({
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          toast.error("Echec de la suppression");
          console.log(error);
        });
    }
  };

  render() {
    return (
      <>
        <SidebarNav />
        <ToastContainer />

        {/* Delete Modal */}
        <Modal
          title="Suppression d'une ville"
          visible={this.state.isDelete}
          okText="Confirmer"
          cancelText="Annuler"
          okType="danger"
          onOk={this.state.isLoading ? null : this.handleOkDelete}
          onCancel={this.state.isLoading ? null : this.handleCancel}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <div className="form-content p-2">
            <h4 className="modal-title">Supprimer {this.state.libelle}</h4>
            <Loader
              style={{
                position: "absolute",
                textAlign: "center",
                marginLeft: "auto",
                left: "0",
                right: "0",
                marginRight: "auto",
                zIndex: "20px",
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            />
            <p className="mb-4">Confimer vous la suppression</p>
          </div>
        </Modal>

        {/* Delete Modal */}

        <Modal
          title={
            this.state.isUpdate
              ? "Modification de la ville"
              : "Ajouter d'une ville"
          }
          visible={this.state.isModalVisible}
          onOk={this.state.isLoading ? null : this.handleOk}
          onCancel={this.state.isLoading ? null : this.handleCancel}
          footer={null}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <form
            form
            action="/"
            onSubmit={this.state.isLoading ? null : this.handleSubmit}
            id="addform"
          >
            <div className="row form-row">
              <div className="col-12">
                <div className="form-group">
                  <div className="col-12">
                    <div className="form-group">
                      <Loader
                        style={{
                          position: "absolute",
                          textAlign: "center",
                          marginLeft: "auto",
                          left: "0",
                          right: "0",
                          marginRight: "auto",
                          zIndex: "200px",
                        }}
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={this.state.isLoading}
                      />
                      <label>Nom de la ville</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Entrer le nom de la ville"
                        name="libelle"
                        value={this.state.libelle}
                        onChange={this.handleChangeLibelle}
                        disabled={this.state.isLoading}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Pays</label>
                      <select
                        className="form-control"
                        type="text"
                        name="pays"
                        value={this.state.idPays}
                        onChange={this.handleChangeIdPays}
                        disabled={this.state.isLoading}
                        required
                      >
                        <option value="">Selectioner un pays</option>
                        {this.state.allPays.length > 0
                          ? this.state.allPays.map((item) => {
                              return (
                                <option value={item.idpays}>
                                  {item.libelle}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn btn-primary btn-block"
              type="submit"
              disabled={this.state.isLoading}
            >
              {this.state.isUpdate ? "Modifier" : "Ajouter"}
            </button>
          </form>
        </Modal>

        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Liste des villes</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admin">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item active">Villes</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Button
                  className="btn btn-primary  mb-2"
                  type="primary"
                  size="large"
                  onClick={this.handleOnclick}
                  disabled={this.state.isLoading}
                >
                  AJOUTER
                </Button>
                <div
                  className={
                    this.state.isLoading &&
                    !this.state.isDelete &&
                    !this.state.isUpdate
                      ? "page_wrapper_blur card"
                      : "card"
                  }
                >
                  <div className="card-body">
                    <div className="table">
                      <Loader
                        style={{
                          position: "absolute",
                          textAlign: "center",
                          marginLeft: "auto",
                          left: "0",
                          right: "0",
                          marginRight: "auto",
                          zIndex: "2000",
                        }}
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={
                          this.state.isLoading &&
                          !this.state.isDelete &&
                          !this.state.isUpdate
                        }
                      />
                      <MDBDataTableV5
                        entriesOptions={[10, 20, 30]}
                        entries={10}
                        responsive={true}
                        entriesLabel={"Lignes par pages"}
                        searchLabel={"Rechercher"}
                        infoLabel={["", "-", "/", ""]}
                        noRecordsFoundLabel={"Aucune données de correspond"}
                        data={{
                          columns: this.state.columns,
                          rows: this.state.rows,
                        }}
                        searchTop
                        searchBottom={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Villes;
