import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import StickyBox from "react-sticky-box";
import { IMG01, IMG02, IMG03, IMG04, IMG05, IMG06, IMG07, IMG08, IMG010, IMG011 } from './img';
import DoctorSidebar from '../sidebar';
import moment from 'moment';
import Pagination from 'react-bootstrap/Pagination';





class Appointments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            professionnel: { rdvs: [] },
            //professionnel : [],
            isLoading: false,
            //creneaux: []
            patients: [],
            patients2: [],
            etat: "btn bg-success-light btn-sm",
            index: 0,
            hourRdv: '',
            dateRdv: '',
            rdv:[]

        }
    }



    async componentDidMount() {
        const dataJson = sessionStorage.getItem('user') || sessionStorage.getItem('user');
        const data = dataJson && JSON.parse(dataJson);

        //const idProfessionnel =  data.user.profesionnel_de_sante.id;
        //  console.log(idProfessionnel);

        let pfSanteResponse = await
            fetch(`https://kwaber-sante.herokuapp.com/users/${data.user.id}`)

        if (pfSanteResponse.status == 200) {
            let data = await pfSanteResponse.json()
            this.setState({
                professionnel: data,
                isLoading: false
            });
        }

            let rdvResponse = await
            fetch(`https://kwaber-sante.herokuapp.com/RDVS?creneau.userPro=${data.user.id}`)

        if (rdvResponse.status == 200) {
            let data2 = await rdvResponse.json()
            this.setState({
                rdv:data2
            });
            console.log(data2,"data2")

            let tabs = []
            this.setState({ patients: [] })
            data2.forEach(async (rdv) => {
                console.log(rdv.creneau, "rdv creneau")
                let creneauxResponse = await
                    fetch(`https://kwaber-sante.herokuapp.com/creneaus/${rdv.creneau.id}`);
                if (creneauxResponse.status == 200) {
                    let creneauxData = await creneauxResponse.json()
                    console.log(creneauxData, "cr data")
                    tabs.push(creneauxData.user)


                    this.setState({
                        patients: tabs
                    })
                    console.log(this.state.patients, "tab patient")



                }

            })


        }
    }





    heureRDV = (heure) => {
        var str = heure;
        var tab = str.split(":");
        return tab[0] + "h" + tab[1]
    }

    handleClose = () => {
        this.setState({
            show: false
        });
    }

    handleShow = () => {
        this.setState({
            show: true
        });
    }
    showItem = (id) => {
        // var requestOptions = {
        //     method: 'GET',
        //     redirect: 'follow'
        //   };

        //   fetch(`https://kwaber-sante.herokuapp.com/creneaus/${id}`, requestOptions)
        //     .then(response => response.text())
        //     .then(result => console.log(result))
        //     .catch(error => console.log('error', error));
        this.setState({
            hourRdv: id.heureRDV,
            dateRdv: id.dateRDV
        });
        this.handleShow();

    }
    render() {
        const { professionnel, isLoading } = this.state;
        if (isLoading) {
            return <p>Loading ...</p>;
        }
        return (

            <div>
                <div className="content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                                <div className="appointments">
                                    <StickyBox offsetTop={50} offsetBottom={20}>
                                        <DoctorSidebar />
                                    </StickyBox>
                                </div>
                            </div>
                            <div className="col-md-7 col-lg-8 col-xl-9">
                                <div className="appointments">
                                    {
                                    this.state.rdv.length === 0 ? (
                                        <div class="jumbotron jumbotron-fluid">
                                            <div class="container">
                                                <h1 class="display-4">Mes Rendez-vous</h1>
                                                <p class="lead"> Vous n'avez pas encore de rendez-vous.</p>
                                            </div>
                                        </div>
                                    ) :

                                        this.state.rdv.map((rdv, index) => {
                                            return <span key={rdv.id}>
                                                <div className="appointment-list">

                                                    {
                                                        this.state.patients[index] !== undefined && <span key={"patient" + this.state.patients[index].id}>
                                                            {console.log(this.state.patients[index],"index")}
                                                            <div className="profile-info-widget">
                                                                <Link className="booking-doc-img" to={{
                                                                    pathname: "/doctor/patient-profile",
                                                                    patient: {
                                                                        idPatient: this.state.patients[index].id
                                                                    }
                                                                }}>
                                                                    {(this.state.patients[index].photo[0] !== undefined && this.state.patients[index].photo[0] !== null)? this.state.patients[index].photo[0].url : <img src="https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" alt="User" /> }
                                                                </Link>
                                                                <div className="profile-det-info">
                                                                    <Link to={{
                                                                        pathname: "/doctor/patient-profile",
                                                                        patient: {
                                                                            idPatient: this.state.patients[index].id
                                                                        }
                                                                    }}>{this.state.patients[index].nom} {this.state.patients[index].prenom}</Link>
                                                                    <div className="patient-details">
                                                                        <h5><i className="far fa-clock"></i> {rdv.dateRDV} à {this.heureRDV(rdv.heureRDV)}</h5>
                                                                        <h5><i className="fas fa-map-marker-alt"></i> {this.state.patients[index].adresse == undefined ? "pas renseigné" : this.state.patients[index].adresse}</h5>
                                                                        <h5><i className="fas fa-envelope"></i> {this.state.patients[index].email} </h5>
                                                                        <h5 className="mb-0"><i className="fas fa-phone"></i> {this.state.patients[index].numTelephone1 == undefined ? "pas renseigné" : this.state.patients[index].numTelephone1} </h5>
                                                                        <h5 className="mb-0"><i className="fas fa-phone"></i> {this.state.patients[index].numTelephone2 == undefined ? "pas renseigné" : this.state.patients[index].numTelephone2} </h5>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </span>
                                                    }

                                                    <div className="appointment-action">
                                                        <Link className="btn btn-sm btn-bluefonce" onClick={() => this.showItem(rdv)}>
                                                            <i className="far fa-eye"></i> Voir
                                                        </Link>
                                                        {/* <Link className="btn btn-sm btn-bluefonce">
                                                            <i className="fas fa-check"></i> Accepter
                                                        </Link>
                                                        <Link className="btn btn-sm bg-danger-light">
                                                            <i className="fas fa-times"></i> Cancel
                                                        </Link> */}
                                                    </div>
                                                </div>

                                            </span>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*view modal*/}
                <Modal show={this.state.show} onHide={this.handleClose} centered>
                    <span>

                        <Modal.Header closeButton>
                            <h5 class="modal-title">Détails du rendez-vous</h5>
                        </Modal.Header>
                        <Modal.Body>
                            <ul class="info-details">
                                <li>
                                    <div class="details-header">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <span class="title">Date du rendez-vous</span>
                                                <span class="text"></span>
                                                <span class="text">{this.state.dateRdv} à {this.state.hourRdv.substring(0, 5)}</span>

                                            </div>
                                            <div class="col-md-6">
                                                <div class="text-right">
                                                    <button type="button" class={this.state.etat} id="topup_status">Confirmé</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <span class="title">Status:</span>
                                    <span class="text">En cours</span>
                                </li>
                                <li>
                                    <span class="title">Date de confirmation de rendez-vous</span>
                                    <span class="text">{this.state.dateRdv}</span>
                                </li>
                                {/* <li>
                                    <span class="title">Montant Payé</span>
                                    <span class="text">100€</span>
                                </li> */}
                            </ul>
                        </Modal.Body>
                    </span>
                </Modal>

            </div >
        );
    }
}
export default Appointments;