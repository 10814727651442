import React, { Component } from "react";
import { Modal, Space, Button, Select } from "antd";
import { Link } from "react-router-dom";
import SidebarNav from "../sidebar";
import { PlusOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { MDBDataTableV5 } from "mdbreact";
import { itemRender, onShowSizeChange } from "../paginationfunction";
import host from "../../../host";
import { Image } from "antd";
const { Option } = Select;

class Centre extends Component {
  constructor(props) {
    super(props);

    let token = "";

    if (sessionStorage.getItem("admin")) {
      token = JSON.parse(sessionStorage.getItem("admin")).token;
    }

    this.state = {
      auth: token,
      isLoading: false,
      isModalVisible: false,
      isDetail: false,
      isDelete: false,
      isUpdate: false,
      gestionnaires: [],
      idgestionnairecentre: 0,

      slug: "",
      libelle: "",
      adresse: "",
      complementadresse: "",
      tel1: "",
      tel2: "",
      fictif: 0,
      uploadedFile: null,

      centre: null,
      columns: [
        {
          label: "id",
          field: "idcentre",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "nom",
          },
        },
        {
          label: "Nom du centre",
          field: "libelle",
          width: 100,
        },
        {
          label: "Fictif",
          field: "fictif",
          width: 100,
        },
        {
          label: "Telephone 1",
          field: "tel1",
          width: 100,
        },
        {
          label: "Telephone 2",
          field: "tel2",
          width: 100,
        },
        {
          label: "Actions",
          field: "action",
          width: 100,
        },
      ],
      rows: [{}],
    };

    this.changeProfileImage = this.changeProfileImage.bind(this);
  }

//Change profile Image
changeProfileImage = (event) => {
  console.log("target:", event.target.files[0])
  const reader = new FileReader();
  reader.readAsDataURL(event.target.files[0]);
  reader.onloadend = () => {
    //  console.log("reader:", reader.result)
    this.setState({
      uploadedFile: reader.result,
      color: false,
      // imageProfile: Date.now() + event.target.files[0].name
    });
    // console.log("PHOTO_USER", event.target.files[0].name);
  };
    setTimeout(() => {
      this.updateImage();
    }, 3000);

};

fetchDataUse = () => {
  var myHeaders = new Headers();
  // myHeaders.append("auth", this.state.auth);
  myHeaders.append("Content-Type", "application/json");
  
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  fetch(host+`/centres/${this.state.centre.idcentre}`, requestOptions)
    .then(response => {
      if(response.status === 200){
        return response.json()
      }
    })
    .then(result => { 
      console.log("SS",result)
      this.setState({
        photo: result.photo
      })
    })
    .catch(error => console.log('error2:', error));
} 

//Update image
updateImage = () => {

  console.log("uploadedFile:", this.state.uploadedFile)
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  let raw = JSON.stringify({
    data: this.state.uploadedFile,
  });

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(host + `/centres/updateImage/${this.state.centre.idcentre}`, requestOptions)
    // fetch(host+`/user/updateImage/22`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      if (result.status === "error") {
        console.log('objectErr',result );
        this.setState({
          color: true,
        });
      }
      this.fetchDataUse();
    })
    .catch((error) => {
       console.log("error", error);
    });
};



  showUpdate = (item) => {
    this.setState({
      isUpdate: true,
      isModalVisible: true,
      centre: item,
      photo: item.photo,
      libelle: item.libelle,
      adresse: item.adresse,
      complementadresse: item.complementadresse,
      tel1: item.tel1,
      tel2: item.tel2,
      fictif: item.fictif,
      idgestionnairecentre: item.gestionnairecentre.idgestionnairecentre,
    });
  };

  showDelete = (item) => {
    this.setState({
      isDelete: true,
      centre: item,
    });
  };

  show = (item) => {
    this.setState({
      isDetail: true,
      centre: item,
    });
  };

  handleChange = (e, tag) => {
    this.state[tag] = e.target.value;
    this.setState({});
  };

  handleChangeGestionnaire = (value) => {
    this.setState({ idgestionnairecentre: value });
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(host + "/centres/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status != 200) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);

        if (result != null) {
          let centres = result.centre;
          for (let index = 0; index < centres.length; index++) {
            centres[index]["action"] = (
              <div className="actions">
                <button
                  className="btn bg-info-light trash p-2"
                  onClick={() => this.show(centres[index])}
                >
                  <i className="far fa-eye"></i>
                </button>
                <button
                  className="btn btn-sm bg-info-light p-2"
                  onClick={() => this.showUpdate(centres[index])}
                >
                  <i className="far fa-edit"></i>
                </button>
                <button
                  className="btn bg-danger-light trash p-2"
                  onClick={() => this.showDelete(centres[index])}
                >
                  <i className="far fa-trash-alt"></i>
                </button>
              </div>
            );
          }
          console.log(centres);
          this.setState({
            rows: centres,
          });
        }
      })
      .catch((error) => console.log("error", error));

    await fetch(host + "/gestionnairecentre/", requestOptions)
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status != 200) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        console.log(result);
        if (result != null) {
          console.log(result.gestionnaire);
          this.setState({
            gestionnaires: result.gestionnaire,
          });
        }
      })
      .catch((error) => console.log("error", error))
      .then(() => this.setState({ isLoading: false }));
  }

  handleOnclick = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleOk = () => {
    this.setState({
      isModalVisible: false,
      isDetail: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      isDetail: false,
      isDelete: false,
      isUpdate: false,
      libelle: "",
      adresse: "",
      complementadresse: "",
      tel1: "",
      tel2: "",
      fictif: "",
      centre: null,
      isLoading: false,
      idgestionnairecentre: 0,
    });
  };

  handleOkDelete = () => {
    if (this.state.pays != null) {
      this.setState({
        isLoading: true,
      });
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("auth", this.state.auth);

      let requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        host + `/centres/delete/${this.state.centre.idcentre}`,
        requestOptions
      )
        .then((response) => {
          console.log(response);
          if (response.status == 401) {
            this.props.history.push("/admin/login");
            return null;
          }
          if (response.status != 200 && response.status != 201) {
            return null;
          }
          return response.json();
        })
        .then((result) => {
          console.log(result);
          if (result == null) {
            toast.error("Echec de la suppression");
          } else {
            let tmp = this.state.rows;
            for (let index = 0; index < tmp.length; index++) {
              const element = tmp[index];
              if (element.idcentre == this.state.pays.idcentre) {
                tmp.splice(index, 1);
                break;
              }
            }
            this.setState({
              rows: [...tmp],
            });
            toast.success("Suppression réalisée avec succés");
            this.handleCancel();
          }
          this.setState({
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
          });
          toast.error("Echec de la suppression");
          console.log(error);
        });
    }
  };

  handleChangeLibelle = (e) => {
    this.setState({ libelle: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.state.idgestionnairecentre == 0) {
      toast.warning("Attention un centre doit avoir un gestionnaire");
    } else {
      this.setState({ isLoading: true });
      if (this.state.isUpdate) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("auth", this.state.auth);
        let raw = JSON.stringify({
          Slug: this.state.libelle,
          id: this.state.idgestionnairecentre,
          libelle: this.state.libelle,
          fictif: this.state.fictif,
          adresse: this.state.adresse,
          complementadresse: this.state.complementadresse,
          tel1: this.state.tel1,
          tel2: this.state.tel2,
        });
        let requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        fetch(
          host + `/centres/update/${this.state.centre.idcentre}`,
          requestOptions
        )
          .then((response) => {
            if (response.status == 401) {
              this.props.history.push("/admin/login");
              return null;
            }
            if (response.status != 200 && response.status != 201) {
              return null;
            }
            return response.json();
          })
          .then((result) => {
            console.log(result);
            if (result == null) {
              toast.error("Les modifications n'ont pas été apportées");
            } else {
              for (let index = 0; index < this.state.rows.length; index++) {
                const element = this.state.rows[index];
                if (element.idcentre == this.state.centre.idcentre) {
                  this.state.rows[index].libelle = this.state.libelle;
                  this.state.rows[index].Slug = this.state.libelle;
                  this.state.rows[index].id = this.state.idgestionnairecentre;
                  this.state.rows[index].libelle = this.state.libelle;
                  this.state.rows[index].fictif = this.state.fictif;
                  this.state.rows[index].adresse = this.state.adresse;
                  this.state.rows[index].complementadresse =
                    this.state.complementadresse;
                  this.state.rows[index].tel1 = this.state.tel1;
                  this.state.rows[index].tel2 = this.state.tel2;
                  break;
                }
              }
              this.setState({});
              toast.success("Modification réalisée avec succès");
              this.handleCancel();
            }
          })
          .catch((error) => {
            console.log("error", error);
            toast.error("Error : " + error);
          })
          .then(() => {
            this.setState({ isLoading: false });
          });
      } else {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("auth", this.state.auth);

        let raw = JSON.stringify({
          Slug: this.state.libelle,
          id: this.state.idgestionnairecentre,
          libelle: this.state.libelle,
          fictif: this.state.fictif,
          adresse: this.state.adresse,
          complementadresse: this.state.complementadresse,
          tel1: this.state.tel1,
          tel2: this.state.tel2,
        });

        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(host + "/centres/create", requestOptions)
          .then((response) => {
            if (response.status == 401) {
              this.props.history.push("/admin/login");
              return null;
            }
            if (response.status != 200 && response.status != 201) {
              return null;
            }
            return response.json();
          })
          .then((result) => {
            console.log(result);
            if (result == null) {
              toast.error("Le centre ne c'est pas correctement enregistrée");
            } else {
              let centre = {
                Slug: this.state.libelle,
                id: this.state.idgestionnairecentre,
                libelle: this.state.libelle,
                fictif: this.state.fictif,
                adresse: this.state.adresse,
                complementadresse: this.state.complementadresse,
                tel1: this.state.tel1,
                tel2: this.state.tel2,
                action: (
                  <div className="actions">
                    {/* <button
                      className="btn bg-info-light trash p-2"
                      // onClick={() => this.showDelete(centre)}
                    >
                      <i className="far fa-eye"></i>
                    </button> */}
                    {/* <button
                      className="btn btn-sm bg-info-light p-2"
                      disabled={true}
                      onClick={() => this.showUpdate(centre)}
                    >
                      <i className="far fa-edit"></i>
                    </button>
                    <button
                      className="btn bg-danger-light trash p-2"
                      disabled={true}
                      onClick={() => this.showDelete(centre)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </button> */}
                  </div>
                ),
              };

              this.setState({
                rows: [centre, ...this.state.rows],
              });
              toast.success("Insertion réalisée avec succès");
              this.handleCancel();
            }
          })
          .catch((error) => {
            console.log("error", error);
            toast.error("Error : " + error);
          })
          .then(() => {
            this.setState({ isLoading: false });
          });
      }
    }
  };

  delete = () => {
    this.setState({
      isLoading: true,
    });
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", this.state.auth);
    let requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: "",
      redirect: "follow",
    };
    fetch(
      host + `/centres/delete/${this.state.centre.idcentre}`,
      requestOptions
    )
      .then((response) => {
        if (response.status == 401) {
          this.props.history.push("/admin/login");
          return null;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return "201";
      })
      .then((result) => {
        if (result == null) {
          toast.error("La suppression du centre a échoué");
        } else {
          let tmp = this.state.rows;
          for (let index = 0; index < tmp.length; index++) {
            const element = tmp[index];
            if (element.idcentre == this.state.centre.idcentre) {
              tmp.splice(index, 1);
              break;
            }
          }
          this.setState({ rows: [...tmp] });
          toast.success("Suppression réalisée avec succès");
          this.handleCancel();
        }
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        toast.error("La suppression du centre a échoué");
        console.log("error", error);
      });
  };

  render() {
    return (
      <>
        <SidebarNav />
        <ToastContainer />

        {/* Delete Modal */}
        <Modal
          title="Suppression dun pays"
          visible={this.state.isDelete}
          okText="Confirmer"
          cancelText="Annuler"
          okType="danger"
          onOk={this.state.isLoading ? null : this.delete}
          onCancel={this.state.isLoading ? null : this.handleCancel}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <div className="form-content p-2">
            <h4 className="modal-title">
              Supprimer {this.state.centre ? this.state.centre.libelle : ""}
            </h4>
            <Loader
              style={{
                position: "absolute",
                textAlign: "center",
                marginLeft: "auto",
                left: "0",
                right: "0",
                marginRight: "auto",
                zIndex: "20px",
              }}
              type="Puff"
              color="#00BFFF"
              height={100}
              width={100}
              visible={this.state.isLoading}
            />
            <p className="mb-4">Confimer vous la suppression</p>
          </div>
        </Modal>

        {/* Delete Modal */}

        <Modal
          title="Détail du centre"
          visible={this.state.isDetail && !this.state.isLoading}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          cancelText={"Retour"}
          destroyOnClose={true}
        >
          {this.state.centre === null ? (
            <div class="jumbotron jumbotron-fluid">
              <div class="container">
                <h1 class="display-4">Centre</h1>
                <p class="lead">évenement imprévu</p>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-lg-12">
                <Image
                                width={150}
                                src={this.state.centre.photo !== "" ||  this.state.centre.photo == null ? this.state.centre.photo : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"}
                                className="img-fluid"                             
                                alt=""
                                  />
                </div>
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                  Nom :
                </p>
                <p className="col-sm-9">
                  {this.state.centre.libelle === null
                    ? "-----"
                    : this.state.centre.libelle}
                </p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                  Gestionnaire :
                </p>
                <p className="col-sm-9">
                  {/* {this.state.centre.gestionnaire.prenom+" "+this.state.centre.gestionnaire.nom} */}
                </p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                  Fictif :
                </p>
                <p className="col-sm-9">
                  {this.state.centre.fictif === null
                    ? "-----"
                    : this.state.centre.fictif === 0
                    ? "Non"
                    : "Oui"}
                </p>
              </div>

              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                  Telephone 1 :
                </p>
                <p className="col-sm-9">
                  {this.state.centre.tel1 === null
                    ? "-----"
                    : this.state.centre.tel1}
                </p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                  Telephone 2 :
                </p>
                <p className="col-sm-9">
                  {this.state.centre.tel2 === null
                    ? "-----"
                    : this.state.centre.tel2}
                </p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">
                  Adresse :
                </p>
                <div className="col-sm-9">
                  <div className="row">
                    <p className="col-1">-</p>
                    <p className="col-11">
                      {this.state.centre.adresse === null
                        ? "-----"
                        : this.state.centre.adresse}
                    </p>
                    <p className="col-1">-</p>
                    <p className="col-11">
                      {this.state.centre.complementadresse === null
                        ? "-----"
                        : this.state.centre.complementadresse}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal>

        <Modal
          title={
            this.state.isUpdate
              ? "Modification du patient"
              : "Ajouter un patient"
          }
          visible={this.state.isModalVisible}
          onOk={this.state.isLoading ? null : this.handleOk}
          onCancel={this.state.isLoading ? null : this.handleCancel}
          footer={null}
          destroyOnClose={true}
          className={this.state.isLoading ? "page_wrapper_blur" : ""}
        >
          <form
            form
            action="/"
            onSubmit={this.state.isLoading ? null : this.handleSubmit}
            id="addform"
          >
            <div className="row form-row">
            <div className="col-12 col-md-12">
                           {this.state.isUpdate ? 
                            <div className="form-group">
                            <div className="change-avatar">
                              <div className="profile-img">
                              <Image
                              width={150}
                              src={
                                this.state.photo !== "" ? this.state.photo : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                                // this.state.imageProfile === null ||
                                // this.state.imageProfile === "" ||
                                // this.state.imageProfile === undefined
                                //   ? IMG01
                                //   : this.state.imageProfile
                              }
                              className="img-fluid"                             
                              alt=""
                                />
                              </div>
                              <div className="upload-img">
                                <div className="change-photo-btn">
                                  <span>
                                    <i className="fa fa-upload"></i> Importer
                                    une photo
                                  </span>
                                  <input
                                    type="file"
                                    className="upload"
                                    name="userImage"
                                     onChange={this.changeProfileImage}
                                  />
                                </div>
                                <small className="form-text text-muted">
                                  {" "}
                                  <strong
                                    className={
                                      this.state.color == true ? "etoile" : ""
                                    }
                                  >
                                    Format autorisé JPG, JPEG ou PNG. Taille
                                    max de 2MB
                                  </strong>{" "}
                                </small>
                              </div>
                            </div>
                          </div> :  ""
                         


                           }
                          </div>

              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>Gestionnaire du centre</label>
                  <br />
                  <Select
                    showSearch
                    // style={{ width: 300 }}
                    placeholder="Rechercher et selectionner"
                    optionFilterProp="search"
                    onChange={this.handleChangeGestionnaire}
                    value={
                      this.state.isUpdate
                        ? this.state.idgestionnairecentre
                        : undefined
                    }
                    disabled={this.state.isLoading}
                    filterOption={(input, option) =>
                      option.search
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.search
                        .toLowerCase()
                        .localeCompare(optionB.search.toLowerCase())
                    }
                  >
                    {this.state.gestionnaires.map((gestionnaire) => {
                      return (
                        <Option
                          value={gestionnaire.idgestionnairecentre}
                          search={
                            gestionnaire.email +
                            "" +
                            gestionnaire.prenom +
                            "" +
                            gestionnaire.nom
                          }
                        >
                          <div>
                            {gestionnaire.prenom + " " + gestionnaire.nom}
                          </div>
                          <br />
                          <i className="pull-right fas">{gestionnaire.email}</i>
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>Fictif</label>
                  <select
                    className="form-control"
                    type="text"
                    placeholder=""
                    name="fictif"
                    value={this.state.fictif}
                    disabled={this.state.isLoading}
                    onChange={(e) => this.handleChange(e, "fictif")}
                    required
                  >
                    <option value="0">Non</option>
                    <option value="1">Oui</option>
                  </select>
                </div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <label>Nom du centre</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Nom du centre"
                    name="libelle"
                    value={this.state.libelle}
                    disabled={this.state.isLoading}
                    onChange={(e) => this.handleChange(e, "libelle")}
                    required
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <label>Adresse</label>
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder="Adresse"
                    name="adresse"
                    value={this.state.adresse}
                    disabled={this.state.isLoading}
                    onChange={(e) => this.handleChange(e, "adresse")}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <Loader
                  style={{
                    position: "absolute",
                    textAlign: "center",
                    marginLeft: "auto",
                    left: "0",
                    right: "0",
                    marginRight: "auto",
                    zIndex: "200px",
                  }}
                  type="Puff"
                  color="#00BFFF"
                  height={100}
                  width={100}
                  visible={this.state.isLoading}
                />
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>Complément adresse</label>
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder="Complément adresse"
                    name="adresse"
                    value={this.state.complementadresse}
                    disabled={this.state.isLoading}
                    onChange={(e) => this.handleChange(e, "complementadresse")}
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>Telephone 1</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Numero de telephone 1"
                    name="tel1"
                    value={this.state.tel1}
                    disabled={this.state.isLoading}
                    onChange={(e) => this.handleChange(e, "tel1")}
                    required
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <label>Telephone 2 (optionnel)</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Numero de telephone 2"
                    name="tel2"
                    value={this.state.tel2}
                    disabled={this.state.isLoading}
                    onChange={(e) => this.handleChange(e, "tel2")}
                  />
                </div>
              </div>
            </div>
            <button
              className="btn btn-primary btn-block"
              type="submit"
              disabled={this.state.isLoading}
            >
              {this.state.isUpdate ? "Modifier" : "Ajouter"}
            </button>
          </form>
        </Modal>

        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Liste des centre</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/admin">Tableau de bord</Link>
                    </li>
                    <li className="breadcrumb-item active">centre</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Button
                  className="btn btn-primary  mb-2"
                  type="primary"
                  size="large"
                  onClick={this.state.isLoading ? null : this.handleOnclick}
                >
                  AJOUTER
                </Button>
                <div
                  className={
                    this.state.isLoading &&
                    !this.state.isDelete &&
                    !this.state.isUpdate
                      ? "page_wrapper_blur card"
                      : "card"
                  }
                >
                  <div className="card-body">
                    <div className="table">
                      <Loader
                        style={{
                          position: "absolute",
                          textAlign: "center",
                          marginLeft: "auto",
                          left: "0",
                          right: "0",
                          marginRight: "auto",
                          zIndex: "2000",
                        }}
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                        visible={
                          this.state.isLoading &&
                          !this.state.isDelete &&
                          !this.state.isUpdate
                        }
                      />
                      <MDBDataTableV5
                        entriesOptions={[10, 20, 30]}
                        entries={10}
                        responsive={true}
                        entriesLabel={"Lignes par pages"}
                        searchLabel={"Rechercher"}
                        infoLabel={["", "-", "/", ""]}
                        noRecordsFoundLabel={"Aucune données de correspond"}
                        data={{
                          columns: this.state.columns,
                          rows: this.state.rows,
                        }}
                        searchTop
                        searchBottom={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Centre;
