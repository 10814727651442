     //------ Lien de la TELECONSULTATION  en LOCAL -------

// const hostMeet = "https://192.168.1.89:3000";
// const hostMeet = "https:/localhost:3001/meeting/";

    //------ Lien de la TELECONSULTATION  en TEST -------

const hostMeet = "https://kwaber-teleconsultation.herokuapp.com/meeting/";


     //------ Lien de la TELECONSULTATION  en PROD -------

// const hostMeet = "http://visio.sante.kwaber.com/meeting/";
// const hostMeet = "https://192.168.1.79:3001/meeting/";
// const hostMeet = "https://live-sante-f5pta.ondigitalocean.app/meeting/";


export default hostMeet;