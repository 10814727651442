import React, { Component } from "react";
import { IMG01, IMG02, IMG03, IMG04, IMG05 } from "./img.jsx";
//slider
import Slider from "react-slick";

class HomeClinic extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      centerPadding: "10px",
      arrows: false,
      centerMode: true,
      responsive: [
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
      ],
    };
    return (
      <>
        <section className="section section-specialities">
          <div className="container-fluid">
            <div className="section-header text-center">
              <h2>Cliniques et spécialités</h2>
              <p className="sub-title">
                Consultation médicale directement sur votre ordinateur, votre
                tablette ou votre téléphone intelligent.
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-9 col-sm-12">
                <div className="specialities-slider slider">
                  <Slider {...settings}>
                    <div>
                      <div className="speicality-item text-center">
                        <div className="speicality-img">
                          <img
                            src={IMG01}
                            className="img-fluid"
                            alt="Speciality"
                          />
                          <span>
                            <i className="fa fa-circle" aria-hidden="true"></i>
                          </span>
                        </div>
                        <p>Urologie</p>
                      </div>
                    </div>
                    <div>
                      <div className="speicality-item text-center">
                        <div className="speicality-img">
                          <img
                            src={IMG02}
                            className="img-fluid"
                            alt="Speciality"
                          />
                          <span>
                            <i className="fa fa-circle" aria-hidden="true"></i>
                          </span>
                        </div>
                        <p>Neurologie</p>
                      </div>
                    </div>
                    <div>
                      <div className="speicality-item text-center">
                        <div className="speicality-img">
                          <img
                            src={IMG03}
                            className="img-fluid"
                            alt="Speciality"
                          />
                          <span>
                            <i className="fa fa-circle" aria-hidden="true"></i>
                          </span>
                        </div>
                        <p>Orthopedie</p>
                      </div>
                    </div>
                    <div>
                      <div className="speicality-item text-center">
                        <div className="speicality-img">
                          <img
                            src={IMG04}
                            className="img-fluid"
                            alt="Speciality"
                          />
                          <span>
                            <i className="fa fa-circle" aria-hidden="true"></i>
                          </span>
                        </div>
                        <p>Cardiologie</p>
                      </div>
                    </div>
                    <div>
                      <div className="speicality-item text-center">
                        <div className="speicality-img">
                          <img
                            src={IMG05}
                            className="img-fluid"
                            alt="Speciality"
                          />
                          <span>
                            <i className="fa fa-circle" aria-hidden="true"></i>
                          </span>
                        </div>
                        <p>Dentiste</p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default HomeClinic;
