import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import IMG01 from '../../../assets/images/patient2.jpg';
import PaiementService from "../../../../stripe/PaiementService";
// import axios from 'axios';
import host from '../../../../host';
import { Spin } from "antd";


class Checkout extends Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
			// professionnel: { centres: [], },
			professionnel: [],
			creneau: [],
			patient: "",
			urlPhoto: "",
			//professionnel : [],
			isLoading: false,
			jourAJD: "",
			date: "",
			heureDebut: "",
			heureFin: "",
			prix: "",
			prixXAF: "",
			idPatient: 1,
			rdvCreer: "",
			creneauCreer: "",
			prestation: "",
			idPro: "",
        	idCentre: "",
			// centre: JSON.parse(window.sessionStorage.getItem('rdv')).centre
		}
	}

	async componentDidMount() {
		const info = JSON.parse(window.sessionStorage.getItem('rdv'));
		let idPro = info.idPro ? info.idPro : null;
		let idCentre = info.idCentre ? info.idCentre : null;

		let condition = info.idPro ? `/ps/${info.idPro}` : `/centres/${info.idCentre}`;

		var requestOptions = {
			method: 'GET',
			redirect: 'follow'
		  };
		  
		//   fetch(host+`/ps/${info.idPro}`, requestOptions)
		//   fetch(host+`/ps/${info.idPro}`, requestOptions)
		fetch(host + condition )
			.then(response => {
				if(response.status === 200){
					return	response.json()
				}
			})
			.then(result => {
				// console.log(result)
				console.log("infoRDVLombre: ",result)

				this.setState({
					professionnel: result,
					// urlPhoto: result.user.photo,
					urlPhoto: result.user ? result.user.photo : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" ,
					isLoading: false,
					creneau: info.idCRDI[0],
					prestation: info.prestation.libelle,
					prix: info.prix,
					prixXAF: info.prixXAF,
					idPro: idPro,
        			idCentre: idCentre,
				});

			})
			.catch(error => console.log('error', error));
	}

	ecrireDate(date) {
		//2021-03-30
		var listeJours = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
		var listeMois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

		var str = new Date(date);
		//var tab = str.split("-");

		var jour = listeJours[str.getDay()]
		var mois = listeMois[str.getMonth()]

		return jour + " " + str.getDate() + " " + mois + " " + str.getFullYear()

	}

	heureCreneau = (heure) => {
		var str = heure;
		var tab = str.split(":");
		return tab[0] + "h" + tab[1]
	}


	handleChange = () => {
		this.props.history.push("/patient/booking-success");
	}
	render() {
		return (
			<div>				
				<div className="content">
					<div className="container">
						<div className="row">
							<div className="col-md-7 col-lg-8">
								<div className="card">
									<div className="card-body">
										<PaiementService/>														
									</div>
								</div>
							</div>
								{
									 this.state.professionnel.length === 0 ? 
									 <div className="spin col-md-5 col-lg-4 theiaStickySidebar">
									   <Spin />
									 </div> :
									 <div className="col-md-5 col-lg-4 theiaStickySidebar">
									 <div className="card booking-card">
									 <div className="card-header">
										 <h4 className="card-title">Résumé du rendez-vous </h4>
									 </div>
									 <div className="card-body">
										 <div className="booking-doc-info">
									 
										 {/* <Link to={`/patient/doctor-profile/${this.state.professionnel.idprofessionnelsante} `} className="booking-doc-img"> */}
										 <Link to={this.state.professionnel.idcentre ? `/centreProfil/${this.state.professionnel.idcentre} ` : `/patient/doctor-profile/${this.state.professionnel.idprofessionnelsante}`} className="booking-doc-img">
												 {/* <img src={this.state.urlPhoto ?  this.state.urlPhoto : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" } alt="User" /> */}
												 {/* <img src={this.state.urlPhoto ?  host + `/user/images/${this.state.urlPhoto}` : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" } alt="User" /> */}
												 {
												 this.state.professionnel.idcentre ? 
												 <img  src="https://cdn0.iconfinder.com/data/icons/medical-and-clinical-healthcare/78/5-512.png"/>
												 :
												 <img
												 src={
													 this.state.urlPhoto
													 ? this.state.urlPhoto
													 : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
												 }
												 alt="User"
												 />
												  }
											 
											 </Link>
											 <div className="booking-info">
												 {/* <h4><Link to={`/patient/doctor-profile/${this.state.professionnel.idprofessionnelsante} `}>{this.state.professionnel.nom} {this.state.professionnel.prenom}</Link></h4> */}
												 <h4>
													 <Link
													   to={this.state.professionnel.idcentre ? `/centreProfil/${this.state.professionnel.idcentre} ` : `/patient/doctor-profile/${this.state.professionnel.idprofessionnelsante}`}>
														   {this.state.professionnel.libelle ? this.state.professionnel.libelle : this.state.professionnel.nom+" "+this.state.professionnel.prenom }
													  </Link></h4>
												 {/* <h4 className="doc-department">{this.state.professionnel.specialite === null || this.state.professionnel.specialite === undefined ? "aucune spécialité" : this.state.professionnel.specialite.libelle}</h4> */}
												 <h4 className="doc-department">
															 {this.state.professionnel.specialite == null ||
															 this.state.professionnel.specialite == undefined
															 ? this.state.professionnel.adresse
															 : this.state.professionnel.specialite.libelle}
													 </h4>
 
												 {/* <div className="rating">
													 <i className="fas fa-star filled"></i>
													 <i className="fas fa-star filled"></i>
													 <i className="fas fa-star filled"></i>
													 <i className="fas fa-star filled"></i>
													 <i className="fas fa-star"></i>
													 <span className="d-inline-block average-rating">35</span>
												 </div> */}
												 {/* <div className="clinic-details">
													 {
															 this.state.professionnel.centres.map((centre) => {
																 return <div key={"centre" + centre.id}>
																	 <div>
																		 <p className="doc-location">
																			 <i className="fas fa-map-marker-alt"></i>{"  " + centre.libelle}
																		 </p>
																	 </div></div>
															 })
														 }
													 {(this.state.centre == 0) ?
														 <div>
															 <p className="doc-location">
																 <i className="fas fa-map-marker-alt"></i>{"  " + "Tous les centres"}
															 </p>
														 </div> :
														 <div>
															 <p className="doc-location">
																 <i className="fas fa-map-marker-alt"></i>{"  " + this.state.centre.libelle}
															 </p>
														 </div>
													 }
												 </div> */}
											 </div>
										 </div> <br /><br /><br />
										 <div className="booking-summary">
											 <div className="booking-item-wrap">
												 <ul className="booking-date">
													 <li>Date <span>{this.ecrireDate(this.state.creneau.datecreneau)}</span></li>
														 
													  {/* <h5> <strong>Date</strong></h5> 
													 {
														 this.state.creneau.map((creneaux) => {
																	 return(
																		 <li>{this.ecrireDate(creneaux.datecreneau)}, Heure: <strong>{creneaux.heuredebut} - {creneaux.heurefin}</strong></li>
																	 )
																 })
															 } */}
																																							 
												 </ul> 
												 <ul className="booking-fee">
													 {/* <h5> <strong></strong></h5>   */}
													 <li>Prestation  <span>{this.state.prestation}</span> </li>																			
													 <li>Montant<span>{ this.state.prix +" € "}/{this.state.prixXAF +" XAF "}</span></li>
												 </ul>
												 <div className="booking-total">
													 <ul className="booking-total-list">
														 <li>
															 <span>Total</span>
															 <span className="total-cost">{this.state.prix+" € "}/{this.state.prixXAF +" XAF "}</span>
														 </li>
													 </ul>
												 </div>
											 </div>
										 </div>
									 </div>
								 </div>
							 </div>
								}
							
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Checkout;