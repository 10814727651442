// import React from "react";
// import { Link, NavLink } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// //icon-
// import { faHospital } from "@fortawesome/free-regular-svg-icons";
// import logo from "../assets/images/kwaber2.png";
// import IMG01 from "../assets/images/doctor-thumb-02.jpg";
// import Dropdown from "react-bootstrap/Dropdown";
// import { useEffect, useState } from "react"
// import { useHistory } from "react-router";
// import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
// import { Navbar, Nav, Form, NavDropdown, Button } from 'react-bootstrap';
// import Image from 'react-bootstrap/Image';


// const Header = (props) => {
//   //const url = window.location.pathname.split("/").slice(0, -1).join("/");
//   const url = window.location.pathname
//   const history = useHistory();



//   function logout(e) {
//     e.preventDefault()
//     sessionStorage.removeItem("user")
//     sessionStorage.removeItem("user")
//     window.location.assign('/login')
//   }
//   const [user, setUser] = useState(null)

//   useEffect(() => {
//     if (user == null) {
//       let dbUser = JSON.parse(sessionStorage.getItem("user")) || JSON.parse(sessionStorage.getItem("user"));
//       setUser(dbUser);
//     } else {
//     }
//   }, [user])
//   return (

//     <>
//       <Navbar bg="white" expand="lg" className="header-nav">
//         <Navbar.Brand href="#home">
//           <Nav.Link href="">         
//             <NavLink to={user != null && user.user.type_user.id == 2 ? "/doctor/doctor-dashboard" : "/home"} activeClassName="active">
//                   <img src={logo} className="img-fluid" alt="Logo" style={{ height: "100px", width: "180px", display: "" }} />
//               </NavLink>        
//           </Nav.Link>
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="mr-auto ml-5">
//             <Nav.Link href="">
            
//               <NavLink to="/home" activeClassName="active">
//               {  user != null && user.user.type_user.id == 2 ? "" : "Accueil"}
//               </NavLink>
//               {/* { user != null &&
//                 <NavLink to={user.user.type_user.id == 2 ? "/doctor/overview" : "/home"} activeClassName="active">
//                 {user.user.type_user.id == 2 ? "Vue d'ensemble" : ""}
//               </NavLink> 
//               } */}
    

//             </Nav.Link>
//             <Nav.Link>
//               <NavLink to="/patient/search-doctor" activeClassName="active">
               
//                 {  user != null && user.user.type_user.id == 2 ? "" : " Trouver un médécin"}
//               </NavLink>
//             </Nav.Link>
//             <Nav.Link>
//               <NavLink to="/qui-sommes-nous" activeClassName="active">
//                 Qui sommes-nous ?
//               </NavLink>
//             </Nav.Link>
//             <Nav.Link>
//               <NavLink to="/contact" activeClassName="active">
//                 Contact
//               </NavLink>
//             </Nav.Link>
//             {/* <Nav.Link>
//               {user == null && <li className={`${url === "/register" ? "active" : ""}`}>
//                 <NavLink to="/register" activeClassName="active">
//                   S'incrire
//                 </NavLink>
//               </li>}
//             </Nav.Link> */}
//             {/* <Nav.Link href="">
//               <li className="nav-item contact-item">
//               <div className="header-contact-img">
             
//                <i class="fas fa-1x fa-phone-alt"></i>
//              </div>
//              <div className="header-contact-detail">
//                <p className="contact-header">Service client</p>
//                <p className="contact-info-header"> +242053769626 / +3361924279 </p>
//              </div>
//            </li>
//               </Nav.Link> */}

//           </Nav>
//           <Form inline className="mr-0">
//             <div className="nav-item contact-item mr-5">
//               <div className="header-contact-img hidden-small">
//                 <i class="fas fa-1x fa-phone-alt "></i>
//               </div>
//               <div className="header-contact-detail">
//                 <p className="contact-header">Service client</p>
//                 <p className="contact-info-header"> +242053769626 / +3361924279 </p>
//               </div>
              
//             </div>
           
//             {user == null &&
//               <NavLink className="nav-link btn btn-md mr-3 mb-3 btn-bluefonce" to="/register" activeClassName="">
//                   S'inscrire
//                 </NavLink>
//               }

//             {user == null && <p className={`${url === "/login" ? "active" : ""}`}>
//               <NavLink to="/login" className="nav-link btn btn-md btn-outline-info-lb" activeClassName="">
//                 Connexion
//               </NavLink>
//             </p>}
//             {user != null &&
//               <>
//                 <Dropdown className="mr-5">
//                   <Dropdown.Toggle variant="" id="basic-nav-dropdown" >

//                     <img
//                       className="rounded-circle"
//                       src={IMG01}
//                       width="45px"
//                       alt="Kwaber santé"
//                     />
//                   </Dropdown.Toggle>
//                   <Dropdown.Menu>
//                     <div className="user-header">
//                       <div className="user-text">
//                         <h6>{user != null && user.user.prenom + " " + user.user.nom}</h6>
//                         <p className=" mb-0">{user.user.type_user.id == 2 ? "Médecin" : "Patient"}</p>
//                       </div>
//                     </div>
//                     <Dropdown.Divider />
//                   <Dropdown.Item href={user.user.type_user.id == 2 ? "/doctor/doctor-dashboard" : "/patient/dashboard"}>
//                       Tableau de bord
//                     </Dropdown.Item>
//                     <Dropdown.Divider />
//                     <Dropdown.Item href="http://localhost:3001">
//                       Démarrer une scéance vidéo
//                     </Dropdown.Item>
//                     <Dropdown.Divider />
//                     <Dropdown.Item onClick={logout} href="0#">Déconnexion</Dropdown.Item>
//                   </Dropdown.Menu>
//                 </Dropdown>
//               </>
//             }
//           </Form>
//         </Navbar.Collapse>
//       </Navbar>
//       {/* <Navbar collapseOnSelect expand="lg" bg="white"  className="header-nav">
//             <Navbar.Brand href="/home">
//             <a href="/home" class="navbar-brand logo">
//                <Link to="/home" className="navbar-brand logo">
//                  <img src={logo} className="img-fluid" alt="Logo" style={{ height: "100px", width: "100px", display: "" }} />
//                </Link>
//            </a> 

//             </Navbar.Brand>
//             <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//             <Navbar.Collapse id="responsive-navbar-nav">
//               <Nav className="mr-auto">
//               <div className="main-menu-wrapper">
//            <div className="menu-header">
//              <Link to="/home" className="menu-logo">
//                <img src={logo} className="img-fluid" alt="Logo" />
//              </Link>
//              <a href="#" id="menu_close" className="menu-close">
//                <i className="fas fa-times"></i>
//              </a>
//            </div>
//            <ul className="main-nav">
//              <li className={`${url === "/home" ? "active" : ""}`}>
//                <NavLink to="/home" activeClassName="active">
//                  Accueil
//                </NavLink>
//              </li>
//              <li className={`${url === "/patient/search-doctor" ? "active" : ""}`}>
//                <NavLink to="/patient/search-doctor" activeClassName="active">
//                  Trouver un médécin
//                </NavLink>
//              </li>
//              <li className={`${url === "/qui-sommes-nous" ? "active" : ""}`}>
//                <NavLink to="/qui-sommes-nous" activeClassName="active">
//                  Qui sommes-nous ?
//                </NavLink>
//              </li>
//              <li className={`${url === "/contact" ? "active" : ""}`}>
//                <NavLink to="/contact" activeClassName="active">
//                  Contact
//                </NavLink>
//              </li>
//              {user == null && <li className={`${url === "/register" ? "active" : ""}`}>
//                <NavLink to="/register" activeClassName="active">
//                  Inscription
//                </NavLink>
//              </li>}
//            </ul>
//          </div>  
//               </Nav>
//               <Nav className="mr-auto">
//              <ul className="nav header-navbar-rht">
//               <li className="nav-item contact-item">
//                 <div className="header-contact-img">
//                <i class="fas fa-1x fa-phone-alt"></i>
//              </div>
//              <div className="header-contact-detail">
//                <p className="contact-header">Service client</p>
//                <p className="contact-info-header"> +242053769626 / +3361924279 </p>
//              </div>
//            </li>


//            {user == null && <li className={`${url === "/login" ? "active" : ""}`}>
//            <Form className="d-flex">
//            <NavLink to="/login" className="nav-link btn btn-outline-info-lb" activeClassName="">
//                Connexion
//              </NavLink>
//              </Form>
             
//            </li>}

//            {user != null &&
//             <>
//               <Dropdown className="user-drop nav-item dropdown has-arrow logged-item ">
//                 <Dropdown.Toggle variant="" id="dropdown-basic" >
//                   <img 
//                     className="rounded-circle"
//                     src={IMG01}
//                     width="50px"
//                     alt="Darren Elder"
//                   />
//                 </Dropdown.Toggle>

//                 <Dropdown.Menu>
//                   <div className="user-header">
//                     <div className="user-text">
//                       <h6>{user != null && user.user.prenom + " " + user.user.nom}</h6>
//                       <p className=" mb-0">{user.user.type_user.id == 2 ? "Médecin" : "Patient"}</p>
//                     </div>
//                   </div>
//                   <Dropdown.Item href={user.user.type_user.id == 2 ? "/doctor/appointments" : "/patient/dashboard"}>
//                     Tableau de bord
//                   </Dropdown.Item>
//                   <Dropdown.Item onClick={logout} href="0#">Déconnexion</Dropdown.Item>
//                 </Dropdown.Menu>
//               </Dropdown>
//             </>
//           }
//         </ul>
//               </Nav>
//             </Navbar.Collapse>
//           </Navbar> */}
//     </>


//     // <header className="header">   
//     //    <nav class="navbar navbar-expand-lg header-nav">
//     //     <div className="navbar-header">
//     //       <a href="#" id="mobile_btn">
//     //         <span className="bar-icon">
//     //           <span></span>
//     //           <span></span>
//     //           <span></span>
//     //         </span>
//     //       </a>
//     //       <a href="/home" class="navbar-brand logo">
//     //           <Link to="/home" className="navbar-brand logo">
//     //             <img src={logo} className="img-fluid" alt="Logo" style={{ height: "100px", width: "100px", display: "" }} />
//     //           </Link>
//     //       </a>         
//     //     </div>

//     //     <div className="main-menu-wrapper">
//     //       <div className="menu-header">
//     //         <Link to="/home" className="menu-logo">
//     //           <img src={logo} className="img-fluid" alt="Logo" />
//     //         </Link>
//     //         <a href="#" id="menu_close" className="menu-close">
//     //           <i className="fas fa-times"></i>
//     //         </a>
//     //       </div>
//     //       <ul className="main-nav">
//     //         <li className={`${url === "/home" ? "active" : ""}`}>
//     //           <NavLink to="/home" activeClassName="active">
//     //             Accueil
//     //           </NavLink>
//     //         </li>
//     //         <li className={`${url === "/patient/search-doctor" ? "active" : ""}`}>
//     //           <NavLink to="/patient/search-doctor" activeClassName="active">
//     //             Trouver un médécin
//     //           </NavLink>
//     //         </li>
//     //         <li className={`${url === "/qui-sommes-nous" ? "active" : ""}`}>
//     //           <NavLink to="/qui-sommes-nous" activeClassName="active">
//     //             Qui sommes-nous ?
//     //           </NavLink>
//     //         </li>
//     //         <li className={`${url === "/contact" ? "active" : ""}`}>
//     //           <NavLink to="/contact" activeClassName="active">
//     //             Contact
//     //           </NavLink>
//     //         </li>
//     //         {user == null && <li className={`${url === "/register" ? "active" : ""}`}>
//     //           <NavLink to="/register" activeClassName="active">
//     //             Inscription
//     //           </NavLink>
//     //         </li>}
//     //       </ul>
//     //     </div>

//     //     <ul className="nav header-navbar-rht">
//     //       <li className="nav-item contact-item">
//     //         <div className="header-contact-img">

//     //           <i class="fas fa-1x fa-phone-alt"></i>
//     //         </div>
//     //         <div className="header-contact-detail">
//     //           <p className="contact-header">Service client</p>
//     //           <p className="contact-info-header"> +242053769626 / +3361924279 </p>
//     //         </div>
//     //       </li>


//     //       {user == null && <li className={`${url === "/login" ? "active" : ""}`}>
//     //         <NavLink to="/login" className="nav-link btn btn-outline-info-lb" activeClassName="">
//     //           Connexion
//     //         </NavLink>
//     //       </li>}

//     //       {user != null &&
//     //         <>
//     //           <Dropdown className="user-drop nav-item dropdown has-arrow logged-item">
//     //             <Dropdown.Toggle variant="" id="dropdown-basic" >
//     //               <img 
//     //                 className="rounded-circle"
//     //                 src={IMG01}
//     //                 width="50px"
//     //                 alt="Darren Elder"
//     //               />
//     //             </Dropdown.Toggle>

//     //             <Dropdown.Menu>
//     //               <div className="user-header">
//     //                 <div className="user-text">
//     //                   <h6>{user != null && user.user.prenom + " " + user.user.nom}</h6>
//     //                   <p className=" mb-0">{user.user.type_user.id == 2 ? "Médecin" : "Patient"}</p>
//     //                 </div>
//     //               </div>
//     //               <Dropdown.Item href={user.user.type_user.id == 2 ? "/doctor/appointments" : "/patient/dashboard"}>
//     //                 Tableau de bord
//     //               </Dropdown.Item>
//     //               <Dropdown.Item onClick={logout} href="0#">Déconnexion</Dropdown.Item>
//     //             </Dropdown.Menu>
//     //           </Dropdown>
//     //         </>
//     //       }
//     //     </ul>
//     //   </nav> 
//     // </header>

//     // <header>
//     //   <nav className="navbar navbar-expand-lg header-nav"> 
//     //       <input type="checkbox"  id="res-menu"/>
//     //       <label htmlFor="res-menu">
//     //         <i className="fa fa-bars" id="sign-one"></i>
//     //         <i  className="fa fa-times" id="sign-two"></i>            
//     //       </label>
//     //       <h1>Heritage</h1>

//     //         <ul>

//     //               <li className={`${url === "/home" ? "active" : ""}`}>
//     //                     <a href="/home">
//     //                         <NavLink to="/home" activeClassName="active">
//     //                           Accueil
//     //                         </NavLink>
//     //                     </a>
//     //                 </li>

//     //                 <li className={`${url === "/patient/search-doctor" ? "active" : ""}`}>
//     //                       <a href="/patient/search-doctor">
//     //                         <NavLink to="/patient/search-doctor" activeClassName="active">
//     //                           Trouver un médécin
//     //                         </NavLink>
//     //                       </a>
//     //                 </li>
//     //               <li><a href="#">Portofolio</a></li>
//     //         </ul>
//     //     </nav>
//     // </header>










//   );
// };

// export default Header;
import React from "react";
import { Link, NavLink } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//icon-
// import { faHospital } from "@fortawesome/free-regular-svg-icons";
import logo from "../assets/images/kwaber2.png";
// import IMG01 from "../assets/images/doctor-thumb-02.jpg";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react"
import { useHistory } from "react-router";
// import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Nav, Form, NavDropdown, Button } from 'react-bootstrap';
// import Image from 'react-bootstrap/Image';
// import host from "../../host";


const Header = (props) => {
  //const url = window.location.pathname.split("/").slice(0, -1).join("/");
  const url = window.location.pathname
  // const history = useHistory();



  function logout(e) {
    e.preventDefault()
    sessionStorage.removeItem("user")
    sessionStorage.removeItem("user")
    window.location.assign('/login')
  }
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (user == null) {
      let dbUser = JSON.parse(sessionStorage.getItem("user")) || JSON.parse(sessionStorage.getItem("user"));
      setUser(dbUser);
    } else {
    }
  }, [user])
  return (

    <>
      <Navbar bg="white" expand="lg" className="header-nav">
        <Navbar.Brand>
          <Nav.Link>         
            <NavLink to={  user !== null && user.user.professionnelsante !== null ? "/doctor/doctor-dashboard" :  user !== null && user.user.patient ? "/home" : user !== null && user.user.gestionnairecentre ? "/gestionnaire/dashboard" : "/home"}
 activeClassName="active">
                  <img src={logo} className="img-fluid" alt="Logo" style={{ height: "100px", width: "180px", display: "" }} />
              </NavLink>        
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto ml-5">
            <Nav.Link href="">
            
              <NavLink to="/home" activeClassName="active">
              {  user != null && user.user.professionnelsante !== null ? "" :  user !== null && user.user.patient ? "Accueil" : user !== null && user.user.gestionnairecentre ? "" : "Accueil"}
              </NavLink>
              {/* { user != null &&
                <NavLink to={user.user.type_user.id == 2 ? "/doctor/overview" : "/home"} activeClassName="active">
                {user.user.type_user.id == 2 ? "Vue d'ensemble" : ""}
              </NavLink> 
              } */}

{/* data.user.patient.prenom */}

            </Nav.Link>
            <Nav.Link>
              <NavLink to="/patient/search-doctor" activeClassName="active">            
                {  user != null && (user.user.professionnelsante !== null || user.user.gestionnairecentre !== null) ? "" : " Trouver un médécin"}
              </NavLink>         
            </Nav.Link>

              {/* <Nav.Link>
              <NavLink to="/patient/searchCentre" activeClassName="active">            
                {  user != null && (user.user.professionnelsante !== null || user.user.gestionnairecentre !== null) ? "" : " Trouver un centre"}
              </NavLink>
              </Nav.Link> */}


            <Nav.Link>
              <NavLink to="/qui-sommes-nous" activeClassName="active">
                Qui sommes-nous ?
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/contact" activeClassName="active">
                Contact
              </NavLink>
            </Nav.Link>
            {/* <Nav.Link>
              {user == null && <li className={`${url === "/register" ? "active" : ""}`}>
                <NavLink to="/register" activeClassName="active">
                  S'incrire
                </NavLink>
              </li>}
            </Nav.Link> */}
            {/* <Nav.Link href="">
              <li className="nav-item contact-item">
              <div className="header-contact-img">
             
               <i class="fas fa-1x fa-phone-alt"></i>
             </div>
             <div className="header-contact-detail">
               <p className="contact-header">Service client</p>
               <p className="contact-info-header"> +242053769626 / +3361924279 </p>
             </div>
           </li>
              </Nav.Link> */}

          </Nav>
          <Form inline className="mr-0">
            <div className="nav-item contact-item mr-5">
              <div className="header-contact-img hidden-small">
                <i class="fas fa-1x fa-phone-alt "></i>
              </div>
              <div className="header-contact-detail">
                <p className="contact-header">Service client</p>
                <p className="contact-info-header"> +242053769626 / +3361924279 </p>
              </div>
              
            </div>
           
            {user == null &&
              <NavLink className="nav-link btn btn-md mr-3 mb-3 btn-bluefonce" to="/register" activeClassName="">
                  S'inscrire
                </NavLink>
              }

            {user == null && <p className={`${url === "/login" ? "active" : ""}`}>
              <NavLink to="/login" className="nav-link btn btn-md btn-outline-info-lb" activeClassName="">
                Connexion
              </NavLink>
            </p>}
            {user != null &&
              <>
                <Dropdown className="mr-5">
                  <Dropdown.Toggle variant="" id="basic-nav-dropdown" >

                    <img
                      className="head-circle-img"
                      // className="rounded-circle"
                      src= {(user.user.photo !== "" && user.user.photo !== null &&  user.user.photo !== undefined) ? user.user.photo  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" }
                      // src= {(user.user.photo !== "" && user.user.photo !== null &&  user.user.photo !== undefined) ? host+`/user/images/${user.user.photo} `  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png" }
                       width="50px"
                       height="50px"
                      alt="Kwaber santé"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="user-header">
                      <div className="user-text">
{/* <<<<<<< HEAD
                        <h6>{user.user.patient !== null ? user.user.patient.prenom + " " + user.user.patient.nom : user.user.professionnelsante !== null ? user.user.professionnelsante.prenom + " " + user.user.professionnelsante.nom : ""}</h6>
                        <p className=" mb-0">{user.user.professionnelsante !== null ? "Médecin" : user.user.patient !== null ? "Patient" : "Patient"  }</p>
                      </div>
                    </div>
                    <Dropdown.Divider />
                  <Dropdown.Item href={user.user.professionnelsante !== null  ? "/doctor/doctor-dashboard" : "/patient/dashboard"}>
======= */}
                        <h6>{user.user.patient !== null ? user.user.patient.prenom + " " + user.user.patient.nom : user.user.professionnelsante !== null ? user.user.professionnelsante.prenom + " " + user.user.professionnelsante.nom : user.user.gestionnairecentre !== null ? user.user.gestionnairecentre.nom+" "+user.user.gestionnairecentre.prenom :""}</h6>
                        <p className=" mb-0">{user.user.professionnelsante !== null ? "Médecin" : user.user.patient !== null ? "Patient" : user.user.gestionnairecentre !== null ?"Gestionnaire":""  }</p>
                      </div>
                    </div>
                    <Dropdown.Divider />
                  <Dropdown.Item href={user.user.gestionnairecentre !== null ? "/gestionnaire/dashboard" : user.user.professionnelsante !== null  ? "/doctor/doctor-dashboard" : "/patient/dashboard"}>
{/* >>>>>>> 9b213e79c851667ead15a4593c680ed233a8b3eb */}
                      Tableau de bord
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {/* <Dropdown.Item href="http://localhost:3001">
                      Démarrer une scéance vidéo
                    </Dropdown.Item>
                    <Dropdown.Divider /> */}
                    <Dropdown.Item onClick={logout} href="0#">Déconnexion</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            }
          </Form>
        </Navbar.Collapse>
      </Navbar>
      {/* <Navbar collapseOnSelect expand="lg" bg="white"  className="header-nav">
            <Navbar.Brand href="/home">
            <a href="/home" class="navbar-brand logo">
               <Link to="/home" className="navbar-brand logo">
                 <img src={logo} className="img-fluid" alt="Logo" style={{ height: "100px", width: "100px", display: "" }} />
               </Link>
           </a> 

            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
              <div className="main-menu-wrapper">
           <div className="menu-header">
             <Link to="/home" className="menu-logo">
               <img src={logo} className="img-fluid" alt="Logo" />
             </Link>
             <a href="#" id="menu_close" className="menu-close">
               <i className="fas fa-times"></i>
             </a>
           </div>
           <ul className="main-nav">
             <li className={`${url === "/home" ? "active" : ""}`}>
               <NavLink to="/home" activeClassName="active">
                 Accueil
               </NavLink>
             </li>
             <li className={`${url === "/patient/search-doctor" ? "active" : ""}`}>
               <NavLink to="/patient/search-doctor" activeClassName="active">
                 Trouver un médécin
               </NavLink>
             </li>
             <li className={`${url === "/qui-sommes-nous" ? "active" : ""}`}>
               <NavLink to="/qui-sommes-nous" activeClassName="active">
                 Qui sommes-nous ?
               </NavLink>
             </li>
             <li className={`${url === "/contact" ? "active" : ""}`}>
               <NavLink to="/contact" activeClassName="active">
                 Contact
               </NavLink>
             </li>
             {user == null && <li className={`${url === "/register" ? "active" : ""}`}>
               <NavLink to="/register" activeClassName="active">
                 Inscription
               </NavLink>
             </li>}
           </ul>
         </div>  
              </Nav>
              <Nav className="mr-auto">
             <ul className="nav header-navbar-rht">
              <li className="nav-item contact-item">
                <div className="header-contact-img">
               <i class="fas fa-1x fa-phone-alt"></i>
             </div>
             <div className="header-contact-detail">
               <p className="contact-header">Service client</p>
               <p className="contact-info-header"> +242053769626 / +3361924279 </p>
             </div>
           </li>


           {user == null && <li className={`${url === "/login" ? "active" : ""}`}>
           <Form className="d-flex">
           <NavLink to="/login" className="nav-link btn btn-outline-info-lb" activeClassName="">
               Connexion
             </NavLink>
             </Form>
             
           </li>}

           {user != null &&
            <>
              <Dropdown className="user-drop nav-item dropdown has-arrow logged-item ">
                <Dropdown.Toggle variant="" id="dropdown-basic" >
                  <img 
                    className="rounded-circle"
                    src={IMG01}
                    width="50px"
                    alt="Darren Elder"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="user-header">
                    <div className="user-text">
                      <h6>{user != null && user.user.prenom + " " + user.user.nom}</h6>
                      <p className=" mb-0">{user.user.type_user.id == 2 ? "Médecin" : "Patient"}</p>
                    </div>
                  </div>
                  <Dropdown.Item href={user.user.type_user.id == 2 ? "/doctor/appointments" : "/patient/dashboard"}>
                    Tableau de bord
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logout} href="0#">Déconnexion</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          }
        </ul>
              </Nav>
            </Navbar.Collapse>
          </Navbar> */}
    </>


    // <header className="header">   
    //    <nav class="navbar navbar-expand-lg header-nav">
    //     <div className="navbar-header">
    //       <a href="#" id="mobile_btn">
    //         <span className="bar-icon">
    //           <span></span>
    //           <span></span>
    //           <span></span>
    //         </span>
    //       </a>
    //       <a href="/home" class="navbar-brand logo">
    //           <Link to="/home" className="navbar-brand logo">
    //             <img src={logo} className="img-fluid" alt="Logo" style={{ height: "100px", width: "100px", display: "" }} />
    //           </Link>
    //       </a>         
    //     </div>

    //     <div className="main-menu-wrapper">
    //       <div className="menu-header">
    //         <Link to="/home" className="menu-logo">
    //           <img src={logo} className="img-fluid" alt="Logo" />
    //         </Link>
    //         <a href="#" id="menu_close" className="menu-close">
    //           <i className="fas fa-times"></i>
    //         </a>
    //       </div>
    //       <ul className="main-nav">
    //         <li className={`${url === "/home" ? "active" : ""}`}>
    //           <NavLink to="/home" activeClassName="active">
    //             Accueil
    //           </NavLink>
    //         </li>
    //         <li className={`${url === "/patient/search-doctor" ? "active" : ""}`}>
    //           <NavLink to="/patient/search-doctor" activeClassName="active">
    //             Trouver un médécin
    //           </NavLink>
    //         </li>
    //         <li className={`${url === "/qui-sommes-nous" ? "active" : ""}`}>
    //           <NavLink to="/qui-sommes-nous" activeClassName="active">
    //             Qui sommes-nous ?
    //           </NavLink>
    //         </li>
    //         <li className={`${url === "/contact" ? "active" : ""}`}>
    //           <NavLink to="/contact" activeClassName="active">
    //             Contact
    //           </NavLink>
    //         </li>
    //         {user == null && <li className={`${url === "/register" ? "active" : ""}`}>
    //           <NavLink to="/register" activeClassName="active">
    //             Inscription
    //           </NavLink>
    //         </li>}
    //       </ul>
    //     </div>

    //     <ul className="nav header-navbar-rht">
    //       <li className="nav-item contact-item">
    //         <div className="header-contact-img">

    //           <i class="fas fa-1x fa-phone-alt"></i>
    //         </div>
    //         <div className="header-contact-detail">
    //           <p className="contact-header">Service client</p>
    //           <p className="contact-info-header"> +242053769626 / +3361924279 </p>
    //         </div>
    //       </li>


    //       {user == null && <li className={`${url === "/login" ? "active" : ""}`}>
    //         <NavLink to="/login" className="nav-link btn btn-outline-info-lb" activeClassName="">
    //           Connexion
    //         </NavLink>
    //       </li>}

    //       {user != null &&
    //         <>
    //           <Dropdown className="user-drop nav-item dropdown has-arrow logged-item">
    //             <Dropdown.Toggle variant="" id="dropdown-basic" >
    //               <img 
    //                 className="rounded-circle"
    //                 src={IMG01}
    //                 width="50px"
    //                 alt="Darren Elder"
    //               />
    //             </Dropdown.Toggle>

    //             <Dropdown.Menu>
    //               <div className="user-header">
    //                 <div className="user-text">
    //                   <h6>{user != null && user.user.prenom + " " + user.user.nom}</h6>
    //                   <p className=" mb-0">{user.user.type_user.id == 2 ? "Médecin" : "Patient"}</p>
    //                 </div>
    //               </div>
    //               <Dropdown.Item href={user.user.type_user.id == 2 ? "/doctor/appointments" : "/patient/dashboard"}>
    //                 Tableau de bord
    //               </Dropdown.Item>
    //               <Dropdown.Item onClick={logout} href="0#">Déconnexion</Dropdown.Item>
    //             </Dropdown.Menu>
    //           </Dropdown>
    //         </>
    //       }
    //     </ul>
    //   </nav> 
    // </header>

    // <header>
    //   <nav className="navbar navbar-expand-lg header-nav"> 
    //       <input type="checkbox"  id="res-menu"/>
    //       <label htmlFor="res-menu">
    //         <i className="fa fa-bars" id="sign-one"></i>
    //         <i  className="fa fa-times" id="sign-two"></i>            
    //       </label>
    //       <h1>Heritage</h1>

    //         <ul>

    //               <li className={`${url === "/home" ? "active" : ""}`}>
    //                     <a href="/home">
    //                         <NavLink to="/home" activeClassName="active">
    //                           Accueil
    //                         </NavLink>
    //                     </a>
    //                 </li>

    //                 <li className={`${url === "/patient/search-doctor" ? "active" : ""}`}>
    //                       <a href="/patient/search-doctor">
    //                         <NavLink to="/patient/search-doctor" activeClassName="active">
    //                           Trouver un médécin
    //                         </NavLink>
    //                       </a>
    //                 </li>
    //               <li><a href="#">Portofolio</a></li>
    //         </ul>
    //     </nav>
    // </header>










  );
};

export default Header;