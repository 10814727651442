import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardSidebar from '../sidebar/sidebar.jsx';
import StickyBox from "react-sticky-box";
import { MDBDataTableV5 } from 'mdbreact';
import host from "../../../../../host.js";
import moment from "moment";



class MyTransaction extends Component {
	constructor(props) {
		super(props);
		this.state = {
            
            // rows: [],
            datatable:{
                columns:[{
                  label: "Nom du professionnel",
                  field: "psCentre",
                  //   sort: 'asc',
                  width: 500,
                },
                // {
                //   label: "Patient",
                //   field: "patient",
                //   //   sort: 'asc',
                //   width: 270,
                // },
                {
                  label: "Prestation",
                  field: "prestation",
                  //   sort: 'asc',
                  width: 270,
                },
                {
                  label: "Prix",
                  field: "prix",
                  //   sort: 'asc',
                  width: 270,
                },
                {
                  label: "Mode de paiement",
                  field: "mode",
                  //   sort: 'asc',
                  width: 270,
                },
                {
                    label: "Date de paiement",
                    field: "date",
                    //   sort: 'asc',
                    width: 270,
                  },
                {
                  label: "idTransaction",
                  field: "idTransaction",
                  //   sort: 'asc',
                  width: 270,
                },
                {
                  label: "Statut",
                  field: "statut",
                  //   sort: 'asc',
                  width: 270,
                },
                // {
                //   label: "Numero",
                //   field: "numero",
                //   //   sort: 'asc',
                //   width: 270,
                // },
              ],
                rows:[]
            },
		
		}
	}
    
    componentDidMount() {
      let dbUser = JSON.parse(localStorage.getItem("user"))
      || JSON.parse(sessionStorage.getItem("user"));

      if (!dbUser) {
          window.location.assign('/')
      }

    if (dbUser === null || dbUser === undefined) {
      this.props.history.push('/login')
    } 

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
          };
          
          fetch(host+`/patient/${dbUser.user.patient.id}`, requestOptions)
            .then(response =>{
                if(response.status === 200){
                    return  response.json()
                }
            })
            .then(result => {
               console.log("result-patient: ",result.paiements)
                // this.setState({
                //     transaction: result.rdvs 
                // })

                    let rowElement = [];
                for (let index = 0; index < result.paiements.length; index++) {
                    const element = result.paiements[index];
                     console.log("ELEMENT:", element)
                     let condition = element.idcentre2 ? element.idcentre2.libelle :  element.idprofessionnelsante.prenom+" "+element.idprofessionnelsante.nom;
                    rowElement.push({
                        psCentre: condition,
                        // patient: element.,
                        prestation: element.prestation,
                        prix: element.prixPrestation,
                        mode: element.modePaiement,
                        idTransaction: element.transactionId,
                        date: moment(element.datepaiement).format("YYYY-MM-DD hh:mm:ss"),
                        
                        // numero:,
                        statut: <span  className="btn btn-sm bg-info-light">Payé</span>
                    })                                            

                    
                      }
                this.setState({
                    datatable: {
                        columns: this.state.datatable.columns,
                        rows: rowElement
                      }
                })

            })
            .catch(error => console.log('error', error));
    }
    


	render() {
		return (
			<div>
				<div className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
								<StickyBox offsetTop={20} offsetBottom={20}>
									<DashboardSidebar />
								</StickyBox>
							</div>						
							<div className="col-md-7 col-lg-8 col-xl-9">
              <MDBDataTableV5
                              entriesOptions={[5,10, 20, 30]}
                              entries={10}
                              responsive={true}
                              entriesLabel={"Lignes par pages"}
                              searchLabel={"Rechercher"}
                              infoLabel={["", "-", "/", ""]}
                              noRecordsFoundLabel={
                                "Aucune données de correspond"
                              }
                              pagesAmount={1}
                              // data={{
                              //   rows: this.state.prestations,
                              //   columns: this.state.columns,
                              // }}
                              data={this.state.datatable}
                              searchTop
                              searchBottom={false}
                            />
                            </div>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

export default MyTransaction;

