import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/KSL_White__Portrait.png";

const Footer = (props) => {
  const exclusionArray = [
    "/pages/doctor-grid",
    "/pages/doctor-list",
    "/pages/video-call",
    "/pages/voice-call",
    "/pages/chat-doctor",
    "/patient/doctor-list",
    "/patient/doctor-grid",
  ];
  if (exclusionArray.indexOf(props.location.pathname) >= 0) {
    return "";
  }
  return (
    <footer className="footer mt-auto py-3">
      <div className="container-fluid">
        <div className="social-icon">
          <img
            src={logo}
            style={{ height: "100px", width: "100px", display: "inline-flex" }}
            alt="Kwaber sante logo"
          />
          {/*<ul style={{ width: "50%", float: "right", justifyContent: "flex-end", marginTop: "3%" }}>
						<li>
							<a href="https://www.facebook.com/kwaberofficiel"><i className="fab fa-facebook-f"></i></a>
	</li>
					</ul>*/}
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container-fluid">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="copyright-text">
                  <p className="mb-0">
                    &copy; 2020 Kwaber. Tous droits réservés.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="copyright-menu">
                  <ul className="policy-menu">
                    <li>
                      <Link to="#">Termes et conditions</Link>
                    </li>
                    {/* <li><Link to="/terms">Termes et conditions</Link></li> */}
                    <li>
                      <Link to="#">Policy</Link>
                    </li>
                    {/* <li><Link to="/privacy-policy">Policy</Link></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
