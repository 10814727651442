import React, { Component } from 'react';
import DoctorSidebar from '../sidebar/index';
import IMG01 from '../../../assets/images/doctor-thumb-02.jpg';
import ReactTagsInput from './tags.jsx';
import Education from './education';
import Experience from './experience';
import Award from './award';
import Memberships from './membership';
import {DropzoneArea} from 'material-ui-dropzone'
import Registration from './registration';
//import Registrations from './registration';

// const KeyCodes = {
// 	comma: 188,
// 	enter: 13,
//   };
   
//const delimiters = [KeyCodes.comma, KeyCodes.enter];

class ProfileSetting extends Component {
	constructor(props) {
		super(props)
		this.state = {
			files: []
		  };
		}

		handleChange(files){
			this.setState({
			  files: files
			});
		  }
		
    render(){
	return(
    <div>
            <div className="breadcrumb-bar">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-12 col-12">
                        <nav aria-label="breadcrumb" className="page-breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/home">Accueil</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Paramètres du profil</li>
                            </ol>
                        </nav>
                        <h2 className="breadcrumb-title">Paramètres du profil</h2>
                    </div>
                </div>
            </div>
        </div>
        <div className="content">
				<div className="container-fluid">

					<div className="row">
						<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
						
						<DoctorSidebar />
							
						</div>
						<div className="col-md-7 col-lg-8 col-xl-9">
						
						
							<div className="card">
								<div className="card-body">
									<h4 className="card-title">Informations classique</h4>
									<div className="row form-row">
										<div className="col-md-12">
											<div className="form-group">
												<div className="change-avatar">
													<div className="profile-img">
														<img src={IMG01} alt="User"/>
													</div>
													<div className="upload-img">
														<div className="change-photo-btn">
															<span><i className="fa fa-upload"></i> Ajouter une photo</span>
															<input type="file" className="upload" />
														</div>
														<small className="form-text text-muted">Autorisé JPG, GIF ou PNG. Taille max de 2MB</small>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Pseudo <span className="text-danger">*</span></label>
												<input type="text" className="form-control" readOnly />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>E-mail <span className="text-danger">*</span></label>
												<input type="email" className="form-control" readOnly/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Prénom <span className="text-danger">*</span></label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Nom <span className="text-danger">*</span></label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Numéro de téléphone</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Sexe</label>
												<select className="form-control select">
													<option>Selectionner</option>
													<option>Masculin</option>
													<option>Féminin</option>
												</select>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group mb-0">
												<label>Date de naissance</label>
												<input type="text" className="form-control" />
											</div>
										</div>
									</div>
								</div>
							</div>
						
							<div className="card">
								<div className="card-body">
									<h4 className="card-title">A propos de moi</h4>
									<div className="form-group mb-0">
										<label>Biographie</label>
										<textarea className="form-control" rows="5"></textarea>
									</div>
								</div>
							</div>
						
							<div className="card">
								<div className="card-body">
									<h4 className="card-title">Renseignement sur la clinique</h4>
									<div className="row form-row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Nom</label>
												<input type="text" className="form-control"/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label>Adresse</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<label>Photo</label>
												<div>
												<DropzoneArea
        onChange={this.handleChange.bind(this)}
        />
         											 </div>
											</div>
										
										</div>
									</div>
								</div>
							</div>
						
							<div className="card contact-card">
								<div className="card-body">
									<h4 className="card-title">Informations supplémentaire</h4>
									<div className="row form-row">
										<div className="col-md-6">
											<div className="form-group">
												<label>Adresse principale</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label className="control-label">Adresse secondaire</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label className="control-label">Quartier</label>
												<input type="text" className="form-control" />
											</div>
										</div>

										<div className="col-md-6">
											<div className="form-group">
												<label className="control-label">Ville / Province</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label className="control-label">Pays</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label className="control-label">Code postale</label>
												<input type="text" className="form-control" />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="card info-paiment-card">
								<div className="card-body">
									<h4 className="card-title">Informations paiement</h4>
									<div className="row form-row">
										<div className="col-md-6">
											<div className="form-group">
												<label>N° de tel Airtel Money</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label className="control-label">N° de tel MTN Mobile Money</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<label className="control-label">IBAN</label>
												<input type="text" className="form-control" />
											</div>
										</div>

									</div>
								</div>
							</div>
				
							<div className="card">
								<div className="card-body">
									<h4 className="card-title">Tarif</h4>
									
									<div className="form-group mb-0">
										<div id="pricing_select">
											<div className="custom-control custom-radio custom-control-inline">
				<input type="radio" id="price_free" name="rating_option" className="custom-control-input" value="price_free" checked />
												<label className="custom-control-label" for="price_free">Gratuit</label>
											</div>
											<div className="custom-control custom-radio custom-control-inline">
												<input type="radio" id="price_custom" name="rating_option" value="custom_price" className="custom-control-input" />
												<label className="custom-control-label" for="price_custom">Personnaliser (par heure)</label>
											</div>
										</div>

									</div>
									
									<div className="row custom_price_cont" id="custom_price_cont" style={{display: 'none'}}>
										<div className="col-md-4">
                                            <input type="text" className="form-control" id="custom_rating_input" 
                                            name="custom_rating_count" value="" placeholder="20" />
											<small className="form-text text-muted">Custom price you can add</small>
										</div>
									</div>
									
								</div>
							</div>
					
							<div className="card services-card">
								<div className="card-body">
									<h4 className="card-title">Services et spécialisations</h4>
									<div className="form-group">
										<label>Services</label>
										<ReactTagsInput key='tag1'/>
									</div> 
									<div className="form-group mb-0">
										<label>Spécialisation </label>
										<ReactTagsInput key='tag2'/>
										<small className="form-text text-muted">Note : Ecrivez et tapez sur Entrée pour ajouter une nouvelle spécialisation</small>
									</div> 
								</div>              
							</div>
						
						<Education />
						<Experience />
						<Award />
						<Memberships />	
						<Registration />	
					
							
							<div className="submit-section submit-btn-bottom">
								<button type="submit" className="btn btn-primary submit-btn">Sauvegarder</button>
							</div>
							
						</div>
					</div>

				</div>

			</div>	
        </div>
     
        );

    }
}

export default ProfileSetting;