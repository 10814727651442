import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DashboardSidebar from '../sidebar/sidebar.jsx';
import StickyBox from "react-sticky-box";
import axios from 'axios';
import Slider from "react-slick";

import { IMG01, IMG02, IMG03, IMG04, IMG05, IMG06, IMG07, IMG08, IMG09 } from './img';

class Favoris extends Component {
	constructor(props) {
		super(props);

		this.state = {
			patient: { favoris: [] }
		}
	}

	async componentDidMount() {
		let dbUser = JSON.parse(sessionStorage.getItem("user"))
			|| JSON.parse(sessionStorage.getItem("user"));
		const dataJson = sessionStorage.getItem('user');
		const data = dataJson && JSON.parse(dataJson);

		let patientResponse = await
			fetch(`https://kwaber-sante.herokuapp.com/users/${data.user.id}`)

		if (patientResponse.status == 200) {
			let data = await patientResponse.json()
			this.setState({
				patient: data,
			});
			// console.log(data.favoris, " lombr")

		}
	}
	render() {
		//  console.log(this.state.patient.favoris, 'lombrfavori')
		return (
			<div>

				<div className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
								<StickyBox offsetTop={20} offsetBottom={20}>
									<DashboardSidebar />
								</StickyBox>
							</div>
							
							<div className="col-md-7 col-lg-8 col-xl-9">
								<div className="row row-grid">

									{
										this.state.patient.favoris.length === 0? (
											<div class="jumbotron jumbotron-fluid">
												<div class="container">
													{/* <h1 class="display-4">Mes Rendez-vous</h1> */}
													<p class="lead"> Vous n'avez pas de favoris</p>
												</div>
											</div>
										) :

										this.state.patient.favoris.map((profesionnel) => (
											<ProfessionnelDeSante key={profesionnel.id} professionneldata={profesionnel} />
										))}
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		);
	}
}

class ProfessionnelDeSante extends Component {

	constructor(props) {
		super(props);
		this.state = {
			professionnel: this.props.professionneldata,
			specialites: [],
			adresseCentre: "",
			tabFavoris: [],
		};
	}

	async componentDidMount() {

		let patientResponse = await
			fetch(`https://kwaber-sante.herokuapp.com/users/${this.props.professionneldata.id}`)

		if (patientResponse.status == 200) {
			let data = await patientResponse.json()
			let adresse = "adresse non renseignée"
			if (data.centres[0] !== undefined && data.centres[0] !== null) {
				adresse = data.centres[0].adresse
			}
			this.setState({
				specialites: data.specialites,
				adresseCentre: adresse
			});

		}
		const dataJson = sessionStorage.getItem('user');
		const data = dataJson && JSON.parse(dataJson);
		fetch(`https://kwaber-sante.herokuapp.com/users/${data.user.id}`)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				console.log(data)
				this.setState({
					tabFavoris: data.favoris
				});
				console.log(data.favoris, "compo di month")
			});
	}
	estPresent(pro) {
		let retour = false
		this.state.tabFavoris.forEach(element => {
			if (pro.id == element.id) {
				retour = true
			}
		});
		return retour
	}

	async favori() {
		const dataJson = sessionStorage.getItem('user');
    const data =  dataJson && JSON.parse(dataJson);

		let tabFavoris = this.state.tabFavoris

		if (this.estPresent(this.state.professionnel) == true) {
			console.log("ici")
			let tabIntermediaire = []
			this.state.tabFavoris.forEach(element => {
				if (this.state.professionnel.id == element.id) { }
				else {
					tabIntermediaire.push(element)
					console.log(element, "element")
				}
			});
			axios.put(`https://kwaber-sante.herokuapp.com/users/${data.user.id}`, {
				favoris: tabIntermediaire
			})
				.then(response => {
					this.componentDidMount()
					console.log(response, "supprimer")
				});

		} else {

			console.log(tabFavoris, "avant push")
			tabFavoris.push(this.state.professionnel)
			axios.put(`https://kwaber-sante.herokuapp.com/users/${data.user.id}`, {
				favoris: tabFavoris
			})
				.then(response => {
					this.componentDidMount()
					console.log(response, "ajout")
				});

		}


	}

	render() {

		const { professionneldata } = this.props;
		console.log(professionneldata,"const")

		function isObjEmpty(obj) {
			return Object.keys(obj).length === 0;
		}
		const test = isObjEmpty(professionneldata) == false ? "data" : "vide"

		console.log(test, "titre")

		return (
			<div className="col-md-6 col-lg-4 col-xl-3">

				{





					// professionneldata ? ( 
					// 			<div class="jumbotron jumbotron-fluid">
					// 			<div class="container">
					// 				<h1 class="display-4">Mes patients</h1>
					// 				<p class="lead"> Vous n'avez pas encore des patients.</p>
					// 			</div>
					// 		</div>
					// 		 ) : 

					<div className="profile-widget">

						<div className="doc-img">
							<Link to={"/patient/doctor-profile/" + this.props.professionneldata.id}>
								<img className="img-fluid" alt="User" src={this.props.professionneldata.photo[0].url} />
							</Link>
							<div className="fav-btn">
								{/* <i className="far fa-bookmark" aria-hidden="true" onClick={this.testC()}></i> */}
								<button className="btn" aria-hidden="true" onClick={this.favori.bind(this)}>
									<i className="far fa-bookmark"></i>
								</button>
							</div>
						</div>
						<div className="pro-content">
							<h3 className="title">
								<Link to={"/patient/doctor-profile/" + this.props.professionneldata.id}>{this.state.professionnel.Nom} {this.state.professionnel.prenom}</Link>
								<i className="fas fa-check-circle verified"></i>
							</h3>
							<div className="speciality">
								Spécialités :
								{this.state.specialites.map((specialite) => (
									<p className="speciality">{specialite.libelle}</p>
								))}
							</div>
							{/* <div className="rating">
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<i className="fas fa-star filled"></i>
							<span className="d-inline-block average-rating">(17)</span>
						</div> */}
							<ul className="available-info">
								<li>
									<i className="fas fa-map-marker-alt"></i> {this.state.adresseCentre}
								</li>
								<li>
									<i className="far fa-clock"></i> Disponible le Mardi 22 Avr, 2022
								</li>
								<li>
									<i className="far fa-money-bill-alt"></i> 300€ - 1000€
									<i className="fas fa-info-circle" data-toggle="tooltip" title="Lorem Ipsum"></i>
								</li>
							</ul>
							<div className="row row-sm">
								<div className="col-6">
									<Link to={"/patient/doctor-profile/" + this.props.professionneldata.id} className="btn view-btn">Voir profil</Link>
								</div>
								<div className="col-6">
									<Link to={{
										pathname: "/prestation",
										aboutProps: {
											idProfessionel: this.state.professionnel.id
										}
									}} className="btn book-btn">Réserver</Link>
									{/* <button onClick={this.test.bind(this)}>test</button> */}
								</div>
							</div>
						</div>
					</div >
				}
			</div >
		);



	}




}
export default Favoris;