import React, { Component } from "react";
// import PatientSidebar from "../patienttsidebar";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Tab, Tabs, Modal } from "react-bootstrap";
import { MDBDataTableV5, MDBTable } from "mdbreact";
import host from "../../../../host";
// import {
//   IMG01,
//   IMG02,
//   IMG03,
//   IMG04,
//   IMG05,
//   IMG06,
//   IMG07,
//   IMG08,
//   IMG09,
//   IMG10,
// } from "./img";
import QRCode from "qrcode";
import Loader from "react-loader-spinner";
import logo from "../../../assets/images/logoK.jpeg";
import qrCode from "../../../assets/images/qrCode.png";
import jsPDF from "jspdf";
import { ToastContainer, toast } from "react-toastify";
const ModalAntd = require("antd").Modal;

class PatientProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1,
      patient: [],
      professionnel: { ordonnances: [] },
      tableauOrdonnance: [],
      rdv: [],
      datatable: {
        columns:[{
          label: "Date du rendez-vous",
          field: "date",
          //   sort: 'asc',
          width: 270,
        },
        {
          label: "Prestation",
          field: "prestation",
          //   sort: 'asc',
          width: 270,
        },
        {
          label: "Date du paiement",
          field: "paiement",
          //   sort: 'asc',
          width: 270,
        },
        {
          label: "Statut",
          field: "statut",
          //   sort: 'asc',
          width: 270,
        },
      ],
        rows:[],     
      },
      tabAffichage: [],
      ordonnance: [],
      isLoading: false,
      visibility: false,
      consultations: [],
      columns: [
        {
          label: "Date de consultation",
          field: "date",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "nom",
          },
        },
        {
          label: "Prestation",
          field: "libelle",
          width: 100,
        },
        {
          label: "Actions",
          field: "action",
          width: 100,
        },
      ],
      prescriptions: [],
      col: [
        {
          label: "Nom",
          field: "libelle",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "nom",
          },
        },
        {
          label: "Quantité",
          field: "quantite",
          width: 100,
        },
        {
          label: "Matin",
          field: "m",
          width: 100,
        },
        {
          label: "Midi",
          field: "a",
          width: 100,
        },
        {
          label: "Soire",
          field: "s",
          width: 100,
        },
        {
          label: "Nuit",
          field: "n",
          width: 100,
        },
        {
          label: "Début",
          field: "dateprescription",
          width: 100,
        },
        {
          label: "Fin",
          field: "datefin",
          width: 100,
        },
      ],
      columnsOrdonnances: [
        {
          label: "Date",
          field: "simpleDate",
          width: 100,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "nom",
          },
        },
        {
          label: "ID",
          field: "idordonnance",
          width: 100,
        },
        {
          label: "Actions",
          field: "action",
          width: 100,
        },
      ],
      detail: null,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    const dataJson =
      sessionStorage.getItem("user") || sessionStorage.getItem("user");
    const data = dataJson && JSON.parse(dataJson);

    // this.setState({
    //   professionnel: data.user.professionnelsante,
    // });

    const info =
      sessionStorage.getItem("patientProfile") ||
      sessionStorage.getItem("patientProfile");
    const tmp = info && JSON.parse(info);

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("auth", tmp.token);

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      host + `/ps/${data.user.professionnelsante.idprofessionnelsante}`,
      requestOptions
    )
      .then((response) => {
        if (response.status == 401) {
          sessionStorage.removeItem("user");
          this.props.history.push("/login");
          return null;
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        // console.log(result);
        if (result === null) {
        } else {
          this.setState({
            professionnel: result,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error);
      });

    await fetch(host + `/patient/${tmp.patient.id}`, requestOptions)
      .then((response) => {
        if (response == 401) {
          alert("token non valide");
        }
        if (response.status != 200 && response.status != 201) {
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result != null) {
          let consultations = [];
          for (let i = 0; i < result.consultation.length; i++) {
            const element = result.consultation[i];
            if (
              element.idprofessionnelsante.idprofessionnelsante ==
              data.user.professionnelsante.idprofessionnelsante
            ) {
              consultations.push({
                libelle: element.prestation.libelle,
                date: (
                  <>
                    {this.ecrireDate(element.dateconsultation)}{" "}
                    <span className="d-block text-info">
                      {element.heuredebutconsultation.substring(0, 5)}
                    </span>
                  </>
                ),
                action: (
                  <Link
                    className="btn btn-sm bg-primary-light"
                    to={{
                      pathname: "/add-prescription",
                      patient: {
                        idPatient: this.state.patient.id,
                      },
                    }}
                    onClick={() => this.go(element.id, element.prestation.id)}
                    style={{ padding: "10px" }}
                  >
                    <i className="fas fa-plus"></i> Ajouter une ordonnance
                  </Link>
                ),
              });
            }
          }
          this.setState({
            patient: result,
            consultations: consultations,
          });
        }
      })
      .catch((error) => {
        // console.log("error", error)
      });

    this.remplirTableau();
    this.setState({
      isLoading: false,
    });
    // if (patientResponse.status == 200) {
    //   let data = await patientResponse.json();
    //   this.setState({
    //     patient: data,
    //   });
    // }
    // let ordonnanceResponse = await
    //   fetch(`https://kwaber-sante.herokuapp.com/ordonnances?userPatient.id= ${this.props.location.patient.idPatient}`)

    // if (ordonnanceResponse.status == 200) {
    //   let data = await ordonnanceResponse.json()
    //   this.setState({
    //     ordonnance: data
    //   });
    // }

    // let pfSanteResponse = await
    //   fetch(`https://kwaber-sante.herokuapp.com/users/${data.user.id}`)

    // if (pfSanteResponse.status == 200) {
    //   let data = await pfSanteResponse.json()
    //   this.setState({
    //     professionnel: data
    //   });
    // }
    // let URL = ` https://kwaber-sante.herokuapp.com/RDVS?creneau.user= ${this.props.location.patient.idPatient} `;

    //   console.log(URL, 'lb');

    // let response = await fetch(URL)

    // if (response.status == 200) {
    //   let data = (await response.json())
    //   // console.log(data, "la datetete")
    //   this.setState({
    //     rdv: data
    //   })
    // }

    // this.remplirTableau()
  }

  go = (idconsultation, idprestation) => {
    sessionStorage.setItem("idconsultation", idconsultation);
    sessionStorage.setItem("idprestation", idprestation);
    // alert(idconsultation+" "+idprestation)
  };

  remplirTableau() {
    // console.log("remplir tableau", this.state.ordonnance);
    let tableauOrdonnance = [];
    let allRdvs = [];
    let tableauRdvs = [];
    for (let i = 0; i < this.state.patient.ordonnances.length; i++) {
      if (
        this.state.patient.ordonnances[i].idprofessionnelsante ==
        this.state.professionnel.idprofessionnelsante
      ) {
        tableauOrdonnance.push(this.state.patient.ordonnances[i]);
      }
    }
    for (let i = 0; i < this.state.patient.rdvs.length; i++) {
      //  console.log("patientRDV:",this.state.patient.rdvs[i])
        if(this.state.patient.rdvs[i].idprofessionnelsante2 !== null){
          if (
            this.state.patient.rdvs[i].idprofessionnelsante2.idprofessionnelsante ==
            this.state.professionnel.idprofessionnelsante
          ) {
            tableauRdvs.push(this.state.patient.rdvs[i]);
          }
        }
    }
    allRdvs =tableauRdvs.reverse();
     let  rowElements = [];
      for (let index = 0; index < allRdvs.length; index++) {
        const element = allRdvs[index];
        // console.log("RDV-LOMBRE: ",element)
        if(element.idprofessionnelsante2 !== null){
          rowElements.push({
            date: <p>{this.ecrireDate3(element.idcreneau2.datecreneau)}{" "}
            <span className="d-block text-info">
             {" Horaire: de "}
              {element.idcreneau2.heuredebut.substring(0,5)}
              { " à "} 
              {element.idcreneau2.heurefin.substring(0,5)}
            </span>
          </p>,
            prestation: element.idprestation2.libelle,
            paiement: element.datepaiement,
            statut: <span  className="btn btn-sm bg-info-light"> Confirmé</span>,
          })
        }
        
      }


    // for (let i = 0; i < this.state.ordonnance.length; i++) {
    //   console.log(this.state.ordonnance[i], "ordo");
    //   if (this.state.ordonnance[i].userPro.id == this.state.professionnel.id) {
    //     tab.push(this.state.ordonnance[i]);
    //   }
    // }
    // console.log(tab, "la tabtab");
    this.setState({
      // tableauOrdonnance: tab.reverse(),
      // tabAffichage: this.state.rdv.reverse(),
      // tableauOrdonnance: this.state.patient.ordonnances.reverse(),
      // tabAffichage: this.state.patient.rdvs.reverse(),
      datatable: {
        columns: this.state.datatable.columns,
        rows: rowElements
          },
      tableauOrdonnance: tableauOrdonnance.reverse(),
      tabAffichage: tableauRdvs.reverse(),
    });
  }
  ecrireDate3(date) {
    //2021-03-30
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    var str = new Date(date);
    //var tab = str.split("-");

    var jour = listeJours[str.getDay()];
    var mois = listeMois[str.getMonth()];

    return str.getDate() + " " + mois + " " + str.getFullYear();
  }

  handleSelect(key) {
    // console.log("selected " + key);
    this.setState({ key });
  }
  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  ecrireDate(date) {
    //2021-03-30
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    var str = new Date(date);
    //var tab = str.split("-");

    var jour = listeJours[str.getDay()];
    var mois = listeMois[str.getMonth()];

    return jour + " " + str.getDate() + " " + mois + " " + str.getFullYear();
  }

  calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    // console.log(age_now);
    return age_now;
  };

  show = (ordonnance) => {
    // console.log(ordonnance);
    for (let i = 0; i < ordonnance.prescriptions.length; i++) {
      ordonnance.prescriptions[i].m = ordonnance.prescriptions[i].matin
        ? "Oui"
        : "Non";
      ordonnance.prescriptions[i].a = ordonnance.prescriptions[i].apresmidi
        ? "Oui"
        : "Non";
      ordonnance.prescriptions[i].s = ordonnance.prescriptions[i].soire
        ? "Oui"
        : "Non";
      ordonnance.prescriptions[i].n = ordonnance.prescriptions[i].nuit
        ? "Oui"
        : "Non";
    }
    this.setState({
      visibility: true,
      detail: ordonnance,
      prescriptions: ordonnance.prescriptions,
    });
  };

  handleCancel = () => {
    this.setState({
      visibility: false,
      detail: null,
      prescriptions: [],
    });
  };

  dateAJD() {
    let date = new Date();
    var listeJours = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    var listeMois = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    var nombre = date.getDate();
    var jour = listeJours[date.getDay()];
    var mois = listeMois[date.getMonth()];
    return jour + " " + nombre + " " + mois;
  }

  ecrireDate2(date) {
    var d = new Date(date);

    var mois = parseInt(d.getMonth());
    var jour = parseInt(d.getDate());
    mois = mois + 1;
    if (mois < 10) {
      mois = "0" + mois;
    }
    if (jour < 10) {
      jour = "0" + jour;
    }

    return jour + "-" + mois + "-" + d.getFullYear();
  }

  generatePDF2 = async () => {
    var doc = new jsPDF("p", "pt");

    // text pour les commandes à inserer
    // let numéroCommande = "0013";
    // let dateCommande = this.dateAJD();
    let numéroCommande = this.state.detail.idordonnance;
    let dateCommande = this.ecrireDate(this.state.detail.date);
    let client = this.state.patient.nom + " " + this.state.patient.prenom;
    let docteur =
      this.state.professionnel.nom + " " + this.state.professionnel.prenom;
    let hauteurTableauDescription = 220;

    var imgData = logo;
    // var imgCode = qrCode;
    let text = "Ordonnance n° "+ numéroCommande+"\n"
    text += "Délivrée le "+dateCommande+"\n"
    text += "À destination de : "+client+"\n"
    text += "Par le docteur "+docteur+"\n"

    // Haut de la page en bleu
    doc.addImage(imgData, "JPEG", 20, 20, 100, 60);
    //droite
    doc.setFontSize(12);
    doc.addFont("arial", "normal");
    doc.text(270, 35, "Ordonnance : ");
    doc.setFont("arial", "bold");
    doc.text(342, 35, "#" + numéroCommande);
    doc.setFont("arial", "normal");
    doc.text(380, 35, "Délivrée le ");
    doc.setFont("arial", "bold");
    doc.text(440, 35, dateCommande);

    doc.setFontSize(16);
    doc.setFont("arial", "bold");
    doc.text(420, 70, "À destination de :");
    doc.setFontSize(12);
    doc.setFont("arial", "normal");
    doc.text(420, 85, client);

    doc.setFontSize(16);
    doc.setFont("arial", "bold");
    doc.text(20, 115, "Medecin");
    doc.setFontSize(12);
    doc.setFont("arial", "normal");
    doc.text(20, 130, docteur);

    doc.setFontSize(14);
    doc.setFont("arial", "bold");
    doc.text(20, 220, "Produit");
    doc.text(100, 220, "Quantité");
    doc.text(170, 220, "Periode");
    doc.text(350, 220, "Date de Début");
    doc.text(500, 220, "Date de Fin");

    doc.setFontSize(12);
    doc.setFont("arial", "normal");
    text += "Produits : \n\n"
    for (let i = 0; i < this.state.prescriptions.length; i++) {
      
      let periode = this.state.prescriptions[i].matin ? "matin" : "";
      periode += this.state.prescriptions[i].apresmidi ? " midi" : "";
      periode += this.state.prescriptions[i].soire ? " soire" : "";
      periode += this.state.prescriptions[i].nuit ? " nuit" : "";
      text += "____________________________ \n\n"

      text += this.state.prescriptions[i].libelle+" | Quantite: "+this.state.prescriptions[i].quantite+" | "+periode+"\n"
      text += "Date de Début: "+this.state.prescriptions[i].dateprescription+" | Date de Fin: "+this.state.prescriptions[i].datefin+"\n"

      hauteurTableauDescription = hauteurTableauDescription + 30;
      doc.text(
        20,
        hauteurTableauDescription,
        this.state.prescriptions[i].libelle
      );
      doc.text(
        this.state.prescriptions[i].quantite + "",
        100,
        hauteurTableauDescription
      );
      doc.text(170, hauteurTableauDescription, periode);
      doc.text(
        this.ecrireDate2(this.state.prescriptions[i].dateprescription),
        350,
        hauteurTableauDescription
      );
      doc.text(
        this.ecrireDate2(this.state.prescriptions[i].datefin),
        500,
        hauteurTableauDescription
      );
    }

    doc.setFontSize(14);
    doc.setFont("arial", "bold");
    doc.text(350, hauteurTableauDescription + 60, "Signature :");
    doc.setFontSize(14);
    doc.setFont("arial", "normal");
    doc.addImage(
      // `${host}/ps/signatures/${this.state.professionnel.signature}`,
      this.state.professionnel.signature,
      "PNG",
      350,
      hauteurTableauDescription + 90,
      200,
      50
    );

    const code = await QRCode.toDataURL(text);
    doc.addImage(code, "PNG", 200, 600, 200, 200);

    this.setState({
      isLoading: true,
    });
    doc
      .save("ordonnace.pdf", {
        returnPromise: true,
      })
      .then((b) => {
        this.setState({
          isLoading: false,
        });
        toast.success("L'ordonnance a bien été imprimée");
      });
  };

  render() {
    return (
      <div>
        <ToastContainer />
        <ModalAntd
          width="75%"
          title="Détail prescription"
          visible={this.state.visibility}
          okText="Ok"
          cancelText="Retour"
          okType="primary"
          onOk={this.state.isLoading ? null : this.handleCancel}
          onCancel={this.state.isLoading ? null : this.handleCancel}
          destroyOnClose={true}
        >
          <div
            className={
              this.state.isLoading
                ? "page_wrapper_blur card card-table mb-0"
                : "card card-table mb-0"
            }
          >
            <div className="card-body">
              <Loader
                style={{
                  position: "absolute",
                  textAlign: "center",
                  marginLeft: "auto",
                  left: "0",
                  right: "0",
                  marginRight: "auto",
                  zIndex: "20px",
                }}
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                visible={this.state.isLoading}
              />
              <div className="table" style={{ padding: "10px" }}>
                <MDBDataTableV5
                  entriesOptions={[5, 10]}
                  displayEntries={false}
                  entries={5}
                  responsive={true}
                  entriesLabel={"Lignes par pages"}
                  searchLabel={"Rechercher"}
                  infoLabel={["", "-", "/", ""]}
                  noRecordsFoundLabel={"Aucune données de correspond"}
                  data={{
                    columns: this.state.col,
                    rows: this.state.prescriptions,
                  }}
                  searchTop
                  searchBottom={false}
                />
              </div>
              <a
                href="#0"
                className="btn btn-sm bg-primary-light"
                onClick={this.generatePDF2}
                style={{ margin: "5px" }}
              >
                <i className="fas fa-print"></i> imprimer
              </a>
            </div>
          </div>
        </ModalAntd>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar dct-dashbd-lft">
                <div className="card widget-profile pat-widget-profile">
                  <div className="card-body">
                    <div className="pro-widget-content">
                      <div className="profile-info-widget">
                        {/* <img src={(this.state.patient.photo[0] !== null && this.state.patient.photo[0] !== undefined) ? this.state.patient.photo[0].url : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"} /> */}
                        <a href="#0" class="booking-doc-img">
                          <img
                            src={
                              this.state.patient &&
                              this.state.patient.user &&
                              this.state.patient.user.photo
                                ? 
                                this.state.patient.user.photo
                                // host + `/user/images/${this.state.patient.user.photo} `
                                : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                            }
                          />
                        </a>
                        <br /> <br />{" "}
                        <div className="profile-det-info">
                          <h3>
                            {" "}
                            {this.state.patient.nom} {this.state.patient.prenom}
                          </h3>

                          <div className="patient-details">
                            {/* <h5>
                              <b>Patient ID :</b> {this.state.patient.id}
                            </h5> */}
                            <h5 className="mb-0">
                              {/* <i className="fas fa-map-marker-alt"></i>{" "} */}
                              {this.state.patient.email}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="patient-info">
                      <ul>
                        <li>
                          Téléphone 1
                          <span>{this.state.patient.numtelephone1}</span>
                        </li>
                        <li>
                          Téléphone 2
                          <span>{this.state.patient.numtelephone2}</span>
                        </li>
                        <li>
                          Age{" "}
                          <span>
                            {" "}
                            {this.calculate_age(
                              this.state.patient.datenaissance
                            )}{" "}
                            ans
                          </span>
                        </li>
                        <li>
                          Sexe <span> {this.state.patient.sexe}</span>
                        </li>
                        <li>
                          Groupe sanguin{" "}
                          <span>{this.state.patient.groupesangin}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-lg-8 col-xl-9 dct-appoinment">
                <div
                  className={
                    this.state.isLoading && !this.state.visibility
                      ? "page_wrapper_blur card"
                      : "card"
                  }
                >
                  <div className="card-body pt-0">
                    <Loader
                      style={{
                        position: "absolute",
                        textAlign: "center",
                        marginLeft: "auto",
                        left: "0",
                        right: "0",
                        marginRight: "auto",
                        zIndex: "20px",
                      }}
                      type="Puff"
                      color="#00BFFF"
                      height={100}
                      width={100}
                      visible={this.state.isLoading && !this.state.visibility}
                    />
                    <Tabs
                      className="tab-view"
                      activeKey={this.state.key}
                      onSelect={this.handleSelect}
                      id="controlled-tab-example"
                    >
                      <Tab
                        className="nav-item"
                        eventKey={1}
                        title="Rendez-vous"
                      >
                        <div className="card card-table mb-0">
                          <div className="card-body">
                          <MDBTable responsive>
                            <MDBDataTableV5
                                    //   La table des prestations with mdbreact 
                                                 hover
                                                 searchTop
                                                 searchBottom={false}
                                                 entriesLabel={"Lignes par pages"}
                                                 searchLabel={"Rechercher"}
                                                 infoLabel={["", "-", "/", ""]}
                                                 noRecordsFoundLabel={
                                                   "Aucune donnée ne correspond"
                                                 }
                                                entriesOptions={[5, 20, 25]}
                                                entries={5}
                                                pagesAmount={4}
                                                data={this.state.datatable}
                                               
                                            />
                            </MDBTable>
                            
                          </div>
                        </div>
                      </Tab>

                      <Tab
                        className="nav-item"
                        eventKey={2}
                        title="Ordonnances"
                      >
                        <div className="text-right">
                          {/* <Link
                            to={{
                              pathname: "/add-prescription",
                              patient: {
                                idPatient: this.state.patient.id,
                              },
                            }}
                            // onClick={this.show}
                            className="add-new-btn"
                          >
                            Ajouter une Ordonance
                          </Link> */}
                        </div>
                        <h4>Liste des ordonnances :</h4>
                        <div className="card card-table mb-0">
                          <div className="card-body">
                            <div className="table" style={{ padding: "10px" }}>
                              <MDBDataTableV5
                                entriesOptions={[5, 10, 15]}
                                entries={5}
                                responsive={true}
                                entriesLabel={"Lignes par pages"}
                                searchLabel={"Rechercher"}
                                infoLabel={["", "-", "/", ""]}
                                noRecordsFoundLabel={
                                  "Aucune données de correspond"
                                }
                                data={{
                                  columns: this.state.columnsOrdonnances,
                                  rows: this.state.tableauOrdonnance.map(
                                    (ordonnance) => {
                                      ordonnance["simpleDate"] =
                                        this.ecrireDate(ordonnance.date);
                                      ordonnance["action"] = (
                                        <a
                                          href="#0"
                                          className="btn btn-sm bg-info-light"
                                          onClick={() => this.show(ordonnance)}
                                        >
                                          <Link
                                            to={
                                              {
                                                // pathname: "/ordonnance",
                                                // ordonnance: {
                                                //   idOrdonnance:
                                                //     ordonnance.id,
                                                // },
                                              }
                                            }
                                            className="far fa-eye"
                                          ></Link>
                                        </a>
                                      );
                                      return ordonnance;
                                    }
                                  ),
                                }}
                                searchTop
                                searchBottom={false}
                              />
                              {/* <table className="table table-hover table-center mb-0">
                                <thead>
                                  <tr>
                                    <th>Date </th>
                                    <th>ID</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.tableauOrdonnance.map(
                                    (ordonnance, index) => {
                                      return (
                                        <tr>
                                          <td>
                                            {this.ecrireDate(ordonnance.date)}
                                          </td>
                                          <td>{ordonnance.idordonnance}</td>

                                          <td className="text-right">
                                            <div className="table-action">
                                              <a
                                                href="#0"
                                                className="btn btn-sm bg-info-light"
                                                onClick={() =>
                                                  this.show(ordonnance)
                                                }
                                              >
                                                <Link
                                                  to={
                                                    {
                                                      
                                                    }
                                                  }
                                                  className="far fa-eye"
                                                ></Link>
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table> */}
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <h4>Liste des consultations :</h4>
                        <div className="card card-table mb-0">
                          <div className="card-body">
                            <div className="table" style={{ padding: "10px" }}>
                              <MDBDataTableV5
                                entriesOptions={[5, 10, 15]}
                                entries={5}
                                responsive={true}
                                entriesLabel={"Lignes par pages"}
                                searchLabel={"Rechercher"}
                                infoLabel={["", "-", "/", ""]}
                                noRecordsFoundLabel={
                                  "Aucune données de correspond"
                                }
                                data={{
                                  columns: this.state.columns,
                                  rows: this.state.consultations,
                                }}
                                searchTop
                                searchBottom={false}
                              />
                            </div>
                          </div>
                        </div>
                      </Tab>
                      {/* <Tab
                        className="nav-item"
                        eventKey={3}
                        title="Dossiers médicaux"
                      >
                        <div className="text-right">
                          <a
                            href="#0"
                            className="add-new-btn"
                            onClick={this.handleShow}
                            data-target="#add_medical_records"
                          >
                            Add Medical Records
                          </a>
                        </div>
                        <div className="card card-table mb-0">
                          <div className="card-body">
                            <table className="table table-hover table-center mb-0">
                              <thead>
                                <tr>
                                  <th>ID</th>
                                  <th>Date </th>
                                  <th>Description</th>
                                  <th>Attachment</th>
                                  <th>Created</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <a href="#0">#MR-0010</a>
                                  </td>
                                  <td>14 Nov 2019</td>
                                  <td>Dental Filling</td>
                                  <td>
                                    <a href="#0">dental-test.pdf</a>
                                  </td>
                                  <td>
                                    <h2 className="table-avatar">
                                      <Link
                                        to="/patient/doctor-profile"
                                        className="avatar avatar-sm mr-2"
                                      >
                                        <img
                                          className="avatar-img rounded-circle"
                                          src={IMG01}
                                          alt="User "
                                        />
                                      </Link>
                                      <Link to="/patient/doctor-profile">
                                        Dr. Ruby Perrin <span>Dental</span>
                                      </Link>
                                    </h2>
                                  </td>
                                  <td className="text-right">
                                    <div className="table-action">
                                      <a
                                        href="#0"
                                        className="btn btn-sm bg-info-light"
                                      >
                                        <i className="far fa-eye"></i> View
                                      </a>
                                      <a
                                        href="#0"
                                        className="btn btn-sm bg-primary-light"
                                      >
                                        <i className="fas fa-print"></i> Print
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <a href="#0">#MR-0009</a>
                                  </td>
                                  <td>13 Nov 2019</td>
                                  <td>Teeth Cleaning</td>
                                  <td>
                                    <a href="#0">dental-test.pdf</a>
                                  </td>
                                  <td>
                                    <h2 className="table-avatar">
                                      <Link
                                        to="/patient/doctor-profile"
                                        className="avatar avatar-sm mr-2"
                                      >
                                        <img
                                          className="avatar-img rounded-circle"
                                          src={IMG02}
                                          alt="User "
                                        />
                                      </Link>
                                      <Link to="/patient/doctor-profile">
                                        Dr. Darren Elder <span>Dental</span>
                                      </Link>
                                    </h2>
                                  </td>
                                  <td className="text-right">
                                    <div className="table-action">
                                      <a
                                        href="#0"
                                        className="btn btn-sm bg-info-light"
                                      >
                                        <i className="far fa-eye"></i> View
                                      </a>
                                      <a
                                        href="#0"
                                        className="btn btn-sm bg-primary-light"
                                      >
                                        <i className="fas fa-print"></i> Print
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <a href="#0">#MR-0008</a>
                                  </td>
                                  <td>12 Nov 2019</td>
                                  <td>General Checkup</td>
                                  <td>
                                    <a href="#0">cardio-test.pdf</a>
                                  </td>
                                  <td>
                                    <h2 className="table-avatar">
                                      <Link
                                        to="/patient/doctor-profile"
                                        className="avatar avatar-sm mr-2"
                                      >
                                        <img
                                          className="avatar-img rounded-circle"
                                          src={IMG03}
                                          alt="User "
                                        />
                                      </Link>
                                      <Link to="/patient/doctor-profile">
                                        Dr. Deborah Angel{" "}
                                        <span>Cardiology</span>
                                      </Link>
                                    </h2>
                                  </td>
                                  <td className="text-right">
                                    <div className="table-action">
                                      <a
                                        href="#0"
                                        className="btn btn-sm bg-info-light"
                                      >
                                        <i className="far fa-eye"></i> View
                                      </a>
                                      <a
                                        href="#0"
                                        className="btn btn-sm bg-primary-light"
                                      >
                                        <i className="fas fa-print"></i> Print
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <a href="#0">#MR-0007</a>
                                  </td>
                                  <td>11 Nov 2019</td>
                                  <td>General Test</td>
                                  <td>
                                    <a href="#0">general-test.pdf</a>
                                  </td>
                                  <td>
                                    <h2 className="table-avatar">
                                      <Link
                                        to="/patient/doctor-profile"
                                        className="avatar avatar-sm mr-2"
                                      >
                                        <img
                                          className="avatar-img rounded-circle"
                                          src={IMG04}
                                          alt="User "
                                        />
                                      </Link>
                                      <Link to="/patient/doctor-profile">
                                        Dr. Sofia Brient <span>Urology</span>
                                      </Link>
                                    </h2>
                                  </td>
                                  <td className="text-right">
                                    <div className="table-action">
                                      <a
                                        href="#0"
                                        className="btn btn-sm bg-info-light"
                                      >
                                        <i className="far fa-eye"></i> View
                                      </a>
                                      <a
                                        href="#0"
                                        className="btn btn-sm bg-primary-light"
                                      >
                                        <i className="fas fa-print"></i> Print
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Tab> */}
                      {/* <Tab
                        className="nav-item"
                        eventKey={4}
                        title="Facturations"
                      >
                        <div className="text-right">
                          <Link to="/add-billing" className="add-new-btn">
                            Add Billing
                          </Link>
                        </div>

                        <div className="card card-table mb-0">
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table table-hover table-center mb-0">
                                <thead>
                                  <tr>
                                    <th>Invoice No</th>
                                    <th>Doctor</th>
                                    <th>Amount</th>
                                    <th>Paid On</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <a href="invoice-view.html">#INV-0010</a>
                                    </td>
                                    <td>
                                      <h2 className="table-avatar">
                                        <Link
                                          to="/patient/doctor-profile"
                                          className="avatar avatar-sm mr-2"
                                        >
                                          <img
                                            className="avatar-img rounded-circle"
                                            src={IMG01}
                                            alt="User "
                                          />
                                        </Link>
                                        <Link to="/patient/doctor-profile">
                                          Ruby Perrin <span>Dental</span>
                                        </Link>
                                      </h2>
                                    </td>
                                    <td>$450</td>
                                    <td>14 Nov 2019</td>
                                    <td className="text-right">
                                      <div className="table-action">
                                        <a
                                          href="invoice-view.html"
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye"></i> View
                                        </a>
                                        <a
                                          href="#0"
                                          className="btn btn-sm bg-primary-light"
                                        >
                                          <i className="fas fa-print"></i> Print
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="invoice-view.html">#INV-0009</a>
                                    </td>
                                    <td>
                                      <h2 className="table-avatar">
                                        <Link
                                          to="/patient/doctor-profile"
                                          className="avatar avatar-sm mr-2"
                                        >
                                          <img
                                            className="avatar-img rounded-circle"
                                            src={IMG02}
                                            alt="User "
                                          />
                                        </Link>
                                        <Link to="/patient/doctor-profile">
                                          Dr. Darren Elder <span>Dental</span>
                                        </Link>
                                      </h2>
                                    </td>
                                    <td>$300</td>
                                    <td>13 Nov 2019</td>
                                    <td className="text-right">
                                      <div className="table-action">
                                        <a
                                          href="invoice-view.html"
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye"></i> View
                                        </a>
                                        <a
                                          href="#0"
                                          className="btn btn-sm bg-primary-light"
                                        >
                                          <i className="fas fa-print"></i> Print
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="invoice-view.html">#INV-0008</a>
                                    </td>
                                    <td>
                                      <h2 className="table-avatar">
                                        <Link
                                          to="/patient/doctor-profile"
                                          className="avatar avatar-sm mr-2"
                                        >
                                          <img
                                            className="avatar-img rounded-circle"
                                            src={IMG03}
                                            alt="User "
                                          />
                                        </Link>
                                        <Link to="/patient/doctor-profile">
                                          Dr. Deborah Angel{" "}
                                          <span>Cardiology</span>
                                        </Link>
                                      </h2>
                                    </td>
                                    <td>$150</td>
                                    <td>12 Nov 2019</td>
                                    <td className="text-right">
                                      <div className="table-action">
                                        <a
                                          href="invoice-view.html"
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye"></i> View
                                        </a>
                                        <a
                                          href="#0"
                                          className="btn btn-sm bg-primary-light"
                                        >
                                          <i className="fas fa-print"></i> Print
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="invoice-view.html">#INV-0007</a>
                                    </td>
                                    <td>
                                      <h2 className="table-avatar">
                                        <Link
                                          to="/patient/doctor-profile"
                                          className="avatar avatar-sm mr-2"
                                        >
                                          <img
                                            className="avatar-img rounded-circle"
                                            src={IMG04}
                                            alt="User "
                                          />
                                        </Link>
                                        <Link to="/patient/doctor-profile">
                                          Dr. Sofia Brient <span>Urology</span>
                                        </Link>
                                      </h2>
                                    </td>
                                    <td>$50</td>
                                    <td>11 Nov 2019</td>
                                    <td className="text-right">
                                      <div className="table-action">
                                        <a
                                          href="invoice-view.html"
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye"></i> View
                                        </a>
                                        <a
                                          href="#0"
                                          className="btn btn-sm bg-primary-light"
                                        >
                                          <i className="fas fa-print"></i> Print
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="invoice-view.html">#INV-0006</a>
                                    </td>
                                    <td>
                                      <h2 className="table-avatar">
                                        <Link
                                          to="/patient/doctor-profile"
                                          className="avatar avatar-sm mr-2"
                                        >
                                          <img
                                            className="avatar-img rounded-circle"
                                            src={IMG05}
                                            alt="User "
                                          />
                                        </Link>
                                        <Link to="/patient/doctor-profile">
                                          Dr. Marvin Campbell{" "}
                                          <span>Ophthalmology</span>
                                        </Link>
                                      </h2>
                                    </td>
                                    <td>$600</td>
                                    <td>10 Nov 2019</td>
                                    <td className="text-right">
                                      <div className="table-action">
                                        <a
                                          href="invoice-view.html"
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye"></i> View
                                        </a>
                                        <a
                                          href="#0"
                                          className="btn btn-sm bg-primary-light"
                                        >
                                          <i className="fas fa-print"></i> Print
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="invoice-view.html">#INV-0005</a>
                                    </td>
                                    <td>
                                      <h2 className="table-avatar">
                                        <Link
                                          to="/patient/doctor-profile"
                                          className="avatar avatar-sm mr-2"
                                        >
                                          <img
                                            className="avatar-img rounded-circle"
                                            src={IMG05}
                                            alt="User "
                                          />
                                        </Link>
                                        <Link to="/patient/doctor-profile">
                                          Dr. Katharine Berthold{" "}
                                          <span>Orthopaedics</span>
                                        </Link>
                                      </h2>
                                    </td>
                                    <td>$200</td>
                                    <td>9 Nov 2019</td>
                                    <td className="text-right">
                                      <div className="table-action">
                                        <Link
                                          to="/patient/doctor-profile"
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye"></i> View
                                        </Link>
                                        <Link
                                          to="/patient/doctor-profile"
                                          className="btn btn-sm bg-primary-light"
                                        >
                                          <i className="fas fa-print"></i> Print
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="invoice-view.html">#INV-0004</a>
                                    </td>
                                    <td>
                                      <h2 className="table-avatar">
                                        <Link
                                          to="/patient/doctor-profile"
                                          className="avatar avatar-sm mr-2"
                                        >
                                          <img
                                            className="avatar-img rounded-circle"
                                            src={IMG07}
                                            alt="User "
                                          />
                                        </Link>
                                        <Link to="/patient/doctor-profile">
                                          Dr. Linda Tobin <span>Neurology</span>
                                        </Link>
                                      </h2>
                                    </td>
                                    <td>$100</td>
                                    <td>8 Nov 2019</td>
                                    <td className="text-right">
                                      <div className="table-action">
                                        <a
                                          href="invoice-view.html"
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye"></i> View
                                        </a>
                                        <a
                                          href="#0"
                                          className="btn btn-sm bg-primary-light"
                                        >
                                          <i className="fas fa-print"></i> Print
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="invoice-view.html">#INV-0003</a>
                                    </td>
                                    <td>
                                      <h2 className="table-avatar">
                                        <Link
                                          to="/patient/doctor-profile"
                                          className="avatar avatar-sm mr-2"
                                        >
                                          <img
                                            className="avatar-img rounded-circle"
                                            src={IMG09}
                                            alt="User "
                                          />
                                        </Link>
                                        <Link to="/patient/doctor-profile">
                                          Dr. Paul Richard{" "}
                                          <span>Dermatology</span>
                                        </Link>
                                      </h2>
                                    </td>
                                    <td>$250</td>
                                    <td>7 Nov 2019</td>
                                    <td className="text-right">
                                      <div className="table-action">
                                        <a
                                          href="invoice-view.html"
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye"></i> View
                                        </a>
                                        <a
                                          href="#0"
                                          className="btn btn-sm bg-primary-light"
                                        >
                                          <i className="fas fa-print"></i> Print
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="invoice-view.html">#INV-0002</a>
                                    </td>
                                    <td>
                                      <h2 className="table-avatar">
                                        <Link
                                          to="/patient/doctor-profile"
                                          className="avatar avatar-sm mr-2"
                                        >
                                          <img
                                            className="avatar-img rounded-circle"
                                            src={IMG09}
                                            alt="User "
                                          />
                                        </Link>
                                        <Link to="/patient/doctor-profile">
                                          Dr. John Gibbs <span>Dental</span>
                                        </Link>
                                      </h2>
                                    </td>
                                    <td>$175</td>
                                    <td>6 Nov 2019</td>
                                    <td className="text-right">
                                      <div className="table-action">
                                        <a
                                          href="invoice-view.html"
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye"></i> View
                                        </a>
                                        <a
                                          href="#0"
                                          className="btn btn-sm bg-primary-light"
                                        >
                                          <i className="fas fa-print"></i> Print
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="invoice-view.html">#INV-0001</a>
                                    </td>
                                    <td>
                                      <h2 className="table-avatar">
                                        <Link
                                          to="/patient/doctor-profile"
                                          className="avatar avatar-sm mr-2"
                                        >
                                          <img
                                            className="avatar-img rounded-circle"
                                            src={IMG10}
                                            alt="User "
                                          />
                                        </Link>
                                        <Link to="/patient/doctor-profile">
                                          Dr. Olga Barlow <span>#0010</span>
                                        </Link>
                                      </h2>
                                    </td>
                                    <td>$550</td>
                                    <td>5 Nov 2019</td>
                                    <td className="text-right">
                                      <div className="table-action">
                                        <a
                                          href="invoice-view.html"
                                          className="btn btn-sm bg-info-light"
                                        >
                                          <i className="far fa-eye"></i> View
                                        </a>
                                        <a
                                          href="#0"
                                          className="btn btn-sm bg-primary-light"
                                        >
                                          <i className="fas fa-print"></i> Print
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Tab> */}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Medical Records</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>Date</label>
              <input
                type="text"
                className="form-control datetimepicker"
                value="31-10-2019"
              />
            </div>
            <div className="form-group">
              <label>Description ( Optional )</label>
              <textarea className="form-control"></textarea>
            </div>
            <div className="form-group">
              <label>Upload File</label>
              <input type="file" className="form-control" />
            </div>
            <div className="submit-section text-center">
              <button type="submit" className="btn btn-primary submit-btn">
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary submit-btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default PatientProfile;
