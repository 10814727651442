import React, { Component } from "react";
// import { Link } from 'react-router-dom';

class AboutUs extends Component {
  render() {
    return (
      <div>
        <div className="container-fluid" style={{ marginTop: "50px" }}>
          <h1 className="text-center">Bienvenue chez Kwaber !</h1>
          <br />
          <br />
          <div class="row">
            <div class="col-sm-2 col-md-6">
              <div class="card">
                <div class="card-body">
                  <h3 class="card-title text-center">
                    Kwaber accompagne toutes actions de développement sanitaire
                    en Afrique central
                  </h3>{" "}
                  <br />
                  <br />
                  <br />
                  {/* <p class="card-text">With supporting text below as a natural lead-in to additional content.</p> */}
                  <p class="card-text text-justify">
                    Kwaber Santé est une nouvelle solution digitale assignée au
                    Congo-Brazzaville <br />
                    <br />
                    <br />
                    Ce service innovant offre à tous la possibilité de
                    contribuer à un impact social vertueux et au développement
                    du Congo en prenant rendez-vous et en payant vos
                    consultations en ligne. Kwaber santé est aussi accessible à
                    la diaspora africaine qui souhaite aider des proches en
                    participant, à distance, au règlement des dépenses de santé.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6">
              <div class="card">
                <div class="card-body">
                  <h3 class="card-title text-center">
                    Pourquoi sommes-nous différents ?
                  </h3>
                  <br />
                  <br />
                  <p class="card-text text-justify">
                    Chez Kwaber nous bâtissons une relation de confiance avec
                    nos clients en leur proposant <br /> <br />
                    <ul>
                      <li>
                        D’accéder aux disponibilités de professionnels de santé
                        assermentés.
                      </li>
                      <li>
                        De bénéficier de la prise de rendez-vous en ligne, des
                        téléconsultations et du paiement sécurisé des soins à
                        distance.
                      </li>
                      <li>
                        De recevoir des rappels automatiques par SMS ou par email.
                      </li>
                      <li>
                        D’accéder à l’historique de vos rendez-vous de vos
                        ordonnances.
                      </li>
                      <li>
                        De régler vos consultations en ligne ou celles de vos
                        proches, même à des milliers de kilomètres.
                      </li>
                    </ul>
                  </p>

                  <p>
                    Pour les clients Kwaber, plus besoin de se déplacer en ville
                    pour faire des files indiennes afin de recevoir de soins de
                    qualités. Kwaber offre également la possibilité à la
                    diaspora de venir en aide à leurs proches pour tout problème
                    de santé.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AboutUs;
