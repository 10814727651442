import React, { Component } from "react";
import { Link } from "react-router-dom";
// import Lightbox from "react-image-lightbox";
import { Tabs, Tab } from "react-bootstrap";
// import { Modal } from "react-bootstrap";
// import Icon from "@material-ui/core/Icon";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Image } from "antd";
import host from "../../../../host";
import numStr from '../separator/separator'



// Vérification si c'est un nombre entier
function isNumeric(number) {
  return !isNaN(parseFloat(number)) && !isNaN(+number);
}


class DoctorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      key: 1,
      activeModal: null,
      professionnel: null,
      distinction: [],
      isLoading: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    if (isNumeric(params.id)) {
      const id = parseInt(params.id);
      //récupération des professionnels de santé
      fetch(host + `/ps/${id}`)
        .then((response) => {
          this.setState({
            isLoading: true,
          });
          if (response.status == 404) {
            this.props.history.push("/404");
          } else if (response.status == 200) {
            return response.json();
          }
        })
        .then((data) => {
          this.setState({
            professionnel: data,
            isLoading: false,
          });
        });
    } else {
      this.props.history.push("/404");
    }
  }

  //Change the  tab 
  handleSelect(key) {
    // console.log("selected " + key);
    this.setState({ key });
  }

  // handleCloseModal = () => {
  //   this.setState({ activeModal: false }, () => {
  //     // console.log(this.state.activeModal, "state");
  //   });
  // };

  render() {
    const { photoIndex, isOpen, professionnel } = this.state;
    // const allFormation = professionnel ? professionnel : "";
     let  allFormation = this.state.professionnel ? this.state.professionnel.diplome.sort((a, b) => b.annee - a.annee) : "";
     let allFormations = allFormation ? allFormation.reverse() : "";

     let  allDistinction = this.state.professionnel ? this.state.professionnel.distinctions.sort((a, b) => b.annee - a.annee) : "";
     let allDistinctions = allDistinction ? allDistinction.reverse() : "";

    if (professionnel === null) {
      return (
        <div
          style={{
            textAlign: "center",
            marginTop: "50vh",
            marginBottom: "40vh",
          }}
        >
          <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      );
    } else
      return (
        
        <div style={{ marginTop: "20px" }}>
          <div
            className={
              this.state.isLoading
                ? "container-fluid page_wrapper_blur"
                : "container-fluid"
            }
          >
            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="doctor-widget">
                    <div className="doc-info-left">
                      <div className="doctor-img">
                        <Image
                           width={150}
                           height={150}
                          src={
                            professionnel.user.photo ?  professionnel.user.photo  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"
                            // professionnel.user.photo ?  host + `/user/images/${professionnel.user.photo}`  : "https://www.belin.re/wp-content/uploads/2018/11/default-avatar.png"                            
                          }
                          className="img-fluid"
                          style={{ borderRadius: "2%" }}
                          alt={professionnel.nom + " " + professionnel.prenom}
                        />
                      </div>
                      <div
                        className="doc-info-cont"
                        style={{ marginTop: "5%" }}
                      >
                        <h4 className="doc-name" id="profil-doctor">
                          {professionnel.nom + " " + professionnel.prenom}
                        </h4>
                        <p className="doc-department">
                          {professionnel.specialite === null ||
                          professionnel.specialite === undefined
                            ? "Aucune spécialité spécifié"
                            : professionnel.specialite.libelle}
                        </p>
                        {/* <p className="doc-department"> "Aucune spécialité spécifié"</p> */}
                      </div>
                    </div>
                    <div className="doc-info-right">
                      <div className="clini-infos"></div>
                      <div className="clinic-booking">
                        <Link
                          to={{
                            // pathname: "/patient/booking",
                            pathname:
                              "/prestation/" +
                              this.state.professionnel.idprofessionnelsante,
                            aboutProps: {
                              idProfessionel:
                                this.state.professionnel.idprofessionnelsante,
                            },
                          }}
                          className="btn book-btn"
                        >
                          {/* Réserver */}
                          Prendre RDV
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div>
                  <Tabs
                    className="tab-view"
                    activeKey={this.state.key}
                    // className="mb-3"
                    onSelect={this.handleSelect}
                    id="controlled-tab-example"
                  >
                    <Tab
                      className="nav-item"
                      eventKey={1}
                      title="Vue d'ensemble"
                    >
                      <div className="col-md-1 col-lg-12">
                        <div className="widget about-widget">
                          <h4 className="widget-title">À propos de moi</h4>
                          <p>{professionnel.biographie} </p>
                        </div>
                      </div>
                      <div className="row">
                        {/* <div className="col-md col-lg" >
													<div className="widget education-widget">
														<h4 className="widget-title">Formation</h4>
															
													</div>
												</div> */}

                        <div className="col-md col-lg">
                          <div className="widget expertise-widget">
                            <h4 className="widget-title">Formation(s)</h4>
                            <div className="experience-box">
                              <ul className="experience-list">
                                {
                                  // const sortedActivities = this.state.professionnel.diplome == null || this.state.professionnel.diplome == undefined ? "" : this.state.professionnel.diplome.sort((a, b) => b.annee - a.annee);
                                  !allFormations ? "" :
                                  allFormations.map(
                                      (diplome) => {
                                        return (
                                          <li>
                                            <div className="experience-user">
                                              <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                              <div className="timeline-content">
                                                <a href="#/" className="name">
                                                  {diplome.libelle}
                                                </a>
                                                <br></br>
                                                <span className="adresse">
                                                  Etablissement :{" "}
                                                  {diplome.etablissement}
                                                </span>
                                                <br /> <br />
                                                <span className="adresse">
                                                  année : {diplome.annee}
                                                </span>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      }
                                    )}
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div className="col-md col-lg">
                          <div className="widget expertise-widget">
                            <h4 className="widget-title">Distinction(s)</h4>
                            <div className="experience-box">
                              <ul className="experience-list">
                                { !allDistinctions ? "" :
                                allDistinctions.map(
                                  (distinction) => {
                                    return (
                                      <li key={distinction.iddistrinction}>
                                        <div className="experience-user">
                                          <div className="before-circle"></div>
                                        </div>
                                        <div className="experience-content">
                                          <div className="timeline-content">
                                            <a href="#/" className="name">
                                              {distinction.libelle}{" "}
                                            </a>
                                            <br></br>
                                            <span className="adresse">
                                              {distinction.annee}{" "}
                                            </span>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md col-lg">
                          <div className="widget expertise-widget">
                            <h4 className="widget-title">Experience</h4>
                            <div className="experience-box">
                              <ul className="experience-list">
                                {professionnel.experiences.map((experience) => {
                                  return (
                                    <li key={experience.idexperience}>
                                      <div className="experience-user">
                                        <div className="before-circle"></div>
                                      </div>
                                      <div className="experience-content">
                                        <div className="timeline-content">
                                          <a href="#/" className="name">
                                            {experience.description}{" "}
                                          </a>
                                          <br></br>
                                          <span className="adresse">
                                            {" "}
                                            Date de début:{" "}
                                            {experience.datedebut}{" "}
                                          </span>
                                          <br></br>
                                          <span className="adresse">
                                            {" "}
                                            Date de fin: {
                                              experience.datefin
                                            }{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </Tab>
                    <Tab className="nav-item" eventKey={2} title="Prestation(s)">
                      <div className="row">
                        <div className="col-md col-lg">
                          <div className="widget education-widget">
                            <div className="col-md-1 col-lg-4">
                              <h4 class="p-3 mb-2 bg-info text-white">
                                Consultation(s)  physique
                              </h4>
                            </div>
                            <div className="experience-box">
                              {professionnel.prestation.length === 0 ? (
                                <div className="col-md-1 col-lg-6">
                                  <h4>
                                    Aucune consultation physique disponible
                                  </h4>
                                </div>
                              ) : (
                                ""
                              )}
                              <ul className="experience-list">
                                {professionnel.prestation.length === 0
                                  ? ""
                                  : professionnel.prestation.map((presta) => {
                                      if (presta.modalite === "physique") {
                                        return (
                                          <li
                                            className="experience-content"
                                            key={"prestation" + presta.id}
                                          >
                                            <div className="experience-user">
                                              <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                              <div className="timeline-content">
                                                <Link
                                                  to={{
                                                    pathname: "#",
                                                    prestation: {
                                                      idProfessionel:
                                                        this.state.professionnel
                                                          .id,
                                                      prestations: presta,
                                                      prixEuro: presta.prixEuro,
                                                      prixXAF: presta.prixXAF,
                                                    },
                                                  }}
                                                >
                                                  <strong>
                                                    {presta.libelle}
                                                  </strong>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                  { numStr(presta.pXAF, '.') + " XAF"}
                                                </Link>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      }
                                    })}
                              </ul>
                            </div>

                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <div className="col-md-1 col-lg-4">
                              <h4 class="p-3 mb-2 bg-info text-white">
                                Consultation(s)  vidéo
                              </h4>
                            </div>
                            <div className="experience-box">
                              {professionnel.prestation.length === 0 ? (
                                <div className="col-md-1 col-lg-6">
                                  <h4>Aucune consultation vidéo disponible</h4>
                                </div>
                              ) : (
                                ""
                              )}
                              <ul className="experience-list">
                                {professionnel.prestation.length === 0
                                  ? ""
                                  : professionnel.prestation.map((presta) => {
                                      if (presta.modalite === "video"){
                                        return (
                                          <li
                                            className="experience-content"
                                            key={"prestation" + presta.id}
                                          >
                                            <div className="experience-user">
                                              <div className="before-circle"></div>
                                            </div>
                                            <div className="experience-content">
                                              <div className="timeline-content">
                                                <Link
                                                  to={{
                                                    pathname: "#",
                                                    // prestation: {
                                                    // 	idProfessionel: this.state.professionnel.id,
                                                    // 	prestations: presta,
                                                    // 	prixEuro: presta.PrixEUR,
                                                    // 	prixXAF: presta.PrixXAF
                                                    // }
                                                  }}
                                                >
                                                  <strong>
                                                    {presta.libelle}
                                                  </strong>
                                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                  {numStr(presta.pXAF, '.') + " XAF"}
                                                </Link>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      }
                                    })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab className="nav-item" eventKey={3} title="Centre(s)">
                      <div>
                        <div className="location-list">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="clinic-content">
                                <ul className="experience-list">
                                  {professionnel.centres.map((centres) => {
                                    return (
                                      <li key={centres.id}>
                                        <div className="Clinic information">
                                          <div className="before-circle"></div>
                                        </div>
                                        <div className="experience-content">
                                          <div className="timeline-content">
                                            <a href="#/" className="name">
                                              {centres.libelle === null ||
                                              centres.libelle === undefined
                                                ? ""
                                                : centres.libelle}{" "}
                                            </a>
                                            <br></br>
                                            <span className="adresse">
                                              {centres.adresse === null ||
                                              centres.adresse === undefined
                                                ? ""
                                                : centres.adresse}{" "}
                                            </span>
                                            {/* <br></br><span className="adresse">{centres.typecentres == null || centres.typecentres == undefined ? "" : centres.typecentres.libelle} </span> */}

                                            {/* <br></br><span className="adresse">{distinction.annee} </span> */}
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default DoctorProfile;
