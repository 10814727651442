import React from 'react'
import erreurimg from "../../assets/images/notfound.png"
function Error() {
    return (
        <div className="container">
	        <div className="row"><br/>
		    <div className=" text-center jumbotron">
                <p className="text-center"> <img  src={erreurimg} alt="" width="200px" height="200px" /> </p>
                <h1>Erreur 404, Page introuvable</h1>
               
            </div>
	    </div>	
   </div>
    )
}
export default  Error;
