import React, {Component} from "react";

import {IMG01, IMG02} from "./img";
import {Tooltip, OverlayTrigger} from "react-bootstrap";
import {AGORA_APP_ID} from "./agora.config";

import AgoraVideoCall from "./components/video_call";

class VideoCall extends Component {

    constructor(props) {
        /*
          this.videoProfile = Cookies.get("videoProfile").split(",")[0] || "480p_4";
          this.channel = Cookies.get("channel") || "test";
          this.transcode = Cookies.get("transcode") || "interop";
          this.attendeeMode = Cookies.get("attendeeMode") || "video";
          this.baseMode = Cookies.get("baseMode") || "avc";
         */

        super(props);
        this.videoProfile = "480p_4";
        this.channel = "test";
        this.transcode = "interop";
        this.attendeeMode = "video"
        this.baseMode = "avc";
        this.appId = AGORA_APP_ID;
        if (!this.appId) {
            return alert("Get App ID first!");
        }
        this.uid = undefined;
    }

    render() {
        return (
            <div style={{backgroundColor: "#202124",height:"100%",width:"100%",position:"absolute"}}>
                <div className="container-fluid">
                    <AgoraVideoCall
                        videoProfile={this.videoProfile}
                        channel={this.channel}
                        transcode={this.transcode}
                        attendeeMode={this.attendeeMode}
                        baseMode={this.baseMode}
                        appId={this.appId}
                        uid={this.uid}
                    />
                </div>
            </div>
        );
    }
}

export default VideoCall;
/*
     <div className="call-footer">
                        <div className="call-icons">
                          <span className="call-duration">00:59</span>
                          <ul className="call-items">
                            <li className="call-item">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip1">Enable video</Tooltip>
                                }
                              >
                                <span className="d-inline-block">
                                  <a href="#0">
                                    {" "}
                                    <i className="fas fa-video camera"></i>
                                  </a>
                                </span>
                              </OverlayTrigger>
                            </li>
                            <li className="call-item">
                              <OverlayTrigger
                                overlay={<Tooltip id="tooltip2">Mute</Tooltip>}
                              >
                                <span className="d-inline-block">
                                  <a href="#0">
                                    {" "}
                                    <i className="fa fa-microphone microphone"></i>
                                  </a>
                                </span>
                              </OverlayTrigger>
                            </li>
                            <li className="call-item">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip2">Add User</Tooltip>
                                }
                              >
                                <span className="d-inline-block">
                                  <a href="#0">
                                    {" "}
                                    <i className="fa fa-user-plus"></i>
                                  </a>
                                </span>
                              </OverlayTrigger>
                            </li>
                            <li className="call-item">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip2">Full-screen</Tooltip>
                                }
                              >
                                <span className="d-inline-block">
                                  <a href="#0">
                                    {" "}
                                    <i className="fas fa-arrows-alt-v full-screen"></i>
                                  </a>
                                </span>
                              </OverlayTrigger>
                            </li>
                          </ul>
                          <div className="end-call">
                            <a href="#0">
                              <i className="material-icons">call_end</i>
                            </a>
                          </div>
                        </div>
                      </div>

*/


/*
  <AgoraVideoCall
                              videoProfile={this.videoProfile}
                              channel={this.channel}
                              transcode={this.transcode}
                              attendeeMode={this.attendeeMode}
                              baseMode={this.baseMode}
                              appId={this.appId}
                              uid={this.uid}
                            />
*/
