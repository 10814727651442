import React, {Component} from 'react';
import {IMG01} from './img';
class InvoiceView extends Component{
    render(){
        return(
            <div>
            		<div className="content">
				<div className="container-fluid">

					<div className="row">
						<div className="col-lg-8 offset-lg-2">
							<div className="invoice-content">
								<div className="invoice-item">
									<div className="row">
										<div className="col-md-6">
											<div className="invoice-logo">
												
											</div>
										</div>
										<div className="col-md-6">
											<p className="invoice-details">
												<strong>Commande:</strong> #00124 
												<strong>Délivrée:</strong> 20/12/2020
											</p>
										</div>
									</div>
								</div>
								
								
								<div className="invoice-item">
									<div className="row">
										<div className="col-md-6">
											<div className="invoice-info">
												<strong className="customer-text">Facture de</strong>
												<p className="invoice-details invoice-details-two">
													Dr. Darren Elder 
													Adresse principale
													Brazza, Congo
												</p>
											</div>
										</div>
										<div className="col-md-6">
											<div className="invoice-info invoice-info2">
												<strong className="customer-text">à destination de</strong>
												<p className="invoice-details">
													Walter Roberson 
													Adresse principale
													Brazza, Congo
												</p>
											</div>
										</div>
									</div>
								</div>
							
								<div className="invoice-item">
									<div className="row">
										<div className="col-md-12">
											<div className="invoice-info">
												<strong className="customer-text">Moyen de paiement</strong>
												<p className="invoice-details invoice-details-two">
													Carte bancaire
													XXXXXXXXXXXX-2541 
													HDFC Bank
												</p>
											</div>
										</div>
									</div>
								</div>
								
								<div className="invoice-item invoice-table-wrap">
									<div className="row">
										<div className="col-md-12">
											<div className="table-responsive">
												<table className="invoice-table table table-bordered">
													<thead>
														<tr>
															<th>Description</th>
															<th className="text-center">Quantité</th>
															<th className="text-center">VAT</th>
															<th className="text-right">Total</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>Consultation générale</td>
															<td className="text-center">1</td>
															<td className="text-center">0 XAF</td>
															<td className="text-right">1000 XAF</td>
														</tr>
														<tr>
															<td>Video Call Booking</td>
															<td className="text-center">1</td>
															<td className="text-center">0 XAF</td>
															<td className="text-right">2500 XAF</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div className="col-md-6 col-xl-4 ml-auto">
											<div className="table-responsive">
												<table className="invoice-table-two table">
													<tbody>
													<tr>
														<th>Frais à payer:</th>
														<td><span>3500 XAF</span></td>
													</tr>
													<tr>
														<th>Réduction:</th>
														<td><span>-10%</span></td>
													</tr>
													<tr>
														<th>Montant total:</th>
														<td><span>3150 XAF</span></td>
													</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							
								
								<div className="other-info">
									<h4>Autres informations</h4>
									<p className="text-muted mb-0">Des informations supplémentaires dans ce genre de cas sont souvent nécessaire telles que la date d'expiration de l'ordonnance et autres.</p>
								</div>
							
								
							</div>
						</div>
					</div>

				</div>

			</div>		

         </div>
        );
    }
}
export default InvoiceView;
